import AmplitudeContext, {
  AmplitudeFlowData,
  FlowName,
} from 'context/AmplitudeData';
import React, { useState, useMemo, ReactNode } from 'react';

const AmplitudeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [amplitudeData, setAmplitudeData] = useState<AmplitudeFlowData>({});

  const updateFlowData = useMemo(
    () => (flowName: FlowName, newEventData: Record<string, any>) => {
      setAmplitudeData(prevData => ({
        ...prevData,
        [flowName]: { ...prevData[flowName], ...newEventData },
      }));
    },
    [],
  );

  const clearFlowData = useMemo(
    () => (flowName: FlowName) => {
      setAmplitudeData(prevData => {
        const { [flowName]: _, ...rest } = prevData;
        return rest;
      });
    },
    [],
  );

  const contextValue = useMemo(
    () => ({ amplitudeData, updateFlowData, clearFlowData }),
    [amplitudeData, updateFlowData, clearFlowData],
  );

  return (
    <AmplitudeContext.Provider value={contextValue}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
