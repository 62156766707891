import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const StyledManualInviteEntriesContainer = styled.div`
  margin: 30px 0;
`;

export const StyledEnterManuallyOrPaste = styled.p`
  margin-bottom: 12px !important;
`;

export const StyledInviteEntryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 100%;
  padding: 16px;
`;

export const StyledInviteFormField = styled(M.TextInput)`
  flex-grow: 1;
  flex-basis: 0;
`;
