import React, { useContext, useCallback } from 'react';
import UserContext from 'context/CurrentUser';
import { useDispatch } from 'react-redux';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  SELF_SERVICE_REAUTHORIZATION_KEY,
  MANAGE_TAX_EXEMPTION_CERTIFICATES,
} from 'Constants';
import { PaymentWrapper } from 'components/Account/Payment/PaymentStyledComponents';
import {
  BankInformation,
  BillingAddress,
  BillingContacts,
  CreditCardInformation,
  LoadingScreen,
  PaymentSectionHeader,
  PaymentSchedule,
  BillingInformationHeader,
  TaxExemptionCertificateSection,
} from 'components/Account';
import { fetchUser } from 'actions';
import { GenericObject } from '@dashboard-experience/utils';
import { QueryResult } from 'react-query';
import {
  BANK_ACCOUNT_METHOD_TYPE,
  CARD_METHOD_TYPE,
  PaymentMethod,
  useGetPaymentMethods,
  usePostPaymentMethod,
  useAuthorizePaymentAction,
  AuthorizePaymentActionParams,
  useGetTaxExemptionCertificates,
  useEnableTaxForCustomer,
} from 'api/payment';
import BillingCustomerContext from 'containers/Billing/context';
import ReauthorizeBanner from 'components/Account/Payment/ReauthorizeBanner';

// Component diagram for Payments tab of Payments & Billing:
// https://checkr.atlassian.net/wiki/spaces/RD/pages/2189918734/Support+for+Multiple+Payment+Methods#Pretty-Pictures

const PaymentContainer: React.FC = () => {
  const userData: any = useContext(UserContext);
  const {
    customerData,
    isLoading: fetchLoading,
    isSuccess: fetchSuccess,
    error: fetchError,
    refetch,
  } = useContext(BillingCustomerContext);
  const dispatch = useDispatch();
  const accountId: string = userData?.account?.id;
  const { postPaymentMethodCall, postPaymentMethodResult }: any =
    usePostPaymentMethod();
  const { data: paymentMethods, isFetching }: QueryResult<GenericObject> =
    useGetPaymentMethods(accountId);

  const { data: taxExemptionCertificates }: any =
    useGetTaxExemptionCertificates(accountId) || [];

  const banks: Array<PaymentMethod> =
    paymentMethods?.payment_methods?.filter(
      (pm: any) => pm.payment_type === BANK_ACCOUNT_METHOD_TYPE,
    ) || [];

  const cards: Array<PaymentMethod> =
    paymentMethods?.payment_methods?.filter(
      (pm: any) => pm.payment_type === CARD_METHOD_TYPE,
    ) || [];

  const reauthorizationSuccessHandler = useCallback(() => {
    refetch();
    dispatch(fetchUser());
  }, [refetch, dispatch]);

  const { authorizePaymentActionCall, authorizePaymentActionResult }: any =
    useAuthorizePaymentAction(reauthorizationSuccessHandler);

  const { action: reauthorizeAction, amount_due: reauthorizeAmount } =
    customerData?.customer_deauthorization?.required_action || {};

  const enableReauthorize =
    useFlag(SELF_SERVICE_REAUTHORIZATION_KEY)?.variantKey === 'on';

  const displayTaxExemptionCertificateSectionFlag =
    useFlag(MANAGE_TAX_EXEMPTION_CERTIFICATES)?.variantKey === 'enabled';

  const displayTaxExemptionCertificateSection =
    displayTaxExemptionCertificateSectionFlag &&
    customerData &&
    taxExemptionCertificates;

  const invitationUrl = customerData?.certexpress_invitation_url;

  const { enableTaxForCustomerCall, enableTaxForCustomerResult }: any =
    useEnableTaxForCustomer();

  const displayReauthorizeBanner =
    customerData?.customer_deauthorization &&
    reauthorizeAction &&
    (reauthorizeAction === 'verify_payment_capability' ||
      (reauthorizeAction === 'capture_reauthorization_payment' &&
        enableReauthorize));

  const authorizePaymentHold = useCallback(() => {
    if (authorizePaymentActionResult.isLoading) {
      return;
    }

    const action = reauthorizeAction;
    const amount = reauthorizeAmount;

    if (!action || !amount) {
      return;
    }

    const params: AuthorizePaymentActionParams = { accountId, amount, action };
    authorizePaymentActionCall(params);
  }, [
    authorizePaymentActionCall,
    authorizePaymentActionResult.isLoading,
    reauthorizeAction,
    reauthorizeAmount,
    accountId,
  ]);

  return (
    <PaymentWrapper className='payment'>
      {!isFetching ? (
        <>
          {displayReauthorizeBanner && (
            <ReauthorizeBanner
              amount={reauthorizeAmount}
              action={reauthorizeAction}
              handleButtonClick={authorizePaymentHold}
              disabled={
                authorizePaymentActionResult.isLoading || reauthorizeAmount == 0
              }
            />
          )}
          <BillingInformationHeader />
          <BillingAddress
            addressData={customerData}
            fetchLoading={fetchLoading}
            fetchSuccess={fetchSuccess}
            fetchError={fetchError}
          />
          <BillingContacts
            contactsData={customerData}
            fetchLoading={fetchLoading}
            fetchSuccess={fetchSuccess}
            fetchError={fetchError}
          />
          <PaymentSchedule customerData={customerData} />
          <PaymentSectionHeader />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '4rem',
            }}
          >
            <div>
              <BankInformation
                banks={banks}
                postPaymentMethod={{
                  postPaymentMethodCall,
                  postPaymentMethodResult,
                }}
                paymentMethodRequired={
                  customerData?.payment_method_required || false
                }
                paymentMethodDisabled={
                  customerData?.billing_entity_enabled || false
                }
              />
            </div>
            <div data-testid='cc-info'>
              <CreditCardInformation
                cards={cards}
                postPaymentMethod={{
                  postPaymentMethodCall,
                  postPaymentMethodResult,
                }}
                paymentMethodRequired={
                  customerData?.payment_method_required || false
                }
                paymentMethodDisabled={
                  customerData?.billing_entity_enabled || false
                }
              />
              {displayTaxExemptionCertificateSection && (
                <TaxExemptionCertificateSection
                  taxExemptionCertificates={taxExemptionCertificates}
                  accountId={accountId}
                  invitationUrl={invitationUrl}
                  enableTaxForCustomerCall={enableTaxForCustomerCall}
                  enableTaxForCustomerResult={enableTaxForCustomerResult}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </PaymentWrapper>
  );
};
export default PaymentContainer;
