import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ReportStatuses } from '@dashboard-experience/utils';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import { useReport } from 'containers/Report';
import { ORDER_ADDONS_FLOW } from 'Constants';
import { useCandidate } from 'providers/Candidate';
import RequestCandidateStory from 'modules/adjudication/ui/decision/candidate-story';
import { useInvitation } from 'modules/candidate/utilities';
import { useCanOrderNewReport } from '../../../utils';
import * as Items from './menu-items';

const useArchiveAction = () => {
  const invitation = useInvitation();
  const report = useReport();
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  if (!archiveReportFlagEnabled) return null;

  if (invitation) {
    return <Items.ArchiveInvitation />;
  }

  const disableArchiveReportButton =
    report.status === ReportStatuses.PENDING ||
    report.status === ReportStatuses.DISPUTE ||
    report.status === ReportStatuses.SUSPENDED;

  if (disableArchiveReportButton) return null;

  return <Items.ArchiveReport />;
};

const useGetMenuItems = () => {
  const canOrderNewReport = useCanOrderNewReport();
  const candidate = useCandidate();

  const orderAddonsFlowEnabled =
    useFlag(ORDER_ADDONS_FLOW)?.variantKey === 'enabled';

  const menuItems = [];

  const isInternationalCandidate = candidate.geos
    ? candidate.geos?.filter(
        geo => geo.country !== 'US' && geo.country !== null,
      )?.length > 0
    : false;

  menuItems.push(<RequestCandidateStory />);

  if (
    canOrderNewReport &&
    !orderAddonsFlowEnabled &&
    !isInternationalCandidate
  ) {
    menuItems.push(<Items.OrderNewReportAction />);
  }

  const archiveAction = useArchiveAction();
  if (archiveAction) {
    menuItems.push(archiveAction);
  }

  const showAddChecksButton =
    canOrderNewReport &&
    orderAddonsFlowEnabled &&
    !!candidate?.last_report_id &&
    !isInternationalCandidate;

  if (showAddChecksButton) {
    menuItems.push(<Items.AddChecksRenderLogic />);
  }

  menuItems.push(<Items.DeleteCandidateData />);

  return menuItems;
};

export default useGetMenuItems;
