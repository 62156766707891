import React from 'react';
import PageContainer from '../shared/PageContainer';
import SettingsContainer from './SettingsContainer';

export const Settings: React.FC = () => {
  return (
    <PageContainer headerText='Settings' testId='case-management-settings-page'>
      <SettingsContainer />
    </PageContainer>
  );
};

export default Settings;
