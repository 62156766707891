import React from 'react';
import capitalize from 'lodash/capitalize';
import { FieldComponentProps } from '../../types';

const Adjudication: React.FC<FieldComponentProps> = ({ report, testid }) => {
  return (
    <div data-testid={testid}>
      {report.adjudication
        ? capitalize(report.adjudication.replaceAll('_', ' '))
        : 'No action'}
    </div>
  );
};

export default Adjudication;
