/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { getFacisPrice } from 'components/AddScreenings/shared/utils';
import SpecialAddScreeningsAction, {
  SpecialAddScreeningsActionProps,
} from 'components/AddScreenings/shared/SpecialAddScreeningsAction';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type FACIS1SearchProps = {
  shouldOpen: boolean | undefined;
  itemPrices: { [key: string]: number };
  actionProps: ActionPropsProps;
  additionalProperties: {
    facis_search: {
      subtype: number;
    };
  };
};

const FACIS1Search = ({
  shouldOpen,
  itemPrices,
  actionProps,
  additionalProperties,
}: FACIS1SearchProps) => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    actionProps;

  const facisSubtype1Props: SpecialAddScreeningsActionProps = {
    screeningType: ScreeningType.facis_search,
    kind:
      addedScreenings.includes(ScreeningType.facis_search) &&
      additionalProperties?.facis_search?.subtype === 1
        ? 'tertiary'
        : 'secondary',

    onClick: () =>
      addedScreenings.includes(ScreeningType.facis_search)
        ? onRemoveClick(ScreeningType.facis_search)
        : onAddClick(ScreeningType.facis_search, { subtype: 1 }),

    disabled:
      disabledScreenings.includes(ScreeningType.facis_search) ||
      additionalProperties?.facis_search?.subtype === 3,

    id:
      addedScreenings.includes(ScreeningType.facis_search) &&
      additionalProperties?.facis_search?.subtype === 1
        ? 'delete-trash-can'
        : '',
    added:
      addedScreenings.includes(ScreeningType.facis_search) &&
      additionalProperties?.facis_search?.subtype === 1,
    // please leave the below a ==
    specialDisabled: additionalProperties?.facis_search?.subtype == 3,
  };

  return (
    <FlexContainer data-testid='facis-1-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-facis-1-search-section'
        title={
          <Title
            getPriceByType={() => getFacisPrice(itemPrices, '1')}
            actionProps={actionProps}
            title='FACIS Level 1'
            priceByType='FACIS_level_1'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Search for penalties, suspensions, or punitive or disciplinary
            actions; meets minimum federal requirements.{` `}
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Help meet regulatory obligations to check specific healthcare sanctions lists.' />
              <BulletPoint text='Level options meet or exceed federal requirements.' />
              <BulletPoint text='Gain insight into a healthcare professional’s healthcare sanctions (if any).' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Some record sources limit PII.' />
              <BulletPoint text='The FACIS search is limited to 7 years of history.' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <SpecialAddScreeningsAction {...facisSubtype1Props} />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default FACIS1Search;
