import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { BrandsButton } from 'components/Account/Brands';
import { scrollToTop } from 'utils';
import { ConfirmModal } from 'components';
import { useDeleteBrand } from 'api/brand/hooks';
import { BrandResponse } from 'api/brand/actions';
import EditModal from './EditModal';
import AssignNodes from './AssignNodesModal';

type BrandsTableRowProps = {
  brand: BrandResponse;
  page: number;
  setPage: (page: number) => void;
};

const BrandsTableRow = ({ brand, setPage, page }: BrandsTableRowProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const { call: deleteBrand } = useDeleteBrand(page);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const showEditModal = useCallback(() => {
    setIsEditOpen(true);
  }, [setIsEditOpen]);

  const hideEditModal = useCallback(() => {
    setIsEditOpen(false);
  }, [setIsEditOpen]);

  const showAssignModal = useCallback(() => {
    setIsAssignOpen(true);
  }, [setIsAssignOpen]);

  const hideAssignModal = useCallback(() => {
    setIsAssignOpen(false);
  }, [setIsAssignOpen]);

  const handleDelete = useCallback(() => {
    if (brand.id != null) {
      deleteBrand(brand.id).then(() => setPage(1));
    }
    hideModal();
  }, [brand.id, deleteBrand, hideModal, setPage]);

  return (
    <>
      <M.TableRow key={brand.name} data-testid='brands-row'>
        <M.TableCell>
          {brand.logo_uri && (
            <img src={brand.logo_uri} alt={brand.name} width='80' />
          )}
        </M.TableCell>
        <M.TableCell data-testid='cell-name'>{brand.name}</M.TableCell>
        <M.TableCell>
          <BrandsButton onClick={showEditModal}>Edit</BrandsButton>
          <BrandsButton onClick={showModal}>Delete</BrandsButton>
          <BrandsButton onClick={showAssignModal}>Assign Segments</BrandsButton>
        </M.TableCell>
      </M.TableRow>
      <ConfirmModal
        header='Delete Brand'
        message='Are you sure you want to delete this brand?'
        open={modalOpen}
        hideModal={hideModal}
        callback={handleDelete}
        cancelMessage='Cancel'
        confirmMessage='Yes'
      />
      <EditModal
        page={page}
        name={brand.name}
        id={brand.id}
        open={isEditOpen}
        hideModal={hideEditModal}
      />
      <AssignNodes
        name={brand.name}
        id={brand.id}
        open={isAssignOpen}
        hideModal={hideAssignModal}
      />
    </>
  );
};

export default BrandsTableRow;
