import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSignupPageContext } from 'pages/Signup/context';
import {
  SIGNUP_EVENT_NAMES,
  FOX_SIGNUP_EVENT_NAMES,
  useTrackEvent,
} from 'utils/analytics';
import { Modal, Footer } from './ui/Modal';
import CustomizeWithAddOns from './ui/CustomizeWithAddOns';
import { updateSessionStorage } from './utils/sessionStorage';

const AddOnsPage = () => {
  const { t } = useTranslation('signup');
  const { addOns } = useSignupPageContext();
  const history = useHistory();
  const location = useLocation();
  const trackEvent = useTrackEvent();

  const handleContinue = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_CUSTOMIZE_ADDONS_STEP_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_CUSTOMIZE_ADDONS_STEP_COMPLETED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    history.push({
      pathname: '/signup/candidate-info',
      search: location.search,
    });
  }, [history, location.search, trackEvent]);

  const handleBack = useCallback(() => {
    history.push({ pathname: '/signup/packages', search: location.search });

    trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_BACK_BUTTON_CLICKED, {
      Modal: 'Packages',
    });
  }, [history, location.search, trackEvent]);

  useEffect(() => {
    updateSessionStorage('addOns', addOns);
  }, [addOns]);

  const primaryButtonProps = { onClick: handleContinue, disabled: false };

  useEffect(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_CUSTOMIZE_ADDONS_VIEWED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  return (
    <div data-dd-privacy='allow'>
      <Modal
        footer={
          <Footer
            primaryBtnText={t(`footer.primary`)}
            secondaryBtnText={t(`footer.secondary`)}
            primaryButtonProps={primaryButtonProps}
            secondaryOnClick={handleBack}
          />
        }
        stepNumber={2}
      >
        <CustomizeWithAddOns />
      </Modal>
    </div>
  );
};

export default AddOnsPage;
