import { useSignupPageContext } from 'pages/Signup/context';
import { useCallback } from 'react';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils';
import useCalculatePrices from './useCalculatePrices';

const useSignupAmplitudeEvents = () => {
  const { addOns, selectedPackage, alias, candidates, workLocation } =
    useSignupPageContext();
  const trackEvent = useTrackEvent();
  const { subtotal, customPackagePrice, addOnsPrice } = useCalculatePrices();

  const trackAliasOfferedEvent = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ALIAS_OFFERED, {
      'Alias Selected': alias[selectedPackage] === 'true' ? 'Yes' : 'No',
    });
  }, [alias, selectedPackage, trackEvent]);

  const trackAliasRadioButtonClickedEvent = useCallback(
    selection => {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ALIAS_RADIO_BUTTON_CLICKED, {
        Selection: selection === 'true' ? 'Add Alias' : 'Do Not Add Alias',
      });
    },
    [trackEvent],
  );

  const trackSignupOrderBGCPageCompletedEvent = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_PAGE_COMPLETED, {
      'Self Serve Signup Version': 'FOX Signup v2',
      'Candidate Work Location Selected': 'In the USA',
      State: workLocation?.state?.name,
      City: workLocation?.city,
      Country: workLocation?.country ? workLocation?.country?.name : 'USA',
      'Package Selected': selectedPackage,
      'Number Of Candidate Email Entered': candidates.length,
      'Add-on Price': addOnsPrice,
      'Add-on Screenings': addOns[selectedPackage],
      'Add-On Selected': addOns[selectedPackage]?.length > 0,
      'MVR Added': addOns[selectedPackage]?.includes('motor_vehicle_report'),
      'Package Price': parseFloat((customPackagePrice ?? '0').replace('$', '')),
      'Total Order Price': parseFloat((subtotal ?? '0').replace('$', '')),
      'Alias Added': alias[selectedPackage] === 'true' ? 'Yes' : 'No',
    });
  }, [
    addOns,
    addOnsPrice,
    alias,
    candidates.length,
    customPackagePrice,
    selectedPackage,
    subtotal,
    trackEvent,
    workLocation?.city,
    workLocation?.country,
    workLocation?.state?.name,
  ]);

  return {
    trackAliasOfferedEvent,
    trackAliasRadioButtonClickedEvent,
    trackSignupOrderBGCPageCompletedEvent,
  };
};

export default useSignupAmplitudeEvents;
