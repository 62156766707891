import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { Container, SecondaryText, Icon, CustomTooltip } from './styles';

type Props = {
  primaryText: string;
  secondaryText?: string;
  status: 'warning' | 'complete' | 'incomplete' | 'not-started';
  tooltip?: string;
};

const StatusIcon = ({ status }: { status: string }) => {
  if (status === 'complete') {
    return (
      <Icon
        name='CheckmarkFilled'
        size='20'
        fill={colors.uiAqua500}
        data-testid='icon-complete'
      />
    );
  }
  if (status === 'incomplete') {
    return (
      <Icon
        name='CircleDash'
        size='20'
        fill={colors.uiAqua500}
        data-testid='icon-incomplete'
      />
    );
  }
  if (status === 'not-started') {
    return (
      <Icon
        name='CircleDash'
        size='20'
        fill={colors.uiGrey300}
        data-testid='icon-not-started'
      />
    );
  }
  return (
    <Icon
      name='Warning'
      size='20'
      fill={colors.uiOrange500}
      data-testid='icon-warning'
    />
  );
};

const AccordionTitle = ({
  primaryText,
  secondaryText,
  status,
  tooltip,
}: Props) => {
  return (
    <Container>
      <StatusIcon status={status} />
      <h4 className='mb-0'>{primaryText}</h4>
      {secondaryText && (
        <SecondaryText data-testid='accordion-primary-text'>
          {secondaryText}
        </SecondaryText>
      )}
      {tooltip && <CustomTooltip>{tooltip}</CustomTooltip>}
    </Container>
  );
};

export default AccordionTitle;
