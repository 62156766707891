import React from 'react';
import { TaskType } from 'modules/case-management/types';
import {
  Cell,
  Assessment,
  Candidate,
  Completed,
  Owner,
  Package,
  TaskType as TaskTypeCell,
} from '../columns';

type PropTypes = {
  isLoading: boolean;
  tasks: TaskType[];
};

export const generateTaskTableRows: any = ({ isLoading, tasks }: PropTypes) => {
  if (isLoading) return;

  // eslint-disable-next-line consistent-return
  return tasks.map((task: TaskType) => {
    const { report } = task;

    const url = `/candidates/${report?.candidate.id}/reports/${report?.id}`;

    return {
      id: task.id,
      assessment: <Assessment assessment={report?.assessment} url={url} />,
      completed_at: <Completed completedAt={report?.completed_at} url={url} />,
      location: (
        <Cell url={url} testid='cm-ui-tasks-page-table-rows-location'>
          {report?.location}
        </Cell>
      ),
      name: <Candidate candidate={report?.candidate} url={url} />,
      owner: <Owner assignee={task.assignee} taskID={task.id} />,
      package: <Package reportPackage={report?.package} url={url} />,
      type: <TaskTypeCell taskType={task.type} url={url} />,
    };
  });
};
