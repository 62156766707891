class CreditReportUtils {
  private report: any;

  public constructor(report: any) {
    this.report = report;
  }

  public isDomestic(): boolean {
    if (!this.report?.credit_report) return false;

    if (!this.report?.credit_report?.records) return false;

    if (this.report?.credit_report?.records?.length <= 0) return false;

    return (
      this.report?.credit_report?.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name === 'United States',
      ) !== undefined
    );
  }

  public isInternationalHasNonEmptyRecords(): boolean {
    if (!this.report?.credit_report) return false;

    if (!this.report?.credit_report?.records) return false;

    if (this.report?.credit_report?.records?.length <= 0) return false;

    return (
      this.report?.credit_report?.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name && creditRecord.pdf_url,
      ) !== undefined
    );
  }

  public findDomesticRecords(): any {
    if (!this.report?.credit_report) return [];

    if (!this.report?.credit_report?.records) return [];

    if (this.report?.credit_report?.records?.length <= 0) return [];

    return this.report?.credit_report?.records.filter(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        !creditRecord.pdf_url,
    );
  }

  public findInternationalRecords(): any {
    if (!this.report?.credit_report) return undefined;

    if (!this.report?.credit_report?.records) return undefined;

    if (this.report?.credit_report?.records?.length <= 0) return undefined;

    return this.report?.credit_report?.records.find(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        creditRecord.country_name && creditRecord.pdf_url,
    );
  }

  public findInternationalEmptyObject(): any {
    if (!this.report?.credit_report) return undefined;

    if (!this.report?.credit_report?.records) return undefined;

    if (this.report?.credit_report?.records?.length <= 0) return undefined;

    return this.report?.credit_report?.records.find(
      (creditRecord: { country_name: string }) =>
        creditRecord.country_name !== 'United States',
    );
  }
}

export default CreditReportUtils;
