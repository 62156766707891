import React from 'react';
import { InputLabel, AddressGroup } from 'components';
import { useTranslation } from 'react-i18next';
import { PostalAddress, CompanyAddress } from 'types';
import AddressManual from 'components/PostalAddress/AddressManual';

type Props = {
  companyAddress: PostalAddress;
  disabled: boolean;
  handleCompanyAddress: (address: CompanyAddress) => void;
  handlePostalAddress: (address: PostalAddress) => void;
  postalAddress: PostalAddress;
};

const AddressSubsection: React.FC<{
  heading: string;
  description: string;
  address: any;
  disabled: boolean;
  handleAddress: (address: any) => void;
  testIdPrefix: string;
}> = ({
  heading,
  description,
  address,
  disabled,
  handleAddress,
  testIdPrefix,
}) => {
  return (
    <div data-testid={`${testIdPrefix}-section`}>
      <InputLabel data-testid={`${testIdPrefix}-label`}>{heading}</InputLabel>
      <p data-testid={`${testIdPrefix}-description`}>{description}</p>
      <AddressGroup style={{ marginLeft: '0 !important' }}>
        <AddressManual
          address={address}
          disabled={disabled}
          handleAddress={handleAddress}
        />
      </AddressGroup>
    </div>
  );
};

export const AddressSection: React.FC<Props> = ({
  companyAddress,
  disabled,
  handleCompanyAddress,
  handlePostalAddress,
  postalAddress,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AddressSubsection
        heading={t('accountSettings.accountCompanyAddress.heading')}
        description={t('accountSettings.accountCompanyAddress.message')}
        address={companyAddress}
        disabled={disabled}
        handleAddress={handleCompanyAddress}
        testIdPrefix='company-address'
      />
      <AddressSubsection
        heading={t('accountSettings.accountPostalAddress.heading')}
        description={t('accountSettings.accountPostalAddress.message')}
        address={postalAddress}
        disabled={disabled}
        handleAddress={handlePostalAddress}
        testIdPrefix='postal-address'
      />
    </>
  );
};

export default AddressSection;
