import React, { useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import AccountSelectorModal from 'components/MultiAccountManagement/AccountSelector/AccountSelectorModal';
import { getParamFromUrl } from '@dashboard-experience/utils';
import { invalidSessionTokenLogout } from '../../utils';
import { AmsClient } from '../../api';

const mamAccountsFromSessionToken = async (token: string) => {
  try {
    return await AmsClient.post(
      'api/auth0/v2/session_tokens/validate',
      {},
      {
        headers: {
          'X-Session-Token': token,
        },
      },
    );
  } catch (e) {
    invalidSessionTokenLogout();
    return null;
  }
};

const AccountSelectionContainer = () => {
  const sessionToken = getParamFromUrl(window, 'session_token');
  const state = getParamFromUrl(window, 'state');
  const [sessionTokenValues, setSessionTokenValues] = useState({
    accounts: [],
    continueUrl: '/',
  });

  useEffect(() => {
    if (sessionToken && state) {
      (async () => {
        const decoded = await mamAccountsFromSessionToken(sessionToken);

        if (decoded?.accounts && decoded?.continue_uri) {
          setSessionTokenValues({
            accounts: decoded.accounts,
            continueUrl: decoded.continue_uri,
          });
        }
      })();
    } else {
      window.location.replace('/');
    }
  }, [sessionToken, state]);

  if (!sessionTokenValues.accounts) {
    // No MAM info found, either log them in to first account or redirect to login
    window.location.replace(sessionTokenValues.continueUrl);
  }

  return sessionTokenValues.accounts.length === 0 ? (
    <M.LoadingSpinner />
  ) : (
    <AccountSelectorModal
      state={state || ''}
      continueUrl={sessionTokenValues.continueUrl}
      accounts={sessionTokenValues.accounts}
    />
  );
};

export default AccountSelectionContainer;
