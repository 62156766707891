import React from 'react';
import { useList as useNodePackages } from 'api/nodePackage';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import PackageCardList from './PackageCardList';
import {
  getNoPackageFoundMessage,
  getSelectablePackages,
} from './SelectPackageUtils';
import ConditionalErrorRender from './ui/ConditionalErrorRender';
import ConditionalLoadingRender from './ui/ConditionalLoadingRender';
import type { GetStartedT } from '../GetStartedStep';
import type { SelectPackageT } from '.';
import { StyledPaginationContainer } from './styles/SelectPackageStyles';

export const packagesPerPage = 10;

const SelectablePackagesList: React.FC<{
  fetchedPackages: FetchPackageType[];
  count: number;
  hierarchy_present: boolean;
  accountId: string;
  node: GetStartedT.Node;
  localeType: GetStartedT.Locale;
  params: SelectPackageT.Params;
  handlePaginationClick: Function;
  selectedPackage: PostPackageType;
  update: Function;
  isManualBulkOrder: boolean;
  showPrice: boolean;
}> = ({
  fetchedPackages,
  count,
  hierarchy_present,
  accountId,
  node,
  localeType,
  params,
  handlePaginationClick,
  selectedPackage,
  update,
  isManualBulkOrder,
  showPrice,
}) => {
  // This will probably get removed once Backend work can filter by locale and node
  const { data: { packages: nodePackages = [] } = {}, isLoading } =
    useNodePackages({
      account: { hierarchy_present, id: accountId },
      customId: node?.custom_id,
    });

  const selectablePackages = getSelectablePackages(
    fetchedPackages,
    hierarchy_present,
    node,
    nodePackages,
  );

  const noNodePackages =
    (!nodePackages || nodePackages.length < 1) && hierarchy_present;

  return (
    <ConditionalLoadingRender shouldRender={!isLoading}>
      <ConditionalErrorRender
        shouldRender={!noNodePackages && selectablePackages.length > 0}
        error={getNoPackageFoundMessage(hierarchy_present, node, localeType)}
      >
        <PackageCardList
          packages={selectablePackages}
          update={update}
          selectedPackage={selectedPackage}
          isManualBulkOrder={isManualBulkOrder}
          showPrice={showPrice}
        />
        {count > 10 && (
          <StyledPaginationContainer
            data-testid='packages-pagination'
            pageCount={Math.ceil(count / packagesPerPage)}
            selectedIndex={params.page - 1}
            onPageClick={handlePaginationClick}
          />
        )}
      </ConditionalErrorRender>
    </ConditionalLoadingRender>
  );
};

export default SelectablePackagesList;
