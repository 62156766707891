import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useRouteMatch } from 'react-router-dom';
import { useCancelInvitation } from '../../../../../../../api/invitations';
import { useCandidate } from '../../../../../../../providers/Candidate';

type MatchParams = {
  invitationId: string;
};

const CancelInvitationAction = () => {
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationId = invitationMatch?.params?.invitationId;

  let candidateMatch = useRouteMatch<MatchParams>('/candidates/:candidateId');
  // We want to only display this on the invitation and candidate pages
  // The pages (report && invitation) off candidates will match above, but not an exact match
  if (!candidateMatch?.isExact) {
    candidateMatch = null;
  }

  const candidate = useCandidate();
  const { invitations = [] } = candidate;
  const invitation =
    invitations.find(item => item.id === invitationId) || invitations[0];

  const { call: cancelInvitation, result } = useCancelInvitation(
    invitation,
    candidate.id,
  );

  // If we didn't match the 2 routes, do nothing
  if (!invitationMatch && !candidateMatch) return null;
  // No invitations
  if (!invitation) return null;
  // If we have an InvitationId, invitation must match
  if (invitationMatch && invitation.id !== invitationId) return null;

  return (
    <div data-testid='invitation-lifecycle-actions'>
      {!result.isLoading && (
        <M.Button
          data-testid='cancel-invitation-button'
          kind='secondary'
          size='small'
          onClick={cancelInvitation}
        >
          Cancel invitation
        </M.Button>
      )}
      {result.isLoading && <M.LoadingInline description='Canceling...' />}
    </div>
  );
};

export default CancelInvitationAction;
