import { M } from '@dashboard-experience/mastodon';
import { useNestedPreference } from 'api/dashboardPreferences';
import { namespace } from 'modules/candidate/locales';
import moment from 'moment';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Exception } from 'types';
import { getExceptionDocumentTypes } from '../../utils';

const List = styled.ul`
  line-height: 20px;
`;

const ListItem = styled.li`
  list-style-type: disc;
  margin-left: 25px;
  padding-left: 3px;
`;

const StyledLink = styled(M.Link)`
  display: inline !important;
`;

const ResolvedExceptionsBanners = ({
  resolvedExceptions,
}: {
  resolvedExceptions: Exception[];
}) => {
  const candidate = useCandidate();
  const { t } = useTranslation();
  const [showPreference] = useNestedPreference(
    'report',
    'showResolvedExceptions',
  );

  if (!showPreference) return null;

  // TODO: ECDX-679 - replace placeholder banner text and styling with provided banner designs
  return (
    <M.ExpandableNotification
      title={t(`${namespace}:report.exceptions.banners.resolved.title`, {
        firstName: candidate.first_name,
      })}
      kind='warning'
      bodyNode={
        <List>
          {resolvedExceptions.map(exception => (
            <ListItem key={exception.id}>
              {t(
                `${namespace}:report.exceptions.banners.resolved.documentLine`,
                {
                  documentType: getExceptionDocumentTypes(exception),
                  resolvedDate: moment(exception.resolved_at).format(
                    'MMM D, YYYY h:mm A',
                  ),
                },
              )}
              <br />
              <StyledLink href={exception.verification_uri} target='_blank'>
                {t(
                  `${namespace}:report.exceptions.banners.resolvable.showRequest`,
                )}
              </StyledLink>{' '}
              {t(
                `${namespace}:report.exceptions.banners.resolvable.sentToCandidate`,
              )}
            </ListItem>
          ))}
        </List>
      }
      initialExpanded
    />
  );
};

export default ResolvedExceptionsBanners;
