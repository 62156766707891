import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Trans, useTranslation } from 'react-i18next';
import {
  StyledButton,
  SpinnerContainer,
  LoadingSpinner,
} from './PaymentStyledComponents';

type Props = {
  modalOpen: boolean;
  hideModal: Function;
  handleContinueClick: Function;
  isLoading: boolean;
  isError: boolean;
};

const TaxExemptionModal: React.FC<Props> = ({
  modalOpen,
  hideModal,
  handleContinueClick,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation();

  const isDisabled = isLoading || isError;

  return (
    <M.ComposedModal
      type='small'
      open={modalOpen}
      onClose={hideModal}
      data-testid='tax-exemption-modal'
    >
      <M.ModalHeader
        closeModal={hideModal}
        title={t(`tax_exemption_modal.title`)}
      />
      <M.ModalBody>
        <M.Grid>
          <M.GridRow style={{ paddingBottom: '1rem' }}>
            <p>
              <Trans
                i18nKey='tax_exemption_modal.description'
                t={t}
                components={{
                  'avalara-link': (
                    <a
                      href='https://www.avalara.com/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Avalara
                    </a>
                  ),
                }}
              />
            </p>

            <p>
              <Trans
                i18nKey='tax_exemption_modal.footer'
                t={t}
                components={{
                  'checkr-tax-link': (
                    <a
                      href='mailto:tax@checkr.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      tax@checkr.com
                    </a>
                  ),
                }}
              />
            </p>
          </M.GridRow>
          <M.GridRow className='justify-right'>
            <StyledButton
              onClick={handleContinueClick}
              disabled={isDisabled}
              data-testid='tax-exemption-modal-btn'
            >
              {isLoading ? (
                <SpinnerContainer data-testid='loading-spinner'>
                  <LoadingSpinner />
                </SpinnerContainer>
              ) : (
                <>
                  <span>{t(`Continue`)}</span>
                  <span style={{ paddingLeft: '0.25rem' }}>
                    <M.Icon icon='ArrowUpRight' />
                  </span>
                </>
              )}
            </StyledButton>
          </M.GridRow>
        </M.Grid>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default TaxExemptionModal;
