import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import { namespace } from 'modules/adjudication/locales';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { useRequestCandidateStory } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import { ConfirmModal } from '../../../../../components';
import Guard from './guard';
import H3 from '../../common/h3';

const Styles = styled.div`
  margin: 1rem 0rem 0rem !important;
`;

const RequestCandidateStoryButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const { call, result } = useRequestCandidateStory();
  const buttonDisabled =
    report?.candidate_stories?.length > 0 ||
    report?.tags?.includes('candidate-story-requested') ||
    (result.data && !result.isError) ||
    report.archived;
  const [modalOpen, setOpenModal] = useState(false);
  const openConfirmModal = useCallback(() => {
    buttonDisabled || setOpenModal(true);
    if (!buttonDisabled) {
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        {
          Action: 'Request Candidate Story',
          Source: 'Adjudication Adverse Action',
        },
      );
    }
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpenModal, buttonDisabled]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const { t } = useTranslation();
  const basePath = `${namespace}:report.actions.candidate_story`;
  const header = t(`${basePath}.modal.header`);
  const buttonText = t(`${basePath}.action`);
  const tooltipType = buttonDisabled ? 'tooltip-disable' : 'tooltip';
  const tooltipText = t(`${basePath}.${tooltipType}`);
  const modalMessage = <H3>{t(`${basePath}.modal.msg`)}</H3>;
  const disabled = buttonDisabled ? 'disabled' : '';

  return (
    <Styles>
      <Guard>
        <M.TooltipButton
          label={tooltipText}
          align='bottom'
          kind='secondary'
          className={disabled}
          id='request-story-button'
          onClick={openConfirmModal}
          fullWidth
          data-testid='request-story-button'
        >
          {buttonText}
        </M.TooltipButton>
        {/* @ts-ignore */}
        <ConfirmModal
          className='actions-modal'
          header={header}
          open={modalOpen}
          hideModal={hideConfirmModal}
          callback={handleConfirm}
          message={modalMessage}
        />
      </Guard>
    </Styles>
  );
};
export default RequestCandidateStoryButton;
