import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const OutOfCountrySearch = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}: ScreeningProps) => {
  return (
    <FlexContainer data-testid='out-of-country-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-out-of-country-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Out-of-country criminal history'
            priceByType='international_criminal_search_v2'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Use this more comprehensive search for candidates who&apos;ve lived
            outside the US.
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text="Use for candidates who'll work in the US and have lived outside the US." />
              <BulletPoint text='Search types include criminal and adverse media searches, and education and employment verifications.' />
              <BulletPoint text='Help maintain compliance, mitigate risk, and make informed hiring decisions.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Longer turnaround compared to US searches.' />
              <BulletPoint text='Candidates might need to provide more documents.' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_criminal_search_v2'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default OutOfCountrySearch;
