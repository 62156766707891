import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { STRIPE_KEY } from 'Constants';
import { StyledErrorInlineNotification } from './PaymentStyledComponents';
import AddPaymentForm from './AddPaymentForm';
import useSetClientSecret from './utils/stripeHelpers';

const AddPaymentModalBody: React.FC<{
  error: any;
  setError: any;
  processing: boolean;
  setIsProcessing: Function;
  elementOptions: any;
  setElementOptions: any;
  paymentType: string;
  hideModal: any;
  postPaymentMethod: any;
  isOrder?: boolean;
}> = ({
  error,
  setError,
  processing,
  setIsProcessing,
  elementOptions,
  setElementOptions,
  paymentType,
  hideModal,
  postPaymentMethod,
  isOrder,
}) => {
  const stripePromise = loadStripe(STRIPE_KEY!);

  useSetClientSecret({
    setElementOptions,
    elementOptions,
    setIsProcessing,
    setError,
    paymentType,
  });

  return (
    <>
      {error && (
        <StyledErrorInlineNotification kind='error' hideCloseButton>
          {error}
        </StyledErrorInlineNotification>
      )}
      {!processing && elementOptions && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: elementOptions }}
        >
          <AddPaymentForm
            hideModal={hideModal}
            setError={setError}
            setElementOptions={setElementOptions}
            postPaymentMethod={postPaymentMethod}
            isOrder={isOrder}
          />
        </Elements>
      )}
    </>
  );
};

export default AddPaymentModalBody;
