import React, { useCallback } from 'react';
import { GenericObject, Report } from 'types';
import { useLogPDFDownload } from 'api/documents';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

type PdfLinkProps = {
  item: GenericObject;
  report: Report;
  closeWithAction: Function;
  showPdfEncryptionNote?: boolean;
};

const PdfLink: React.FC<PdfLinkProps> = ({
  item,
  report,
  closeWithAction,
  showPdfEncryptionNote = false,
}) => {
  const { docType, type, link, text, isPdfReport, disabled } = item;
  const { id } = report;
  const isConsent = docType === 'consent';

  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const trackPdfLink = useCallback(() => {
    closeWithAction();

    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Document Selected': item.text,
        Report: reportProperties,
      });
    }
  }, [closeWithAction, currentUser, item.text, reportProperties, trackEvent]);

  const { call: logDownload } = useLogPDFDownload({
    reportId: id,
    type: isPdfReport ? type.replace('pdf_', '') : docType,
  });

  const handleClick = useCallback(() => {
    window.open(link, '_blank');
    if (isConsent) {
      trackPdfLink();
    } else {
      trackPdfLink();
      logDownload();
    }
  }, [isConsent, trackPdfLink, logDownload, link]);

  const showItem = item && (isConsent || !disabled);

  const encryptionNote =
    'Report PDF is password protected with the last four digits of the candidate’s SSN';

  const showEncryptionNote = showPdfEncryptionNote && type === 'pdf_report';

  return showItem ? (
    <M.ActionMenuItem
      title={text}
      kind='node'
      actionNode={<M.Icon icon='Download' size='16' />}
      onClick={handleClick}
      description={showEncryptionNote ? encryptionNote : null}
    />
  ) : null;
};

export default PdfLink;
