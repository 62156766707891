import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  validateEmail,
  validateFirstName,
  validatePhoneNumber,
} from 'components/AddScreenings/shared/ModalComponents';
import { useHandlePasteForInviteForm } from '../../../../../hooks';
import InviteEntry from './InviteEntry';
import { Invite, emptyInvite } from '../../SharedItems';
import { useOrderBackgroundCheckContext, actionTypes } from '../../Context';

const InviteContainer = styled.div`
  padding-top: 32px;
`;

const InvitesContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.uiGrey200};
  background: ${colors.uiGrey0};
  padding: 16px;
  gap: 24px;
  width: 100%;
  margin-top: 12px;
`;

const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid ${colors.uiGrey200};
  width: 100%;
  margin: 16px 0;
`;

const StyledText = styled.p`
  && {
    color: ${colors.uiTextPrimaryLight};
    font-size: 12px !important;
    line-height: 100% !important;
  }
`;

const InviteEntryContainer = ({ maxEmails }: { maxEmails: number }) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const handlePaste = useHandlePasteForInviteForm(state, dispatch);

  const handleAddInvite = useCallback(() => {
    if (state.invites.length >= maxEmails) return;

    const newInvite: Invite = emptyInvite();
    dispatch({
      type: actionTypes.ADD_INVITES,
      payload: [...state.invites, newInvite],
    });
  }, [state, dispatch, maxEmails]);

  const handleRemoveInvite = useCallback(
    id => {
      const newInvites = state.invites.filter(invite => invite.id !== id);
      if (newInvites.length === 0) {
        newInvites.push(emptyInvite());
      }

      dispatch({
        type: actionTypes.ADD_INVITES,
        payload: newInvites,
      });
    },
    [state, dispatch],
  );

  const handleEmailChange = useCallback(
    (e, inviteId) => {
      const newInvites: Invite[] = state.invites.map(invite => {
        if (invite.id === inviteId) {
          const { name, value } = e.target;
          return {
            ...invite,
            [name]: {
              value,
              isValid: validateEmail(value),
            },
          };
        }
        return invite;
      });
      dispatch({
        type: actionTypes.ADD_INVITES,
        payload: newInvites,
      });
    },
    [state, dispatch],
  );

  const handleNameChange = useCallback(
    (e, inviteId) => {
      const newInvites: Invite[] = state.invites.map(invite => {
        if (invite.id === inviteId) {
          const { name, value } = e.target;
          return {
            ...invite,
            [name]: {
              value,
              isValid: validateFirstName(value, true),
            },
          };
        }
        return invite;
      });
      dispatch({
        type: actionTypes.ADD_INVITES,
        payload: newInvites,
      });
    },
    [state, dispatch],
  );

  const handlePhoneNumberChange = useCallback(
    (e, inviteId) => {
      const newInvites: Invite[] = state.invites.map(invite => {
        if (invite.id === inviteId) {
          const { name, value } = e.target;
          return {
            ...invite,
            [name]: {
              value,
              isValid: validatePhoneNumber(value, true),
            },
          };
        }
        return invite;
      });
      dispatch({
        type: actionTypes.ADD_INVITES,
        payload: newInvites,
      });
    },
    [state, dispatch],
  );

  return (
    <InviteContainer data-testid='invite-container' onPaste={handlePaste}>
      <StyledText>Enter manually or paste up to {maxEmails}.</StyledText>
      <InvitesContainer>
        <EntryContainer>
          {state.invites.map((invite, index) => (
            <React.Fragment key={`invite-${invite.id}`}>
              <InviteEntry
                index={index}
                invites={state.invites}
                handleEmailChange={handleEmailChange}
                handleNameChange={handleNameChange}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleRemove={handleRemoveInvite}
              />
              {index < state.invites.length - 1 && <StyledHr />}
            </React.Fragment>
          ))}
        </EntryContainer>
        <M.Button
          kind='tertiary'
          onClick={handleAddInvite}
          data-testid='add-another-candidate-button'
          size='sm'
          style={{ position: 'relative', left: 0, bottom: 0, border: 'none' }}
        >
          <M.Icon icon='Add' /> Add another candidate
        </M.Button>
      </InvitesContainer>
    </InviteContainer>
  );
};

export default InviteEntryContainer;
