import { useCallback } from 'react';
import { useMutation, queryCache, AnyQueryKey, useQuery } from 'react-query';
import { sortBy, last, groupBy, some, map } from 'lodash';
import moment from 'moment';
import {
  ADJUDICATION_KINDS,
  GenericObject,
  hasPermission,
  getTimeDelta,
} from '@dashboard-experience/utils';
import {
  sendCompleteReport,
  fetchAdverseActions,
  fetchPreAdverseActionNoticeTemplate,
  fetchAdverseActionTemplates,
  fetchAdversibleItems,
  fetchExpungements,
  fetchIndividualizedAssessmentDocuments,
  fetchPreAdverseActionNoticePreview,
  updateAdverseActionReassessment,
  uploadIndividualizedAssessment,
  BaseAAPayload,
  PostAAPayload,
  cancelAdverseAction,
  requestCandidateStory,
  postAdverseAction,
  pauseAdverseAction,
  resumeAdverseAction,
  setReportTask,
  patchAdverseAction,
  restartAdverseAction,
  TemplateParams,
} from 'modules/adjudication/api';
import { useUser } from 'context/CurrentUser';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useModalState } from 'modules/adjudication/ui/adverse-action/initiate/flow/context';
import { toastSuccess, toastError } from 'actions';
import { setProcessingStatus } from 'utils';
import { useGetReport } from 'api/reports';
import { AdverseAction } from 'types';
import { useErrorNotifier } from 'hooks';
import { ChargeSeverity } from '../../assess/models';
import {
  formatReportGeos,
  formatCandidateLocation,
  formatReportTags,
  formatReportWorkLocations,
} from '../utilities';

import { unengage, set, SetAdjudicationPayload } from './adjudications';
import { get, AdjudicationSubtype } from './subtypes';
import { namespace } from '../locales';
import { AdversableItem, AdversibleItemsPayload } from '../data';
import { ReportTaskType } from '../ui/report-tasks/types';

export const useSetAdjudication = (reportId: string, candidateId: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const request = (params: SetAdjudicationPayload) => set(reportId, params);

  const [call, result] = useMutation(request, {
    onSuccess: (_data, variables) => {
      const { adjudication } = variables;
      const msg = t(`${namespace}:report.actions.${adjudication}.success`);
      dispatch(toastSuccess(msg));

      if (adjudication === ADJUDICATION_KINDS.ENGAGED)
        setProcessingStatus({
          candidateId,
          timeStamp: Date.now(),
        });

      queryCache.invalidateQueries('adjudication-events');
      queryCache.invalidateQueries('reports/multi-mvr');
    },
  });

  return {
    call,
    result,
  };
};

export const useUnengage = (reportId: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const request = () => unengage(reportId);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      const msg = t(`${namespace}:report.actions.change.success`);
      dispatch(toastSuccess(msg));

      queryCache.invalidateQueries('reports/multi-mvr');
    },
    onError: () => {
      const msg = t(`${namespace}:report.actions.change.error`);
      dispatch(toastError(msg));
    },
  });

  return {
    call,
    result,
  };
};

export const useAdverseActions = (reportId: string) => {
  const key: AnyQueryKey = ['adverse-actions', { id: reportId }];
  const currentUser = useUser();

  const request = () => fetchAdverseActions(reportId);

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled:
      // Only make the call if we have an ID to work with and if the user has AT LEAST one of these permissions
      !!reportId &&
      (hasPermission(currentUser, 'perform_adjudication') ||
        hasPermission(currentUser, 'read_adverse_actions')),
    staleTime: 60000,
  });

  const adverseActions = sortBy(result?.data?.data, (event) =>
    moment(event.created_at).unix(),
  );
  const adverseAction = last(adverseActions);

  if (adverseAction?.pause_data?.time) {
    const duration = getTimeDelta(adverseAction.pause_data.time, Date.now());
    adverseAction.pause_data.duration = duration.humanize();
  }

  const payload = {
    adverseActions,
    adverseAction,
    isLoading: result?.isLoading,
    isError: result?.isError,
  };

  return payload;
};

export const useCancelAdverseAction = () => {
  const { report } = useGetReport();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const adverseAction = useAdverseActions(report?.id);
  const adverseActionId = adverseAction.adverseAction?.id;
  const request = () => cancelAdverseAction(adverseActionId);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess(t(`${namespace}:report.actions.cancel.success`)));

      queryCache.invalidateQueries('adjudication-events');
      queryCache.invalidateQueries('reports/multi-mvr');
      queryCache.invalidateQueries('adverse-actions');
    },
    onError: () => {
      dispatch(toastError(t(`${namespace}:report.actions.cancel.error`)));
    },
  });

  return {
    call,
    result,
  };
};

export const useGetAdjudicationSubtypes = () => {
  const { report } = useGetReport();

  const subtypesEnabled = report?.account?.adjudication_subtypes_enabled;
  const engagedDefaultSubtype = {
    kind: ADJUDICATION_KINDS.ENGAGED,
    name: 'Engage',
    slug: null,
  } as AdjudicationSubtype;
  const adverseActionDefaultSubtype = {
    kind: ADJUDICATION_KINDS.ADVERSE_ACTION,
    name: 'Adverse Action',
    slug: null,
  } as AdjudicationSubtype;
  const accountId = report?.account?.id;
  const key: AnyQueryKey = ['adjudication-subtypes', { id: report?.id }];

  const request = () => get(accountId);

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled: subtypesEnabled,
  });

  const isEngaged = (sub: AdjudicationSubtype) =>
    sub.kind === ADJUDICATION_KINDS.ENGAGED;

  const isAdverseAction = (sub: AdjudicationSubtype) =>
    sub.kind === ADJUDICATION_KINDS.ADVERSE_ACTION;

  const isDeferred = (sub: AdjudicationSubtype) =>
    sub.kind === ADJUDICATION_KINDS.DEFERRED;

  if (subtypesEnabled && !result.isLoading) {
    const adjudicationSubtypes = [
      engagedDefaultSubtype,
      ...(result?.data || []),
    ];

    const adverseActionSubtypes = adjudicationSubtypes.filter(isAdverseAction);
    adverseActionSubtypes.unshift(adverseActionDefaultSubtype);

    return {
      adjudicationSubtypes,
      engagedSubtypes: adjudicationSubtypes.filter(isEngaged),
      adverseActionSubtypes,
      deferredSubtypes: adjudicationSubtypes.filter(isDeferred),
    };
  }

  // TODO: Setting steps for adverseActionsSubtype

  return {
    adjudicationSubtypes: [engagedDefaultSubtype],
    engagedSubtypes: [engagedDefaultSubtype].filter(isEngaged),
    adverseActionSubtypes: [adverseActionDefaultSubtype].filter(
      isAdverseAction,
    ),
    deferredSubtypes: [engagedDefaultSubtype].filter(isDeferred),
    isLoading: result.isLoading,
  };
};

export const useFetchAdverseActionTemplates = () => {
  const { report } = useGetReport();
  const { geos, id, tags, work_locations } = report;
  const { basis } = useModalState('state');
  const params: TemplateParams = {};

  const aaByJurisdictionEnabled =
    report?.account?.adverse_action_by_jurisdiction;
  const aaSuspendedReport = report?.account?.adverse_action_report_suspended;

  if (aaSuspendedReport) Object.assign(params, { basis });

  const key: AnyQueryKey = ['adverse-action-templates', { id }, { params }];
  const request = () => fetchAdverseActionTemplates(id, params);

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !aaSuspendedReport || (aaSuspendedReport && basis),
  });

  const { data, isLoading, isError, error } = result;

  let showCandidateDetails = false;

  let templates = null;

  if (aaByJurisdictionEnabled) {
    templates = {
      pre: {
        selected: null as number | null | undefined, // ID
        options: data?.pre || [],
      },
      post: {
        selected: null as number | null | undefined, // ID
        options: data?.post || [],
      },
    };

    if (data?.pre?.length === 1) {
      templates.pre.selected = data.pre[0].id;
    }

    if (data?.post?.length === 1) {
      templates.post.selected = data.post[0].id;
    }
  }

  if (
    aaByJurisdictionEnabled &&
    ((data?.pre && data?.pre?.length > 1) ||
      (data?.post && data?.post?.length > 1))
  ) {
    showCandidateDetails = true;
  }

  const candidateLocation = {
    zipCode: report?.candidate?.zipcode,
    location: formatCandidateLocation(data?.candidate_locations),
  };

  const reportDetails = {
    assessment: report?.assessment?.display?.decision,
    geo: geos && formatReportGeos(geos),
    tags: formatReportTags(tags),
    workLocations: work_locations
      ? formatReportWorkLocations(work_locations)
      : [],
  };

  return {
    candidateLocation,
    isError,
    isLoading,
    reportDetails,
    showCandidateDetails,
    templates,
    error,
  };
};

export const useSetNewAdverseAction = () => {
  const { report } = useGetReport();
  const { t } = useTranslation();

  const { data, isLoading } = useFetchPreAdverseActionNoticeTemplate();

  const Severities = {
    ...ChargeSeverity,
    other: 'other',
  };

  const newAdverseAction = {
    selectedAdverseItems: [],
  } as GenericObject;

  newAdverseAction.adversibleItems = data?.adversible_items;

  newAdverseAction.bases = data?.bases;

  // group by severity
  const showAdversibleItemsGroupedBySeverity =
    report?.account?.adverse_action_ui_items_group_by_type;

  if (showAdversibleItemsGroupedBySeverity) {
    const itemsBySeverity = groupBy(
      newAdverseAction.adversibleItems,
      (item: GenericObject) => {
        const category = (item.charge_type_classification || '').toLowerCase();
        const isInCategory = Severities[category as keyof typeof Severities];
        return item.criminal && isInCategory && category
          ? category
          : Severities.other;
      },
    );

    newAdverseAction.adversibleItemsPerChargeType = Object.values(Severities)
      .map((name) => {
        const items = itemsBySeverity[name] || [];
        const translated_category = t(
          `${namespace}:report.charge_severity_category.${name}`,
        );
        const display = translated_category;
        return { name, display, items };
      })
      .filter((group) => group.items.length);
  }

  newAdverseAction.filteredAdversibleItems = data?.filtered_adversible_items;

  newAdverseAction.daysToPostAdverseAction =
    data?.post_adverse_action_wait_time;

  const anyAdversibleItem =
    some(newAdverseAction.adversibleItems) ||
    some(newAdverseAction.filteredAdversibleItems);

  const candidateDob = report?.candidate?.dob;

  const FCI_FLOW_ENABLED =
    data?.individualized_assessment_show_type === 'required';
  const OPTIONAL_FCI_FLOW_ENABLED =
    data?.individualized_assessment_show_type === 'optional';

  // TODO: Setting steps for adverse items
  // Do not set current step if just preLoading
  // if (!$scope.preLoadSelectionScreenItems) {
  //   $scope.currentStep = SCREENS.ADVERSE_ITEMS;
  // }

  return {
    newAdverseAction,
    anyAdversibleItem,
    candidateDob,
    FCI_FLOW_ENABLED,
    OPTIONAL_FCI_FLOW_ENABLED,
    showAdversibleItemsGroupedBySeverity,
    isLoading,
  };
};

export const useFetchPreAdverseActionNoticeTemplate = () => {
  const { report } = useGetReport();
  const { id } = report;

  const errorNotifier = useErrorNotifier();

  const key: AnyQueryKey = ['adverse-action-template', { id }];
  const request = () => fetchPreAdverseActionNoticeTemplate(id);

  const { data, isLoading } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    onError: (error) =>
      errorNotifier(error, {
        title: `Error: Unable to retrieve adverse action template`,
      }),
  });

  return { data, isLoading };
};

export const useFetchAdversibleItems = () => {
  const { report } = useGetReport();
  const { id } = report;
  const adverseActions = useAdverseActions(id);
  const adverseItems = adverseActions.adverseAction?.adverse_items;

  const key: AnyQueryKey = ['adversible-items', { id }];
  const request = () => fetchAdversibleItems(id);

  // TODO: verify if we should add staleTime to optimize API calls.
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  const { data } = result;

  const adverseItemText = map(adverseItems, 'text');
  const charges = data?.filter((item: AdversibleItemsPayload) =>
    adverseItemText?.includes(item.text),
  );

  return {
    charges,
    ...result,
  };
};

export const useFetchExpungements = (adverseItem: AdversableItem) => {
  const key: AnyQueryKey = ['expungements', { adverseItem }];
  const params = {
    charge_id: adverseItem.adversible_id,
  };
  const request = () => fetchExpungements(params);

  // TODO: verify if we should add staleTime to optimize API calls.
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });

  const { data } = result;
  const expungement = data?.data[0];

  return expungement?.status;
};

export const useFetchIndividualizedAssessmentDocuments = () => {
  const { report } = useGetReport();
  const { id } = report;

  const key: AnyQueryKey = ['individualized-assessment-documents', { id }];
  const request = () =>
    fetchIndividualizedAssessmentDocuments({ report_id: id });

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });

  return result;
};

export const useFetchPreAdverseActionNoticePreview = () => {
  const { report } = useGetReport();
  const request = (params: BaseAAPayload) =>
    fetchPreAdverseActionNoticePreview(report.id, params);

  const [call, result] = useMutation(request);

  return {
    call,
    result,
  };
};

export const usePostAdverseAction = (nextScreen: () => void) => {
  const { report } = useGetReport();
  const request = (params: Omit<PostAAPayload, 'report_id'>) =>
    postAdverseAction({ report_id: report?.id, ...params });

  const [postAA, postAAResult] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries('adjudication-events');
      queryCache.invalidateQueries('adverse-actions');
      queryCache.invalidateQueries([
        'reports/multi-mvr',
        { reportId: report.id },
      ]);
      nextScreen && nextScreen();
    },
  });

  return {
    postAA,
    postAAResult,
  };
};

export const useUploadIndividualizedAssessment = (callback?: () => void) => {
  const { report } = useGetReport();
  const request = (file: GenericObject) => {
    const newFile = {
      bucket: file.container,
      content_type: file.mimetype,
      filesize: file.originalFile.size,
      path: file.key,
      type: 'individualized_assessment',
    };
    return uploadIndividualizedAssessment(report.id, newFile);
  };

  const [uploadAssessment, uploadAssessmentResult] = useMutation(request, {
    onSuccess: () => {
      callback && callback();
    },
  });

  return {
    uploadAssessment,
    uploadAssessmentResult,
  };
};

export const useSendCompleteReport = () => {
  const { report } = useGetReport();
  const { id } = report;

  const request = () => sendCompleteReport(id);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      // clear cache and refetch report
      queryCache.invalidateQueries('reports/multi-mvr');
    },
  });

  return {
    call,
    result,
  };
};

export const useRequestCandidateStory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { report } = useGetReport();
  const { id } = report;

  const request = () => requestCandidateStory(id);

  const [call, result] = useMutation(request, {
    onSuccess: (_data) => {
      const msg = t(`${namespace}:report.actions.candidate_story.success`);
      dispatch(toastSuccess(msg));
    },
  });

  return {
    call,
    result,
  };
};

export const usePauseAdverseAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { report } = useGetReport();
  const adverseAction = useAdverseActions(report?.id);
  const adverseActionId = adverseAction.adverseAction?.id;

  const request = () => pauseAdverseAction(adverseActionId);

  const [call, result] = useMutation(request, {
    onError: (err) => {
      dispatch(
        toastError(t(`${namespace}:report.actions.pause.error`), err.message),
      );
    },
    onSuccess() {
      const msg = t(`${namespace}:report.actions.pause.success`);
      dispatch(toastSuccess(msg));
      queryCache.invalidateQueries('adverse-actions');
    },
  });

  return {
    call,
    result,
  };
};

export const useReportTasksAction = (
  id: string,
  callback: (task: ReportTaskType) => void,
  task?: ReportTaskType,
) => {
  const dispatch = useDispatch();
  const params = {
    kind: task?.kind || 'ActionRequired',
    value: !task?.value,
  };
  const request = () => setReportTask(id, params);

  const [call, result] = useMutation(request, {
    onError: () => {
      dispatch(toastError('Something went wrong'));
    },
    onSuccess: (res) => {
      callback(res);
    },
  });

  return {
    call,
    result,
  };
};

export const useResumeAdverseAction = () => {
  const dispatch = useDispatch();
  const { report } = useGetReport();
  const adverseAction = useAdverseActions(report?.id);
  const adverseActionId = adverseAction.adverseAction?.id;

  const request = () => resumeAdverseAction(adverseActionId);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries('adverse-actions');
    },
    onError: (err) => {
      // @ts-ignore
      dispatch(toastError('Error:', err.response.data.error));
    },
  });

  return {
    call,
    result,
  };
};

export const useRestartAdverseAction = (adverseActionId?: string) => {
  const dispatch = useDispatch();

  const request = () => restartAdverseAction(adverseActionId);
  const [restartCall, restartResult] = useMutation(request, {
    onError: (err) => {
      dispatch(toastError('Error:', err.message));
    },
    onSuccess: () => {
      dispatch(toastSuccess('Success', `Email has been sent`));
    },
  });

  return {
    restartCall,
    restartResult,
  };
};

export const usePatchAdverseAction = (adverseAction: AdverseAction) => {
  const { id } = adverseAction;
  const dispatch = useDispatch();

  const request = () => patchAdverseAction(id);

  const [patchCall, patchResult] = useMutation(request, {
    onError: (err) => {
      dispatch(toastError('Error:', err.message));
    },
    onSuccess: (data) => {
      dispatch(toastSuccess('Success', 'Adverse Action marked as Delivered'));
    },
  });

  return {
    patchCall,
    patchResult,
  };
};

export const useUpdateReassessment = () => {
  const dispatch = useDispatch();

  const update = ({ adverseActionId, params }: any) =>
    updateAdverseActionReassessment({ adverseActionId, params });

  const onUploadSuccess = useCallback(() => {
    dispatch(
      toastSuccess(
        'File uploaded successfully',
        'New assessment document updaded',
      ),
    );
    queryCache.invalidateQueries('reports/multi-mvr');
  }, []);

  const { uploadAssessment, uploadAssessmentResult } =
    useUploadIndividualizedAssessment(onUploadSuccess);

  const [updateReassessment, updateReassessmentResult] = useMutation(update, {
    onSuccess: () => {
      dispatch(toastSuccess('Success', 'Reassessment updaded'));
      queryCache.invalidateQueries('adverse-actions');
    },
  });

  return {
    updateReassessment,
    uploadAssessment,
    updateReassessmentResult,
    uploadAssessmentResult,
    isLoading:
      updateReassessmentResult.isLoading || uploadAssessmentResult.isLoading,
    isError: updateReassessmentResult.isError || uploadAssessmentResult.isError,
  };
};
