import { M } from '@dashboard-experience/mastodon';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import { scrollToElement } from 'utils';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

const OrderNewReportAction = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const history = useHistory();
  const trackEvent = useTrackEvent();

  const scrollToOrderSection = () => {
    const orderNewWrap = document.getElementById('order-new-wrapper');
    const orderNewElOffset = orderNewWrap?.offsetTop;

    if (orderNewElOffset) {
      window.scrollTo({
        top: orderNewElOffset,
        behavior: 'smooth',
      });
      scrollToElement(orderNewElOffset);
    }
  };

  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const orderNewReport = useCallback(() => {
    if (currentUser) {
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_ORDER_NEW_REPORT_SELECTED,
        {
          Source: 'Report Actions',
          Report: reportProperties,
        },
      );
    }

    const path = `/candidates/${candidate.id}/new`;
    history.push(path);
    scrollToOrderSection();
  }, [currentUser, candidate.id, history, trackEvent, reportProperties]);

  return (
    <M.ActionMenuItem
      title='Order new check'
      kind='node'
      actionNode={<M.Icon icon='TaskAdd' size='16' />}
      onClick={orderNewReport}
    />
  );
};

export default OrderNewReportAction;
