import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from 'actions';
import { ICohortDetail } from 'components/RandomTestingProgram/Cohort/types';

import { IPollDetail } from 'components/RandomTestingProgram/Poll/types';

import {
  deleteCohort,
  NewCohortParams,
  createCohort,
  getCohortDetails,
  getCohorts,
  getCohortCsv,
  getRandomPollDetails,
  createRandomSelectionPoll,
  NewRandomSelectionPollParams,
  NewRandomSelectionOrderParams,
  createRandomSelectionReportOrder,
  AlternateCandidateProps,
  selectAlternateCandidates,
  editCohort,
  EditCohortParams,
  updatePollCandidate,
  UpdateCandidateProps,
} from './actions';
import { convertCaseInArray, convertCaseInObject } from './utils';

const genericError = 'Something went wrong, please try again later.';

export const useGetCohorts = () => {
  const request = () => getCohorts();

  const { data, isLoading, isError, refetch } = useQuery(
    'randomTestingCohorts',
    request,
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: data ? convertCaseInArray(data) : data,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetCohortDetails = (id: string) => {
  const request = () => getCohortDetails(id);

  const { data, isLoading, isError, refetch } = useQuery(
    `randomTestingCohorts_${id}`,
    request,
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: data ? convertCaseInObject<ICohortDetail>(data) : data,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetCohortCsv = (id: number) => {
  const dispatch = useDispatch();
  const request = () => getCohortCsv(id);

  return useMutation(request, {
    onError: () => {
      dispatch(toastError('error', genericError));
    },
  });
};

export const useDeleteCohort = (id: string) => {
  const dispatch = useDispatch();
  const request = () => deleteCohort(id);

  const [mutate, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Pool has been deleted'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      let errorMessage: string = '';
      if (errorData.error) {
        errorMessage = errorData.error;
      } else {
        errorMessage = errorData.errors.join(', ');
      }

      dispatch(
        toastError(
          'Failed to delete pool. Please try again later',
          errorMessage,
        ),
      );
    },
  });

  const { isLoading, error } = result;

  return {
    mutate,
    data: result,
    isLoading,
    error,
  };
};

export const useCreateCohort = () => {
  const dispatch = useDispatch();
  const request = (params: NewCohortParams) => createCohort(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Pool has been created'));
    },
    onError: (error: any) => {},
  });

  return {
    call,
    result,
  };
};

export const useEditCohort = () => {
  const dispatch = useDispatch();
  const request = (params: EditCohortParams) => editCohort(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Pool has been updated.'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      const errorMessage =
        errorData?.message ||
        errorData?.error ||
        errorData?.errors?.join(', ') ||
        'Unknown error';

      dispatch(
        toastError(
          'There was an error updating your pool. Please try again later and contact support if the issue persists.',
          errorMessage,
        ),
      );
    },
  });

  return { call, result };
};

export const useCreateRandomSelectionPoll = () => {
  const dispatch = useDispatch();
  const request = (params: NewRandomSelectionPollParams) =>
    createRandomSelectionPoll(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Random Poll has been created'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      let errorMessage: string = '';
      if (errorData.error) {
        errorMessage = errorData.error;
      } else {
        errorMessage = errorData.errors.join(', ');
      }

      dispatch(toastError('Failed to create random poll', errorMessage));
    },
  });

  return {
    call,
    result,
  };
};

export const useGetRandomPollDetails = (id: number, shouldQuery: boolean) => {
  const dispatch = useDispatch();
  const request = () => getRandomPollDetails(id);

  const { data, isLoading, isError, refetch } = useQuery(
    `randomPollDetails_${id}`,
    request,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        dispatch(toastError('error', genericError));
      },
      enabled: shouldQuery,
    },
  );

  return {
    data: data ? convertCaseInObject<IPollDetail>(data) : data,
    isLoading,
    isError,
    refetch,
  };
};

export const useCreateRandomSelectionReportOrder = () => {
  const dispatch = useDispatch();
  const request = (params: NewRandomSelectionOrderParams) =>
    createRandomSelectionReportOrder(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Random report has been ordered'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      let errorMessage: string = '';
      if (errorData.error) {
        errorMessage = errorData.error;
      } else {
        errorMessage = errorData;
      }

      dispatch(toastError('Failed to create report orders', errorMessage));
    },
  });

  return {
    call,
    result,
  };
};

export const useSelectAlternates = () => {
  const dispatch = useDispatch();
  const request = (params: AlternateCandidateProps) =>
    selectAlternateCandidates(params);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Alternate candidate(s) have been selected.'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      let errorMessage: string = '';
      if (errorData?.error) {
        errorMessage = errorData.error;
      } else {
        errorMessage = errorData;
      }

      dispatch(toastError('Failed to select alternate(s)', errorMessage));
    },
  });

  const { isLoading, isError } = result;

  return { call, result, isLoading, isError };
};

export const useUpdateCandidate = () => {
  const dispatch = useDispatch();
  const request = (params: UpdateCandidateProps) => updatePollCandidate(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Candidate has been updated.'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      const errorMessage =
        errorData?.message ||
        errorData?.error ||
        errorData?.errors?.join(', ') ||
        'Unknown error';

      dispatch(
        toastError(
          'There was an error updating your poll. Please try again later and contact support if the issue persists.',
          errorMessage,
        ),
      );
    },
  });

  return { call, result };
};
