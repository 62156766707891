import React from 'react';
import styled from 'styled-components';
import { HelpFilledIconWithTooltip } from 'components/Packages/OrderBackgroundCheck/SharedItems';
import { getDollarAmount } from '../../AddScreenings/shared/utils';

const DisplayAliasPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisplayAliasPrice: React.FC<{
  price: string;
  onTooltipClick?: Function;
}> = ({ price, onTooltipClick = () => {} }) => {
  const definition =
    'This package already includes aliases. The price for aliases reflects the total cost for all applicable checks, including add-ons.';
  return (
    <DisplayAliasPriceContainer data-testid='display-alias-price-container'>
      <div>
        Aliases
        <HelpFilledIconWithTooltip
          definition={definition}
          align='bottom-left'
          onClick={onTooltipClick}
        />
      </div>
      <div>{getDollarAmount(price)}</div>
    </DisplayAliasPriceContainer>
  );
};

export default DisplayAliasPrice;
