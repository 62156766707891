import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { Invitation } from 'types/report/invitation';
import { ExpandablePanel, KeyValueList } from '../styles';

interface InvitationStatusProps {
  invitation: Invitation;
}

const InvitationStatus: React.FC<InvitationStatusProps> = ({ invitation }) => {
  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.general-details.title`);

  const invitationStatus = useMemo(
    () => capitalize(invitation?.status),
    [invitation?.status],
  );

  const items = useMemo(
    () => [{ itemKey: 'Status', itemValue: invitationStatus }],
    [invitationStatus],
  );

  return invitation && invitationStatus ? (
    <ExpandablePanel
      data-pendo-id='invitation-information-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList data-testid='overview-invitation-status' items={items} />
    </ExpandablePanel>
  ) : null;
};

export default InvitationStatus;
