import { M, colors } from '@dashboard-experience/mastodon';
import {
  AdjudicationStatuses,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { useAdverseActions } from 'modules/adjudication/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Report } from 'types';

import { CANDIDATE_REDESIGN, DATE_FORMAT } from 'Constants';
import { useShowCompleteNow } from 'modules/adjudication/utilities';
import moment from 'moment';
import { useBetaEnabled } from 'providers/Beta';
import { namespace } from '../../../locales';
import AdverseActionEventsTimeline from '../events';
import Actions from './actions';
import Tooltip from './tooltip';

const AdverseActionPanelHeading = styled(M.Container.Row)`
  height: 48px;
  display: flex;
  color: ${colors.uiGrey800};
  padding: 0 30px !important;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  h3 {
    color: ${colors.uiGrey700};
    display: inline;
  }

  .flex {
    padding-top: 1.5rem !important;
    display: flex;
    align-items: self-start;
  }
`;

const ArchivedNote = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  color: ${colors.darkGray100};
`;

const AdverseAction: React.FC<{ report: Report }> = ({ report }) => {
  const { adverseAction } = useAdverseActions(report.id);
  const { t } = useTranslation(namespace);
  const showCompleteNow = useShowCompleteNow();
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  let pausedAt;
  let duration;
  if (adverseAction?.pause_data) {
    pausedAt = adverseAction.pause_data.time; // The time+day that the pause happened
    duration = adverseAction.pause_data.duration; // How much time has passed since the pause occurred
  }

  if (candidateRedesignEnabled) {
    return null;
  }

  return (
    <M.Container data-testid='aa-panel-container' style={{ padding: '0' }}>
      <AdverseActionPanelHeading>
        <M.Container.Col className='flex'>
          <h5 className='mb-0'>{t('adjudication:report.header')}</h5>
          <Tooltip />
        </M.Container.Col>
      </AdverseActionPanelHeading>
      <div className='panel-body' style={{ padding: '0 30px 30px 30px' }}>
        {report.archived && (
          <ArchivedNote>{t('adjudication:report.archived.note')}</ArchivedNote>
        )}
        {pausedAt && duration && (
          <div
            data-testid='aa-panel-pause-text'
            style={{ marginBottom: '10px' }}
          >
            {t('adjudication:report.body.pause', {
              time: moment(pausedAt).format(DATE_FORMAT),
            })}{' '}
            <b>({t('adjudication:report.body.duration', { duration })})</b>
          </div>
        )}
        {report.adjudication === AdjudicationStatuses.ENGAGED && (
          <div
            data-testid='aa-panel-engaged-text'
            style={{ fontStyle: 'italic' }}
          >
            {t('adjudication:report.body.engaged')}
          </div>
        )}
        {report.status === ReportStatuses.PENDING && (
          <div
            data-testid='aa-panel-pending-text'
            style={{ fontStyle: 'italic' }}
          >
            {t('adjudication:report.body.pending')}
          </div>
        )}
        <Actions />
        {showCompleteNow && !report.completable && (
          <div
            data-testid='aa-panel-complete-unavailable-text'
            style={{ marginTop: '8px' }}
          >
            {t('adjudication:report.body.complete_unavailable')}
          </div>
        )}
        <AdverseActionEventsTimeline report={report} />
      </div>
    </M.Container>
  );
};

export default AdverseAction;
