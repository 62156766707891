import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import type { GetStartedT } from 'components/BetterOrderExperience';

import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeader,
  StyledTableRow,
} from './CandidateInvitesTable.Styles';

export type CandidateInvitesTableProps = {
  invites: GetStartedT.Invite[];
};

const CandidateInvitesTable: React.FC<CandidateInvitesTableProps> = ({
  invites,
}) => {
  return (
    <StyledTableContainer data-testid='candidate-invites-table'>
      <M.Table>
        <M.TableHead>
          <StyledTableRow>
            <StyledTableHeader flex={1}>Email</StyledTableHeader>
            <StyledTableHeader width={130}>First name</StyledTableHeader>
            <StyledTableHeader width={130}>Phone</StyledTableHeader>
          </StyledTableRow>
        </M.TableHead>
        {invites.map(invite => (
          <StyledTableRow key={invite.id}>
            <StyledTableCell flex={1} data-dd-privacy='mask'>
              {invite.email.value}
            </StyledTableCell>
            <StyledTableCell width={130}>
              {invite.firstName.value || 'n/a'}
            </StyledTableCell>
            <StyledTableCell width={130} data-dd-privacy='mask'>
              {invite.phoneNumber.value || 'n/a'}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </M.Table>
    </StyledTableContainer>
  );
};

export default CandidateInvitesTable;
