import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import AccountChoices from './AccountChoices';
import { SelectorModal } from './styles';
import { MultiAccount } from '../../../utils';

type AccountSelectorModalProps = {
  accounts: MultiAccount[];
  continueUrl: string;
  state: string;
};

const AccountSelectorModal: React.FC<AccountSelectorModalProps> = ({
  accounts,
  continueUrl,
  state,
}: AccountSelectorModalProps) => {
  const [open, setOpen] = useState(true);
  const closeModal = useCallback(async () => {
    setOpen(false);
    // Login the user without the account id param
    window.location.replace(`${continueUrl}?state=${state}`);
  }, [continueUrl, state]);
  return (
    <SelectorModal onClose={closeModal} open={open}>
      <M.ModalHeader buttonOnClick={closeModal} />
      <M.ModalBody hasScrollingContent>
        <AccountChoices
          state={state}
          continueUrl={continueUrl}
          accounts={accounts}
        />
      </M.ModalBody>
    </SelectorModal>
  );
};

export default AccountSelectorModal;
