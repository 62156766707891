import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { Namespace } from 'modules/assess/api';
import * as ContextDates from 'modules/assess/api/context-dates';
import * as rulesetAPI from 'modules/assess/api/ruleset';
import { ID } from 'modules/id';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useFetch = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ContextDates.uri(context, id);
  const key: AnyQueryKey = [namespace, { id }];

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return ContextDates.fetch(context, id);
  };

  const result = useQuery(key, request);
  return result;
};

export const useUpdate = (id?: ID, ruleset?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = rulesetAPI.uri(context);
  const update = (options: ContextDates.ApiOptions) => {
    if (!id) {
      return Promise.reject();
    }
    return ContextDates.update(context, id, options);
  };

  const endpointsToInvalidate = ['diff', 'draft'];
  const [call, result] = useMutation(update, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        if (ns !== namespace) {
          return false;
        }

        const entityId = (entity as { id?: unknown })?.id;
        if (entityId !== ruleset) {
          return false;
        }

        return !field || endpointsToInvalidate.includes(field.toString());
      });
    },
  });

  return {
    call,
    result,
  };
};
