import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { useGetCounties } from 'api/location';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { M, colors } from '@dashboard-experience/mastodon';
import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_ADDONS_STATE_COUNTY } from 'Flags';
import { State, County } from 'components/AddScreenings/types';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';
import { AdditionalCrimProps } from './shared/types/AdditionalCrimProps.types';

type FlexProps = {
  direction?: string;
  width?: number;
};

type CountiesResponseData = {
  [key: string]: {
    counties: County[];
  };
};

type CountyCriminalSearchProps = ScreeningProps & AdditionalCrimProps;

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-flow: ${({ direction }) => direction ?? 'row'};
  width: 100%;
  width: ${({ width }) => `${width}%` ?? '100%'};
`;

const StateSelect = styled(M.StateSelect)`
  margin-right: 16px;
  margin-left: 40px;

  .cds--form-requirement {
    color: ${colors.uiButtonDestructiveLightDefault};
  }
`;

const CountySelect = styled(M.CountySelect)`
  .cds--form-requirement {
    color: ${colors.uiButtonDestructiveLightDefault};
  }
`;

const itemToString = (item: County) => {
  if (!item?.name) return '';
  return `${item.name} county`;
};

const toTitleCase = (string: string) => {
  return string.toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase());
};

const CountyCriminalSearch = ({
  shouldOpen,
  getPriceByType,
  actionProps,
  usingAddChecks,
  states = [],
  handlePrice,
}: CountyCriminalSearchProps) => {
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState<State | undefined>(
    undefined,
  );
  const [selectedCounty, setSelectedCounty] = useState<County | undefined>(
    undefined,
  );
  const [counties, setCounties] = useState<County[]>([]);
  const [error, setError] = useState<{
    state?: string | null;
    county?: string | null;
  } | null>({ state: null, county: null });

  const useOrderAddonsStateCounty =
    useFlag(ORDER_ADDONS_STATE_COUNTY)?.variantKey === 'enabled';

  const { onAddClick, basePackageScreenings } = actionProps;
  const selectedStatesParams = selectedState ? selectedState.abbreviation : '';
  const showStateCountyDropdowns = useOrderAddonsStateCounty && usingAddChecks;
  const basePackageScreeningsMapped = basePackageScreenings?.map(
    screening => screening.type,
  );

  const handleGetCountiesSuccess = (data: CountiesResponseData) => {
    const countyResponseData = Object.values(data)[0];
    const mapped = countyResponseData.counties?.map((county: County) => {
      return {
        id: county.id,
        name: toTitleCase(county.name),
        state: selectedState?.name,
        abbreviation: selectedState?.abbreviation,
        fips_code: county.fips_code,
      };
    });
    setCounties(mapped as County[]);
  };

  const handleGetCountiesError = (error: Error) => {
    dispatch(toastError('Error retrieving counties: ', error.message));
  };

  useGetCounties(
    selectedStatesParams,
    handleGetCountiesSuccess,
    handleGetCountiesError,
    !!selectedState,
  );

  const handleStateChange = useCallback(
    e => {
      if (!e) {
        setCounties([]);
      }
      if (error?.state) {
        setError({ ...error, state: null });
      }
      setSelectedState(e);
      setSelectedCounty(undefined);
    },
    [error],
  );

  const handleCountyChange = useCallback(
    e => {
      setSelectedCounty(e);

      if (usingAddChecks) {
        handlePrice();
      }
      if (error?.county) {
        setError({ ...error, county: null });
      }
    },
    [setSelectedCounty, usingAddChecks, handlePrice, error, setError],
  );

  const handleAddScreening = useCallback(
    e => {
      if (showStateCountyDropdowns && !selectedState?.id) {
        setError({ state: 'Selection required' });
        return;
      }
      if (showStateCountyDropdowns && !selectedCounty?.id) {
        setError({ county: 'Selection required' });
        return;
      }
      if (showStateCountyDropdowns && selectedState?.id && error?.state) {
        setError({ ...error, state: null });
      }
      if (showStateCountyDropdowns && selectedCounty?.id && error?.county) {
        setError({ ...error, county: null });
      }

      if (showStateCountyDropdowns) {
        onAddClick(e, {
          subtype: 'current',
          ...selectedCounty,
        });
      } else {
        onAddClick(e);
      }
    },
    [
      error,
      onAddClick,
      selectedCounty,
      selectedState?.id,
      showStateCountyDropdowns,
    ],
  );

  return (
    <FlexContainer data-testid='county-criminal-search-section'>
      <Flex direction='column'>
        <Flex direction='row'>
          <ExpandableTitle
            initialExpanded={shouldOpen}
            data-testid='expandable-county-criminal-search-section'
            title={
              <Title
                getPriceByType={usingAddChecks ? handlePrice : getPriceByType}
                actionProps={actionProps}
                title={
                  !showStateCountyDropdowns
                    ? 'County criminal search for 7-year address history'
                    : 'County criminal search'
                }
                priceByType='county_criminal_search'
              />
            }
          >
            <ScreeningDescriptionContainer>
              <ScreeningInfo>
                Fullest look at the candidate’s criminal history; essential to a
                comprehensive background check.
              </ScreeningInfo>
              {/* <TurnAroundTime /> */}
            </ScreeningDescriptionContainer>
            <Flex>
              <Box>
                <BoxHeader>Advantages</BoxHeader>
                <ul>
                  <BulletPoint text='County courts include most felony and misdemeanor cases.' />
                  <BulletPoint text='Find records older than 7 years where the law allows.' />
                  <BulletPoint text='Can better ensure full coverage and case information.' />
                </ul>
              </Box>
              <BoxDivider />
              <Box>
                <BoxHeader>Considerations</BoxHeader>
                <ul>
                  <BulletPoint text='Each county search has a fee.' />
                  <BulletPoint text='Slower for courts without digital records.' />
                  <BulletPoint text='Fees vary, and additional fees are possible.' />
                </ul>
              </Box>
            </Flex>
          </ExpandableTitle>
          <AddButtonContainer>
            <AddScreeningsAction
              screeningType='county_criminal_search'
              {...actionProps}
              onAddClick={handleAddScreening}
            />
          </AddButtonContainer>
        </Flex>
        {showStateCountyDropdowns && (
          <Flex width={75} data-testid='crim-selects'>
            <StateSelect
              data-testid='county-crim-state-select'
              placeholder='Select a state'
              titleText={null}
              states={states}
              selectedState={selectedState}
              onChange={handleStateChange}
              warn={error?.state}
              warnText={error?.state}
              disabled={basePackageScreeningsMapped?.includes(
                ScreeningType.county_criminal_search,
              )}
            />
            <CountySelect
              id='county-crim-county-select'
              data-testid='county-crim-county-select'
              counties={counties}
              placeholder='Select a county'
              titleText={null}
              itemToString={itemToString}
              selectedCounty={null}
              disabled={!selectedState}
              onChange={handleCountyChange}
              warn={error?.county}
              warnText={error?.county}
            />
          </Flex>
        )}
      </Flex>
    </FlexContainer>
  );
};

export default CountyCriminalSearch;
