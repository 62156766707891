import React from 'react';
import * as Entity from 'modules/assess/models/rulesets/context-dates/option';
import Option from './options';

type Props = {
  options?: Readonly<Entity.List>;
};

export const List: React.FC<Props> = ({ options }) => {
  const children = options?.map(contextDate => (
    <Option
      key={contextDate.id}
      contextDate={contextDate}
      optionsCount={options.length}
    />
  ));

  return (
    <div data-testid='assess-rules-version-context-dates-list'>{children}</div>
  );
};

export default List;
