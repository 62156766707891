import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { InternationalEmploymentRecord as Record } from 'types';
import SearchPDF from './SearchPDF';
import formatCountry, {
  getInternationalRecordStatusOrAssessment,
} from './utils';
import HistoryTimeline from '../HistoryTimeline';
import DoNotContact from '../EmploymentVerification/DoNotContact';

const InternationalEmploymentRecord: React.FC<{
  record: Record;
  statusType: any;
  screeningAssessment?: string | null;
}> = ({ record, statusType, screeningAssessment }) => {
  const title = formatCountry(record.employer.address.country);

  // result is an object on these records, as opposed to report lifecycle result, so use the status directly
  const { status } = record;
  const displayStatus = getInternationalRecordStatusOrAssessment(
    status,
    screeningAssessment,
  );

  return (
    <M.SubScreening
      title={title}
      status={displayStatus}
      statusType={statusType}
      screening={record}
    >
      {record.pdf_url && (
        <SearchPDF
          url={record.pdf_url}
          text={`View ${title} international employment report`}
        />
      )}
      {record.employer.do_not_contact && <DoNotContact />}
      {record.events?.length > 0 && <HistoryTimeline events={record.events} />}
    </M.SubScreening>
  );
};

export default InternationalEmploymentRecord;
