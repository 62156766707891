import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useRumError } from '@dashboard-experience/utils';
import { useEditPackage } from 'api/packages';
import UIContext from 'context/UI';
import styled from 'styled-components';
import { updateParentWindowUrl } from 'utils';
import { colors, M } from '@dashboard-experience/mastodon';
import Dialog from 'components/Dialog';
import { toastError } from 'actions/ToastActions';
import throttle from 'lodash/throttle';
import { useDashboardRelay, useDashboardWindowScroll } from 'hooks';
import { useDispatch } from 'react-redux';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import AddAddons from './AddAddons';
import * as utils from './shared/utils';
import { getAliasEnabled } from './shared/utils';
import {
  PACKAGE_EDITOR_EVENT_NAMES,
  useTrackEvent,
} from '../Packages/Amplitude/analytics';
import { useUser } from '../../context/CurrentUser';

const Container = styled.div`
  min-width: 600px;
  margin: 0 auto;
  min-height: 1040px;
  overflow-y: scroll;
`;

const TopBar = styled.div`
  max-width: 1200px;
  display: flex;
  margin: auto;
  padding-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.uiGrey0} !important;
  height: 100%;

  .short-text {
    display: none;
  }

  @media (max-width: 672px) {
    .short-text {
      display: inline-block;
    }
    .full-text {
      display: none;
    }
  }

  h3 {
    margin: 2.5rem 0 1.5rem 1.5rem !important;
    letter-spacing: 0;
  }
`;

const SaveSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 25px !important;
`;

const Icon = styled(M.Icon)`
  color: ${colors.uiGreen400};
  margin-right: 8px;
`;

const Screeningli = styled.li`
  margin-bottom: 4px;
`;

const ScreeningListContainer = styled.div`
  margin-top: 16px;
  list-style-type: none;
`;

const MaxWidth = styled.div`
  position: sticky;
  background-color: ${colors.uiGrey0} !important;
  top: ${({ topPosition }: { topPosition: number }) => topPosition}px;
  z-index: 10;
  margin: auto;
  margin-bottom: 24px;
  margin-top: 30px;
  width: 100%;
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid ${colors.uiGrey200};
  height: 70px;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
`;

type Package = {
  source_version_id: any;
  name: string;
  slug: string;
  id: string;
  screenings: (
    | {
        type: string;
        subtype?: undefined;
      }
    | {
        type: string;
        subtype: string;
      }
    | {
        contract_type: boolean;
        date_matching: number;
        international_upgrade: boolean;
        lookback_years: number;
        manager_contact: boolean;
        maximum_allowed_employers: number | undefined;
        position_matching: string;
        requires_employment_history: boolean;
        requires_full_lookback: boolean;
        salary: boolean;
        subtype: string;
        type: string;
      }
    | {
        type: string;
        required_quantity: Array<boolean | number>;
      }
  )[];
  international_only: boolean;
  aliases_enabled?: string;
  use_alias_skus?: boolean;
  [key: string]: any;
};

type Props = {
  account: any;
  basePackage: Package;
  packageId: string;
};

export const initialState = {
  addedScreeningTypes: [],
  additionalProperties: {},
  newPackageName: '',
  newPackageNameInvalid: true,
  error: '',
  aliasesEnabled: AliasesEnabledType.OFF,
  aliasPrice: 0,
};

export const actionTypes = {
  SCREENING_ADDED: 'SCREENING_ADDED',
  SCREENING_REMOVED: 'SCREENING_REMOVED',
  ADD_ONS_CANCELLED: 'ADD_ONS_CANCELLED',
  SET_PACKAGE_NAME: 'SET_PACKAGE_NAME',
  SAVE_FOR_NEXT_TIME: 'SAVE_FOR_NEXT_TIME',
  SET_ERROR: 'SET_ERROR',
  SET_ALIASES_ENABLED: 'SET_ALIAS_ENABLED',
  SET_ALIASES_PRICE: 'SET_ALIASES_PRICE',
};
const isPackageNameInvalid = (name: string) =>
  !name.trim().length || !/^[a-zA-Z0-9\s]*$/.test(name);

export const reducer = (state: any, action: any) => {
  let newState;
  switch (action.type) {
    case actionTypes.SCREENING_ADDED:
      newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.includes(
          action.payload.screeningType,
        )
          ? state.addedScreeningTypes
          : state.addedScreeningTypes.concat(action.payload.screeningType),
        additionalProperties: action.payload.additionalProperties
          ? {
              ...state.additionalProperties,
              [action.payload.screeningType]: {
                ...(state.additionalProperties[action.payload.screeningType] ||
                  {}),
                ...action.payload.additionalProperties,
              },
            }
          : state.additionalProperties,
      };
      break;
    case actionTypes.SCREENING_REMOVED:
      newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.filter(
          (screeningType: any) =>
            screeningType !== action.payload.screeningType,
        ),
      };
      if (action.payload.screeningType === 'employment_verification') {
        delete newState.additionalProperties[action.payload.screeningType]
          ?.international_upgrade;
      }
      if (action.payload.screeningType !== 'employment_verification') {
        delete newState.additionalProperties[action.payload.screeningType];
      }
      // removes adverse media search any time intl crim search v2 is removed
      if (
        action.payload.screeningType.includes(
          'international_criminal_search_v2',
        )
      ) {
        newState = {
          ...newState,
          addedScreeningTypes: newState.addedScreeningTypes.filter(
            (screeningType: any) =>
              screeningType !== 'international_adverse_media_search',
          ),
        };
      }
      break;
    case actionTypes.SET_ALIASES_ENABLED:
      newState = {
        ...state,
        aliasesEnabled: action.payload.aliasesEnabled,
      };
      break;
    case actionTypes.SET_ALIASES_PRICE:
      newState = {
        ...state,
        aliasPrice: action.payload.aliasPrice,
      };
      break;
    case actionTypes.SET_PACKAGE_NAME:
      newState = {
        ...state,
        newPackageName: action.payload.name,
        newPackageNameInvalid: isPackageNameInvalid(action.payload.name),
        error: '',
      };
      break;
    case actionTypes.SET_ERROR:
      newState = {
        ...state,
        error: action.payload.error,
      };
      break;
    case actionTypes.ADD_ONS_CANCELLED:
      newState = { ...initialState };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

const EditScreenings: React.FC<Props> = ({
  account,
  basePackage,
  packageId,
}) => {
  const { contextId, isIframe } = useContext(UIContext);
  const history = useHistory();
  const dispatchToast = useDispatch();
  const [state, dispatchState] = useReducer(reducer, initialState);
  const [packageNameInvalid, setPackageNameInvalid] = useState(false);
  const [packageNameDirty, setPackageNameDirty] = useState(false);
  const [windowOffsetY, setWindowOffsetY] = useState(0);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const {
    addedScreeningTypes,
    newPackageNameInvalid,
    newPackageName,
    error,
    aliasesEnabled,
  } = state;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const addDataDogError = useRumError();

  const { call: editPackage, result: editPackageResult } = useEditPackage({
    accountId: account.id,
    packageId,
    onError: (e: any) => {
      const errorData = e?.response?.data;

      if (!errorData) {
        dispatchToast(
          toastError(
            'Failed to create package',
            'Unable to create package at this time',
          ),
        );
        return;
      }
      const { errors } = errorData;
      setShowConfirmation(false);
      if (Array.isArray(errors)) {
        let errorMessage = errors.join(', ');
        const error = new Error(errorMessage);
        addDataDogError(error, {});
        if (errors[0]?.toLowerCase() === 'slug has already been taken') {
          errorMessage = 'Please choose a unique name for your new package';
          dispatchState({
            type: actionTypes.SET_ERROR,
            payload: { error: errorMessage },
          });
        } else if (errors[0]?.toLowerCase() === 'slug is invalid') {
          errorMessage =
            'Package name cannot be blank or include special characters';
          dispatchState({
            type: actionTypes.SET_ERROR,
            payload: { error: errorMessage },
          });
        } else {
          dispatchToast(toastError('Failed to create package', errorMessage));
        }
      } else if ('error' in errorData) {
        let message = errorData.error;
        const error = new Error(message);
        addDataDogError(error, {});
        if (message.includes('Domestic packages with a criminal screening')) {
          message =
            'Domestic packages with a criminal screening must include ALL of the following basic screenings: SSN Trace, Sex Offender Search, National Criminal Search';
        }
        dispatchToast(toastError('Failed to create package', message));
      } else {
        dispatchToast(toastError('Failed to create package'));
      }
    },
  });
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [initialAdditionalProperties, setInitialAdditionalProperties] =
    useState({});

  const [prices, setPrices] = useState({
    addonPrices: [
      {
        product_type: '',
        base_price: 0,
      },
    ],
    basePackagePrice: 0,
    aliasPrice: 0,
    subtotalPrice: 0,
  });

  const handlePriceChange = useCallback((value: any) => {
    setPrices(prevPrices => {
      if (JSON.stringify(prevPrices) !== JSON.stringify(value)) {
        return value;
      }
      return prevPrices;
    });
  }, []);

  const buildInitialAdditonalProperties = () => {
    let additionalProperties = {};

    const [facisScreening] = basePackage.screenings.filter(
      screening => screening.type === 'facis_search',
    );
    const [employmentVerification] = basePackage.screenings.filter(
      screening => screening.type === 'employment_verification',
    );
    const [personalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'personal_reference_verification',
    );
    const [professionalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'professional_reference_verification',
    );

    if (facisScreening?.type && 'subtype' in facisScreening) {
      additionalProperties = {
        ...additionalProperties,
        facis_search: { subtype: facisScreening.subtype },
      };
    }

    if (
      employmentVerification?.type &&
      'maximum_allowed_employers' in employmentVerification &&
      !isNaN(employmentVerification.maximum_allowed_employers as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        employment_verification: {
          maximum_allowed_employers:
            employmentVerification.maximum_allowed_employers,
        },
      };
    }

    if (
      personalReferenceVerification?.type &&
      'required_quantity' in personalReferenceVerification &&
      !isNaN(personalReferenceVerification.required_quantity[1] as number)
    ) {
      additionalProperties = {
        ...additionalProperties,
        personal_reference_verification: {
          required_quantity: personalReferenceVerification.required_quantity,
        },
      };
    }

    if (
      professionalReferenceVerification?.type &&
      'required_quantity' in professionalReferenceVerification &&
      !isNaN(professionalReferenceVerification.required_quantity[1] as number)
    ) {
      additionalProperties = {
        ...additionalProperties,
        professional_reference_verification: {
          required_quantity:
            professionalReferenceVerification.required_quantity,
        },
      };
    }

    setInitialAdditionalProperties(additionalProperties);
  };

  const buildPostBodyWithAddOns = useCallback(() => {
    const packageName =
      (!packageNameInvalid && newPackageName) || basePackage.name;

    const aliasesEnabledToUse = getAliasEnabled(
      basePackage.aliases_enabled,
      aliasesEnabled,
    );
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...basePackage,
        aliases_enabled: aliasesEnabledToUse,
      },
      ...state,
      packageName,
      setSlug: true,
      isPrivate: false,
    });
  }, [packageNameInvalid, newPackageName, basePackage, aliasesEnabled, state]);

  const backToPackages = useCallback(() => {
    const path = '/packages';
    trackEvent(
      currentUser,
      PACKAGE_EDITOR_EVENT_NAMES.PACKAGE_EDITOR_MODAL_COMPLETED,
    );
    if (contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: false,
      });
    }
    history.push(path);
  }, [contextId, currentUser, history, trackEvent]);

  const savePackage = useCallback(() => {
    const body = buildPostBodyWithAddOns();
    editPackage(body);
    const addonSum = prices?.addonPrices
      ? prices.addonPrices.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.base_price;
        }, 0)
      : 0;
    const formattedAddonSum = `$${(addonSum / 100).toFixed(2)}`;
    trackEvent(
      currentUser,
      PACKAGE_EDITOR_EVENT_NAMES.PACKAGE_EDITOR_PACKAGE_SAVED,
      {
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings
          .map(screening => screening.type)
          .join(', '),
        'Add-Ons Screenings': state.addedScreeningTypes?.length
          ? state.addedScreeningTypes.join(', ')
          : null,
        'Package Saved': 'Yes',
        'New Package Name': newPackageName,
        'Work Location': basePackage.international_only
          ? 'Outside of the US'
          : 'United States',
        ...(prices
          ? {
              'Original Package Price': `$${prices.basePackagePrice / 100}`,
              'Add-on Price': formattedAddonSum,
              'Alias Price': `$${prices.aliasPrice / 100}`,
              'Total Order Price': prices?.subtotalPrice,
            }
          : {}),
      },
    );
  }, [
    basePackage.international_only,
    basePackage.name,
    basePackage.screenings,
    buildPostBodyWithAddOns,
    currentUser,
    editPackage,
    newPackageName,
    prices,
    state.addedScreeningTypes,
    trackEvent,
  ]);

  const toggleCancelConfirmation = useCallback(() => {
    setShowCancelConfirmation(!showCancelConfirmation);
  }, [showCancelConfirmation]);

  const toggleConfirmation = useCallback(() => {
    setShowConfirmation(!showConfirmation);
    trackEvent(
      currentUser,
      PACKAGE_EDITOR_EVENT_NAMES.PACKAGE_EDITOR_COMPLETED,
    );
  }, [currentUser, showConfirmation, trackEvent]);

  const handlePackageNameChange = useCallback((name: any) => {
    if (isPackageNameInvalid(name)) {
      setPackageNameInvalid(true);
    } else {
      setPackageNameInvalid(false);
    }
    setPackageNameDirty(true);
  }, []);

  const handleParentWindowScroll = useCallback(
    throttle((e: MessageEvent) => {
      const offsetY = e.data?.window?.pageYOffset;
      setWindowOffsetY(offsetY);
    }, 200),
    [],
  );

  useEffect(() => {
    if (editPackageResult.isSuccess) {
      backToPackages();
    }
  }, [editPackageResult.isSuccess]);

  useEffect(() => {
    buildInitialAdditonalProperties();
  }, []);

  useDashboardWindowScroll(contextId);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current?.clientHeight);
    }
  }, [headerRef.current]);

  const getWindowInnerHeight = useCallback((e: MessageEvent) => {
    setWindowInnerHeight(e.data.window.innerHeight);
  }, []);

  useEffect(() => {
    trackEvent(
      currentUser,
      PACKAGE_EDITOR_EVENT_NAMES.PACKAGE_EDITOR_PAGE_VIEWED,
    );
  }, [currentUser, trackEvent]);

  useDashboardRelay({
    messageId: 'window_iframe_positions',
    callback: getWindowInnerHeight,
    isIframe,
    contextId,
    deps: [getWindowInnerHeight],
  });

  useDashboardRelay({
    messageId: 'window_iframe_positions_on_scroll',
    callback: handleParentWindowScroll,
    isIframe,
    contextId,
  });

  const saveIsDisabled = useMemo(() => {
    if (
      basePackage.aliases_enabled !== AliasesEnabledType.ON &&
      aliasesEnabled === AliasesEnabledType.ON
    ) {
      return false;
    }
    return (
      editPackageResult.isLoading ||
      (!state.addedScreeningTypes.length && !packageNameDirty) ||
      packageNameInvalid
    );
  }, [
    basePackage,
    editPackageResult.isLoading,
    packageNameDirty,
    packageNameInvalid,
    state.addedScreeningTypes.length,
    aliasesEnabled,
  ]);

  const handleCancelClick = useCallback(() => {
    trackEvent(
      currentUser,
      PACKAGE_EDITOR_EVENT_NAMES.PACKAGE_EDITOR_CANCEL_CLICKED,
    );
    state.addedScreeningTypes.length
      ? toggleCancelConfirmation()
      : backToPackages();
  }, [
    backToPackages,
    currentUser,
    state.addedScreeningTypes.length,
    toggleCancelConfirmation,
    trackEvent,
  ]);

  return (
    <Container>
      <Dialog
        open={showCancelConfirmation}
        header='Remove add-ons'
        content={
          <span>
            This will remove all of the selected add-ons from your package. If
            you are experiencing any issues or need additional info, please
            visit our&nbsp;
            <a
              href='https://help.checkr.com/hc/en-us/articles/8789699142551-Package-add-ons-Add-screenings-to-an-existing-package'
              target='_blank'
              rel='noopener noreferrer'
            >
              Help Center
            </a>
            .
          </span>
        }
        footer={
          <div>
            <M.Button onClick={toggleCancelConfirmation} kind='secondary'>
              Cancel
            </M.Button>
            <M.Button onClick={backToPackages}>Remove</M.Button>
          </div>
        }
        onClose={toggleCancelConfirmation}
      />
      <Dialog
        open={showConfirmation}
        header={`Save ${newPackageName}`}
        content={
          <>
            Please review your selected add-ons. Once you <strong>Save</strong>{' '}
            this package, your add-ons can&apos;t be changed.
            <ScreeningListContainer>
              {state.addedScreeningTypes.map((item: any) => {
                if (item === 'international_adverse_media_search') {
                  return null;
                }
                return (
                  <Screeningli key={item}>
                    {' '}
                    <Icon icon='Checkmark' size='16' />
                    {utils.getScreeningSummaryLabel(item, {
                      ...state.additionalProperties,
                    })}
                  </Screeningli>
                );
              })}
            </ScreeningListContainer>
          </>
        }
        footer={
          <div>
            <M.Button
              data-testid='dialog-save-package-button'
              onClick={savePackage}
              disabled={editPackageResult.isLoading}
            >
              Save
            </M.Button>
          </div>
        }
        onClose={toggleConfirmation}
      />
      <MaxWidth topPosition={windowOffsetY + 24} ref={headerRef}>
        <TopBar>
          <h3>
            <span>Edit your package</span>
          </h3>
          <SaveSection>
            <M.Button
              kind='secondary'
              onClick={handleCancelClick}
              id='cancel-addons-button'
            >
              Cancel
            </M.Button>

            <M.Button
              disabled={saveIsDisabled}
              onClick={toggleConfirmation}
              data-testid='confirm-addons-button'
              id='confirm-addons-button'
            >
              Save
            </M.Button>
          </SaveSection>
        </TopBar>
      </MaxWidth>
      <Content>
        <AddAddons
          basePackage={basePackage}
          windowOffsetY={windowOffsetY + headerHeight}
          windowInnerHeight={windowInnerHeight - headerHeight}
          addedScreeningTypes={addedScreeningTypes}
          additionalProperties={{
            ...initialAdditionalProperties,
            ...state.additionalProperties,
          }}
          newPackageName={newPackageName}
          selectedGeo={
            basePackage.international_only ? 'international' : 'domestic'
          }
          dispatch={dispatchState}
          newPackageNameInvalid={newPackageNameInvalid}
          account={account}
          error={error}
          handlePackageNameChange={handlePackageNameChange}
          aliasesEnabled={aliasesEnabled}
          getPrices={handlePriceChange}
        />
      </Content>
    </Container>
  );
};

export default EditScreenings;
