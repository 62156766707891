import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { useUser } from 'context/CurrentUser';
import { useReportEta } from 'containers/Report';
import useGetFields from './use-get-fields';
import withReportContentContainer from '../report-content-container';
import { buildColumnConfig } from '../utils';
import { KeyValueList, ExpandablePanel } from '../styles';
import { SectionProps } from '../types';

export const OrderInformation: React.FC<SectionProps> = ({
  report,
  statusType,
}) => {
  const user = useUser();

  const { reportPrediction, reportEstimationGenerated } = useReportEta(report);
  const fields = useGetFields({
    report,
    reportPrediction,
    reportEstimationGenerated,
  });

  const items = buildColumnConfig({
    fields,
    report,
    statusType,
    reportPrediction,
    reportEstimationGenerated,
    user,
  });

  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.order-information.title`);

  return fields.length > 0 ? (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='report-order-information-panel'
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList items={items} data-testid='overview-order-information' />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(OrderInformation);
