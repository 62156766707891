import { useUser } from 'context/CurrentUser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { namespace } from 'modules/candidate/locales';
import { ExpandablePanel, KeyValueList } from '../styles';
import { SectionProps } from '../types';
import { buildColumnConfig } from '../utils';
import useGetFields from './use-get-fields';

const CandidateInformation: React.FC<SectionProps> = ({
  report,
  statusType,
  candidate,
}) => {
  const user = useUser();
  const [candidateUpdates, setCandidateUpdates] = useState({});
  const { t } = useTranslation();

  const title = t(`${namespace}:report.overview.candidate-information.title`);

  const invitationMatch = useRouteMatch({
    path: '/candidates/:candidateId/invitations/:invitationId',
    exact: true,
  });

  const candidateMatch = useRouteMatch({
    path: '/candidates/:candidateId',
    exact: true,
  });

  const isInvitation = !!invitationMatch || !!candidateMatch;

  const fields = useGetFields({ candidate, isInvitation, report });

  const candidateInformation = buildColumnConfig({
    fields,
    report,
    statusType,
    user,
    candidate,
    candidateUpdates,
    setCandidateUpdates,
  });

  return fields.length > 0 ? (
    <ExpandablePanel
      data-pendo-id='candidate-information-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={candidateInformation}
        data-testid='overview-candidate-information'
      />
    </ExpandablePanel>
  ) : null;
};

export default CandidateInformation;
