import React, { useCallback } from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { BASIC_SCREENINGS } from 'components/Signup/constants/screenings';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import ScreeningsListItem from '../ScreeningsListItem';

const BasicPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
  showCompleteCrim,
}) => {
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Basic',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_basic_plus_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Basic+'
      description={
        !showCompleteCrim
          ? 'Simple and limited search'
          : 'Our most affordable option with limited criminal search'
      }
      price={2999}
      packageType='checkrdirect_basic_plus_criminal'
      selected={selectedPackage === 'checkrdirect_basic_plus_criminal'}
      onClick={handlePackageSelect}
    >
      <>
        {BASIC_SCREENINGS.map(screeningName => (
          <ScreeningsListItem
            key={screeningName}
            screeningName={screeningName}
          />
        ))}
      </>
    </PackageCard>
  );
};

export default BasicPackageCard;
