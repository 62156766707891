import { useMemo } from 'react';
import { useUser } from 'context/CurrentUser';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useAtsIntegrationConfigs } from 'api/atsIntegration';
import Components from './Components';
import { Tab, Tabs } from './types';
import {
  USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY,
  SHOW_REPORT_SUMMARY,
} from '../../../Flags';

const PATH = 'account';
const ATS_INTEGRATION_ALLOWED_PARTNERS = new Set(['workday', 'dayforce']);

export const getInitialTabIndex = (tabs: Tabs, tab?: string) => {
  for (const t in tabs) {
    if (tabs[t].slug === tab) return Number(t);
  }
  return 0;
};

const GetTabs = () => {
  const user = useUser();
  const canSeeFullDashboard = accountHasPermission(user, 'see_full_dashboard');
  const newUserPreferencesEnabled =
    useFlag(USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY)?.variantKey === 'on' ||
    false;
  const showReportSummary = useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';

  const hrisEnabled = accountHasPermission(user, 'hris_configurations_enabled');

  const { data: integrationConfigData } = useAtsIntegrationConfigs(hrisEnabled);

  return useMemo(() => {
    const tabs: Tabs = {
      0: {
        content: Components[Tab.settings],
        slug: Tab.settings,
        url: `/${PATH}/settings`,
      },
    };

    if (newUserPreferencesEnabled) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.notifications],
        slug: Tab.notifications,
        url: `/${PATH}/notifications`,
      };
    }

    if (hasPermission(user, 'read_account_users')) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.users],
        slug: Tab.users,
        url: `/${PATH}/users`,
      };
    }

    if (
      canSeeFullDashboard &&
      (hasPermission(user, 'manage_idp_settings') ||
        hasPermission(user, 'manage_all_idps'))
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab['single-sign-on']],
        slug: Tab['single-sign-on'],
        url: `/${PATH}/single-sign-on`,
      };
    }

    if (canSeeFullDashboard && hasPermission(user, 'manage_dev_settings')) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.developer_settings],
        slug: Tab.developer_settings,
        url: `/${PATH}/developer_settings`,
      };
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.integrations],
        slug: Tab.integrations,
        url: `/${PATH}/integrations`,
      };
    }

    if (
      canSeeFullDashboard &&
      user.account.self_service_applications &&
      hasPermission(user, 'manage_applications')
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.applications],
        slug: Tab.applications,
        url: `/${PATH}/applications`,
        // TODO: migrate `selectedRoutes` from angular
      };
    }

    if (
      canSeeFullDashboard &&
      user.account.hierarchy_present &&
      hasPermission(user, 'manage_brands')
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.brands],
        slug: Tab.brands,
        url: `/${PATH}/brands`,
      };
    }

    if (
      canSeeFullDashboard &&
      user.account.segmentation_enabled &&
      hasPermission(user, 'manage_account_hierarchy') &&
      !user.account.hierarchy_prohibited
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.hierarchy],
        slug: Tab.hierarchy,
        url: `/${PATH}/hierarchy`,
      };
    }

    if (
      canSeeFullDashboard &&
      hrisEnabled &&
      ATS_INTEGRATION_ALLOWED_PARTNERS.has(integrationConfigData?.partner ?? '')
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.ats_settings],
        slug: Tab.ats_settings,
        url: `/${PATH}/ats_settings`,
      };
    }

    if (
      showReportSummary &&
      canSeeFullDashboard &&
      hasPermission(user, 'manage_dev_settings')
    ) {
      tabs[Object.keys(tabs).length] = {
        content: Components[Tab.reports],
        slug: Tab.reports,
        url: `/${PATH}/reports`,
      };
    }

    return tabs;
  }, [
    newUserPreferencesEnabled,
    user,
    canSeeFullDashboard,
    hrisEnabled,
    integrationConfigData?.partner,
    showReportSummary,
  ]);
};

export default GetTabs;
