import React, { useReducer, useMemo, useEffect } from 'react';
import CustomProvider from 'state/provider';
import useSearchURLParams from 'components/Search/useSearchURLParams';
import context from './context';
import reducer, { initialState } from './reducer';
import { ActionType } from './types';

type Props = {};

const Provider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // TODO: Assess if we need to eventually migrate PARAMS.updateGeneralParams call once user is available

  // On initial pageload, populate the state with querystring params
  useEffect(() => {
    dispatch({ type: ActionType.INITIALIZE_FILTERS });
    // Non-exhaustive deps because we ONLY want to run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSearchURLParams(state.filters);

  const props = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CustomProvider context={context} props={props} stateKey='reportsList'>
      {children}
    </CustomProvider>
  );
};

export default Provider;
