import { Client } from 'api';
import {
  ICohort,
  ICohortDetail,
} from 'components/RandomTestingProgram/Cohort/types';
import { IPoll, IPollDetail } from 'components/RandomTestingProgram/Poll/types';
import {
  RANDOMS_COHORT_PATH,
  RANDOMS_ORDERING_PATH,
  RANDOMS_POLL_PATH,
} from './constants';

export interface CohortParams {
  csv_file_bucket: string;
  csv_file_path: string;
}

export interface NewCohortParams extends CohortParams {
  name: string;
  dot_compliant: boolean;
  dot_compliance_agency?: string;
  drug_screening: string;
  alcohol_screening: string;
}

export interface EditCohortParams extends CohortParams {
  id: string;
}

export type NewRandomSelectionPollParams = {
  cohort_id: number;
  drug_selection_count: number;
  alcohol_selection_count: number;
};

export type NewRandomSelectionOrderParams = {
  cohort_id: number;
  disclosure_acknowledged: boolean;
  random_testing_poll_candidate_ids: string[];
  clinic_location_id?: string;
  clinic_zipcode?: string;
};

export type AlternateCandidateProps = {
  id: number;
  alternates: [{ random_testing_poll_candidate_id: number; reason: string }];
};

export type UpdateCandidateProps = {
  poll_id: number;
  candidate_id: number;
  report_id: string | null;
  screening_type: string | null;
  notes: string | null;
  excused: boolean | null;
  alternate: boolean | null;
};

export const getCohorts = (): Promise<ICohort[]> => {
  return Client.get(RANDOMS_COHORT_PATH);
};

export const getCohortDetails = (id: string): Promise<ICohortDetail> => {
  return Client.get(`${RANDOMS_COHORT_PATH}/${id}`);
};

export const deleteCohort = (id: string): Promise<string> => {
  return Client.delete(`${RANDOMS_COHORT_PATH}/${id}`);
};

export const createCohort = (
  params: NewCohortParams,
): Promise<ICohortDetail> => {
  return Client.post(`${RANDOMS_COHORT_PATH}`, params);
};

export const editCohort = (
  params: EditCohortParams,
): Promise<ICohortDetail> => {
  return Client.patch(`${RANDOMS_COHORT_PATH}/${params.id}`, params);
};

export const getCohortCsv = (id: number): Promise<string> => {
  return Client.get(`${RANDOMS_COHORT_PATH}/${id}/export`);
};

export const getRandomPollDetails = (id: number): Promise<IPollDetail> => {
  return Client.get(`${RANDOMS_POLL_PATH}/${id}`);
};

export const createRandomSelectionPoll = (
  params: NewRandomSelectionPollParams,
): Promise<IPoll> => {
  return Client.post(`${RANDOMS_POLL_PATH}`, params);
};

export const createRandomSelectionReportOrder = (
  params: NewRandomSelectionOrderParams,
): Promise<any> => {
  return Client.post(`${RANDOMS_ORDERING_PATH}`, params);
};

export const selectAlternateCandidates = (params: AlternateCandidateProps) => {
  const { id, alternates } = params;
  return Client.post(`${RANDOMS_POLL_PATH}/${id}/alternate_selectees`, {
    alternates,
  });
};

export const updatePollCandidate = (params: UpdateCandidateProps) => {
  const { poll_id, candidate_id } = params;
  return Client.put(
    `${RANDOMS_POLL_PATH}/${poll_id}/poll_candidates/${candidate_id}`,
    params,
  );
};
