import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

export const DropdownContent = () => {
  return (
    <>
      <ScreeningDescriptionContainer>
        <ScreeningInfo>
          Search various government and regulatory databases that list people
          who are either prohibited from certain industries, such as healthcare
          and finance, or on a Most Wanted criminal list.
        </ScreeningInfo>
      </ScreeningDescriptionContainer>
      <Flex>
        <Box>
          <BoxHeader>Advantages</BoxHeader>
          <ul>
            <BulletPoint text='Help identify suspected terrorists, money launderers, and drug traffickers from federal databases that are unrelated to court records.' />
            <BulletPoint text='Data sources include The Office of Inspector General for the US Department of Health and Human Services (OIG-HHS), System for Award Management (SAM), and FBI Most Wanted Lists.' />
          </ul>
        </Box>
        <BoxDivider />
        <Box>
          <BoxHeader>Considerations</BoxHeader>
          <ul>
            <BulletPoint text='Federal, state, and county searches rarely return results from global watchlist search sources.' />
          </ul>
        </Box>
      </Flex>
    </>
  );
};

const GlobalWatchlistSearch: React.FC<ScreeningProps> = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}) => {
  return (
    <FlexContainer data-testid='global-watchlist-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-global-watchlist-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Global watchlist search'
            priceByType='global_watchlist_search'
          />
        }
      >
        <DropdownContent />
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='global_watchlist_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default GlobalWatchlistSearch;
