import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  ManualBulkUploadData,
  BulkInviteCSV,
  Geo,
  Node,
  Program,
  emptyInvite,
} from '../SharedItems';
import { Action, BasePackage, StateType } from './context';
import { BillingEntity } from '../../../../types/Billing';
import { isPackageNameValid } from '../../shared/utils';

type Location = {
  country: string;
  state: string;
  city?: string;
};

export const CANDIDATE = 'CANDIDATE';
export const MYSELF = 'MYSELF';
export const MANUAL = 'MANUAL';
export const UPLOAD = 'UPLOAD';
export const SINGLE_PERSON = 'SINGLE';
export const MULTIPLE_PEOPLE = 'MULTIPLE';
export const ADD_CHECKS = 'ADD_CHECKS';
export enum ManualOrdersReview {
  NOT_REQUESTED = 'not_requested',
  REQUESTED = 'requested',
  APPROVED = 'approved',
}

export const requiredInitialState = {
  addedScreeningTypes: [],
  additionalProperties: {},
  newPackageName: '',
  newPackageNameInvalid: true,
  basePackage: {
    name: '',
    id: '',
    screenings: [],
  },
  selectedGeo: 'domestic',
  error: null,
};

export const initialLocation: Location = {
  country: '',
  state: '',
  city: '',
};

// Adding to state? Update this and STATE_TYPE in context
export const initialState: StateType = {
  ...requiredInitialState,
  basePackage: {} as BasePackage,
  oldBasePackageAddedScreeningTypes: [],
  oldBasePackage: {} as BasePackage,
  providerType: CANDIDATE,
  inviteMethod: MANUAL,
  manualBulkUploadData: {} as ManualBulkUploadData,
  manualBulkUploadType: SINGLE_PERSON,
  emails: [],
  invites: [emptyInvite()],
  csv: {} as BulkInviteCSV,
  location: initialLocation,
  geo: {} as Geo,
  node: {} as Node,
  program: {} as Program,
  continueButtonDisabled: true,
  saveForNextTime: false,
  internationalPriceLinkClicked: false,
  selectPackageAmplitudeData: {
    searchUsed: false,
    numberOfPagesViewed: 1,
  },
  paymentProfile: {} as BillingEntity,
  addOnsPrice: '',
  workflow: '',
  aliasesEnabled: AliasesEnabledType.OFF,
  aliasesEnabledChanged: false,
  aliasPrice: 0,
  pricesSnapshot: {
    basePackagePrice: 0,
    addonPrices: [],
    aliasPrice: 0,
    subtotalPerCandidate: 0,
  },
  recommendedPackageSelected: false,
  recommendedPackageType: '',
  packageOnReport: {} as BasePackage,
  selectedPackage: {} as BasePackage,
  editClicked: false,
};

export const actionTypes = {
  SCREENING_ADDED: 'SCREENING_ADDED', // Not implemented
  SCREENING_REMOVED: 'SCREENING_REMOVED', // Not implemented
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_EMAILS: 'ADD_EMAIL',
  ADD_INVITES: 'ADD_INVITE',
  UPLOAD_CSV: 'UPLOAD_CSV',
  SET_GEO: 'SET_GEO',
  SET_PAYMENT_PROFILE: 'SET_PAYMENT_PROFILE',
  SET_NODE: 'SET_NODE',
  SET_SELECTED_GEO: 'SET_SELECTED_GEO',
  SET_ERROR: 'SET_ERROR',
  SET_OLD_BASE_PACKAGE: 'SET_OLD_BASE_PACKAGE',
  SET_PACKAGE_ON_REPORT: 'SET_PACKAGE_ON_REPORT',
  SET_SELECTED_PACKAGE: ' SET_SELECTED_PACKAGE',
  SET_BASE_PACKAGE: 'PACKAGE_SELECTED',
  SET_PACKAGE_NAME: 'SET_PACKAGE_NAME',
  SET_MANUAL_BULK_UPLOAD_DATA: 'SET_MANUAL_BULK_UPLOAD_DATA',
  ADD_PROVIDER_TYPE: 'ADD_PROVIDER_TYPE',
  ADD_INVITE_METHOD: 'ADD_INVITE_METHOD',
  CHANGE_MANUAL_BULK_UPLOAD_TYPE: 'CHANGE_MANUAL_BULK_UPLOAD_TYPE',
  ADD_PROGRAM: 'ADD_PROGRAM',
  CONTINUE_BUTTON_DISABLED: 'CONTINUE_BUTTON_DISABLED',
  RESET_PACKAGE_NAME: 'RESET_PACKAGE_NAME',
  RESET_CUSTOMIZE_PAGE: 'RESET_CUSTOMIZE_PAGE',
  RESET_ADDONS: 'RESET_ADDONS',
  RESET_ALL_EXCEPT_LOCATION: 'RESET_ALL_EXCEPT_LOCATION',
  RESET_ALL: 'RESET_ALL',
  RESET_ADDITIONAL_PROPERTIES: 'RESET_ADDITIONAL_PROPERTIES',
  SAVE_FOR_NEXT_TIME: 'SAVE_FOR_NEXT_TIME',
  SET_INTERNATIONAL_PRICE_LINK_CLICK: 'SET_INTERNATIONAL_PRICE_LINK_CLICK',
  SET_SELECT_PACKAGE_AMPLITUDE_DATA: 'SET_SELECT_PACKAGE_AMPLITUDE_DATA',
  SET_ADDONS_PRICE: 'SET_ADDONS_PRICE',
  SET_CURRENT_WORKFLOW: 'SET_CURRENT_WORKFLOW',
  SET_ALIASES_ENABLED: 'SET_ALIASES_ENABLED',
  SET_ALIASES_PRICE: 'SET_ALIASES_PRICE',
  SET_PRICE_SNAPSHOT: 'SET_PRICE_SNAPSHOT',
  EDIT_CLICKED: 'EDIT_CLICKED',
};

export const reducer = (state: StateType, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SCREENING_ADDED:
      return {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.includes(
          payload.screeningType,
        )
          ? state.addedScreeningTypes
          : state.addedScreeningTypes.concat(payload.screeningType),
        additionalProperties: {
          ...state.additionalProperties,
          [payload.screeningType]: {
            ...(state.additionalProperties[payload.screeningType] || {}),
            ...payload.additionalProperties,
          },
        },
      };
    case actionTypes.SCREENING_REMOVED: {
      let newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.filter(
          (screeningType: any) => screeningType !== payload.screeningType,
        ),
      };
      if (payload.screeningType === 'employment_verification') {
        delete newState.additionalProperties[payload.screeningType]
          ?.international_upgrade;
        delete newState.additionalProperties[payload.screeningType]
          ?.dot_compliance;
      }
      if (payload.screeningType !== 'employment_verification') {
        delete newState.additionalProperties[payload.screeningType];
      }
      // removes adverse media search any time intl crim search v2 is removed
      if (payload.screeningType.includes('international_criminal_search_v2')) {
        newState = {
          ...newState,
          addedScreeningTypes: newState.addedScreeningTypes.filter(
            (screeningType: any) =>
              screeningType !== 'international_adverse_media_search',
          ),
        };
      }
      return newState;
    }
    case actionTypes.SET_PACKAGE_NAME:
      return {
        ...state,
        newPackageName: action.payload.name,
        newPackageNameInvalid: !isPackageNameValid(action.payload.name),
        error: !isPackageNameValid(action.payload.name)
          ? 'Package name cannot be blank or include special characters'
          : null,
      };
    case actionTypes.RESET_PACKAGE_NAME:
      return {
        ...state,
        newPackageName: '',
        newPackageNameInvalid: false,
        error: null,
      };
    case actionTypes.RESET_CUSTOMIZE_PAGE:
      return {
        ...state,
        additionalProperties: {},
        addedScreeningTypes: [],
        newPackageName: '',
        newPackageNameInvalid: false,
        error: null,
        saveForNextTime: false,
      };
    case actionTypes.RESET_ADDONS:
      return {
        ...state,
        addedScreeningTypes: [],
      };
    // Triggered when user "edits" a work location
    case actionTypes.RESET_ALL_EXCEPT_LOCATION: {
      // Save references
      const savedLoc = state.location;
      const savedNode = state.node;
      const savedGeo = state.geo;
      return {
        ...initialState,
        location: savedLoc,
        node: savedNode,
        geo: savedGeo,
      };
    }
    case actionTypes.RESET_ALL: {
      return { ...state };
    }
    case actionTypes.RESET_ADDITIONAL_PROPERTIES: {
      return { ...state, additionalProperties: {} };
    }
    case actionTypes.SAVE_FOR_NEXT_TIME:
      return {
        ...state,
        saveForNextTime: !state.saveForNextTime,
        oldBasePackageAddedScreeningTypes: [...state.addedScreeningTypes],
      };
    // this geo represents 'domestic' or 'international'
    case actionTypes.SET_SELECTED_GEO:
      return {
        ...state,
        selectedGeo: payload.selectedGeo,
        providerType:
          payload.selectedGeo === 'international'
            ? 'CANDIDATE'
            : state.providerType,
      };
    case actionTypes.ADD_LOCATION:
      return {
        ...state,
        location: payload.location,
      };
    case actionTypes.ADD_EMAILS:
      return {
        ...state,
        emails: payload.emails,
      };
    case actionTypes.ADD_INVITES:
      return {
        ...state,
        invites: payload,
      };
    case actionTypes.UPLOAD_CSV:
      return {
        ...state,
        csv: payload.csv,
      };
    case actionTypes.ADD_PROVIDER_TYPE:
      return {
        ...state,
        providerType: payload.providerType,
      };
    case actionTypes.ADD_INVITE_METHOD:
      return {
        ...state,
        inviteMethod: payload.inviteMethod,
      };
    case actionTypes.CHANGE_MANUAL_BULK_UPLOAD_TYPE:
      return {
        ...state,
        manualBulkUploadType: payload.manualBulkUploadType,
      };
    case actionTypes.SET_MANUAL_BULK_UPLOAD_DATA:
      return {
        ...state,
        manualBulkUploadData: payload.manualBulkUploadData,
      };
    case actionTypes.SET_GEO:
      return {
        ...state,
        geo: payload.geo,
      };
    case actionTypes.SET_PAYMENT_PROFILE:
      return {
        ...state,
        paymentProfile: payload.paymentProfile,
      };
    case actionTypes.SET_NODE:
      return {
        ...state,
        node: payload.node,
      };
    case actionTypes.ADD_PROGRAM:
      return {
        ...state,
        program: payload.program,
      };
    case actionTypes.SET_BASE_PACKAGE:
      return {
        ...state,
        basePackage: payload.basePackage,
        recommendedPackageSelected:
          payload.recommendedPackageSelected ??
          state.recommendedPackageSelected,
        recommendedPackageType:
          payload.recommendedPackageType ?? state.recommendedPackageType,
      };
    case actionTypes.SET_PACKAGE_ON_REPORT:
      return {
        ...state,
        packageOnReport: payload.packageOnReport,
      };
    case actionTypes.SET_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: payload.selectedPackage,
      };
    case actionTypes.SET_OLD_BASE_PACKAGE:
      return {
        ...state,
        oldBasePackage: payload.oldBasePackage,
        additionalProperties: payload.additionalProperties
          ? {
              ...payload.additionalProperties,
            }
          : state.additionalProperties,
      };
    case actionTypes.CONTINUE_BUTTON_DISABLED:
      return {
        ...state,
        continueButtonDisabled: payload.continueButtonDisabled,
      };
    case actionTypes.SET_INTERNATIONAL_PRICE_LINK_CLICK:
      return {
        ...state,
        internationalPriceLinkClicked: payload.internationalPriceLinkClicked,
      };

    case actionTypes.SET_SELECT_PACKAGE_AMPLITUDE_DATA:
      return {
        ...state,
        selectPackageAmplitudeData: payload.selectPackageAmplitudeData,
      };
    case actionTypes.SET_ADDONS_PRICE:
      return {
        ...state,
        addOnsPrice: payload.addOnsPrice,
      };
    case actionTypes.SET_CURRENT_WORKFLOW:
      return {
        ...state,
        workflow: payload.workflow,
      };
    case actionTypes.SET_ALIASES_ENABLED:
      return {
        ...state,
        aliasesEnabled: payload.aliasesEnabled,
        aliasesEnabledChanged: payload.aliasesEnabledChanged || false,
        basePackage: {
          ...state.basePackage,
          request_aliases_hard_cap_threshold: 4,
        },
      };
    case actionTypes.SET_ALIASES_PRICE:
      return {
        ...state,
        aliasPrice: payload.aliasPrice,
      };
    case actionTypes.SET_PRICE_SNAPSHOT:
      return {
        ...state,
        pricesSnapshot: payload.pricesSnapshot,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actionTypes.EDIT_CLICKED:
      return {
        ...state,
        editClicked: payload.editClicked,
      };

    default:
      return state;
  }
};
