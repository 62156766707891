import React from 'react';
import {
  getScreeningType,
  getScreeningSetting,
  isInternationalMotorVehicleReport,
  getIncludeDrivingHistorySetting,
} from 'components/Packages/PackageScreenings';
import { Screening } from 'types';
import { ScreeningSetting } from '@dashboard-experience/utils';
import {
  ScreeningListItem,
  ScreeningSettings,
  ScreeningType,
} from './styles/PackageCard.styles';

const PackageScreeningItem: React.FC<{
  screening: Screening;
  screeningSettings: ScreeningSetting[];
}> = ({ screening = { type: null }, screeningSettings = [] }) => {
  const screeningType = getScreeningType(screening.type);

  const screeningSetting = getScreeningSetting(screening, screeningSettings);
  return (
    <ScreeningListItem key={`${screening.type}`}>
      <ScreeningType data-testid={`screening-type-${screening.type}`}>
        {screeningType}
        <ScreeningSettings>{screeningSetting}</ScreeningSettings>
        {isInternationalMotorVehicleReport(screening.type) && (
          <ScreeningSettings>
            {`${getIncludeDrivingHistorySetting(screening)}`}
          </ScreeningSettings>
        )}
      </ScreeningType>
    </ScreeningListItem>
  );
};

export default PackageScreeningItem;
