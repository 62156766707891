import React from 'react';
import styled from 'styled-components';
import Icon, { ActionMap } from 'modules/assess/ui/common';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';

type Props = {
  actions: Pick<ActionMap, 'positionDown' | 'positionUp' | 'remove'>;
  position: number;
  optionsCount: number;
};

const Container = styled.div`
  display: flex;
  > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Component: React.FC<Props> = ({ actions, position, optionsCount }) => {
  const { disabled } = useState();
  const { positionDown, positionUp, remove } = actions;
  if (disabled) {
    return null;
  }

  const testId = 'ruleset-version-context-date-option-actions';
  const isFirst = position === 1;
  const isLast = position === optionsCount;

  const positionDownElement = isLast ? null : (
    <Icon
      name='positionDown'
      onClick={positionDown}
      data-testid={`${testId}-position-down`}
      tooltip={false}
    />
  );

  const positionUpElement = isFirst ? null : (
    <Icon
      name='positionUp'
      onClick={positionUp}
      data-testid={`${testId}-position-up`}
      tooltip={false}
    />
  );

  return (
    <Container data-testid={testId}>
      {positionDownElement}
      {positionUpElement}
      <Icon name='remove' onClick={remove} data-testid={`${testId}-remove`} />
    </Container>
  );
};

export default Component;
