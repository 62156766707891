import axios from 'axios';
import {
  CHECKR_API_BASE_URL,
  CHECKR_DIRECT_WEBSITE_CLIENT_ID,
  CHECKR_GOODHIRE_WEBSITE_CLIENT_ID,
} from 'Constants';
import { useMutation, queryCache } from 'react-query';
import { useLocation } from 'react-router-dom';
import usePatchUtmParams from './usePatchUtmParams';

const getAccountPayload = (
  form: any,
  recaptchaCode: string | null | undefined,
  queryParams: any,
) => {
  let clientId;
  let numEmployees;

  const valueMap = {
    '5': '1-10',
    '20': '11-25',
    '35': '26-50',
    '150': '51-299',
    '750': '300-1000',
    '1001': '1000+',
  };

  if (queryParams.get('isGoodhire')) {
    clientId = CHECKR_GOODHIRE_WEBSITE_CLIENT_ID;
    numEmployees = queryParams.get('expected_checks_per_year');
  } else {
    clientId = CHECKR_DIRECT_WEBSITE_CLIENT_ID;
    numEmployees = queryParams.get('volume');
  }

  return {
    client_id: clientId,
    amplitude_experiment_bucket: 'ab-08-fox-v2-treatment',
    good_better_best_experiment_bucket: queryParams.get(
      'amplitudeExperimentBucket',
    ),
    company: {
      city: form.city,
      phone: form.phone,
      num_employees: numEmployees
        ? valueMap[numEmployees as keyof typeof valueMap]
        : null,
      state: form.state,
      street: form.street,
      tax_id: String(form.taxId).replace(/-|\s/g, ''),
      zipcode: form.zipcode,
    },
    compliance_contact_email: form.email,
    default_compliance_city: form.complianceCity,
    default_compliance_state: form.complianceState,
    name: form.company,
    purpose: form.purpose,
    recaptcha_code: recaptchaCode,
    user: {
      email: form.email,
      full_name: form.fullName,
      password: form.password,
    },
  };
};

const createAccount = async (payload: any) => {
  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/v1/accounts`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response;
};

function useSubmitWithRecaptcha() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { mutate: patchUtmParams } = usePatchUtmParams();

  const [mutate, result] = useMutation(
    async ({
      formData,
      recaptchaCode,
    }: {
      formData: any;
      recaptchaCode: any;
    }) => {
      const payload = getAccountPayload(formData, recaptchaCode, queryParams);
      const response = await createAccount(payload);
      localStorage.setItem('accessToken', response.data.user.token);
      window.dispatchEvent(new Event('storage'));
      return { res: response.data };
    },
    {
      onSuccess: async (data: any) => {
        queryCache.invalidateQueries('signup-user');
        await patchUtmParams({ accountId: data.res.id });
      },
    },
  );

  const { isLoading, error } = result;

  return {
    mutate,
    data: result,
    isLoading,
    error,
  };
}

export default useSubmitWithRecaptcha;
