import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const StyledTableContainer = styled(M.TableContainer)`
  max-height: 288px; /* 288px is height of header and 5 rows */
  overflow-y: scroll;

  /* Always show scrollbar */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const StyledTableRow = styled(M.TableRow)`
  display: flex;
`;

interface TableHeaderProps {
  flex?: number;
  width?: string;
}

export const StyledTableHeader = styled(M.TableHeader)<TableHeaderProps>`
  align-items: center;
  display: flex;
  flex: ${props => props.flex || 'none'} !important;
  font-size: 12px !important;
  padding: 12px 24px 12px 0 !important;
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
`;

interface TableCellProps {
  flex?: number;
  width?: string;
}

export const StyledTableCell = styled(M.TableCell)<TableCellProps>`
  align-items: center;
  display: flex;
  flex: ${props => props.flex || 'none'} !important;
  font-size: 12px !important;
  line-height: 100% !important;
  padding: 12px 24px 12px 0 !important;
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
  word-break: break-all;
`;
