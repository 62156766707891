import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { PostPaymentMethodParams } from 'api/payment';
import {
  Button,
  StripeNote,
  FormBody,
  FormFooter,
} from './PaymentStyledComponents';

type Props = {
  hideModal: any;
  setError: any;
  setElementOptions: any;
  postPaymentMethod: any;
  isOrder?: boolean;
};

const AddPaymentForm: React.FC<Props> = ({
  hideModal,
  setError,
  setElementOptions,
  postPaymentMethod,
  isOrder,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const user = useUser();

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      stripe
        .confirmSetup({
          elements,
          redirect: 'if_required',
        })
        .then(result => {
          if (result.error) return;

          hideModal();
          const stripeId: any = result.setupIntent?.payment_method!;
          const params: PostPaymentMethodParams = {
            accountId: user.account.id,
            stripeId,
          };
          postPaymentMethod.postPaymentMethodCall(params);
          setElementOptions();
        })
        .catch(e => {
          setError(e.message);
        });
    },
    [
      stripe,
      elements,
      postPaymentMethod,
      setElementOptions,
      hideModal,
      setError,
      user,
    ],
  );

  return (
    <form onSubmit={handleSubmit}>
      <M.Grid>
        <FormBody>
          <PaymentElement />
          <StripeNote>
            You receive a monthly invoice of only completed reports.
          </StripeNote>
        </FormBody>
        <FormFooter>
          <Button
            onClick={hideModal}
            kind='secondary'
            data-testid='payment-modal-form-cancel-btn'
          >
            {t(`buttons.cancel`)}
          </Button>
          <Button
            type='submit'
            disabled={!stripe}
            data-testid='payment-modal-form-confirm-btn'
          >
            {isOrder ? t(`verbs.save`) : t(`buttons.continue`)}
          </Button>
        </FormFooter>
      </M.Grid>
    </form>
  );
};

export default AddPaymentForm;
