import { createContext } from 'use-context-selector';
import { initialState } from './reducer';
import { State, Dispatch } from './types';

const context = createContext<{ state: State; dispatch: Dispatch }>({
  state: initialState,
  dispatch: () => {},
});

export default context;
