import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useTranslation } from 'react-i18next';
import { ASSESS_RULESET_ASSIGNMENT_V2_FLAG_KEY } from 'Constants';
import Actions from './Actions';
import Content from './Content';
import Modal from '../apply/Modal';
import { useAssignments, useUpdate } from './hooks';

type Props = {
  ruleset?: Ruleset.Type;
  rulesets?: Array<Ruleset.Type>;
  open: boolean;
  onClose?: (event?: SyntheticEvent) => void;
};

export const ContainerV1: React.FC<Props> = ({
  ruleset,
  rulesets,
  open,
  onClose,
}) => {
  const [assignments, setAssignments] = useAssignments(ruleset);
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:ruleset.assign.title', {
    id: ruleset?.id,
    name: ruleset?.name,
    interpolation: { escapeValue: false },
  });

  const update = useUpdate(ruleset?.id);

  const onSave = useCallback(() => {
    update.call(assignments);
  }, [assignments, update]);

  useEffect(() => {
    if (update.result.status === 'success' && onClose) {
      onClose();
      update.result.reset();
    }
  }, [update.result, onClose]);

  if (!open) {
    return null;
  }

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-assign-dialog'
      open={open}
      size='lg'
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-assign-title'
        closeModal={onClose}
        title={modalTitle}
      />
      <M.ModalBody data-testid='assess-ruleset-assign-content'>
        <Content
          assignments={assignments}
          setAssignments={setAssignments}
          ruleset={ruleset}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-assign-actions'>
        <Actions
          onCancel={onClose}
          onSave={onSave}
          disabled={update.result.status === 'loading'}
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export const Container: React.FC<Props> = props => {
  const rulesetAssignmentV2Flag =
    useFlag(ASSESS_RULESET_ASSIGNMENT_V2_FLAG_KEY)?.variantKey === 'on';

  if (rulesetAssignmentV2Flag) {
    return <Modal {...props} />;
  }

  return <ContainerV1 {...props} />;
};

export default Container;
