import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ReviewAndSubmitT } from '..';
import {
  StyledInfoContainer,
  StyledFirstColumn,
  StyledSectionTitleTypography,
  StyledLink,
  StyledComponentWrapper,
} from './InfoSection.styles';
import { namespace } from '../../locales';

const InfoSection: React.FC<ReviewAndSubmitT.InfoSectionProps> = ({
  section,
  onEditClick,
  children,
}) => {
  const { t } = useTranslation(namespace);
  return (
    <StyledInfoContainer>
      <StyledFirstColumn>
        <StyledSectionTitleTypography data-testid={`${section}-section-title`}>
          {t(`reviewAndSubmit.infoSection.${section}`)}
        </StyledSectionTitleTypography>
        <StyledLink onClick={onEditClick} data-testid={`${section}-edit-link`}>
          {t(`reviewAndSubmit.edit`)}
        </StyledLink>
      </StyledFirstColumn>

      {children && <StyledComponentWrapper>{children}</StyledComponentWrapper>}
    </StyledInfoContainer>
  );
};

export default InfoSection;
