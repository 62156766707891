import React, { useEffect } from 'react';
import { Namespace } from 'modules/assess/api';
import { Page } from 'modules/assess/ui';
import { useNamespace } from 'modules/assess/ui/router/context';
import * as State from 'modules/assess/ui/state/context-date';
import { ID } from 'modules/id';
import ActionsContainer from './ActionsContainer';
import { useFetch } from './api/hooks';
import { Criminal as CriminalOptions } from './criminal';
import { Description } from './Description';
import { MVR as MVROptions } from './mvr';
import * as Store from './store';
import { Title } from './Title';

type Props = {
  disabled?: boolean;
  id: ID;
};

export const Container: React.FC<Props> = ({ disabled, id }) => {
  const namespace = useNamespace() || Namespace.criminal;
  const query = useFetch(id);
  const { data: response } = query;
  const store = Store.useStore();
  const { dispatch } = store;

  const Options =
    namespace === Namespace.criminal ? CriminalOptions : MVROptions;

  useEffect(() => {
    if (!response) {
      return;
    }

    dispatch(
      State.Actions.Load.create({
        disabled,
        options: response,
      }),
    );
  }, [disabled, dispatch, response]);

  return (
    <Store.Provider value={store}>
      <Page.Container data-testid='assess-ruleset-version-context-dates-container'>
        <ActionsContainer id={id} />
        <Title disabled={disabled} />
        <Description />
        <Options />
      </Page.Container>
    </Store.Provider>
  );
};

export default Container;
