import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import querystring from 'querystring';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import { usePackages } from 'api/packages';
import {
  FetchPackageType,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { Footer, StepContainer } from '../ui';
import { parsePackagesResponse } from './SelectPackageUtils';
import ConditionalLoadingRender from './ui/ConditionalLoadingRender';
import { namespace } from '../locales';
import type { SelectPackageT } from '..';
import SelectablePackagesList, {
  packagesPerPage,
} from './SelectablePackagesList';
import { Header, SearchContainer } from './styles/SelectPackageStyles';

const defaultParams: SelectPackageT.Params = {
  page: 1,
  per_page: packagesPerPage,
  name: '',
  program_ids: [],
  locale: 'DOMESTIC', // TODO this will need to match backend when that code is merged
};

const isEmpty = (object: {}) => Object.values(object).length < 1;

const SelectPackageStep: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { contextId } = useContext(UIContext);
  const basePath = `${namespace}:selectPackage`;

  const { account } = useUser();
  const {
    id: accountId,
    show_package_price: showPackagePrice,
    package_price_state: packagePriceState,
    hierarchy_present,
  } = account;

  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const {
    node,
    manualOrderType,
    update,
    selectedPackage,
    program,
    localeType,
  } = useContext(BetterOrderExperienceContext);

  const startingParams = {
    ...defaultParams,
    program_ids: program ? [program.id] : [],
  };

  const [params, setParams] = useState<SelectPackageT.Params>(startingParams);
  const [apiParams, setApiParams] = useState(
    new URLSearchParams(querystring.stringify({ ...params })),
  );
  const [searchParam, setSearchParam] = useState(params.name);

  const packagesResponse = usePackages(accountId, apiParams);

  const { count, packages, packagesLoading } = parsePackagesResponse(
    packagesResponse as unknown as SelectPackageT.QueryResult<FetchPackageType>,
    localeType === 'DOMESTIC',
  );

  const handlePageNumberChange = useCallback(
    (pageIndex: number) => {
      setParams({ ...params, page: pageIndex + 1 });
    },
    [params, setParams],
  );

  const debouncedSearch = useDebouncedCallback(
    value => {
      setParams({ ...params, page: 1, name: value });
    },
    350,
    [accountId],
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchParam(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const showPrice: boolean =
    showPackagePrice && packagePriceState !== 'disabled via partner';
  const isManualBulkOrder = manualOrderType === 'MULTIPLE';

  const continueDisabled = useMemo(
    () => isEmpty(selectedPackage),
    [selectedPackage],
  );

  const handleBack = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/add-ons';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  useEffect(() => {
    const newParams: SelectPackageT.Params = { ...params };

    setApiParams(new URLSearchParams(querystring.stringify(newParams)));

    if (contextId) {
      updateParentWindowUrl({
        contextId,
        search: newParams,
      });
    }
  }, [contextId, params, pathname, setApiParams]);

  return (
    <StepContainer data-testid='select-package-step-container'>
      <Header className='p2' data-testid='select-your-package-header'>
        {isManualBulkOrder
          ? t(`${basePath}.manualBulkHeaderDescription`)
          : t(`${basePath}.headerDescription`)}
      </Header>
      <SearchContainer
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
        data-testid='package-search'
        placeholder={t(`${basePath}.searchPlaceholder`)}
      />
      <ConditionalLoadingRender shouldRender={!packagesLoading}>
        <SelectablePackagesList
          fetchedPackages={packages}
          count={count}
          hierarchy_present={hierarchy_present}
          accountId={accountId}
          node={node}
          localeType={localeType}
          params={params}
          handlePaginationClick={handlePageNumberChange}
          update={update}
          selectedPackage={selectedPackage}
          isManualBulkOrder={isManualBulkOrder}
          showPrice={showPrice}
        />
      </ConditionalLoadingRender>
      <Footer>
        <M.Button
          type='button'
          data-testid='select-package-back-btn'
          onClick={handleBack}
        >
          {t(`${basePath}.backButton`)}
        </M.Button>
        <M.Button
          type='button'
          data-testid='select-package-continue-btn'
          disabled={continueDisabled}
          onClick={handleContinue}
        >
          {t(`${basePath}.continueButton`)}
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default SelectPackageStep;
