import React, { useCallback } from 'react';
import { emailIsValid } from '@dashboard-experience/utils';
import { useUpdateCandidate } from 'api/candidate';

import { FieldComponentProps } from '../../../types';
import RedesignEmail from './redesign-email';

const Email: React.FC<FieldComponentProps> = ({
  fieldKey,
  allowCandidateEdit,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
  currentUser,
  testid,
}) => {
  const { email, id: candidateId } = candidate;
  const updatedEmail = candidateUpdates?.email;

  // updatedEmail may be empty string
  const displayEmail = updatedEmail === undefined ? email : updatedEmail;

  const successCallback = useCallback(
    (email: string) => setCandidateUpdates?.({ ...candidateUpdates, email }),
    [candidateUpdates, setCandidateUpdates],
  );

  const { updateCandidateCall } = useUpdateCandidate(
    fieldKey,
    'email',
    successCallback,
  );

  const onEmailEditSubmit = useCallback(
    (newEmailValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          email: newEmailValue,
        });
        if (!emailIsValid(newEmailValue)) {
          return;
        }
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  return (
    <div data-testid={testid}>
      <RedesignEmail
        candidate={candidate}
        allowEdit={allowCandidateEdit || false}
        displayEmail={displayEmail}
        onEmailEditSubmit={onEmailEditSubmit}
      />
    </div>
  );
};

export default Email;
