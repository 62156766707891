import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

const FlexContainerWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;
  width: 100%;
  max-width: none;
  margin: 1em 1em;
`;

const FlexGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2.1rem;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const FlexCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0 auto;
  flex: 1;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const IconColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 1rem;
`;

const TextColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
`;

const CardHeading = styled.h5`
  color: ${colors.uiTextPrimaryLight} !important;
  font-size: 1rem !important;
  line-height: 1;
  margin-bottom: 1rem;
`;

const CardBody = styled.p`
  color: ${colors.uiTextSecondaryLight} !important;
  font-size: 1rem !important;
  line-height: 24px;
`;

const IconWrapGreen = styled.div`
  background-color: ${colors.uiGreen500};
  border-radius: 2rem;
  color: ${colors.brandSlate1};
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InstructionTitle = styled.p`
  color: ${colors.brandNavy4};
  font-size: 1.3rem !important;
  font-weight: bold;
  line-height: 1 !important;
  align-self: center;
  margin-bottom: 6px !important;
`;

const PARSEducationInstructions: FC = () => {
  return (
    <M.Grid>
      <M.GridRow>
        <M.GridCol>
          <InstructionTitle data-testid='instruction-title'>
            Establishing your program
          </InstructionTitle>
        </M.GridCol>
      </M.GridRow>

      <FlexContainerWithBorder>
        <FlexGrid>
          <FlexCard>
            <IconColumn>
              <IconWrapGreen>
                <M.Icon icon='Login' size={24} />
              </IconWrapGreen>
            </IconColumn>
            <TextColumn>
              <CardHeading>Use any device</CardHeading>
              <CardBody>
                After you share a link below, reporters can use any device with
                Internet access to schedule a test without logging in to Checkr.
              </CardBody>
            </TextColumn>
          </FlexCard>

          <FlexCard>
            <IconColumn>
              <IconWrapGreen>
                <M.Icon icon='Time' size={24} />
              </IconWrapGreen>
            </IconColumn>
            <TextColumn>
              <CardHeading>Schedule 24/7</CardHeading>
              <CardBody>
                Reporters can select a testing site or schedule onsite
                collection at any time. <br /> <br />
                Checkr shows the most cost-effective options first.
              </CardBody>
            </TextColumn>
          </FlexCard>

          <FlexCard>
            <IconColumn>
              <IconWrapGreen>
                <M.Icon icon='Connect' size={24} />
              </IconWrapGreen>
            </IconColumn>
            <TextColumn>
              <CardHeading>Get results fast</CardHeading>
              <CardBody>
                Checkr quickly shares your employee&apos;s results. <br />
                <br /> Your invoice shows only completed tests.
              </CardBody>
            </TextColumn>
          </FlexCard>
        </FlexGrid>
      </FlexContainerWithBorder>
    </M.Grid>
  );
};

export default PARSEducationInstructions;
