import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { AssessmentVariant } from 'types/report/assessmentVariants';
import { StatusTypes } from '@dashboard-experience/utils';

type Props = {
  onSelect: (assessmentId: string) => void;
  option: AssessmentVariant;
  isSelected: boolean;
};

const StatusBadge = styled(M.StatusBadge)`
  margin-left: 0.5rem;
`;

const StyledActionMenuItem = styled(M.ActionMenuItem)<{ isSelected: boolean }>`
  .mastodon-action-menu-item-info {
    border: ${({ isSelected }) => (isSelected ? '2px solid #d1e4f8' : 'none')};
    border-radius: 10px;
    outline: ${({ isSelected }) => (isSelected ? '2px solid #5399df' : 'none')};
    padding: 8px 16px 8px 12px;
  }

  .mastodon-action-menu-item-title {
    width: 100%;
  }

  .mastodon-action-menu-item-content {
    padding: 6px 0px 8px 7px !important;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AssessmentDropdownItem: React.FC<Props> = ({
  onSelect,
  option,
  isSelected,
}) => {
  const onClick = useCallback(() => {
    onSelect(option.id);
  }, [onSelect, option.id]);

  const { t } = useTranslation();
  const displayLabel = (
    <span data-testid='assessment-dropdown-item-display-label'>
      {option.display_label}
    </span>
  );

  const { decision } = option.current_variant;
  const title = (
    <StyledTitle>
      <div>
        {displayLabel}
        <StatusBadge
          data-testid='all-assessments-action-menu-item-status-badge'
          statusType={StatusTypes.Assess}
          status={decision}
        />
      </div>
      <M.StatusIcon icon={decision} />
    </StyledTitle>
  );

  const adjudication =
    option.current_variant.adjudication ||
    t(`${namespace}:report.adjudication-decision.none`);

  return (
    <StyledActionMenuItem
      title={title}
      description={adjudication}
      onClick={onClick}
      isSelected={isSelected}
    />
  );
};

export default AssessmentDropdownItem;
