import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { useExceptions, useReport } from 'containers/Report';
import { useCandidate, useCandidateExceptions } from 'providers/Candidate';
import React from 'react';

import useFetchCandidateDocuments from 'api/candidateDocuments/hooks';
import {
  Addresses,
  Aliases,
  Body,
  CandidateDocuments,
  CandidateStories,
  Exceptions,
  InternationalCandidateAddresses,
} from 'components/Report';
import HealthScreeningAppointmentPane from 'components/Report/Screenings/DrugHealthScreenings/HealthScreeningAppointmentPane';
import { CANDIDATE_REDESIGN } from 'Flags';
import CandidateActions from 'modules/adjudication/ui/report/actions';
import SecondaryPanelReports from 'modules/adjudication/ui/report/actions/secondary-panel-reports';
import InternalNotes from 'modules/case-management/ui/InternalNotes';
import { BetaState, useBetaState } from 'providers/Beta';

type Props = {
  showCustomerViewToggle: boolean;
  customerView: boolean;
  customerViewProps: GenericObject;
  toggleCustomerView: () => void;
  showInternalNotes: boolean;
  showCustomerViewComponents: boolean;
};

const ReportContent: React.FC<Props> = ({
  showCustomerViewToggle,
  customerView,
  customerViewProps,
  toggleCustomerView,
  showInternalNotes,
  showCustomerViewComponents,
}) => {
  const report = useReport();
  const candidate = useCandidate();
  const candidateDocuments = useFetchCandidateDocuments(candidate.id);
  const reportExceptions = useExceptions();
  const candidateExceptions = useCandidateExceptions();
  const betaState = useBetaState(CANDIDATE_REDESIGN);
  const showLegacyExceptions = betaState === BetaState.DISABLED;

  return (
    <>
      <M.Grid>
        <M.GridRow
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(365px, 1fr))',
            gridColumnGap: '30px',
          }}
        >
          <div>
            <CandidateActions report={report} />
          </div>
          <div>
            <SecondaryPanelReports report={report} />
          </div>
        </M.GridRow>
      </M.Grid>
      {!!report.appointment && (
        <HealthScreeningAppointmentPane appointment={report.appointment} />
      )}
      {showCustomerViewToggle && (
        <M.Expandable
          title='Customer View'
          initialExpanded={customerView}
          onExpand={toggleCustomerView}
          titleRowProps={customerViewProps}
        />
      )}
      {showInternalNotes && <InternalNotes />}
      {showCustomerViewComponents && (
        <>
          {!!candidateDocuments?.length && (
            <CandidateDocuments documents={candidateDocuments} />
          )}

          {!!reportExceptions?.exceptions?.length && showLegacyExceptions && (
            <Exceptions
              report={report}
              reportExceptions={reportExceptions.exceptions}
              candidateExceptions={[]}
              meta={reportExceptions.meta}
            />
          )}
          {!!candidateExceptions?.length && showLegacyExceptions && (
            <Exceptions
              report={report}
              reportExceptions={[]}
              candidateExceptions={candidateExceptions}
              meta={[]}
            />
          )}
          {!!report.candidate_stories?.length && (
            <CandidateStories report={report} />
          )}
          <Aliases report={report} />
          <Addresses report={report} />
          <InternationalCandidateAddresses report={report} />
          <Body />
        </>
      )}
    </>
  );
};

export default ReportContent;
