import { M, RECORD_TYPES } from '@dashboard-experience/mastodon';
import { useRecordsFilter } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import React from 'react';
import { GenericObject, Report } from 'types';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { useFlag } from '@dashboard-experience/react-flagr';
import { handleAiClick } from '../../../../api/aiExplainr';

type Props = {
  search: GenericObject;
  filteredRecords: number;
  report: Report;
  reportStatusType: string;
  filter?: string;
};

const FilteredByAdjudicationRecords: React.FC<Props> = ({
  search,
  filteredRecords,
  report,
  reportStatusType,
  filter,
}) => {
  const currentUser = useUser();
  const recordsFilter = useRecordsFilter();
  const finalFilter = recordsFilter || filter;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  return (
    <M.Expandable
      title={
        <M.Screening.Heading
          headingLeft={{
            name: `${filteredRecords} Record(s) filtered by ${finalFilter}`,
          }}
        />
      }
    >
      {search.filtered_by_positive_adjudication_records?.map(
        (filteredRecord: GenericObject) => {
          const handleClickWithExplainer = handleAiClick(
            filteredRecord.state || '',
            filteredRecord.county || '',
          );
          return (
            <M.Record
              key={filteredRecord.id}
              record={filteredRecord}
              recordType={RECORD_TYPES.filteredByAdjudication}
              currentUser={currentUser}
              report={report}
              reportStatusType={reportStatusType}
              chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
              chargesExplainer={
                aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
              }
            />
          );
        },
      )}
    </M.Expandable>
  );
};

export default FilteredByAdjudicationRecords;
