import { BillingClient as client, Client as monolith } from 'api';
import { BillingAddress, BillingCustomer } from '../../types/Billing';
import ManualBulkOrderHistory from '../../containers/Billing/ManualBulkOrderHistory/ManualBulkOrderHistory';

export type TaxDocs = {
  id: string;
  document_name: string;
  last_modified: any;
}[];

export type CreditMemos = {
  id: string;
  resource_id: string;
  memo: string;
  effective_at: string;
  total_credit: number;
  available_credit: number;
}[];

export type DownloadInvoiceParams = {
  id: string;
  type: string;
};

export const customer = (accountId: string): Promise<BillingCustomer> => {
  return client.get(`/accounts/${accountId}/billing_preferences`);
};

export const invoices = (
  accountId: string,
): Promise<{ [key: string]: any }> => {
  return client.get(`/accounts/${accountId}/invoices`);
};

export const creditMemos = (accountId: string): Promise<CreditMemos> => {
  return client.get(`/accounts/${accountId}/credit_memos`);
};

export const downloadCreditMemo = (resource_id: string) => {
  return client.get(`/credit_memos/${resource_id}/pdf`);
};

export const taxDocuments = (): Promise<TaxDocs> => {
  return client.get('/documents');
};

export const downloadInvoiceFile = (params: DownloadInvoiceParams) => {
  return client.get(`/invoices/${params.id}/files/${params.type}`);
};

export const downloadTaxDocument = (id: string) => {
  return client.get(`/documents/${id}`);
};

export type FreeCredit = {
  amount: number;
  accountId: string;
};

export const credit = (accountId: string): Promise<FreeCredit> => {
  return client.get(`/accounts/${accountId}/credit`).then(data => ({
    amount: data.amount * 100,
    accountId: data.account_id,
  }));
};

export const bulkOrderHistory = (
  accountId: string,
  page: number,
  per_page: number,
) => {
  return monolith.get(`/accounts/${accountId}/csv_uploads`, {
    params: { page, per_page },
  });
};

export const aggregatedBulkOrderHistory = (accountId: string) => {
  return monolith.get(`/accounts/${accountId}/aggregated_csv_uploads`);
};

export const aggregatedManualBulkOrderHistory = (
  accountId: string,
): Promise<ManualBulkOrderHistory[]> => {
  return monolith.get(`apply/bulk/history/${accountId}`);
};

export const downloadBulkOrderHistoryFile = (
  s3ObjectKey: string,
): Promise<string> => {
  return monolith.get(`apply/bulk/csv`, {
    params: { 's3-object-key': s3ObjectKey },
  });
};

export const putBillingCustomer = (params: BillingCustomer) => {
  return client.put(
    `/accounts/${params.account_id}/billing_preferences`,
    params,
  );
};

export type BillableNodePayload = {
  custom_id: string;
  billable: boolean;
  billing_address?: BillingAddress;
  primary_email?: string;
  additional_emails?: string[];
};

export const putBillableNode = (params: BillableNodePayload) => {
  return monolith.put(
    `/v1/nodes/${params.custom_id}/billing_preferences`,
    params,
  );
};

export const getNodesWithBillingPrefs = () => {
  return monolith.get(`/v1/nodes/billing_preferences`);
};

export const getCurrentAccountBalance = (accountId: string) => {
  return client.get(`/accounts/${accountId}/open_balance`);
};
