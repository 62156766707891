import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  PasswordHelperDropdown,
  PasswordInputForm,
  PasswordInputWrapper,
} from './styles';
import PasswordHelperRow from './PasswordHelperRow';

const PASSWORD_HELPERS = [
  {
    id: 'minimum_characters',
    validator: (input: string) => input?.length >= 8,
  },
  {
    id: 'special_character',
    validator: (input: string) => /[!@#$%^&*]/.test(input),
  },
  {
    id: 'uppercase_letter',
    validator: (input: string) => /[A-Z]/.test(input),
  },
  {
    id: 'lowercase_letter',
    validator: (input: string) => /[a-z]/.test(input),
  },
  {
    id: 'has_number',
    validator: (input: string) => /\d/.test(input),
  },
];

type Size = 'sm' | 'md' | 'lg';

type PasswordProps = {
  id: string;
  name: string;
  width?: string;
  value?: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  size?: Size;
  invalid?: boolean;
  invalidText?: any;
  placeholder?: string;
  disabled?: boolean;
};

const PasswordInput = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  invalidText,
  width,
  placeholder,
  invalid = false,
  size = 'md',
  disabled = false,
}: PasswordProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation('signup');

  useEffect(() => {
    const passwordElement = document.getElementById('password');
    if (passwordElement) {
      passwordElement.addEventListener('blur', () => setIsOpen(false));
      passwordElement.addEventListener('focus', () => {
        if (value && value.length > 0) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      });
    }
  }, [value]);

  useEffect(() => {
    if (isEmpty(value)) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [value]);

  return (
    <PasswordInputWrapper>
      <PasswordInputForm
        labelText='Password'
        placeholder={placeholder}
        width={width}
        size={size}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        invalidText={invalidText}
        data-testid='password-form-input'
        disabled={disabled}
        autoComplete='off'
      />
      {isOpen && (
        <PasswordHelperDropdown>
          {PASSWORD_HELPERS.map(helper => {
            const helper_text = t(
              `components.PasswordInput.helper_text.${helper.id}`,
            );
            return (
              <PasswordHelperRow
                key={helper_text}
                text={helper_text}
                value={value}
                validator={helper.validator}
              />
            );
          })}
        </PasswordHelperDropdown>
      )}
    </PasswordInputWrapper>
  );
};

export default PasswordInput;
