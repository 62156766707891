import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const getLabelText = (itemType: string) => {
  switch (itemType) {
    case 'transcribed_issued_date':
      return 'Transcribed issued date';
    case 'privilege_to_drive':
      return 'Privilege to drive';
    default:
      return '';
  }
};

const getTooltipText = (itemType: string) => {
  switch (itemType) {
    case 'transcribed_issued_date':
      return 'This information was transcribed from a license provided by the candidate.';
    case 'privilege_to_drive':
      return 'Privilege to drive status is determined by validity of the license, license class, and expiration date. If one or more of these is invalid, then Privilege to Drive is invalidated.';
    default:
      return '';
  }
};

const ItemKeyWithTooltip: React.FC<{ itemType: string }> = ({ itemType }) => {
  const labelText = getLabelText(itemType);
  const tooltipText = getTooltipText(itemType);
  return (
    <div>
      <M.TooltipLabel>{labelText}</M.TooltipLabel>
      <M.TooltipDefinition
        align='top'
        highlighted={false}
        openOnHover={false}
        definition={tooltipText}
      >
        <M.Icon icon='HelpFilled' />
      </M.TooltipDefinition>
    </div>
  );
};

export default ItemKeyWithTooltip;
