import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { SCREENING_TITLES } from 'utils/ReportUtils';

// eslint-disable-next-line import/prefer-default-export
export const isCandidateReportURL = (url: string) => {
  const pattern = /candidates\/[a-f0-9]+\/reports\/[a-f0-9]+/;

  return pattern.test(url);
};

const switchGlobalWatchlistFromTerroristWatchlist = (
  screenings: {
    key: string;
  }[],
): ScreeningType[] => {
  const result = screenings.map(screening => {
    if (screening.key === 'terrorist_watchlist_search') {
      return ScreeningType.global_watchlist_search;
    }
    return screening.key as ScreeningType;
  });
  return result;
};

export const getReportPackageScreenings = (report: any) => {
  const summary = [];

  for (const key of Object.keys(
    SCREENING_TITLES,
  ) as (keyof typeof SCREENING_TITLES)[]) {
    if (report[key]) {
      const screeningObj = report[key];

      let count = 0;

      if (Array.isArray(screeningObj)) {
        count = screeningObj.length;
      } else if (screeningObj) {
        count = 1;
      }
      if (count > 0) {
        summary.push({
          key,
        });
      }
    }
  }
  const screeningsAlreadyOnReport: ScreeningType[] =
    switchGlobalWatchlistFromTerroristWatchlist(summary);

  return screeningsAlreadyOnReport;
};
