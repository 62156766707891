import React from 'react';
import { FieldComponentProps } from '../../types';

export const reportSourceMap: { [key: string]: string } = {
  api: 'API',
  continuous_check: 'Continuous Check',
  form: 'Form',
  manual_order: 'Manual Order',
  recurrence: 'Recurrence',
  web: 'Web',
};

const Source: React.FC<Pick<FieldComponentProps, 'report' | 'testid'>> = ({
  report,
  testid,
}) => {
  const source = report.source ? reportSourceMap[report.source] : '-';
  return <div data-testid={testid}>{source}</div>;
};

export default Source;
