import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { Package } from 'types';
import ShowLinkModal from './ShowLinkModal';

interface PackageCardProps {
  pkg: Package;
}

const FlexCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid ${colors.borderPrimaryLight};
  border-left: 6px solid ${colors.brandNavy1};
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-sizing: border-box;
  min-height: 180px;
  flex: 1;
  max-width: 600px;
  width: 100%;
`;

const CardContent = styled.div`
  flex-grow: 1;
`;

const CardHeading = styled.h5`
  color: ${colors.uiTextPrimaryLight};
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TestList = styled.ul`
  list-style: disc;
  padding: 0;
`;

const TestItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;

  & > svg {
    margin-right: 0.5rem;
    color: ${colors.uiGreen500};
  }
`;

const ShowLinkButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.brandSlate3} !important;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  & > svg {
    margin-right: 0.5rem;
  }
`;

const PackageCard: React.FC<PackageCardProps> = ({ pkg }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = useCallback(() => {
    setShowModal(prev => !prev);
  }, []);

  return (
    <FlexCardWrapper data-testid='package-card'>
      <CardContent>
        <CardHeading title={pkg.name}>{pkg.name}</CardHeading>
        <TestList>
          {Array.isArray(pkg.enabled_exams) &&
            pkg.enabled_exams.map(
              exam =>
                exam.services?.length > 0 && (
                  <TestItem key={`${pkg.id}-${exam.name}`}>
                    <M.Icon icon='Checkmark' size={16} />
                    {exam.services[0].label}
                  </TestItem>
                ),
            )}
        </TestList>
      </CardContent>
      <ShowLinkButton type='button' onClick={handleModalToggle}>
        <M.Icon icon='SendAlt' size={16} />
        Show link
      </ShowLinkButton>
      <ShowLinkModal
        applyUrl={pkg.apply_url}
        packageName={pkg.name}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </FlexCardWrapper>
  );
};

export default PackageCard;
