import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import Guard from './guard';
import DeferModal from '../flow/modal/modal';

const StyledButton = styled(M.Button)`
  width: 100%;
  max-width: none !important;
  justify-content: center;
`;

const Defer: React.FC = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const buttonTitle = t(`${namespace}:report.actions.defer.action`);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      {
        Action: 'Mark candidate deferred',
        Source: 'Adverse Action Defer Button',
      },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpen]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const disabled =
    report.archived || report.adjudication === ADJUDICATION_VALUES.DEFERRED;

  return (
    <Guard>
      <StyledButton
        id='defer-button'
        data-testid='defer-button'
        disabled={disabled}
        kind='secondary'
        onClick={handleOpen}
      >
        {buttonTitle}
      </StyledButton>
      {open && <DeferModal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default Defer;
