/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Base from 'modules/assess/models/assignables/base';
import { useUser } from 'context/CurrentUser';
import { usePackages } from 'api/packages';
import { useFetchAllNodes } from 'api/nodes';
import { useList } from 'api/geos';
import { useJobRoles } from 'api/jobRoles';
import { useGetStates } from 'api/location';
import { toastError } from 'actions';
import { Assignment, Ruleset } from 'modules/assess/models/rulesets';
import { SelectedAssignments } from 'modules/assess/models/rulesets/assignment';
import Selector from './Selector';

const GEOS = 'geos';
const ROLES = 'roles';
const SEGMENTS = 'segments';
const PACKAGES = 'packages';
const STATES = 'states';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > h5 {
    color: ${colors.textIconBlackPrimary};
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.16px;
    margin-bottom: 0 !important;
  }

  & > p {
    color: ${colors.textIconBlackSecondary78};
    font-size: 0.75rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 0 !important;
  }
`;

const StyledListItem = styled(M.ListItem)`
  color: ${colors.textIconBlackSecondary78};
  font-size: 0.75rem !important;
  font-weight: 400;
`;

const StyledLink = styled(M.Link)`
  color: ${colors.linkDefaultNavy600};
  font-size: 0.75rem !important;
  text-decoration: none;
`;

const Label = styled.p`
  color: ${colors.textIconBlackSecondary78};
  font-size: 0.75rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 500px;
  margin-top: 0 !important;
`;

const Divider = styled(M.Divider)`
  margin: 16px 0 16px 0 !important;
`;

type StateData = { [key: string]: string };

export interface Items {
  account: string[];
  geo: string[];
  segment: string[];
  package: string[];
  jobrole: string[];
  state: string[];
}
type Props = {
  assignments?: Assignment.List;
  currentItems: SelectedAssignments;
  rulesets?: Array<Ruleset.Type>;
  setAssignments?: React.Dispatch<React.SetStateAction<Assignment.List>>;
  setCurrentItems: React.Dispatch<React.SetStateAction<SelectedAssignments>>;
};

export const Edit: React.FC<Props> = ({
  assignments = [],
  currentItems,
  rulesets,
  setAssignments,
  setCurrentItems,
}) => {
  const { t } = useTranslation('');
  const [isAccountDefault, setIsAccountDefault] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [statesList, setStatesList] = useState<string[]>([]);
  const [items, setItems] = useState<Items>({
    account: [],
    geo: [],
    segment: [],
    package: [],
    jobrole: [],
    state: [],
  });

  const { account } = useUser();
  const accountId = account?.id || '';

  const existingAccountDefault = rulesets?.find(
    (ruleset: Ruleset.Type) =>
      Array.isArray(ruleset.assignments) &&
      ruleset.assignments.length === 1 &&
      ruleset.assignments[0].id === 'ACCOUNT_DEFAULT_ID',
  );

  const accountDefaultLabel = t(
    `assess:ruleset:apply:selectors:default:label`,
    {
      existing_default: existingAccountDefault?.name,
    },
  );

  const getInitialItem = (type: Base.Kind | undefined) => {
    if (!type) return [];

    const filteredAssignments = assignments.filter(
      assignment => type in assignment,
    );

    const mapAssignments = (items: { [key: string]: any }) => {
      return items.map((item: { id: string; name: string; metadata: any }) => ({
        id: item.id,
        label: item.metadata ? item.metadata[`${type}_name`] : item?.name,
      }));
    };

    return mapAssignments(Object.values(filteredAssignments));
  };

  const mapToItems = (items: { [key: string]: any }) => {
    return items.map((item: { id: string; name: string }) => ({
      id: item.id,
      label: item.name,
    }));
  };

  const handleGetStatesSuccess = (data: StateData | null) => {
    if (data) {
      const states = Object.values(data);
      const stateItems = mapToItems(states);
      setItems(prevItems => ({ ...prevItems, state: stateItems }));
      setStatesList(states);
    }
  };

  const handleGetStatesError = (error: any) => {
    toastError(error);
  };

  const { data: packages, isLoading: packagesIsLoading } = usePackages(
    accountId,
    new URLSearchParams({}),
  );

  const {
    nodes,
    count,
    isLoading: nodesIsLoading,
  } = useFetchAllNodes({ account });

  const { data: geos, isLoading: geosIsLoading } = useList({ accountId });

  const { data: jobRoles, isLoading: jobRolesIsLoading } = useJobRoles({
    accountId,
  });

  useGetStates(handleGetStatesSuccess, handleGetStatesError);

  useEffect(() => {
    if (
      !packagesIsLoading &&
      !nodesIsLoading &&
      !geosIsLoading &&
      !jobRolesIsLoading
    ) {
      setShowLoader(false);
      setIsAccountDefault(currentItems.account.length > 0);
    }
  }, [
    currentItems.account.length,
    geosIsLoading,
    jobRolesIsLoading,
    nodesIsLoading,
    packagesIsLoading,
  ]);

  useEffect(() => {
    if (packages && packages.length > 0) {
      const packageItems = mapToItems(packages);
      setItems(prevItems => ({ ...prevItems, package: packageItems }));
    }
  }, [packages]);

  useEffect(() => {
    if (geos && geos?.geos.length > 0) {
      const geoItems = mapToItems(geos?.geos);
      setItems(prevItems => ({ ...prevItems, geo: geoItems }));
    }
  }, [geos]);

  useEffect(() => {
    if (jobRoles && jobRoles?.job_roles.length > 0) {
      const jobRolesItems = mapToItems(jobRoles?.job_roles);
      setItems(prevItems => ({ ...prevItems, jobrole: jobRolesItems }));
    }
  }, [jobRoles]);

  useEffect(() => {
    if (nodes && count > 0) {
      const nodeItems = mapToItems(nodes);
      setItems(prevItems => ({ ...prevItems, segment: nodeItems }));
    }
    // Disabling: adding nodes to the dependency array causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const showFullDescriptionHandler = () => {
    setShowFullDescription(!showFullDescription);
  };

  const setAssignmentState = useCallback(
    (updatedItems: SelectedAssignments) => {
      if (setAssignments) {
        setAssignments(
          [
            updatedItems.account,
            updatedItems.geo,
            updatedItems.segment,
            updatedItems.package,
            updatedItems.role,
            updatedItems.state,
          ]
            .filter(Boolean)
            .flat(),
        );
      }
    },
    [setAssignments],
  );

  const onChange = useCallback(
    (assignmentType: 'geos' | 'roles' | 'segments' | 'packages' | 'states') =>
      (e: any) => {
        const findAndMapAssignments = (
          assignables: any[],
          list: any[],
          type: Base.Kind,
          metadataKeys: any[],
        ) => {
          return assignables.map(assignable => {
            const item = list.find(item => item.id === assignable.id);
            const metadata = metadataKeys.reduce((acc, key) => {
              acc[`${type.toLowerCase()}_${key}`] = item?.[key];
              return acc;
            }, {});
            return {
              id: item?.id,
              name: item?.name,
              type,
              [type.toLowerCase()]: item,
              metadata,
            };
          });
        };

        const assignmentTypeConfig: {
          [key: string]: {
            list: any[];
            type: Base.Kind;
            metadataKeys: string[];
          };
        } = {
          [GEOS]: {
            list: geos?.geos || [],
            type: Base.Kind.GEO,
            metadataKeys: ['name', 'state'],
          },
          [ROLES]: {
            list: jobRoles?.job_roles || [],
            type: Base.Kind.ROLE,
            metadataKeys: ['name'],
          },
          [SEGMENTS]: {
            list: nodes || [],
            type: Base.Kind.SEGMENT,
            metadataKeys: ['name'],
          },
          [PACKAGES]: {
            list: Array.isArray(packages) ? packages : [],
            type: Base.Kind.PACKAGE,
            metadataKeys: ['name'],
          },
          [STATES]: {
            list: statesList || [],
            type: Base.Kind.STATE,
            metadataKeys: ['name'],
          },
        };

        const config = assignmentTypeConfig[assignmentType as string];
        if (config) {
          const selectedItems = e?.selectedItems;
          const newAssignment = findAndMapAssignments(
            selectedItems,
            config.list,
            config.type,
            config.metadataKeys,
          );

          setCurrentItems(prevState => {
            const updatedItems = {
              ...prevState,
              [config.type]: [...newAssignment],
            };

            setAssignmentState(updatedItems);

            return updatedItems;
          });
        }
      },
    [
      geos?.geos,
      jobRoles?.job_roles,
      nodes,
      packages,
      statesList,
      setCurrentItems,
      setAssignmentState,
    ],
  );

  const handleAccountDefaultChange = () => () => {
    setCurrentItems(prevState => {
      const updatedItems = {
        ...prevState,
        account: isAccountDefault
          ? []
          : [
              {
                id: 'ACCOUNT_DEFAULT_ID',
                name: 'ACCOUNT_DEFAULT_ID',
                type: Base.Kind.ACCOUNT,
                metadata: {},
              },
            ],
      };

      setAssignmentState(updatedItems);

      setIsAccountDefault(!isAccountDefault);

      return updatedItems;
    });
  };

  return (
    <div data-testid='assess-ruleset-apply-edit-section'>
      <h4 data-testid='assess-ruleset-apply-edit-header'>
        {t(`assess:ruleset:apply:headers:edit`)}
      </h4>
      <Container>
        <SubHeader>
          <h5 data-testid='assess-ruleset-apply-edit-subheader'>
            {t(`assess:ruleset:apply:sub_headers:edit:title`)}
          </h5>
          <p data-testid='assess-ruleset-apply-edit-subheader-description'>
            {t(`assess:ruleset:apply:sub_headers:edit:short_description`)}
            {showFullDescription ? (
              <>
                <M.UnorderedList nested>
                  <StyledListItem data-testid='long_description_bullet1'>
                    {t(`assess:ruleset:apply:sub_headers:edit:bullet_1`)}
                  </StyledListItem>
                  <StyledListItem data-testid='long_description_bullet2'>
                    {t(`assess:ruleset:apply:sub_headers:edit:bullet_2`)}
                  </StyledListItem>
                </M.UnorderedList>
                <StyledLink onClick={showFullDescriptionHandler} href='#'>
                  {t(`assess:ruleset:apply:sub_headers:edit:see_less`)}
                </StyledLink>
              </>
            ) : (
              <StyledLink onClick={showFullDescriptionHandler} href='#'>
                {t(`assess:ruleset:apply:sub_headers:edit:see_more`)}
              </StyledLink>
            )}
          </p>
        </SubHeader>
        {showLoader ? (
          <M.LoadingSpinner data-testid='loading-spinner' />
        ) : (
          <>
            {items.jobrole.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.role}
                initialSelectedItems={getInitialItem(Base.Kind.ROLE)}
                items={items.jobrole}
                labels={{
                  label: t(`assess:ruleset:apply:selectors:role:label`),
                  long_label: t(
                    `assess:ruleset:apply:selectors:role:long_label`,
                  ),
                  single_label: t(
                    `assess:ruleset:apply:selectors:role:single_label`,
                  ),
                  plural_label: t(
                    `assess:ruleset:apply:selectors:role:plural_label`,
                  ),
                }}
                onChange={onChange(ROLES)}
                testId='assess-ruleset-apply-role-selector'
              />
            )}
            {items.package.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.package}
                initialSelectedItems={getInitialItem(Base.Kind.PACKAGE)}
                items={items.package}
                labels={{
                  label: t(`assess:ruleset:apply:selectors:package:label`),
                  long_label: t(
                    `assess:ruleset:apply:selectors:package:long_label`,
                  ),
                  single_label: t(
                    `assess:ruleset:apply:selectors:package:single_label`,
                  ),
                  plural_label: t(
                    `assess:ruleset:apply:selectors:package:plural_label`,
                  ),
                }}
                onChange={onChange(PACKAGES)}
                testId='assess-ruleset-apply-package-selector'
              />
            )}
            {items.segment.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.segment}
                initialSelectedItems={getInitialItem(Base.Kind.SEGMENT)}
                items={items.segment}
                labels={{
                  label: t(`assess:ruleset:apply:selectors:node:label`),
                  long_label: t(
                    `assess:ruleset:apply:selectors:node:long_label`,
                  ),
                  single_label: t(
                    `assess:ruleset:apply:selectors:node:single_label`,
                  ),
                  plural_label: t(
                    `assess:ruleset:apply:selectors:node:plural_label`,
                  ),
                }}
                onChange={onChange(SEGMENTS)}
                testId='assess-ruleset-apply-node-selector'
              />
            )}
            {items.geo.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.geo}
                initialSelectedItems={getInitialItem(Base.Kind.GEO)}
                items={items.geo}
                labels={{
                  label: t(`assess:ruleset:apply:selectors:geo:label`),
                  long_label: t(
                    `assess:ruleset:apply:selectors:geo:long_label`,
                  ),
                  single_label: t(
                    `assess:ruleset:apply:selectors:geo:single_label`,
                  ),
                  plural_label: t(
                    `assess:ruleset:apply:selectors:geo:plural_label`,
                  ),
                }}
                onChange={onChange(GEOS)}
                testId='assess-ruleset-apply-geo-selector'
              />
            )}
            {items.state.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.state}
                initialSelectedItems={getInitialItem(Base.Kind.STATE)}
                items={items.state}
                labels={{
                  label: t(`assess:ruleset:apply:selectors:state:label`),
                  long_label: t(
                    `assess:ruleset:apply:selectors:state:long_label`,
                  ),
                  single_label: t(
                    `assess:ruleset:apply:selectors:state:single_label`,
                  ),
                  plural_label: t(
                    `assess:ruleset:apply:selectors:state:plural_label`,
                  ),
                }}
                onChange={onChange(STATES)}
                testId='assess-ruleset-apply-state-selector'
              />
            )}
          </>
        )}
      </Container>
      <Divider />
      <M.Toggle
        data-testid='assess-ruleset-apply-default-toggle'
        id='assess-ruleset-apply-default-toggle'
        labelA={<Label>{accountDefaultLabel}</Label>}
        labelB={<Label>{accountDefaultLabel}</Label>}
        onToggle={handleAccountDefaultChange()}
        toggled={isAccountDefault}
      />
    </div>
  );
};

export default Edit;
