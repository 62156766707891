import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Option from 'modules/assess/models/context-date-option';
import { useState as useContextDatesState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { Button } from 'modules/assess/ui/page';
import * as Dialog from './create/Container';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Params = {
  availableOptions: Option.List;
};

const Container: React.FC<Params> = ({ availableOptions }) => {
  const { disabled } = useContextDatesState();
  const [open, show] = useState(false);
  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  if (disabled || !availableOptions.length) {
    return null;
  }

  return (
    <StyledContainer>
      <Button
        kind='secondary'
        onClick={handleClick}
        size='sm'
        trans='assess:context_dates.create.button'
      />
      <Dialog.Container
        open={open}
        onClose={handleClose}
        options={availableOptions}
      />
    </StyledContainer>
  );
};

export default Container;
