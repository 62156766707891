import { ReportStatuses } from '@dashboard-experience/utils';
import { titleCase } from 'humanize-plus';
import { isoCountry } from 'utils';

const formatCountry = (country: string) =>
  `${titleCase(isoCountry(country))} (${country})`;

export const getInternationalRecordStatusOrAssessment = (
  status: string,
  assessment?: string | null,
) => {
  return status === ReportStatuses.CONSIDER && assessment ? assessment : status;
};

export default formatCountry;
