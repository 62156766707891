import React from 'react';
import Menu from 'modules/assess/ui/ruleset/menu';
import { useDestroy } from 'modules/assess/ui/ruleset/api';
import { useDraftDestroy } from 'modules/assess/ui/ruleset/version/api';
import * as Entity from 'modules/assess/models/rulesets/ruleset';

type Props = {
  ruleset: Entity.Type;
  rulesets: Array<Entity.Type>;
};

export const RulesetMenu: React.FC<Props> = ({ ruleset, rulesets }) => {
  const destroy = useDestroy(ruleset.id);
  const destroyDraft = useDraftDestroy(ruleset.id);

  return (
    <Menu
      key='menu'
      kind='options'
      ruleset={ruleset}
      rulesets={rulesets}
      onDelete={destroy.call}
      onDeleteDraft={destroyDraft.call}
    />
  );
};

export default RulesetMenu;
