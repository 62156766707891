import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import AutopayEnabledBadge from './AutopayEnabledBadge';

const AutopayIndicator = ({ enabled }) => {
  const { t } = useTranslation();

  return enabled ? (
    <AutopayEnabledBadge />
  ) : (
    <M.StatusBadge status='escalated' label={t(`payment.autopay.disabled`)} />
  );
};

AutopayIndicator.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default AutopayIndicator;
