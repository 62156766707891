import { AiExplainerClient } from 'api';
import { GenericObject } from 'types';

export const fetchAiExplanation = (
  type: string,
  data: {},
): Promise<{ [key: string]: any }> => {
  return AiExplainerClient.post(`/explain`, { type, data });
};

export const createThumbsUpFeedback = (
  explanationId: string,
  type: string,
): Promise<GenericObject> => {
  return AiExplainerClient.post(
    `/explanations/${explanationId}/feedback/thumbs_up`,
    { type },
  );
};

export const createThumbsDownFeedback = (
  explanationId: string,
  type: string,
  reason: string | null,
): Promise<GenericObject> => {
  const payload = { type, reason };

  return AiExplainerClient.post(
    `/explanations/${explanationId}/feedback/thumbs_down`,
    payload,
  );
};
