import { Tab } from 'components/DashboardWrapper/tabs';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { FlagrContext } from '@dashboard-experience/react-flagr';
import NavElement from './nav-element';

const TRANSLATION_KEY_PREFIX = 'navigation';

type Props = {
  tab: Tab;
  titleKey: string;
  currentPath: string;
  navCollapsed: boolean;
};

/** Creates a Nav Element based off of a `tab` object as declared in the Constants file */
const PredefinedNavElement: React.FC<Props> = ({
  tab,
  titleKey,
  currentPath,
  navCollapsed,
}) => {
  const user = useUser();
  const { flags } = useContext(FlagrContext) as any;

  const { t } = useTranslation('', { keyPrefix: TRANSLATION_KEY_PREFIX });

  const url = typeof tab.url === 'function' ? tab.url(user, flags) : tab.url;

  const isActive = useMemo(() => {
    return currentPath.startsWith(tab.activePrefix || url);
  }, [currentPath, tab.activePrefix, url]);

  // Don't show the tab if the user doesn't have the proper permissions/flags for it
  if (!tab.authorized(user, flags)) {
    return null;
  }

  const { openNewTab } = tab;

  const trackingEventName = tab.tracking?.(user, flags);

  return (
    <NavElement
      isActive={isActive}
      navCollapsed={navCollapsed}
      openNewTab={openNewTab}
      text={t(titleKey)}
      url={url}
      tabName={titleKey}
      trackingEventName={trackingEventName}
    />
  );
};

export default PredefinedNavElement;
