import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_ADDONS_STATE_COUNTY } from 'Flags';
import { State } from 'components/AddScreenings/types';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';
import { AdditionalCrimProps } from './shared/types/AdditionalCrimProps.types';

const DISABLED_STATES = [
  'AZ',
  'AR',
  'CA',
  'CT',
  'FL',
  'GA',
  'HI',
  'IL',
  'IN',
  'KS',
  'LA',
  'MA',
  'ME',
  'MN',
  'MS',
  'NV',
  'NH',
  'OH',
  'SC',
  'TN',
  'TX',
  'VA',
  'WV',
  'WY',
];

type FlexProps = {
  direction?: string;
  width?: number;
};

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-flow: ${({ direction }) => direction ?? 'row'};
  width: 100%;
  width: ${({ width }) => `${width}%` ?? '100%'};
`;

const StateSelect = styled(M.StateSelect)`
  margin-right: 16px;
  margin-left: 40px;
  max-width: 208px;

  .cds--form-requirement {
    color: ${colors.uiButtonDestructiveLightDefault};
  }
`;

type StateCriminalSearchProps = ScreeningProps & AdditionalCrimProps;

const StatewideCriminalSearch = ({
  shouldOpen,
  getPriceByType,
  actionProps,
  usingAddChecks = false,
  states = [],
  handlePrice,
}: StateCriminalSearchProps) => {
  const [mappedStates, setMappedStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State>();
  const [error, setError] = useState<string | null>(null);

  const useOrderAddonsStateCounty =
    useFlag(ORDER_ADDONS_STATE_COUNTY)?.variantKey === 'enabled';

  const { onAddClick, basePackageScreenings } = actionProps;
  const showStateCountyDropdowns = useOrderAddonsStateCounty && usingAddChecks;
  const basePackageScreeningsMapped = basePackageScreenings?.map(
    screening => screening.type,
  );

  const handleStateChange = useCallback(
    e => {
      setSelectedState(e);

      if (usingAddChecks) {
        handlePrice();
      }
      if (error) {
        setError(null);
      }
    },
    [usingAddChecks, handlePrice, error, setError],
  );

  useEffect(() => {
    const mapped = states.map(state => {
      if (DISABLED_STATES.includes(state.abbreviation)) {
        return {
          ...state,
          name: `${state.name} - Not Available`,
          disabled: true,
        };
      }

      return state;
    });
    setMappedStates(mapped);
  }, [states]);

  const handleAddScreening = useCallback(() => {
    if (showStateCountyDropdowns && !selectedState?.id) {
      setError('Selection required');
    } else if (showStateCountyDropdowns) {
      onAddClick(ScreeningType.state_criminal_search, {
        subtype: 'current',
        ...selectedState,
      });
    } else {
      onAddClick(ScreeningType.state_criminal_search);
    }
  }, [onAddClick, selectedState, showStateCountyDropdowns]);

  const hasCountySearch = basePackageScreeningsMapped?.includes(
    ScreeningType.county_criminal_search,
  );

  return (
    <FlexContainer data-testid='statewide-criminal-search-section'>
      <Flex direction='column'>
        <Flex direction='row'>
          <ExpandableTitle
            initialExpanded={shouldOpen}
            data-testid='expandable-statewide-criminal-search-section'
            title={
              <Title
                getPriceByType={usingAddChecks ? handlePrice : getPriceByType}
                actionProps={actionProps}
                title={
                  !showStateCountyDropdowns
                    ? 'State criminal search for 7-year address history'
                    : 'State criminal search'
                }
                priceByType='state_criminal_search'
              />
            }
          >
            <ScreeningDescriptionContainer>
              <ScreeningInfo>
                {hasCountySearch
                  ? 'This search of multiple counties in a state can increase found records up to 30%.'
                  : 'In-depth search that can increase found records coverage by up to 47%.'}
              </ScreeningInfo>
            </ScreeningDescriptionContainer>
            <Flex>
              <Box>
                <BoxHeader>Advantages</BoxHeader>
                <ul>
                  <BulletPoint text='Help meet compliance requirements for industries such as healthcare and childcare.' />
                  <BulletPoint text='A state criminal search can be more cost-effective than searching each county separately.' />
                </ul>
              </Box>
              <BoxDivider />
              <Box>
                <BoxHeader>Considerations</BoxHeader>
                <ul>
                  <BulletPoint text='Not offered in every state.' />
                  <BulletPoint text='Included counties and data quality vary by state.' />
                  <BulletPoint text='Passthrough fees vary by county.' />
                </ul>
              </Box>
            </Flex>
          </ExpandableTitle>
          <AddButtonContainer>
            <AddScreeningsAction
              screeningType='state_criminal_search'
              {...actionProps}
              onAddClick={handleAddScreening}
            />
          </AddButtonContainer>
        </Flex>
        {showStateCountyDropdowns && (
          <Flex direction='column'>
            <StateSelect
              data-testid='state-crim-state-select'
              placeholder='Select a state'
              titleText={null}
              states={mappedStates}
              selectedState={selectedState}
              onChange={handleStateChange}
              warn={error}
              warnText={error}
              disabled={basePackageScreeningsMapped?.includes(
                ScreeningType.state_criminal_search,
              )}
            />
          </Flex>
        )}
      </Flex>
    </FlexContainer>
  );
};

export default StatewideCriminalSearch;
