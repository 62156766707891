import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { hasPermission } from '@dashboard-experience/utils';
import { GenericObject } from 'types';
import { useGetFullSsn } from 'api/candidate';
import { FieldComponentProps, FullSsnProps } from '../../types';

const IconTextLink = styled.a`
  display: inline-block;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  padding-left: 0.2em;
  color: ${colors.uiGrey900} !important;
  svg {
    position: absolute;
    transform: translateY(-80%);
  }
`;

const FullSsn: React.FC<FullSsnProps> = ({ candidateId }) => {
  const [fullSsn, setFullSsn] = useState(null);
  const { data }: GenericObject = useGetFullSsn(candidateId);
  useEffect(() => {
    if (data) {
      const { full_ssn } = data;
      setFullSsn(full_ssn);
    }
  }, [data]);

  return <span data-testid='full-ssn-text'>{fullSsn}</span>;
};

const SocialSecurity: React.FC<FieldComponentProps> = ({
  candidate,
  testid,
}) => {
  const { id: candidateId, no_ssn, ssn } = candidate;
  const currentUser = useUser();
  const [showFullSsn, setShowFullSsn] = useState(false);
  const allowFullSsnAccess = hasPermission(currentUser, 'view_unencrypted_pii');

  /**
   * @name toggleFullSsn
   * @function
   * @memberOf Ssn
   * @description Toggles the visibility of the full ssn
   */
  const toggleFullSsn = useCallback(
    () => setShowFullSsn(!showFullSsn),
    [showFullSsn],
  );
  const displaySsn = () => {
    let ssnPrefix;

    if (!ssn) {
      ssnPrefix = <p>-</p>;
    } else if (no_ssn) {
      ssnPrefix = <p>Declared None</p>;
    } else if (ssn?.includes('(derived)')) {
      ssnPrefix = <p>-</p>;
    }
    return (
      <span data-testid='ssn-text'>
        {ssnPrefix}
        {showFullSsn ? <FullSsn candidateId={candidateId} /> : ssn}
      </span>
    );
  };

  return (
    <div data-testid={testid}>
      {displaySsn()}
      {allowFullSsnAccess && (
        <>
          {' '}
          <IconTextLink
            onClick={toggleFullSsn}
            data-testid='candidate-information-field-show-full-ssn-button'
          >
            <M.Icon icon={showFullSsn ? 'ViewOff' : 'View'} size='16' />
          </IconTextLink>
        </>
      )}
    </div>
  );
};

export default SocialSecurity;
