import React from 'react';
import type { SelectPackageT } from '..';

const ConditionalErrorRender: React.FC<
  SelectPackageT.ConditionalErrorProps
> = ({ shouldRender, children, error }) => {
  return shouldRender ? <>{children}</> : <em>{error}</em>;
};

export default ConditionalErrorRender;
