import { useFlag } from '@dashboard-experience/react-flagr';
import { SHOW_REPORT_SUMMARY } from 'Flags';
import { namespace } from 'modules/candidate/locales';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionLabel } from '../styles';
import ShowReportSummary from './show-report-summary';
import ShowResolvedExceptions from './show-resolved-exceptions';

const ResultsPage = () => {
  const { t } = useTranslation();
  const showReportSummary = useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';

  const label = t(`${namespace}:report.settings.menu.results-page.label`);

  return (
    <>
      <SectionLabel style={{ marginTop: '1rem' }}>{label}</SectionLabel>
      {showReportSummary && <ShowReportSummary />}
      <ShowResolvedExceptions />
    </>
  );
};

export default ResultsPage;
