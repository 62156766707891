import { usePackagePrice } from 'components/AddScreenings/hooks/usePackagePrice';
import {
  buildPostBodyWithAddOns,
  mapPackage,
} from 'components/AddScreenings/shared/utils';
import useGetUser from './useGetUser';
import useGetPackages from './useGetPackages';
import { useSignupPageContext } from '../../../pages/Signup/context';
import {
  calculateSubtotal,
  getAddOnsTotal,
  getPackagePriceWithAddOns,
  priceToString,
} from '../utils/pricing';
import { countCandidates } from '../utils/candidates';
import BasePackages from '../constants/basePackages';
import { isSelectedPackageInternational } from '../utils/package';

const useCalculatePrices = () => {
  const { data: user } = useGetUser();
  const { data: packages } = useGetPackages();
  const { addOns, selectedPackage, alias, candidates } = useSignupPageContext();
  const basePackagePrice = priceToString(BasePackages[selectedPackage].price);
  const currentPackage = packages?.find((p: any) => p.slug === selectedPackage);
  const sortedAddOns = [...addOns[selectedPackage]].sort((a, b) =>
    a.localeCompare(b),
  );
  const addOnsPrice = parseFloat(
    (getAddOnsTotal(sortedAddOns) / 100).toFixed(2),
  );

  const basePackageQuery = usePackagePrice(
    ['base-package-price', sortedAddOns, selectedPackage, currentPackage],
    { id: user?.account_id },
    currentPackage &&
      buildPostBodyWithAddOns({
        basePackage: mapPackage(currentPackage),
        addedScreeningTypes: addOns[selectedPackage],
        additionalProperties: {},
        packageName: 'custom package',
        setSlug: false,
        isPrivate: false,
        origin: 'customer_via_signup',
      }),
    undefined,
    currentPackage !== undefined,
  );

  const basePackageQueryWithAlias = usePackagePrice(
    ['base-package-price-alias', sortedAddOns, selectedPackage, currentPackage],
    { id: user?.account_id },
    currentPackage && {
      ...buildPostBodyWithAddOns({
        basePackage: { ...mapPackage(currentPackage), aliases_enabled: 'on' },
        addedScreeningTypes: addOns[selectedPackage],
        additionalProperties: {},
        packageName: 'custom package with alias',
        setSlug: false,
        isPrivate: false,
        origin: 'customer_via_signup',
      }),
    },
    undefined,
    currentPackage !== undefined,
  );

  const numCandidates = countCandidates(candidates);
  let subtotal = null;
  let customPackagePrice = null;
  let aliasPrice = null;

  const isBasePackageQueryReady =
    !basePackageQuery.isIdle && !basePackageQuery.isLoading;
  const isBasePackageQueryWithAliasReady =
    !basePackageQueryWithAlias.isIdle && !basePackageQueryWithAlias.isLoading;

  if (
    isBasePackageQueryReady &&
    isBasePackageQueryWithAliasReady &&
    !isSelectedPackageInternational(selectedPackage)
  ) {
    const basePrice = basePackageQuery?.data?.bundled_price ?? 0;
    const aliasBasePrice = basePackageQueryWithAlias?.data?.bundled_price ?? 0;

    aliasPrice = priceToString(aliasBasePrice - basePrice);

    const selectedPackageIsAlias = alias[selectedPackage] === 'true';
    const selectedBasePrice = selectedPackageIsAlias
      ? aliasBasePrice
      : basePrice;

    customPackagePrice = priceToString(selectedBasePrice);
    subtotal = priceToString(
      selectedBasePrice * (numCandidates > 0 ? numCandidates : 1),
    );
  }

  if (isSelectedPackageInternational(selectedPackage)) {
    aliasPrice = priceToString(0);
    customPackagePrice = priceToString(
      getPackagePriceWithAddOns(selectedPackage, sortedAddOns),
    );
    subtotal = calculateSubtotal(selectedPackage, sortedAddOns, candidates);
  }

  return {
    aliasPrice,
    subtotal,
    customPackagePrice,
    basePackagePrice,
    addOnsPrice,
  };
};

export default useCalculatePrices;
