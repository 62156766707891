/**
 * Credit Report
 * @export
 * @interface CreditReport
 */
export interface CreditReport {
  /**
   *
   * @type {string}
   * @memberof CreditReport
   */
  type: 'credit_report';
  global_subtype: 'domestic';
}

export const DEF01 = 'def01';
