import React from 'react';
import { useTranslation } from 'react-i18next';
import { SCREENING } from '../../constants/screenings';
import {
  ScreeningRow,
  ScreeningText,
  Tooltip,
  InformationIcon,
} from './styles';
import IconCheckmark from './IconCheckmark';

const SCREENING_TOOLTIPS: Record<SCREENING, string | undefined> = {
  [SCREENING.NATIONAL_CRIMINAL]: 'nationalCriminal',
  [SCREENING.COUNTY_CRIMINAL]: 'countyCriminal',
  [SCREENING.EDUCATION_VERIFICATION]: 'educationVerification',
  [SCREENING.EMPLOYMENT_VERIFICATION]: 'employmentVerification',
  [SCREENING.FEDERAL_CRIMINAL]: 'federalCriminal',
  [SCREENING.STATE_CRIMINAL]: 'stateCriminal',
  [SCREENING.IDENTITY_DOCUMENT]: 'identityDocumentVerification',
  [SCREENING.SSN_TRACE]: undefined,
  [SCREENING.SEX_OFFENDER_REGISTRY]: undefined,
  [SCREENING.GLOBAL_WATCHLIST]: undefined,
  [SCREENING.INTERNATIONAL_CRIMINAL]: undefined,
};

const ScreeningsListItem: React.FC<{
  screeningName: SCREENING;
  align?: string;
}> = ({ screeningName, align = 'top-right' }) => {
  const { t } = useTranslation('signup');
  const tooltipKey = SCREENING_TOOLTIPS[screeningName];

  return (
    <ScreeningRow>
      <IconCheckmark />
      <ScreeningText>{screeningName}</ScreeningText>
      {tooltipKey && (
        <Tooltip
          align={align}
          label={t(`pages.packagesPage.tooltips.${tooltipKey}`)}
        >
          <InformationIcon icon='Information' size='20px' />
        </Tooltip>
      )}
    </ScreeningRow>
  );
};

export default ScreeningsListItem;
