import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  isOpen: any;
  closeModal: any;
  archive: any;
};

const ModalBody = styled(M.ModalBody)`
  p {
    color: ${colors.coolBlack70};
    padding-right: 0;
  }
`;

const ArchiveInvitationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  archive,
}) => {
  return (
    <M.ComposedModal
      open={isOpen}
      onClose={closeModal}
      data-testid='archive-modal'
    >
      <M.ModalHeader closeModal={closeModal}>
        <h2>Archive invitation?</h2>
      </M.ModalHeader>
      <ModalBody>
        <p>
          Archiving this invitation will remove it from your{' '}
          <strong>Active</strong> tab. You can unarchive it at any time.
        </p>
      </ModalBody>
      <M.ModalFooter>
        <M.Button kind='secondary' onClick={closeModal}>
          Cancel
        </M.Button>
        <M.Button
          type='submit'
          onClick={archive}
          data-testid='confirm-archive-modal'
        >
          Confirm
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ArchiveInvitationModal;
