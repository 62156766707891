import React from 'react';
import { age } from 'utils/TimeUtils';
import moment from 'moment';
import { FieldComponentProps } from '../../types';

const DateOfBirth: React.FC<FieldComponentProps> = ({ candidate, testid }) => {
  if (!candidate) return null;

  const { dob } = candidate;

  const formattedDob = moment(dob).format('MMM DD, YYYY');
  const candidateAge = age(dob);

  return (
    <div data-testid={testid}>
      {dob ? formattedDob : '-'}
      {dob && candidateAge && <> ({candidateAge} years old)</>}
    </div>
  );
};

export default DateOfBirth;
