import React from 'react';
import CustomProvider from 'state/provider';
import { useReportsWithMultiMvr } from 'api/reports';
import { useReportMergedExceptions } from 'hooks';
import { Settings } from 'types';
import Context from './context';
import { usePackagesFromApi, useReportEta } from './hooks';
import { getRecordsFilter } from './utils';

type Props = {
  reportId: string;
  screenings: string[];
  settings: Settings;
  updateSettings: (
    newSettings: Partial<{ hideReportSummary: boolean }>,
  ) => void;
};

const Provider: React.FC<Props> = ({
  reportId,
  screenings,
  settings,
  updateSettings,
  children,
}) => {
  const { report, isLoading, isError } = useReportsWithMultiMvr({ reportId });
  const reportExceptions = useReportMergedExceptions(reportId);
  const { packageObj, packages, packagesLoading, upgradePackages } =
    usePackagesFromApi(report);
  const recordsFilter = getRecordsFilter(report);
  const eta = useReportEta(report);

  return (
    <CustomProvider
      context={Context}
      props={{
        isLoading,
        isError,
        packageObj,
        packages,
        packagesLoading,
        recordsFilter,
        report,
        reportExceptions,
        reportId,
        screenings,
        upgradePackages,
        eta,
        settings,
        updateSettings,
      }}
      stateKey='reportPage'
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
