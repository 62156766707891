export enum Criminal {
  ARREST_DATE = 'arrest_date',
  CHARGE_DATE = 'charge_date',
  CONVICTION_DATE = 'conviction_date',
  DEPOSITION_DATE = 'deposition_date',
  DISPOSITION_DATE = 'disposition_date',
  FILE_DATE = 'file_date',
  OFFENSE_DATE = 'offense_date',
  RELEASE_DATE = 'release_date',
  SENTENCE_DATE = 'sentence_date',
  PROBATION_ESTIMATED_END_DATE = 'probation_estimated_end_date',
  INCARCERATION_ESTIMATED_RELEASE_DATE = 'incarceration_estimated_release_date',
}

export default Criminal;
