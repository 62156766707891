import { camelCase } from 'lodash';
import {
  GenericObject,
  Report,
  getDocument,
  getInternationalDocument,
} from '@dashboard-experience/utils';

const useGetDocuments = (documents: string[], report: Report) => {
  const docs = {} as GenericObject;

  documents.forEach((document: string) => {
    if (
      report?.documents &&
      report.documents.length > 1 &&
      document === 'pdf_international_report'
    ) {
      docs[camelCase(document)] = getInternationalDocument(report, document);
    } else {
      docs[camelCase(document)] = getDocument(report, document);
    }
  });

  return docs;
};

export default useGetDocuments;
