/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';
import { Geo } from '@dashboard-experience/utils';
import { isInternationalCountry, updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import AccountContext from 'pages/Account/AccountContext';
import { BillingEntity } from 'types/Billing';
import { Footer, StepContainer } from '../ui';
import InfoSourceRadios from './InfoSourceSection/InfoSourceRadios';
import { ManualOrder } from './ManualOrderSection';
import type { GetStartedT } from '.';
import {
  NodesAndWorkLocationSection,
  GeosAndProgramsSection,
} from './LocationSection';
import LocationSectionHeader from './LocationSection/LocationSectionHeader';
import { CandidateContactInfoSection } from './CandidateContactInfoSection';
import { BillingSection } from './BillingSection';
import { namespace } from '../locales';
import { StyledCandidateDetailsHeader } from './GetStartedStep.styles';
import { isContinueDisabled } from './GetStartedStepUtils';

const GetStartedStep: React.FC<{}> = () => {
  const { t } = useTranslation(namespace);
  const [selectedInfoSource, setSelectedInfoSource] = useState<
    GetStartedT.InfoSource | undefined
  >();
  const [selectedInfoMethod, setSelectedInfoMethod] =
    useState<GetStartedT.InfoMethod>('MANUAL');
  const [selectedManualOrderType, setSelectedManualOrderType] = useState<
    GetStartedT.ManualOrderType | undefined
  >();
  const [selectedGeo, setSelectedGeo] = useState<Geo>({} as Geo);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<BillingEntity>({} as BillingEntity);
  const [selectedLocation, setSelectedLocation] =
    useState<GetStartedT.WorkLocation>({} as GetStartedT.WorkLocation);
  const [selectedNode, setSelectedNode] = useState<GetStartedT.Node>();
  const [selectedProgram, setSelectedProgram] =
    useState<GetStartedT.Program | null>(null);
  const [enteredInvites, setEnteredInvites] = useState<GetStartedT.Invite[]>(
    [],
  );
  const [uploadedCsv, setUploadedCsv] = useState<GetStartedT.BulkInviteCSV>(
    {} as GetStartedT.BulkInviteCSV,
  );
  const [parsedEmails, setParsedEmails] = useState<GetStartedT.Email[]>([]);

  const history = useHistory();
  const { contextId } = useContext(UIContext);

  const currentUser = useUser();
  const {
    update,
    selectedPackage,
    infoSource,
    infoMethod,
    manualOrderType,
    geo,
    location,
    node,
    program,
    invites,
    paymentProfile,
    csv,
    emails,
  } = useContext(BetterOrderExperienceContext);

  const {
    account,
    geos,
    nodes,
    nodesIsLoading,
    handleNodeSearchTextChange,
    nodeSearchText,
    programs,
    payment_profiles,
    billing_entity_enabled,
  } = useContext(AccountContext);

  const {
    segmentation_enabled,
    geos_required,
    programs_enabled,
    manual_orders_review,
  } = account;

  const selectablePrograms = useMemo(() => {
    if (selectedGeo?.id && selectedGeo?.id !== 'None') {
      return programs.filter(program => {
        return program.geo_ids.includes(selectedGeo?.id);
      });
    }
    return [];
  }, [programs, selectedGeo?.id]);

  const disabled = isContinueDisabled({
    account,
    nodes,
    selectedGeo,
    selectedLocation,
    selectedNode,
    selectedProgram,
    selectedInfoSource,
    selectedInfoMethod,
    enteredInvites,
    uploadedCsv,
    programsRequiredForGeo: selectablePrograms.length > 0,
  });

  const countries = useMemo(() => {
    return uniq(geos.filter(geo => geo.country).map(geo => geo.country));
  }, [geos]);

  const isInternational = useMemo(() => {
    const country = segmentation_enabled
      ? selectedLocation?.country
      : selectedGeo?.country;

    return isInternationalCountry(country);
  }, [segmentation_enabled, selectedGeo?.country, selectedLocation?.country]);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/select-your-package';
    update({
      infoSource: selectedInfoSource,
      infoMethod: selectedInfoMethod,
      location: selectedLocation,
      localeType: isInternational ? 'INTERNATIONAL' : 'DOMESTIC',
      node: selectedNode,
      geo: selectedGeo,
      program: selectedProgram,
      manualOrderType: selectedManualOrderType,
      invites: enteredInvites,
      paymentProfile: selectedPaymentProfile,
      csv: uploadedCsv,
      emails: parsedEmails,
    });

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [
    update,
    selectedInfoSource,
    selectedInfoMethod,
    selectedLocation,
    isInternational,
    selectedNode,
    selectedGeo,
    selectedProgram,
    selectedManualOrderType,
    enteredInvites,
    selectedPaymentProfile,
    uploadedCsv,
    parsedEmails,
    contextId,
    history,
  ]);

  useEffect(() => {
    // The following persists the values on refresh & when going back and forth from steps
    setSelectedInfoSource(infoSource);
    setSelectedInfoMethod(infoMethod);
    setSelectedGeo(geo);
    setSelectedProgram(program);
    setSelectedManualOrderType(manualOrderType);
    setEnteredInvites(invites);
    setSelectedPaymentProfile(paymentProfile);
    setUploadedCsv(csv);
    setParsedEmails(emails);

    if (node) setSelectedNode(node);
    if (location?.country) setSelectedLocation(location);
  }, [
    geo,
    infoSource,
    infoMethod,
    location,
    manualOrderType,
    node,
    program,
    paymentProfile,
    invites,
    csv,
    emails,
    setSelectedInfoSource,
    setSelectedInfoMethod,
    setSelectedGeo,
    setSelectedManualOrderType,
    setEnteredInvites,
    setUploadedCsv,
    setParsedEmails,
  ]);

  return (
    <StepContainer data-testid='get-started-step-container'>
      {billing_entity_enabled && (
        <BillingSection
          payment_profiles={payment_profiles}
          selectedPaymentProfile={selectedPaymentProfile}
          setSelectedPaymentProfile={setSelectedPaymentProfile}
        />
      )}
      {geos.length > 0 && <LocationSectionHeader />}
      {segmentation_enabled ? (
        <NodesAndWorkLocationSection
          node={node}
          countries={countries as string[]}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          nodes={nodes}
          nodesIsLoading={nodesIsLoading}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          nodeSearchText={nodeSearchText}
          handleNodeSearchTextChange={handleNodeSearchTextChange}
        />
      ) : (
        <GeosAndProgramsSection
          geos={geos}
          selectedGeo={selectedGeo}
          setSelectedGeo={setSelectedGeo}
          programs={selectablePrograms}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          geos_required={geos_required}
          programs_enabled={programs_enabled}
        />
      )}

      <StyledCandidateDetailsHeader className='h4'>
        {t('candidateDetails.header')}
      </StyledCandidateDetailsHeader>

      <InfoSourceRadios
        isInternational={isInternational}
        manual_orders_review={manual_orders_review}
        selectedInfoSource={selectedInfoSource}
        setSelectedInfoSource={setSelectedInfoSource}
        accountId={JSON.stringify(account.id)}
      />

      {selectedInfoSource === 'CANDIDATE' && (
        <CandidateContactInfoSection
          selectedInfoMethod={selectedInfoMethod}
          setSelectedInfoMethod={setSelectedInfoMethod}
          enteredInvites={enteredInvites}
          setEnteredInvites={setEnteredInvites}
          uploadedCsv={uploadedCsv}
          setUploadedCsv={setUploadedCsv}
          setParsedEmails={setParsedEmails}
        />
      )}
      {selectedInfoSource === 'MYSELF' && (
        <ManualOrder
          currentUser={currentUser}
          selectedManualOrderType={selectedManualOrderType}
          setSelectedManualOrderType={setSelectedManualOrderType}
        />
      )}

      <Footer>
        <M.Button
          data-testid='get-started-continue'
          type='button'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default GetStartedStep;
