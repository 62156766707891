import React from 'react';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useTranslation } from 'react-i18next';
import { StyledPrice, StyledPriceVaries } from '../styles/PackagePrice.styles';
import { namespace } from '../../locales';

const PackagePrice: React.FC<{ price?: number }> = ({ price = 0 }) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;
  return price !== 0 ? (
    <StyledPrice>
      <span>{t(`${basePath}.packagePrice.startingAt`)} </span>
      {getDollarAmount(String(price))}
    </StyledPrice>
  ) : (
    <StyledPriceVaries data-testid='price-varies'>
      {t(`${basePath}.packagePrice.priceVaries`)}
    </StyledPriceVaries>
  );
};

export default PackagePrice;
