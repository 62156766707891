import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
`;

export const HeaderTiles = styled.div<{ alignRight?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  min-width: 276px;
  color: ${colors.uiTextPrimaryLight};

  text-align: ${props => (props?.alignRight ? 'right' : 'start')};
  &:nth-child(2) {
    margin-right: auto; /* Pushes second item towards the first */
  }
`;

export const PackageHeader = styled(HeaderTiles)`
  @media (max-width: 940px) {
    display: none;
  }
`;

export const MobilePackageHeader = styled(HeaderTiles)`
  display: none;

  @media all and (max-width: 940px) {
    display: flex;
  }
`;

export const ScreeningsHeader = styled(HeaderTiles)`
  width: 100%;
  padding-left: 24px;
  min-width: unset;
  @media all and (max-width: 1156px) {
    display: none;
  }
`;

export const PriceHeader = styled(HeaderTiles)`
  width: 100%;
  min-width: unset;
  @media all and (max-width: 540px) {
    display: none;
  }
`;
