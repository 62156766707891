/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { SpecialAddScreeningsActionProps } from 'components/AddScreenings/shared/SpecialAddScreeningsAction';
import {
  AccordionItem,
  CategoryTitle,
  Icon,
  ScreeningProps,
} from '../../shared/ListItemElements';
import EducationVerification from './VerificationScreenings/EducationVerification';
import EmploymentVerification from './VerificationScreenings/EmploymentVerification';
import ProfessionalLicenseVerification from './VerificationScreenings/ProfessionalLicenseVerification';
import PersonalReferenceVerification from './VerificationScreenings/PersonalReferenceVerification';
import ProfessionalReferenceVerification from './VerificationScreenings/ProfessionalReferenceVerification';
import certificate from '../../shared/svg/certificate.svg';
import {
  getEmploymentVerificationPrice,
  getPersonalReferenceVerificationPrice,
  getProfessionalLicenseVerificationPrice,
  getProfessionalReferenceVerificationPrice,
} from '../../shared/utils';

const NumberSelectorContainer = styled.div`
  flex: 1 0 5%;
  padding-right: 10px;

  .mastodon-select {
    margin-bottom: 0px !important;
  }

  .cds--select--inline {
    justify-content: end;
  }
`;

const NumberSelector = styled(M.Select)`
  flex: 1 0 15%;
`;

type GetPriceFunction = (
  itemPrices: any,
  value: string | null,
  lookback?: number,
  screeningSettingPrice?: string[],
  additionalProperties?: any,
) => any;

type OnChangeHandler = (
  e: React.ChangeEvent<any>,
  screeningType: string,
) => void;
type AdditionalPropsPropsValue =
  | 'international_upgrade'
  | 'name'
  | 'maximum_allowed_employers'
  | 'required_quantity';

type VerificationsItemProps = ScreeningProps & {
  screeningTypesInSearch: string[];
  itemPrices: string[];
  additionalProperties: {
    [key: string]: {
      international_upgrade: boolean;
      name: string;
      maximum_allowed_employers: number;
      lookback_years: number;
      required_quantity: number;
      dot_compliance: boolean;
    };
  };
  basePackage: BasePackage;
  screeningSettingPrice: string[];
};

export const determineEmploymentVerificationCase = (
  basePackageScreenings: any,
  itemPrices: string[],
  verifPanel: {
    international_upgrade?: boolean;
    name: string;
    maximum_allowed_employers?: number;
    lookback_years: number;
    dot_compliance: boolean;
  },
  getPriceFunc: GetPriceFunction,
  screeningSettingPrice?: string[],
) => {
  const existingScreening = basePackageScreenings?.find(
    (screening: any) => screening.type === 'employment_verification',
  );
  const lookbackYears = verifPanel?.lookback_years;

  switch (lookbackYears) {
    case 3:
      return getPriceFunc(
        itemPrices,
        null,
        3,
        screeningSettingPrice,
        verifPanel,
      );
    case 5:
      return getPriceFunc(
        itemPrices,
        null,
        5,
        screeningSettingPrice,
        verifPanel,
      );
    case 7:
      return getPriceFunc(
        itemPrices,
        null,
        7,
        screeningSettingPrice,
        verifPanel,
      );
    case 10:
      if (!verifPanel?.maximum_allowed_employers) {
        return getPriceFunc(
          itemPrices,
          null,
          10,
          screeningSettingPrice,
          verifPanel,
        );
      }
      return getPriceFunc(
        itemPrices,
        '1',
        10,
        screeningSettingPrice,
        verifPanel,
      );
    case undefined:
      if (!existingScreening) {
        return getPriceFunc(
          itemPrices,
          null,
          7,
          screeningSettingPrice,
          verifPanel,
        );
      }
      return 'Price varies';
    default:
      return getPriceFunc(
        itemPrices,
        null,
        7,
        screeningSettingPrice,
        verifPanel,
      );
  }
};

const VerificationsItem: React.FC<any> = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
  basePackage,
  itemPrices,
  additionalProperties,
  screeningSettingPrice,
}: VerificationsItemProps) => {
  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    actionProps;

  const plvAdditionalProperties = {
    type: ScreeningType.pro_license_verification,
    input_required: false,
    locations: [
      true,
      '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
    ],
  };

  const handleRemove = () => {
    onRemoveClick(ScreeningType.professional_license_verification);
  };

  const plvProps: SpecialAddScreeningsActionProps = {
    screeningType: ScreeningType.professional_license_verification,
    id:
      addedScreenings.includes(
        ScreeningType.professional_license_verification,
      ) || addedScreenings.includes(ScreeningType.pro_license_verification)
        ? 'delete-trash-can'
        : '',

    kind:
      addedScreenings.includes(
        ScreeningType.professional_license_verification,
      ) || addedScreenings.includes(ScreeningType.pro_license_verification)
        ? 'tertiary'
        : 'secondary',

    onClick: () => {
      addedScreenings.includes(
        ScreeningType.professional_license_verification,
      ) || addedScreenings.includes(ScreeningType.pro_license_verification)
        ? handleRemove()
        : onAddClick(ScreeningType.professional_license_verification, {
            ...plvAdditionalProperties,
          });
    },

    disabled:
      disabledScreenings.includes(
        ScreeningType.professional_license_verification,
      ) || disabledScreenings.includes(ScreeningType.pro_license_verification),
    added:
      addedScreenings.includes(
        ScreeningType.professional_license_verification,
      ) || disabledScreenings.includes(ScreeningType.pro_license_verification),
  };

  const generateDropdownOptions = (
    itemPrices: any,
    maxDropdownSelection: number,
    getPriceFunc: GetPriceFunction,
  ): JSX.Element[] => {
    return Array.from({ length: maxDropdownSelection }, (_, i) => i + 1).map(
      num => (
        <M.Select.Item
          key={`maxQty-${num}`}
          value={num.toString()}
          label={`${num} (${getPriceFunc(itemPrices, num.toString())})`}
        />
      ),
    );
  };

  const handlePrice = (
    screeningType: ScreeningType,
    propName: AdditionalPropsPropsValue,
    maxDropdownSelection: number,
    getPriceFunc: GetPriceFunction,
    onChangeHandler?: OnChangeHandler,
    screeningSettingPrice?: string[],
  ): JSX.Element | any => {
    if (screeningType === 'employment_verification') {
      return itemPrices.length === 0
        ? 'Loading price...'
        : determineEmploymentVerificationCase(
            basePackage?.screenings,
            itemPrices,
            additionalProperties?.[screeningType],
            getPriceFunc,
            screeningSettingPrice,
          );
    }
    if (!addedScreenings.includes(screeningType)) {
      return itemPrices.length === 0
        ? 'Loading price...'
        : getPriceFunc(
            itemPrices,
            (additionalProperties?.[screeningType]?.[
              propName
            ] as unknown as string) || '1',
          );
    }

    return (
      <NumberSelectorContainer>
        <NumberSelector
          name={`${screeningType}_${propName}`}
          id={`${screeningType}_${propName}`}
          defaultValue='0'
          inline
          hideLabel
          onChange={(e: any) => {
            if (onChangeHandler) {
              onChangeHandler(e, screeningType);
            } else {
              onAddClick(screeningType, {
                [propName]: e.target.value,
              });
            }
          }}
        >
          {generateDropdownOptions(
            itemPrices,
            maxDropdownSelection,
            getPriceFunc,
          )}
        </NumberSelector>
      </NumberSelectorContainer>
    );
  };

  const customOnChangeHandler =
    (propName: string, screeningType: string): OnChangeHandler =>
    (e: any) => {
      onAddClick(screeningType, {
        [propName]: [true, e.target.value],
      });
    };

  const handlePriceForEmplVerification = (): JSX.Element | any => {
    const selectedItem = basePackage?.screenings?.find(
      (screening: any) => screening.type === 'employment_verification',
    );
    const lookbackYears = selectedItem ? selectedItem?.lookback_years : 7;
    return handlePrice(
      ScreeningType.employment_verification,
      'maximum_allowed_employers',
      lookbackYears,
      getEmploymentVerificationPrice,
      undefined,
      screeningSettingPrice,
    );
  };

  const handlePriceForPersonalRef = (): JSX.Element | any =>
    handlePrice(
      ScreeningType.personal_reference_verification,
      'required_quantity',
      3,
      getPersonalReferenceVerificationPrice,
      customOnChangeHandler(
        'required_quantity',
        ScreeningType.personal_reference_verification,
      ),
    );

  const handlePriceForProfessionalRef = (): JSX.Element | any =>
    handlePrice(
      ScreeningType.professional_reference_verification,
      'required_quantity',
      3,
      getProfessionalReferenceVerificationPrice,
      customOnChangeHandler(
        'required_quantity',
        ScreeningType.professional_reference_verification,
      ),
    );

  return (
    <AccordionItem
      open={
        screeningTypesInSearch?.includes('employment_verification') ||
        screeningTypesInSearch?.includes('education_verification') ||
        screeningTypesInSearch?.includes('personal_reference_verification') ||
        screeningTypesInSearch?.includes('professional_license_verification') ||
        screeningTypesInSearch?.includes('professional_reference_verification')
      }
      data-testid='verifications-dropdown'
      title={
        <CategoryTitle data-testid='verifications-title'>
          <Icon src={certificate} className='screening-icon' /> Verifications
        </CategoryTitle>
      }
    >
      <ul>
        <EmploymentVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'employment_verification',
          )}
          handlePrice={handlePriceForEmplVerification}
          actionProps={actionProps}
          basePackage={basePackage}
          additionalProperties={additionalProperties}
        />
        <EducationVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'education_verification',
          )}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
          additionalProperties={additionalProperties}
        />
        <ProfessionalLicenseVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'professional_license_verification',
          )}
          getProfessionalLicenseVerificationPrice={
            getProfessionalLicenseVerificationPrice
          }
          itemPrices={itemPrices}
          actionProps={actionProps}
          plvProps={plvProps}
        />
        <PersonalReferenceVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'personal_reference_verification',
          )}
          getPriceByType={handlePriceForPersonalRef}
          actionProps={actionProps}
        />
        <ProfessionalReferenceVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'professional_reference_verification',
          )}
          getPriceByType={handlePriceForProfessionalRef}
          actionProps={actionProps}
        />
      </ul>
    </AccordionItem>
  );
};

export default VerificationsItem;
