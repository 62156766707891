import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'order-summary-container',
})`
  display: flex;
  width: 33.3%;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  background: ${colors.brandSlate1};
  min-height: 400px;
  border-radius: 6px;

  @media (max-width: 992px) {
    width: 50%;
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const Content = styled.div.attrs({
  className: 'order-summary-content',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.borderPrimaryLight};
  }
`;

export const SectionTitle = styled.span.attrs({
  className: 'order-summary-section-title p4',
})`
  color: ${colors.uiTextTertiaryLight};
  display: flex;
`;

export const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-self: stretch;
`;

export const Screening = styled.h5.attrs({
  className: 'order-summary-screening mb-0',
})`
  color: ${colors.uiTextSecondaryLight} !important;
`;

export const ScreeningPrice = styled.span.attrs({
  className: 'order-summary-screening-price',
})`
  color: ${colors.uiTextSecondaryLight};
`;

export const EmptyPrice = styled.span`
  color: ${colors.uiTextDisabledLight};
`;

export const NoneAdded = styled.h5.attrs({
  className: 'order-summary-none-added mb-0',
})`
  color: ${colors.uiTextDisabledLight} !important;
`;
