import React from 'react';
import { M } from '@dashboard-experience/mastodon';

export type DropdownItem = {
  title: string;
  subtitle: string | React.ReactNode;
  action: (params?: any) => void;
};

type Props = {
  dropdownItems: DropdownItem[];
  buttonLabel: string;
  buttonAction: (params?: any) => void;
  [key: string]: any;
};

const AdjudicationSplitActionButton: React.FC<Props> = ({
  dropdownItems,
  buttonLabel,
  buttonAction,
  ...props
}) => {
  return (
    <M.SplitActionButton
      kind='secondary'
      buttonLabel={buttonLabel}
      buttonAction={buttonAction}
      showLeftIcon={false}
      showRightIcon={false}
      dropdownItems={dropdownItems}
      {...props}
    />
  );
};

export default AdjudicationSplitActionButton;
