import React from 'react';
import ReportLifeCycleActions from './report';
import CancelInvitationAction from './cancel-invitation-action';

const LifeCycleActions = () => {
  return (
    <div data-testid='lifecycle-actions'>
      <CancelInvitationAction />
      <ReportLifeCycleActions />
    </div>
  );
};

export default LifeCycleActions;
