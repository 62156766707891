const assessmentVariants = [
  {
    id: '914eafe9d068863d341d7888',
    report_id: 'a8dbd7cb983a01931228d872',
    display_label: 'GA Alcohol Test',
    label: 'Test Label 1',
    created_at: '2024-11-16T19:10:51Z',
    updated_at: '2024-11-16T19:12:51Z',
    current_variant: {
      id: '92246257595a66843e78e3bc',
      decision: 'eligible',
      created_at: '2024-11-16T19:12:51Z',
      updated_at: '2024-11-16T19:12:51Z',
      adjudication: null,
    },
  },
  {
    id: '914eafe9d068863d341d7889',
    report_id: 'a8dbd7cb983a01931228d873',
    display_label: 'VA Driver Test',
    label: 'Test Label 2',
    created_at: '2024-11-17T19:10:34Z',
    updated_at: '2024-11-17T19:12:34Z',
    current_variant: {
      id: '92246257595a66843e78e3bd',
      decision: 'review',
      created_at: '2024-11-17T19:12:34Z',
      updated_at: '2024-11-17T19:12:34Z',
      adjudication: null,
    },
  },
];

export default assessmentVariants;
