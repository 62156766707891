import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledPrice = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: right;
  color: ${colors.uiTextPrimaryLight};
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: ${colors.brandSlate6};
  }

  .card-disabled & {
    color: ${colors.uiTextDisabledLight} !important;
  }
`;

export const StyledPriceVaries = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  color: ${colors.uiTextPrimaryLight};
`;
