import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import CIRCLE_CHECKMARK from './CIRCLE_CHECKMARK';

export const CHECKMARK = '✓';
export const EX = 'ⅹ';

export const ListItem = styled.li`
  font-size: 14px;
  color: ${colors.brandSlate7};
  border-bottom: 1px solid ${colors.brandSlate2};
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  max-width: 300px;

  &:last-child {
    border-bottom: none;
  }
`;

export const BoldListItem = styled(ListItem)`
  color: ${colors.brandNavy3};
  font-weight: bold;
`;

export const RadioButton = styled(M.RadioButton)`
  display: flex;
  justify-content: center;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid ${colors.brandSlate2};
  width: 100%;
  height: 56px;
  .cds--radio-button__appearance {
    border: 1px solid ${colors.brandSlate5} !important;
  }
  background: ${props =>
    props.checked
      ? `${colors.brandSlate2} !important`
      : `${colors.uiGrey0} !important`};

  .cds--radio-button:focus
    + .cds--radio-button__label
    .cds--radio-button__appearance::after {
      box-shadow: unset !important;
    }
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    margin-left: -15px;
    margin-top: -15px;
    content: url(${CIRCLE_CHECKMARK}) !important;
    background-color: ${props =>
      props.checked
        ? `${colors.brandSlate2} !important`
        : `${colors.uiGrey0} !important`};
    width: 100% !important;
    height: 100% !important;
    transform: scale(0.5) !important;
  }
`;
