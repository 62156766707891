import React, { useState, useCallback } from 'react';
import pick from 'lodash/pick';
import { M } from '@dashboard-experience/mastodon';
import { useTrackEvent, AI_CHARGE_EXPLAINER_FEEDBACK } from 'utils/analytics';
import {
  createThumbsUpFeedback,
  createThumbsDownFeedback,
} from 'api/aiExplainr';

import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import renderModalWithPortal from '../../modules/candidate/ui/overview/sections/render-modal';
import FeedbackModal from './FeedbackModal';

type FeedbackButtonsProps = {
  charge: any;
  explanation_id: string;
  record_type: string;
};

const amplitudeFields = [
  'charge',
  'charge_type',
  'record_type',
  'category',
  'charge_category',
  'charge_subcategory',
  'charge_subsubcategory',
  'state',
  'county',
];

const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({
  charge,
  explanation_id,
  record_type,
}) => {
  const [feedbackGiven, setFeedbackGiven] = useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleThumbsUpClick = useCallback(() => {
    createThumbsUpFeedback(explanation_id, record_type);

    const chargeData = pick(charge, amplitudeFields);
    trackEvent(AI_CHARGE_EXPLAINER_FEEDBACK.AI_CHARGE_EXPLAINER_THUMBS_UP, {
      Charge: chargeData,
      explanation_id,
      Report: reportProperties,
    });
    setFeedbackGiven(true);
  }, [charge, explanation_id, record_type, reportProperties, trackEvent]);

  const handleThumbsDownClick = useCallback(() => {
    const chargeData = pick(charge, amplitudeFields);
    trackEvent(AI_CHARGE_EXPLAINER_FEEDBACK.AI_CHARGE_EXPLAINER_THUMBS_DOWN, {
      Charge: chargeData,
      explanation_id,
      Report: reportProperties,
    });
    setModalOpen(true);
  }, [charge, explanation_id, reportProperties, trackEvent]);

  const sendNoFeedbackReason = useCallback(() => {
    createThumbsDownFeedback(explanation_id, record_type, null);
    setFeedbackGiven(true);
  }, [explanation_id, record_type]);

  const closeModal = useCallback(() => {
    if (selectedFeedback === null) {
      sendNoFeedbackReason();
    }
    setSelectedFeedback(null);
    setModalOpen(false);
  }, [selectedFeedback, sendNoFeedbackReason]);

  const submitFeedback = useCallback(() => {
    if (selectedFeedback !== null) {
      createThumbsDownFeedback(explanation_id, record_type, selectedFeedback);
      setModalOpen(false);
      setFeedbackGiven(true);
    }
  }, [explanation_id, record_type, selectedFeedback]);

  return (
    <>
      {/* @ts-ignore */}
      {renderModalWithPortal(FeedbackModal, {
        open: modalOpen,
        hideModal: closeModal,
        submitFeedback,
        explanation_id,
        record_type,
        selectedFeedback,
        setSelectedFeedback,
      })}

      {feedbackGiven ? (
        <p style={{ margin: 0 }}>Thank you for your feedback.</p>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <M.TooltipDefinition
              highlighted={false}
              align='top'
              definition='Helpful'
              data-testid='thumbs-up'
            >
              <M.Icon
                icon='ThumbsUp'
                onClick={handleThumbsUpClick}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </M.TooltipDefinition>

            <M.TooltipDefinition
              highlighted={false}
              align='top'
              definition='Not helpful'
              data-testid='thumbs-down'
            >
              <M.Icon
                icon='ThumbsDown'
                onClick={handleThumbsDownClick}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </M.TooltipDefinition>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackButtons;
