/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

import { validCustomIdChars, validNameChars } from './utils';
import TierCell from './TierCell';
import ParentCustomIdCell from './ParentCustomIdCell';

type Props = {
  cell: any;
  rowIndex: number;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  sortedRows: any;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setSortInfo: any;
  tiers: any;
  setTiers: any;
};

const CustomIDInput = styled(M.TextInput)`
  #mastodon && {
    .cds--form-requirement {
      // This hides the error message from the input
      display: none;
    }
  }
`;

export const NodesTableCells: React.FC<Props> = ({
  cell,
  rowIndex,
  setModified,
  sortedRows,
  setRows,
  setSortInfo,
  tiers,
  setTiers,
}) => {
  if (rowIndex >= sortedRows.length) {
    return null;
  }

  let invalidCustomIdBool = false;
  const uniqueCustomIds = new Set();

  for (const row of sortedRows) {
    if (uniqueCustomIds.has(row.id) && row.id === cell.value) {
      invalidCustomIdBool = true;
      break;
    }
    uniqueCustomIds.add(row.id);
  }

  const handleCellValueChange = (
    columnName: string,
    newValue: string,
    id: string,
    rowIndex: number,
    oldValue: string,
  ) => {
    const updatedRows = sortedRows.slice();

    let rIndex = 0;
    let cIndex: any;

    updatedRows.forEach((r: any, index: number) => {
      if (r.id === id) {
        r.cells.forEach((c: any, i: number) => {
          if (c.id === columnName && c.value === oldValue) {
            rIndex = index;
            cIndex = i;
          }
        });
      }
    });
    let newCell = null;

    if (columnName === 'id') {
      newCell = document.getElementById(`idSelector-${rowIndex}`);
    } else if (columnName === 'name') {
      newCell = document.getElementById(`nameSelector-${rowIndex}`);
    } else if (columnName === 'tier') {
      newCell = document.getElementById(
        `tierSelection-${sortedRows[rIndex].id}`,
      );
    } else {
      newCell = document.getElementById(
        `parentCustomIdSelection-${sortedRows[rIndex].id}-${sortedRows[rIndex].name}`,
      );
    }
    // @ts-ignore
    newCell.focus();
    const row = updatedRows[rowIndex];
    row[columnName] = newValue;
    row.cells[cIndex].value = newValue;

    updatedRows[rowIndex][columnName] = newValue;
    setRows(updatedRows);
    setModified(true);
  };

  switch (cell.id) {
    case 'id':
      return (
        <M.TableCell key={cell.id}>
          <CustomIDInput
            key={cell.id}
            id={`idSelector-${rowIndex}`}
            type='text'
            name={cell.id}
            value={cell.value}
            invalid={invalidCustomIdBool || !validCustomIdChars(cell.value)}
            data-testid='account-hierarchy-custom-id-input'
            onChange={(e: any) => {
              setSortInfo({
                columnId: cell.id,
                sortCycle: 'id-states-from-ascending',
                oldDirection: 'DESC',
              });
              handleCellValueChange(
                cell.id,
                e.target.value,
                sortedRows[rowIndex].id,
                rowIndex,
                cell.value,
              );
            }}
            style={{ width: '100%' }}
          />
        </M.TableCell>
      );

    case 'name':
      return (
        <M.TableCell key={cell.id}>
          <M.TextInput
            key={cell.id}
            id={`nameSelector-${rowIndex}`}
            type='text'
            name={cell.id}
            value={cell.value}
            invalid={!validNameChars(cell.value)}
            data-testid='account-hierarchy-name-input'
            onChange={(e: any) => {
              setSortInfo({
                columnId: cell.id,
                sortCycle: 'name-states-from-ascending',
                oldDirection: 'DESC',
              });
              handleCellValueChange(
                cell.id,
                e.target.value,
                sortedRows[rowIndex].id,
                rowIndex,
                cell.value,
              );
            }}
          />
        </M.TableCell>
      );

    case 'tier':
      return (
        <TierCell
          key={cell.id}
          setTiers={setTiers}
          tiers={tiers}
          cell={cell}
          handleCellValueChange={handleCellValueChange}
          rowIndex={rowIndex}
          sortedRowsId={sortedRows[rowIndex].id}
          setSortInfo={setSortInfo}
        />
      );

    case 'parent_custom_id':
      return (
        <ParentCustomIdCell
          key={cell.id}
          rows={sortedRows}
          tiers={tiers}
          cell={cell}
          rowIndex={rowIndex}
          handleCellValueChange={handleCellValueChange}
          sortedRowsId={sortedRows[rowIndex].id}
          setSortInfo={setSortInfo}
        />
      );
    default:
      return null;
  }
};

export default NodesTableCells;
