import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Option from 'modules/assess/models/context-date-option';
import { useNamespace } from 'modules/assess/ui/router/context';

export type Props = {
  option?: Option.Type;
};

const StyledContainer = styled.p`
  display: flex;
  margin-top: 2em;
`;

export const Content: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation('assess');
  const namespace = useNamespace();
  const optionName = t(
    `assess:context_dates.${namespace}.options.${option}.name`,
  );
  const warning = t('assess:context_dates.remove.warning', {
    option: optionName,
  });

  return <StyledContainer>{warning}</StyledContainer>;
};

export default Content;
