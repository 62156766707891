import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  OnlyAdvantagesBox,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type GlobalWatchlistSearchProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};
const GlobalWatchlistSearch = ({
  shouldOpen,
  actionProps,
}: GlobalWatchlistSearchProps) => {
  return (
    <FlexContainer data-testid='international-global-watchlist-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Global watchlist search'
            priceByType='international_global_watchlist_search'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Confirm whether watchlists worldwide list the candidate. {` `}
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <OnlyAdvantagesBox>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Search multiple international, government, and regulatory databases.' />
              <BulletPoint text='Help maintain compliance, mitigate risk, and make informed hiring decisions.' />
            </ul>
          </OnlyAdvantagesBox>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_global_watchlist_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default GlobalWatchlistSearch;
