import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  ALIAS_AS_ADDON_PAGES,
  ALIASES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import { useLocation } from 'react-router-dom';

const aliasDemoGif = require('../../shared/assets/alias-demo.gif');

const Heading = styled.div`
  margin-bottom: 15px;
`;

const DescriptionOne = styled.div`
  margin-bottom: 10px;
  line-height: var(--font-line-height-lg, 20px); /* 142.857% */
  letter-spacing: 0.14px;
`;

const DescriptionTwo = styled.div`
  line-height: var(--font-line-height-lg, 20px); /* 142.857% */
  letter-spacing: 0.14px;
`;

const GifContainer = styled.div`
  margin-top: 32px;
  min-height: 96px;

  img {
    width: 100%;
    border-radius: 8px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ClickableText = styled.span`
  color: ${colors.uiNavy600};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const AliasLearnMoreModal: React.FC<{ text: string }> = ({ text = '' }) => {
  const [open, setOpen] = useState(false);

  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const location = useLocation();
  const locationPath = location.pathname;
  const idx = locationPath.lastIndexOf('/');
  const page = locationPath.substring(idx + 1);

  const trackAliasLearnMoreClicks = useCallback(() => {
    trackEvent(currentUser, ALIASES.CHECKR_DASHBOARD_ALIAS_TOOLTIP_CLICKED, {
      Source: ALIAS_AS_ADDON_PAGES[page as keyof typeof ALIAS_AS_ADDON_PAGES],
    });
  }, [currentUser, page, trackEvent]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    trackAliasLearnMoreClicks();
    setOpen(true);
  }, [trackAliasLearnMoreClicks]);

  const buttonProps = {
    name: 'Got it',
    onClick: handleClose,
    'data-testid': 'got-it-button',
  };

  return (
    <>
      <M.ComposedModal open={open} preventCloseOnClickOutside>
        <M.ModalHeader
          data-testid='submit-modal-header'
          closeModal={handleClose}
        >
          Alias searches
        </M.ModalHeader>
        <M.ModalBody>
          <Heading className='h5'>Why search for aliases?</Heading>
          <DescriptionOne>
            About 1 in 4 Americans have an alias—a previously used or
            alternative name—that could have relevant records. Some aliases,
            such as former names, are intentional, and others (such as
            misspellings) can be accidental.
          </DescriptionOne>
          <DescriptionTwo>
            Including alias searches can uncover{' '}
            <Bold>up to 27% more records</Bold> for an individual candidate.
          </DescriptionTwo>
          <GifContainer>
            {' '}
            <img src={aliasDemoGif} alt='alias demo' />
          </GifContainer>
        </M.ModalBody>
        <M.ModalFooter>
          <M.BinaryButtons btnLeft={null} btnRight={buttonProps} size='small' />
        </M.ModalFooter>
      </M.ComposedModal>
      <ClickableText onClick={handleOpen}>{text}</ClickableText>
    </>
  );
};
export default AliasLearnMoreModal;
