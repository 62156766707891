import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import * as Assignable from 'modules/assess/models/assignables';
import * as Types from '../types';
import Label from './Label';

type Props = {
  onChange?: (type: Types.FilterType) => void;
};

const types = Object.values(Assignable.Kind).filter(
  type => type !== Assignable.Kind.ROLE,
);

export const TypeSelector: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('assess');
  const itemToString = useItemToString();

  const handleChange = useCallback(
    ({ selectedItem: type }) => onChange?.(type === 'any' ? undefined : type),
    [onChange],
  );

  return (
    <M.Dropdown
      data-testid='assess-ruleset-assign-filters-type'
      id='assess-ruleset-assign-filters-type'
      initialSelectedItem={types[0]}
      items={types}
      itemToString={itemToString}
      label={t('assess:ruleset.assign.filters.type.label')}
      onChange={handleChange}
      selectedItem={undefined}
      size='sm'
      titleText={<Label />}
    />
  );
};

function useItemToString() {
  const { t } = useTranslation('assess');
  return (key: string) =>
    t([
      `assess:ruleset_assignments.${key}.noun`,
      `assess:ruleset.assign.filters.type.${key}`,
    ]);
}

export default TypeSelector;
