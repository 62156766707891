import { M } from '@dashboard-experience/mastodon';
import {
  getResultOrStatus,
  orderByKey,
  ReportStatuses,
} from '@dashboard-experience/utils';
import React from 'react';

import { STATUS_ORDER } from 'Constants';
import { useUser } from 'context/CurrentUser';
import { MunicipalCriminalSearch, Report } from 'types';

import {
  hasCompleteScreenings,
  hasScreeningPointers,
} from 'utils/ScreeningPointerUtils';

import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { useFlag } from '@dashboard-experience/react-flagr';
import { handleAiClick } from '../../../../api/aiExplainr';
import { FilteredByAdjudicationRecords } from '../Components';
import AboutThisSearch from './AboutThisSearch';
import PatchRequest from './PatchRequest';
import { StateCriminalInfo, StateDisclaimer } from './StateCriminalComponents';

type Props = {
  report: Report;
  title: string;
  reportStatusType: string;
  screening?: MunicipalCriminalSearch[];
  screeningType?: string;
  id: string;
};

const GenericCriminalSearch: React.FC<Props> = ({
  report,
  title,
  reportStatusType,
  screening: searches = [],
  screeningType,
  children,
  id,
}) => {
  const isStateCriminalSearch = screeningType === 'state_criminal_searches';

  const currentUser = useUser();
  const orderedSearches = orderByKey(
    searches,
    'status',
    STATUS_ORDER,
  ) as MunicipalCriminalSearch[];

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  return orderedSearches?.length > 0 ? (
    <M.Screening
      title={title}
      screening={orderedSearches}
      statusType={reportStatusType}
      id={id}
      data-testid='screenings-municipal-criminal'
    >
      {children}
      {orderedSearches.map(search => {
        const {
          state_source_description,
          state,
          court,
          county,
          filtered_by_positive_adjudication_records,
          in_lieu_of_county,
          state_source_name,
          id,
          records,
          further_research_enabled,
        } = search;
        const stateCriminalSearchTitle = state_source_description || state;
        const subScreeningTitle = isStateCriminalSearch
          ? stateCriminalSearchTitle
          : `${court}, ${county}, ${state}`;
        const filteredByPAMRecords =
          filtered_by_positive_adjudication_records?.length || 0;
        const hasFilteredByPAMRecords = filteredByPAMRecords > 0;

        const status = getResultOrStatus(search);
        return (
          <M.SubScreening
            key={subScreeningTitle}
            title={subScreeningTitle}
            screening={search}
            statusType={reportStatusType}
          >
            {isStateCriminalSearch && (in_lieu_of_county || state === 'NY') && (
              <StateDisclaimer stateName={state} />
            )}
            {isStateCriminalSearch && status === ReportStatuses.COMPLETE && (
              <>
                {further_research_enabled && <StateCriminalInfo />}
                {state_source_name === 'patch' && (
                  <PatchRequest reportId={report.id} screeningId={id} />
                )}
              </>
            )}

            {status === ReportStatuses.CONSIDER &&
              records?.map(record => {
                const handleClickWithExplainer = handleAiClick(
                  record.state || '',
                  record.county || '',
                );
                return (
                  <M.Record
                    key={record.id}
                    record={record}
                    currentUser={currentUser}
                    report={report}
                    reportStatusType={reportStatusType}
                    chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
                    chargesExplainer={
                      aiChargeExplainerFlagEnabled
                        ? handleClickWithExplainer
                        : null
                    }
                  />
                );
              })}
            {hasFilteredByPAMRecords && (
              <FilteredByAdjudicationRecords
                search={search}
                filter='Positive Adjudication Matrix'
                filteredRecords={filteredByPAMRecords}
                report={report}
                reportStatusType={reportStatusType}
              />
            )}
          </M.SubScreening>
        );
      })}
      {hasScreeningPointers(searches) && hasCompleteScreenings(searches) && (
        <AboutThisSearch screenings={searches} />
      )}
    </M.Screening>
  ) : null;
};

export default GenericCriminalSearch;
