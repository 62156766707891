import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useNestedPreference } from 'api/dashboardPreferences';
import { namespace } from 'modules/candidate/locales';
import { useTrackEvent, REPORT_SETTING_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

type Props = {
  trackAction: () => void;
};

const ShowEmptyFields: React.FC<Props> = ({ trackAction }) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const title = t(
    `${namespace}:report.settings.menu.right-panel.show-empty-fields`,
  );

  const [showPreference, setShowPreference] = useNestedPreference(
    'report',
    'showEmptyFields',
  );

  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleToggle = useCallback(() => {
    trackAction();

    trackEvent(REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_SHOW_EMPTY_FIELDS, {
      'Show empty fields Selected': !showPreference,
      reportData: reportProperties,
    });

    setShowPreference(!showPreference);
  }, [
    trackAction,
    reportProperties,
    trackEvent,
    showPreference,
    setShowPreference,
  ]);

  return (
    <div data-testid='show-empty-fields'>
      <M.ActionMenuItem
        title={title}
        kind='toggle'
        initialChecked={showPreference}
        onToggle={handleToggle}
      />
    </div>
  );
};

export default ShowEmptyFields;
