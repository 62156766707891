import { BillingClient as client } from 'api';
import {
  BANK_ACCOUNTS_PATH,
  BILLING_PREFERENCES_PATH,
  ADDRESSES_PATH,
  CONTACTS_PATH,
  CREDIT_CARDS_PATH,
  PATH,
  PAYMENT_METHODS_PATH,
  SET_DEFAULT_PATH,
  SETUP_INTENT_PATH,
  TAX_EXEMPTION_CERTIFICATES_PATH,
  ENABLE_TAX_FOR_CUSTOMER_PATH,
} from './constants';
import { BillingAddress, BillingEntity } from '../../types/Billing';

export interface BillingAddressResponse {
  id: number;
  account_id: string;
  name: string;
  email: string;
  uri_name: string;
  address: BillingAddress;
  address_line_1: string;
  address_line_2?: any;
  city: string;
  country: string;
  postal_code: string;
  region: string;
  created_at: string;
  updated_at: string;
  autopay_enabled: boolean;
  billing_entity_enabled: boolean;
  payment_method_required: boolean;
  emails: {
    id: number;
    email: string;
    primary: boolean;
  }[];
  payment_term: {
    id: number;
    name: string;
    due_days: string;
    created_at: string;
    updated_at: string;
  };
  billing_entities: BillingEntity[] | [];
  currency: {
    id: number;
    name: string;
    iso_code: string;
  };
}

export type PostAddressParams = {
  account_id: string;
  name: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  region: string;
  postal_code: string;
  country: string;
};

export type PutContactsParams = {
  account_id: string;
  preferred_email: string;
  additional_emails: string[];
};

export type PaymentMethod = {
  account_holder_type: string;
  bank_name: string;
  brand: string;
  exp_month: string;
  exp_year: string;
  holder_name: string;
  id: string;
  last4: string;
  payment_type: string;
  stripe_id: string;
  verified: boolean;
  is_default_payment: boolean;
  ach_verification_url: string;
};

export type TaxExemptionCertificate = {
  id: string;
  status: string;
  expiration_date: string;
  filename: string;
  created_at: string;
  exposure_region: string;
  signed_date: string;
};

export type SetDefaultParams = {
  accountId: string;
  stripeId: string;
};

export type VerifyBAParams = {
  accountId: string;
  bankAccountId: string;
  values: any;
};

export type SetupIntentParams = {
  accountId: string;
  paymentType: string;
};

export type ClientSecret = {
  client_secret: string;
};

export type PostPaymentMethodParams = {
  accountId: string;
  stripeId: string;
  setAsDefault?: boolean;
};

export type DeletePaymentMethodParams = {
  accountId: string;
  stripeId: string;
};

export type AuthorizePaymentActionParams = {
  accountId: string;
  amount: number;
  action: string;
};

export type EnableTaxForCustomerParams = {
  accountId: string;
};

export const fetchBillingAddress = (accountId: string) => {
  return client.get(`${PATH}/${accountId}/${BILLING_PREFERENCES_PATH}`);
};

export const postBillingAddress = (params: PostAddressParams) => {
  return client.post(`${PATH}/${params.account_id}/${ADDRESSES_PATH}`, params);
};

export const putBillingContacts = (params: PutContactsParams) => {
  return client.put(`${PATH}/${params.account_id}/${CONTACTS_PATH}`, params);
};

export const getPaymentMethods = (accountId: string) => {
  return client.get(`${PATH}/${accountId}/${PAYMENT_METHODS_PATH}`);
};

export const setDefaultPayment = (params: SetDefaultParams) => {
  return client.post(
    `${PATH}/${params.accountId}/${PAYMENT_METHODS_PATH}/${SET_DEFAULT_PATH}`,
    { default_source_id: params.stripeId },
  );
};

export const deleteCC = (accountId: string) => {
  return client.delete(`${PATH}/${accountId}/${CREDIT_CARDS_PATH}`);
};

export const deleteBA = (accountId: string) => {
  return client.delete(`${PATH}/${accountId}/${BANK_ACCOUNTS_PATH}`);
};

export const verifyBA = (params: VerifyBAParams) => {
  return client.put(
    `${PATH}/${params.accountId}/${BANK_ACCOUNTS_PATH}/${params.bankAccountId}`,
    params.values,
  );
};

export const createSetupIntent = (
  params: SetupIntentParams,
): Promise<ClientSecret> => {
  return client.post(`${PATH}/${params.accountId}/${SETUP_INTENT_PATH}`, {
    payment_type: params.paymentType,
  });
};

export const postPaymentMethod = (
  params: PostPaymentMethodParams,
): Promise<PaymentMethod> => {
  return client.post(`${PATH}/${params.accountId}/${PAYMENT_METHODS_PATH}`, {
    stripe_id: params.stripeId,
    set_as_default: params.setAsDefault,
  });
};

export const deletePaymentMethod = (params: DeletePaymentMethodParams) => {
  return client.delete(
    `${PATH}/${params.accountId}/${PAYMENT_METHODS_PATH}/${params.stripeId}`,
  );
};

export const authorizePaymentAction = (
  params: AuthorizePaymentActionParams,
) => {
  return client.post(`${PATH}/${params.accountId}/${params.action}`, {
    amount: params.amount,
  });
};

export const getTaxExemptionCertificates = (accountId: string) => {
  return client.get(`${PATH}/${accountId}/${TAX_EXEMPTION_CERTIFICATES_PATH}`);
};

export const enableTaxForCustomer = (params: EnableTaxForCustomerParams) => {
  return client.patch(
    `${PATH}/${params.accountId}/${ENABLE_TAX_FOR_CUSTOMER_PATH}`,
  );
};
