import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import Timeline from 'components/Timeline/Timeline';
import { useRouteMatch } from 'react-router-dom';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const Row = styled(M.GridRow)`
  margin-bottom: 1.5rem;
`;

const TimelineContainer = styled.div`
  max-height: '70vh';
  overflow-x: auto;
  width: 100%;
`;

interface ReportActionLogProps {
  style?: React.CSSProperties;
}

const ReportActionLog: React.FC<ReportActionLogProps> = ({ style }) => {
  // Figure out the currently selected report to mark it active
  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  // Figure out the currently selected invitation to mark it active
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationOrReportId =
    reportMatch?.params?.reportId || invitationMatch?.params?.invitationId;

  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  if (currentUser) {
    trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_REPORT_LOG_USED, {
      Source: 'Report Actions',
      Report: reportProperties,
    });
  }

  if (invitationOrReportId) {
    return (
      <Row>
        <TimelineContainer style={style}>
          <Timeline
            data-testid='timeline'
            contextName='reports'
            contextId={invitationOrReportId}
          />
        </TimelineContainer>
      </Row>
    );
  }

  return null;
};

export default ReportActionLog;
