import React, { useCallback, useEffect, useState } from 'react';
import { useGetAssessRuleItemTypes } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';
import { useTranslation } from 'react-i18next';
import Kind from 'modules/assess/models/rules/common/kind';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import MultiSelect from 'modules/assess/ui/rules/common/rule/editor/multi-select';

type Props = {
  kind: Kind;
  index: number;
  filterValue: string | null;
};

const ItemTypes: React.FC<Props> = ({ kind, index, filterValue }) => {
  const { updateConditionByIndex, selectedItemTypes } = useConditions();
  const { data } = useGetAssessRuleItemTypes(kind);

  // filter the data based on the filter value
  const filteredData = data?.filter((itemType: string) => {
    if (filterValue === 'points') {
      return itemType === 'violation';
    }
    return true;
  });

  const { t } = useTranslation('assess');
  const buildItem = (itemType: string) => ({
    id: itemType,
    label: t(`rules.facts.object_type.values.${itemType}`),
  });
  const items = filteredData?.map((itemType: string) => buildItem(itemType));
  const initialSelectedItems = selectedItemTypes().map(itemType =>
    buildItem(itemType),
  );

  // if the filter value is 'points', only show the 'violation' item type
  const filteredInitialSelectedItems = initialSelectedItems.filter(
    itemType => filterValue !== 'points' || itemType.id === 'violation',
  );

  const [renderKey, setRenderKey] = useState('');
  const [updatedFromCallback, setUpdatedFromCallback] = useState(false);
  const onChange = useCallback(
    selectedItems => {
      const value: string[] = selectedItems?.map(
        (item: { id: string }) => item.id,
      );
      setUpdatedFromCallback(true);
      updateConditionByIndex(index, { value });
    },
    [index, updateConditionByIndex],
  );

  useEffect(() => {
    if (
      renderKey === '' &&
      initialSelectedItems.length > 0 &&
      !updatedFromCallback
    ) {
      setRenderKey('rerender');
    }
  }, [initialSelectedItems, updatedFromCallback]);

  // if the initial 'count type' changes, update the 'applies to' condition
  useEffect(() => {
    const initialIds = initialSelectedItems.map(item => item.id);
    const filteredIds = filteredInitialSelectedItems.map(item => item.id);

    const arraysMatch = (arr1: any[], arr2: any[]) =>
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index]);

    if (!arraysMatch(initialIds, filteredIds)) {
      updateConditionByIndex(index, { value: filteredIds });
    }
  }, [
    initialSelectedItems,
    filteredInitialSelectedItems,
    index,
    updateConditionByIndex,
  ]);

  return (
    <MultiSelect
      key={renderKey + index}
      items={items}
      onChange={onChange}
      initialSelectedItems={filteredInitialSelectedItems}
    />
  );
};

export default ItemTypes;
