import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { Package } from '../../../../types';
import PackageChoiceCard from './PackageChoiceCard';
import { MULTIPLE_PEOPLE, useOrderBackgroundCheckContext } from '../Context';
import {
  DEFAULT_PACKAGE_NAMES,
  DEFAULT_PACKAGE_NAMES_WITH_COMPLETE,
} from '../SharedItems';

const RecommendPackagesContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 10px;
  flex-shrink: 0;

  .cds--popover-container {
    display: flex;
    flex: 1;

    .button-reset {
      display: flex !important;
      height: 100%;
    }
  }
  @media all and (max-width: 630px) {
    flex-direction: column;
  }
`;

type RecommendedPackagesProp = {
  recommendedPackages: Package[];
  showPackagePrice?: boolean;
  isAvailableForManualBulk: (pkg: Package) => boolean;
};

export const getHighestPricedPackageName = (
  recommendedPackages: Package[],
  isAvailableForManualBulk: (pkg: Package) => boolean,
  showCompleteCrim: boolean,
) => {
  const customPackages = recommendedPackages.filter(
    pkg =>
      (!showCompleteCrim
        ? !DEFAULT_PACKAGE_NAMES.includes(pkg.name)
        : !DEFAULT_PACKAGE_NAMES_WITH_COMPLETE.includes(pkg.name)) &&
      isAvailableForManualBulk(pkg),
  );
  const hasCustomPackages = customPackages.length > 0;

  if (!hasCustomPackages) {
    const essentialPackage = recommendedPackages.find(
      pkg => pkg.name === 'Essential',
    );
    if (essentialPackage) return 'Essential';
  }

  const highestCustomPackage = customPackages.sort(
    (a, b) => (b.price ?? 0) - (a.price ?? 0),
  )[0];

  return highestCustomPackage?.name ?? 'Essential'; // Return highest custom package or 'Essential' if none found
};

const RecommendedPackages: React.FC<RecommendedPackagesProp> = ({
  recommendedPackages,
  showPackagePrice = false,
  isAvailableForManualBulk,
}) => {
  const currentUser = useUser();
  const showCompleteCrim = currentUser?.account?.cohorts?.some(
    (cohort: { name: string }) =>
      cohort.name === 'ab-10-good-better-best-test' ||
      cohort.name === 'ab-11-good-better-best-test-assisted',
  );

  const [isAllCustom, setIsAllCustom] = useState(false);
  const selectedPackageName = getHighestPricedPackageName(
    recommendedPackages,
    isAvailableForManualBulk,
    showCompleteCrim,
  );
  const selectedPackagePrice = recommendedPackages.find(
    pkg => pkg.name === selectedPackageName,
  )?.price;
  const essentialPackagePrice = recommendedPackages.find(
    pkg => pkg.name === 'Essential',
  )?.price;
  const { state } = useOrderBackgroundCheckContext();
  const manualBulkOrderFlow = state.manualBulkUploadType === MULTIPLE_PEOPLE;
  const hideMostPopular =
    selectedPackagePrice && essentialPackagePrice
      ? selectedPackageName !== 'Essential' &&
        selectedPackagePrice > essentialPackagePrice
      : false;

  useMemo(() => {
    setIsAllCustom(
      recommendedPackages.every(pkg => {
        return !showCompleteCrim
          ? !DEFAULT_PACKAGE_NAMES.includes(pkg.name)
          : !DEFAULT_PACKAGE_NAMES_WITH_COMPLETE.includes(pkg.name);
      }),
    );
  }, [recommendedPackages, showCompleteCrim]);

  return (
    <RecommendPackagesContainer>
      {recommendedPackages.map((_package: Package, idx: number) => (
        <PackageChoiceCard
          key={_package?.id}
          basePackage={_package}
          selected={
            !manualBulkOrderFlow || isAvailableForManualBulk(_package)
              ? selectedPackageName === _package?.name
              : false
          }
          showPackagePrice={showPackagePrice}
          idx={idx}
          hideMostPopularTag={hideMostPopular}
          isAvailableForManualBulk={isAvailableForManualBulk}
          isAllCustom={isAllCustom}
        />
      ))}
    </RecommendPackagesContainer>
  );
};

export default RecommendedPackages;
