import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { M, colors } from '@dashboard-experience/mastodon';
import SelectableAlias from './SelectableAlias';
import { AliasesEnabledType } from './types';
import AliasLearnMoreModal from '../OrderBackgroundCheck/ReviewAndSubmitOrder/AliasLearnMoreModal';
import { useOrderBackgroundCheckContext } from '../OrderBackgroundCheck/Context';

const Container = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const BoldTitle = styled.span`
  color: ${colors.uiTextPrimaryLight};
  font-weight: 700;
`;

const Description = styled.div`
  color: ${colors.uiTextSecondaryLight};
  margin-top: 1rem;
`;

const RecommendedLabel = styled(M.TagV2)`
  margin-left: 16px !important;
  background-color: #c4f3f8 !important;
`;

const AddAlias: React.FC<{
  onClick?: Function;
  price?: string;
  aliasesEnabled: string;
  onTooltipClick?: Function;
  defaultAliasSetting?: boolean;
  aliasParam?: string | null;
}> = ({
  onClick = () => {},
  price = '0',
  aliasesEnabled = AliasesEnabledType.OFF,
  onTooltipClick = () => {},
  defaultAliasSetting = null,
  aliasParam,
}) => {
  const location = useLocation();
  const isCustomizeOrEdit = useMemo(
    () =>
      location.pathname === '/package/customize' ||
      location.pathname.includes('edit'),
    [location],
  );

  const useDefaultSetting = defaultAliasSetting !== null;

  const aliasesEnabledValue =
    aliasesEnabled === AliasesEnabledType.ON ? true : false ?? false;

  let aliasSelected =
    useDefaultSetting && !isCustomizeOrEdit && !aliasesEnabledValue
      ? defaultAliasSetting
      : aliasesEnabledValue;

  const { state } = useOrderBackgroundCheckContext();
  const { aliasesEnabledChanged } = state || {};

  // If aliasesEnabledChanged is true, we want to use the value from the state in case they navigate away and back to the page
  if (aliasesEnabledChanged) {
    aliasSelected = aliasesEnabledValue;
  }

  const [selected, setSelected] = useState(
    aliasSelected || aliasParam === 'on',
  );

  const handleSelect = useCallback(() => {
    setSelected(!selected);
    onClick(!selected);
  }, [onClick, selected]);

  useEffect(() => {
    document.body.classList.add('page-addons');

    return () => {
      document.body.classList.remove('page-addons');
    };
  }, []);

  return (
    <Container data-testid='add-alias-container'>
      <>
        <BoldTitle data-testid='alias-bold-title'>Alias searches</BoldTitle>
        <RecommendedLabel type='aqua'>Recommended</RecommendedLabel>
      </>
      <Description className='p4'>
        Search for records under{' '}
        <AliasLearnMoreModal text='alternative names' /> (former names,
        nicknames, or pseudonyms) even if the candidate hasn&apos;t shared them.
      </Description>
      <SelectableAlias
        handleSelect={handleSelect}
        selected={selected}
        price={price}
      />
    </Container>
  );
};

export default AddAlias;
