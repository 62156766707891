import { WorkLocation } from 'types';
import { Geo } from '@dashboard-experience/utils';
import type { GetStartedT } from '..';

export const isGeoRequirementFulfilled = (
  geos_required: boolean,
  selectedGeo?: Geo,
): boolean => {
  return !geos_required || Boolean(geos_required && selectedGeo?.country);
};

export const isProgramRequirementFulfilled = (
  programs_enabled: boolean,
  selectedProgram: GetStartedT.Program | null,
  programsRequiredForGeo: boolean,
): boolean => {
  return (
    !programs_enabled ||
    !programsRequiredForGeo ||
    (programsRequiredForGeo && programs_enabled && Boolean(selectedProgram))
  );
};

export const isInfoSourceRequirementFulfilled = (
  selectedInfoSource: GetStartedT.InfoSource,
  selectedInfoMethod: GetStartedT.InfoMethod,
  enteredInvites: GetStartedT.Invite[],
  uploadedCsv?: GetStartedT.BulkInviteCSV,
): boolean => {
  const enteredEmail = enteredInvites[0]?.email;

  const candidiateInvitesRequirement = Boolean(
    selectedInfoSource === 'CANDIDATE' &&
      selectedInfoMethod === 'MANUAL' &&
      enteredEmail?.value &&
      enteredEmail?.isValid,
  );

  const bulkInviteRequirement =
    selectedInfoSource === 'CANDIDATE' &&
    selectedInfoMethod === 'UPLOAD' &&
    Boolean(uploadedCsv?.fileStorageKey);

  const manualRequirements = Boolean(selectedInfoSource === 'MYSELF');

  return (
    candidiateInvitesRequirement || bulkInviteRequirement || manualRequirements
  );
};

export const isNodeRequirementFulfilled = (
  nodes: GetStartedT.Node[],
  selectedNode?: GetStartedT.Node,
): boolean => {
  return (
    nodes.length === 1 ||
    nodes.length === 0 ||
    Boolean(nodes.length > 1 && selectedNode)
  );
};

export const isLocationRequirementFulfilled = (
  selectedLocation?: WorkLocation,
): boolean => {
  const domesticUSLocRequirement = Boolean(
    selectedLocation?.country === 'US' && selectedLocation?.state,
  );

  const internationalLocRequirement = Boolean(
    selectedLocation?.country && selectedLocation?.country !== 'US',
  );
  return domesticUSLocRequirement || internationalLocRequirement;
};

export const isContinueDisabled = ({
  account,
  nodes = [],
  selectedGeo,
  selectedLocation,
  selectedNode,
  selectedProgram,
  selectedInfoSource = 'CANDIDATE',
  selectedInfoMethod = 'MANUAL',
  enteredInvites = [],
  uploadedCsv,
  programsRequiredForGeo = false,
}: GetStartedT.ContinueButtonInput): boolean => {
  const { segmentation_enabled, programs_enabled, geos_required } = account;

  const geoReqFulfilled = isGeoRequirementFulfilled(geos_required, selectedGeo);
  const infoSourceReqFulfilled = isInfoSourceRequirementFulfilled(
    selectedInfoSource,
    selectedInfoMethod,
    enteredInvites,
    uploadedCsv,
  );
  const nodeReqFulfilled = isNodeRequirementFulfilled(nodes, selectedNode);
  const locReqFulfilled = isLocationRequirementFulfilled(selectedLocation);
  const programReqFulfilled = isProgramRequirementFulfilled(
    programs_enabled,
    selectedProgram,
    programsRequiredForGeo,
  );

  if (!segmentation_enabled) {
    return !(geoReqFulfilled && infoSourceReqFulfilled && programReqFulfilled);
  }
  return !(nodeReqFulfilled && locReqFulfilled && infoSourceReqFulfilled);
};
