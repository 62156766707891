/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  BaseSyntheticEvent,
  useRef,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  parseNodeFilter,
  useDebouncedCallback,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import moment from 'moment';
import * as SubscriptionType from 'modules/core-types/subscription';
import { WorkLocation } from 'modules/core-types/continuous_check_subscription';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useFetchNodes } from 'api/nodes';
import {
  CreateSubscriptionParams,
  useCreateSubscription,
} from 'api/continuousChecks';
import {
  getPackagesWithoutProperties,
  useList as usePackagesForAccount,
} from 'api/packages';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';

import { useFlag } from '@dashboard-experience/react-flagr';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import { MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY } from '../../Constants';
import useWorkLocation from './hooks/useWorkLocation';
import { formatDate } from '../../components/Search';
import PostHireModuleCard from '../PostHire/PostHireModuleCard';
import { useUser } from '../../context/CurrentUser';

type Props = {
  candidate: any;
  updateSubscriptions(data: object, action: string): void;
};

function convertWorkLocationToSubmit(workLocation: WorkLocation) {
  const submitWorkLocation = {} as { city?: string; state?: string };
  if (workLocation.city) {
    // @ts-ignore
    submitWorkLocation.city = workLocation.city.name;
  }
  if (workLocation.state) {
    // @ts-ignore
    submitWorkLocation.state = workLocation.state.abbreviation;
  }

  return submitWorkLocation;
}

const ButtonContainer = styled.div`
  button {
    margin: 0 0 0.75rem 0 !important;
  }
`;

const SubscriptionCreate: React.FC<Props> = ({
  candidate,
  updateSubscriptions,
}) => {
  const { account } = candidate;
  const candidateId = candidate.id;
  const currentUser = useUser();
  const segmentationEnabled = account?.segmentation_enabled;
  const hierarchyPresent = account?.hierarchy_present;
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [editing, setEditing] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const permissionsFlag =
    useFlag(MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY)?.variantKey === 'enabled';
  const { createCall, createResult } = useCreateSubscription();

  const onCheckboxClick = useCallback((event, { checked }) => {
    setCheckboxChecked(checked);
  }, []);

  const [selectedPackage, setSelectedPackage] = useState('');
  const [nextOccurrence, setNextOccurrence] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(new Date()).startOf('day').format('MM/DD/YYYY'),
  );
  const [selectedIntervalCount, setSelectedIntervalCount] = useState(Number(1));
  const [selectedNode, setSelectedNode] = useState('');
  const {
    selectedWorkLocation,
    setSelectedWorkLocation,
    setUsState,
    setCountry,
    setCity,
  } = useWorkLocation(undefined);

  const datePickerDivRef = useRef(null);
  const [dateNode, setDateNode] = useState(null);
  useEffect(() => {
    if (datePickerDivRef.current) {
      setDateNode(datePickerDivRef.current);
    }
  }, [editing]);

  const res = usePackagesForAccount({
    accountId: account.id,
    // adding the useCache option allows us to get the cached version of packages
    // which will include Continuous Check packages, which should not be part of the
    // selection, therefore they are being removed by getPackagesWithoutProperties
    // more context: https://gitlab.checkrhq.net/dashboard-experience/customer/-/merge_requests/2513
    useCache: true,
  });

  const data = res?.data || [];

  const canManageSubscriptions = permissionsFlag
    ? hasPermission(currentUser, 'manage_subscriptions')
    : true;

  const packages = getPackagesWithoutProperties(data, {
    continuous_check: true,
    us_only: false,
    // TODO: Verify if packages need to be filtered by this property
    // has_screenings_requiring_data: false,
  });

  const submitCreate = useCallback(() => {
    const payload: CreateSubscriptionParams = {
      candidate_id: candidateId,
      package: selectedPackage,
      start_date: moment(selectedStartDate).startOf('day').format('YYYY-MM-DD'),
      interval_count: selectedIntervalCount,
      interval_unit: 'month',
    };

    if (selectedNode) {
      payload.node = selectedNode;
    }

    if (selectedWorkLocation) {
      payload.work_locations = [
        convertWorkLocationToSubmit(selectedWorkLocation),
      ];
    }

    createCall(payload);
  }, [
    candidateId,
    selectedPackage,
    selectedStartDate,
    selectedIntervalCount,
    selectedNode,
    selectedWorkLocation,
    createCall,
  ]);

  useEffectAfterInitialized(() => {
    setEditing(false);
    if (!createResult.isSuccess) return;
    const subscription = createResult.data as SubscriptionType.Type;
    updateSubscriptions(subscription, 'add');
  }, [createResult.data, createResult.isSuccess]);

  const handleCancelClick = useCallback(() => {
    setEditing(false);
  }, []);

  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleAddSubscriptionClick = useCallback(() => {
    setEditing(true);
    if (currentUser) {
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.REPORT_ADD_SUBSCRIPTION_SELECTED,
        {
          Report: reportProperties,
        },
      );
    }
  }, [currentUser, reportProperties, trackEvent]);

  const handlePackageSelect = useCallback(p => {
    if (p == null) {
      setSelectedPackage('');
      return;
    }

    setSelectedPackage(p.slug);
  }, []);

  const handleIntervalChange = useCallback(
    (e: BaseSyntheticEvent, { value, direction }) => {
      if (e.type === 'click') {
        // Click events are on the up/down arrows which don't have a value
        setSelectedIntervalCount(isNaN(value) ? value.valueAsNumber : value);
      } else {
        setSelectedIntervalCount(e.target.valueAsNumber);
      }
    },
    [],
  );

  const handleStartDateChange = useCallback(e => {
    if (e[0]) {
      const d = e[0].toISOString();
      setSelectedStartDate(formatDate(d));
    }
  }, []);

  // To determine if the Add Subscription button should be active
  useEffectAfterInitialized(() => {
    if (
      !checkboxChecked ||
      !selectedPackage ||
      !selectedStartDate ||
      !selectedIntervalCount
    ) {
      setCanEnroll(false);
      return;
    }

    if (hierarchyPresent) {
      selectedNode && selectedWorkLocation
        ? setCanEnroll(true)
        : setCanEnroll(false);
      return;
    }

    if (segmentationEnabled) {
      selectedWorkLocation ? setCanEnroll(true) : setCanEnroll(false);
      return;
    }

    setCanEnroll(true);
  }, [
    checkboxChecked,
    selectedPackage,
    selectedStartDate,
    selectedIntervalCount,
    hierarchyPresent,
    segmentationEnabled,
    selectedNode,
    selectedWorkLocation,
  ]);

  useEffect(() => {
    if (selectedStartDate && selectedIntervalCount) {
      setNextOccurrence(
        moment(selectedStartDate)
          .add(selectedIntervalCount, 'months')
          .format('MM/DD/YYYY'),
      );
    }
  }, [selectedIntervalCount, selectedStartDate]);

  // Mark filter dirty immediately when changed
  // but debounce updating it to prevent over fetching
  const [nodeFilterIsDirty, setNodeFilterIsDirty] = useState(false);
  const [nodeFilter, setNodeFilter] = useState('');

  const debouncedSetNodeFilter = useDebouncedCallback(
    (newFilter: string) => {
      setNodeFilter(newFilter);
      setNodeFilterIsDirty(false);
    },
    350,
    [debounce, setNodeFilter, setNodeFilterIsDirty],
  );

  const onNodeFilterChange = useCallback(
    newFilter => {
      setNodeFilterIsDirty(true);
      debouncedSetNodeFilter(newFilter);
    },
    [debouncedSetNodeFilter, setNodeFilterIsDirty],
  );

  const { data: { data: nodes = [] } = {}, isLoading: nodeIsLoading } =
    useFetchNodes({
      account,
      nodeName: parseNodeFilter(nodeFilter).name,
    });

  const nodeSelected = useCallback(
    value => {
      setSelectedNode(value?.custom_id);
    },
    [setSelectedNode],
  );

  return (
    <>
      {!editing && (
        <ButtonContainer>
          <M.Button
            kind='secondary'
            onClick={handleAddSubscriptionClick}
            disabled={!canManageSubscriptions}
            data-testid='add-subscription-btn'
          >
            <M.Icon icon='Add' size={20} />
            Add subscription
          </M.Button>
        </ButtonContainer>
      )}
      {editing && (
        <PostHireModuleCard type='shadow' style={{ minHeight: '500px' }}>
          <M.Container.Row>
            <M.Container.Col>
              <M.PackageSelect
                packages={packages}
                onChange={handlePackageSelect}
              />
            </M.Container.Col>
            <M.Container.Col>
              <div ref={datePickerDivRef} style={{ position: 'relative' }}>
                <M.DatePicker
                  datePickerType='single'
                  minDate={moment(new Date())
                    .startOf('day')
                    .format('MM/DD/YYYY')}
                  onChange={handleStartDateChange}
                  value={selectedStartDate}
                  appendTo={dateNode}
                >
                  <M.DatePickerInput
                    id='firstOccurrence'
                    labelText='Subscription start date'
                    placeholder='MM/DD/YYYY'
                  />
                </M.DatePicker>
              </div>
            </M.Container.Col>
          </M.Container.Row>
          <M.Container.Row>
            <M.Container.Col>
              <M.NumberInput
                id='monthlyInterval'
                label='Month(s) before next occurence'
                min={1}
                invalidText='Must be 1 or more'
                onChange={handleIntervalChange}
                allowEmpty={false}
                defaultValue={1}
                max={100}
              />
            </M.Container.Col>
            <M.Container.Col>
              <M.Input
                id='nextOccurrence'
                labelText='Next occurrence'
                type='string'
                value={nextOccurrence}
                disabled
              />
            </M.Container.Col>
          </M.Container.Row>
          {(hierarchyPresent || segmentationEnabled) && (
            <M.Container.Row>
              {hierarchyPresent && (
                <M.Container.Col>
                  <M.NodeSelect
                    nodes={nodes}
                    onChange={nodeSelected}
                    onFilterChange={onNodeFilterChange}
                    loading={nodeIsLoading || nodeFilterIsDirty}
                  />
                </M.Container.Col>
              )}
              {segmentationEnabled && (
                <M.Container.Col>
                  <M.WorkLocationSelect
                    countries={['US']}
                    onCountryChange={setCountry}
                    onStateChange={setUsState}
                    onCityChange={setCity}
                    countrySelectProps={{ withWrapper: false }}
                    localeSelectProps={{ withWrapper: false }}
                    stateSelectProps={{ withWrapper: false }}
                    citySelectProps={{ withWrapper: false }}
                  />
                </M.Container.Col>
              )}
            </M.Container.Row>
          )}
          <M.Container.Row>
            <M.Container.Col colSpan='2'>
              <M.Checkbox
                labelText={
                  'I certify collection of the appropriate authorization from the ' +
                  'consumer as required by the Fair Credit Reporting Act and all ' +
                  'applicable state laws.'
                }
                id='subscription-certify'
                defaultChecked={subscriptionActive}
                onChange={onCheckboxClick}
                required
              />
            </M.Container.Col>
          </M.Container.Row>
          <M.Container.Row>
            <M.Container.Col
              style={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <M.BinaryButtons
                btnLeft={{ name: 'Cancel', onClick: handleCancelClick }}
                btnRight={{
                  name: 'Add subscription',
                  onClick: submitCreate,
                  disabled: !canEnroll,
                }}
                size='sm'
              />
            </M.Container.Col>
          </M.Container.Row>
        </PostHireModuleCard>
      )}
    </>
  );
};

export default SubscriptionCreate;
