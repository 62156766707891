import { Item, children } from '../utilities/hierarchy';

const TREE: Array<Item> = [
  {
    id: 'Vehicles & Traffic',
    name: 'Vehicles & Traffic',
    children: [
      {
        id: 'Unsafe Operation',
        name: 'Unsafe Operation',
        children: [
          {
            id: 'Accident Involving Damage/Injury',
            name: 'Accident Involving Damage/Injury',
            description:
              'Reckless driving causing accident, Involving 2 or more vehicles in motion',
          },
          {
            id: 'Careless Driving',
            name: 'Careless Driving',
            description:
              'Careless operation of a vehicle. This is less severe than Reckless or Negligent Operation. Failing to operate a vehicle with due care.',
          },
          {
            id: 'Disobey Traffic Rules',
            name: 'Disobey Traffic Rules',
            description:
              'Disobeying traffic rules such as a traffic light, stop sign, or other traffic signal.',
          },
          {
            id: 'Distracted Driving',
            name: 'Distracted Driving',
            description:
              'Not paying proper attention to road while driving by means of using a phone or some other activity.',
          },
          {
            id: 'Failure to Report Accident',
            name: 'Failure to Report Accident',
            description:
              'Failure to report an accident to insurance and/or authorities.',
          },
          {
            id: 'Failure to Stop at Scene of Accident',
            name: 'Failure to Stop at Scene of Accident',
            description:
              'Failing to stop when witnessing an accident or involved in (but not causing) an accident.',
          },
          {
            id: 'Hit & Run',
            name: 'Hit & Run',
            description:
              'Causing an accident with vehicle and leaving the scene or striking an unattended vehicle or stationary object',
          },
          {
            id: 'Hit & Run Causing Injury',
            name: 'Hit & Run Causing Injury',
            description:
              'Causing injury (to either a person or property) via a vehicle and not stopping at scene of accident.',
          },
          {
            id: 'Racing',
            name: 'Racing',
            description: 'Racing a vehicle.',
          },
          {
            id: 'Reckless or Negligent Operation',
            name: 'Reckless or Negligent Operation',
            description:
              'Reckless operation of vehicle.  This is more severe than failing to obey traffic rules.  Needs to signify some sort of recklessness or unsafe operation.',
          },
          {
            id: 'Recreational Vehicle Unsafe Operation',
            name: 'Recreational Vehicle Unsafe Operation',
            description: 'Unsafe operation of ATV, boat, plane, etc..',
          },
          {
            id: 'Unlawfully Operate Commercial Vehicle',
            name: 'Unlawfully Operate Commercial Vehicle',
            description:
              'General unlawfully operating commercial motor vehicle - can include charges such as exceeding maximum drive time, etc.',
          },
        ],
      },
      {
        id: 'Speeding',
        name: 'Speeding',
        children: [
          {
            id: 'Speeding',
            name: 'Speeding',
            description: 'Driving a vehicle above speed limit.',
          },
        ],
      },
      {
        id: 'License & Registration',
        name: 'License & Registration',
        children: [
          {
            id: "Driver's License not in Possession",
            name: "Driver's License not in Possession",
            description:
              "Not possessing driver's license while operating a vehicle.  Example is when you left it at home.",
          },
          {
            id: 'Invalid Vehicle Inspection',
            name: 'Invalid Vehicle Inspection',
            description: 'Not possessing a valid vehicle inspection document.',
          },
          {
            id: 'No Valid Registration',
            name: 'No Valid Registration',
            description:
              'Using a vehicle with no valid registration of a vehicle.  Also includes no valid license plates or tags.',
          },
          {
            id: 'Operate a Vehicle without Insurance',
            name: 'Operate a Vehicle without Insurance',
            description:
              'Operating a vehicle without insurance.  Sometimes known as failure to provide financial responsibility.',
          },
          {
            id: "Operate a Vehicle without valid Driver's License",
            name: "Operate a Vehicle without valid Driver's License",
            description:
              "Not having a valid driver's license while operating a vehicle.  Not simply left at home, but not generally having a valid license.",
          },
          {
            id: 'Wrongful Entrustment',
            name: 'Wrongful Entrustment',
            description:
              'Wrongful entrustement of vehicle to one while knowing they cannot legally drive.',
          },
        ],
      },
      {
        id: 'Vehicle Equipment',
        name: 'Vehicle Equipment',
        children: [
          {
            id: 'Car Seat/Child Restraint Violation',
            name: 'Car Seat/Child Restraint Violation',
            description:
              'Not using seatbelt or car seat on child when required.',
          },
          {
            id: 'Commercial Motor Vehicle Equipment Violation',
            name: 'Commercial Motor Vehicle Equipment Violation',
            description:
              'Broken, illegal, or unsafe vehicle equipment (e.g. speedometer, wheels, license plate lights, etc..) on a commercial motor vehicle.',
          },
          {
            id: 'Defective Vehicle Equipment',
            name: 'Defective Vehicle Equipment',
            description:
              'Broken, illegal, or unsafe vehicle equipment (e.g. speedometer, wheels, license plate lights, etc..).',
          },
          {
            id: 'Obscuring License Plate',
            name: 'Obscuring License Plate',
            description:
              'License plate not visible or obscured by cover or other accessory to prevent or impede surveillance equipment or identification.',
          },
          {
            id: 'Recreational Vehicle Equipment Violation',
            name: 'Recreational Vehicle Equipment Violation',
            description:
              'Boat, plane, motorcycle or other vehicle not having proper equipement that is legally necessary.',
          },
          {
            id: 'Seatbelt Violation',
            name: 'Seatbelt Violation',
            description: 'Not wearing a seatbelt.',
          },
          {
            id: 'Unsafe Vehicle Load',
            name: 'Unsafe Vehicle Load',
            description: 'Unsafe load or cargo on a vehicle.',
          },
          {
            id: 'Vehicle Lights Violation',
            name: 'Vehicle Lights Violation',
            description:
              'Issue with headlight or tailights only.  Includes improper use, broken, or other issue. Does not include license plate light.',
          },
          {
            id: 'Window Tint Violation',
            name: 'Window Tint Violation',
            description:
              'Tinted, reflective windows or other vehicle window violation.',
          },
        ],
      },
      {
        id: 'Parking',
        name: 'Parking',
        children: [
          {
            id: 'Disabled Parking Violation',
            name: 'Disabled Parking Violation',
            description: 'Handicap parking violation.',
          },
          {
            id: 'Illegally Parked',
            name: 'Illegally Parked',
            description:
              'Parking in restricted areas or in unrestricted areas for illegal amounts of time.',
          },
        ],
      },
    ],
  },
  {
    id: 'Statutory',
    name: 'Statutory',
    children: [
      {
        id: 'Custody & Support',
        name: 'Custody & Support',
        children: [
          {
            id: 'Allow Unlicensed to Drive',
            name: 'Allow Unlicensed to Drive',
            description: 'Allowing a person who is unlicensed to drive.',
          },
          {
            id: 'Curfew Violation',
            name: 'Curfew Violation',
            description:
              'A minor is out past city/county/state mandated curfew or a parent allows their minor child to be out past city/county/state mandated curfew.',
          },
          {
            id: 'Interference with Custody',
            name: 'Interference with Custody',
            description:
              'Interfering with the custody of a child without privledge to do so.',
          },
          {
            id: 'Nonsupport of Dependents',
            name: 'Nonsupport of Dependents',
            description:
              'Failure to pay child support or other dependent related expenses or care.',
          },
          {
            id: 'Truancy',
            name: 'Truancy',
            description:
              'A parent allowing a child to have any unjustified absence from compulsory education.',
          },
        ],
      },
      {
        id: 'Public Nuisance',
        name: 'Public Nuisance',
        children: [
          {
            id: 'Disorderly Conduct',
            name: 'Disorderly Conduct',
            description: 'Obnoxious or unruly conduct in public.',
          },
          {
            id: 'Littering',
            name: 'Littering',
            description: 'Trash and other materials disposed of on the ground.',
          },
          {
            id: 'Loitering',
            name: 'Loitering',
            description:
              'Loitering is the act of remaining in a particular public place for a protracted time without any apparent purpose.',
          },
          {
            id: 'Maintain a Disorderly House',
            name: 'Maintain a Disorderly House',
            description:
              'Maintaining a place where public nuisance or other disorderly conduct takes place.',
          },
          {
            id: 'Noise Ordinance Violation',
            name: 'Noise Ordinance Violation',
            description:
              'Unreasonable loud noises that are a detriment to public welfare.',
          },
          {
            id: 'Obstruct Passageway',
            name: 'Obstruct Passageway',
            description:
              "Blocking a roadway or sidewalk with a vehicle, one's body, or other object.",
          },
          {
            id: 'Public Urination',
            name: 'Public Urination',
            description: 'Urinating in public.',
          },
          {
            id: 'Unlawful Storage',
            name: 'Unlawful Storage',
            description: 'Wrongfully storing junk materials.',
          },
        ],
      },
      {
        id: 'Safety & Zoning',
        name: 'Safety & Zoning',
        children: [
          {
            id: 'Burning Violation',
            name: 'Burning Violation',
            description:
              'Open burning in prohibited areas or at prohibited times.',
          },
          {
            id: 'Hazardous Materials',
            name: 'Hazardous Materials',
            description: 'Not abiding by law for dangerous or toxic materials.',
          },
          {
            id: 'Illegal Fireworks',
            name: 'Illegal Fireworks',
            description: 'Possessing and using fireworks illegally.',
          },
          {
            id: 'Sanitation Violation',
            name: 'Sanitation Violation',
            description:
              'Violation of sanitation codes which require cleanliness and the safe disposal of all trash and debris.',
          },
          {
            id: 'Zoning Violation',
            name: 'Zoning Violation',
            description:
              'A zoning violation is when a property does not meet the regulations as governed in the Zoning Ordinance.',
          },
        ],
      },
      {
        id: 'Fish & Game',
        name: 'Fish & Game',
        children: [
          {
            id: 'Fishing/Hunting without License',
            name: 'Fishing/Hunting without License',
            description: 'Fishing or hunting without a license.',
          },
          {
            id: 'Miscellaneous Wildlife Violation',
            name: 'Miscellaneous Wildlife Violation',
            description:
              "Any other wildlife violation that doesn't include unlawful possession of animal, unlawful sale of animal, or fishing/hunting without a license.",
          },
          {
            id: 'Unlawful Possession of Animal',
            name: 'Unlawful Possession of Animal',
            description: 'Unlawfully possessing an animal.',
          },
          {
            id: 'Unlawful Sale of Animal',
            name: 'Unlawful Sale of Animal',
            description: 'Unlawful sale of an animal.',
          },
        ],
      },
      {
        id: 'Animal Ordinances',
        name: 'Animal Ordinances',
        children: [
          {
            id: 'Animal at Large',
            name: 'Animal at Large',
            description:
              'An animal at large refers to a domestic animal which is free, unrestrained or not under control.',
          },
          {
            id: 'Animal Ordinance',
            name: 'Animal Ordinance',
            description:
              'General animal law not being followed.  Does not include other defined categories (e.g. Vicious Animal, Not Tag on Animal).',
          },
          {
            id: 'Failure to Vaccinate',
            name: 'Failure to Vaccinate',
            description:
              'Failure to vaccinate animal.  Includes not having a vaccination tag on animal.',
          },
          {
            id: 'Improper Confinement',
            name: 'Improper Confinement',
            description:
              'Improperly confining, restraining, or sheltering an animal. (mistreatment of animals)',
          },
          {
            id: 'No Animal License/Registration',
            name: 'No Animal License/Registration',
            description:
              'No license or registration for a pet.  Includes no license tag.',
          },
          {
            id: 'Vicious Animal',
            name: 'Vicious Animal',
            description:
              'Owning a vicious animal that is abrasive to human interaction.',
          },
        ],
      },
      {
        id: 'Gambling',
        name: 'Gambling',
        children: [
          {
            id: 'Participate Gambling/Lottery Activity',
            name: 'Participate Gambling/Lottery Activity',
            description:
              'Participating in illegal gambling or lottery activity.',
          },
          {
            id: 'Promote Gambling/Lottery Activity',
            name: 'Promote Gambling/Lottery Activity',
            description:
              'Participating in illegal gambling or lottery activity.',
          },
        ],
      },
      {
        id: 'Miscellaneous Citations & Violations',
        name: 'Miscellaneous Citations & Violations',
        children: [
          {
            id: 'Biking or Skateboarding Offense',
            name: 'Biking or Skateboarding Offense',
            description:
              'Bicycle, skateboard, or other similar recreational vehicle citation or violation.',
          },
          {
            id: 'Failure to Pay Toll',
            name: 'Failure to Pay Toll',
            description:
              'Failure to pay a toll for road, bridge or other transportation means.',
          },
          {
            id: 'Failure to Pay Transit Fare',
            name: 'Failure to Pay Transit Fare',
            description: 'Failing to pay the fare for public transportation.',
          },
          {
            id: 'Miscellaneous Citation or Violation',
            name: 'Miscellaneous Citation or Violation',
            description: 'Any other citation or violation.',
          },
          {
            id: 'Non-Criminal Solicitation',
            name: 'Non-Criminal Solicitation',
            description:
              'Unlawfully begging, seeking donations, or conducting door to door sales.',
          },
          {
            id: 'Water Use Violation',
            name: 'Water Use Violation',
            description: 'Using water on restricted days and times.',
          },
        ],
      },
    ],
  },
  {
    id: 'Drugs & Alcohol',
    name: 'Drugs & Alcohol',
    children: [
      {
        id: 'Drugs-Possession/Use',
        name: 'Drugs-Possession/Use',
        children: [
          {
            id: 'Drug Possession',
            name: 'Drug Possession',
            description:
              'Possessing illegal drugs for personal use (does not include Marijuana).',
          },
          {
            id: 'Intent to Possess Drugs',
            name: 'Intent to Possess Drugs',
            description: 'Loitering or trying to purchase illegal drugs.',
          },
          {
            id: 'Possession of Drug Paraphernalia',
            name: 'Possession of Drug Paraphernalia',
            description:
              'Possession of devices or instruments for illegal drug use.  Also includes purchasing or using illegal drug paraphernalia.',
          },
          {
            id: 'Possession of Drug without Prescription',
            name: 'Possession of Drug without Prescription',
            description: 'Possessing a medical drug without a prescription.',
          },
          {
            id: 'Under the Influence of Illegal Drugs',
            name: 'Under the Influence of Illegal Drugs',
            description: 'The act of illegal drug intoxication.',
          },
        ],
      },
      {
        id: 'Drugs-Sale & Manufacture',
        name: 'Drugs-Sale & Manufacture',
        children: [
          {
            id: 'Maintain Drug House',
            name: 'Maintain Drug House',
            description:
              'Knowingly owns, leases, opens, or rents any place for the purpose of manufacturing, distributing, or using any controlled substance.',
          },
          {
            id: 'Marijuana Cultivation',
            name: 'Marijuana Cultivation',
            description: 'Growing marijuana.',
          },
          {
            id: 'Marijuana Manufacture/Sell/Distribute',
            name: 'Marijuana Manufacture/Sell/Distribute',
            description:
              'Contributing to distribution and spread of marijuana.  Also includes possession with intent to sell Marijuana.',
          },
          {
            id: 'Possession with Intent to Sell',
            name: 'Possession with Intent to Sell',
            description:
              'Possession of drugs with intent to sell (does not include Marijuana)..',
          },
          {
            id: 'Sell/Distribute/Manufacture Drugs',
            name: 'Sell/Distribute/Manufacture Drugs',
            description:
              'Contributing to distribution and spread of illegal drugs (does not include Marijuana).  If no drug is specified then choose this instead of Mairijuana distribution.',
          },
          {
            id: 'Sell/Distribute/Manufacture Counterfeit Drugs',
            name: 'Sell/Distribute/Manufacture Counterfeit Drugs',
            description:
              'Contributing to distribution and spread of counterfeit illegal drugs (does not include Marijuana).',
          },
        ],
      },
      {
        id: 'Alcohol & Tobacco',
        name: 'Alcohol & Tobacco',
        children: [
          {
            id: 'Minor in Possession of Alcohol',
            name: 'Minor in Possession of Alcohol',
            description: 'Underage possession of alcohol.',
          },
          {
            id: 'Minor in Possession of Tobacco',
            name: 'Minor in Possession of Tobacco',
            description: 'Underage possession of tobacco.',
          },
          {
            id: 'Open Container',
            name: 'Open Container',
            description:
              'Open container laws prohibit the existence of open containers of alcohol in certain areas, as well as the active consumption of alcohol in those areas',
          },
          {
            id: 'Public Intoxication',
            name: 'Public Intoxication',
            description:
              'Public intoxication, also known as "drunk and disorderly" and drunk in public, is a summary offense in some countries rated to public cases or displays of drunkenness.',
          },
          {
            id: 'Purchase Alcohol by Minor',
            name: 'Purchase Alcohol by Minor',
            description: 'Underage purchase of alcohol.',
          },
          {
            id: 'Purchasing Alcohol for Minor',
            name: 'Purchasing Alcohol for Minor',
            description: 'When a person of age purchases alcohol for a minor.',
          },
          {
            id: 'Unlawful Sale of Alcohol to Minor',
            name: 'Unlawful Sale of Alcohol to Minor',
            description: 'Illegally selling alcohol to an underage person.',
          },
          {
            id: 'Unlawful Sale of Tobacco to Minor',
            name: 'Unlawful Sale of Tobacco to Minor',
            description: 'Illegally selling tobacco to an underage person.',
          },
          {
            id: 'Unstamped Tobacco',
            name: 'Unstamped Tobacco',
            description:
              'Possessing tobacco without tax stamps - not paying tax on tobacco.',
          },
        ],
      },
      {
        id: 'Drugs-Marijuana Possession/Use',
        name: 'Drugs-Marijuana Possession/Use',
        children: [
          {
            id: 'Marijuana Possession/Use',
            name: 'Marijuana Possession/Use',
            description: 'Possessing marijuana.',
          },
        ],
      },
      {
        id: 'Driving under the Influence (DUI)',
        name: 'Driving under the Influence (DUI)',
        children: [
          {
            id: 'Driving Under the Influence',
            name: 'Driving Under the Influence',
            description:
              'Known as a DUI, this is when someone is driving a vehicle while drunk (BAC > .08%) or under the influence of drugs.',
          },
          {
            id: 'Driving Under the Influence (Recreational Vehi...',
            name: 'Driving Under the Influence (Recreational Vehi...',
            description:
              'Driving a boat, plane, bike, or other recreational vehicle under the influence.',
          },
          {
            id: 'Minor Driving under the Influence (DUI)',
            name: 'Minor Driving under the Influence (DUI)',
            description:
              'A minor driving under the influence of drugs or alcohol.',
          },
          {
            id: 'Open Container in Vehicle',
            name: 'Open Container in Vehicle',
            description:
              'Having an open container of alcohol in a vehicle while operating.',
          },
          {
            id: 'Physical Control',
            name: 'Physical Control',
            description:
              "Being in the driver's position of the front seat of a vehicle and having possession of the ignition key while under the influence of alcohol or drugs.",
          },
          {
            id: 'Refuse Chemical Test',
            name: 'Refuse Chemical Test',
            description:
              'Refusing to take a test for driving under the influence.',
          },
        ],
      },
    ],
  },
  {
    id: 'Criminal Intent',
    name: 'Criminal Intent',
    children: [
      {
        id: 'Conspiracy',
        name: 'Conspiracy',
        children: [
          {
            id: 'Attempt to Commit a Crime',
            name: 'Attempt to Commit a Crime',
            description:
              'An attempt to commit a crime. Does not apply to charges that list the actual crime. E.g. Attempted robbery is robbery not an attempt.',
          },
          {
            id: 'Conspiracy',
            name: 'Conspiracy',
            description:
              'Conspiracy is an agreement between two or more persons to commit a crime at some time in the future. Does not apply to charges that list the actual crime. E.g. Conspiracy robbery is robbery not conspiracy.',
          },
        ],
      },
      {
        id: 'Accessory',
        name: 'Accessory',
        children: [
          {
            id: 'Accessory',
            name: 'Accessory',
            description:
              'An accessory is a person who assists in the commission of a crime, but who does not actually participate in the commission of the crime as a joint principal.',
          },
          {
            id: 'Aid & Abet',
            name: 'Aid & Abet',
            description:
              'To assist someone in committing or encourage someone to commit a crime.  Does not apply to charges that list the actual crime.  E.g. Aid & Abet robbery is a robbery.',
          },
          {
            id: 'Compounding',
            name: 'Compounding',
            description:
              'A criminal act in which a person agrees not to report the occurrence of a crime or not to prosecute a criminal offender in exchange for money or other consideration.',
          },
          {
            id: 'Harboring',
            name: 'Harboring',
            description:
              'Harboring a fugitive refers to the crime of knowingly hiding a wanted criminal from the authorities.',
          },
          {
            id: 'Solicitation',
            name: 'Solicitation',
            description:
              'Criminal solicitation is requesting, encouraging or demanding someone to engage in criminal conduct, with the intent to facilitate or contribute to the commission of that crime.',
          },
        ],
      },
      {
        id: 'Criminal Tools',
        name: 'Criminal Tools',
        children: [
          {
            id: 'Possession of Criminal Tools',
            name: 'Possession of Criminal Tools',
            description:
              'To possess tools that are intended to facilitate crimes.',
          },
          {
            id: 'Use Motor Vehicle in Commission of a Crime',
            name: 'Use Motor Vehicle in Commission of a Crime',
            description:
              'Using a motor vehicle during the course of commiting another crime.',
          },
        ],
      },
      {
        id: 'Organized Crime',
        name: 'Organized Crime',
        children: [
          {
            id: 'Gang Activity',
            name: 'Gang Activity',
            description: 'Gang related offenses.',
          },
          {
            id: 'Organized Crime',
            name: 'Organized Crime',
            description:
              'Organized crime is a category of transnational, national, or local groupings of highly centralized enterprises run by criminals who intend to engage in illegal activity, most commonly for money and profit.',
          },
          {
            id: 'Racketeering',
            name: 'Racketeering',
            description:
              'A racket is a planned or organized criminal act, usually in which the criminal act is a form of business or a way to regularly, or briefly but repeatedly, earn illegal or extorted money.',
          },
        ],
      },
      {
        id: 'Obstruction',
        name: 'Obstruction',
        children: [
          {
            id: 'Concealing Death',
            name: 'Concealing Death',
            description:
              'Hinders a discovery of the unlawful killing of another.',
          },
          {
            id: 'Escape',
            name: 'Escape',
            description: 'Escape from penal institution.',
          },
          {
            id: 'Evidence Tampering',
            name: 'Evidence Tampering',
            description:
              'Tampering with evidence can be any action that destroys, alters, conceals, or falsifies any sort of evidence.',
          },
          {
            id: 'Fail to Identify',
            name: 'Fail to Identify',
            description:
              'Failure to give identifying information to authorities after arrest or being detained.',
          },
          {
            id: 'Fail to Obey Law Enforcement/Resist Arrest',
            name: 'Fail to Obey Law Enforcement/Resist Arrest',
            description:
              'Failing to obey instructions of law enforcement. Includes resisting arrest from a law enforcement officer.',
          },
          {
            id: 'Fail to Register as Sex Offender',
            name: 'Fail to Register as Sex Offender',
            description:
              'Failure to register as a sex offender. Often when moving addresses or changing personal information.',
          },
          {
            id: 'False Statement',
            name: 'False Statement',
            description: 'False statement to authorities.',
          },

          {
            id: 'Falsification of Drug Test',
            name: 'Falsification of Drug Test',
            description:
              'Person who uses or posseses any substance or tool used to falsify the results of a drug test.',
          },
          {
            id: 'Fleeing or Attempting to Elude Arrest',
            name: 'Fleeing or Attempting to Elude Arrest',
            description:
              'Fleeing from law enforcement or hiding from law enforcement while being pursued. ',
          },
          {
            id: 'Interfere with Public Safety Officer Duties',
            name: 'Interfere with Public Safety Officer Duties',
            description:
              'Unlawful conduct during emergency call, police or other emergency service.',
          },
          {
            id: 'Perjury',
            name: 'Perjury',
            description:
              'Perjury is the intentional act of swearing a false oath or falsifying an affirmation to tell the truth, whether spoken or in writing, concerning matters material to an official proceeding.',
          },
          {
            id: 'Witness Tampering',
            name: 'Witness Tampering',
            description:
              'Witness tampering is the act of attempting to alter or prevent the testimony of witnesses within criminal or civil proceedings.',
          },
          {
            id: 'Introduce Contraband into Correctional Facility',
            name: 'Introduce Contraband into Correctional Facility',
            description:
              'Smuggling or taking drugs, weapons, or other illicit items into penal institutions.',
          },
        ],
      },
      {
        id: 'Court Orders',
        name: 'Court Orders',
        children: [
          {
            id: 'Bail Jumping',
            name: 'Bail Jumping',
            description:
              'Bail jumping is defined as the intentional failure to comply with conditions of bail, such as leaving the jurisdiction before your court date.',
          },
          {
            id: 'Contempt of Court',
            name: 'Contempt of Court',
            description:
              'Contempt of court is the offence of being disobedient to or discourteous towards a court of law and its officers in the form of behavior that opposes or defies the authority, justice and dignity of the court.',
          },
          {
            id: 'Failure to Appear',
            name: 'Failure to Appear',
            description: 'Failure to appear for a court hearing.',
          },
          {
            id: 'Failure to Pay',
            name: 'Failure to Pay',
            description: 'Failure to pay a court fine.',
          },
          {
            id: 'Failure to Vacate',
            name: 'Failure to Vacate',
            description: 'Failure to vacate housing after eviction.',
          },
          {
            id: 'Fugitive',
            name: 'Fugitive',
            description:
              'A fugitive from justice, also known as a wanted person, can be a person who is either convicted or accused of a crime and hiding from law enforcement in the state or taking refuge in a different country in order to avoid arrest',
          },
          {
            id: 'Probation Violation',
            name: 'Probation Violation',
            description:
              'The criminal act of violating a court ordered probation.',
          },
          {
            id: 'Violation of Protective Order',
            name: 'Violation of Protective Order',
            description:
              'The act of violating a court ordered protective or restraining order.',
          },
          {
            id: 'Violation of Treatment Program',
            name: 'Violation of Treatment Program',
            description: 'Not abiding by a court ordered treatment program.',
          },
          {
            id: 'Habitual Offender',
            name: 'Habitual Offender',
            description:
              "Repeated convictions of crimes.  At the felony level this is a 'habitual felon' charge. At less than felony level this is often for repeated traffic charges (habitual violator).",
          },
        ],
      },
    ],
  },
  {
    id: 'Security',
    name: 'Security',
    children: [
      {
        id: 'Immigration',
        name: 'Immigration',
        children: [
          {
            id: 'Assisting Illegal Alien',
            name: 'Assisting Illegal Alien',
            description: 'Aiding or assisting an illegal immigrant.',
          },
          {
            id: 'Illegal Immigration',
            name: 'Illegal Immigration',
            description:
              "Illegal immigration is the illegal entry of a person or a group of persons across a country's border, in a way that violates the immigration laws of the destination country, with the intention to remain in the country.",
          },
        ],
      },
      {
        id: 'Terrorism',
        name: 'Terrorism',
        children: [
          {
            id: 'Terrorism',
            name: 'Terrorism',
            description:
              'Terrorism is, in the broadest sense, the use of intentionally indiscriminate violence as a means to create terror, or fear, to achieve a political, religious or ideological aim.',
          },
          {
            id: 'Terroristic Threats',
            name: 'Terroristic Threats',
            description:
              'To threaten violence, endangerment, or other terroristic acts.',
          },
        ],
      },
      {
        id: 'Treason',
        name: 'Treason',
        children: [
          {
            id: 'Misprision',
            name: 'Misprision',
            description: 'Concealing a felony or serious crime to a state.',
          },
          {
            id: 'Treason',
            name: 'Treason',
            description:
              "The offense of attempting to overthrow the government of one's country or of assisting its enemies in war. Includes spying and treason.",
          },
        ],
      },
    ],
  },
  {
    id: 'Fraud & Deception',
    name: 'Fraud & Deception',
    children: [
      {
        id: 'Fraud',
        name: 'Fraud',
        children: [
          {
            id: 'Bank/Securities Fraud',
            name: 'Bank/Securities Fraud',
            description:
              'Security fraud, also known as stock fraud and investment fraud, is a deceptive practice in the stock or commodities markets that induces investors to make purchase or sale decisions on the basis of false information, frequently resulting in losses, in violation of securities laws.',
          },
          {
            id: 'Benefits/Welfare Fraud',
            name: 'Benefits/Welfare Fraud',
            description:
              'Submitting false or misleading information to a government agency in order to obtain a benefit. Includes food stamp fraud and welfare code violations.',
          },
          {
            id: 'Bigamy',
            name: 'Bigamy',
            description:
              'Bigamy is the act of entering into a marriage with one person while still legally married to another.',
          },
          {
            id: 'Check Fraud',
            name: 'Check Fraud',
            description:
              'Fraudulently using checks.  More intent to decieve than a worthless or bad check.',
          },
          {
            id: 'Credit Card Fraud',
            name: 'Credit Card Fraud',
            description:
              'Credit card fraud is a wide-ranging term for theft and fraud committed using or involving a payment card, such as a credit card or debit card, as a fraudulent source of funds in a transaction.',
          },
          {
            id: 'Fraud',
            name: 'Fraud',
            description:
              'Wrongful or criminal deception intended to result in financial or personal gain. Also includes concealment of financial assets or property.',
          },
          {
            id: 'Health Care Fraud',
            name: 'Health Care Fraud',
            description: 'Fraud related to medicine or health care.',
          },
          {
            id: 'Insurance Fraud',
            name: 'Insurance Fraud',
            description:
              'Insurance fraud is any act committed with the intent to obtain a fraudulent outcome from an insurance process.',
          },
          {
            id: 'Money Laundering',
            name: 'Money Laundering',
            description:
              'Money laundering is the process of transforming the profits of crime and corruption into ostensibly "legitimate" assets.',
          },
        ],
      },
      {
        id: 'Embezzlement',
        name: 'Embezzlement',
        children: [
          {
            id: 'Embezzlement',
            name: 'Embezzlement',
            description:
              'Embezzlement is the act of withholding assets for the purpose of conversion (theft) of such assets, by one or more persons to whom the assets were entrusted, either to be held or to be used for specific purposes.',
          },
        ],
      },
      {
        id: 'Cyber Crimes',
        name: 'Cyber Crimes',
        children: [
          {
            id: 'Cyber Crimes',
            name: 'Cyber Crimes',
            description:
              'Use of computer, communication facility or network to commit criminal act.  Does not include child pornography, nor solicitation of minor via computer.',
          },
        ],
      },
      {
        id: 'Bribery & Corruption',
        name: 'Bribery & Corruption',
        children: [
          {
            id: 'Bribery',
            name: 'Bribery',
            description:
              'Offering, giving, receiving, or soliciting of any item of value to influence the actions of an official or other person in charge of a public or legal duty.',
          },
          {
            id: 'Corruption',
            name: 'Corruption',
            description: 'Dishonest or fraudulent conduct by those in power.',
          },
          {
            id: 'Official Misconduct',
            name: 'Official Misconduct',
            description:
              'Commission of an unlawful act, done in an official capacity, which affects the performance of official duties.',
          },
          {
            id: 'Use Public Influence',
            name: 'Use Public Influence',
            description:
              'Using position of power to force unwanted conduct or actions.',
          },
        ],
      },
      {
        id: 'Identity Theft & Impersonation',
        name: 'Identity Theft & Impersonation',
        children: [
          {
            id: 'Criminal Impersonation',
            name: 'Criminal Impersonation',
            description:
              'Impersonating another person for financial or other gain.',
          },
          {
            id: 'Identity Theft',
            name: 'Identity Theft',
            description:
              "Identity theft occurs when someone uses another's personally identifying information, like their name, identifying number, or credit card number, without their permission, to commit fraud or other crimes.",
          },
          {
            id: 'Impersonating an Officer',
            name: 'Impersonating an Officer',
            description:
              'Impersonating an officer or public official - often to commit a crime.',
          },
          {
            id: 'Possess Fake ID',
            name: 'Possess Fake ID',
            description: 'Possessing fake identification.',
          },
          {
            id: 'Unlawful Use of ID',
            name: 'Unlawful Use of ID',
            description:
              'Unlawfully using identification to gain entry or access to property, finances, or any other purpose.',
          },
        ],
      },
      {
        id: 'Forgery',
        name: 'Forgery',
        children: [
          {
            id: 'Counterfeiting',
            name: 'Counterfeiting',
            description:
              'Counterfeit products are fakes or unauthorized replicas of the real product.',
          },
          {
            id: 'Fake/False/Altered Identification',
            name: 'Fake/False/Altered Identification',
            description:
              'Creating or trafficking in fake or unauthorized identification documents.',
          },
          {
            id: 'Fictitious Plates',
            name: 'Fictitious Plates',
            description:
              'Fake or unauthorized vehicle license plates, registration or tags.',
          },
          {
            id: 'Forgery',
            name: 'Forgery',
            description:
              'Forgery is the process of making, adapting, or imitating objects, statistics, or documents with the intent to deceive for the sake of altering the public perception, or to earn profit by selling the forged item.',
          },
          {
            id: 'Prescription Forgery',
            name: 'Prescription Forgery',
            description: 'Forging medical prescriptions.',
          },
          {
            id: 'Tamper with Official Record',
            name: 'Tamper with Official Record',
            description:
              'Manipulating, falsifying, or destroying official records. Does not include tampering with victim or evidence of a criminal case.',
          },
        ],
      },
      {
        id: 'Worthless Check',
        name: 'Worthless Check',
        children: [
          {
            id: 'Worthless Instrument',
            name: 'Worthless Instrument',
            description:
              'Bad check, worthless check, or any other worthless document used to exchange money. Less fraudulent intent than check fraud.',
          },
        ],
      },
      {
        id: 'Business & Tax',
        name: 'Business & Tax',
        children: [
          {
            id: 'Business Violations',
            name: 'Business Violations',
            description:
              'Various business violations that do not include the following (Contracting without a License, Failure to Pay Wages, No Business License, Tax Violation).',
          },
          {
            id: 'Contracting without a License',
            name: 'Contracting without a License',
            description:
              'Providing labor or materials professionally without a license.',
          },
          {
            id: 'Failure to Pay Wages',
            name: 'Failure to Pay Wages',
            description: 'Failure to pay employee wages.',
          },
          {
            id: 'No Business License',
            name: 'No Business License',
            description: 'Operating a business without a license.',
          },
          {
            id: 'Tax Violation',
            name: 'Tax Violation',
            description:
              'Failure to file, pay, or any other violation of the tax code.',
          },
        ],
      },
    ],
  },
  {
    id: 'Theft & Property',
    name: 'Theft & Property',
    children: [
      {
        id: 'Robbery',
        name: 'Robbery',
        children: [
          {
            id: 'Armed Robbery',
            name: 'Armed Robbery',
            description: 'Robbery involving use of a weapon.',
          },
          {
            id: 'Carjacking',
            name: 'Carjacking',
            description:
              'Carjacking is a robbery in which the item taken over is a vehicle.',
          },
          {
            id: 'Robbery',
            name: 'Robbery',
            description:
              'Robbery is the crime of taking or attempting to take anything of value by force, threat of force, or by putting the victim in fear.',
          },
        ],
      },
      {
        id: 'Burglary',
        name: 'Burglary',
        children: [
          {
            id: 'Burglary',
            name: 'Burglary',
            description:
              'Burglary (also called breaking and entering and sometimes housebreaking) is an unlawful entry into a building or other location for the purposes of committing an offence.',
          },
        ],
      },
      {
        id: 'Arson',
        name: 'Arson',
        children: [
          {
            id: 'Arson',
            name: 'Arson',
            description:
              'Arson is a crime of intentionally, deliberately and maliciously setting fire to buildings, wildland areas, abandoned homes, vehicles or other property with the intent to cause damage.',
          },
        ],
      },
      {
        id: 'Theft',
        name: 'Theft',
        children: [
          {
            id: 'Auto Theft',
            name: 'Auto Theft',
            description: 'Stealing a car (not a robbery/carjacking).',
          },
          {
            id: 'Credit Card Theft',
            name: 'Credit Card Theft',
            description: 'Stealing a credit card or card information.',
          },
          {
            id: 'Criminal Conversion',
            name: 'Criminal Conversion',
            description:
              'A person who knowingly or intentionally exerts unauthorized control over property of another person commits criminal conversion.',
          },
          {
            id: 'Grand Theft (Non Auto)',
            name: 'Grand Theft (Non Auto)',
            description:
              'Theft of high value property. (Value over $500 in most jurisdictions or as otherwise indicated in the charge name)',
          },
          {
            id: 'Theft',
            name: 'Theft',
            description: 'General stealing.',
          },
          {
            id: 'Unauthorized Use of Property',
            name: 'Unauthorized Use of Property',
            description:
              'Using property without permission of owner, oftentimes a car.',
          },
        ],
      },
      {
        id: 'Petty Theft',
        name: 'Petty Theft',
        children: [
          {
            id: 'Petty Theft',
            name: 'Petty Theft',
            description:
              'Petty theft is a type of theft crime that is based on the low value of the stolen property.',
          },
          {
            id: 'Retail Theft',
            name: 'Retail Theft',
            description:
              'Shoplifting or a theft that takes place in a retail store. Includes concealment/price altering.',
          },
        ],
      },
      {
        id: 'Possession of Stolen Property',
        name: 'Possession of Stolen Property',
        children: [
          {
            id: 'Dealing Stolen Property',
            name: 'Dealing Stolen Property',
            description:
              'Distributing/selling property that is known to be stolen.',
          },
          {
            id: 'Possession/Receipt of Stolen Property',
            name: 'Possession/Receipt of Stolen Property',
            description:
              'Possessing or buying property that is known to be stolen.',
          },
        ],
      },
      {
        id: 'Vandalism & Mischief',
        name: 'Vandalism & Mischief',
        children: [
          {
            id: 'Graffiti',
            name: 'Graffiti',
            description:
              'Writing or drawings scribbled, scratched, or sprayed illicitly on a wall or other surface in a public place.',
          },
          {
            id: 'Vandalism & Mischief',
            name: 'Vandalism & Mischief',
            description:
              'Recklessly defacing or damaging property of another person.  Involves Criminal Damage.',
          },
        ],
      },
      {
        id: 'Trespassing',
        name: 'Trespassing',
        children: [
          {
            id: 'Prowling',
            name: 'Prowling',
            description:
              'Prowling generally refers to the crime of lurking in an area with an intent to commit a crime.',
          },
          {
            id: 'Trespassing',
            name: 'Trespassing',
            description:
              "To enter an owner's land or property without permission.",
          },
          {
            id: 'Unlawful Entry',
            name: 'Unlawful Entry',
            description:
              'Unlawful entry is entering property for the purpose of damaging the property or interfering with the lawful use of the property by the rightful owner of the property.',
          },
        ],
      },
    ],
  },
  {
    id: 'Sexual',
    name: 'Sexual',
    children: [
      {
        id: 'Sexual Abuse',
        name: 'Sexual Abuse',
        children: [
          {
            id: 'Child Pornography',
            name: 'Child Pornography',
            description: 'Pornography involving children.',
          },
          {
            id: 'Incest',
            name: 'Incest',
            description:
              'Incest is sexual activity between family members or close relatives.',
          },
          {
            id: 'Lewd Behavior with a Minor',
            name: 'Lewd Behavior with a Minor',
            description:
              'Crude and offensive conduct that is sexual in nature with a minor.',
          },
          {
            id: 'Pandering Obscenities',
            name: 'Pandering Obscenities',
            description:
              'Creating or distributing sexual acts on camera without consent.',
          },
          {
            id: 'Rape',
            name: 'Rape',
            description: 'Forced sexual intercourse with another person.',
          },
          {
            id: 'Sexual Assault',
            name: 'Sexual Assault',
            description: 'Unwanted sexual contact.',
          },
          {
            id: 'Sexual Assault of a Minor',
            name: 'Sexual Assault of a Minor',
            description: 'Unwanted sexual contact with a minor.',
          },
          {
            id: 'Sodomy',
            name: 'Sodomy',
            description:
              'Sexual intercourse involving anal or oral copulation.',
          },
        ],
      },
      {
        id: 'Lewd Behavior',
        name: 'Lewd Behavior',
        children: [
          {
            id: 'Improper Sexual Relationship',
            name: 'Improper Sexual Relationship',
            description:
              'Relationship with someone of age and someone not of age. Does not include lewd acts with a minor. Commonly a student/teacher relationship.',
          },
          {
            id: 'Indecent Exposure',
            name: 'Indecent Exposure',
            description:
              "The crime of intentionally showing one's sexual organs in public.",
          },
          {
            id: 'STD Exposure',
            name: 'STD Exposure',
            description:
              'Exposing another to a sexually transmitted disease by concealment. Includes giving HIV to another.',
          },
          {
            id: 'Lewd & Lascivious Conduct',
            name: 'Lewd & Lascivious Conduct',
            description:
              'Offense sexual in conduct, usually involving touching, not as severe as rape or sexual assault.',
          },
          {
            id: 'Voyeurism',
            name: 'Voyeurism',
            description:
              'The practice of gaining sexual pleasure from watching others when they are naked or engaged in sexual activity.',
          },
        ],
      },
      {
        id: 'Prostitution',
        name: 'Prostitution',
        children: [
          {
            id: 'Promoting Prostitution',
            name: 'Promoting Prostitution',
            description:
              'Promoting sex work - running a brothel or other area of prositution.',
          },
          {
            id: 'Prostitution',
            name: 'Prostitution',
            description: 'Engage in sexual acts for compensation.',
          },
          {
            id: 'Prostitution Involving Minor',
            name: 'Prostitution Involving Minor',
            description: 'Engage in sexual acts with a minor for compensation.',
          },
          {
            id: 'Soliciting a Prostitute',
            name: 'Soliciting a Prostitute',
            description: 'Requesting or seeking out sexual acts for money.',
          },
        ],
      },
    ],
  },
  {
    id: 'Violence',
    name: 'Violence',
    children: [
      {
        id: 'Assault & Battery',
        name: 'Assault & Battery',
        children: [
          {
            id: 'Assault & Battery',
            name: 'Assault & Battery',
            description:
              'Assault & Battery is a physical act that results in that harmful or offensive contact.',
          },
          {
            id: 'Assault or Battery on Police Officer',
            name: 'Assault or Battery on Police Officer',
            description:
              'Assault & Battery is a physical act that results in that harmful or offensive contact against a police officer or other public official.',
          },
          {
            id: 'Assault with a Deadly Weapon',
            name: 'Assault with a Deadly Weapon',
            description:
              'An assault with a deadly weapon occurs when an attacker accompanies a physical attack with a physical object capable of inflicting serious bodily injury or death.',
          },
          {
            id: 'Domestic Assault & Battery',
            name: 'Domestic Assault & Battery',
            description: 'Assault & Battery of a household member.',
          },
          {
            id: 'Strangulation',
            name: 'Strangulation',
            description:
              'Strangling is compression of the neck that may lead to unconsciousness or death by causing an increasingly hypoxic state in the brain.',
          },
          {
            id: 'Throwing of Bodily Fluids',
            name: 'Throwing of Bodily Fluids',
            description:
              'Bodily fluid means any bodily secretion, including but not limited to feces, urine, blood, and saliva.',
          },
        ],
      },
      {
        id: 'Child & Elder Abuse',
        name: 'Child & Elder Abuse',
        children: [
          {
            id: 'Child Abandonment',
            name: 'Child Abandonment',
            description:
              "Child abandonment is the practice of relinquishing interests and claims over one's offspring in an extralegal way with the intent of never again resuming or reasserting them.",
          },
          {
            id: 'Child Abuse',
            name: 'Child Abuse',
            description:
              'Child abuse or child maltreatment is physical or psychological maltreatment or neglect of a child or children, especially by a parent or other caregiver.',
          },
          {
            id: 'Elder/Disabled Abuse',
            name: 'Elder/Disabled Abuse',
            description:
              "Physical or psychological maltreatment or neglect of a disalbed or eledery person in one's care.",
          },
          {
            id: 'Endangering Welfare of Children',
            name: 'Endangering Welfare of Children',
            description:
              'Violating a duty of care, protection or support of a child.',
          },
          {
            id: 'Fail to Report Child & Elder Abuse',
            name: 'Fail to Report Child & Elder Abuse',
            description:
              'Failing to report child, disabled, or elderly abuse to authorities.',
          },
        ],
      },
      {
        id: 'Harassment & Threats',
        name: 'Harassment & Threats',
        children: [
          {
            id: 'Blackmail/Extortion',
            name: 'Blackmail/Extortion',
            description:
              'Refers to intimidating or threatening the person with violence, social, or emotional defemation in exchange for money or another form of worth.',
          },
          {
            id: 'Harassment',
            name: 'Harassment',
            description:
              'Behavior that appears to be disturbing or threatening.',
          },
          {
            id: 'Hate Crimes',
            name: 'Hate Crimes',
            description:
              'A crime motivated by racial, sexual, or other prejudice, typically one involving violence.',
          },
          {
            id: 'Hazing',
            name: 'Hazing',
            description:
              'The practice of rituals, challenges, and other activities involving harassment, abuse or humiliation used as a way of initiating a person into a group.',
          },
          {
            id: 'Intimidation',
            name: 'Intimidation',
            description:
              'Intimidation is intentional behavior that "would cause a person of ordinary sensibilities" to fear injury or harm.',
          },
          {
            id: 'Invasion of Privacy',
            name: 'Invasion of Privacy',
            description:
              "Intrusion upon an individual's reasonable expectation of privacy.",
          },
          {
            id: 'Menacing',
            name: 'Menacing',
            description:
              'Conduct placing others in fear of imminent serious physical injury.',
          },
          {
            id: 'Stalking',
            name: 'Stalking',
            description:
              'Stalking can be defined as the willful and repeated following, watching and/or harassing of another person.',
          },
          {
            id: 'Telephone/Cyber Harassment',
            name: 'Telephone/Cyber Harassment',
            description:
              'Harassment via phone, computer, or other digital device.',
          },
          {
            id: 'Threats',
            name: 'Threats',
            description: 'Threatening another - not terroristic in nature.',
          },
        ],
      },
      {
        id: 'Abduction & Restraint',
        name: 'Abduction & Restraint',
        children: [
          {
            id: 'Abduction',
            name: 'Abduction',
            description:
              'Abduction is when someone uses deceit or force in order to take a person or a child away from their home or relatives.',
          },
          {
            id: 'False Imprisonment',
            name: 'False Imprisonment',
            description:
              'False imprisonment occurs when a person is restricted in their personal movement within any area without justification or consent.',
          },
          {
            id: 'Human Trafficking',
            name: 'Human Trafficking',
            description:
              'The action or practice of illegally transporting people from one country or area to another, typically for the purposes of forced labour or commercial sexual exploitation.',
          },
          {
            id: 'Kidnapping',
            name: 'Kidnapping',
            description:
              'Kidnapping is taking away or forcefully transporting a person against their will and holding hostage for a ransom.',
          },
          {
            id: 'Unlawful Restraint',
            name: 'Unlawful Restraint',
            description:
              'The crime of unlawful restraint occurs whenever someone illegally deprives others of their physical freedom.',
          },
        ],
      },
      {
        id: 'Weapons & Endangerment',
        name: 'Weapons & Endangerment',
        children: [
          {
            id: 'Endangerment',
            name: 'Endangerment',
            description:
              'Endangerment is a type of crime involving conduct that is wrongful and reckless or wanton, and likely to produce death or grievous bodily harm to another person.',
          },
          {
            id: 'Manufacture/Sale/Import of Weapons',
            name: 'Manufacture/Sale/Import of Weapons',
            description: 'Selling or distributing weapons illegally.',
          },
          {
            id: 'Reckless Use of Firearm',
            name: 'Reckless Use of Firearm',
            description:
              'Reckless, wanton use or negligent discharge of firearm.',
          },
          {
            id: 'Unlawful Possession of Ammunition',
            name: 'Unlawful Possession of Ammunition',
            description: 'Unlawfully possessing and/or storing ammunition.',
          },
          {
            id: 'Unlawful Possession of Firearm',
            name: 'Unlawful Possession of Firearm',
            description:
              'Unlawfully possessing and/or storing a firearm (felon owning firearm, no license for firearm, carrying a concealed firearm, etc)',
          },
          {
            id: 'Use Firearm/Weapon in Commission of a Crime',
            name: 'Use Firearm/Weapon in Commission of a Crime',
            description:
              'Using a firearm/weapon during the course of commiting another crime.',
          },
          {
            id: 'Weapons Violations',
            name: 'Weapons Violations',
            description: 'General weapons violations.',
          },
        ],
      },
      {
        id: 'Disorderly Behavior',
        name: 'Disorderly Behavior',
        children: [
          {
            id: 'Causing Catastrophe',
            name: 'Causing Catastrophe',
            description:
              'A person causes a catastrophe by explosion, fire, flood, avalanche, collapse of building, release of poison, or other means.',
          },
          {
            id: 'Disorderly Conduct (with Violence)',
            name: 'Disorderly Conduct (with Violence)',
            description:
              'Engaging in disorderly conduct that is violent in nature.',
          },
          {
            id: 'Fighting',
            name: 'Fighting',
            description: 'Fighting, affray.',
          },
          {
            id: 'Inducing Panic',
            name: 'Inducing Panic',
            description: 'Cause serious public inconvenience or alarm.',
          },
          {
            id: 'Interfere with Electronic Communications',
            name: 'Interfere with Electronic Communications',
            description:
              'Preventing a person from calling emergency services in the course of a dispute (often domestic).',
          },
          {
            id: 'Resist Arrest (with Violence)',
            name: 'Resist Arrest (with Violence)',
            description: "Resisting one's arrest with violence.",
          },
          {
            id: 'Rioting or Mob Action',
            name: 'Rioting or Mob Action',
            description: 'A violent disturbance of the peace by a crowd.',
          },
        ],
      },
      {
        id: 'Animal Cruelty',
        name: 'Animal Cruelty',
        children: [
          {
            id: 'Animal Abandonment',
            name: 'Animal Abandonment',
            description: 'Abandoning a domesticated animal.',
          },
          {
            id: 'Animal Cruelty',
            name: 'Animal Cruelty',
            description:
              'Generally cruelty towards animals not listed in other categories.',
          },
          {
            id: 'Animal Fighting',
            name: 'Animal Fighting',
            description:
              'Dog fighting, or any other illegal fighting of animals.',
          },
          {
            id: 'Leave without Food & Water',
            name: 'Leave without Food & Water',
            description: 'Leaving an animal one owns without food or water.',
          },
          {
            id: 'Mutilate Animal',
            name: 'Mutilate Animal',
            description:
              'Inflict a violent and disfiguring injury on an animal.',
          },
          {
            id: 'Overworking Animals',
            name: 'Overworking Animals',
            description:
              "Overworking the animals one owns, putting the animal's life in danger.",
          },
        ],
      },
    ],
  },
  {
    id: 'Homicide',
    name: 'Homicide',
    children: [
      {
        id: 'Intentional Homicide',
        name: 'Intentional Homicide',
        children: [
          {
            id: 'Intentional Homicide',
            name: 'Intentional Homicide',
            description: 'Intentionally kill another person.',
          },
        ],
      },
      {
        id: 'Attempted Homicide',
        name: 'Attempted Homicide',
        children: [
          {
            id: 'Attempted Homicide',
            name: 'Attempted Homicide',
            description: 'Attempt to kill another person.',
          },
        ],
      },
      {
        id: 'Unintentional Killing',
        name: 'Unintentional Killing',
        children: [
          {
            id: 'Hit & Run Causing Death',
            name: 'Hit & Run Causing Death',
            description:
              'Causing death via a vehicle and not stopping at scene of accident.',
          },
          {
            id: 'Manslaughter',
            name: 'Manslaughter',
            description:
              'The crime of killing a human being without malice aforethought, or otherwise in circumstances not amounting to murder.  Does not include driving causing death.',
          },
          {
            id: 'Negligent Homicide',
            name: 'Negligent Homicide',
            description:
              'Allowing another person to die through criminal negligence.',
          },
          {
            id: 'Reckless Driving Causing Death',
            name: 'Reckless Driving Causing Death',
            description:
              'Causing a death from reckless driving.  Includes driving under the influence causing death.',
          },
        ],
      },
    ],
  },
];

export const CATEGORIES: Array<Item> = Object.values(TREE);

export const SUB_CATEGORIES: Array<Item> = children(CATEGORIES);

export const CHARGES: Array<Item> = children(SUB_CATEGORIES);

export const CHARGES_DESCRIPTION_MAP = Object.fromEntries(
  CHARGES.map(charge => [charge.name, charge.description]),
);
