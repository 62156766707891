import { useMemo } from 'react';
import { debug } from 'utils';
import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { toastError, toastSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import {
  aggregatedBulkOrderHistory,
  aggregatedManualBulkOrderHistory,
  BillableNodePayload,
  bulkOrderHistory,
  credit,
  creditMemos,
  customer,
  downloadBulkOrderHistoryFile,
  downloadCreditMemo,
  downloadInvoiceFile,
  DownloadInvoiceParams,
  downloadTaxDocument,
  getNodesWithBillingPrefs,
  invoices,
  putBillableNode,
  putBillingCustomer,
  taxDocuments,
  getCurrentAccountBalance,
} from './actions';
import { BillingCustomer } from '../../types/Billing';

export const useGetCustomer = (accountId: string) => {
  const key: AnyQueryKey = ['billing/customer', { id: accountId }];

  const request = () => customer(accountId);

  const res = useQuery(key, request, {
    onError: err => {
      debug(`Error fetching billing customer: ${err}`);
    },
  });

  const payload = useMemo(
    () => ({
      customerData: res?.data,
      isLoading: res?.isLoading,
      isSuccess: res?.isSuccess,
      error: res?.error,
      refetch: res?.refetch,
    }),
    [res?.data, res?.isLoading, res?.isSuccess, res?.error, res?.refetch],
  );

  return payload;
};

export const useGetInvoices = (accountId: string) => {
  const key: AnyQueryKey = ['billing/invoices', { id: accountId }];

  const request = () => invoices(accountId);

  return useQuery(key, request, {
    enabled: Boolean(accountId),
    refetchOnWindowFocus: false,
  });
};

export const useGetTaxDocuments = () => {
  const key: AnyQueryKey = ['billing/documents'];

  const request = () => taxDocuments();

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: err => {
      debug(`Error fetching tax documents: ${err}`);
    },
  });
};

export const useGetCreditMemos = (accountId: string) => {
  const key: AnyQueryKey = ['billing/credit_memos', { id: accountId }];

  const request = () => creditMemos(accountId);

  return useQuery(key, request, {
    enabled: Boolean(accountId),
    refetchOnWindowFocus: false,
  });
};

export const useDownloadInvoiceFile = () => {
  const request = (params: DownloadInvoiceParams) =>
    downloadInvoiceFile(params);

  const [downloadInvoiceCall, downloadInvoiceResult] = useMutation(request, {
    onError: err => {
      debug(`File download error: ${err}`);
    },
  });

  return { downloadInvoiceCall, downloadInvoiceResult };
};

export const useDownloadTaxDocument = () => {
  const request = (id: string) => downloadTaxDocument(id);

  const [call, result] = useMutation(request, {
    onError: err => {
      debug(`File download error: ${err}`);
    },
  });

  return { call, result };
};

export const useDownloadCreditMemo = () => {
  const request = (id: string) => downloadCreditMemo(id);

  const [downloadMemoCall, downloadMemoResult] = useMutation(request, {
    onError: err => {
      debug(`File download error: ${err}`);
    },
  });

  return { downloadMemoCall, downloadMemoResult };
};

export const useGetCredits = (accountId: string) => {
  const key: AnyQueryKey = ['billing/credits', { id: accountId }];

  const request = () => credit(accountId);

  return useQuery(key, request, {
    enabled: Boolean(accountId),
    refetchOnWindowFocus: false,
  });
};

export const useGetBulkOrderHistory = (
  accountId: string,
  page: number,
  per_page: number,
) => {
  const key: AnyQueryKey = ['billing/csv_uploads', { id: accountId, page }];

  const request = () => bulkOrderHistory(accountId, page, per_page);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: err => {
      debug(`Error fetching order history: ${err}`);
    },
  });
};

export const useGetAggregatedBulkOrderHistory = (accountId: string) => {
  const key: AnyQueryKey = ['billing/csv_uploads', { id: accountId }];

  const request = () => aggregatedBulkOrderHistory(accountId);
  const dispatch = useDispatch();

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onSuccess: ({ data, error }) => {
      if (error) dispatch(toastError('ERROR:', error, 0));
    },
    onError: err => {
      dispatch(toastError('ERROR:', `Error fetching order history: ${err}`));
    },
  });
};

export const useGetAggregatedManualBulkOrderHistory = (accountId: string) => {
  const key: AnyQueryKey = ['billing/manual_bulk_orders', { id: accountId }];

  const request = () => aggregatedManualBulkOrderHistory(accountId);
  const dispatch = useDispatch();

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: err => {
      dispatch(toastError('ERROR:', `Error fetching order history: ${err}`));
    },
  });
};

export const useDownloadBulkOrderHistoryFile = () => {
  const request = (params: { s3ObjectKey: string }) =>
    downloadBulkOrderHistoryFile(params.s3ObjectKey);
  const dispatch = useDispatch();

  const [call, result] = useMutation(request, {
    onError: err => {
      dispatch(toastError('File download error:', `${err}`));
    },
  });

  return { call, result };
};

export const usePutBillingCustomer = () => {
  const dispatch = useDispatch();
  const request = (params: BillingCustomer) => putBillingCustomer(params);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Profile successfully updated'));
    },
    onError: () => {
      dispatch(toastError('There was an error updating your profile'));
    },
  });

  return { call, result };
};

export const usePutBillableNode = () => {
  const dispatch = useDispatch();
  const request = (params: BillableNodePayload) => putBillableNode(params);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Node successfully updated'));
    },
    onError: () => {
      dispatch(
        toastError(
          'There was an error updating the billing preferences for this node',
        ),
      );
    },
  });

  return { call, result };
};

export const useGetNodeWithBillingPrefs = () => {
  const key: AnyQueryKey = ['billing/nodes_billing_prefs'];

  const request = () => getNodesWithBillingPrefs();

  const { data, isLoading, error, refetch } = useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error, refetch };
};

export const useGetCurrentAccountBalance = (
  enabled: boolean,
  accountId: string,
) => {
  const key: AnyQueryKey = ['billing/open_balance', { id: accountId }];
  const request = () => getCurrentAccountBalance(accountId);

  const { data, isLoading, error, refetch } = useQuery(key, request, {
    enabled,
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error, refetch };
};
