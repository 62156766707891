import React, { useCallback } from 'react';
import {
  DASHBOARD_LANDING_EVENT_NAMES,
  getDashboardUrl,
  useTrackEvent,
} from 'utils';
import { useUser } from 'context/CurrentUser';
import { useLocation, Link } from 'react-router-dom';
import { accountHasPermission } from '@dashboard-experience/utils';
import { CheckrInitial } from './icons';

export const LogoInitial = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');

  const showDashboardLandingPage = accountHasPermission(
    user,
    'is_self_service_account',
  );
  const destination = showDashboardLandingPage ? 'Home' : 'Candidate';

  const handleTracking = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.LOGO_CLICKED, {
        'Logo Destination': destination,
      });
    }
  }, [destination, isSignup, trackEvent]);

  return (
    <Link
      data-testid='logo-link'
      to={getDashboardUrl('/')}
      onClick={handleTracking}
      className='logo'
      target='_self'
    >
      <CheckrInitial />
    </Link>
  );
};

export default LogoInitial;
