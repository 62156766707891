/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Helmet } from 'react-helmet';

import '@dashboard-experience/mastodon/lib/styles.css';
import '@dashboard-experience/mastodon/lib/reset.css';
import GlobalStyle from 'GlobalStyle';
import App from 'App';
import DashboardWrapper from 'components/DashboardWrapper';
import { AuthContainer } from 'containers';
import { LoginPage, LogoutPage, SignupPage, AccountSelectionPage } from 'pages';
import reducers from 'reducers';
import { unregister as unregisterServiceWorker } from 'registerServiceWorker';

import { setContextParam } from 'actions';
import {
  redactTokensFromEvent,
  redactElementNamesFromEvent,
  getParamFromUrl,
  localStorageFallback,
} from '@dashboard-experience/utils';

import { datadogRum } from '@datadog/browser-rum';

import FlagrProviderWrapper from 'components/FlagrProviderWrapper';
import './i18n';

import {
  SENTRY_ENV,
  ENV,
  USE_DD_RUM,
  DD_RUM_APPLICATION_ID,
  DD_RUM_CLIENT_TOKEN,
  DD_RUM_SITE,
  IS_IN_IFRAME,
  SANDBOX,
  STAGING,
  WINDOW_CONTEXT,
  WINDOW_CONTEXT_ID,
  CANDIDATE_REDESIGN,
  IS_ANGULAR_IFRAME,
  CANDIDATE_REDESIGN_LEARN_MORE_URL,
} from 'Constants';
import ApiStatusBannerPage from 'pages/ApiStatusBannerPage';

import { isMr, postMessageToDashboard } from 'utils';
import { OauthRoutes } from 'pages/OathRoutes';
import AnalyticsWrapper from 'components/AnalyticsWrapper';
import { BetaProvider } from 'providers/Beta/BetaProvider';
import { useIsCandidateRoute } from 'modules/candidate';
import { BetaState, useBetaState } from 'providers/Beta';
import BetaBanner from 'components/Beta/BetaBanner';
import { clearUserPreferences } from 'api/dashboardPreferences';
import AmplitudeProvider from 'containers/AmplitudeDataContainer';
import ModalContextProvider from './providers/Modal';
import { initializeSentry, sentryReduxEnhancer } from './sentry';

// initialize sentry
if (SENTRY_ENV !== 'development') {
  initializeSentry();
}

// initialize datadog RUM for production environments
// (Assumes token and app ID are only defined for the appropriate environments)
if (USE_DD_RUM) {
  const datadogRumInit = () => {
    const onVisibilityChange = callback => {
      let visible = true;

      if (!callback) {
        throw new Error('no callback given');
      }

      const focused = () => {
        if (!visible) {
          callback((visible = true));
        }
      };

      const unfocused = () => {
        if (visible) {
          callback((visible = false));
        }
      };

      // Standards:
      if ('hidden' in document) {
        visible = !document.hidden;
        document.addEventListener('visibilitychange', () => {
          (document.hidden ? unfocused : focused)();
        });
      }
      if ('mozHidden' in document) {
        visible = !document.mozHidden;
        document.addEventListener('mozvisibilitychange', () => {
          (document.mozHidden ? unfocused : focused)();
        });
      }
      if ('webkitHidden' in document) {
        visible = !document.webkitHidden;
        document.addEventListener('webkitvisibilitychange', () => {
          (document.webkitHidden ? unfocused : focused)();
        });
      }
      if ('msHidden' in document) {
        visible = !document.msHidden;
        document.addEventListener('msvisibilitychange', () => {
          (document.msHidden ? unfocused : focused)();
        });
      }
      // IE 9 and lower:
      if ('onfocusin' in document && 'onfocusout' in document) {
        document.onfocusin = focused;
        document.onfocusout = unfocused;
      }
      // All others:
      window.onpageshow = focused;
      window.onpagehide = unfocused;
    };
    datadogRum.init({
      applicationId: DD_RUM_APPLICATION_ID,
      clientToken: DD_RUM_CLIENT_TOKEN,
      site: DD_RUM_SITE,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      env: ENV,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [
        /https:\/\/.*\.checkr\.com\/.*/,
        /http:\/\/.*\.checkr.localhost\/.*/,
        /http:\/\/.*\.checkrhq-staging\.net\/.*/,
        /http:\/\/.*\.checkr-sandbox\.com\/.*/,
      ],
      service: 'customer',
      // trackViewsManually: true,
      beforeSend: event => {
        // Sanitize token from the url
        redactTokensFromEvent(event);

        // Hide element names to avoid sending any PII
        const allowList = ['visibilityChanged', 'customer_internal_redirect'];
        redactElementNamesFromEvent(event, allowList);
      },
    });
    datadogRum.addRumGlobalContext('view', {
      // old/MS browsers may not support document.hidden.  If it's not supported, then we don't know
      // for sure the tab was or wasn't hidden, so consider it to have been hidden.
      window_hidden: document.hidden === undefined || document.hidden,
      has_focus: document.hasFocus(),
      iframe: IS_IN_IFRAME,
      is_mr: isMr(),
      webdriver: navigator.webdriver,
    });
    onVisibilityChange(visible => {
      window.DD_RUM.addRumGlobalContext('visibility', {
        changed: true,
      });
      window.DD_RUM.addAction('visibilityChanged', { visible });
    });
  };
  datadogRum.onReady(datadogRumInit);
}

const clearUser = getParamFromUrl(window, 'clearUser');
if (clearUser === 'true') {
  clearUserPreferences();
  localStorageFallback.removeItem('currentUser');
  localStorageFallback.removeItem('accessToken');
}

const defaultMiddleware = [ReduxThunk];

let storeToUse;

if (process.env.NODE_ENV === 'development') {
  storeToUse = createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(
        ...defaultMiddleware.concat([immutableStateInvariantMiddleware()]),
      ),
    ),
  );
} else {
  storeToUse = createStore(
    reducers,
    compose(applyMiddleware(...defaultMiddleware), sentryReduxEnhancer),
  );
}

const store = storeToUse;

store.dispatch(setContextParam(WINDOW_CONTEXT, WINDOW_CONTEXT_ID));

// Helper to message parent dashboard to let it know when Customer loads
const OnLoadListener = () => {
  useEffect(() => {
    postMessageToDashboard({
      messageId: 'loaded',
      contextId: WINDOW_CONTEXT_ID,
    });
  }, []);
  return null;
};

// When in standalone, add this Wrapper element for things like the sidenav, banners, etc.
const Wrapper = WINDOW_CONTEXT !== 'iframe' ? DashboardWrapper : React.Fragment;

const CandidateBetaBanner = () => {
  const candidateRedesignState = useBetaState(CANDIDATE_REDESIGN);
  const withinCandidate = useIsCandidateRoute();

  // Show beta banner for internal users on candidate routes
  const showBetaCandidateBanner =
    IS_ANGULAR_IFRAME &&
    withinCandidate &&
    candidateRedesignState === BetaState.OPT_OUT;

  if (!showBetaCandidateBanner) return null;

  return (
    <BetaBanner
      flagKey={CANDIDATE_REDESIGN}
      translationKey='candidate_redesign'
      learnMoreLink={CANDIDATE_REDESIGN_LEARN_MORE_URL}
    />
  );
};

const MainApp = () => {
  return (
    <AuthContainer>
      <GlobalStyle context={WINDOW_CONTEXT} />
      <ModalContextProvider>
        <FlagrProviderWrapper>
          <AnalyticsWrapper>
            <AmplitudeProvider>
              <BetaProvider>
                <CandidateBetaBanner />
                <Wrapper>
                  <App />
                </Wrapper>
              </BetaProvider>
            </AmplitudeProvider>
          </AnalyticsWrapper>
        </FlagrProviderWrapper>
      </ModalContextProvider>
    </AuthContainer>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      {(SANDBOX || STAGING) && (
        <meta name='robots' content='noindex, nofollow' />
      )}
    </Helmet>
    {WINDOW_CONTEXT === 'iframe' && <OnLoadListener />}
    <Router>
      <Switch>
        <Route path='/signup' component={SignupPage} />
        <Route exact path='/select-account' component={AccountSelectionPage} />
        <Route exact path='/login' component={LoginPage} />
        <Route exact path='/logout' component={LogoutPage} />
        <Route exact path='/api_status' component={ApiStatusBannerPage} />
        <Route path='/oauth/authorize' component={OauthRoutes} />
        <Route component={MainApp} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('mastodon'),
);

unregisterServiceWorker();

// eslint-disable-next-line import/no-anonymous-default-export
export default { store };
