import * as Entity from 'modules/assess/models/settings/account';
import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { AUTO_ADVERSE_ACTION_FLAG_KEY } from 'Constants';
import Title from './title';
import CandidateStoryRequestSetting from './candidate-story-request/candidate-story-request-setting';
import AdverseActionPauseSetting from './adverse-action-pause/adverse-action-pause-setting';
import AdverseActionAutomationSetting from './adverse-action-automation/adverse-action-automation-setting';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const Container: React.FC<Props> = ({ settings, setSettings }) => {
  const autoAdverseActionEnabled =
    useFlag(AUTO_ADVERSE_ACTION_FLAG_KEY)?.variantKey === 'enabled';

  if (!autoAdverseActionEnabled) {
    return null;
  }

  return (
    <div>
      <Title />
      <AdverseActionAutomationSetting
        settings={settings}
        setSettings={setSettings}
      />
      <CandidateStoryRequestSetting
        settings={settings}
        setSettings={setSettings}
      />
      <AdverseActionPauseSetting
        settings={settings}
        setSettings={setSettings}
      />
    </div>
  );
};

export default Container;
