/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { AnyQueryKey, useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastInfo, toastSuccess, toastError } from 'actions';
import { useDispatch as useHealthDispatch } from 'components/Report/Screenings/DrugHealthScreenings/OccHealthScreening/health-context';

import { useMemo } from 'react';
import { GenericObject } from 'types';
import {
  CurrentUser as User,
  hasPermission,
} from '@dashboard-experience/utils';
import {
  list,
  postV1Documents,
  logPDFDownload,
  BaseParams,
  PostDocuments,
  LogPDFDownload,
  getConsentLink,
} from './actions';

/**
 * Retrieve legal documents generated by Checkr for the Candidate (e.g. our Consent form for them)
 * (in contrast to useFetchCandidateDocuments, which gets the documents made by the Candidate)
 */
export const useList = (params: BaseParams) => {
  const key: AnyQueryKey = ['documents/list', params];

  const request = () => list(params);

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const usePostV1Documents = () => {
  const dispatch = useDispatch();
  const healthDispatch = useHealthDispatch();

  const request = (params: PostDocuments) => postV1Documents(params);

  const [call, result] = useMutation(request, {
    onMutate: () => {
      dispatch(toastInfo('Uploading document'));
    },
    onSuccess: (document, variables) => {
      const {
        params: { verifiable_id },
      } = variables;
      document.alternate_documentable_id = verifiable_id;

      dispatch(toastSuccess('Document uploaded!'));
      healthDispatch({ type: 'post document success', payload: document });
    },
    onError: err => {
      dispatch(toastError(`Error Uploading Document: ${err}`));
    },
  });

  return {
    call,
    result,
  };
};

export const useLogPDFDownload = (params: LogPDFDownload) => {
  const request = () => logPDFDownload(params);

  const [call, result] = useMutation(request);

  return { call, result };
};

export const useGetConsentLink = (
  reportId: string,
  isDocQa: boolean,
  currentUser: User,
) => {
  const request = () => getConsentLink(reportId);
  const key: AnyQueryKey = ['consentLink', { reportId }];
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !isDocQa && hasPermission(currentUser, 'read_reports') && reportId,
  });

  const consent = useMemo(
    () => result?.data || {},
    [result?.data],
  ) as GenericObject;
  const { consent_link, international_consent_link } = consent;
  return {
    consentLink: consent_link,
    internationalConsentLink: international_consent_link,
  };
};
