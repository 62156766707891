import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { Footer, StepContainer } from '../ui';
import { getGeoWorkLocation, getWorkLocation } from './ReviewAndSubmitUtils';
import { namespace } from '../locales';
import { InfoSection } from './InfoSection';
import { CandidateInfoSection } from './CandidateInfoSection';
import { StyledInfoText } from './CandidateInfoSection/CandidateInfoSection.styles';

const ReviewAndSubmitStep: React.FC<{}> = () => {
  // TODO: Update disabled
  const { t } = useTranslation(namespace);
  const disabled = false;
  const history = useHistory();
  const {
    account: { segmentation_enabled },
  } = useUser();

  const { geo, location, manualOrderType, infoSource } =
    useBetterOrderExperienceContext();

  const workLocationValue = !segmentation_enabled
    ? getGeoWorkLocation(geo)
    : getWorkLocation(location);

  const hasCandidateInfo =
    manualOrderType === 'MULTIPLE' || infoSource === 'CANDIDATE';

  const { contextId } = useContext(UIContext);

  const goToGetStarted = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleBack = useCallback(() => {
    const addonsAvailable = true;
    const path = addonsAvailable
      ? '/order-experience/add-ons'
      : '/order/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const submitOrder = useCallback(() => {
    console.log('TODO: Submit order');
  }, []);

  return (
    <StepContainer data-testid='review-and-submit-step-container'>
      {hasCandidateInfo && (
        <>
          <StyledInfoText>
            {t(`reviewAndSubmit.infoSection.candidateInfoText`)}
          </StyledInfoText>
          <InfoSection section='candidateInfo' onEditClick={goToGetStarted}>
            <CandidateInfoSection />
          </InfoSection>
        </>
      )}
      {workLocationValue && (
        <InfoSection section='workLocation' onEditClick={goToGetStarted}>
          {workLocationValue}
        </InfoSection>
      )}
      <Footer>
        <M.Button
          data-testid='review-step-back-btn'
          type='button'
          onClick={handleBack}
        >
          {t('reviewAndSubmit.footerButtons.goBack')}
        </M.Button>
        <M.Button type='button' disabled={disabled} onClick={submitOrder}>
          {t('reviewAndSubmit.footerButtons.submitOrder')}
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default ReviewAndSubmitStep;
