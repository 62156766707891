import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 40px;
  border: 1px solid rgb(190, 198, 207);
  border-radius: 2px;
`;
