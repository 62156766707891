import React from 'react';
import styled from 'styled-components';

import { useOrderBackgroundCheckContext } from '../../Context';

import EditableSection from '../EditableSection';
import * as SectionContent from '../SectionContent';

const InfoText = styled.p`
  color: var(--text-icon-black-primary, #1a2026);
  font-size: 16px !important;
  padding: 16px 0;
`;

export type SmsInvitationManualEntryProps = {
  handleGetStartedEditClick: () => void;
};

const SmsInvitationManualEntry: React.FC<SmsInvitationManualEntryProps> = ({
  handleGetStartedEditClick,
}) => {
  const { state } = useOrderBackgroundCheckContext();
  const { invites } = state;

  return (
    <>
      <InfoText>
        If a candidate has opted out of texts, they&apos;ll get the link only by
        email.
      </InfoText>
      <EditableSection
        id='candidates'
        sectionTitle='Candidates'
        onEditClick={handleGetStartedEditClick}
        description={{
          title: 'An invitation link will be sent to:',
          content: <SectionContent.SmsInviteSectionContent invites={invites} />,
          isCandidatesSection: true,
          expand: true,
        }}
        inlineEdit
        hideLastColumn
      />
    </>
  );
};

export default SmsInvitationManualEntry;
