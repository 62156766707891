import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { callUserActions } from 'components/Report/userActions';
import { Report, ArrestRecord, InternationalCriminalRecord } from 'types';
import { useUser } from 'context/CurrentUser';
import { BaseRecord } from 'types/report/screenings/record';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { useFlag } from '@dashboard-experience/react-flagr';
import { handleAiClick } from '../../../../api/aiExplainr';

type RecordType = BaseRecord | InternationalCriminalRecord | ArrestRecord;

const CriminalRecord: React.FC<{
  report: Report;
  record: RecordType;
  statusType: any;
  [key: string]: any;
}> = ({ report, record, statusType, ...props }) => {
  const currentUser = useUser();
  const { id } = report;

  const hasStateAndCounty = (
    rec: RecordType,
  ): rec is BaseRecord | ArrestRecord => {
    return 'state' in rec && 'county' in rec;
  };

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  const handleClickWithExplainer = hasStateAndCounty(record)
    ? handleAiClick(record.state || '', record.county || '')
    : handleAiClick('', '');

  const onChargeClick = useCallback(
    isExpanded => {
      const eventProperties = {
        event: 'clicked_into_charges',
        extra_info: record.charges,
      };
      // only call if expanding charges
      return isExpanded
        ? callUserActions(id, 'report', eventProperties)
        : undefined;
    },
    [id, record.charges],
  );

  return (
    <M.Record
      record={record}
      currentUser={currentUser}
      report={report}
      reportStatusType={statusType}
      onChargeClick={onChargeClick}
      chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
      chargesExplainer={
        aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
      }
      {...props}
    />
  );
};

export default CriminalRecord;
