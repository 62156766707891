import {
  GenericObject,
  getAccessToken,
  localStorageFallback,
  sessionStorageFallback,
} from '@dashboard-experience/utils';
import { WebAuth } from 'auth0-js';
import {
  DEVELOPMENT,
  FORCE_TOKEN_REFRESH_WINDOW_IN_SECONDS,
  IDENTITY_PROVIDER_AUDIENCE,
  IDENTITY_PROVIDER_CLIENT_ID,
  IDENTITY_PROVIDER_DOMAIN,
  IS_ANGULAR_IFRAME,
} from 'Constants';
import { clearUserPreferences } from 'api/dashboardPreferences';
import {
  getImpersonation,
  shouldUseImpersonationData,
  updateImpersonatedUser,
} from './ImpersonationUtils';

export type MultiAccount = {
  account_id: string;
  name: string;
};

const saveExpiryTime = (expiresIn: number | string) => {
  const tokenExpiryTime = new Date(
    Date.now() + parseInt(expiresIn.toString(), 10) * 1000,
  ).getTime();
  localStorageFallback.setItem('tokenExpiryTime', tokenExpiryTime.toString());
};

const getPath = () =>
  `${window.location.pathname}${window.location.search}${window.location.hash}`;

// Support both http(s) for local development, force https for deployed versions
const protocol = DEVELOPMENT ? window.location.protocol : 'https:';
const redirectUri = `${protocol}//${window.location.hostname}/login`;

const auth0 = new WebAuth({
  audience: IDENTITY_PROVIDER_AUDIENCE || '',
  clientID: IDENTITY_PROVIDER_CLIENT_ID || '',
  domain: IDENTITY_PROVIDER_DOMAIN || '',
  redirectUri,
  responseType: 'token',
  scope: 'openid',
  state: 'ok',
});

export const logout = () => {
  // Dashboard handles its own login/logout
  if (IS_ANGULAR_IFRAME) return false;
  setTimeout(() => {
    auth0.logout({ returnTo: redirectUri });
  }, 0);
  return true;
};

export const saveRedirectPath = () =>
  sessionStorageFallback.setItem('redirectPath', JSON.stringify(getPath()));

export const authorize = () => {
  setTimeout(() => {
    const params: GenericObject = {};
    const customErrorMessages = [
      'checkr_user_does_not_exist',
      'password_expired_needs_reset',
      'social_login_disabled_for_enterprise',
      'first_login_social_disabled',
    ];
    const error_description =
      sessionStorageFallback.getItem('error_description');
    if (error_description) {
      params.errorDescription = error_description;
      // we need to pass this param to use in Auth0's New Universal Login
      params['ext-error'] = customErrorMessages.includes(error_description)
        ? error_description
        : 'auth0_error';
    }
    sessionStorageFallback.removeItem('error_description');
    auth0.authorize(params);
  }, 0);
};

/**
 * This will return the number of MILLISECONDS remaining in the current authenticated session
 */
export const getRemainingSessionDuration = () => {
  // Retrieve the remaining time from storage, or default t0 if it's not found.
  const tokenExpiryTime = parseInt(
    localStorageFallback.getItem('tokenExpiryTime') || '0',
    10,
  );

  return tokenExpiryTime - Date.now();
};
const checkSessionAsync = (queryParams: {
  prompt: string;
  account_id?: string;
}) => {
  return new Promise(resolve => {
    auth0.checkSession(queryParams, (err, authResult) => {
      if (err) {
        // if there was an error with silent authentication, save the current path and authorize the user via the real flow
        saveRedirectPath();
        authorize();
      } else {
        // otherwise, save the token and expiry time
        const { expiresIn, accessToken } = authResult;
        localStorageFallback.setItem('accessToken', accessToken);
        if (expiresIn) {
          saveExpiryTime(expiresIn);
        }
      }
      resolve(true);
    });
  });
};

/**
 * Allow the user to login to a different account without a login screen, assuming they are
 * multi_account_enabled users
 *
 * @param account_id Account id selected by the user to log in to
 */
export const multiUserAccountSwitch = async (account_id: string) => {
  const queryParams: { prompt: string; account_id: string } = {
    prompt: 'none',
    account_id,
  };
  await checkSessionAsync(queryParams);
  clearUserPreferences();
  localStorageFallback.removeItem('currentUser');
};

/**
 * Attempts to refresh the user's current authenticated session
 *  It will ONLY attempt to re-auth if (1) not in iframe, (2) not impersonating, and (3) there are <5 minutes remaining in the session
 * @returns whether the call to reauthenticate was made (regardless of whether it was successful or not)
 */
export const silentReauthenticate = async () => {
  // Dashboard handles authorization when iframed
  if (IS_ANGULAR_IFRAME) {
    return false;
  }

  // Never re-authenticate an impersonation
  if (getImpersonation().enabled) {
    return false;
  }

  const secondsRemaining = getRemainingSessionDuration() / 1000;
  if (secondsRemaining < FORCE_TOKEN_REFRESH_WINDOW_IN_SECONDS) {
    const queryParams: { prompt: string; account_id?: string } = {
      prompt: 'none',
    };

    // Passing account_id is required for MAM Users to stay logged into the same account
    const currentAuthorizations = accessTokenAuthorizations();
    if (currentAuthorizations)
      queryParams.account_id = currentAuthorizations.account_id;

    clearUserPreferences();

    await checkSessionAsync(queryParams);
    return true;
  }
  return false;
};

const handleAuthResponse = (
  // allow passing in react-router callback
  replaceLocation: Function = window.location.replace,
) => {
  auth0.parseHash({ __enableIdPInitiatedLogin: true }, (err, authResult) => {
    if (err) {
      if (err.errorDescription)
        sessionStorageFallback.setItem(
          'error_description',
          err.errorDescription,
        );
      // logout the user to force a reauthentication
      logout();
    } else {
      if (authResult?.accessToken)
        localStorageFallback.setItem('accessToken', authResult.accessToken);
      if (authResult?.expiresIn) {
        saveExpiryTime(authResult.expiresIn);
      }
      const redirectUri = sessionStorageFallback.getItem('redirectPath') || '/';
      sessionStorageFallback.removeItem('redirectPath');
      let redirect;
      try {
        redirect = JSON.parse(redirectUri);
      } catch (e) {
        // fallback if JSON is malformed
        redirect = '/';
      }

      // If somehow a Path beginning with double-slash was stored, strip out the extraneous one here
      if (redirect.startsWith('//')) {
        redirect = redirect.substring(1);
      }

      replaceLocation(redirect);
    }
  });
};

export const login = (replaceLocation: Function = window.location.replace) => {
  if (IS_ANGULAR_IFRAME) return false;
  if (window.location.hash.length > 1) {
    // if there is a hash, try and parse it for token information
    handleAuthResponse(replaceLocation);
  } else {
    // otherwise, kick off authorization flow
    authorize();
  }
  return true;
};

/**
 * An Impersonation-aware function, for retrieving the appropriate User object (between the real user and whomever they are impersonating)
 * @returns the appropriate User object
 */
export const getUserObject = () => {
  // Sometimes when Impersonating, we grab the user object from the Impersonation data:
  if (shouldUseImpersonationData()) {
    return getImpersonation().impersonatedUser;
  }

  // Otherwise, try to just grab the currentUser object instead:
  const storedUser = localStorageFallback.getItem('currentUser');
  if (storedUser) {
    return JSON.parse(storedUser);
  }

  return undefined;
};
/**
 * An Impersonation-aware function, for retrieving the appropriate Access Token (between the real user and whomever they are impersonating)
 * @returns the appropriate Access Token
 */
export const getAppropriateToken = () => {
  if (shouldUseImpersonationData()) {
    return getImpersonation().impersonatedToken;
  }

  return getAccessToken();
};

export const storeUserObject = (userData: any) => {
  // If impersonating, then this object is a part of the whole impersonation object
  if (shouldUseImpersonationData()) {
    updateImpersonatedUser(userData);
  }
  // Otherwise, for normal user sessions, just store as 'currentUser'
  else {
    localStorageFallback.setItem('currentUser', JSON.stringify(userData));
  }
};

export const decodeJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export const accessTokenAuthorizations = () => {
  const accessToken = getAppropriateToken();
  if (accessToken) {
    return decodeJwt(accessToken)['https://checkr.com/authorizations'];
  }

  return undefined;
};

export const invalidSessionTokenLogout = () => {
  // This is a generic error message, we should update this when we get more specific error messages from Auth0
  sessionStorageFallback.setItem('error_description', 'auth0_error');
  logout();
};

// Used to determine if Auth should be attempted
export const isSignupFlow = () => window.location.pathname.includes('signup');
