import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import AddPaymentModalBody from './AddPaymentModalBody';

const AddPaymentModal = ({
  modalOpen,
  hideModal,
  elementOptions,
  setElementOptions,
  error,
  setError,
  postPaymentMethod,
  paymentType,
}: any) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();

  const paymentTypeKey =
    paymentType === 'card' ? 'cc_info' : 'bank_information';
  const modalTitle = t(`payment.${paymentTypeKey}.modal_header_add`);

  return (
    <M.ComposedModal
      open={modalOpen}
      onClose={hideModal}
      data-testid='add-payment-modal'
    >
      <M.LoadingSpinner active={isProcessing} />
      <M.ModalHeader closeModal={hideModal}>
        <h2>{modalTitle}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <AddPaymentModalBody
          error={error}
          setError={setError}
          processing={isProcessing}
          setIsProcessing={setIsProcessing}
          elementOptions={elementOptions}
          setElementOptions={setElementOptions}
          paymentType={paymentType}
          hideModal={hideModal}
          postPaymentMethod={postPaymentMethod}
        />
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default AddPaymentModal;
