import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { upperFirst } from 'lodash';

import { AdversableItem } from 'modules/adjudication/data';
import {
  Alias,
  CurrentUser,
  GenericObject,
  getDecisionAliases,
  underscoresToSpaces,
} from '@dashboard-experience/utils';

import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import { useFetchExpungements } from 'modules/adjudication/api';
import { useDispatch } from 'modules/adjudication/ui/adverse-action/initiate/flow/context';
import { namespace } from 'modules/adjudication/locales';
import * as FieldComponents from '../fields';
import { FieldConfig, FieldKVListConfig } from '../types';

const SelectableTile = styled(M.SelectableTile)`
  .cds--styles--selectable {
    padding-right: 0;
  }

  // Possibly update Mastodon Expungement with this
  .cds--inline-notification--info {
    background: ${colors.uiGrey100};
  }
`;

const fields = [
  'adversibleType',
  'customerCategory',
  'chargeType',
  'disposition',
  'judgement',
  'suspensionStartDate',
  'dateType',
  'suspensionEndDate',
  'dispositionDate',
  'ageAtOffense',
  'timeSinceOffense',
  'customerRuleActivated',
];

const getDisplayedAdversibleType = (adversibleType: string, t: TFunction) => {
  const ADVERSIBLE_TYPES = [
    'ArrestCharge',
    'Violation',
    'Accident',
    'AgeCriterion',
    'LicenseInStatesCriterion',
    'LocalLicenseCriterion',
    'RestrictionCriterion',
    'MotorVehicleReport',
    'StatefulSsnTrace',
  ];
  const getType = () => {
    return ADVERSIBLE_TYPES.includes(adversibleType)
      ? adversibleType
      : 'Charge';
  };

  return t(
    `${namespace}:report.actions.pre_adverse.displayed_type.${getType()}`,
  );
};

const buildAdverseItemFields = ({
  adverseItem,
  isAssessEnabled,
  isAssessedWithMvrAssessRules,
  t,
  currentUser,
}: {
  currentUser: CurrentUser;
  decisionAliases: Alias[];
  adverseItem: AdversableItem;
  isAssessEnabled?: boolean;
  isAssessedWithMvrAssessRules?: boolean;
  t: TFunction;
}) => {
  const fieldsArr: FieldConfig[] = [];
  const {
    category,
    charge_type,
    date_type,
    disposition,
    judgement,
    adversible,
    adversible_type,
  } = adverseItem;

  const adversibleType = getDisplayedAdversibleType(
    adverseItem.adversible_type,
    t,
  );
  const suspended = adversible_type === 'Suspension';
  const rules = adverseItem?.assessment?.display?.rules;
  const dispositionDate = adversible?.disposition_date;
  const displayContextDate =
    currentUser?.account?.adverse_action_item_date_alignment;

  const getFieldItem = (field: string) => {
    switch (field) {
      case 'adversibleType':
        fieldsArr.push({
          fieldName: () => adversibleType,
          fieldKey: 'adversibleType',
        });
        break;
      case 'customerCategory':
        if (category && !isAssessedWithMvrAssessRules)
          fieldsArr.push({
            fieldName: () => 'Customer Category',
            fieldKey: 'customerCategory',
          });
        break;
      case 'chargeType':
        if (charge_type)
          fieldsArr.push({
            fieldName: () => 'Charge type',
            fieldKey: 'chargeType',
          });
        break;
      case 'disposition':
        if (disposition)
          fieldsArr.push({
            fieldName: () => 'Disposition',
            fieldKey: 'disposition',
          });
        break;
      case 'judgement':
        if (judgement)
          fieldsArr.push({
            fieldName: () => 'Judgement',
            fieldKey: 'judgement',
          });
        break;
      case 'suspensionStartDate':
        if (suspended && date_type === 'suspension_end_date')
          fieldsArr.push({
            fieldName: () => 'Suspension start date',
            fieldKey: 'suspensionDate',
          });
        break;
      case 'dateType':
        if (date_type)
          fieldsArr.push({
            fieldName: () => underscoresToSpaces(upperFirst(date_type)),
            fieldKey: 'dateType',
          });
        break;
      case 'suspensionEndDate':
        if (suspended && date_type === 'suspension_start_date')
          fieldsArr.push({
            fieldName: () => 'Suspension end date',
            fieldKey: 'suspensionDate',
          });
        break;
      case 'dispositionDate':
        if (
          dispositionDate &&
          displayContextDate &&
          date_type !== 'disposition_date'
        )
          fieldsArr.push({
            fieldName: () => 'Disposition Date',
            fieldKey: 'dispositionDate',
          });
        break;
      case 'ageAtOffense':
        if (date_type)
          fieldsArr.push({
            fieldName: () => 'Age at offense',
            fieldKey: 'ageAtOffense',
          });
        break;
      case 'timeSinceOffense':
        if (date_type)
          fieldsArr.push({
            fieldName: () => 'Time since offense',
            fieldKey: 'timeSinceOffense',
          });
        break;
      case 'customerRuleActivated':
        if (isAssessEnabled && rules?.length > 0)
          fieldsArr.push({
            fieldName: () => 'Customer rule activated',
            fieldKey: 'customerRuleActivated',
          });
        break;
      default:
        break;
    }
  };

  fields.map((field) => {
    return getFieldItem(field);
  });

  return fieldsArr;
};

// map of field keys to components
const componentMap: {
  [fieldKey: string]: React.FC<any>;
} = {
  adversibleType: FieldComponents.AdversibleType,
  customerCategory: FieldComponents.Category,
  chargeType: FieldComponents.ChargeType,
  disposition: FieldComponents.Disposition,
  dispositionDate: FieldComponents.DispositionDate,
  judgement: FieldComponents.Judgement,
  dateType: FieldComponents.DateType,
  ageAtOffense: FieldComponents.AgeAtOffense,
  timeSinceOffense: FieldComponents.TimeSinceOffense,
  customerRuleActivated: FieldComponents.CustomerRuleActivated,
  suspensionDate: FieldComponents.SuspensionDate,
};

/**
 * @name buildColumnConfig
 * @function
 * @memberOf adverse-item
 * @description Build the config to be used with M.KeyValueList
 * @returns {array} - The config
 * @param {object} currentUser - The currentUser object
 * @param {object} adverseItem - the adverseItem object
 * @param {object} report - The report object
 */
const buildColumnConfig = (
  currentUser: CurrentUser,
  adverseItem: AdversableItem,
  decisionAliases: Alias[],
  t: TFunction,
  isAssessEnabled?: boolean,
  isAssessedWithMvrAssessRules?: boolean,
) => {
  const adverseItemFields = buildAdverseItemFields({
    currentUser,
    decisionAliases,
    adverseItem,
    isAssessEnabled,
    isAssessedWithMvrAssessRules,
    t,
  });

  const fields: FieldConfig[] = adverseItemFields;

  const config: FieldKVListConfig[] = fields
    .map((field: FieldConfig) => {
      const { fieldName, fieldKey }: FieldConfig = field;
      let item: FieldKVListConfig = {
        itemKey: null,
        itemValue: null,
      };

      // @ts-ignore TODO: Remove comment once CurrentUser type is updated in Utils
      const renderedFieldName = fieldName(adverseItem, currentUser);
      item = getColConfigItem(
        renderedFieldName,
        fieldKey,
        currentUser,
        adverseItem,
        isAssessEnabled,
        isAssessedWithMvrAssessRules,
      );
      return item;
    })
    .filter((item) => item.itemKey);
  return config;
};

/**
 * @name getColConfigItem
 * @function
 * @memberOf adverse-item
 * @description Get a single config object to be used with M.KeyValueList
 * @returns {object} - The single config object
 */
const getColConfigItem = (
  fieldName: string,
  fieldKey: string,
  currentUser: CurrentUser,
  adverseItem: AdversableItem,
  isAssessEnabled?: boolean,
  isAssessedWithMvrAssessRules?: boolean,
) => {
  // define KVList config item object
  const configItem: FieldKVListConfig = {
    itemKey: null,
    itemValue: null,
  };

  // set KVList key
  configItem.itemKey = fieldName;
  // set KVList value
  const FieldComponent = componentMap[fieldKey];
  configItem.itemValue = (
    <FieldComponent
      adverseItem={adverseItem}
      fieldKey={fieldKey}
      displayName={fieldName}
      currentUser={currentUser}
      testid={`adverse-item-field-${fieldKey}`}
      isAssessEnabled={isAssessEnabled}
      isAssessedWithMvrAssessRules={isAssessedWithMvrAssessRules}
    />
  );

  return configItem;
};

const Content: React.FC<{
  adverseItem: AdversableItem;
  isAssessEnabled?: boolean;
  isAssessedWithMvrAssessRules?: boolean;
}> = ({ adverseItem, isAssessEnabled, isAssessedWithMvrAssessRules }) => {
  const [adversibleItemList, setAdversibleItemList] = useState<GenericObject[]>(
    [],
  );
  const { t } = useTranslation();

  const candidate = useCandidate();
  const currentUser = useUser();
  const decisionAliases = useMemo(
    // @ts-ignore - ignoring, might need update in utils to make report optional
    () => getDecisionAliases({ currentUser }),
    [currentUser],
  );

  useEffect(() => {
    if (candidate && currentUser) {
      setAdversibleItemList(
        buildColumnConfig(
          currentUser,
          adverseItem,
          decisionAliases,
          t,
          isAssessEnabled,
          isAssessedWithMvrAssessRules,
        ),
      );
    }
  }, [
    candidate,
    currentUser,
    decisionAliases,
    adverseItem,
    isAssessEnabled,
    isAssessedWithMvrAssessRules,
    t,
  ]);

  const adversibleItems = adversibleItemList;

  const expungementsStatus = useFetchExpungements(adverseItem);

  return (
    <>
      <M.KeyValueList
        style={{ width: '100%' }}
        items={adversibleItems}
        keyStyle={{ width: '27%' }}
        valueStyle={{ width: '100%' }}
      />
      {expungementsStatus && (
        <M.Expungements
          className='panel info-panel'
          status={expungementsStatus}
        />
      )}
    </>
  );
};

type AdverseItemProps = {
  item: AdversableItem;
  isAssessEnabled?: boolean;
  isAssessedWithMvrAssessRules?: boolean;
  selected: boolean;
};
const AdverseItem: React.FC<AdverseItemProps> = ({
  item,
  isAssessEnabled,
  isAssessedWithMvrAssessRules,
  selected,
}) => {
  const dispatch = useDispatch();
  const onChange = useCallback(() => {
    dispatch({ type: 'SET_ADVERSE_ITEM', payload: item });
  }, [item, dispatch]);

  return (
    <SelectableTile
      data-testid='adversible-charge'
      onChange={onChange}
      selected={selected}
      isTall
      light
    >
      <Content
        adverseItem={item}
        isAssessEnabled={isAssessEnabled}
        isAssessedWithMvrAssessRules={isAssessedWithMvrAssessRules}
      />
    </SelectableTile>
  );
};

export default AdverseItem;
