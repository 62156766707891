import React, { useCallback, useEffect } from 'react';
import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { Actions } from 'modules/assess/ui/state/context-date';
import { ID } from 'modules/entity';
import { useUpdate } from './api/hooks';
import { useDispatch, State } from './store';

type Props = {
  disabled?: boolean;
  id: ID;
};

export const SaveButton: React.FC<Props> = ({ disabled, id }) => {
  const ruleset = useRuleset();
  const dispatch = useDispatch();
  const state = State.useValue();
  const action = useUpdate(id, ruleset?.data?.id);
  const loading = action.result.status === 'loading';
  const entity = State.useEntity(state);

  useEffect(() => {
    if (action.result.status === 'success') {
      dispatch(Actions.Update.create({ dirty: false }));
      action.result.reset();
    }
  }, [action, dispatch]);

  const handleClick = useCallback(() => {
    action.call(entity as any);
  }, [action, entity]);

  return (
    <>
      <PageLoading enabled={loading} />
      <Button
        data-testid='assess-ruleset-version-context-dates-actions-save-button'
        disabled={disabled}
        kind='primary'
        onClick={handleClick}
        size='sm'
        trans='verbs.save'
      />
    </>
  );
};

export default SaveButton;
