import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxDivider,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalEmploymentVerificationProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};

const InternationalEmploymentVerification = ({
  shouldOpen,
  actionProps,
}: InternationalEmploymentVerificationProps) => {
  return (
    <FlexContainer data-testid='international-employment-verification-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Employment verification'
            priceByType='international_employment_verification'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Confirm the candidate&apos;s work history outside the United States.
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text="Verify the candidate's last 3 employers." />
              <BulletPoint text='Reports can include employer name, employment dates, position or title, comments, and salary when available.' />
              <BulletPoint text='Help maintain compliance, mitigate risk, and make informed hiring decisions.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Turnaround can be slower than US verification.' />
              <BulletPoint text="Verify the candidate's employment, not their identity." />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_employment_verification'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalEmploymentVerification;
