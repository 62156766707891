import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  #mastodon && {
    /* Need to supersede the inline styles set at the Mastodon level */
    .mastodon-expandable-notification {
      width: 100% !important;
    }

    /* Override the dropshadow, as it implies an interactability that this banner does not actually have */
    .mastodon-expandable-panel:hover {
      box-shadow: unset;
    }

    .expandable-panel-title {
      font-weight: unset;
      line-height: 20px;
    }

    .expandable-panel-content-body {
      display: none;
    }

    .expandable-panel-heading-right {
      display: none;
    }
  }
`;

/**
 * For cases where we want a banner with all of the general styles and new designs of the ExpandableNotification, but where none of the interactivity is applicable.
 * This strips out any of the elements for interactivity, and otherwise just passes all valid props through.
 */
const NonActionableBanner = ({ ...props }: any) => {
  return (
    <Container>
      <M.ExpandableNotification {...props} isClosable />
    </Container>
  );
};

export default NonActionableBanner;
