import React from 'react';
import styled from 'styled-components';
import { Report } from 'types';
import ReportActionsV2 from './report-actions/v2';
import Viewers from './viewers';
import Settings from './settings';

type Props = {
  reportsAndInvitations: Report[];
};

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ViewersContainer = styled.div`
  padding-top: 4px;
`;

const RightSection: React.FC<Props> = ({ reportsAndInvitations }) => {
  const className = 'candidate-header-right-v2';

  return (
    <Container className={className}>
      <ViewersContainer>
        <Viewers reportsAndInvitations={reportsAndInvitations} />
      </ViewersContainer>
      <Settings />
      <ReportActionsV2 />
    </Container>
  );
};

export default RightSection;
