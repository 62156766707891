import React, { RefObject, useCallback } from 'react';
import { stateGeos } from 'components/Signup/constants/geos';
import { IMaskMixin } from 'react-imask';
import styled from 'styled-components';

import { FormRow, Input, TypeaheadStyled } from '../styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MaskedInput = IMaskMixin(
  ({
    inputRef,
    ...props
  }: {
    inputRef: RefObject<HTMLInputElement>;
    [key: string]: any;
  }) => <Input {...props} ref={inputRef} />,
);

type StateDropdownOption = {
  name: string;
  state: string;
};

const getStateName = (stateInfo: string | StateDropdownOption) => {
  if (typeof stateInfo === 'string') {
    const stateGeo = stateGeos.find(geos => geos.state === stateInfo);
    return stateGeo?.name || '';
  }
  return stateInfo?.name || '';
};

const stateFilter = (state: any) => {
  return (
    state?.item?.name
      ?.toLowerCase()
      .includes(state?.inputValue?.toLowerCase()) ||
    state?.item?.state?.toLowerCase().includes(state?.inputValue?.toLowerCase())
  );
};

type Props = {
  values: any;
  disableFields: boolean;
  handleChange: (e: any) => void;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: (e: any) => void;
  showError: (field: string) => boolean;
  errorMessage: (field: string) => string | null;
};

const AddressManual: React.FC<Props> = ({
  values,
  disableFields,
  handleChange,
  setFieldValue,
  handleBlur,
  showError,
  errorMessage,
}) => {
  const handleStateChange = useCallback(
    e => {
      setFieldValue('state', e?.state || '');
    },
    [setFieldValue],
  );

  return (
    <Container>
      <FormRow>
        <Input
          name='street'
          id='street'
          value={values.street}
          onChange={handleChange}
          placeholder='Street address'
          data-testid='street-input'
          disabled={disableFields}
          autoComplete='street-address'
          onBlur={handleBlur}
          invalid={showError('street')}
          invalidText={errorMessage('street')}
        />
        <Input
          name='street2'
          id='street2'
          value={values.street2}
          onChange={handleChange}
          placeholder='Apt, suite, etc.'
          data-testid='street2-input'
          disabled={disableFields}
          autoComplete='address-line2'
          handleBlur={handleBlur}
          invalid={showError('street2')}
          invalidText={errorMessage('street2')}
        />
      </FormRow>
      <FormRow>
        <Input
          width='33%'
          size='sm'
          name='city'
          id='city'
          value={values.city}
          onChange={handleChange}
          placeholder='City'
          data-testid='city-input'
          disabled={disableFields}
          autoComplete='address-level2'
          onBlur={handleBlur}
          invalid={showError('city')}
          invalidText={errorMessage('city')}
        />
        <TypeaheadStyled
          items={stateGeos}
          id='state'
          name='state'
          onChange={handleStateChange}
          itemToString={getStateName}
          selectedItem={values.state}
          data-testid='state-input'
          disabled={disableFields}
          placeholder='State'
          shouldFilterItem={stateFilter}
          onBlur={handleBlur}
          invalid={showError('state')}
          invalidText={errorMessage('state')}
        />

        <MaskedInput
          mask='00000'
          width='33%'
          size='sm'
          name='zipcode'
          id='zipcode'
          value={values.zipcode}
          onChange={handleChange}
          placeholder='ZIP code'
          data-testid='zipcode-input'
          disabled={disableFields}
          autoComplete='postal-code'
          onBlur={handleBlur}
          invalid={showError('zipcode')}
          invalidText={errorMessage('zipcode')}
        />
      </FormRow>
    </Container>
  );
};

export default AddressManual;
