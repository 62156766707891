import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  InternationalEducationRecord as Record,
  Document as CandidateDocument,
} from 'types';
import SearchPDF from './SearchPDF';
import formatCountry, {
  getInternationalRecordStatusOrAssessment,
} from './utils';
import HistoryTimeline from '../HistoryTimeline';
import RecordDocuments from '../EducationVerification/RecordDocuments';

const removeDuplicateStartEvents = (record: Record) => {
  const start_event_count = record.events.filter(
    x => x.type === 'verification_start',
  ).length;

  if (start_event_count === 2) {
    return record.events.splice(0, 1);
  }

  return record.events;
};

const InternationalEducationRecord: React.FC<{
  record: Record;
  statusType: any;
  completed: boolean;
  documents?: CandidateDocument[];
  screeningAssessment?: string | null;
}> = ({ record, statusType, completed, documents, screeningAssessment }) => {
  const title = formatCountry(record.country);

  // result is an object on these records, as opposed to report lifecycle result, so use the status directly
  const { status } = record;
  const displayStatus = getInternationalRecordStatusOrAssessment(
    status,
    screeningAssessment,
  );

  return (
    <M.SubScreening
      title={title}
      status={displayStatus}
      statusType={statusType}
      screening={record}
    >
      {record.pdf_url && (
        <SearchPDF
          url={record.pdf_url}
          text={`View ${title} international education report`}
        />
      )}
      {record.events?.length > 0 && (
        <HistoryTimeline events={removeDuplicateStartEvents(record)} />
      )}
      {completed && <RecordDocuments documents={documents} record={record} />}
    </M.SubScreening>
  );
};

export default InternationalEducationRecord;
