import * as Entity from 'modules/assess/models/rulesets/context-dates/option';
import client from './client';
import { Namespace } from './constants';

export const BASE_PATH = 'ruleset_versions';
export type CriminalApi = Entity.List;

export type MVRApi = {
  accident_options: Entity.List;
  suspension_options: Entity.List;
  violation_options: Entity.List;
};

export type ApiOptions = CriminalApi | MVRApi;

export const fetch = (
  namespace: Namespace,
  id: string,
): Promise<CriminalApi | MVRApi> => {
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.get(path);
};

export const update = (
  namespace: Namespace,
  id: string,
  contextDates: ApiOptions,
): Promise<void> => {
  // TODO: (EADA-4730) Cleanup to accept the same payload for both namespaces
  const payload =
    namespace === Namespace.criminal
      ? { context_dates: contextDates }
      : contextDates;
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.put(path, payload);
};

export const uri = (namespace: Namespace, id?: string): string => {
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.uri({ url: path });
};
