import ContextDates from 'modules/assess/models/rulesets/context-dates';
import * as Actions from 'state/utilities/actions';

export type Action = Actions.AnyWithPayload<Payload>;
export type Creator = Actions.Creators.Payload<Payload>;
export type Payload = {
  disabled?: boolean;
  options: ContextDates.Type;
  currentOption?: string;
};

export const TYPE = 'checkr/assess/context-date/load';
export const create: Creator = Actions.Creators.create(TYPE);
