import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getScreeningStatus,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import InternationalEmploymentRecord from './InternationalEmploymentRecord';

const InternationalEmploymentVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_employment_verification: screening } = report;
  if (!screening) return null;

  const status = getScreeningStatus(screening, reportStatusType);
  const employmentRecords = screening.records;

  return (
    <M.Screening
      title='International Employment Verification'
      statusType={reportStatusType}
      screening={screening}
      id={SCREENING_TITLES.international_employment_verification.id}
    >
      {employmentRecords.length === 0 && status !== ReportStatuses.CANCELED ? (
        <div>No employment history provided by candidate</div>
      ) : (
        employmentRecords.map((record, i: number) => (
          <React.Fragment key={record.id}>
            <InternationalEmploymentRecord
              screeningAssessment={screening?.assessment?.display?.decision}
              record={record}
              statusType={reportStatusType}
            />
            {i < screening.records.length - 1 && <M.Divider />}
          </React.Fragment>
        ))
      )}
    </M.Screening>
  );
};

export default InternationalEmploymentVerification;
