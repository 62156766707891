import React from 'react';
import unionBy from 'lodash/unionBy';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import {
  AccordionItem,
  ActionPropsProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import drugBottle from '../../shared/svg/drug-bottle.svg';
import DrugScreenings from './DrugAndHealthScreenings/DrugScreening';
import OccupationalHealth from './DrugAndHealthScreenings/OccupationalHealth';

export type DrugSKU = {
  name: String;
  charge_type: String;
};

export type DrugPanel = {
  name: String;
  label: String;
  counterpart_slug: String;
  integration: String;
  skus: DrugSKU[];
};

export type DrugPanelGroup = {
  name: String;
  description: String;
  screening_type: String;
  services: DrugPanel[];
};

export const defaultDrugPanels: DrugPanel[] = [
  {
    name: 'DS_5_PANEL',
    label: '5 Panel',
    counterpart_slug: 'DS_INSTANT_5PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-STD-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-5PO-STD-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_5_PANEL_NO_THC',
    label: '5 Panel no THC',
    counterpart_slug: 'DS_XCUP_5PANEL_NO_THC',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-MTHC-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-5PO-MTHC-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_INSTANT_5PANEL',
    label: 'Instant 5 Panel',
    counterpart_slug: '',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-I-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-5PO-I-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_7_PANEL',
    label: '7 Panel',
    counterpart_slug: 'DS_XCUP_7PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-7PI-STD-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-7PO-STD-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_9_PANEL',
    label: '9 Panel',
    counterpart_slug: 'DS_XCUP_9PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-9PI-STD-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-9PO-STD-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_9_PANEL_NO_THC',
    label: '9 Panel no THC',
    counterpart_slug: '',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-9PI-MTHC-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-9PO-MTHC-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_10_PANEL',
    label: '10 Panel',
    counterpart_slug: 'DS_XCUP_10PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-10PI-STD-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-10PO-STD-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
  {
    name: 'DS_10_PANEL_NO_THC',
    label: '10 panel no THC',
    counterpart_slug: 'DS_XCUP_10PANEL_NO_THC',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-10PI-MTHC-LAB-U',
        charge_type: 'in-network',
      },
      {
        name: 'DRG-0001-10PO-MTHC-LAB-U',
        charge_type: 'out-of-network',
      },
    ],
  },
];
export const determineDrugScreenTypes = (
  drugScreeningsEnabledForAccount: Boolean,
  data: any,
) => {
  const drugScreenTypes = data?.drug_screening_v2?.flatMap(
    (d: DrugPanelGroup) => d.services,
  );
  if (drugScreeningsEnabledForAccount) {
    const drugScreenTypesWithDefaults = unionBy(
      defaultDrugPanels,
      drugScreenTypes,
      'name',
    );
    return drugScreenTypesWithDefaults;
  }

  return drugScreenTypes || [];
};

export const showOccupationalHealth = (
  shouldRenderOccHealthScreenDropdown: boolean,
  occHealthScreeningsEnabledForAccount: boolean,
  occHealthFlagEnabled: boolean,
) => {
  return (
    (occHealthScreeningsEnabledForAccount &&
      shouldRenderOccHealthScreenDropdown) ||
    occHealthFlagEnabled
  );
};

type DrugAndHealthScreeningsItemProps = {
  screeningTypesInSearch: string[];
  actionProps: ActionPropsProps;
  basePackage: BasePackage;
  drugScreenTypes: any;
  shouldRenderDrugScreenDropdown: boolean;
  drugStatus: boolean;
  occupationalHealthServices: any;
  shouldRenderOccHealthScreenDropdown: boolean;
};

export const DrugAndHealthScreeningsItem = ({
  screeningTypesInSearch,
  actionProps,
  basePackage,
  drugScreenTypes,
  shouldRenderDrugScreenDropdown,
  drugStatus,
  occupationalHealthServices,
  shouldRenderOccHealthScreenDropdown,
}: DrugAndHealthScreeningsItemProps) => {
  return (
    <AccordionItem
      open={
        screeningTypesInSearch?.includes('drug_screening') ||
        screeningTypesInSearch?.includes('occupational_health_screening')
      }
      data-testid='drug-and-health-screening-section-dropdown'
      title={
        <CategoryTitle data-testid='drug-and-health-screening-section-title'>
          <Icon src={drugBottle} className='screening-icon' /> Drug tests and
          health screenings
        </CategoryTitle>
      }
    >
      <ul>
        {shouldRenderDrugScreenDropdown && (
          <DrugScreenings
            shouldOpen={screeningTypesInSearch?.includes('drug_screening')}
            drugScreenTypes={drugScreenTypes}
            basePackage={basePackage}
            actionProps={actionProps}
            drugSetupStatus={drugStatus}
          />
        )}
        {shouldRenderOccHealthScreenDropdown && (
          <OccupationalHealth
            shouldOpen={screeningTypesInSearch?.includes(
              'occupational_health_screening',
            )}
            occupationalHealthServices={occupationalHealthServices}
            actionProps={actionProps}
            basePackage={basePackage}
          />
        )}
      </ul>
    </AccordionItem>
  );
};

export default DrugAndHealthScreeningsItem;
