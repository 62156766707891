import React from 'react';
import { SCREENING_NAME_MAP } from 'Constants';
import { countCandidates } from 'components/Signup/utils/candidates';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ALIAS_IN_SIGNUP } from 'Flags';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Content,
  SectionTitle,
  Screening,
  ScreeningPrice,
  NoneAdded,
  Section,
  SectionItem,
  EmptyPrice,
} from './styles';
import { getDollarAmount } from '../../../AddScreenings/shared/utils';
import { AddOnsType, Candidate } from '../../types';
import { LoadingBlock } from '../Alias/styles';

type Props = {
  packageName: string;
  customPackagePrice: string | null;
  basePackagePrice: string | null;
  screenings: AddOnsType[];
  candidates: Candidate[];
  screeningPrices: { [key: string]: number };
  isInternational?: boolean;
  isAliasEnabled: string | null;
  aliasPrice: string | null;
  subtotal: string | null;
};

const OrderSummary = ({
  packageName,
  screenings,
  candidates,
  screeningPrices,
  customPackagePrice,
  basePackagePrice,
  isInternational = false,
  isAliasEnabled,
  aliasPrice,
  subtotal,
}: Props) => {
  const { t } = useTranslation('signup');
  const numOfCandidates = countCandidates(candidates);
  const aliasEnabledFlag =
    useFlag(ALIAS_IN_SIGNUP)?.variantKey === 'on' || false;

  return (
    <Container data-testid='order-summary'>
      <h4 className='mb-0'>{t('components.OrderSummary.orderSummary')}</h4>
      <Content>
        <Section data-testid='order-summary-package-details'>
          <SectionTitle>{t('components.OrderSummary.package')}</SectionTitle>
          <SectionItem data-testid='order-summary-package'>
            <Screening data-testid='order-summary-package-name'>
              {packageName}
            </Screening>
            <ScreeningPrice data-testid='order-summary-package-price'>
              {basePackagePrice === null ? <LoadingBlock /> : basePackagePrice}
            </ScreeningPrice>
          </SectionItem>
        </Section>
        {!isInternational && (
          <Section data-testid='order-summary-add-ons'>
            <SectionTitle>
              {t('components.OrderSummary.customize')}
            </SectionTitle>
            {screenings.length > 0 ? (
              screenings.map(screening => (
                <SectionItem key={screening}>
                  <Screening data-testid='order-summary-addons-name'>
                    {SCREENING_NAME_MAP[screening]}
                  </Screening>
                  <ScreeningPrice>
                    {getDollarAmount(screeningPrices[screening].toString())}
                  </ScreeningPrice>
                </SectionItem>
              ))
            ) : (
              <SectionItem>
                <NoneAdded data-testid='screening-none-added'>
                  {t('components.OrderSummary.noneAdded')}
                </NoneAdded>
                <EmptyPrice>—</EmptyPrice>
              </SectionItem>
            )}
          </Section>
        )}
        {aliasEnabledFlag && isAliasEnabled === 'true' && (
          <Section>
            <SectionItem>
              <Screening data-testid='order-summary-alias'>
                {t('components.OrderSummary.aliasSearches')}
              </Screening>
              <ScreeningPrice data-testid='order-summary-alias-price'>
                {aliasPrice === null ? <LoadingBlock /> : aliasPrice}
              </ScreeningPrice>
            </SectionItem>
          </Section>
        )}
        <Section data-testid='order-summary-recipients'>
          {numOfCandidates > 0 && (
            <SectionTitle>
              {numOfCandidates} {t('components.OrderSummary.candidates')} ×{' '}
              {customPackagePrice === null ? (
                <LoadingBlock />
              ) : (
                customPackagePrice
              )}{' '}
              {t('components.OrderSummary.perReport')}
            </SectionTitle>
          )}
          <SectionItem data-testid='order-summary-package'>
            <Screening data-testid='order-summary-package-name'>
              {t('components.OrderSummary.subtotal')}
            </Screening>
            <ScreeningPrice data-testid='order-summary-package-price'>
              {subtotal === null ? <LoadingBlock /> : subtotal}
            </ScreeningPrice>
          </SectionItem>
        </Section>
      </Content>
    </Container>
  );
};

export default OrderSummary;
