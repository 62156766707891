import React from 'react';

const ManualEntryStep: React.FC<{}> = () => {
  return (
    <div data-testid='manual-entry-step-container'>
      Manual Entry Step Placeholder
    </div>
  );
};

export default ManualEntryStep;
