export type Action =
  | { type: ActionType.SET_FILTERS; payload: Partial<Filters> }
  | { type: ActionType.INITIALIZE_FILTERS };

export type Dispatch = (action: Action) => void;

// TODO: Enhance filter type
export type Filters = { [filterKey: string]: any };

export type State = {
  filters: Filters;
};

export enum ActionType {
  SET_FILTERS = 'set filters',
  INITIALIZE_FILTERS = 'initialize filters',
}
