import * as Entity from 'modules/assess/models/rulesets/context-dates/option';

export function findAvailableOptions(
  selectedOptions: Entity.List,
  optionsSource: Record<string, any>,
) {
  const allOptions = Object.values(optionsSource);
  const mapSelectedOptions = selectedOptions.map(o => o.option);
  const availableOptions = allOptions.filter(
    o => !mapSelectedOptions.includes(o),
  );

  return availableOptions;
}
