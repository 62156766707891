import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  ALIASES,
  ALIASES_EVENT_PROPERTIES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';

const Container = styled.div``;

const LabelDescription = styled.div`
  color: ${colors.uiTextSecondaryLight};
  font-size: var(--font-size-xs, 12px);
  line-height: var(--font-line-height-md, 16px); /* 133.333% */
`;

const RadioLabelInclude = ({ includeAlias }: { includeAlias: Boolean }) => (
  <>
    <div>Search aliases for my future orders</div>
    {includeAlias && (
      <LabelDescription className='p4'>
        You can remove alias searches from an order in the Customize step.{' '}
      </LabelDescription>
    )}
  </>
);

const RadioLabelExclude = ({ includeAlias }: { includeAlias: Boolean }) => (
  <>
    <div>Don&apos;t search aliases for my future orders</div>
    {!includeAlias && (
      <LabelDescription className='p4'>
        You can add alias searches to an order in the Customize step.{' '}
      </LabelDescription>
    )}
  </>
);

const AliasRadioSelection: React.FC<{
  setDefaultAlias: Function;
  aliasAutoEnableSetting: Boolean;
  source: ALIASES_EVENT_PROPERTIES['Source'];
}> = ({ setDefaultAlias, aliasAutoEnableSetting, source }) => {
  const [includeAlias, setIncludeAlias] = useState(aliasAutoEnableSetting);
  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const trackRadioButtonClicks = useCallback(
    (selected: ALIASES_EVENT_PROPERTIES['Selection']) => {
      trackEvent(
        currentUser,
        ALIASES.CHECKR_DASHBOARD_DEFAULT_ALIAS_TOGGLE_CLICKED,
        {
          Source: source,
          Selection: selected,
        },
      );
    },
    [currentUser, source, trackEvent],
  );

  const handleRadioSelection = useCallback(
    (selection: string) => {
      if (selection === 'include' && !includeAlias) {
        trackRadioButtonClicks('On');
        setIncludeAlias(true);
        setDefaultAlias(true);
      }
      if (selection === 'exclude' && includeAlias) {
        trackRadioButtonClicks('Off');
        setIncludeAlias(false);
        setDefaultAlias(false);
      }
    },
    [includeAlias, setDefaultAlias, trackRadioButtonClicks],
  );

  return (
    <Container>
      <M.RadioButtonGroup
        name='alias-radio-selection'
        orientation='vertical'
        onChange={handleRadioSelection}
        valueSelected={includeAlias ? 'include' : 'exclude'}
        data-testid='alias-radio-selection'
      >
        <M.RadioButton
          data-testid='default-alias-enabled'
          key='include'
          labelText={<RadioLabelInclude includeAlias={includeAlias} />}
          value='include'
          selected={includeAlias}
        />
        <M.RadioButton
          data-testid='default-alias-disabled'
          key='exclude'
          labelText={<RadioLabelExclude includeAlias={includeAlias} />}
          value='exclude'
          selected={!includeAlias}
        />
      </M.RadioButtonGroup>
    </Container>
  );
};

export default AliasRadioSelection;
