import React, { useMemo, useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import RightPanel from './right-panel';
import ResultsPage from './results-page';

type Props = {};

const IconButton = styled(M.Button)`
  #mastodon && {
    border: none;
    align-self: start;
    svg {
      fill: rgba(26, 32, 38, 0.78);
    }
  }
`;

const ActionMenu = styled(M.ActionMenu)`
  #mastodon && {
    align-self: start;
    .mastodon-action-menu-container {
      z-index: 1000;
      width: 260px;
    }
    .mastodon-action-menu-children {
      padding: 1rem 0;
    }
  }
`;

const Settings: React.FC<Props> = () => {
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const [thereWasAction, setThereWasAction] = useState(false);

  const onCloseMenu = useCallback(() => {
    if (!thereWasAction) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Setting Selected': 'No Action',
        Report: reportProperties,
      });
    }
  }, [thereWasAction, reportProperties, trackEvent]);

  const onActionMenuOpen = useCallback(
    open => {
      if (!open) onCloseMenu();
    },
    [onCloseMenu],
  );

  const trackAction = useCallback(
    () => setThereWasAction(true),
    [setThereWasAction],
  );

  const trigger = useMemo(
    () => (
      <IconButton kind='tertiary'>
        <M.Icon icon='SettingsView' />
      </IconButton>
    ),
    [],
  );

  return (
    <ActionMenu
      position='left-bottom'
      trigger={trigger}
      onMenuOpen={onActionMenuOpen}
      data-pendo-id='candidate-page-settings-menu'
    >
      <RightPanel trackAction={trackAction} />
      <ResultsPage />
    </ActionMenu>
  );
};

export default Settings;
