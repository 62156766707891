import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Trans, useTranslation } from 'react-i18next';
import { useUnengage } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import { namespace } from 'modules/adjudication/locales';
import { useCandidate } from 'providers/Candidate';
import { getCandidateName } from 'modules/candidate/ui/header/utils';

type Props = {
  open: boolean;
  hideModal: () => void;
};

const Modal: React.FC<Props> = ({ open, hideModal }) => {
  const { report } = useGetReport();
  const { call } = useUnengage(report?.id);

  const candidate = useCandidate();
  const name = getCandidateName(candidate);

  const { t } = useTranslation();
  const title = t(`${namespace}:report.actions.change.modal.title`);
  const body = `${namespace}:report.actions.change.modal.body`;
  const action = t(`${namespace}:report.actions.change.modal.action`);

  const submit = useCallback(() => {
    call();
    hideModal();
  }, [call, hideModal]);

  return (
    <M.ComposedModal
      type='small'
      kind='destructive'
      data-testid='change-decision-modal'
      open={open}
      onClose={hideModal}
    >
      <M.ModalHeader
        icon={<M.Icon icon='WarningHexFilled' className='test-class' />}
        title={title}
        buttonOnClick={hideModal}
      />
      <M.ModalBody>
        <p>
          <Trans i18nKey={body} values={{ name }} components={{ br: <br /> }} />
        </p>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button onClick={submit} kind='danger'>
          {action}
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Modal;
