import React from 'react';
import { Suspensions } from 'modules/assess/models/context-date-option/mvr';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { entries } from 'modules/assess/ui/state/context-date/options/state';
import { MVR } from 'modules/assess/ui/state/context-date/state';
import Create from '../../CreateContainer';
import List from '../../List';
import { findAvailableOptions } from '../../utils';

const Container: React.FC = () => {
  const { options: stateOptions } = useState();
  const mvrStateOptions = stateOptions as MVR;
  const options = entries(mvrStateOptions.suspensions) || [];
  const availableOptions = findAvailableOptions(
    options,
    Suspensions.Suspensions,
  );

  return (
    <div data-testid='ruleset-version-context-dates-mvr-suspensions-container'>
      <List options={options} />
      <Create availableOptions={availableOptions} />
    </div>
  );
};

export default Container;
