import React from 'react';
import { useContextDatesOverride } from 'modules/assess/ui/hooks';

export const Guard: React.FC = ({ children }) => {
  const isEnabled = useContextDatesOverride();

  if (!isEnabled) {
    return null;
  }

  return <>{children}</>;
};

export default Guard;
