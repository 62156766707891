import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import InfoMethodRadios from './InfoMethodRadios';
import { namespace } from '../../locales';
import { ManualInviteEntries } from './ManualInviteEntries';
import CandidateBulkUpload from './CandidateBulkUpload';
import {
  StyledCandidateContactContainer,
  StyledCandidateContactFormHeader,
  StyledCandidateContactSectionHeader,
  StyledManualInviteInputContainer,
} from './CandidateContactInfoSection.styles';

type CandidateContactInfoSectionProps = {
  selectedInfoMethod: GetStartedT.InfoMethod;
  setSelectedInfoMethod: Dispatch<SetStateAction<GetStartedT.InfoMethod>>;
  enteredInvites: GetStartedT.Invite[];
  setEnteredInvites: Dispatch<SetStateAction<GetStartedT.Invite[]>>;
  uploadedCsv: GetStartedT.BulkInviteCSV;
  setUploadedCsv: Dispatch<SetStateAction<GetStartedT.BulkInviteCSV>>;
  setParsedEmails: Dispatch<SetStateAction<GetStartedT.Email[]>>;
};

const CandidateContactInfoSection = ({
  selectedInfoMethod,
  setSelectedInfoMethod,
  enteredInvites,
  setEnteredInvites,
  uploadedCsv,
  setUploadedCsv,
  setParsedEmails,
}: CandidateContactInfoSectionProps) => {
  const { t } = useTranslation(namespace);
  const manualBasePath = `${namespace}:candidateContactInfo.manual`;

  return (
    <StyledCandidateContactContainer>
      <StyledCandidateContactSectionHeader
        className='section-title'
        data-testid='candidate-contact-info-section-header'
      >
        {t('candidateContactInfo.header')}
      </StyledCandidateContactSectionHeader>

      <InfoMethodRadios
        selectedInfoMethod={selectedInfoMethod}
        setSelectedInfoMethod={setSelectedInfoMethod}
      />

      {selectedInfoMethod === 'MANUAL' && (
        <StyledManualInviteInputContainer>
          <StyledCandidateContactFormHeader data-testid='candidate-contact-form-header'>
            {t(`${manualBasePath}.header`)}
          </StyledCandidateContactFormHeader>

          <p className='p2'>{t(`${manualBasePath}.descriptionOne`)}</p>
          <p className='p2'>{t(`${manualBasePath}.descriptionTwo`)}</p>

          <ManualInviteEntries
            enteredInvites={enteredInvites}
            setEnteredInvites={setEnteredInvites}
          />
        </StyledManualInviteInputContainer>
      )}
      {selectedInfoMethod === 'UPLOAD' && (
        <CandidateBulkUpload
          uploadedCsv={uploadedCsv}
          setUploadedCsv={setUploadedCsv}
          setParsedEmails={setParsedEmails}
        />
      )}
    </StyledCandidateContactContainer>
  );
};

export default CandidateContactInfoSection;
