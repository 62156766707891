// BackToPoolModal.tsx

import React, { FC, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const BackToPoolModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <M.ComposedModal open={isOpen}>
      <M.ModalHeader closeModal={onClose}>
        <h2>Renew eligibility</h2>
      </M.ModalHeader>
      <StyledModalBody>
        <p>
          Renewing the eligibility allows you to take action on this employee
          again. Please order a test again.
        </p>
      </StyledModalBody>
      <M.ModalFooter>
        <M.Button onClick={onClose} kind='tertiary'>
          Cancel
        </M.Button>
        <M.Button onClick={handleConfirm}>Confirm</M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

const StyledModalBody = styled(M.ModalBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export default BackToPoolModal;
