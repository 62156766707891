import { Initializer } from 'state/utilities/state';
import { ApiOptions } from 'modules/assess/api/context-dates';
import * as Options from './options';

export type Criminal = {
  criminal: Options.State.Type;
};

export type MVR = {
  accidents: Options.State.Type;
  suspensions: Options.State.Type;
  violations: Options.State.Type;
};

export type Type = {
  disabled?: boolean;
  dirty: boolean;
  options: Criminal | MVR;
  currentOption?: string;
};

export const initialize: Initializer<Type> = () => {
  return {
    dirty: false,
    options: {
      criminal: Options.State.initialize(),
      mvr: {
        accidents: Options.State.initialize(),
        suspensions: Options.State.initialize(),
        violations: Options.State.initialize(),
      },
    },
  };
};

export const entity = (state: Type): ApiOptions => {
  if ('criminal' in state.options) {
    const criminalOptions = state.options as Criminal;
    return Options.State.entries(criminalOptions.criminal);
  }

  const mvrOptions = state.options as MVR;
  return {
    accident_options: Options.State.entries(mvrOptions.accidents),
    suspension_options: Options.State.entries(mvrOptions.suspensions),
    violation_options: Options.State.entries(mvrOptions.violations),
  };
};
