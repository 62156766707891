import React from 'react';
import {
  hasPermission,
  CurrentUser as User,
} from '@dashboard-experience/utils';
import * as ReportInfoFields from './report-information/fields';
import * as OrderInfoFields from './order-information/fields';
import * as AssessGuidelines from './assess-guidelines/fields';
import * as CandidateInfoFields from './candidate-information/fields';

import {
  GetColConfigItemParams,
  BuildConfigParmas,
  FieldKVList,
  FieldConfig,
} from './types';

const componentMap: {
  [fieldKey: string]: React.FC<any>;
} = {
  status: ReportInfoFields.Status,
  adjudication: ReportInfoFields.Adjudication,
  assigned_to: ReportInfoFields.AssignedTo,
  tags: ReportInfoFields.Tags,
  package: OrderInfoFields.Package,
  nodes: OrderInfoFields.Nodes,
  source: OrderInfoFields.Source,
  work_locations: OrderInfoFields.WorkLocation,
  created_at: OrderInfoFields.Date,
  completed_at: OrderInfoFields.Date,
  revised_at: OrderInfoFields.RevisedAt,
  upgraded_at: OrderInfoFields.UpgradedAt,
  turnaround_time: OrderInfoFields.TurnaroundTime,
  estimation_updated: OrderInfoFields.EstimationUpdated,
  estimated_completion: OrderInfoFields.EstimatedCompletion,
  mvr_ruleset: AssessGuidelines.Mvr,
  criminal_ruleset: AssessGuidelines.Criminal,
  custom_id: CandidateInfoFields.CustomID,
  date_of_birth: CandidateInfoFields.DateOfBirth,
  drivers_license: CandidateInfoFields.DriversLicense,
  email: CandidateInfoFields.Email,
  full_name: CandidateInfoFields.FullName,
  invitation_sent: CandidateInfoFields.InvitationSent,
  location: CandidateInfoFields.Location,
  phone: CandidateInfoFields.Phone,
  postal_code: CandidateInfoFields.PostalCode,
  social_security: CandidateInfoFields.SocialSecurity,
  candidate_created_at: CandidateInfoFields.CreatedAt,
};

export const allowEdit = (user: User) => {
  return (field: string) => {
    if (field === 'package') {
      return hasPermission(user, 'perform_upgrade');
    }
    return user.roles?.includes('admin');
  };
};

export const getColConfigItem = ({
  fieldName,
  fieldKey,
  user,
  report,
  statusType,
  reportPrediction,
  reportEstimationGenerated,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
}: GetColConfigItemParams) => {
  const configItem: FieldKVList = {
    itemKey: null,
    itemValue: null,
  };

  // set KVList key
  configItem.itemKey = fieldName;

  const allowEditField = allowEdit(user);

  // set KVList value
  const FieldComponent = componentMap[fieldKey];
  configItem.itemValue = (
    <FieldComponent
      fieldKey={fieldKey}
      displayName={fieldName}
      allowCandidateEdit={allowEditField(fieldKey)}
      testid={`report-information-field-${fieldKey}`}
      report={report}
      currentUser={user}
      // note sure
      statusType={statusType}
      reportPrediction={reportPrediction}
      reportEstimationGenerated={reportEstimationGenerated}
      candidate={candidate}
      candidateUpdates={candidateUpdates}
      setCandidateUpdates={setCandidateUpdates}
    />
  );

  return configItem;
};

export const buildColumnConfig = (params: BuildConfigParmas) => {
  const { fields } = params;

  const config: FieldKVList[] = fields
    .map((field: FieldConfig) => {
      const { getFieldName, fieldKey } = field;
      let item: FieldKVList = {
        itemKey: null,
        itemValue: null,
      };

      const fieldName = getFieldName();

      item = getColConfigItem({
        fieldName,
        fieldKey,
        ...params,
      });
      return item;
    })
    .filter((item: FieldKVList) => item.itemKey);
  return config;
};
