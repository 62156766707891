import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ThirdPartyTooltip, Toggletip } from './ListItemElements';
import { getDollarAmount } from './utils';
import { ALIAS_AS_AN_ADDON } from '../../../Flags';

export type ThirdPartyFeeProps = {
  serviceFeeMin: number;
  serviceFeeMax: number;
  passthroughFeeMin: number;
  passthroughFeeMax: number;
};

const ThirdPartyFee = styled.div`
  padding-top: 24px;
`;

const LinePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

const TooltipSpan = styled.span`
  font-size: 14px !important;
  color: ${colors.brandNavy3};
  display: flex;
  align-items: center;
  .mastodon-toggletip {
    margin-top: 0 !important;
    margin-left: 0.25rem !important;
  }
`;

const SalesTaxText = styled.div`
  margin-top: 12px;
  font-size: 12px;
  color: ${colors.uiTextTertiaryLight};
`;

const ThirdPartyPrice = styled.span`
  font-size: 14px;
  color: ${colors.brandNavy3};
  margin-top: 4px;
`;

const ThirdPartyFees: React.FC<ThirdPartyFeeProps> = ({
  passthroughFeeMin,
  passthroughFeeMax,
  serviceFeeMin,
  serviceFeeMax,
}) => {
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  const minTotalFees = passthroughFeeMin + serviceFeeMin;
  const maxTotalFees = passthroughFeeMax + serviceFeeMax;
  const showFeeAmount = aliasFlag && maxTotalFees > 0;

  return (
    <ThirdPartyFee className='third-party-fee'>
      {showFeeAmount ? (
        <LinePrice className='line-price'>
          <TooltipSpan>
            Estimated passthrough fees
            <div data-testid='third-party-price-icon'>
              <Toggletip align='top' tabIndex={0}>
                <M.ToggletipButton>
                  <M.Icon icon='HelpFilled' />
                </M.ToggletipButton>
                <ThirdPartyTooltip />
              </Toggletip>
            </div>
          </TooltipSpan>
          <ThirdPartyPrice
            className='third-party-price'
            data-testid='summary-section-third-party-fees'
          >
            {`${getDollarAmount(`${minTotalFees}`)} - ${getDollarAmount(
              `${maxTotalFees}`,
            )}`}
          </ThirdPartyPrice>
        </LinePrice>
      ) : (
        <LinePrice className='line-price'>
          <TooltipSpan>
            Passthrough fees might apply.
            <Toggletip align='top' tabIndex={0}>
              <M.ToggletipButton>
                <M.Icon icon='HelpFilled' />
              </M.ToggletipButton>
              <ThirdPartyTooltip />
            </Toggletip>
          </TooltipSpan>
        </LinePrice>
      )}
      <SalesTaxText>Invoice will show sales tax.</SalesTaxText>
    </ThirdPartyFee>
  );
};

export default ThirdPartyFees;
