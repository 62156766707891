import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledCandidateContactContainer = styled.div`
  margin-top: 24px;
`;

export const StyledCandidateContactSectionHeader = styled.h4`
  font-size: 18px !important;
`;

const ChoiceCardGroup = styled.div`
  display: flex;
  margin: 1rem 0;
`;

export const StyledChoiceCardGroup = styled(ChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;

export const StyledManualInviteInputContainer = styled.div`
  margin-top: 40px;
`;

export const StyledCandidateContactFormHeader = styled.h4`
  font-size: 18px !important;
`;

export const StyledNeedHelp = styled.p`
  margin-top: 16px !important;
`;

export const StyledTemplateInstructions = styled.li`
  line-height: 20px;
`;

export const StyledFindARecord = styled.p`
  margin: 12px 0 !important;
`;

export const StyledDeleteFileButton = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0 !important;
`;

export const StyledUploadedFile = styled(M.Container.Row)`
  align-items: center;
  margin-top: 2rem;

  .mastodon-icon {
    fill: ${colors.uiGreen600};
  }

  .filename {
    color: ${colors.uiTextPrimaryLight};
    font-size: 0.875rem !important;
    margin: 0 1rem;
  }
`;
