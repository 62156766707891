/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import { COMMERCIAL_MVR_ADD_ON_FLAG_KEY } from 'Constants';
import { Flag } from '@dashboard-experience/react-flagr';
import {
  AccordionItem,
  CategoryTitle,
  Icon,
  ExpandableTitle,
  AddButtonContainer,
  Box,
  BoxDivider,
  BoxHeader,
  Flex,
  JustifyContentSpaceBetween,
  ScreeningInfo,
  BulletPoint,
  Title,
  AccordionItemProps,
  CheckboxWrapper,
  Toggletip,
  FlexContainerNoBorder,
  ContainerWithoutBorder,
} from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import truck from '../../shared/svg/truck.svg';
import CommercialMvrCheckbox from './MotorVehicleReport/CommercialMvrCheckbox';

export type MotorVehicleReportAdditionalPropertiesProps = {
  [motor_vehicle_report: string]: {
    mvr_type: [boolean, string];
  };
};

type MotorVehicleReportProps = AccordionItemProps & {
  additionalProperties: MotorVehicleReportAdditionalPropertiesProps;
  basePackage: BasePackage;
};

const MotorVehicleRecordsCheckItem = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
  additionalProperties,
  basePackage,
}: MotorVehicleReportProps) => {
  const shouldOpen = screeningTypesInSearch?.includes('motor_vehicle_report');
  const { onAddClick, disabledScreenings } = actionProps;

  const checked = () => {
    if (
      (additionalProperties?.motor_vehicle_report?.mvr_type?.length ?? 0) > 0
    ) {
      return additionalProperties?.motor_vehicle_report?.mvr_type[0];
    }
    return false;
  };

  return (
    <AccordionItem
      open={screeningTypesInSearch?.includes('motor_vehicle_report')}
      data-testid='motor-vehicle-records-check-dropdown'
      title={
        <CategoryTitle data-testid='motor-vehicle-records-check-title'>
          <Icon src={truck} className='screening-icon' />
          Motor vehicle record (MVR) report
        </CategoryTitle>
      }
    >
      <FlexContainerNoBorder>
        <ExpandableTitle
          initialExpanded={shouldOpen}
          data-testid='expandable-motor-vehicle-search-section'
          title={
            <Title
              getPriceByType={getPriceByType}
              title='MVR report'
              priceByType='motor_vehicle_report'
            />
          }
        >
          <JustifyContentSpaceBetween>
            <ScreeningInfo>
              Search a state&apos;s DMV or similar entity, confirm driver&apos;s
              license, and identify driving incidents.
            </ScreeningInfo>
          </JustifyContentSpaceBetween>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <ul>
                <BulletPoint text='Mostly digitized records mean fast turnaround.' />
                <BulletPoint text="One source includes all the state's driving-related information." />
                <BulletPoint text='Find all DMV data from the past 3-10 years, depending on state (or Puerto Rico).' />
              </ul>
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <ul>
                <BulletPoint text='Search only the state (or Puerto Rico) of the candidate’s current driver’s license.' />
                <BulletPoint text='Some states and Puerto Rico have extra requirements.' />
                <BulletPoint text='To set up Pennsylvania MVR, contact Checkr.' />
              </ul>
            </Box>
          </Flex>
        </ExpandableTitle>
        <AddButtonContainer>
          <AddScreeningsAction
            screeningType='motor_vehicle_report'
            {...actionProps}
          />
        </AddButtonContainer>
      </FlexContainerNoBorder>
      <ContainerWithoutBorder>
        <Flag flagKey={COMMERCIAL_MVR_ADD_ON_FLAG_KEY} variantKey='enabled'>
          {!disabledScreenings.includes('motor_vehicle_report') && (
            <CheckboxWrapper>
              <CommercialMvrCheckbox
                checked={checked()}
                onAddClick={onAddClick}
              />
              <div data-testid='help-filled-icon-require-commercial-mvr'>
                <Toggletip align='bottom' tabIndex={0}>
                  <M.ToggletipButton>
                    <M.Icon icon='HelpFilled' />
                  </M.ToggletipButton>
                  <M.ToggletipContent>
                    <div>
                      <p>
                        A commercial MVR report is a specialized MVR report for
                        hiring drivers with commercial driver&apos;s licenses
                        (CDLs).
                      </p>
                      <p>
                        A commercial MVR report searches Commercial
                        Driver&apos;s License Information System (CDLIS),
                        includes medical certificate data, and complies with
                        Federal Motor Carrier Safety Administration (FMCSA)
                        regulations.
                      </p>
                    </div>
                  </M.ToggletipContent>
                </Toggletip>
              </div>
            </CheckboxWrapper>
          )}
        </Flag>
      </ContainerWithoutBorder>
    </AccordionItem>
  );
};

export default MotorVehicleRecordsCheckItem;
