import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { usePostPaymentMethod } from 'api/payment';
import { useHistory } from 'react-router-dom';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT,
} from 'utils/analytics';
import AddPaymentModalBody from '../../Account/Payment/AddPaymentModalBody';

const ComposedModal = styled(M.ComposedModal)`
  #mastodon & .cds--modal-container {
    max-height: 90vh;
  }

  .StripeElement {
    width: 100%;
  }

  .justify-right {
    justify-content: end;
    padding-bottom: 1.5rem;
  }
`;

const ContentSwitcher = styled(M.ContentSwitcher)`
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const AddPaymentDetailsModal: React.FC<{
  isPaymentDetailsModalOpen: boolean;
  setIsPaymentDetailsModalOpen: (isPaymentDetailsModalOpen: boolean) => void;
  hasPermission: boolean;
  setIsPaymentDetailsAdded: Function;
}> = ({
  isPaymentDetailsModalOpen,
  setIsPaymentDetailsModalOpen,
  hasPermission,
  setIsPaymentDetailsAdded,
}) => {
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [cardElementOptions, setCardElementOptions]: any = useState();
  const [bankElementOptions, setBankElementOptions]: any = useState();
  const [paymentType, setPaymentType] = useState<'us_bank_account' | 'card'>(
    'card',
  );

  const { postPaymentMethodCall, postPaymentMethodResult }: any =
    usePostPaymentMethod();

  useEffect(() => {
    if (isPaymentDetailsModalOpen) {
      trackEvent(
        ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT.CHECKR_DASHBOARD_ORDER_BGC_PARTNER_PAYMENT_MODAL_VIEWED,
        {
          'Have permission to add payment details': hasPermission
            ? 'Yes'
            : 'No',
        },
      );
    }
  }, [trackEvent, isPaymentDetailsModalOpen, hasPermission]);

  useEffect(() => {
    if (postPaymentMethodResult.isSuccess) {
      setIsPaymentDetailsAdded(true);
      trackEvent(
        ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT.CHECKR_DASHBOARD_PARTNER_PAYMENT_ENTERED,
      );
    }
  }, [trackEvent, postPaymentMethodResult.isSuccess, setIsPaymentDetailsAdded]);

  const onTabSwitch = useCallback(({ name }) => {
    setPaymentType(name);
  }, []);

  const onModalClose = useCallback(
    () => setIsPaymentDetailsModalOpen(false),
    [setIsPaymentDetailsModalOpen],
  );

  const onBackButtonClick = useCallback(() => {
    setIsPaymentDetailsModalOpen(false);
    history.push('/');
  }, [history, setIsPaymentDetailsModalOpen]);

  if (!hasPermission) {
    return (
      <M.ComposedModal
        style={{ zIndex: 999999 }}
        type='small'
        open={isPaymentDetailsModalOpen}
        onClose={onModalClose}
      >
        <M.ModalHeader
          className='fer'
          title='Add payment'
          buttonOnClick={onModalClose}
        />
        <M.ModalBody>
          <p>
            Ordering through Checkr requires you to save a payment method.
            Checkr can&apos;t use your payment method from your partner
            integration. Before you can order, ask your account admin to save a
            payment method with Checkr.
          </p>
        </M.ModalBody>
        <M.ModalFooter
          actions={{
            primary: {
              name: 'Close',
              onClick: onModalClose,
            },
            secondary: {
              name: 'Go back',
              onClick: onBackButtonClick,
            },
          }}
        />
      </M.ComposedModal>
    );
  }

  return (
    <ComposedModal
      style={{ zIndex: 999999 }}
      open={isPaymentDetailsModalOpen}
      data-testid='add-payment-modal'
      onClose={onModalClose}
    >
      <M.LoadingSpinner active={isProcessing} />
      <M.ModalHeader buttonOnClick={onModalClose}>
        <h2>Add payment details</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p>
          Ordering through Checkr requires you to save a payment method. Checkr
          can&apos;t use your payment method from your partner integration.
        </p>
        <ContentSwitcher onChange={onTabSwitch}>
          <M.Switch name='card' text='Credit or debit card' />
          <M.Switch name='us_bank_account' text='Bank account' />
        </ContentSwitcher>
        <AddPaymentModalBody
          key={paymentType}
          error={error}
          setError={setError}
          processing={isProcessing}
          setIsProcessing={setIsProcessing}
          elementOptions={
            paymentType === 'card' ? cardElementOptions : bankElementOptions
          }
          setElementOptions={
            paymentType === 'card'
              ? setCardElementOptions
              : setBankElementOptions
          }
          paymentType={paymentType}
          hideModal={onModalClose}
          postPaymentMethod={{
            postPaymentMethodCall,
            postPaymentMethodResult,
          }}
          isOrder
        />
      </M.ModalBody>
    </ComposedModal>
  );
};

export default AddPaymentDetailsModal;
