import React from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { P } from './styles';

type Props = {
  error?: AxiosError | null;
};

const ErrorResult: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  if (!error) return null;

  if (error?.response?.status === 404)
    return (
      <P>
        {t(`${namespace}:report.exceptions.banners.action.error.not-found`)}
      </P>
    );

  return (
    <P>{t(`${namespace}:report.exceptions.banners.action.error.generic`)}</P>
  );
};

export default ErrorResult;
