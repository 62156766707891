import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useNestedPreference } from 'api/dashboardPreferences';
import { namespace } from 'modules/candidate/locales';
import { useTrackEvent, REPORT_SETTING_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

const ShowResolvedExceptions = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const title = t(
    `${namespace}:report.settings.menu.results-page.show-resolved-exceptions`,
  );

  const [showPreference, setShowPreference] = useNestedPreference(
    'report',
    'showResolvedExceptions',
  );

  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleToggle = useCallback(() => {
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_SHOW_RESOLVED_EXCEPTIONS,
      {
        'Show resolved exceptions Selected': !showPreference,
        reportData: reportProperties,
      },
    );

    setShowPreference(!showPreference);
  }, [reportProperties, trackEvent, showPreference, setShowPreference]);

  return (
    <div data-testid='show-resolved-exceptions'>
      <M.ActionMenuItem
        title={title}
        kind='toggle'
        initialChecked={showPreference}
        onToggle={handleToggle}
      />
    </div>
  );
};

export default ShowResolvedExceptions;
