import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React, { useCallback } from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const ProfessionalReferenceVerification = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}: ScreeningProps) => {
  const { onAddClick } = actionProps;

  const handleAddClick = useCallback(() => {
    onAddClick('professional_reference_verification', {
      required_quantity: [true, '1'],
    });
  }, [onAddClick]);

  return (
    <FlexContainer data-testid='professional-employment-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-professional-employment-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Professional reference verification'
            priceByType='professional_reference_verification'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Contact a professional reference the candidate knows.
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text="Vouch for the candidate's work ability." />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='References might not respond or provide all information.' />
              <BulletPoint text="Check the candidate's professional references, not their identity." />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='professional_reference_verification'
          {...actionProps}
          onAddClick={handleAddClick}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default ProfessionalReferenceVerification;
