import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutopayEnabledIcon,
  AutopayEnabledContent,
} from './PaymentStyledComponents';

const AutopayEnabledBadge: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <AutopayEnabledContent>
      <AutopayEnabledIcon>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='renew'>
            <g id='vector'>
              <g id='Union'>
                <path
                  d='M5.07614 6.31295C5.66064 5.30253 6.75157 4.625 8.00001 4.625C8.85638 4.625 9.63692 4.94313 10.2323 5.46859C10.491 5.697 10.886 5.67238 11.1144 5.41358C11.3428 5.15479 11.3182 4.75983 11.0594 4.53141C10.2445 3.81214 9.17254 3.375 8.00001 3.375C6.37683 3.375 4.94968 4.21146 4.125 5.47431V5C4.125 4.65482 3.84518 4.375 3.5 4.375C3.15482 4.375 2.875 4.65482 2.875 5V7C2.875 7.34518 3.15482 7.625 3.5 7.625H5.5C5.84518 7.625 6.125 7.34518 6.125 7C6.125 6.65482 5.84518 6.375 5.5 6.375H5.03521C5.04989 6.35538 5.06357 6.33469 5.07614 6.31295Z'
                  fill='currentColor'
                />
                <path
                  d='M11.8313 10.5914C11.0005 11.8175 9.59487 12.625 8.00001 12.625C6.82748 12.625 5.75553 12.1879 4.9406 11.4686C4.68181 11.2402 4.65718 10.8452 4.8856 10.5864C5.11401 10.3276 5.50897 10.303 5.76777 10.5314C6.3631 11.0569 7.14364 11.375 8.00001 11.375C9.24845 11.375 10.3394 10.6975 10.9239 9.68705C10.9364 9.66531 10.9501 9.64462 10.9648 9.625H10.4563C10.1111 9.625 9.8313 9.34518 9.8313 9C9.8313 8.65482 10.1111 8.375 10.4563 8.375H12.4563C12.8015 8.375 13.0813 8.65482 13.0813 9V11C13.0813 11.3452 12.8015 11.625 12.4563 11.625C12.1111 11.625 11.8313 11.3452 11.8313 11V10.5914Z'
                  fill='currentColor'
                />
              </g>
            </g>
          </g>
        </svg>
      </AutopayEnabledIcon>
      {t(`payment.autopay.enabled`)}
    </AutopayEnabledContent>
  );
};

export default AutopayEnabledBadge;
