import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import T from 'components/Table';
import { Settings } from 'modules/assess/utilities';
import * as Entity from 'modules/assess/models/settings/geo';
import { capitalize } from 'lodash';

type Props = {
  geos: Entity.List;
  setGeos: React.Dispatch<React.SetStateAction<Entity.List>>;
};

export const Table: React.FC<Props> = ({ geos, setGeos }) => {
  const { t } = useTranslation('assess');
  const headers = [
    {
      key: 'name',
      header: t('settings.v1.adverse_action_automation.dialog.geos.table.name'),
    },
    {
      key: 'state',
      header: t(
        'settings.v1.adverse_action_automation.dialog.geos.table.state',
      ),
    },
    {
      key: 'city',
      header: t('settings.v1.adverse_action_automation.dialog.geos.table.city'),
    },
  ];
  const geosSorted = geos.sort((a, b) => a.name.localeCompare(b.name));
  const rows = geosSorted.map(item => ({
    ...item,
    state: item.state,
    city: item.city?.split(' ').map(capitalize).join(' '),
  }));

  const selections = Settings.getGeoSelections(geosSorted);

  const onSelect = useCallback(
    (id: string, selected: boolean) => {
      setGeos(state => {
        return state.map(geo => {
          if (geo.id === id) {
            return {
              ...geo,
              isSelected: selected,
              settings: {
                ...geo.settings,
                adverse_action_automation: {
                  enabled: selected,
                  value: selected
                    ? 'false'
                    : geo.settings.adverse_action_automation.value,
                },
              },
            };
          }
          return geo;
        });
      });
    },
    [setGeos],
  );

  return (
    <T
      data-testid='assess-v1-settings-adverse-action-automation-geos-table'
      disabled={false}
      headers={headers}
      onSelect={onSelect}
      rows={rows}
      selections={selections}
      useSelections
    />
  );
};

export default Table;
