import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import {
  useGetTaskTypeCounts,
  useTaskSearchParams,
} from 'modules/case-management/ui/TasksPage/hooks';
import FilterLabel from '../FilterLabel';

export const TaskType: React.FC = () => {
  const history = useHistory();
  const taskParams = useTaskSearchParams();
  const { t } = useTranslation('case-management');
  const { data } = useGetTaskTypeCounts(); // gets enabled task types

  const selectedItems = taskParams?.task_type
    ? taskParams.task_type.split(',')
    : [];

  const onChange = useCallback(
    ({ selectedItems }) => {
      const selections = selectedItems.map(
        (selection: { id: any }) => selection.id,
      );
      const searchParams = new URLSearchParams({
        ...taskParams,
        page: '1',
      } as Record<string, string>);
      if (selectedItems.length) {
        searchParams.set('task_type', selections.join(','));
      } else {
        searchParams.delete('task_type');
      }
      history.push({
        pathname: TASKS_PATH,
        search: searchParams.toString(),
      });
    },
    [history, taskParams],
  );

  const formatOptions = (options: any) => {
    if (!options.length) return [];

    return options.map((option: string) => ({
      id: option,
    }));
  };

  const taskTypes = data ? Object.keys(data) : [];

  const items = formatOptions(taskTypes);

  const formattedSelections = formatOptions(selectedItems);

  const itemToString = useCallback(
    (item: { id: string }) => {
      return t(`tasks.filters.task_type.options.${item.id}`);
    },
    [t],
  );

  const placeholderText = !formattedSelections.length ? 'All' : '';

  return (
    <M.MultiSelect
      id='case-management-tasks-page-task-type-filter'
      label={placeholderText}
      initialSelectedItems={formattedSelections}
      items={items || []}
      itemToString={itemToString}
      onChange={onChange}
      titleText={<FilterLabel filterType='task_type' />}
    />
  );
};

export default TaskType;
