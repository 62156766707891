import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import { useTranslation } from 'react-i18next';
import { useUpdateAccountSettingsWithoutDispatch } from 'components';
import { useUpdateSettings, useSettings } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, REPORT_SETTING_EVENT_NAMES } from 'utils/analytics';

const ReportSettings: React.FC = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const { account } = user;
  const { call: updateAccountCall } = useUpdateAccountSettingsWithoutDispatch();
  const { t } = useTranslation();

  const title = t(
    `${namespace}:report.settings.menu.results-page.show-report-summary`,
  );

  const settings = useSettings();
  const { hideReportSummary } = settings;

  const updateSettings = useUpdateSettings();

  const toggleReportSummary = useCallback(() => {
    const newValue = !hideReportSummary;
    const changedSettings = { hide_report_summary: newValue };
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_REPORT_VIEW_SHOW_REPORT_SUMMARY_CLICKED,
      {
        'Show Report Summary Clicked': !newValue,
      },
    );

    updateAccountCall({ changedSettings, account });
    account.hide_report_summary = newValue;

    updateSettings({ hideReportSummary: newValue });
  }, [
    hideReportSummary,
    trackEvent,
    updateAccountCall,
    account,
    updateSettings,
  ]);

  return (
    <M.ActionMenuItem
      title={title}
      kind='toggle'
      initialChecked={!hideReportSummary}
      onToggle={toggleReportSummary}
    />
  );
};

export default ReportSettings;
