import React, { useEffect } from 'react';
import { PackageCardProps } from 'components/Signup/types';
import useGetUser from 'components/Signup/hooks/useGetUser';
import { M } from '@dashboard-experience/mastodon';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils';
import { Container } from '../styles';
import BasicPackageCard from './BasicPackageCard';
import CompletePackageCard from './CompletePackageCard';
import EssentialPackageCard from './EssentialPackageCard';
import ProfessionalPackageCard from './ProfessionalPackageCard';

const DomesticPackages = ({
  selectedPackage,
  setSelectedPackage,
}: PackageCardProps) => {
  const { data, isLoading } = useGetUser();
  const trackEvent = useTrackEvent();

  let showCompleteCrim = false;
  let cohorts = [];

  if (data && !isLoading) {
    cohorts = data?.account?.cohorts;
    showCompleteCrim = cohorts?.some(
      (cohort: { name: string }) =>
        cohort.name === 'ab-10-good-better-best-test' ||
        cohort.name === 'ab-11-good-better-best-test-assisted',
    );
  }

  const cardProps = {
    selectedPackage,
    setSelectedPackage,
    showCompleteCrim,
  };

  useEffect(() => {
    if (!isLoading && showCompleteCrim) {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SELECT_PACKAGE_STEP_VIEWED, {
        'Base Packages Offered': 'Basic, Essential, Complete',
      });
    } else {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SELECT_PACKAGE_STEP_VIEWED, {
        'Base Packages Offered': 'Basic, Essential, Professional',
      });
    }
  }, [isLoading, showCompleteCrim, trackEvent]);

  return !isLoading ? (
    <Container data-testid='domestic-packages-container'>
      <BasicPackageCard {...cardProps} />
      <EssentialPackageCard {...cardProps} />
      {showCompleteCrim ? (
        <CompletePackageCard {...cardProps} />
      ) : (
        <ProfessionalPackageCard {...cardProps} />
      )}
    </Container>
  ) : (
    <M.LoadingBlock />
  );
};

export default DomesticPackages;
