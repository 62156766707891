/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PageContainer from 'components/PageContainer';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  accountHasPermission,
  CurrentUser,
  hasPermission,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { Package } from 'types';
import { QueryResult } from 'react-query';
import { useDeletePackage, usePackages } from 'api/packages/hooks';
import DrugScreeningSetupModal from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/DrugScreeningSetupModal';
import CreditReportSetupModal from 'components/AddScreenings/Domestic/List/CreditReportSetupModal';
import OccupationalHealthSetupModal from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/OccupationalHealthSetupModal';
import { useDismissAddonEnablementBanner } from 'api/accounts';
import PackagesTable from './PackagesTable';
import AddonSetupContainer from './ScreeningSetup/AddonSetupContainer';

const SearchContainer = styled(M.Search)`
  width: 500px;
  margin-bottom: 36px !important;
  .cds--search-input {
    color: ${colors.brandSlate6} !important;
    border-bottom: 1px solid ${colors.brandSlate7} !important;
  }
  .cds--search-input::placeholder {
    color: ${colors.brandSlate6};
  }
  .cds--search-magnifier-icon {
    fill: ${colors.brandSlate6};
  }
`;

const PriceAsterisk = styled.div`
  color: ${colors.brandNavy4};
  margin-top: 35px;
  font-size: 14px;
`;

const Margin24 = styled.div`
  margin-bottom: 24px;
`;

const ScrollRef = styled.div``;

type Prop = {
  currentUser?: CurrentUser;
};

const parsePackagesResponse = (
  response: QueryResult<{ [key: string]: any }, Error>,
) => {
  const count = response?.data?.count;
  const packages = response?.data?.data as Package[];
  const packagesLoading = response?.isLoading as boolean;
  const packagesError = response.isError ? response.error : null;

  return {
    count,
    packages,
    packagesLoading,
    packagesError,
  };
};

const PackagesComponent: React.FC<Prop> = ({ currentUser }) => {
  const [showSetupScreenings, setShowSetupScreenings] = useState(true);
  const [showDrugSetupModal, setShowDrugSetupModal] = useState(false);
  const [showCreditSetupModal, setShowCreditSetupModal] = useState(false);
  const [showOccupationHealthSetupModal, setShowOccupationHealthSetupModal] =
    useState(false);

  const { call } = useDismissAddonEnablementBanner();

  const handleDrugSetupClick = useCallback(() => {
    setShowDrugSetupModal(true);
  }, []);

  const handleDrugSetupClose = useCallback(() => {
    setShowDrugSetupModal(false);
  }, []);

  const handleCreditSetupClick = useCallback(() => {
    setShowCreditSetupModal(true);
  }, []);

  const handleCreditSetupClose = useCallback(() => {
    setShowCreditSetupModal(false);
  }, []);

  const handleOccHealthSetupClick = useCallback(() => {
    setShowOccupationHealthSetupModal(true);
  }, []);

  const handleOccHealthSetupClose = useCallback(() => {
    setShowOccupationHealthSetupModal(false);
  }, []);

  const handleCloseClick = useCallback(() => {
    setShowSetupScreenings(false);
    call({ accountId: currentUser?.account?.id, dismissStatus: true });
  }, [call, currentUser?.account?.id]);

  const drugStatus = useMemo(() => {
    return currentUser?.account?.drug_screening_setup;
  }, [currentUser?.account?.drug_screening_setup]);

  const creditStatus = useMemo(() => {
    return currentUser?.account?.credit_report_setup;
  }, [currentUser?.account?.credit_report_setup]);

  const occHealthStatus = useMemo(() => {
    return currentUser?.account?.occ_health_screening_setup;
  }, [currentUser?.account?.occ_health_screening_setup]);

  const isInvitationLinkVisible =
    !accountHasPermission(currentUser, 'segmentation_enabled') &&
    accountHasPermission(currentUser, 'allow_dashboard_report_ordering');
  const isAssignNodeCellVisible =
    accountHasPermission(currentUser, 'hierarchy_present') &&
    hasPermission(currentUser, 'manage_packages');
  const accountId = currentUser?.account?.id;
  const urlSearchParams = new URLSearchParams(window.location.search)
    .getAll('search')
    .toString()
    .split('+')
    .join(' ');
  const [searchParam, setSearchParam] = useState(urlSearchParams || '');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    name: searchParam,
  });

  const searchRef: any = useRef<HTMLDivElement>(null);

  const [apiParams, setApiParams] = useState(
    new URLSearchParams({
      page: params.page.toString(),
      per_page: params.per_page.toString(),
      name: params.name,
    }),
  );

  const showSetupBanner =
    hasPermission(currentUser, 'manage_account_settings') &&
    !currentUser?.account?.dismiss_addon_enablement_banner &&
    showSetupScreenings;

  useEffect(() => {
    setApiParams(
      new URLSearchParams({
        page: params.page.toString(),
        per_page: params.per_page.toString(),
        name: params.name,
      }),
    );
  }, [params]);

  const response = usePackages(accountId, apiParams);
  const { deleteCall } = useDeletePackage(response.refetch);
  const { count, packages, packagesLoading, packagesError } =
    parsePackagesResponse(response);

  const fetchedPackages: Package[] = useMemo(() => packages || [], [packages]);

  useEffect(() => {
    if (packages?.length === 0 && currentPageIndex !== 0) {
      setApiParams(
        new URLSearchParams({
          page: '1',
          per_page: '25',
          name: '',
        }),
      );
    }
  }, [currentPageIndex, fetchedPackages, packages?.length]);

  const currentPackages = useMemo(
    () =>
      fetchedPackages?.filter((currentPackage: Package) =>
        currentPackage.name
          ?.toLocaleLowerCase()
          .includes(searchParam.toLocaleLowerCase()),
      ),
    [fetchedPackages, searchParam],
  );

  const debouncedSearch = useDebouncedCallback(
    value => {
      setParams({ page: 1, per_page: 10, name: value });
      setCurrentPageIndex(0);
    },
    350,
    [accountId],
  );

  const handleSearchChange = useCallback(
    (e: any) => {
      setSearchParam(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const handlePageClick = useCallback(
    (pageIndex: number) => {
      setParams({ ...params, page: pageIndex + 1 });
      setCurrentPageIndex(pageIndex);
    },
    [params],
  );

  if (packagesError) {
    return (
      <PageContainer>
        <ScrollRef ref={searchRef} />
        <SearchContainer type='text' disabled />
        <PackagesTable
          accountId={accountId}
          currentUser={currentUser}
          packages={[]}
          isInvitationLinkVisible={isInvitationLinkVisible}
          isAssignNodeCellVisible={isAssignNodeCellVisible}
          deletePackage={deleteCall}
          scrollIntoView={() => {
            searchRef?.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            });
          }}
        />
      </PageContainer>
    );
  }
  packagesLoading && (
    <PageContainer>
      <SearchContainer type='text' disabled />
      <M.LoadingInline />
    </PageContainer>
  );

  return (
    <PageContainer data-testid='packages-container'>
      {showDrugSetupModal && (
        <DrugScreeningSetupModal
          open={showDrugSetupModal}
          handleClose={handleDrugSetupClose}
        />
      )}
      {showCreditSetupModal && (
        <CreditReportSetupModal
          open={showCreditSetupModal}
          handleClose={handleCreditSetupClose}
        />
      )}
      {showOccupationHealthSetupModal && (
        <OccupationalHealthSetupModal
          open={showOccupationHealthSetupModal}
          handleClose={handleOccHealthSetupClose}
        />
      )}
      {showSetupBanner && (
        <AddonSetupContainer
          pendingAddons={
            currentUser?.account?.pending_addon_enablement_processes
          }
          drugSetupStatus={drugStatus}
          creditSetupStatus={creditStatus}
          occHealthSetupStatus={occHealthStatus}
          handleDrugSetupClick={handleDrugSetupClick}
          handleCreditSetupClick={handleCreditSetupClick}
          handleOccHealthSetupClick={handleOccHealthSetupClick}
          handleCloseClick={handleCloseClick}
        />
      )}
      <Margin24 />
      <ScrollRef ref={searchRef} />
      <SearchContainer
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
      />
      {packagesLoading ? (
        <M.LoadingInline />
      ) : (
        <PackagesTable
          accountId={accountId}
          currentUser={currentUser}
          packages={currentPackages}
          isInvitationLinkVisible={isInvitationLinkVisible}
          isAssignNodeCellVisible={isAssignNodeCellVisible}
          deletePackage={deleteCall}
          scrollIntoView={() =>
            searchRef?.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            })
          }
        />
      )}
      {count > 10 && currentPackages.length > 0 && (
        <M.Pagination
          data-testid='packages-pagination'
          pageCount={Math.ceil(count / params.per_page)}
          selectedIndex={currentPageIndex}
          onPageClick={handlePageClick}
        />
      )}
      <PriceAsterisk>
        *
        <a
          href='https://checkr.com/pricing/pass-through-fees#state-fees'
          target='_blank'
          rel='noreferrer'
        >
          Additional passthrough fees might apply.
        </a>
      </PriceAsterisk>
    </PageContainer>
  );
};

export default PackagesComponent;
