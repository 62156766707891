import React from 'react';
import moment from 'moment';
import {
  StyledBadgeDiv,
  StyledValidBadgeDiv,
  StyledInvalidBadgeDiv,
  StyledWarningBadgeDiv,
} from './PaymentStyledComponents';

type Props = {
  status: string;
  expirationDate: string;
};

const TaxCertificateStatusBadge: React.FC<Props> = ({
  status,
  expirationDate,
}) => {
  const currentDate = moment();
  const expiration = moment(expirationDate);

  const isExpiredCertificate = (): boolean => {
    return currentDate.isAfter(expiration);
  };

  const isExpiringSoon = (): boolean => {
    return currentDate.isAfter(expiration.clone().subtract(1, 'month'));
  };

  let statusBadge;

  switch (status) {
    case 'valid':
      if (isExpiredCertificate()) {
        statusBadge = (
          <StyledInvalidBadgeDiv data-testid='tec-expired-badge'>
            Expired
          </StyledInvalidBadgeDiv>
        );
      } else if (isExpiringSoon()) {
        statusBadge = (
          <StyledWarningBadgeDiv data-testid='tec-warning-badge'>
            Expires&nbsp;soon
          </StyledWarningBadgeDiv>
        );
      } else {
        statusBadge = (
          <StyledValidBadgeDiv data-testid='tec-valid-badge'>
            Valid
          </StyledValidBadgeDiv>
        );
      }
      break;
    case 'invalid':
      statusBadge = (
        <StyledInvalidBadgeDiv data-testid='tec-invalid-badge'>
          Invalid
        </StyledInvalidBadgeDiv>
      );
      break;
    case 'in_review':
      statusBadge = (
        <StyledBadgeDiv data-testid='tec-in-review-badge'>
          Pending&nbsp;Approval
        </StyledBadgeDiv>
      );
      break;
    default:
      statusBadge = (
        <StyledBadgeDiv data-testid='tec-in-review-badge'>
          Unknown
        </StyledBadgeDiv>
      );
  }
  return statusBadge;
};
export default TaxCertificateStatusBadge;
