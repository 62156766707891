import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Trans, useTranslation } from 'react-i18next';
import * as Entity from 'modules/assess/models/settings/account';
import styled from 'styled-components';

type Props = {
  name: 'geos' | 'packages';
  disabled?: boolean;
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const Description = styled.div`
  margin-bottom: 0.75rem;
  margin-left: 2.75rem;
`;

export const SubSettingToggle: React.FC<Props> = ({
  disabled = false,
  name,
  settings,
  setSettings,
}) => {
  const { t } = useTranslation('assess');
  const dataTestId = `assess-v1-settings-adverse-action-automation-${name}`;
  const description = t(
    `settings.v1.adverse_action_automation.sub_settings.${name}.description`,
  );
  const title = t(
    `settings.v1.adverse_action_automation.sub_settings.${name}.title`,
  );
  const label = (
    <p className={`settings-label ${disabled ? 'disabled' : ''}`}>{title}</p>
  );
  const value = settings?.adverse_action_automation?.value?.[name];

  const handleToggle = useCallback(() => {
    let newValue: boolean | null;
    // Currently, geos can only be true or null while
    // packages can only be false and null
    if (name === 'geos') {
      newValue = !value ? true : null;
    } else {
      newValue = value === false ? null : false;
    }

    setSettings((state: Entity.Type) => ({
      ...state,
      adverse_action_automation: {
        ...state.adverse_action_automation,
        value: {
          ...state.adverse_action_automation?.value,
          [name]: newValue,
        },
      },
    }));
  }, [name, setSettings, value]);

  return (
    <div className='sub-setting'>
      <M.Toggle
        data-testid={`${dataTestId}-toggle`}
        disabled={disabled}
        onToggle={handleToggle}
        toggled={name === 'geos' ? value !== null : value !== null && !value}
        toggledText={label}
        untoggledText={label}
        role='input'
      />
      <Description data-testid={`${dataTestId}-description`}>
        <Trans>{description}</Trans>
      </Description>
    </div>
  );
};

export default SubSettingToggle;
