import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { NEW_SUMMARY_COMPONENT } from 'Constants';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import {
  usePackagePrice,
  useAllAddOnPricesFromSkus,
} from 'components/AddScreenings/hooks/usePackagePrice';
import DomesticAddScreeningsList from 'components/AddScreenings/Domestic/List';
import InternationalAddScreeningsList from 'components/AddScreenings/International/List';
import DomesticAddScreeningsSummary from 'components/AddScreenings/Domestic/Summary';
import DomesticAddScreeningsSummaryV2 from 'components/AddScreenings/Domestic/SummaryV2';
import InternationalAddScreeningsSummary from 'components/AddScreenings/International/Summary';
import { Screening } from 'components/AddScreenings/types/Screenings';
import { getDisabledScreenings } from 'components/AddScreenings/AddScreeningList';
import PopularAddOns from 'components/AddScreenings/Domestic/PopularAddOns';
import * as utils from 'components/AddScreenings/shared/utils';
import {
  ActionType,
  DOMESTIC_GEO,
  INTERNATIONAL_GEO,
  SKIPPED_NAME,
  useCreatePackageContext,
} from './Context';
import { AliasesEnabledType } from '../Alias/types';

const OuterContainer = styled.div`
  max-width: 1200px;
`;

const InnerContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;

  @media (min-width: 672px) {
    flex-direction: row;
  }
`;

const ScreeningsSection = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  margin-bottom: 24px !important;

  @media (min-width: 672px) {
    flex: 1 1 65%;
    border-bottom: 0;
  }

  @media (max-width: 672px) {
    min-height: 400px !important;
  }
`;

const SummaryContainer = styled.div<SummaryContainerProps>`
  min-width: 375px !important;
  padding-bottom: 60px !important;
  position: relative;
  top: ${({ top }) => top}px;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (max-width: 672px) {
    padding-top: 50px !important;
  }
`;

const SummarySection = styled.div<SummarySectionProps>`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 24px;
  padding-bottom: 32px !important;
  background-color: ${colors.uiGrey0};
  margin-bottom: 50px !important;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (min-width: 672px) {
    min-width: 375px;
    max-width: 375px;
    flex: 1 1 35%;
    margin-left: 16px;
    overflow-y: auto;
  }
`;

const SaveForNextTimeContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 16px;

  border: 1px solid ${colors.uiGrey200};
  border-radius: 10px;
  padding: 0 20px 10px 20px;
  background-color: ${colors.uiNavy50};

  @media (min-width: 672px) {
    flex: 1 1 35%;
  }
`;

const SavePackageNameContainer = styled.div`
  margin-top: 16px;
`;

const NameInput = styled(M.TextInput)`
  .cds--text-input {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      background-color: ${colors.uiGrey0};
      transition: background-color 9999s ease-in-out 0s;
    }
  }
  .cds--label {
    margin-bottom: 0.5rem !important;
  }
`;

const Margin16 = styled.div`
  margin-top: 16px;
`;

const ADDITIONAL_PACKAGE_SUMMARY_PADDING = 24;

type SummaryContainerProps = {
  top: number;
  maxHeight: number;
};

type SummarySectionProps = {
  maxHeight: number;
};

const InternationalDisclaimer = () => {
  const StyledDiv = styled.div`
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    color: ${colors.brandNavy3};
  `;

  return (
    <StyledDiv>
      <M.Link
        style={{ color: colors.brandNavy1 }}
        href='https://checkr.com/pricing/international'
        target='_blank'
        rel='noreferrer'
      >
        Prices and turnaround times vary by location.
      </M.Link>
    </StyledDiv>
  );
};

type CustomizeProps = {
  windowOffsetY: number;
  windowInnerHeight: number;
  goBack?: Function;
  getPrices?: Function;
};

const Customize = ({
  windowOffsetY,
  windowInnerHeight,
  goBack,
  getPrices,
}: CustomizeProps) => {
  const [packageNameDirty, setPackageNameDirty] = useState(false);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const packageSummaryRef = useRef<HTMLDivElement>(null);
  const [packageSummaryOffsetTop, setPackageSummaryOffsetTop] = useState(0);
  const [packageSummaryTop, setPackageSummaryTop] = useState(0);
  const {
    reducerState,
    account,
    search: { screeningTypesInSearch, searchString },
    basePackageScreeningTypes,
    functions: {
      dispatch,
      addScreeningToReducerState,
      addPackageNameToReducerState,
      removeScreeningFromReducerState,
    },
  } = useCreatePackageContext();

  const {
    addedScreeningTypes,
    additionalProperties,
    newPackageName,
    newPackageNameInvalid,
    basePackage,
    selectedGeo,
    error,
  } = reducerState;

  const screeningAdded = debounce(
    useCallback(
      (screeningType, additionalProperties) => {
        addScreeningToReducerState({
          type: screeningType,
          additionalProperties,
        } as unknown as Screening);
      },
      [addScreeningToReducerState],
    ),
    200,
    { leading: false },
  );

  const screeningRemoved = useCallback(
    screeningType => removeScreeningFromReducerState(screeningType),
    [removeScreeningFromReducerState],
  );

  // query for the price of the initial package with no-add ons
  // this data populates the base price in the summary
  const basePackagePricesQuery = usePackagePrice(
    ['base-package-prices', basePackage.name],
    account,
    basePackage,
  );

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...basePackage,
        aliases_enabled: AliasesEnabledType.OFF,
      },
      addedScreeningTypes: reducerState.addedScreeningTypes,
      additionalProperties: reducerState.additionalProperties,
      packageName: basePackage.name,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    basePackage,
    reducerState.addedScreeningTypes,
    reducerState.additionalProperties,
  ]);

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
    ],
    account,
    {
      ...buildPostBodyWithAddOns(),
    },
  );

  const buildPostBodyWithAddOnsAlias = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...basePackage,
        aliases_enabled: AliasesEnabledType.ON,
      },
      addedScreeningTypes: reducerState.addedScreeningTypes,
      additionalProperties: reducerState.additionalProperties,
      packageName: basePackage.name,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    basePackage,
    reducerState.addedScreeningTypes,
    reducerState.additionalProperties,
  ]);

  const pendingAddOnPricesQueryAlias = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    account,
    {
      ...buildPostBodyWithAddOnsAlias(),
    },
  );

  const defaultHandlePackageNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      (SKIPPED_NAME !== e.target.value || (e.target.value as string) === '') &&
        addPackageNameToReducerState(e.target.value);
      setPackageNameDirty(true);
    },
    [addPackageNameToReducerState],
  );

  useEffect(() => {
    if (windowOffsetY <= packageSummaryOffsetTop) {
      setPackageSummaryTop(0);
      return;
    }

    setPackageSummaryTop(
      windowOffsetY +
        ADDITIONAL_PACKAGE_SUMMARY_PADDING -
        packageSummaryOffsetTop,
    );
  }, [packageSummaryOffsetTop, windowOffsetY]);

  useEffect(() => {
    if (packageSummaryRef.current) {
      setPackageSummaryOffsetTop(packageSummaryRef.current.offsetTop);
    }
  }, []);

  const [initialAdditionalProperties, setInitialAdditionalProperties] =
    useState({});

  const disabledScreenings = getDisabledScreenings(basePackageScreeningTypes);

  // query for the price of all possible add ons
  // this data populates each individual screening price in the list section
  const allAddOnPricesQuery = useAllAddOnPricesFromSkus(account?.id);
  const mappedSkusQuery = utils.mapAddonSkus(allAddOnPricesQuery);

  const handleAliasClick = useCallback(() => {
    const isNotOn =
      basePackage.aliases_enabled !== AliasesEnabledType.ON &&
      reducerState.aliasesEnabled !== AliasesEnabledType.ON;
    if (reducerState.aliasesEnabled === AliasesEnabledType.ON) {
      dispatch({
        type: ActionType.SET_ALIASES_PRICE,
        payload: {
          aliasPrice: 0,
        },
      });
    }

    if (isNotOn) {
      dispatch({
        type: ActionType.SET_ALIASES_ENABLED,
        payload: { aliasesEnabled: AliasesEnabledType.ON },
      });
    } else {
      dispatch({
        type: ActionType.SET_ALIASES_ENABLED,
        payload: {
          aliasesEnabled: basePackage.aliases_enabled,
        },
      });
    }
  }, [basePackage.aliases_enabled, dispatch, reducerState.aliasesEnabled]);

  const setAliasPrice = useCallback(
    (aliasPrice: number) => {
      if (aliasPrice) {
        dispatch({
          type: ActionType.SET_ALIASES_PRICE,
          payload: {
            aliasPrice,
          },
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    let additionalProperties = {};

    const [facisScreening] = basePackage.screenings.filter(
      screening => screening.type === 'facis_search',
    );
    const [employmentVerification] = basePackage.screenings.filter(
      screening => screening.type === 'employment_verification',
    );
    const [personalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'personal_reference_verification',
    );
    const [professionalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'professional_reference_verification',
    );

    if (facisScreening?.type && 'subtype' in facisScreening) {
      additionalProperties = {
        ...additionalProperties,
        facis_search: { subtype: facisScreening.subtype },
      };
    }

    if (
      employmentVerification?.type &&
      'maximum_allowed_employers' in employmentVerification &&
      !isNaN(employmentVerification.maximum_allowed_employers as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        employment_verification: {
          maximum_allowed_employers:
            employmentVerification.maximum_allowed_employers,
        },
      };
    }

    if (
      personalReferenceVerification?.type &&
      'required_quantity' in personalReferenceVerification &&
      !isNaN(personalReferenceVerification.required_quantity as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        personal_reference_verification: {
          required_quantity: personalReferenceVerification.required_quantity,
        },
      };
    }

    if (
      professionalReferenceVerification?.type &&
      'required_quantity' in professionalReferenceVerification &&
      !isNaN(professionalReferenceVerification.required_quantity as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        professional_reference_verification: {
          required_quantity:
            professionalReferenceVerification.required_quantity,
        },
      };
    }

    setInitialAdditionalProperties(additionalProperties);
  }, [basePackage.screenings]);

  const newSummaryComponentFlag =
    useFlag(NEW_SUMMARY_COMPONENT)?.variantKey === 'on';

  const domesticScreeningSummary = newSummaryComponentFlag ? (
    <DomesticAddScreeningsSummaryV2
      basePackage={basePackage}
      basePackagePrices={basePackagePricesQuery.data}
      basePackageScreeningTypes={basePackageScreeningTypes}
      pendingAddOnPricesQuery={pendingAddOnPricesQuery}
      pendingAddOnPricesQueryAlias={pendingAddOnPricesQueryAlias}
      hasAddedScreenings={Boolean(addedScreeningTypes.length)}
      additionalProperties={additionalProperties}
      goBack={goBack}
      handleAliasClick={handleAliasClick}
      aliasEnabled={reducerState.aliasesEnabled}
      setAliasPrice={setAliasPrice}
      getPrices={getPrices}
    />
  ) : (
    <DomesticAddScreeningsSummary
      basePackage={basePackage}
      basePackagePrices={basePackagePricesQuery.data}
      basePackageScreeningTypes={basePackageScreeningTypes}
      pendingAddOnPricesQuery={pendingAddOnPricesQuery}
      pendingAddOnPricesQueryAlias={pendingAddOnPricesQueryAlias}
      hasAddedScreenings={Boolean(addedScreeningTypes.length)}
      additionalProperties={additionalProperties}
      goBack={goBack}
      handleAliasClick={handleAliasClick}
      aliasEnabled={reducerState.aliasesEnabled}
      setAliasPrice={setAliasPrice}
      getPrices={getPrices}
    />
  );

  return (
    <OuterContainer>
      {selectedGeo !== DOMESTIC_GEO && <InternationalDisclaimer />}
      <InnerContainer type='transparent'>
        <ScreeningsSection>
          {selectedGeo === DOMESTIC_GEO && (
            <>
              <PopularAddOns
                onAddClick={screeningAdded}
                onRemoveClick={screeningRemoved}
                addedScreenings={addedScreeningTypes}
                disabledScreenings={disabledScreenings}
                additionalProperties={additionalProperties}
                allAddOnPricesQuery={mappedSkusQuery}
                basePackageScreenings={basePackage.screenings}
              />
              <Margin16 />
            </>
          )}

          {selectedGeo === DOMESTIC_GEO && (
            <DomesticAddScreeningsList
              onAddClick={screeningAdded}
              onRemoveClick={screeningRemoved}
              disabledScreenings={disabledScreenings}
              screeningTypesInSearch={screeningTypesInSearch}
              searchString={searchString}
              addedScreenings={addedScreeningTypes}
              additionalProperties={{
                ...additionalProperties,
                ...initialAdditionalProperties,
              }}
              itemPrices={mappedSkusQuery}
              basePackage={basePackage}
              screeningSettingPrice={pendingAddOnPricesQuery?.data?.items || []}
            />
          )}
          {selectedGeo === INTERNATIONAL_GEO && (
            <InternationalAddScreeningsList
              onAddClick={screeningAdded}
              onRemoveClick={screeningRemoved}
              disabledScreenings={disabledScreenings}
              screeningTypesInSearch={screeningTypesInSearch}
              addedScreenings={addedScreeningTypes}
              additionalProperties={{
                ...additionalProperties,
                ...initialAdditionalProperties,
              }}
              itemPrices={mappedSkusQuery}
              basePackage={basePackage}
            />
          )}
        </ScreeningsSection>
        <SummaryContainer
          ref={packageSummaryRef}
          top={packageSummaryTop}
          maxHeight={windowInnerHeight - ADDITIONAL_PACKAGE_SUMMARY_PADDING}
        >
          <SummarySection
            data-testid='package-summary'
            maxHeight={
              windowInnerHeight - 2 * ADDITIONAL_PACKAGE_SUMMARY_PADDING
            }
          >
            {selectedGeo === DOMESTIC_GEO ? (
              domesticScreeningSummary
            ) : (
              <InternationalAddScreeningsSummary
                basePackage={basePackage}
                basePackageScreeningTypes={basePackageScreeningTypes}
                pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                hasAddedScreenings={Boolean(addedScreeningTypes.length)}
                additionalProperties={additionalProperties}
                goBack={goBack}
              />
            )}
            <div>
              <SaveForNextTimeContainer data-testid='save-for-next-time-container'>
                <SavePackageNameContainer>
                  <NameInput
                    ref={nameFieldRef}
                    data-testid='name-your-package-textinput'
                    id='name-your-package-textinput'
                    labelText='Name your package'
                    value={newPackageName}
                    invalid={
                      error || (packageNameDirty && newPackageNameInvalid)
                    }
                    onChange={defaultHandlePackageNameChange}
                    invalidText={
                      error ||
                      'Package name cannot be blank or include special characters'
                    }
                  />
                </SavePackageNameContainer>
              </SaveForNextTimeContainer>
            </div>
          </SummarySection>
        </SummaryContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

export default Customize;
