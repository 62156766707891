/* eslint-disable react/jsx-no-bind */
import React, { useState, useMemo, useEffect } from 'react';
import { uniq } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Flex } from 'components/Packages/OrderBackgroundCheck/SharedItems';
import { useUser } from 'context/CurrentUser';
import SelectCity from 'components/Packages/InvitationLink/DropdownSelections/SelectCity';
import SelectState from 'components/Packages/InvitationLink/DropdownSelections/SelectState';
import SelectNode from 'components/Packages/InvitationLink/DropdownSelections/SelectNode';

const MainInfo = styled.p`
  padding-right: 0px !important;
`;

const Margin = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`;

const LocationFlexContainer = styled(Flex)``;

const ShowLinkModal: React.FC<any> = ({
  applyUrl,
  packageName,
  setShowModal,
  showModal,
}) => {
  const currentUser = useUser();
  const { account, geos } = currentUser;
  const { segmentation_enabled, hierarchy_present } = account;

  // list of countries from customer geos
  const countries: string[] = useMemo(
    () =>
      uniq(
        geos
          .filter((geo: any) => geo.country)
          .map((geo: any) => geo.country)
          .concat(['US']),
      ),
    [geos],
  );
  // is domestic if US is the only country in the list
  const isDomestic = countries.includes('US');
  const invitationLinkForHierarchyEnabled = segmentation_enabled && isDomestic;

  const [linkUrl, setLinkUrl] = useState(applyUrl);
  const [isCopied, setIsCopied] = useState(false);

  const [selectedNode, setSelectedNode] = useState<{
    custom_id: string;
    parent_custom_id: string;
    tier: string;
    name: string;
    id: string;
  }>({ custom_id: '', parent_custom_id: '', tier: '', name: '', id: '' });
  const [selectedCity, setSelectedCity] = useState<{
    name: string;
    state: string;
  }>({ name: '', state: '' });
  const [selectedState, setSelectedState] = useState<{
    name: string;
    abbreviation: string;
    id: string;
  }>({ name: '', abbreviation: '', id: '' });
  const queryParamKeys = {
    city: 'wl_city',
    node: 'node_id',
    state: 'wl_state',
  };

  // used to create the query params for the link
  const createQueryParam = (key: string, value: string) => {
    if (!value) return '';
    return `${key}=${encodeURIComponent(value)}`;
  };

  // create the link url when the node, state, or city is selected
  useEffect(() => {
    const nodeQueryParam = createQueryParam(
      queryParamKeys.node,
      selectedNode.custom_id,
    );
    const stateQueryParam = createQueryParam(
      queryParamKeys.state,
      selectedState.abbreviation,
    );
    const cityQueryParam = createQueryParam(
      queryParamKeys.city,
      selectedCity.name,
    );

    const queryParams = [nodeQueryParam, stateQueryParam, cityQueryParam];
    const filteredQueryParams = queryParams.filter(param => param !== '');
    const joinedQueryParams = filteredQueryParams.join('&');

    if (filteredQueryParams.length) {
      setLinkUrl(`${applyUrl}?${joinedQueryParams}`);
    } else {
      setLinkUrl(applyUrl);
    }
  }, [
    applyUrl,
    queryParamKeys.city,
    queryParamKeys.state,
    queryParamKeys.node,
    selectedNode,
    selectedState,
    selectedCity,
  ]);

  // reset the selected  state, and city when hierarchy is present
  // and the node is deselected
  useEffect(() => {
    if (hierarchy_present && !selectedNode.custom_id) {
      setSelectedCity({ name: '', state: '' });
      setSelectedState({ name: '', abbreviation: '', id: '' });
    }
  }, [selectedNode, hierarchy_present]);

  // For CopyField view
  const nodeAndStateSelected =
    selectedNode.custom_id && selectedState.abbreviation;
  const stateSelected = selectedState.abbreviation;

  const copyField = (
    <M.CopyField
      text={linkUrl}
      data-testid='copy-invitation-link'
      copy={(element: HTMLElement) => {
        navigator.clipboard.writeText(element.innerHTML.replace(/&amp;/g, '&'));
        setIsCopied(true);
      }}
      style={{ maxWidth: '45rem' }}
    />
  );

  const shouldShowCopyField = () => {
    // When segmentation is disabled, always show copy field
    if (!segmentation_enabled) return true;

    // When segmentation is enabled and hierarchy isn't present, only require state selection
    if (segmentation_enabled && !hierarchy_present) return stateSelected;

    // When segmentation and hierarchy are enabled, require node and state selection
    if (segmentation_enabled && hierarchy_present) return nodeAndStateSelected;

    return false;
  };

  // Applying the condition to the JSX rendering
  const copyFieldBlock = shouldShowCopyField() && copyField;

  return (
    <M.ComposedModal open={showModal} onClose={() => setShowModal(false)}>
      <M.ModalHeader closeModal={() => setShowModal(false)}>
        <h2>Copy link</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div>
          <MainInfo>
            Use the link below to schedule a post-accident or reasonable
            suspicion test. Your monthly invoice will show only completed tests.
          </MainInfo>
          {/* segmentation enabled */}
          {invitationLinkForHierarchyEnabled && segmentation_enabled && (
            <LocationFlexContainer>
              {hierarchy_present && (
                <SelectNode
                  account={account}
                  selectedNode={selectedNode}
                  setSelectedNode={setSelectedNode}
                />
              )}
              <SelectState
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
              <SelectCity
                state={selectedState.abbreviation}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                isStateSet={!!selectedState.abbreviation}
              />
            </LocationFlexContainer>
          )}
          {isCopied && (
            <M.InlineNotification
              kind='success'
              title=''
              onCloseButtonClick={() => setIsCopied(false)}
            >
              Link copied
            </M.InlineNotification>
          )}
          <Margin>{packageName}</Margin>
          {copyFieldBlock}
        </div>
      </M.ModalBody>
      <M.ModalFooter>
        <p>
          By clicking above, I certify that: (1) I have reviewed and approved of
          all documents that will be presented to the candidates; (2) I have a
          permissible purpose to request a consumer report on the candidates;
          and (3) my use of consumer reports will comply with all applicable
          laws.
        </p>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ShowLinkModal;
