import { Geo } from '@dashboard-experience/utils';
import * as Countries from 'i18n-iso-countries';
import { US_STATES } from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import type { GetStartedT } from '..';

export const countryToString = (country: string) => {
  return Countries.getName(country, 'en') || country || '';
};

export const getGeoWorkLocation = (geo: Geo) => {
  return geo.country ? countryToString(geo.country) : geo.name ?? '';
};

export const getWorkLocation = (location: GetStartedT.WorkLocation) => {
  if (
    location.country !== 'US' ||
    (location.country === 'US' && !location.state)
  )
    return countryToString(location.country as string);

  if (!location.city || location.city === '')
    return getStateNameByAbbreviation(location.state as string);

  return `${location.city}, ${location.state}`;
};

export const getStateNameByAbbreviation = (abbreviation: string): string => {
  const state = US_STATES.find(state => state.abbreviation === abbreviation);
  return state ? state.name : '';
};
