import React from 'react';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../../types';

const CreatedAt: React.FC<FieldComponentProps> = ({ candidate, testid }) => {
  return (
    <div data-testid={testid}>
      {dateWithTimezone(candidate.created_at || '')}
    </div>
  );
};

export default CreatedAt;
