import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Entity from 'modules/assess/models/settings/geo';
import Table from './table';

type Props = {
  geos: Entity.List;
  setGeos: React.Dispatch<React.SetStateAction<Entity.List>>;
};

const components = {
  'screenings-tab-link': <Link to='screening/geos'>here</Link>,
};

export const Content: React.FC<Props> = ({ geos, setGeos }) => {
  const { t } = useTranslation('assess');
  const header = t(
    'settings.v1.adverse_action_automation.dialog.geos.content_header',
  );
  return (
    <>
      <Trans i18nKey={header} t={t} components={components} />
      <Table geos={geos} setGeos={setGeos} />
    </>
  );
};

export default Content;
