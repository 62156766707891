import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { SectionProps } from '../types';
import { ExpandablePanel, KeyValueList } from '../styles';

const Nodes: React.FC<SectionProps> = ({ report }) => {
  const generateKeyValueItems = (segments: string[] = []) => {
    if (!segments.length) {
      return [];
    }

    const nodeValue = segments
      .map(segment => {
        const [prop, value] = segment.split('|');
        if (prop && value) {
          return `${capitalize(prop)}: ${value}`;
        }
        if (prop) {
          return capitalize(prop);
        }
        if (value) {
          return value;
        }
        return '';
      })
      .join('<br />');

    return [
      {
        itemKey: 'Node:',
        itemValue: nodeValue,
      },
    ];
  };

  const items = useMemo(
    () => generateKeyValueItems(report.segment_stamps || []),
    [report.segment_stamps],
  );

  return (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='nodes-expandable-panel'
      title='Nodes'
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={items.map(({ itemKey, itemValue }) => ({
          itemKey,
          itemValue: <span dangerouslySetInnerHTML={{ __html: itemValue }} />,
        }))}
      />
    </ExpandablePanel>
  );
};

export default Nodes;
