import { createContext, useContext } from 'react';

export const FlowNames = {
  ORDER_BACKGROUND_CHECK: 'ORDER_BACKGROUND_CHECK',
  SIGNUP_FLOW: 'SIGNUP_FLOW',
} as const;

export type FlowName = keyof typeof FlowNames;

export interface AmplitudeFlowData {
  [key: string]: Record<string, any>;
}

interface AmplitudeContextProps {
  amplitudeData: AmplitudeFlowData;
  updateFlowData: (
    flowName: FlowName,
    newEventData: Record<string, any>,
  ) => void;
  clearFlowData: (flowName: FlowName) => void;
}

const AmplitudeContext = createContext<AmplitudeContextProps | undefined>(
  undefined,
);

export default AmplitudeContext;

export const useAmplitudeData = (): AmplitudeContextProps => {
  const context = useContext(AmplitudeContext);
  if (!context) {
    throw new Error(
      'useAmplitudeData must be used within an AmplitudeProvider',
    );
  }
  return context;
};
