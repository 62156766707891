import React from 'react';
import { FieldComponentProps } from '../../types';

const buildFullname = ({
  full_name,
  first_name,
  middle_name,
  last_name,
}: {
  full_name?: null | string;
  first_name?: null | string;
  middle_name?: null | string;
  last_name?: null | string;
}) => {
  // If the candidate already has a properly-established full name field, then simply return that
  if (full_name) return full_name;

  // Otherwise, build up a full name based on the first/middle/last parts:
  const name = [];
  if (first_name) {
    name.push(first_name);
  }
  if (middle_name) {
    name.push(middle_name);
  }
  if (last_name) {
    name.push(last_name);
  }

  if (name.length === 0) return '-';

  return name.join(' ');
};

const FullName: React.FC<FieldComponentProps> = ({ candidate, testid }) => {
  if (!candidate) {
    return null;
  }

  return <div data-testid={testid}>{buildFullname(candidate)}</div>;
};

export default FullName;
