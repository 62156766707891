import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  localStorageFallback,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { useUpdateAccountSettings } from 'components/AccountSettings';
import {
  ALIASES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import AliasRadioSelection from './AliasRadioSelection';

const Container = styled.div`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 30px;
  margin: 24px auto;
  box-shadow: 0px 4px 32px -16px rgba(26, 32, 38, 0.1);
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 20px !important;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid ${colors.brandSlate3};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-left: auto;
  &#hide-modal-checkbox-container-unauthorized {
    margin-left: 0;
  }
  p {
    margin-right: 12px;
  }
  .cds--checkbox-label .cds--checkbox-label-text {
    padding-left: 0 !important;
  }
`;

const LoadingInline = styled(M.LoadingInline)`
  width: unset !important;
  svg {
    fill: #fff !important;
  }
`;

const CanUpdateAccountContent: React.FC<{
  aliasAutoEnableSetting: boolean;
  aliasEnabled: boolean;
  handleUpdateSettingClick: (aliasAutoEnableSetting: boolean) => void;
  onSave: () => void;
  isLoading?: boolean | string;
}> = ({
  aliasAutoEnableSetting,
  aliasEnabled,
  handleUpdateSettingClick,
  onSave,
  isLoading,
}) => {
  const [isHideDefaultAliasChecked, setIsHideDefaultAliasChecked] =
    useState(false);

  const handleCheck = useCallback(
    e => {
      setIsHideDefaultAliasChecked(!isHideDefaultAliasChecked);
      localStorageFallback.setItem(
        'hideDefaultAliasModal',
        (!isHideDefaultAliasChecked).toString(),
      );
    },
    [isHideDefaultAliasChecked],
  );

  return (
    <>
      <InfoContainer>
        <Header className='h5'>Alias searches</Header>
        <Description>
          <p>
            You{' '}
            <Bold data-testid='alias-setting-selection-bold-text-1'>
              {aliasEnabled ? 'added' : 'removed'}
            </Bold>{' '}
            alias searches {aliasEnabled ? 'to' : 'from'} this order. Do you
            want to search aliases for future orders?
          </p>
          <p>
            You can change your decision in <Bold>Account &gt; Settings</Bold>{' '}
            or in the <Bold>Customize</Bold> step of each order.
          </p>
        </Description>
        <AliasRadioSelection
          aliasAutoEnableSetting={aliasAutoEnableSetting}
          setDefaultAlias={handleUpdateSettingClick}
          source='Modal'
        />
      </InfoContainer>
      <ButtonContainer>
        {!isLoading && (
          <M.Button kind='secondary' onClick={onSave}>
            Update setting
          </M.Button>
        )}
        {isLoading && (
          <LoadingInline id='loading-button' description='Updating...' />
        )}
        <CheckboxContainer>
          <M.Checkbox
            data-testid='hide-modal-checkbox'
            id='hide-modal-checkbox'
            kind='primary'
            labelText={
              <p
                style={{ paddingLeft: '12px' }}
                data-testid='hide-modal-checkbox-label'
              >
                Don&apos;t show me this again
              </p>
            }
            onChange={handleCheck}
            checked={isHideDefaultAliasChecked}
          />
        </CheckboxContainer>
      </ButtonContainer>
    </>
  );
};

const CannotUpdateAccountContent: React.FC<{
  aliasAutoEnableSetting: boolean;
  aliasEnabled: boolean;
}> = ({ aliasAutoEnableSetting, aliasEnabled }) => {
  const [isHideDefaultAliasChecked, setIsHideDefaultAliasChecked] =
    useState(false);

  const handleCheck = useCallback(
    e => {
      setIsHideDefaultAliasChecked(!isHideDefaultAliasChecked);

      localStorageFallback.setItem(
        'hideDefaultAliasModal',
        (!isHideDefaultAliasChecked).toString(),
      );
    },
    [isHideDefaultAliasChecked],
  );
  return (
    <>
      <InfoContainer>
        <Header className='h5'>Alias searches</Header>
        <Description>
          <p>
            You{' '}
            <span data-testid='alias-setting-selection-bold-text-1'>
              {aliasEnabled ? 'added' : 'removed'}
            </span>{' '}
            alias searches {aliasEnabled ? 'to' : 'from'} this order. Your
            account admin can update whether to search aliases for future orders
            in Account &gt; Settings.
          </p>
        </Description>
      </InfoContainer>
      <ButtonContainer>
        <CheckboxContainer id='hide-modal-checkbox-container-unauthorized'>
          <M.Checkbox
            data-testid='hide-modal-checkbox'
            id='hide-modal-checkbox'
            labelText={
              <p
                style={{ paddingLeft: '12px' }}
                data-testid='hide-modal-checkbox-label'
              >
                Don&apos;t show me this again
              </p>
            }
            kind='primary'
            onChange={handleCheck}
            checked={isHideDefaultAliasChecked}
          />
        </CheckboxContainer>
      </ButtonContainer>
    </>
  );
};

const AliasSettingSelection: React.FC<{
  aliasEnabled: boolean;
}> = ({ aliasEnabled }) => {
  const currentUser = useUser();
  const { account } = currentUser;
  const [aliasAutoEnable, setAliasAutoEnable] = useState(aliasEnabled);
  const {
    call: updateAccountSettings,
    result: { isLoading },
  } = useUpdateAccountSettings();
  const trackEvent = useTrackEvent();
  const [trackingEventsSent, setTrackingEventsSent] = useState(false);

  const onSave = useCallback(() => {
    const changedSettings = {
      alias_auto_enable: aliasAutoEnable,
    };

    // Fire off api to update setting with new change
    updateAccountSettings({ changedSettings, account, isModal: true });
    trackEvent(
      currentUser,
      ALIASES.CHECKR_DASHBOARD_DEFAULT_ALIAS_ACCOUNT_SETTING_UPDATED,
      {
        Source: 'Modal',
        Selection: aliasAutoEnable ? 'On' : 'Off',
      },
    );
  }, [
    account,
    aliasAutoEnable,
    currentUser,
    trackEvent,
    updateAccountSettings,
  ]);

  const handleUpdateSettingClick = useCallback(event => {
    setAliasAutoEnable(event);
  }, []);

  const canUpdateSettings = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  useEffect(() => {
    if (!trackingEventsSent)
      trackEvent(currentUser, ALIASES.CHECKR_DASHBOARD_ALIAS_MODAL_VIEWED, {
        'User Type': canUpdateSettings ? 'admin' : 'non-admin',
      });
    setTrackingEventsSent(true);
  }, [canUpdateSettings, currentUser, trackEvent, trackingEventsSent]);

  return (
    <Container>
      {canUpdateSettings && (
        <CanUpdateAccountContent
          handleUpdateSettingClick={handleUpdateSettingClick}
          onSave={onSave}
          aliasAutoEnableSetting={aliasAutoEnable}
          aliasEnabled={aliasEnabled}
          isLoading={isLoading}
        />
      )}
      {!canUpdateSettings && !isLoading && (
        <CannotUpdateAccountContent
          aliasAutoEnableSetting={aliasAutoEnable}
          aliasEnabled={aliasEnabled}
        />
      )}
    </Container>
  );
};

export default AliasSettingSelection;
