import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import {
  useGetReport,
  useReportPropertiesForAmplitudeEvents,
} from 'api/reports';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import Guard from 'modules/adjudication/ui/defer/button/guard';
import DeferModal from 'modules/adjudication/ui/defer/flow/modal/modal';

const Defer: React.FC = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const { t } = useTranslation();
  const buttonTitle = t(`${namespace}:report.actions.defer.action`);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      {
        Action: 'Mark candidate deferred',
        Source: 'Decision Defer Button',
        Report: reportProperties,
      },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [reportProperties, trackEvent]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const disabled =
    report.archived || report.adjudication === ADJUDICATION_VALUES.DEFERRED;

  if (disabled) return null;

  return (
    <Guard>
      <M.Button
        id='defer-button'
        data-testid='defer-button'
        kind='secondary'
        onClick={handleOpen}
      >
        {buttonTitle}
      </M.Button>
      {open && <DeferModal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default Defer;
