import React, { useCallback, useContext, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useArchiveReports, useUnarchiveReports } from 'api/archiveReport';
import { ARCHIVE_REPORTS_NAMES, useTrackEvent } from 'utils/analytics';
import { useReport } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { scrollToTopIfIframe } from 'utils';
import { RealtimeContext } from 'containers/RealtimeProvider';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import ArchiveModal from './archive-modal';
import UnarchiveModal from './unarchive-modal';

const ArchiveReport = () => {
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const currentUser = useUser();
  const report = useReport();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [unarchiveModalOpen, setUnarchiveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openArchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_REPORTS_NAMES.ARCHIVE_REPORT_ACTION_SELECTED, {
        'Archive action': 'Archive report',
        Source: 'Secondary Actions',
        Report: reportProperties,
      });
    }

    setArchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [currentUser, trackEvent, reportProperties]);

  const openUnarchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_REPORTS_NAMES.ARCHIVE_REPORT_ACTION_SELECTED, {
        'Archive action': 'Unarchive report',
        Source: 'Secondary Actions',
        Report: reportProperties,
      });
    }

    setUnarchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [currentUser, trackEvent, reportProperties]);

  const closeArchiveModal = useCallback(() => {
    setArchiveModalOpen(false);
  }, [setArchiveModalOpen]);

  const closeUnarchiveModal = useCallback(() => {
    setUnarchiveModalOpen(false);
  }, [setUnarchiveModalOpen]);

  const { call: archivedReportCall } = useArchiveReports();
  const { call: unarchivedReportCall } = useUnarchiveReports();
  const { triggerRefetches } = useContext(RealtimeContext);

  const handleArchiveAction = useCallback(
    (setModalOpen, apiCall) => {
      setIsLoading(true);
      setModalOpen(false);

      apiCall({
        candidate_report_ids: [
          {
            candidate_id: report.candidate?.id as string,
            report_id: report.id,
          },
        ],
        user_resource_id: currentUser?.id as string,
      }).then(() => {
        triggerRefetches();
        setIsLoading(false);
      });
    },
    [archivedReportCall, unarchivedReportCall, report],
  );

  const archive = useCallback(
    () => handleArchiveAction(setArchiveModalOpen, archivedReportCall),
    [handleArchiveAction, archivedReportCall],
  );
  const unarchive = useCallback(
    () => handleArchiveAction(setUnarchiveModalOpen, unarchivedReportCall),
    [handleArchiveAction, unarchivedReportCall],
  );

  return (
    <>
      <M.ActionMenuItem
        title={report.archived ? 'Unarchive report' : 'Archive report'}
        kind='node'
        actionNode={<M.Icon icon='Archive' size='16' />}
        onClick={report.archived ? openUnarchiveModal : openArchiveModal}
      />
      <ArchiveModal
        isOpen={archiveModalOpen}
        closeModal={closeArchiveModal}
        archive={archive}
      />
      <UnarchiveModal
        isOpen={unarchiveModalOpen}
        closeModal={closeUnarchiveModal}
        unarchive={unarchive}
      />
      {isLoading && <M.LoadingSpinner />}
    </>
  );
};

export default ArchiveReport;
