import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { accountHasPermission } from '@dashboard-experience/utils';
import * as utils from 'components/AddScreenings/shared/utils';
import UserContext from 'context/CurrentUser';
import { usePackagePrice } from 'components/AddScreenings/hooks/usePackagePrice';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  actionTypes,
  useOrderBackgroundCheckContext,
} from '../OrderBackgroundCheck/Context';
import EditableSection from './EditableSection';
import ScreeningsContent from './ScreeningsContent';
import { AddOnSectionContent } from '../OrderBackgroundCheck/ReviewAndSubmitOrder/SectionContent';
import { ALIAS_AS_AN_ADDON } from '../../../Flags';
import TitleSection from './TitleSection';
import useAutoStartReport, { AutoStartReportProps } from './AutoStartReport';

const ReportData = styled.div`
  display: flex;
  width: 506px;
  font-size: 16px;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
`;

const CandidateInfo = styled.div`
  display: flex;
  width: 745px;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 60px;
  border-bottom: 1px solid var(--grey-300, #bec6cf);
`;

const CandidateInfoLabel = styled.div`
  width: 25%;
  flex-shrink: 0;
  color: var(--text-icon-black-primary, #1a2026);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding-top: 2px;
`;

const SubtotalSection = styled.div`
  display: flex;
  width: 745px;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  border-bottom: 1px solid
    var(--text-icon-black-secondary-78, rgba(26, 32, 38, 0.78));
`;

const Subtotal = styled.div`
  color: var(--text-icon-black-primary, #1a2026);
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
`;

const Footer = styled.div`
  width: 100%;
`;

const FooterTypography = styled.div<TypographyProps>`
  font-weight: 400;
  max-width: 716px;
  color: ${colors.uiGrey700};
  line-height: ${props => (props.$isFootnote ? '16px' : '18px')};
  font-size: ${props => (props.$isFootnote ? '12px' : '14px')};
`;

const AsteriskFooterTypography = styled(FooterTypography)`
  margin: 16px 0px;
  color: ${colors.uiGrey900} !important;
`;

const LinkStyled = styled(M.Link)<TypographyProps>`
  text-decoration: underline;
  font-size: ${props => (props.$isFootnote ? '12px' : '14px')} !important;
  color: ${props =>
    props.$isFootnote
      ? colors.uiLinkPrimaryLightDefault
      : colors.uiTextPrimaryLight} !important;
`;

const LearnMoreLinkStyled = styled(M.Link)`
  text-decoration: underline;
  font-size: 12px !important;
  color: ${colors.uiTextPrimaryLight} !important;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const CheckboxStyled = styled(M.Checkbox)``;

type TypographyProps = {
  $isFootnote?: boolean;
};

type Props = {
  orderCertified: boolean;
  setOrderCertified: Function;
  candidateName: string;
  autoStartReportProps: AutoStartReportProps;
  hasReportCreationError: boolean;
  handleEditClick: (stepNumber: number) => void;
  handleLearnMoreClick: () => void;
  handleServiceAgreementClick: () => void;
  setCreateReportView: Function;
};

const ReviewAndSubmitOrder: React.FC<Props> = ({
  orderCertified,
  setOrderCertified,
  candidateName,
  hasReportCreationError,
  handleEditClick,
  handleLearnMoreClick,
  handleServiceAgreementClick,
  autoStartReportProps,
  setCreateReportView,
}) => {
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const { basePackage, addedScreeningTypes, additionalProperties } = state;

  const currentUser = useContext(UserContext);

  const { isCreateReportView, data: eligibilityData } =
    useAutoStartReport(autoStartReportProps);

  const hasEligibilityError = useMemo(
    () =>
      !!(
        eligibilityData &&
        eligibilityData.eligible === false &&
        eligibilityData.send_invitation === false
      ),
    [eligibilityData],
  );

  const showPrices =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner';

  const skippedPackageSelection = useMemo(() => {
    return state.oldBasePackage?.name === 'SKIP_NAME_DO_NOT_SUBMIT_00q5wq58';
  }, [state.oldBasePackage]);

  const packageWithAddons = useMemo(() => {
    // See if the customer selected a package AND added addons
    return state.basePackage?.slug?.includes('with_addons');
  }, [state.basePackage.slug]);

  const basePackageToUse = useMemo(
    () =>
      skippedPackageSelection || packageWithAddons
        ? state.oldBasePackage
        : basePackage,
    [
      basePackage,
      skippedPackageSelection,
      state.oldBasePackage,
      packageWithAddons,
    ],
  );

  // calculate_prices call will throw an error if requires_fmcsa_compliance is true
  const shouldCalculatePrice = basePackageToUse
    ? !basePackageToUse.requires_fmcsa_compliance
    : true;

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: basePackageToUse,
      addedScreeningTypes,
      additionalProperties,
      packageName: basePackageToUse.name,
      setSlug: true,
      isPrivate: false,
    });
  }, [addedScreeningTypes, additionalProperties, basePackageToUse]);

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackageToUse.name,
      addedScreeningTypes,
      additionalProperties,
    ],
    currentUser.account,
    {
      ...buildPostBodyWithAddOns(),
    },
  );

  const basePackageQuery = usePackagePrice(
    ['base-package-price', basePackageToUse?.name],
    currentUser.account,
    basePackageToUse,
    undefined,
    shouldCalculatePrice,
  );
  const basePackagePrice = basePackageQuery?.data?.fixed_price;

  const { data, isLoading: isAddOnsPricesLoading } = pendingAddOnPricesQuery;

  const subtotalPerCandidate = useMemo(() => {
    return utils.getDollarAmount(data?.fixed_price?.toString());
  }, [data]);

  const basePackageScreenings = useMemo(() => {
    return basePackageToUse?.screenings?.map(screening => screening.type);
  }, [basePackageToUse]);

  const handleCheck = useCallback(
    (event, _) => {
      setOrderCertified(!orderCertified);
    },
    [orderCertified, setOrderCertified],
  );

  const onEditClick = useCallback(() => {
    handleEditClick(2);
  }, [handleEditClick]);

  useEffect(() => {
    if (data?.fixed_price) {
      const totalAddonsPrice = data.fixed_price - basePackagePrice;

      dispatch({
        type: actionTypes.SET_ADDONS_PRICE,
        payload: {
          addOnsPrice: totalAddonsPrice,
        },
      });
    }
  }, [basePackagePrice, data, dispatch]);

  useEffect(() => {
    setCreateReportView(isCreateReportView);
  }, [isCreateReportView, setCreateReportView]);

  const thirdPartyFeeRange = useCallback(() => {
    if (!isAddOnsPricesLoading && data) {
      const minTotalFees =
        data.service_fee_range[0] + data.passthrough_fee_range[0];
      const maxTotalFees =
        data.service_fee_range[1] + data.passthrough_fee_range[1];
      return `${getDollarAmount(minTotalFees)}-${getDollarAmount(
        maxTotalFees,
      )}`;
    }
    return 'calculating...';
  }, [isAddOnsPricesLoading, data]);

  return (
    <div>
      {hasEligibilityError && (
        <M.InlineNotification kind='error' title='' subtitle=''>
          It looks like we are unable to process this report. Your report{' '}
          {eligibilityData?.errors?.report[0]}
        </M.InlineNotification>
      )}
      {hasReportCreationError ? (
        <M.InlineNotification kind='warning' title='' subtitle=''>
          <div>
            It looks like we&apos;re unable to process your order without more
            information. To continue, select
            <Bold>&nbsp;Send invitation link&nbsp;</Bold>and we&apos;ll
            <Bold>&nbsp;email the candidates a new invitation link&nbsp;</Bold>
            to submit the required details.
          </div>
        </M.InlineNotification>
      ) : (
        <TitleSection createReportView={isCreateReportView} />
      )}
      <CandidateInfo>
        <CandidateInfoLabel>Candidates</CandidateInfoLabel>
        <ReportData>{state.emails}</ReportData>
      </CandidateInfo>
      <CandidateInfo>
        <CandidateInfoLabel>Work Location</CandidateInfoLabel>
        <ReportData>
          {state.location.country === 'US' &&
          typeof state.location.state?.name === 'string'
            ? `${state.location.state?.name}, United States`
            : state.location.country}
        </ReportData>
      </CandidateInfo>
      {state.basePackage.name && !skippedPackageSelection && (
        <EditableSection
          id='add-checks-review-selected-package'
          sectionTitle='Selected package'
          onEditClick={onEditClick}
          description={{
            title: packageWithAddons
              ? state.oldBasePackage.name
              : state.basePackage.name,
            content: (
              <ScreeningsContent
                screenings={
                  packageWithAddons
                    ? state.oldBasePackage.screenings
                    : state.basePackage.screenings
                }
                additionalProperties={state.additionalProperties}
                isAddChecks
              />
            ),
          }}
          showPricingColumn={showPrices}
          price={
            basePackageToUse.price ? basePackageToUse.price!.toString() : ''
          }
          priceDescription='Starting at'
          isAddChecks
        />
      )}
      {addedScreeningTypes.length > 0 && (
        <EditableSection
          id='add-checks-review-selected-addons'
          sectionTitle='Additional Checks'
          onEditClick={handleEditClick}
          description={{
            expand: true,
            content: (
              <AddOnSectionContent
                addOnPricesData={data}
                basePackageScreenings={basePackageScreenings}
                showPrices={showPrices}
                isLoading={isAddOnsPricesLoading}
                additionalProperties={additionalProperties}
                isAddChecks
              />
            ),
          }}
          showPricingColumn={showPrices}
          hideLastColumn
          isAddChecks
        />
      )}

      {showPrices && (
        <SubtotalSection>
          <Subtotal> *Subtotal: {subtotalPerCandidate}</Subtotal>
          {aliasFlag && (
            <div>Estimated third-party fees: {thirdPartyFeeRange()}</div>
          )}
        </SubtotalSection>
      )}
      <Footer>
        <AsteriskFooterTypography $isFootnote>
          Excludes{' '}
          <LearnMoreLinkStyled
            href='https://checkr.com/pricing/pass-through-fees'
            data-testid='footer-learn-more'
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleLearnMoreClick}
          >
            passthrough fees
          </LearnMoreLinkStyled>
          , which will appear on your invoice with other applicable taxes and
          fees.
        </AsteriskFooterTypography>
        <CheckboxStyled
          id='review-and-submit-order-legal-check'
          checked={orderCertified}
          onChange={handleCheck}
          disabled={hasEligibilityError}
          labelText={
            <FooterTypography $isFootnote>
              I certify that I will order and use the Reports only for a lawful
              and appropriate permissible purpose, as defined by applicable law
              (including but not limited to the FCRA) and will use Reports in
              accordance with all applicable law and the terms set forth in the{' '}
              <LinkStyled
                data-testid='checkr-services-agreement-link'
                href='https://checkr.com/customer-agreement'
                target='_blank'
                rel='noopener noreferrer'
                onClick={handleServiceAgreementClick}
                $isFootnote
              >
                Checkr, Inc. Services Agreement.
              </LinkStyled>
            </FooterTypography>
          }
        />
      </Footer>
    </div>
  );
};

export default ReviewAndSubmitOrder;
