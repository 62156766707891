import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { stateName } from 'utils';
import {
  MODAL_STEPS,
  ONE,
  TWO,
  THREE,
  INITIAL_VALIDATIONS,
  fieldValidator,
  getInvalidErrorMessage,
  mapBackendFieldsToFrontend,
  SelectedScreening,
} from 'components/AddScreenings/shared/ModalComponents';
import {
  OccupationalHealthData,
  useOccupationalHealth,
} from 'components/AddScreenings/hooks/useOccupationalHealth';
import OccupationalHealthSetupPageOne, {
  OccupationalHealthPageOneStateType,
} from './OccupationalHealthSetupPageOne';
import OccupationalHealthPageTwo from './OccupationalHealthSetupPageTwo';
import CompletedSetup from './CompletedSetup';

type FooterProps = {
  currentStep: number;
};

interface OccupationalHealthItem {
  label: string;
  value: string;
  section: string;
  price: string;
}

interface SelectedOccupationalHealthItem {
  name: string;
  label: string;
}

interface OutputObject {
  subtype: string[];
  selectedOccupationalHealth: SelectedOccupationalHealthItem[];
}

export const getOccHealthAdditionalProperties = (
  input: OccupationalHealthItem[],
): OutputObject => {
  const subtype: string[] = input.map(item => item.value);

  const selectedOccupationalHealth: SelectedOccupationalHealthItem[] =
    input.map(item => ({
      name: item.value,
      label: item.label,
    }));

  return {
    subtype,
    selectedOccupationalHealth,
  };
};

const Footer = styled(M.ModalFooter)<FooterProps>`
  justify-content: ${props =>
    props.currentStep === 2 ? 'space-between' : 'right'};
`;

const OccupationHealthSetupDescription = {
  email: 'We’ll email you after set up is complete.',
  calendar: 'This typically takes 7-10 business days. ',
  shoppingCatalog: 'Then you can start using occupational health screenings.',
};

type OccupationalHealthSetupModalProps = {
  open: boolean;
  handleClose: () => void;
};

const OccupationalHealthSetupModal: React.FC<
  OccupationalHealthSetupModalProps
> = ({ open, handleClose }) => {
  const currentUser = useUser();

  const {
    account: { id: accountId, company },
  } = currentUser;
  const {
    street,
    city,
    zipcode,
    state: companyState,
  } = company || {
    street: '',
    city: '',
    zipcode: '',
    state: '',
  };
  const companyStateName = stateName(companyState);

  const [hasError, setHasError] = useState(false);
  const [pageOneState, setPageOneState] =
    useState<OccupationalHealthPageOneStateType>({
      name: '',
      email: '',
      phoneNumber: '',
      street: street || '',
      city: city || '',
      zip: zipcode || '',
      state: companyStateName || '',
    });
  const [selectedScreenings, setSelectedScreenings] = useState<
    SelectedScreening[]
  >([]);
  const [isDOT, setIsDot] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [validationWarnings, setValidationWarnings] =
    useState(INITIAL_VALIDATIONS);
  const [screeningsError, setScreeningsError] = useState(false);

  const {
    call: setupOccupationalHealth,
    result: setupOccupationalHealthResult,
  } = useOccupationalHealth({
    accountId,
  });

  const scrollToTopOfModal = useCallback(() => {
    const element = document.getElementById(
      'occupational-health-setup-modal-body',
    );
    if (element) {
      element.scrollTo({
        top: 0,
      });
    }
  }, []);

  const handleBackClick = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleNextClick = useCallback(() => {
    // only checking first page fields
    const { fieldsAreValid, invalidFields } = fieldValidator(
      pageOneState.email,
      pageOneState.phoneNumber,
      pageOneState.city,
      pageOneState.zip,
      pageOneState.street,
      pageOneState.state,
      pageOneState.name,
    );
    setValidationWarnings(invalidFields);

    if (fieldsAreValid) {
      setCurrentStep(currentStep + 1);
      setValidationWarnings(INITIAL_VALIDATIONS);
      scrollToTopOfModal();
    }
  }, [
    currentStep,
    pageOneState.city,
    pageOneState.email,
    pageOneState.name,
    pageOneState.phoneNumber,
    pageOneState.state,
    pageOneState.street,
    pageOneState.zip,
    scrollToTopOfModal,
  ]);

  const handleSubmitClick = useCallback(() => {
    if (selectedScreenings.length === 0) {
      setScreeningsError(true);
      return;
    }

    const screeningsWithLabels = selectedScreenings.map(
      (screen: SelectedScreening) => screen.label,
    );
    const data: OccupationalHealthData = {
      contact_name: pageOneState.name,
      contact_email: pageOneState.email,
      contact_phone: pageOneState.phoneNumber,
      business_address_street: pageOneState.street,
      business_address_city: pageOneState.city,
      business_address_state: pageOneState.state,
      business_address_zipcode: pageOneState.zip,
      occ_health_dot_regulated: !!isDOT,
      occ_health_screenings: screeningsWithLabels,
    };
    setupOccupationalHealth(data);
  }, [
    isDOT,
    pageOneState.city,
    pageOneState.email,
    pageOneState.name,
    pageOneState.phoneNumber,
    pageOneState.state,
    pageOneState.street,
    pageOneState.zip,
    selectedScreenings,
    setupOccupationalHealth,
  ]);

  useEffect(() => {
    if (
      setupOccupationalHealthResult.isSuccess &&
      MODAL_STEPS[currentStep] === TWO
    ) {
      setCurrentStep(currentStep + 1);
      setValidationWarnings(INITIAL_VALIDATIONS);
    }
    if (
      setupOccupationalHealthResult.isError &&
      validationWarnings.email.isValid &&
      !hasError
    ) {
      const errorField = (setupOccupationalHealthResult.error as any).response
        .data.errors[0].field;
      const field = mapBackendFieldsToFrontend(errorField);
      const fieldErrorMessage = getInvalidErrorMessage(field);
      setValidationWarnings({
        ...validationWarnings,
        [field]: { isValid: false, error: fieldErrorMessage },
      });
      setHasError(true);
      setCurrentStep(1);
    }
  }, [
    setupOccupationalHealthResult.error,
    setupOccupationalHealthResult.isSuccess,
    currentStep,
    setupOccupationalHealthResult.isError,
    validationWarnings,
    setupOccupationalHealthResult,
    hasError,
  ]);

  return (
    <M.ComposedModal
      data-testid='occupational-health-setup-modal'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='occupational-health-setup-modal-header'
        closeModal={handleClose}
      >
        <h2>Set up occupational health</h2>
      </M.ModalHeader>
      <M.ModalBody id='occupational-health-setup-modal-body'>
        <div data-testid='occupational-health-modal-body'>
          {setupOccupationalHealthResult.isLoading && <M.LoadingSpinner />}
          {MODAL_STEPS[currentStep] === ONE && (
            <OccupationalHealthSetupPageOne
              state={pageOneState}
              setState={setPageOneState}
              validationWarnings={validationWarnings}
            />
          )}
          {MODAL_STEPS[currentStep] === TWO && (
            <OccupationalHealthPageTwo
              selectedScreenings={selectedScreenings}
              setSelectedScreenings={setSelectedScreenings}
              screeningsError={screeningsError}
              accountId={currentUser.account.id}
              isDOT={isDOT}
              setIsDot={setIsDot}
            />
          )}
        </div>
        {MODAL_STEPS[currentStep] === THREE && (
          <CompletedSetup description={OccupationHealthSetupDescription} />
        )}
      </M.ModalBody>
      {MODAL_STEPS[currentStep] === ONE && (
        <Footer currentStep={currentStep}>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='occupational-health-modal-next-button'
            onClick={handleNextClick}
          >
            Next
          </M.Button>
        </Footer>
      )}

      {MODAL_STEPS[currentStep] === TWO && (
        <Footer currentStep={currentStep}>
          <M.Button
            kind='secondary'
            size='sm'
            data-testid='occupational-health-modal-back-button'
            onClick={handleBackClick}
          >
            Back
          </M.Button>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='occupational-health-modal-submit-button'
            onClick={handleSubmitClick}
          >
            Submit
          </M.Button>
        </Footer>
      )}
      {MODAL_STEPS[currentStep] === THREE && (
        <Footer>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='occupational-health-modal-close-button'
            onClick={handleClose}
          >
            Close
          </M.Button>
        </Footer>
      )}
    </M.ComposedModal>
  );
};

export default OccupationalHealthSetupModal;
