import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import Option from 'modules/assess/models/context-date-option';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  onClose?: (event: SyntheticEvent) => void;
  onDelete: () => void;
  open: boolean;
  option?: Option.Type;
};

export const Container: React.FC<Props> = ({
  onClose,
  onDelete,
  open,
  option,
}) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:context_dates.remove.title');

  if (!open) {
    return null;
  }

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-version-context-dates-remove-dialog'
      open={open}
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-version-context-dates-remove-title'
        title={modalTitle}
        closeModal={onClose}
      />
      <M.ModalBody data-testid='assess-ruleset-version-context-dates-remove-content'>
        <Content option={option} />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-version-context-dates-remove-actions'>
        <Actions onCancel={onClose} onDelete={onDelete} />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
