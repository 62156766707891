import * as ContextDates from 'modules/assess/models/rulesets/context-dates';
import { assign, reduce } from 'state/utilities/reducers';
import * as Actions from './actions';
import * as State from './state';
import * as Options from './options';

export const meta = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Load.TYPE: {
      const {
        payload: { options: _options, ...data },
      } = action as Actions.Load.Action;

      return assign.apply(state, { dirty: false, ...data });
    }
    default: {
      break;
    }
  }
  return state;
};

const updateCriminalOptions = (
  state: State.Type,
  action: Actions.Any,
): State.Type => {
  const criminalOptions = state.options as State.Criminal;
  const options = Options.reducers.default(criminalOptions.criminal, action);

  let dirty = true;

  switch (action.type) {
    case Options.Actions.Load.TYPE: {
      dirty = false;
      break;
    }
    default: {
      break;
    }
  }

  return assign.apply(state, { dirty, options: { criminal: options } });
};

const updateAccidents = (
  state: State.Type,
  action: Actions.Any,
  mvrOptions: State.MVR,
): State.Type => {
  const accidents = Options.reducers.default(mvrOptions.accidents, action);

  return assign.apply(state, {
    dirty: true,
    options: { ...mvrOptions, accidents },
  });
};

const updateSuspensions = (
  state: State.Type,
  action: Actions.Any,
  mvrOptions: State.MVR,
): State.Type => {
  const suspensions = Options.reducers.default(mvrOptions.suspensions, action);

  return assign.apply(state, {
    dirty: true,
    options: { ...mvrOptions, suspensions },
  });
};

const updateViolations = (
  state: State.Type,
  action: Actions.Any,
  mvrOptions: State.MVR,
): State.Type => {
  const violations = Options.reducers.default(mvrOptions.violations, action);

  return assign.apply(state, {
    dirty: true,
    options: { ...mvrOptions, violations },
  });
};

const updateMvrOption = (
  state: State.Type,
  action: Actions.Any,
): State.Type => {
  const mvrOptions = state.options as State.MVR;
  const { currentOption } = state;

  switch (currentOption) {
    case 'accidents': {
      return updateAccidents(state, action, mvrOptions);
    }
    case 'suspensions': {
      return updateSuspensions(state, action, mvrOptions);
    }
    case 'violations': {
      return updateViolations(state, action, mvrOptions);
    }
    default: {
      return state;
    }
  }
};

const loadMvrOptions = (
  state: State.Type,
  data: Actions.Load.Payload,
): State.Type => {
  const mvrOptions = state.options as State.MVR;
  const loadOptions = data.options as ContextDates.Mvr.Type;
  const loadAccidents = Options.Actions.Load.create(
    loadOptions.accident_options,
  );
  const loadSuspensions = Options.Actions.Load.create(
    loadOptions.suspension_options,
  );
  const loadViolations = Options.Actions.Load.create(
    loadOptions.violation_options,
  );

  const mvrState = {
    accidents: Options.reducers.default(mvrOptions.accidents, loadAccidents),
    suspensions: Options.reducers.default(
      mvrOptions.suspensions,
      loadSuspensions,
    ),
    violations: Options.reducers.default(mvrOptions.violations, loadViolations),
  };

  // Default/initial mvr tab is accidents
  return {
    ...state,
    options: mvrState,
    currentOption: 'accidents',
  };
};

export const options = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Load.TYPE: {
      const { payload: data } = action as Actions.Load.Action;
      if (Array.isArray(data.options)) {
        const loadOptions = data.options as ContextDates.Criminal.Type;
        const load = Options.Actions.Load.create(loadOptions);
        return updateCriminalOptions(state, load);
      }

      return loadMvrOptions(state, data);
    }
    case Actions.Update.TYPE: {
      const {
        payload: { dirty, currentOption },
      } = action as Actions.Update.Action;
      return assign.apply(state, { dirty, currentOption });
    }
    default: {
      break;
    }
  }

  if ('criminal' in state.options) {
    return updateCriminalOptions(state, action);
  }

  return updateMvrOption(state, action);
};

export const reducer = reduce(null, meta, options);

export default reducer;
