import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import {
  useAdverseActionable,
  useGetReport,
  useIsInternationalReport,
} from 'api/reports';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import Modal from '../flow';

const kind = 'pre_adverse';
const { PRE_ADVERSE_ACTION, POST_ADVERSE_ACTION } = ADJUDICATION_VALUES;
const noop = () => {};

const PreAAButton = () => {
  const { report, isLoading } = useGetReport();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Pre Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpen]);

  const { adverseActionable } = useAdverseActionable();
  const isInternationalReport = useIsInternationalReport();

  const text = t(`${namespace}:report.actions.pre_adverse.action`);

  const hideButton =
    report?.adjudication === PRE_ADVERSE_ACTION ||
    report?.adjudication === POST_ADVERSE_ACTION ||
    isInternationalReport;

  const defaultTooltipText = t(
    `${namespace}:report.actions.pre_adverse.tooltip.disabled`,
  );
  let tooltipText = defaultTooltipText;

  if (adverseActionable?.errors?.length) {
    tooltipText = adverseActionable.errors.join('. ');
  } else if (adverseActionable?.enabled) {
    tooltipText = t(`${namespace}:report.actions.pre_adverse.tooltip.enabled`);
  }

  const buttonDisabled =
    isLoading || !adverseActionable?.enabled || report.archived;
  const disabled = buttonDisabled ? 'disabled' : '';

  return (
    <M.Container.Col>
      <>
        {hideButton ? null : (
          <M.TooltipButton
            data-testid='pre-adverse-action-button'
            className={disabled}
            fullWidth
            kind={kind}
            label={tooltipText}
            onClick={buttonDisabled ? noop : handleOpen}
            disabled={report.archived}
          >
            {text}
          </M.TooltipButton>
        )}
        {open && <Modal onClose={handleClose} open={open} />}
      </>
    </M.Container.Col>
  );
};

export default PreAAButton;
