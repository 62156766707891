import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { Footer, StepContainer } from '../ui';

const BulkUploadStep: React.FC<{}> = () => {
  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();
  const { contextId } = useContext(UIContext);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/review-and-submit';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [history, contextId]);

  return (
    <StepContainer data-testid='bulk-upload-step-container'>
      Bulk Upload Step Placeholder
      <Footer>
        <M.Button type='button' disabled={disabled} onClick={handleContinue}>
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default BulkUploadStep;
