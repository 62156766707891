import styled from 'styled-components';

const ChoiceCardGroup = styled.div`
  display: flex;
  margin: 1rem 0;
`;

// eslint-disable-next-line import/prefer-default-export
export const StyledChoiceCardGroup = styled(ChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;
