import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxDivider,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalIdentityDocumentValidationProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};

const InternationalIdentityDocumentValidation = ({
  shouldOpen,
  actionProps,
}: InternationalIdentityDocumentValidationProps) => {
  return (
    <FlexContainer data-testid='international-identity-document-validation-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Identity document validation'
            priceByType='international_identity_document_validation'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Validate the authenticity of a candidate&apos;s passport, national
            ID, or driver&apos;s license.
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Reports cross-check information against government databases in more than 200 countries and regions.' />
              <BulletPoint text='Ensure that the presented document isn’t stolen, lost, expired or invalid.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text="Validate the candidate's documentation, not their identity." />
              <BulletPoint text="This validation doesn't satisfy the United Kingdom (England and Wales) identity verification requirement." />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_identity_document_validation'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalIdentityDocumentValidation;
