import React, { useCallback } from 'react';
import { parse } from 'papaparse';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import SecureFilestack from 'components/SecureFilestack/secureFilestack';
import { useDispatch } from 'react-redux';
import { useFilestackUploadResponse } from 'hooks';
import { useUser } from 'context/CurrentUser';
import { hasPermission } from '@dashboard-experience/utils';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_BULKORDER_NAMES,
} from 'utils/analytics';
import {
  bulkOrderFilestackKey as filestackKey,
  CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE,
} from 'Constants';
import { toastError } from '../../../../../actions';
import { BULK_INVITE_CSV_STORAGE_PATH, BulkInviteCSV } from '../../SharedItems';
import { useOrderBackgroundCheckContext, actionTypes } from '../../Context';

const UploadTip = styled.div`
  line-height: 1.25rem;
  margin: 1rem 0;

  h3 {
    margin: 0.5rem 0 !important;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const BoldText = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

const UploadedFile = styled(M.Container.Row)`
  align-items: center;
  margin-top: 2rem;

  .mastodon-icon {
    fill: ${colors.uiGreen600};
  }

  .filename {
    color: ${colors.uiTextPrimaryLight};
    font-size: 0.875rem !important;
    margin: 0 1rem;
  }
`;

type BulkInviteUploadRow = {
  candidate_email: string[];
};

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0 !important;
`;

const fileStackOptions = {
  accept: ['text/csv'],
  storeTo: {
    location: 's3',
    path: `/${BULK_INVITE_CSV_STORAGE_PATH}/`,
  },
  maxFiles: 1,
  uploadInBackground: false,
  fromSources: ['local_file_system'],
  maxSize: 1000000,
  customText: {
    Files: 'File',
    'Select Files to Upload': 'Select file to upload',
    'Selected Files': 'Selected file',
    'Deselect All': 'Deselect file',
    'or Drag and Drop, Copy and Paste Files':
      'or drag and drop, copy and paste file ',
  },
};

type ProvideContactInfoViaUploadProps = {
  bulkInviteFlagrEnabled: boolean;
  redirectPage: Function;
  bulkUploadMax: string;
  fileDisplayName: string;
};

const ProvideContactInfoViaUploadSection: React.FC<
  ProvideContactInfoViaUploadProps
> = ({
  bulkInviteFlagrEnabled,
  redirectPage,
  bulkUploadMax,
  fileDisplayName,
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const trackEvent = useTrackEvent();
  const dispatchToast = useDispatch();
  const currentUser = useUser();
  const csvTemplate = CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE;

  const handleDownloadCSVTemplate = useCallback(() => {
    if (bulkInviteFlagrEnabled) {
      trackEvent(
        ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.DOWNLOAD_CSV_TEMPLATE_CLICKED,
      );
    }
  }, [bulkInviteFlagrEnabled, trackEvent]);

  const handleRedirectToDocuments = useCallback(
    e => {
      e.preventDefault();
      if (hasPermission(currentUser, 'read_account_invoices')) {
        if (bulkInviteFlagrEnabled) {
          redirectPage('/billing/bulk-history', true);
          trackEvent(
            ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.BULK_ORDER_REDIRECT_TO_DOCUMENTS,
          );
        }
      } else {
        redirectPage('/bulk-order-history', true);
      }
    },
    [currentUser, bulkInviteFlagrEnabled, redirectPage, trackEvent],
  );

  const fileStackCustomRender = useCallback(
    ({ onPick }) => (
      <M.Button
        kind='secondary'
        disabled={state.csv.fileDisplayName}
        data-testid='csv-upload-button'
        size='sm'
        onClick={onPick}
      >
        Upload
      </M.Button>
    ),
    [state.csv],
  );

  const handleFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatchToast(toastError('Failed to upload file'));
    },
    successCallback: file => {
      const csv: BulkInviteCSV = {
        fileStorageKey: file.key,
        fileDisplayName: file.filename,
        fileS3URL: file.url,
      };

      dispatch({
        type: actionTypes.UPLOAD_CSV,
        payload: { csv },
      });

      if (csv.fileStorageKey && bulkInviteFlagrEnabled) {
        trackEvent(
          ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.CSV_SUCCESSFULLY_UPLOADED,
        );
      }

      parse(file.url, {
        download: true,
        header: true,
        skipEmptyLines: 'greedy',
        complete: result => {
          const data = result?.data as BulkInviteUploadRow[];
          dispatch({
            type: actionTypes.ADD_EMAILS,
            payload: { emails: data.map(row => row.candidate_email) },
          });
        },
      });
    },
  });

  const handleFileDelete = useCallback(() => {
    if (bulkInviteFlagrEnabled) {
      trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.CSV_FILE_DELETED);
    }

    dispatch({
      type: actionTypes.UPLOAD_CSV,
      payload: { csv: {} },
    });
  }, [bulkInviteFlagrEnabled, dispatch, trackEvent]);

  return (
    <>
      <p className='p2'>
        Need help getting started?{' '}
        <M.Link
          data-testid='download-csv-template'
          size='lg'
          href={csvTemplate}
          download
          onClick={handleDownloadCSVTemplate}
        >
          Download CSV template
        </M.Link>
      </p>
      <UploadTip>
        <h5>Template instructions:</h5>
        <ul>
          <li>
            &bull; Keep the first row, and add rows for candidate information.
          </li>
          <li>&bull; Phone numbers must contain 10 digits and be US-based.</li>
        </ul>
        <p style={{ marginTop: '1rem' }}>
          Find a record of this order on <BoldText>Payment & billing</BoldText>{' '}
          &gt;{' '}
          <M.Link
            data-testid='redirect-to-bulk-history'
            href='#'
            onClick={handleRedirectToDocuments}
          >
            <BoldText>Bulk order history</BoldText>.
          </M.Link>
        </p>
      </UploadTip>
      <SecureFilestack
        actionOptions={fileStackOptions}
        apikey={filestackKey}
        customRender={fileStackCustomRender}
        onSuccess={handleFileUpload}
      />
      {fileDisplayName && (
        <UploadedFile>
          <M.Icon icon='CheckmarkFilled' size={16} />
          <p className='filename' data-testid='uploaded-file-name'>
            {fileDisplayName}
          </p>
          <DeleteButton
            data-testid='delete-uploaded-csv'
            onClick={handleFileDelete}
          >
            Delete
          </DeleteButton>
        </UploadedFile>
      )}
    </>
  );
};

export default ProvideContactInfoViaUploadSection;
