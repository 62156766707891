import React, { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { Geo } from '@dashboard-experience/utils';
import { includes } from 'lodash';
import type { GetStartedT } from '..';
import { GeoDropdown } from './Dropdown.styles';

const COUNTRIES_NOT_AVAILABLE = ['AF'];

const defaultItemToString = (geo: Geo) => {
  const geoName = geo.name;
  const geoCountry = geo.country;

  if (includes(COUNTRIES_NOT_AVAILABLE, geoCountry)) {
    return `${geoName} (Unavailable)`;
  }

  if (geoCountry === 'GE' && geoName === 'Georgia') {
    return `${geoName} (country)`;
  }

  return geoName;
};

export const geoFilter = ({ item }: { item: Geo }) => {
  return item.name;
};

type Props = {
  geos: Geo[];
  selectedGeo: Geo | null;
  selectedProgram: GetStartedT.Program | null;
  setSelectedGeo: (geo: Geo) => void;
  setSelectedProgram: Dispatch<SetStateAction<GetStartedT.Program | null>>;
  geos_required: boolean;
};

const GeosDropdown: React.FC<Props> = ({
  geos,
  selectedGeo,
  selectedProgram,
  setSelectedGeo,
  setSelectedProgram,
  geos_required,
}) => {
  const resetSelectedPrograms = useCallback(() => {
    setSelectedProgram(null);
  }, [setSelectedProgram]);

  const handleGeoChange = useCallback(
    ({ selectedItem }: { selectedItem: Geo }) => {
      setSelectedGeo(selectedItem);
      if (selectedProgram?.id) {
        resetSelectedPrograms();
      }
    },

    [resetSelectedPrograms, selectedProgram?.id, setSelectedGeo],
  );

  const selectableGeos = useMemo(() => {
    const sortedGeos = geos.sort((a: Geo, b: Geo) => {
      const aString = defaultItemToString(a) ?? '';
      const bString = defaultItemToString(b) ?? '';
      return aString.localeCompare(bString);
    });

    // Add a "None" geo option if geos is not required
    if (!geos_required) sortedGeos.push({ name: 'None', id: 'None' });

    return sortedGeos;
  }, [geos_required, geos]);

  return (
    <GeoDropdown
      className='geo-dropdown-wrapper'
      data-testid='geo-dropdown'
      id='geo-dropdown'
      placeholder='Geo'
      items={selectableGeos}
      itemToString={defaultItemToString}
      onChange={handleGeoChange}
      selectedItem={selectedGeo}
      shouldFilterItem={geoFilter}
    />
  );
};

export default GeosDropdown;
