import React from 'react';
import {
  CaretDown,
  CaretUp,
  Copy,
  Edit,
  M,
  TrashCan,
} from '@dashboard-experience/mastodon';
import { ActionName } from './types';

type Props = {
  name: ActionName;
  tooltip?: boolean;
  onClick?: () => void;
};

const ICONS = {
  positionDown: {
    icon: CaretDown,
    size: 32,
  },
  positionUp: {
    icon: CaretUp,
    size: 32,
  },
  copy: {
    icon: Copy,
    size: 20,
  },
  edit: {
    icon: Edit,
    size: 20,
  },
  remove: {
    icon: TrashCan,
    size: 20,
  },
};

const Icon: React.FC<Props> = ({ name, tooltip, onClick, ...props }) => {
  const IconComponent = ICONS[name].icon;
  const { size } = ICONS[name];

  if (tooltip ?? true) {
    return (
      <M.TooltipDefinition align='top' definition={name}>
        <IconComponent
          data-testid='assess-common-icon'
          onClick={onClick}
          size={size}
          {...props}
        />
      </M.TooltipDefinition>
    );
  }

  return (
    <IconComponent
      data-testid='assess-common-icon'
      onClick={onClick}
      size={size}
      {...props}
    />
  );
};

export default Icon;
