import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

import { BasePackage } from '../../types';

const packageColors: {
  [key in BasePackage]: {
    background: string;
    color: string;
  };
} = {
  checkrdirect_basic_plus_criminal: {
    background: colors.brandPaper2,
    color: colors.brandSlate6,
  },
  checkrdirect_complete_criminal: {
    background: colors.brandNavy3,
    color: colors.brandNavy4,
  },
  checkrdirect_essential_criminal: {
    background: colors.brandAqua4,
    color: colors.uiAqua700,
  },
  checkrdirect_professional_criminal: {
    background: colors.brandNavy3,
    color: colors.brandNavy4,
  },
  checkrdirect_international_basic_plus: {
    background: colors.brandPaper2,
    color: colors.brandSlate6,
  },
  checkrdirect_international_professional: {
    background: colors.brandAqua4,
    color: colors.uiAqua700,
  },
  checkrdirect_international_professional_plus: {
    background: colors.brandNavy3,
    color: colors.brandNavy4,
  },
};

export const getPackageColor = (packageType: BasePackage) =>
  packageColors[packageType];

export type ContainerProps = {
  selected?: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  min-height: 20rem;
  border: ${({ selected = false }) =>
    selected
      ? `1px solid ${colors.uiGrey300}`
      : ` 0.0625rem solid ${colors.uiGrey200}`};
  border-radius: ${({ selected = false }) => (selected ? '6px' : '0.25rem')};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  box-shadow: ${({ selected }) =>
    selected &&
    `0 24px 48px -24px rgba(135, 153, 171, 0.15),
      0 16px 24px -12px rgba(6, 85, 111, 0.1);
`};

  @media (max-width: 720px) {
    &:first-child {
      margin-bottom: 24px;
    }
  }

  &.most-popular {
    border-radius: 0 0 0.25rem 0.25rem;

    .package-card-header {
      border-radius: ${({ selected = false }) => (selected ? '0' : '0.25rem')};
    }
  }
`;

type HeaderProps = {
  packageType: BasePackage;
  selected: boolean;
};

export const Header = styled.div.attrs({
  className: 'package-card-header',
})<HeaderProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ selected = false }) =>
    selected ? '5px 5px 0 0' : '0.25rem'};
  font-size: 0.875rem;
  font-weight: 400;
  background-color: ${({ packageType }) =>
    getPackageColor(packageType).background};
  position: relative;
  margin: ${({ selected = false }) => (selected ? '0' : '0.25rem')};
  padding: ${({ selected = false }) => (selected ? '1.25rem' : '1rem')};
`;

type PackageNameProps = {
  dark?: boolean;
};

export const PackageName = styled.h4.attrs({
  className: 'package-card-name mb-0',
})<PackageNameProps>`
  color: ${({ dark = true }) =>
    dark ? colors.uiGrey900 : colors.uiGrey0} !important;
`;

type PackageDescriptionProps = {
  dark?: boolean;
};

export const PackageDescription = styled.p.attrs({
  className: 'package-card-description',
})<PackageDescriptionProps>`
  color: ${({ dark = true }) =>
    dark ? colors.uiTextSecondaryLight : colors.uiTextSecondaryDark} !important;
  margin-top: 0 !important;
`;

export const MostPopular = styled.div.attrs({
  className: 'package-card-most-popular',
})`
  padding: 0.375rem 1.25rem;
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  background: ${colors.uiAqua400};
  color: ${colors.uiTextPrimaryLight};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
  border: 1px solid ${colors.uiGrey200};
  border-bottom: 0;
`;

export const PriceContainer = styled.div.attrs({
  className: 'package-card-price-container',
})`
  display: flex;
  gap: 4px;
  padding: 0 0 8px 0;
`;

export const ReportText = styled.p.attrs({
  className: 'package-card-report-text',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    align-self: center;
    margin-top: 0;
  }
`;

export const PackageCardBody = styled.div.attrs({
  className: 'package-card-body',
})`
  display: flex;
  padding: 20px 20px 24px 20px;
  flex-direction: column;
  gap: 12px;
`;

export const SelectedCheckContainer = styled.div.attrs({
  className: 'package-card-selected-check-container',
})`
  position: absolute;
  z-index: 2;
  right: 0.5rem;
  top: 100%;
  transform: translateY(-50%);
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 auto;
  background: ${colors.uiGrey0};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 50%;
`;
