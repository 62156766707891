import { M } from '@dashboard-experience/mastodon';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useBetaState } from 'providers/Beta';
import React from 'react';
import AdjudicationEngagedBanner from './adjudication-engaged-banner';
import AdjudicationPreAABanner from './adjudication-pre-aa-banner';
import CandidateStoryRequestedBanner from './candidate-story-requested-banner';
import InvitationExpiredBanner from './invitation-expired-banner';
import InvitationQueuedBanner from './invitation-queued-banner';
import InvitationSentBanner from './invitation-sent-banner';
import ReportArchivedBanner from './report-archived-banner';
import ReportDisputedBanner from './report-disputed-banner';
import RequestCandidateStoryBanner from './request-candidate-story-banner';

const RedesignBanners = () => {
  const candidateRedesignEnabled = useBetaState(CANDIDATE_REDESIGN);

  // None of these banners should be shown to people without the redesign flag
  if (!candidateRedesignEnabled) {
    return null;
  }

  return (
    <M.Grid>
      <M.GridRow>
        <InvitationQueuedBanner />
        <InvitationExpiredBanner />
        <InvitationSentBanner />
        <AdjudicationEngagedBanner />
        <AdjudicationPreAABanner />
        <ReportArchivedBanner />
        <ReportDisputedBanner />
        <RequestCandidateStoryBanner />
        <CandidateStoryRequestedBanner />
        {/* Add in other banners here as needed */}
      </M.GridRow>
    </M.Grid>
  );
};

export default RedesignBanners;
