import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ADJUDICATION_KINDS, GenericObject } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useSetAdjudication } from 'modules/adjudication/api';
import {
  useGetPostSubtypePayload,
  Subtype,
} from 'modules/adjudication/utilities';
import { useGetReport } from 'api/reports';
import { ModalFooter } from 'modules/adjudication/ui/common';

type Props = {
  selection: Subtype | undefined;
  onClose: () => void;
};

const Confirm: React.FC<Props> = ({ selection, onClose }) => {
  const { t } = useTranslation();
  const cancel = t(`${namespace}:report.actions.defer.modal.footer.cancel`);
  const confirm = t(`${namespace}:report.actions.defer.modal.footer.confirm`);
  const message = t(`${namespace}:report.actions.defer.modal.confirmMessage`);

  const { candidateId }: GenericObject = useParams();
  const { report } = useGetReport();
  const payload = useGetPostSubtypePayload({
    subtypeKind: ADJUDICATION_KINDS.DEFERRED,
    selection,
  });
  const { call } = useSetAdjudication(report?.id, candidateId); // correct function? should be yes

  const close = {
    name: cancel,
    onClick: onClose,
  };

  const next = {
    name: confirm,
    onClick: () => {
      call(payload);
      onClose();
    },
  };

  return (
    <>
      <h4
        style={{
          textAlign: 'center',
          marginTop: '2.5rem',
          marginBottom: '2.5rem',
        }}
      >
        {message}
      </h4>
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default Confirm;
