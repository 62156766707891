import React from 'react';
import { useGetReport } from 'api/reports';
import {
  ReportStatuses,
  ADJUDICATION_VALUES,
} from '@dashboard-experience/utils';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const { report: { adjudication, status } = {} } = useGetReport();

  const displayChangeDecision =
    adjudication === ADJUDICATION_VALUES.ENGAGED &&
    status !== ReportStatuses.PENDING;

  return displayChangeDecision ? <>{children}</> : null;
};

export default Guard;
