import React, { useCallback, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { FlagrContext } from '@dashboard-experience/react-flagr';
import { useTrackEvent } from 'utils';
import { Tab } from 'components/DashboardWrapper/tabs';
import { useUser } from 'context/CurrentUser';
import { Link } from 'react-router-dom';
import { ExternalLink } from './icons';

type Props = {
  titleKey: string;
  tab: Tab;
};

const SubmenuItem: React.FC<Props> = ({ titleKey, tab }) => {
  const user = useUser();
  const { flags } = useContext(FlagrContext) as any;
  const { t } = useTranslation('', { keyPrefix: 'navigation' });
  const trackEvent = useTrackEvent();
  const { icon, openNewTab } = tab;
  const testId = ['nav_link', titleKey, 'side-nav'].filter(Boolean).join('-');

  const commonElementProps = {
    id: `${titleKey}_left`, // This ID is required for Pendo elements to latch onto
    ...(titleKey ? { 'data-pendo-id': `nav-${titleKey}` } : {}),
    'data-testid': testId,
  };

  const url = typeof tab.url === 'function' ? tab.url(user, flags) : tab.url;

  const trackingEventName = tab.tracking?.(user, flags);

  const clickHandler = useCallback(
    e => {
      // If this item has a defined tracking event associated with it, then trigger that event onclick
      if (trackingEventName) {
        trackEvent(trackingEventName);
      }
      if (openNewTab) {
        window.open(url, '_blank');
        e.preventDefault();
      }
    },
    [trackEvent, trackingEventName, url, openNewTab],
  );

  // Don't show the item if the user doesn't have the proper permissions/flags for it
  if (!tab.authorized(user, flags)) {
    return null;
  }

  return (
    <Link {...commonElementProps} to={url} onClick={clickHandler}>
      <M.ActionMenuItem
        key={titleKey}
        title={t(titleKey)}
        kind='submenu'
        actionNode={icon ? <ExternalLink /> : ''}
      />
    </Link>
  );
};

export default SubmenuItem;
