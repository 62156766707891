import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { Location } from './types';
import {
  LocationLabelContainer,
  LocationlLabelHeaderContainer,
  LabelAddressContainer,
  BusinessHoursContainer,
} from './styles';
import { formatPhoneNumber, getAvailability, ScheduleHelpers } from './utils';
import './LocationSearch.scss';

interface Props {
  location: Location;
  selectedSiteId: string | null | undefined;
  setSelectedClinic: Function;
}

const Panel: React.FC<Props> = ({
  selectedSiteId,
  setSelectedClinic,
  location,
}) => {
  const {
    hours,
    siteId,
    siteName,
    address1,
    address2,
    distance,
    distanceUnit,
    phoneNumber,
    electronicallyEnabled,
  } = location;
  const isSelected = siteId === selectedSiteId;
  const businessHours = getAvailability({
    hours,
  } as Pick<ScheduleHelpers, 'hours'>);
  const businessHoursString = `Open ${businessHours.daysOpen.join(' - ')}`;
  const [isPaperChainModalOpen, setIsPaperChainModalOpen] =
    useState<boolean>(false);

  const TypeTag = electronicallyEnabled
    ? ElectronicLocationTag
    : PaperLocationTag;

  const onLocationChange = useCallback(
    () => setSelectedClinic(location),
    [location, setSelectedClinic],
  );

  const openPaperChainModal = useCallback(() => {
    setIsPaperChainModalOpen(true);
  }, []);

  const closePaperChainModal = useCallback(() => {
    setIsPaperChainModalOpen(false);
  }, []);

  const confirmPaperChainModal = useCallback(() => {
    setIsPaperChainModalOpen(false);
    onLocationChange();
  }, [onLocationChange]);

  return (
    <>
      <M.ComposedModal open={isPaperChainModalOpen}>
        <M.ModalHeader buttonOnClick={closePaperChainModal}>
          Paper custody & control form
        </M.ModalHeader>
        <M.ModalBody>
          This clinic requires that the individual testing take a paper chain of
          custody form with them. Do not choose this clinic unless you have your
          own paper custody & control forms to give to the employee.
        </M.ModalBody>
        <M.ModalFooter>
          <>
            <M.Button kind='secondary' onClick={closePaperChainModal}>
              Cancel
            </M.Button>{' '}
            <M.Button kind='danger' onClick={confirmPaperChainModal}>
              Confirm
            </M.Button>
          </>
        </M.ModalFooter>
      </M.ComposedModal>
      <M.ChoiceCard
        id={siteId}
        type='checkbox'
        name={siteName}
        value={siteId}
        onChange={
          electronicallyEnabled ? onLocationChange : openPaperChainModal
        }
        checked={isSelected}
        title={
          <LocationLabelContainer>
            <LocationlLabelHeaderContainer>
              {siteName?.toUpperCase()}
              <TypeTag />
              <br />
              <LabelAddressContainer>
                {`${address1}

          ${address2 ? `, ${address2}` : ''}
          `}
              </LabelAddressContainer>
            </LocationlLabelHeaderContainer>
            <>{`${distance} ${distanceUnit}`}</>
          </LocationLabelContainer>
        }
        description={
          <LabelAddressContainer>
            <span>{formatPhoneNumber(phoneNumber)}</span>
            {isSelected ? (
              <div>
                {businessHours &&
                  businessHours.dayBlocks.map((block, idx) => (
                    <BusinessHoursContainer key={`days-${idx + 1}`}>
                      <p>
                        <strong>{block.daySpanFull.join(' - ')}</strong>
                      </p>
                      <p>{block.businessHours}</p>
                    </BusinessHoursContainer>
                  ))}
              </div>
            ) : (
              <span>{businessHoursString}</span>
            )}
          </LabelAddressContainer>
        }
      />
    </>
  );
};

const ElectronicLocationTag = () => (
  <IconWrapper>
    <M.Icon icon='Lightning' size='15' />
    <span>Get results faster</span>
  </IconWrapper>
);

const PaperLocationTag = () => (
  <IconWrapper>
    <M.Icon icon='WhitePaper' size='15' />
    <span>Paper custody & control form required</span>
  </IconWrapper>
);

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  padding-top: 5px;
  color: ${colors.uiGrey900};

  svg {
    margin-right: 2px;
  }
`;

export default Panel;
