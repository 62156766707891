import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const SelectableAliasContainer = styled.div`
  margin-top: 1rem;
  width: 100%;

  #mastodon & .mastodon-choice-card {
    margin-right: 0;
    width: 100%;
  }
`;

const SelectableAlias: React.FC<{
  selected: boolean;
  price?: string;
  handleSelect?: Function;
}> = ({ selected, handleSelect = () => {}, price = '0.00' }) => {
  const handleClick = useCallback(() => {
    handleSelect();
  }, [handleSelect]);

  return (
    <SelectableAliasContainer data-testid='selectable-alias-container'>
      <M.ChoiceCard
        data-testid='selectable-alias'
        id='selectable-alias'
        type='checkbox'
        checked={selected}
        onChange={handleClick}
        title='Include alias searches'
        description={`${price} per candidate`}
      />
    </SelectableAliasContainer>
  );
};

export default SelectableAlias;
