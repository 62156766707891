/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { getFromSessionStorage } from 'components/Signup/utils/sessionStorage';
import styled from 'styled-components';
import AddressAutocomplete from './AddressAutocomplete';
import AddressManual from './AddressManual';

const StyledSpan = styled.span.attrs({
  className: 'create-account-address-toggle',
})`
  color: ${colors.uiNavy600};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  line-height: 16px;
`;

const Label = styled.span`
  color: ${colors.uiGrey900};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.25rem;
`;

type Props = {
  values: any;
  disableFields: boolean;
  handleChange: (e: any) => void;
  errors: any;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: (e: any) => void;
  showError: (field: string) => boolean;
  errorMessage: (field: string) => string | null;
};

const AddressInput = ({
  values,
  disableFields,
  handleChange,
  errors,
  setFieldValue,
  handleBlur,
  showError,
  errorMessage,
}: Props) => {
  const createAccountSection = getFromSessionStorage(
    'createAccountSection',
    {},
  );
  const isCreateAccountSectionEmpty =
    Object.keys(createAccountSection).length === 0 ||
    (createAccountSection.street === '' &&
      createAccountSection.city === '' &&
      createAccountSection.state === '' &&
      createAccountSection.zipcode === '');

  const [autoComplete, setAutoComplete] = useState(isCreateAccountSectionEmpty);

  const handleClick = useCallback(() => {
    if (!autoComplete) {
      setFieldValue('street', '');
      setFieldValue('street2', '');
      setFieldValue('city', '');
      setFieldValue('state', '');
      setFieldValue('zipcode', '');
    }
    setAutoComplete(!autoComplete);
  }, [autoComplete, setFieldValue]);

  return (
    <div>
      <Label>Business address</Label>
      <div>
        {autoComplete ? (
          <AddressAutocomplete
            setFieldValue={setFieldValue}
            disableFields={disableFields}
            showError={showError}
            setAutoComplete={setAutoComplete}
          />
        ) : (
          <AddressManual
            setFieldValue={setFieldValue}
            values={values}
            disableFields={disableFields}
            handleChange={handleChange}
            handleBlur={handleBlur}
            showError={showError}
            errorMessage={errorMessage}
          />
        )}
      </div>
      <StyledSpan onClick={handleClick} data-testid='manual-button'>
        {autoComplete ? 'Input manually' : 'Search address'}
      </StyledSpan>
    </div>
  );
};

export default AddressInput;
