export const CARD_METHOD_TYPE = 'card';
export const BANK_ACCOUNT_METHOD_TYPE = 'bank_account';
export const PAYMENT_METHODS_QUERY_KEY = 'payment/payment_methods';
export const BILLING_CUSTOMER_QUERY_KEY = 'billing/preferences';
export const PATH = '/accounts';
export const CREDIT_CARDS_PATH = 'credit_cards';
export const BILLING_PREFERENCES_PATH = 'billing_preferences';
export const ADDRESSES_PATH = 'address';
export const CONTACTS_PATH = 'contacts';
export const PAYMENT_METHODS_PATH = 'payment_methods';
export const SET_DEFAULT_PATH = 'set_default';
export const BANK_ACCOUNTS_PATH = 'bank_accounts';
export const SETUP_INTENT_PATH = 'setup_intent';
export const HELP_PAYMENT_BILLING_URL =
  'https://help.checkr.com/hc/en-us/articles/4429308795927-Payment-billing';
export const STRIPE_URL = 'https://www.stripe.com';
export const DUE_ON_RECEIPT = 'Due on receipt';
export const TAX_EXEMPTION_CERTIFICATES_PATH = 'tax_exemption_certificates';
export const ENABLE_TAX_FOR_CUSTOMER_PATH = 'enable_tax_for_customer';
