class CreditReportRecordUtils {
  private readonly records: any;

  public constructor(records: any) {
    this.records = records;
  }

  public isDomestic(): boolean {
    if (!this.records) return false;

    return (
      this.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name === 'United States' ||
          creditRecord.country_name === 'US',
      ) !== undefined
    );
  }

  public isInternationalHasNonEmptyRecords(): boolean {
    if (!this.records) return false;

    return (
      this.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name && creditRecord.pdf_url,
      ) !== undefined
    );
  }

  public isEmpty(): boolean {
    if (!this.records) return false;

    return (
      this.records.find(
        (creditRecord: { country_name: string }) =>
          creditRecord.country_name !== 'United States',
      ) !== undefined
    );
  }

  public findDomesticRecords(): any {
    if (!this.records) return [];

    return this.records.filter(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        !creditRecord.pdf_url,
    );
  }

  public findInternationalRecords(): any {
    if (!this.records) return undefined;

    return this.records.find(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        creditRecord.country_name && creditRecord.pdf_url,
    );
  }

  public findInternationalEmptyObject(): any {
    if (!this.records) return undefined;

    return this.records.find(
      (creditRecord: { country_name: string }) =>
        creditRecord.country_name !== 'United States',
    );
  }
}

export default CreditReportRecordUtils;
