import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { Footer, StepContainer } from '../ui';

const AddonsStep: React.FC<{}> = () => {
  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();
  const { contextId } = useContext(UIContext);
  // TODO: Update showShip
  const showSkip = false;

  const handleBack = useCallback(() => {
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleContinueAndSkip = useCallback(() => {
    const path = '/order-experience/review-and-submit';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [history, contextId]);

  return (
    <StepContainer data-testid='addons-step-container'>
      Addons Step Placeholder
      <Footer>
        <M.Button
          data-testid='addons-step-back-btn'
          type='button'
          onClick={handleBack}
        >
          Go back
        </M.Button>
        {showSkip ? (
          <M.Button
            data-testid='addons-step-skip-btn'
            type='button'
            onClick={handleContinueAndSkip}
          >
            Skip
          </M.Button>
        ) : (
          <M.Button
            type='button'
            data-testid='addons-step-continue-btn'
            disabled={disabled}
            onClick={handleContinueAndSkip}
          >
            Continue
          </M.Button>
        )}
      </Footer>
    </StepContainer>
  );
};

export default AddonsStep;
