import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const FederalCriminalSearch: React.FC<ScreeningProps> = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}) => {
  return (
    <FlexContainer data-testid='federal-criminal-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-federal-criminal-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Federal criminal search'
            priceByType='federal_criminal_search'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Search all 94 federal district criminal courts; often used when
            hiring C-level executives, CPAs, financial staff, and people working
            with vulnerable populations.
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Find federal crimes such as kidnapping, embezzlement, and drug trafficking in one source.' />
              <BulletPoint text="Find records that county and state criminal searches don't include." />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Limited PII from the source means limited reportable records.' />
              <BulletPoint text='Records exclude convictions prosecuted at the state level (most crimes).' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='federal_criminal_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default FederalCriminalSearch;
