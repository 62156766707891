import * as Account from 'modules/core-types/account';
import { Initializer } from 'state/utilities/state';
import * as Base from './base';

export type Type = Base.Type & {
  type?: Base.Kind.ACCOUNT;
};

export const initialize: Initializer<Type> = (account: Account.Type) => ({
  id: account.id,
  type: Base.Kind.ACCOUNT,
  name: account.name,
});

export type Map = { [key: string]: Account.Type };
