import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { hasPermission } from '@dashboard-experience/utils';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import {
  useAdjudicationEvents,
  useReportActionEvents,
} from 'modules/adjudication/ui/report/events/api';
import processEvents from 'modules/adjudication/ui/report/events/processEvents';
import { useUser } from 'context/CurrentUser';

import { filterTimeline, TimelineEvent } from '../../components/Timeline';
import { fetchTimeline } from './actions';

export type FetchTimelineResponse = {
  isLoading: boolean;
  isError: boolean;
  timeline: TimelineEvent[];
};

export const useFetchTimelines = (
  contextName: string,
  contextId: string,
): FetchTimelineResponse => {
  const key: string = `${contextName}/${contextId}`;
  const user = useUser();
  const canViewReportLogs = hasPermission(user, 'read_report_logs');

  const request = () => fetchTimeline(contextName, contextId);

  const { data, isLoading, isError } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!contextId && canViewReportLogs,
    staleTime: 60000,
  });

  const timeline = data ? filterTimeline(data as TimelineEvent[]) : [];

  return { isError, isLoading, timeline };
};

export const useFetchEvents = (): TimelineEvent[] => {
  const events: TimelineEvent[] = [];

  const report = useReport();
  const { id: reportId } = report;
  const { data: adjudicationEvents } = useAdjudicationEvents(reportId);
  const { adverseActions } = useAdverseActions(reportId);
  const { data: reportActionEvents } = useReportActionEvents(reportId);
  const { t } = useTranslation(namespace);

  const processedEvents = processEvents(
    t,
    report,
    adverseActions,
    reportActionEvents || {},
    adjudicationEvents || [],
  );

  processedEvents.forEach(e => {
    events.push({
      adjudication: e.text,
      event: 'update',
      source: 'events',
      time: e.time,
      type: 'adverse_action_event',
    });
  });

  return events;
};
