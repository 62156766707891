import { useContext } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { hasPermission, CurrentUser } from '@dashboard-experience/utils';
import UserContext from 'context/CurrentUser';
import { ASSESS_OVERRIDE_MVR_CONTEXT_DATES } from 'Flags';
import * as Account from 'modules/core-types/account';
import { useNamespace } from './router/context';
import { Namespace } from '../api';
import { RulesetSettings } from '../models';
import { useConfig as useApiConfig } from './api';

export function useMVR() {
  const account = useUserAccount();
  return account?.assess_mvr_authorized;
}

export function useMVRCustomCategories() {
  const account = useUserAccount();
  return !!account?.assess_mvr_custom_categories;
}

export const useReadOnly = () => {
  const currentUser = useUser();

  return (
    hasPermission(currentUser, 'assess_view') &&
    !hasPermission(currentUser, 'assess_edit')
  );
};

export function useUser(): CurrentUser | undefined {
  const user = useContext(UserContext);
  return user;
}

export function useUserAccount(): Account.Type | undefined {
  const user = useUser();
  return user?.account as Account.Type;
}

export function useRulesetConfig(): RulesetSettings | null | undefined {
  const config = useConfig();
  const namespace = useNamespace();

  switch (namespace) {
    case Namespace.criminal:
      return config?.criminal;
    case Namespace.mvr:
      return config?.mvr;
    default:
      return null;
  }
}

export function useAssignments(): boolean {
  const config = useRulesetConfig();
  return config?.rulesets?.assignments?.enabled || false;
}

export function useAllowRulesetCreation(): boolean {
  const config = useRulesetConfig();
  if (!config?.rulesets) {
    return false;
  }

  return config.rulesets.max > config.rulesets.current_count;
}

export function useCanDeleteRuleset(): boolean {
  const config = useRulesetConfig();
  const current = config?.rulesets?.current_count || 0;
  return current > 1;
}

export function useConfig() {
  const accountId = useUserAccount()?.id;
  return useApiConfig(accountId)?.data;
}

export function useAssessMvrContextDateOverride(): boolean {
  return useFlag(ASSESS_OVERRIDE_MVR_CONTEXT_DATES)?.variantKey === 'true';
}

export function useContextDatesOverride(): boolean {
  const account = useUserAccount();
  const namespace = useNamespace();
  const mvrEnabled = useAssessMvrContextDateOverride();

  const enabled = account?.assess_context_date_override;

  if (!enabled || !namespace) {
    return false;
  }

  if (namespace === Namespace.mvr) {
    return mvrEnabled;
  }

  return true;
}
