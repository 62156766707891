import { CandidateBanners } from 'components/Candidate';
import { OrderNewReport } from 'containers/Candidate';
import { useCandidateId } from 'providers/Candidate';
import React, { memo } from 'react';
import RedesignBanners from '../components/banners/redesign-banners';
import ExceptionBanners from '../exceptions/banners/exception-banners';
import RoutesContent from './routes-content';

const Content = () => {
  const candidateId = useCandidateId();

  return (
    <div
      data-testid='candidate-entries'
      style={{ padding: '2.5rem 2rem 0 2rem' }}
    >
      <CandidateBanners />
      <RedesignBanners />
      <ExceptionBanners />
      <RoutesContent candidateId={candidateId} />
      <OrderNewReport />
    </div>
  );
};

export default memo(Content);
