import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  State,
  US_STATES,
} from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import { CandidatePostalAddress } from 'types';
import styled from 'styled-components';

const GridCol = styled(M.GridCol)`
  padding-left: 0 !important;
`;

type Props = {
  address: CandidatePostalAddress;
  disabled?: boolean;
  handleAddress: Function;
};

const AddressManual: React.FC<Props> = ({
  address,
  disabled,
  handleAddress,
}) => {
  const handleStreetChange = useCallback(
    e => {
      handleAddress({ ...address, street: e.target.value });
    },
    [address, handleAddress],
  );

  const handleStreet2Change = useCallback(
    e => {
      handleAddress({ ...address, street2: e.target.value });
    },
    [address, handleAddress],
  );

  const handleCityChange = useCallback(
    e => {
      handleAddress({ ...address, city: e.target.value });
    },
    [address, handleAddress],
  );

  const handleStateChange = useCallback(
    e => {
      const state = e.selectedItem ? e.selectedItem.abbreviation : '';

      handleAddress({
        ...address,
        state,
      });
    },
    [address, handleAddress],
  );

  const handleZipcodeChange = useCallback(
    e => {
      handleAddress({ ...address, zipcode: e.target.value });
    },
    [address, handleAddress],
  );

  return (
    <M.Grid
      style={{ marginTop: '0 !important' }}
      data-testid='manual-address-form'
    >
      <M.GridRow>
        <GridCol>
          <M.Input
            className='address-field'
            data-testid='manual-address-street'
            disabled={disabled}
            id='street'
            labelText=''
            placeholder='Street'
            defaultValue={address.street}
            onChange={handleStreetChange}
          />
        </GridCol>
        <GridCol>
          <M.Input
            className='address-field'
            data-testid='manual-address-street2'
            disabled={disabled}
            id='street2'
            labelText=''
            placeholder='Unit/Suite'
            defaultValue={address.street2}
            onChange={handleStreet2Change}
          />
        </GridCol>
      </M.GridRow>
      <M.GridRow>
        <GridCol>
          <M.Input
            className='address-field'
            data-testid='manual-address-city'
            disabled={disabled}
            id='city'
            labelText=''
            placeholder='City'
            defaultValue={address.city}
            onChange={handleCityChange}
          />
        </GridCol>
        <GridCol>
          <M.ComboBox
            className='address-field'
            data-testid='manual-address-state'
            disabled={disabled}
            id='state'
            items={US_STATES}
            itemToString={useCallback((item: State) => item?.name, [])}
            placeholder='State'
            initialSelectedItem={US_STATES.find(
              state => state.abbreviation === address.state,
            )}
            selectedItem={US_STATES.find(
              state => state.abbreviation === address.state,
            )}
            onChange={handleStateChange}
          />
        </GridCol>
      </M.GridRow>
      <M.GridRow>
        <GridCol>
          <M.Input
            className='address-field'
            data-testid='manual-address-zipcode'
            disabled={disabled}
            id='zipcode'
            labelText=''
            placeholder='Zipcode'
            defaultValue={address.zipcode}
            onChange={handleZipcodeChange}
          />
        </GridCol>
        <M.GridCol />
      </M.GridRow>
    </M.Grid>
  );
};

export default AddressManual;
