export enum taskTableColumns {
  name = 'name',
  type = 'type',
  assessment = 'assessment',
  package = 'package',
  location = 'location',
  completed_at = 'completed_at',
  owner = 'owner',
}

export const sortableTaskTableColumns = [taskTableColumns.completed_at];
