import React, { useCallback } from 'react';
import { useGetAccountSettings, useGetLastToSContent } from 'api/i9';
import { M } from '@dashboard-experience/mastodon';
import { FormI9, AccountSetupUpsell } from '../components/FormI9';

const FormI9Page = () => {
  const accountSettings = useGetAccountSettings();
  const { isLoading, isError } = accountSettings;
  const { data: tosData, isLoading: tosLoading } = useGetLastToSContent();

  const updateAccountSettings = useCallback(() => {
    accountSettings.refetch();
  }, [accountSettings]);

  if (isLoading || tosLoading) {
    return <M.LoadingInline description='Loading...' />;
  }

  const renderSetup = !isLoading && isError;

  return renderSetup ? (
    <AccountSetupUpsell tosData={tosData} />
  ) : (
    <FormI9
      settingsData={accountSettings.data}
      changedDefaultWorksite={updateAccountSettings}
    />
  );
};

export default FormI9Page;
