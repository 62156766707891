import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { unparse } from 'papaparse';
import { downloadBlobAsFile } from 'utils/APIClient';
import { CandidateInvitesTable } from './CandidateInvitesTable';
import {
  StyledDescription,
  StyledDownloadCSVLink,
} from './CandidateInfoSection.styles';
import { namespace } from '../../locales';

const CandidateInfoSection = () => {
  const { t } = useTranslation(namespace);
  const {
    invites,
    csv,
    manualOrderType,
    infoSource,
    infoMethod,
    manualBulkUploadData,
  } = useBetterOrderExperienceContext();

  const downloadManualBulkCsv = useCallback(
    (e: Event) => {
      e.preventDefault();

      const blob = new Blob([unparse(manualBulkUploadData.parsedData)]);
      downloadBlobAsFile({
        data: blob,
        headers: {
          'content-type': 'application/CSV',
        },
        filename: manualBulkUploadData.fileName,
      });
    },
    [manualBulkUploadData?.fileName, manualBulkUploadData?.parsedData],
  );

  if (infoSource === 'CANDIDATE') {
    if (csv.fileDisplayName && infoMethod === 'UPLOAD') {
      return (
        <>
          <StyledDescription>
            {t(`reviewAndSubmit.infoSection.manualBulkCSVText`)}
          </StyledDescription>
          <StyledDownloadCSVLink
            href={csv.fileS3URL}
            data-testid='uploaded-csv-link'
            size='lg'
          >
            {csv.fileDisplayName}
          </StyledDownloadCSVLink>
        </>
      );
    }

    if (invites.length !== 0) {
      return (
        <>
          <StyledDescription>
            {t(`reviewAndSubmit.infoSection.invitationLinkText`)}
          </StyledDescription>
          <CandidateInvitesTable invites={invites} />
        </>
      );
    }
  }

  if (manualOrderType === 'MULTIPLE') {
    return (
      <>
        <StyledDescription>
          {t(`reviewAndSubmit.infoSection.manualBulkCSVText`)}
        </StyledDescription>
        <StyledDownloadCSVLink
          data-testid='uploaded-manual-bulk-csv-link'
          size='lg'
          onClick={downloadManualBulkCsv}
          download
        >
          {manualBulkUploadData.fileName}
        </StyledDownloadCSVLink>
      </>
    );
  }

  return null;
};

export default CandidateInfoSection;
