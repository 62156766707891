import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

const AdverseActionEvent: React.FC<{ timelineEvent: TimelineEvent }> = ({
  timelineEvent,
}) => {
  return (
    <M.TableRow>
      <TimelineCell>
        <EventName data-testid='report-timeline-adverse-event-name'>
          {timelineEvent.adjudication}
        </EventName>
      </TimelineCell>
      <TimelineCell data-testid='report-timeline-adverse-event-placeholder' />
      <TimelineCell>
        <Timestamp data-testid='report-timeline-adverse-event-time'>
          {moment(timelineEvent.time).format('llll')}
        </Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default AdverseActionEvent;
