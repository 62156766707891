import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNamespace } from 'modules/assess/ui/router/context';

export const Description: React.FC = () => {
  const { t } = useTranslation();
  const namespace = useNamespace();
  const label = t(`assess:context_dates.${namespace}.description`);

  return <p>{label}</p>;
};

export default Description;
