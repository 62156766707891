import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { M, Upload } from '@dashboard-experience/mastodon';
import ReactFilestack from 'filestack-react';
import {
  brandingFilestackKey as filestackKey,
  filestackOptions,
} from 'Constants';
import { BrandsButton } from 'components/Account/Brands';
import { useSaveBrand } from 'api/brand/hooks';
import { useFilestackUploadResponse } from 'hooks';
import { toastError } from 'actions';

type AddBrandProps = {
  page: number;
};

const INITIAL_BRAND_STATE = {
  logo_uri: '',
  name: '',
  website: '',
};

const AddBrand: React.FC<AddBrandProps> = ({ page }) => {
  const [brand, setBrand] = useState(INITIAL_BRAND_STATE);
  const dispatch = useDispatch();
  const { call: saveBrands } = useSaveBrand(page);

  const handleSave = useCallback(() => {
    saveBrands(brand).then(() => setBrand(INITIAL_BRAND_STATE));
  }, [brand, saveBrands]);

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;
      setBrand(prevBrandValues => ({
        ...prevBrandValues,
        [name]: value,
      }));
    },
    [setBrand],
  );

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatch(toastError('Failed to upload file'));
    },
    successCallback: file => {
      setBrand(prevBrandValues => ({
        ...prevBrandValues,
        logo_uri: file.url,
      }));
    },
  });

  const customRender = useCallback(
    ({ onPick }) => (
      <BrandsButton
        className='light'
        style={{ margin: 'auto' }}
        onClick={onPick}
      >
        <span>{brand.logo_uri ? 'Logo Uploaded!' : 'Upload Brand Logo!'}</span>
        &nbsp;
        {!brand.logo_uri ? <Upload size={16} /> : null}
      </BrandsButton>
    ),
    [brand.logo_uri],
  );

  return (
    <M.GridRow style={{ margin: '1rem' }} data-testid='addbrand-container'>
      <M.GridCol lg={3} style={{ padding: '0px' }}>
        <ReactFilestack
          actionOptions={filestackOptions}
          apikey={filestackKey}
          customRender={customRender}
          onSuccess={onFileUpload}
        />
      </M.GridCol>
      <M.GridCol lg={6} style={{ paddingLeft: '0px' }}>
        <M.Input
          type='text'
          onChange={handleChange}
          value={brand.name || ''}
          name='name'
          placeholder='Enter brand name (required)'
        />
      </M.GridCol>
      <M.GridCol lg={4}>
        <M.Input
          type='text'
          onChange={handleChange}
          value={brand.website || ''}
          name='website'
          placeholder='Enter brand website (optional)'
        />
      </M.GridCol>
      <M.GridCol lg={2}>
        <M.Button style={{ margin: 'auto' }} onClick={handleSave}>
          Add Brand
        </M.Button>
      </M.GridCol>
    </M.GridRow>
  );
};

export default AddBrand;
