import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { ADD_ONS_PRICES } from 'components/Signup/constants/screenings';
import { priceToString } from 'components/Signup/utils/pricing';
import useCalculatePrices from 'components/Signup/hooks/useCalculatePrices';

import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils';
import useGetUser from 'components/Signup/hooks/useGetUser';
import {
  Container,
  TitleContainer,
  InnerContainer,
  MostCommonlyAdded,
  Icon,
} from './styles';
import { PageSubtitle, DisclaimerLink, PriceDisclaimer } from '../../styles';
import CommonAddOn from '../CommonAddOn';
import OrderSummary from '../OrderSummary';
import { useSignupPageContext } from '../../../../pages/Signup/context';
import BasePackages from '../../constants/basePackages';
import { AddOnsType } from '../../types';

const CustomizeWithAddOns = () => {
  const { t } = useTranslation('signup');
  const trackEvent = useTrackEvent();
  const { addOns, setAddOns, selectedPackage, candidates, alias } =
    useSignupPageContext();
  const selectedPackageAddOns = addOns[selectedPackage];
  const mvrSelected = selectedPackageAddOns.includes('motor_vehicle_report');
  const federalCriminalSelected = selectedPackageAddOns.includes(
    'federal_criminal_search',
  );
  const educationVerificationSelected = selectedPackageAddOns.includes(
    'education_verification',
  );
  const employmentVerificationSelected = selectedPackageAddOns.includes(
    'employment_verification',
  );

  const { aliasPrice, subtotal, basePackagePrice, customPackagePrice } =
    useCalculatePrices();

  const handleAdd = useCallback(
    (addOn: AddOnsType) => {
      setAddOns((prevAddOns: any) => {
        const currentPackageAddOns = prevAddOns[selectedPackage] || [];
        return {
          ...prevAddOns,
          [selectedPackage]: [...currentPackageAddOns, addOn],
        };
      });
    },
    [selectedPackage, setAddOns],
  );

  const handleRemove = useCallback(
    (addOn: AddOnsType) => {
      setAddOns((prevAddOns: any) => {
        const currentPackageAddOns = prevAddOns[selectedPackage] || [];
        return {
          ...prevAddOns,
          [selectedPackage]: currentPackageAddOns.filter(
            (a: any) => a !== addOn,
          ),
        };
      });
    },
    [selectedPackage, setAddOns],
  );

  const handleLearnMoreLinkedClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_LEARN_MORE_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  const { data, isLoading } = useGetUser();

  let showCompleteCrim = false;
  let cohorts = [];

  if (data && !isLoading) {
    cohorts = data?.account?.cohorts;
    showCompleteCrim = cohorts?.some(
      (cohort: { name: string }) =>
        cohort.name === 'ab-10-good-better-best-test' ||
        cohort.name === 'ab-11-good-better-best-test-assisted',
    );
  }

  return !isLoading ? (
    <Container>
      <TitleContainer>
        <h3 className='mb-0'>{t('components.CustomizeWithAddons.title')}</h3>
        <PageSubtitle>
          {t('components.CustomizeWithAddons.subtitle')}
        </PageSubtitle>
      </TitleContainer>
      <InnerContainer>
        <MostCommonlyAdded>
          <CommonAddOn
            name={t('components.CustomizeWithAddons.commonAddon1.name')}
            description={t(
              'components.CustomizeWithAddons.commonAddon1.description',
            )}
            screening='motor_vehicle_report'
            price={priceToString(ADD_ONS_PRICES.motor_vehicle_report)}
            icon={<Icon name='DeliveryTruck' />}
            selected={mvrSelected}
            onAddClick={handleAdd}
            onRemoveClick={handleRemove}
          />
          {!showCompleteCrim && (
            <CommonAddOn
              name={t('components.CustomizeWithAddons.commonAddon2.name')}
              description={t(
                'components.CustomizeWithAddons.commonAddon2.description',
              )}
              screening='federal_criminal_search'
              price={priceToString(ADD_ONS_PRICES.federal_criminal_search)}
              icon={<Icon name='Explore' />}
              selected={federalCriminalSelected}
              onAddClick={handleAdd}
              onRemoveClick={handleRemove}
            />
          )}

          {showCompleteCrim && (
            <CommonAddOn
              name={t('components.CustomizeWithAddons.commonAddon4.name')}
              description={t(
                'components.CustomizeWithAddons.commonAddon4.description',
              )}
              screening='employment_verification'
              price={priceToString(ADD_ONS_PRICES.employment_verification)}
              icon={<Icon name='Explore' />}
              selected={employmentVerificationSelected}
              onAddClick={handleAdd}
              onRemoveClick={handleRemove}
            />
          )}
          {selectedPackage !== 'checkrdirect_professional_criminal' && (
            <CommonAddOn
              name={t('components.CustomizeWithAddons.commonAddon3.name')}
              description={t(
                'components.CustomizeWithAddons.commonAddon3.description',
              )}
              screening='education_verification'
              price={priceToString(ADD_ONS_PRICES.education_verification)}
              icon={<Icon name='Education' />}
              selected={educationVerificationSelected}
              onAddClick={handleAdd}
              onRemoveClick={handleRemove}
            />
          )}
        </MostCommonlyAdded>
        <OrderSummary
          packageName={BasePackages[selectedPackage].name}
          basePackagePrice={basePackagePrice}
          customPackagePrice={customPackagePrice}
          screenings={selectedPackageAddOns}
          candidates={candidates}
          screeningPrices={ADD_ONS_PRICES}
          isAliasEnabled={alias[selectedPackage]}
          aliasPrice={aliasPrice}
          subtotal={subtotal}
        />
      </InnerContainer>
      <PriceDisclaimer>
        {t('disclaimer')}
        <DisclaimerLink onClick={handleLearnMoreLinkedClick}>
          {' '}
          {t('disclaimerLink')}
        </DisclaimerLink>
        .
      </PriceDisclaimer>
    </Container>
  ) : (
    <M.LoadingBlock />
  );
};

export default CustomizeWithAddOns;
