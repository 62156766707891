import React, { useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Assignment, Ruleset } from 'modules/assess/models/rulesets';
import { SelectedAssignments } from 'modules/assess/models/rulesets/assignment';
import { Base } from 'modules/assess/models/assignables';
import Edit from './Edit';
import AppliesTo from './AppliesTo';

const Container = styled.div`
  height: 700px;
  > * {
    margin: 2em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  assignments?: Assignment.List;
  rulesets?: Array<Ruleset.Type>;
  setAssignments?: React.Dispatch<React.SetStateAction<Assignment.List>>;
};

type Category = 'account' | 'geo' | 'segment' | 'package' | 'role' | 'state';

export const Content: React.FC<Props> = ({
  assignments = [],
  rulesets,
  setAssignments,
}) => {
  const [currentItems, setCurrentItems] = useState<SelectedAssignments>({
    account: [],
    geo: [],
    segment: [],
    package: [],
    role: [],
    state: [],
  });

  const isNotAccountDefault =
    assignments.length > 1 || assignments[0]?.id !== 'ACCOUNT_DEFAULT_ID';
  const hasAssignments = assignments.length > 0;

  const showAssignToSection = hasAssignments && isNotAccountDefault;

  const categorizeAssignment = (assignment: Assignment.Type): Category[] => {
    const categories: Category[] = [];

    if (Base.Kind.GEO in assignment) {
      categories.push('geo');
    }
    if (Base.Kind.SEGMENT in assignment) {
      categories.push('segment');
    }
    if (Base.Kind.ROLE in assignment) {
      categories.push('role');
    }
    if (Base.Kind.PACKAGE in assignment) {
      categories.push('package');
    }
    if (Base.Kind.STATE in assignment) {
      categories.push('state');
    }
    if (assignment.default) {
      categories.push('account');
    }

    return categories;
  };

  useEffect(() => {
    const currentItems: SelectedAssignments = {
      account: [],
      geo: [],
      segment: [],
      package: [],
      role: [],
      state: [],
    };

    assignments.forEach((assignment: Assignment.Type) => {
      const categories = categorizeAssignment(assignment);

      categories.forEach(category => {
        currentItems[category].push(assignment);
      });
    });

    setCurrentItems(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {showAssignToSection && (
        <>
          <AppliesTo currentItems={currentItems} />
          <M.Divider />
        </>
      )}
      <Edit
        assignments={assignments}
        setAssignments={setAssignments}
        currentItems={currentItems}
        rulesets={rulesets}
        setCurrentItems={setCurrentItems}
      />
    </Container>
  );
};

export default Content;
