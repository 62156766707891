/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { ACCOUNT_HIERARCHY_UI_MAX_NODES } from 'Constants';
import { useFetchAllNodes } from 'api/nodes/hooks';
import styled from 'styled-components';
import { useUser } from '../../../context/CurrentUser';

import { newEmptyRow, headers, initialSortInfo } from './table-data';
import useSortInfo from './useSortInfo';
import useSortedRows from './useSortedRows';

import AddButton from './AddButton';
import ErrorBanner from './ErrorBanner';
import NodesTable from './NodesTable';
import PageDescription from './PageDescription';
import SubmitButton from './SubmitButton';
import UploadNodes from './UploadNodes/Container';
import { validCustomIdChars, validNameChars } from './utils';

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20%;
  padding-bottom: 1rem;
`;

const AccountHierarchy = () => {
  const [tiers, setTiers] = useState<string[]>([]);

  const [useAPI, setUseAPI] = useState(false);

  const [modified, setModified] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  const { account } = useUser();

  // @ts-ignore
  const { nodes, isLoading, count, isFetching } = useFetchAllNodes({ account });
  const [sortInfo, setSortInfo] = useSortInfo(initialSortInfo);

  const customIdSet = new Set(rows.map((row: { id: any }) => row.id));
  const areCustomIdsUnique = customIdSet.size === rows.length;

  const invalidChar = rows.some((row: any) => {
    return !validCustomIdChars(row.id) || !validNameChars(row.name);
  });

  const [sortedRows] = useSortedRows(rows, sortInfo);

  useEffect(() => {
    if (isFetching) {
      if (rows.length) {
        // guard against re-setting already-empty values, otherwise we'll have an infinite loop
        setRows([]);
        setTiers([]);
      }
    } else if (!rows.length && nodes) {
      if (count > ACCOUNT_HIERARCHY_UI_MAX_NODES) {
        setUseAPI(true);
      }
      const filteredNodes = nodes.map((node: any) => {
        const id = node.custom_id || node.id;
        return {
          ...node,
          id,
          cells: headers.map((header: any) => {
            return {
              id: header.key,
              value: node[header.key] === null ? '' : node[header.key],
            };
          }),
        };
      });
      setRows(filteredNodes);
      const tiersArray: any = nodes
        ? Array.from(new Set(nodes.map((item: any) => item.tier)))
        : [];

      const filteredTiers = tiersArray.filter((value: any) => {
        return value !== null || value === '';
      });

      filteredTiers.unshift('None');
      setTiers(filteredTiers);
    } else if (!rows.length && !nodes) {
      setTiers(['None']);
      const newRows: any = [newEmptyRow()];
      setRows(newRows);
    }
  }, [rows.length, nodes, count, isFetching]);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <Header>
        <PageDescription />
        <UploadNodes disableUploads={!nodes || nodes.length > 1} />
      </Header>
      <ErrorBanner useAPI={useAPI} />
      {!useAPI && (
        <div className='row'>
          <div className='col-md-12'>
            <NodesTable
              modified={modified}
              setModified={setModified}
              tiers={tiers}
              setTiers={setTiers}
              setRows={setRows}
              sortInfo={sortInfo}
              setSortInfo={setSortInfo}
              sortedRows={sortedRows}
            />
            {!areCustomIdsUnique && (
              <M.InlineNotification
                style={{
                  width: '30%',
                  marginRight: '0px',
                  color: colors.red,
                }}
                hideCloseButton
              >
                Custom IDs must be unique
              </M.InlineNotification>
            )}
            {invalidChar && (
              <M.InlineNotification
                style={{
                  width: '30%',
                  marginRight: '0px',
                  color: colors.red,
                }}
                hideCloseButton
              >
                A field contains invalid characters
              </M.InlineNotification>
            )}

            <AddButton
              sortedRows={sortedRows}
              setRows={setRows}
              setModified={setModified}
            />
            <div className='col-md-12'>
              <SubmitButton
                account={account}
                rows={rows}
                modified={modified}
                setModified={setModified}
                areCustomIdsUnique={areCustomIdsUnique}
                areCharsValid={!invalidChar}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountHierarchy;
