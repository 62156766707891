import { useEffect } from 'react';
import { useGetReport } from 'api/reports';
import {
  useFetchAdverseActionTemplates,
  useSetNewAdverseAction,
} from 'modules/adjudication/api';
import { Template } from 'modules/adjudication/data';
import { useAdverseActionAllowedOnSuspendedReport } from 'modules/adjudication/utilities/adjudication';
import { useDispatch, useModalState } from '../../../context';

export const useInitializeAdverseItems = () => {
  const dispatch = useDispatch();
  const { initialized } = useModalState('state');
  const { templates, isLoading: templatesLoading } =
    useFetchAdverseActionTemplates();
  const {
    newAdverseAction,
    FCI_FLOW_ENABLED,
    OPTIONAL_FCI_FLOW_ENABLED,
    isLoading: aaLoading,
  } = useSetNewAdverseAction();

  const isLoading = templatesLoading || aaLoading;

  useEffect(() => {
    if (!isLoading && !initialized) {
      dispatch({
        type: 'SET_INITIAL_STATE',
        payload: {
          templates: {
            pre: templates?.pre?.selected || null,
            post: templates?.post?.selected || null,
          },
          daysToPostAdverseAction:
            newAdverseAction.daysToPostAdverseAction as number,
        },
      });
    }
  }, [
    dispatch,
    isLoading,
    initialized,
    newAdverseAction.daysToPostAdverseAction,
    templates?.pre?.selected,
    templates?.post?.selected,
  ]);

  return {
    newAdverseAction,
    FCI_FLOW_ENABLED,
    OPTIONAL_FCI_FLOW_ENABLED,
    isLoading,
  };
};

export const useNextDisabled = () => {
  const validAdverseActionPeriod = useValidAdverseActionPeriod();
  const validAdverseItems = useValidAdverseItems();
  const validBasis = useValidBasis();
  const { report } = useGetReport();
  const { templates } = useModalState('state');

  return (
    !validAdverseActionPeriod ||
    !validAdverseItems ||
    !validBasis ||
    (report?.account?.adverse_action_by_jurisdiction &&
      !report?.account?.adverse_action_medium_selection &&
      !(templates.pre && templates.post))
  );
};

export const useValidAdverseItems = function () {
  const { report } = useGetReport();
  const { adverseItems } = useModalState('state');
  const isChargeSelectionOptional =
    report?.account?.adverse_action_charge_selection_optional;
  const anyAdverseItemsSelected = adverseItems?.length > 0;
  const isSelfDisclosureTextSelected = useIsSelfDisclosureTextSelected();

  return (
    isChargeSelectionOptional ||
    anyAdverseItemsSelected ||
    isSelfDisclosureTextSelected
  );
};

export const useValidAdverseActionPeriod = () => {
  const { daysToPostAdverseAction } = useModalState('state');
  return (
    !!daysToPostAdverseAction &&
    daysToPostAdverseAction >= 7 &&
    daysToPostAdverseAction <= 30
  );
};

export const useValidBasis = () => {
  const { basis } = useModalState('state');
  const adverseActionAllowedOnSuspendedReport =
    useAdverseActionAllowedOnSuspendedReport();

  if (!adverseActionAllowedOnSuspendedReport) return true;

  return !!basis;
};

export const useIsSelfDisclosureTextSelected = () => {
  const selectedPreAdverseActionTemplate = useSelectedTemplate('pre');
  const selectedPostAdverseActionTemplate = useSelectedTemplate('post');

  return (
    selectedPreAdverseActionTemplate?.type === 'SelfDisclosureText' &&
    selectedPostAdverseActionTemplate?.type === 'SelfDisclosureText'
  );
};

export const useSelectedTemplate = (type: 'pre' | 'post') => {
  const { templates } = useFetchAdverseActionTemplates();
  const { templates: selectedTemplates } = useModalState('state');

  const adverseActionTemplate = templates && templates[type];
  return adverseActionTemplate?.options.find(
    (t: Template) => t.id === selectedTemplates[type],
  );
};
