import { Screening } from 'components/AddScreenings/types/Screenings';
import {
  screeningPayloadsByType,
  buildEmploymentVerification,
  buildMotorVehicleReport,
  buildDrugScreening,
} from 'components/AddScreenings/shared/screeningPayloads';
import { GenericObject } from '@dashboard-experience/utils';
import {
  BASIC,
  ESSENTIAL,
  PROFESSIONAL,
  COMPLETE_CRIMINAL,
  INTERNATIONAL_BASIC,
  INTERNATIONAL_PROFESSIONAL,
  INTERNATIONAL_GEO_PARAM,
  DOMESTIC_GEO_PARAM,
  BASIC_PACKAGE_TYPE_PARAM,
  COMPLETE_CRIMINAL_PACKAGE_TYPE_PARAM,
  ESSENTIAL_PACKAGE_TYPE_PARAM,
  PROFESSIONAL_PACKAGE_TYPE_PARAM,
  INTERNATIONAL_BASIC_PACKAGE_TYPE__PARAM,
  INTERNATIONAL_PROFESSIONAL_PACKAGE_TYPE_PARAM,
} from './CONSTANTS';

// HELPER FUNCTIONS
export const getBasePackage = (packageType: string) => {
  // Using basic+ as default
  const basePackage = buildBasicPackage();

  if (packageType === ESSENTIAL) {
    return buildEssentialPackage();
  }
  if (packageType === PROFESSIONAL) {
    return buildProfessionalPackage();
  }
  if (packageType === COMPLETE_CRIMINAL) {
    return buildCompleteCriminalPackage();
  }
  if (packageType === INTERNATIONAL_BASIC) {
    return buildInternationalBasicPackage();
  }
  if (packageType === INTERNATIONAL_PROFESSIONAL) {
    return buildInternationalProfessionalPackage();
  }

  return basePackage;
};

export const buildBasicPackage = () => {
  const postPackage = {
    name: 'Basic+',
    slug: 'basic_+',
    base_package_sku: 'BPK-0002',
    price: 2999,
    screenings: [
      {
        type: 'ssn_trace',
      },
      {
        type: 'sex_offender_search',
      },
      {
        type: 'global_watchlist_search',
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
      },
    ],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };

  return postPackage;
};

export const buildEssentialPackage = () => {
  const postPackage = {
    name: 'Essential',
    slug: 'essential',
    base_package_sku: 'BPK-0007-7-7',
    price: 5499,
    screenings: [
      {
        type: 'ssn_trace',
      },
      {
        type: 'sex_offender_search',
      },
      {
        type: 'global_watchlist_search',
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
      },
      {
        type: 'county_criminal_search',
        subtype: '7years',
        requires_education_history: false,
        requires_employment_history: false,
      },
    ],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };

  return postPackage;
};

export const buildEssentialPackageV2 = () => {
  return {
    price: 5499,
    base_package_sku: 'BPK-0007-7-7',
    drug_screening_price: null,
    enabled_exams: [],
    requires_observed_drug_test: false,
    object: 'package',
    applicant_pay: null,
    county_coverage_for_all_state_searches: false,
    dl_history_verification_enabled: false,
    education_history_based_county_criminal_searches: false,
    education_history_based_state_criminal_searches: false,
    employment_history_based_county_criminal_searches: false,
    employment_history_based_state_criminal_searches: false,
    pointer_lookback_in_years_for_county: 7,
    criminal_self_disclosure: false,
    skip_self_disclosure_validation: false,
    name: 'Essential',
    program_id: null,
    program_name: null,
    screenings: [
      {
        type: 'county_criminal_search',
        subtype: '7years',
        preferences: {
          requires_education_history: false,
          requires_employment_history: false,
        },
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
        preferences: null,
      },
      {
        type: 'sex_offender_search',
        subtype: null,
        preferences: null,
      },
      {
        type: 'ssn_trace',
        subtype: null,
        preferences: null,
      },
      {
        type: 'global_watchlist_search',
        subtype: null,
        preferences: null,
      },
    ],
    slug: 'essential',
    county_level_verification_fee: 0,
    skip_county_fees: false,
    skip_mandatory_county_fees: false,
    skip_criminal_searches_in_inaccessible_states_and_counties: false,
    skip_criminal_searches_in_inaccessible_states_and_counties_type: null,
    auto_inaccessible_followup_type: null,
    child_abuse_search_state_values: [],
    inaccessible_followup_type: null,
    request_aliases_hard_cap_threshold: null,
    health_screening_passport_expiration_days: null,
    only_search_states_values: [],
    always_search_states_values: [],
    always_search_counties_values: [],
    minors_allowed: false,
    assess_auto_engage_eligible_reports: false,
    county_update_to_state_search_values: [],
    address_history_lookback_years: null,
    protected_invitation_fields: [
      'first_name',
      'middle_name',
      'last_name',
      'dob',
      'ssn',
      'zipcode',
      'mother_maiden_name',
      'phone',
      'email',
      'current_address',
      'driver_license',
    ],
    version_number: 1,
    county_criminal_search_skip: false,
    origin: null,
    source_version_id: null,
    partner_origin_id: null,
    auto_upgrade_on_assessment: false,
    allow_dashboard_upgrade: false,
    type: 'Package',
    private: false,
    override_default_skip_thin_file_based_on_age: null,
    next_progressive_package_id: null,
    defer_high_cost_searches: false,
    requires_fmcsa_compliance: false,
    protected_invitation_flow: false,
    has_screenings_requiring_data: false,
    continuous_check: false,
    skip_ssn_collection_on_candidate_create: false,
    skip_ssn_trace_exceptions: null,
    candidates_can_declare_no_ssn: false,
    override_international_report_locales: false,
    international_only: false,
    us_only: true,
    screening_settings: [],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };
};

export const buildCompleteCriminalPackage = () => {
  const postPackage = {
    name: 'Complete',
    slug: 'complete',
    base_package_sku: 'BPK-0016-7-7',
    price: 8999,
    screenings: [
      {
        type: 'ssn_trace',
      },
      {
        type: 'sex_offender_search',
      },
      {
        type: 'global_watchlist_search',
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
      },
      {
        type: 'county_criminal_search',
        subtype: '7years',
        requires_education_history: false,
        requires_employment_history: false,
      },
      {
        type: 'federal_criminal_search',
        subtype: '10years',
      },
      {
        type: 'state_criminal_search',
        subtype: '7years',
        requires_education_history: false,
        requires_employment_history: false,
      },
    ],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };

  return postPackage;
};

export const buildCompleteCriminalPackageV2 = () => {
  return {
    price: 8999,
    base_package_sku: 'BPK-0016-7-7',
    drug_screening_price: null,
    enabled_exams: [],
    requires_observed_drug_test: false,
    object: 'package',
    applicant_pay: false,
    county_coverage_for_all_state_searches: false,
    dl_history_verification_enabled: false,
    education_history_based_county_criminal_searches: false,
    education_history_based_state_criminal_searches: false,
    employment_history_based_county_criminal_searches: false,
    employment_history_based_state_criminal_searches: false,
    pointer_lookback_in_years_for_county: 7,
    criminal_self_disclosure: false,
    skip_self_disclosure_validation: false,
    name: 'Complete',
    program_id: null,
    program_name: null,
    screenings: [
      {
        type: 'county_criminal_search',
        subtype: '7years',
        preferences: {
          requires_education_history: false,
          requires_employment_history: false,
          standalone_search_counties: [],
        },
      },
      {
        type: 'federal_criminal_search',
        subtype: '10years',
        preferences: null,
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
        preferences: null,
      },
      {
        type: 'sex_offender_search',
        subtype: null,
        preferences: null,
      },
      {
        type: 'ssn_trace',
        subtype: null,
        preferences: null,
      },
      {
        type: 'state_criminal_search',
        subtype: '7years',
        preferences: {
          requires_education_history: false,
          requires_employment_history: false,
          standalone_search_states: [],
        },
      },
      {
        type: 'global_watchlist_search',
        subtype: null,
        preferences: null,
      },
    ],
    slug: 'checkrdirect_complete_criminal',
    county_level_verification_fee: 0,
    skip_county_fees: false,
    skip_mandatory_county_fees: false,
    skip_criminal_searches_in_inaccessible_states_and_counties: false,
    skip_criminal_searches_in_inaccessible_states_and_counties_type: null,
    auto_inaccessible_followup_type: null,
    child_abuse_search_state_values: [],
    inaccessible_followup_type: null,
    request_aliases_hard_cap_threshold: null,
    aliases_enabled: 'off',
    health_screening_passport_expiration_days: null,
    only_search_states_values: [],
    always_search_states_values: [],
    always_search_counties_values: [],
    minors_allowed: false,
    assess_auto_adverse_action_escalated_reports: false,
    assess_auto_engage_eligible_reports: false,
    candidate_stories_request_on_adverse_action: false,
    adverse_action_pause_on_candidate_story_submitted: false,
    county_update_to_state_search_values: [],
    address_history_lookback_years: null,
    protected_invitation_fields: [
      'first_name',
      'middle_name',
      'no_middle_name',
      'last_name',
      'dob',
      'ssn',
      'zipcode',
      'mother_maiden_name',
      'phone',
      'email',
      'current_address',
      'driver_license',
    ],
    version_number: 1,
    county_criminal_search_skip: false,
    origin: null,
    source_version_id: null,
    partner_origin_id: null,
    auto_upgrade_on_assessment: false,
    allow_dashboard_upgrade: false,
    type: 'Package',
    private: false,
    override_default_skip_thin_file_based_on_age: null,
    next_progressive_package_id: null,
    defer_high_cost_searches: false,
    use_alias_skus: false,
    requires_fmcsa_compliance: false,
    protected_invitation_flow: false,
    has_screenings_requiring_data: false,
    continuous_check: false,
    skip_ssn_collection_on_candidate_create: false,
    skip_ssn_trace_exceptions: null,
    candidates_can_declare_no_ssn: false,
    international_only: false,
    us_only: true,
    screening_settings: [],
  };
};

export const buildProfessionalPackage = () => {
  const postPackage = {
    name: 'Professional',
    slug: 'professional',
    base_package_sku: 'BPK-0010-7-7',
    price: 7999,
    screenings: [
      {
        type: 'ssn_trace',
      },
      {
        type: 'sex_offender_search',
      },
      {
        type: 'global_watchlist_search',
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
      },
      {
        type: 'employment_verification',
        lookback_years: 7,
        requires_full_lookback: false,
        maximum_allowed_employers: 1,
        requires_employment_history: false,
        international_upgrade: false,
        salary: false,
        manager_contact: true,
        contract_type: true,
        date_matching: 6,
      },
      {
        type: 'education_verification',
        requires_education_history: false,
        international_upgrade: false,
      },
      {
        type: 'county_criminal_search',
        subtype: '7years',
        requires_education_history: false,
        requires_employment_history: false,
      },
    ],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };

  return postPackage;
};

export const buildProfessionalPackageV2 = () => {
  return {
    price: 7999,
    base_package_sku: 'BPK-0010-7-7',
    drug_screening_price: null,
    enabled_exams: [],
    requires_observed_drug_test: false,
    object: 'package',
    applicant_pay: null,

    county_coverage_for_all_state_searches: false,
    dl_history_verification_enabled: false,
    education_history_based_county_criminal_searches: false,
    education_history_based_state_criminal_searches: false,
    employment_history_based_county_criminal_searches: false,
    employment_history_based_state_criminal_searches: false,
    pointer_lookback_in_years_for_county: 7,
    criminal_self_disclosure: false,
    skip_self_disclosure_validation: false,
    name: 'Professional',
    program_id: null,
    program_name: null,
    screenings: [
      {
        type: 'county_criminal_search',
        subtype: '7years',
        preferences: {
          requires_education_history: false,
          requires_employment_history: false,
        },
      },
      {
        type: 'education_verification',
        subtype: null,
        preferences: {
          requires_history: false,
          international_upgrade: false,
          requires_completed_degrees: false,
          document_collection: true,
        },
      },
      {
        type: 'employment_verification',
        subtype: 'current',
        preferences: {
          requires_history: false,
          maximum_allowed_employers: 1,
          maximum_lookback_years: 7,
          requires_full_lookback: false,
          position_matching: 'strict',
          manager_contact: true,
          salary: false,
          contract_type: true,
          date_matching: 6,
          international_upgrade: false,
          document_collection: true,
        },
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
        preferences: null,
      },
      {
        type: 'sex_offender_search',
        subtype: null,
        preferences: null,
      },
      {
        type: 'ssn_trace',
        subtype: null,
        preferences: null,
      },
      {
        type: 'global_watchlist_search',
        subtype: null,
        preferences: null,
      },
    ],
    slug: 'professional',
    county_level_verification_fee: 0,
    skip_county_fees: false,
    skip_mandatory_county_fees: false,
    skip_criminal_searches_in_inaccessible_states_and_counties: false,
    skip_criminal_searches_in_inaccessible_states_and_counties_type: null,
    auto_inaccessible_followup_type: null,
    child_abuse_search_state_values: [],
    inaccessible_followup_type: null,
    request_aliases_hard_cap_threshold: null,
    health_screening_passport_expiration_days: null,
    only_search_states_values: [],
    always_search_states_values: [],
    always_search_counties_values: [],
    minors_allowed: false,
    assess_auto_engage_eligible_reports: false,
    county_update_to_state_search_values: [],
    address_history_lookback_years: null,
    protected_invitation_fields: [
      'first_name',
      'middle_name',
      'last_name',
      'dob',
      'ssn',
      'zipcode',
      'mother_maiden_name',
      'phone',
      'email',
      'current_address',
      'driver_license',
    ],
    version_number: 1,
    county_criminal_search_skip: false,
    origin: null,
    source_version_id: null,
    partner_origin_id: null,
    auto_upgrade_on_assessment: false,
    allow_dashboard_upgrade: false,
    type: 'Package',
    private: false,
    override_default_skip_thin_file_based_on_age: null,
    next_progressive_package_id: null,
    defer_high_cost_searches: false,
    requires_fmcsa_compliance: false,
    protected_invitation_flow: false,
    has_screenings_requiring_data: true,
    continuous_check: false,
    skip_ssn_collection_on_candidate_create: false,
    skip_ssn_trace_exceptions: null,
    candidates_can_declare_no_ssn: false,
    override_international_report_locales: false,
    international_only: false,
    us_only: true,
    screening_settings: [],
    aliases_enabled: 'off',
    use_alias_skus: false,
  };
};

export const buildInternationalBasicPackage = () => {
  const postPackage = {
    name: 'International Basic+',
    slug: 'international_basic_+',
    price: 0,
    screenings: [
      {
        type: 'international_criminal_search_v2',
        out_of_country_history: true,
      },
      {
        type: 'international_adverse_media_search',
        out_of_country_history: true,
      },
      {
        type: 'international_global_watchlist_search',
      },
    ],
  };

  return postPackage;
};

export const buildInternationalProfessionalPackage = () => {
  const postPackage = {
    name: 'International Professional',
    slug: 'international_professional',
    price: 0,
    screenings: [
      {
        type: 'international_criminal_search_v2',
        out_of_country_history: true,
      },
      {
        type: 'international_adverse_media_search',
        out_of_country_history: true,
      },
      {
        type: 'international_global_watchlist_search',
      },
      {
        type: 'international_education_verification',
      },
      {
        type: 'international_employment_verification',
      },
    ],
  };

  return postPackage;
};

const getDomesticPackageTypeFromParam = (
  packageType: string,
  geoParam: string,
) => {
  if (!geoParam || geoParam !== DOMESTIC_GEO_PARAM) {
    return 'invalid';
  }

  if (geoParam === DOMESTIC_GEO_PARAM) {
    switch (packageType) {
      case BASIC_PACKAGE_TYPE_PARAM:
        return BASIC;
      case ESSENTIAL_PACKAGE_TYPE_PARAM:
        return ESSENTIAL;
      case PROFESSIONAL_PACKAGE_TYPE_PARAM:
        return PROFESSIONAL;
      case COMPLETE_CRIMINAL_PACKAGE_TYPE_PARAM:
        return COMPLETE_CRIMINAL;
      default:
        return 'invalid';
    }
  }

  return 'invalid';
};

const getInternationalPackageTypeFromParam = (
  packageType: string,
  geoParam: string,
) => {
  if (!geoParam || geoParam !== INTERNATIONAL_GEO_PARAM) {
    return 'invalid';
  }

  if (geoParam === INTERNATIONAL_GEO_PARAM) {
    switch (packageType) {
      case INTERNATIONAL_BASIC_PACKAGE_TYPE__PARAM:
        return INTERNATIONAL_BASIC;
      case INTERNATIONAL_PROFESSIONAL_PACKAGE_TYPE_PARAM:
        return INTERNATIONAL_PROFESSIONAL;
      default:
        return 'invalid';
    }
  }

  return 'invalid';
};

export const getPackageTypeFromParam = (
  packageType: string,
  geoParam: string,
) => {
  return geoParam === DOMESTIC_GEO_PARAM
    ? getDomesticPackageTypeFromParam(packageType, geoParam)
    : getInternationalPackageTypeFromParam(packageType, geoParam);
};

const getDomesticScreeningByTypeFromParam = (
  addOnParam: string,
  geoParam: string,
): Screening | GenericObject | string => {
  if (!geoParam || geoParam !== DOMESTIC_GEO_PARAM) {
    return 'invalid';
  }
  if (geoParam === DOMESTIC_GEO_PARAM) {
    switch (addOnParam) {
      case 'mvr':
        return screeningPayloadsByType.motor_vehicle_report;
      case 'cmvr':
        return buildMotorVehicleReport({ requires_commerical_mvr: true });
      case 'countycrim':
        return screeningPayloadsByType.county_criminal_search;
      case 'statecrim':
        return screeningPayloadsByType.state_criminal_search;
      case 'fedcrim':
        return screeningPayloadsByType.federal_criminal_search;
      case 'gwl':
        return screeningPayloadsByType.global_watchlist_search;
      case 'ooccrim':
        return {
          screening_one:
            screeningPayloadsByType.international_criminal_search_v2,
          screening_two:
            screeningPayloadsByType.international_adverse_media_search,
        };
      // we dont want to autoadd drug or ohs screens but they aren't invalid
      case 'drug':
      case 'ohs':
        return 'valid';
      case 'dotdrug':
        return buildDrugScreening({ drugScreeningSubtype: 'DS_7_PANEL' });
      case 'empv':
        return screeningPayloadsByType.employment_verification;
      case 'dotempv':
        return buildEmploymentVerification({ dot_compliance: true });
      case 'eduv':
        return screeningPayloadsByType.education_verification;
      case 'plv':
        return screeningPayloadsByType.professional_license_verification;
      case 'persref':
        return screeningPayloadsByType.personal_reference_verification;
      case 'profref':
        return screeningPayloadsByType.professional_reference_verification;
      case 'countyciv':
        return screeningPayloadsByType.county_civil_search;
      case 'fedciv':
        return screeningPayloadsByType.federal_civil_search;
      case 'facis1':
        return screeningPayloadsByType.facis_search;
      case 'facis3':
        return screeningPayloadsByType.facis_search_level_3;
      case 'credit':
        return screeningPayloadsByType.credit_report;
      default:
        return 'invalid';
    }
  }

  return 'invalid';
};

const getInternationalScreeningByTypeFromParam = (
  addOnParam: string,
  geoParam: string,
): Screening | GenericObject | string => {
  if (!geoParam || geoParam !== INTERNATIONAL_GEO_PARAM) {
    return 'invalid';
  }

  if (geoParam === INTERNATIONAL_GEO_PARAM) {
    switch (addOnParam) {
      case 'intcrim':
        return {
          screening_one:
            screeningPayloadsByType.international_criminal_search_v2,
          screening_two:
            screeningPayloadsByType.international_adverse_media_search,
        };
      case 'intgwl':
        return screeningPayloadsByType.international_global_watchlist_search;
      case 'intempv':
        return screeningPayloadsByType.international_employment_verification;
      case 'inteduv':
        return screeningPayloadsByType.international_education_verification;
      case 'intidv':
        return screeningPayloadsByType.international_identity_document_validation;
      default:
        return {};
    }
  }

  return 'invalid';
};

export const getScreeningByTypeFromParam = (
  screeningType: string,
  geoParam: string,
) => {
  return geoParam === DOMESTIC_GEO_PARAM
    ? getDomesticScreeningByTypeFromParam(screeningType, geoParam)
    : getInternationalScreeningByTypeFromParam(screeningType, geoParam);
};

export const getScreeningTypeFromParam = (addOnParam: string): string => {
  switch (addOnParam) {
    case 'mvr':
    case 'cmvr':
      return 'motor_vehicle_report';
    case 'countycrim':
      return 'county_criminal_search';
    case 'statecrim':
      return 'state_criminal_search';
    case 'fedcrim':
      return 'federal_criminal_search';
    case 'gwl':
      return 'global_watchlist_search';
    case 'ooccrim':
      return 'out_of_country_criminal_search';
    case 'empv':
    case 'dotempv':
      return 'employment_verification';
    case 'eduv':
      return 'education_verification';
    case 'plv':
      return 'professional_license_verification';
    case 'persref':
      return 'personal_reference_verification';
    case 'profref':
      return 'professional_reference_verification';
    case 'countyciv':
      return 'county_civil_search';
    case 'fedciv':
      return 'federal_civil_search';
    case 'facis1':
    case 'facis3':
      return 'facis_search';
    case 'drug':
    case 'dotdrug':
      return 'drug_screening';
    case 'ohs':
      return 'occupational_health_screening';
    case 'credit':
      return 'credit_report';
    case 'intcrim':
      return 'out_of_country_criminal_search';
    case 'intgwl':
      return 'international_global_watchlist_search';
    case 'intempv':
      return 'international_employment_verification';
    case 'inteduv':
      return 'international_education_verification';
    case 'intidv':
      return 'international_identity_document_validation';
    default:
      return 'invalid';
  }
};
