import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import * as SubscriptionType from 'modules/core-types/subscription';
import styled from 'styled-components';
import { useDeleteSubscription } from 'api/continuousChecks';
import moment from 'moment';
import { useUser } from '../../context/CurrentUser';
import {
  DATE_FORMAT,
  MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY,
} from '../../Constants';

type Props = {
  candidate: any;
  subscription: SubscriptionType.Type;
  updateSubscriptions(data: object, action: string): void;
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const InfoColumn = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const InfoCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  width: 240px;
  padding: 10px 12px;
  border-radius: 6px;
`;

const DeleteColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

const isObject = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const Subscription: React.FC<Props> = ({
  candidate,
  subscription,
  updateSubscriptions,
}) => {
  const { account } = candidate;
  const segmentationEnabled = account?.segmentation_enabled;
  const hierarchyPresent = account?.hierarchy_present;
  const [deleting, setDeleting] = useState(false);
  const { deleteCall, deleteResult } = useDeleteSubscription();
  const currentUser = useUser();

  const permissionsFlag =
    useFlag(MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY)?.variantKey === 'enabled';

  const canManageSubscriptions = permissionsFlag
    ? hasPermission(currentUser, 'manage_subscriptions')
    : true;

  // Initial values to be used if editing canceled
  const [initialSubscription, updateInitialSubscription] =
    useState(subscription);

  useEffect(() => {
    updateInitialSubscription(subscription);
  }, [subscription, updateInitialSubscription]);

  const handleDeleteCancelClick = useCallback(() => {
    setDeleting(false);
  }, []);

  const displayWorkLocation = useCallback(() => {
    // @ts-ignore
    if (
      initialSubscription?.work_locations === undefined ||
      initialSubscription?.work_locations[0] === undefined
    ) {
      return <em> None </em>;
    }
    const work_location = initialSubscription.work_locations[0];

    let output = '';
    if (work_location?.city) {
      isObject(work_location.city)
        ? // @ts-ignore
          (output = work_location.city.name)
        : (output = work_location.city);
    }
    if (work_location?.state) {
      if (work_location?.city) {
        output += ', ';
      }
      isObject(work_location.state)
        ? // @ts-ignore
          (output += work_location.state.abbreviation)
        : (output += work_location.state);
    }
    return <span> {output} </span>;
    // @ts-ignore
  }, [initialSubscription]);

  const submitDelete = useCallback(() => {
    const payload: { id: string } = {
      // @ts-ignore
      id: subscription.id,
    };

    deleteCall(payload);
  }, [deleteCall, subscription]);

  useEffectAfterInitialized(() => {
    setDeleting(false);
    if (!deleteResult.isSuccess) return;
    updateSubscriptions(subscription, 'delete');
  }, [deleteResult.data, deleteResult.isSuccess]);

  const handleDeleteLinkClick = useCallback(
    e => {
      e?.preventDefault();
      setDeleting(true);
    },
    [setDeleting],
  );

  return (
    <M.Container type='shadow'>
      <FlexContainer>
        <InfoColumn>
          <InfoCell>
            <strong>Package</strong>
            {subscription.package}
          </InfoCell>

          <InfoCell>
            <strong>Interval</strong>
            {subscription.interval_count} {subscription.interval_unit}
            {
              // @ts-ignore
              subscription.interval_count > 1 ? 's' : ''
            }
          </InfoCell>

          <InfoCell>
            <strong>Subscription start date</strong>
            {moment(subscription.start_date).format(DATE_FORMAT)}
          </InfoCell>

          <InfoCell>
            <strong>Next occurrence</strong>
            {moment(subscription.next_occurrence_date).format(DATE_FORMAT)}
          </InfoCell>

          {(hierarchyPresent || segmentationEnabled) && (
            <>
              <InfoCell>
                <strong>Node</strong>
                {subscription?.node || <em>None</em>}
              </InfoCell>
              <InfoCell>
                <strong>Work Location(s)</strong>
                {displayWorkLocation()}{' '}
              </InfoCell>
            </>
          )}
        </InfoColumn>

        <DeleteColumn>
          {canManageSubscriptions && (
            <div>
              <M.Link
                className='delete'
                style={{ color: colors.uiOrange600 }}
                href='#'
                onClick={handleDeleteLinkClick}
                disabled={!canManageSubscriptions}
              >
                Delete
              </M.Link>
            </div>
          )}
        </DeleteColumn>
      </FlexContainer>

      {canManageSubscriptions && deleting && (
        <>
          <div style={{ paddingBottom: '8px' }}>
            <span>
              Are you sure you want to delete this subscription from this
              candidate?
            </span>
          </div>
          <div>
            <M.BinaryButtons
              disabled={!canManageSubscriptions}
              btnLeft={{ name: 'Cancel', onClick: handleDeleteCancelClick }}
              btnRight={{
                kind: 'danger',
                name: 'Delete Subscription',
                onClick: submitDelete,
              }}
              size='sm'
            />
          </div>
        </>
      )}
    </M.Container>
  );
};

export default Subscription;
