import React, { useCallback, useContext } from 'react';
import { Report } from 'types';
import { M } from '@dashboard-experience/mastodon';
import { useArchiveReports, useUnarchiveReports } from 'api/archiveReport';
import { ReportStatuses } from '@dashboard-experience/utils';
import { ARCHIVE_REPORTS_NAMES, useTrackEvent } from 'utils/analytics';
import { useUser } from 'context/CurrentUser';
import { scrollToTopIfIframe } from 'utils';
import ArchiveModal from 'modules/candidate/ui/header/report-actions/v2/secondary-actions/menu-items/archive-modal';
import UnarchiveModal from 'modules/candidate/ui/header/report-actions/v2/secondary-actions/menu-items/unarchive-modal';

import { RealtimeContext } from '../../../../containers/RealtimeProvider';

const ArchiveReportAction: React.FC<{ report: Report }> = ({ report }) => {
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  const [unarchiveModalOpen, setUnarchiveModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const disableButton =
    report.status === ReportStatuses.PENDING ||
    report.status === ReportStatuses.DISPUTE ||
    report.status === ReportStatuses.SUSPENDED;

  const openArchiveModal = useCallback(() => {
    if (disableButton) return;

    if (currentUser) {
      trackEvent(ARCHIVE_REPORTS_NAMES.ARCHIVE_REPORT_ACTION_SELECTED, {
        'Archive action': 'Archive report',
        Source: 'Report Information',
      });
    }

    setArchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setArchiveModalOpen, currentUser, trackEvent, disableButton]);

  const openUnarchiveModal = useCallback(() => {
    if (disableButton) return;

    if (currentUser) {
      trackEvent(ARCHIVE_REPORTS_NAMES.ARCHIVE_REPORT_ACTION_SELECTED, {
        'Archive action': 'Unarchive report',
        Source: 'Report Information',
      });
    }

    setUnarchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setUnarchiveModalOpen, currentUser, trackEvent, disableButton]);

  const closeArchiveModal = useCallback(() => {
    if (disableButton) return;
    setArchiveModalOpen(false);
  }, [setArchiveModalOpen, disableButton]);

  const closeUnarchiveModal = useCallback(() => {
    if (disableButton) return;
    setUnarchiveModalOpen(false);
  }, [setUnarchiveModalOpen, disableButton]);

  const { call: archivedReportCall } = useArchiveReports();
  const { call: unarchivedReportCall } = useUnarchiveReports();
  const { triggerRefetches } = useContext(RealtimeContext);

  const archive = useCallback(() => {
    setIsLoading(true);
    setArchiveModalOpen(false);
    archivedReportCall({
      candidate_report_ids: [
        {
          candidate_id: report.candidate?.id as string,
          report_id: report.id,
        },
      ],
      user_resource_id: currentUser?.id as string,
    }).then(() => {
      triggerRefetches();
      setIsLoading(false);
    });
  }, [archivedReportCall, report]);

  const unarchive = useCallback(() => {
    setIsLoading(true);
    setUnarchiveModalOpen(false);
    unarchivedReportCall({
      candidate_report_ids: [
        {
          candidate_id: report.candidate?.id as string,
          report_id: report.id,
        },
      ],
      user_resource_id: currentUser?.id as string,
    }).then(() => {
      triggerRefetches();
      setIsLoading(false);
    });
  }, [unarchivedReportCall, report]);

  return (
    <div style={{ marginLeft: '1rem' }}>
      {isLoading && <M.LoadingSpinner />}
      {report.archived ? (
        <M.TooltipDefinition
          highlighted={false}
          align='top'
          definition='Unable to unarchive until report is completed'
          openOnHover={disableButton}
        >
          <M.Button
            className={`${disableButton ? 'disabled' : ''}`}
            onClick={openUnarchiveModal}
            kind='tertiary'
            size='md'
            data-testid='unarchive-report'
          >
            Unarchive report
          </M.Button>
        </M.TooltipDefinition>
      ) : (
        <M.TooltipDefinition
          highlighted={false}
          align='top'
          definition='Unable to archive until report is completed'
          openOnHover={disableButton}
        >
          <M.Button
            className={`${disableButton ? 'disabled' : ''}`}
            onClick={openArchiveModal}
            kind='tertiary'
            size='md'
            data-testid='archive-report'
          >
            Archive report
          </M.Button>
        </M.TooltipDefinition>
      )}

      <ArchiveModal
        isOpen={archiveModalOpen}
        closeModal={closeArchiveModal}
        archive={archive}
      />
      <UnarchiveModal
        isOpen={unarchiveModalOpen}
        closeModal={closeUnarchiveModal}
        unarchive={unarchive}
      />
    </div>
  );
};

export default ArchiveReportAction;
