import React, { useCallback, useState } from 'react';
import { queryCache } from 'react-query';
import { M } from '@dashboard-experience/mastodon';
import { InvitationStatuses } from '@dashboard-experience/utils';
import {
  useArchiveInvitations,
  useUnarchiveInvitations,
} from 'api/archiveReport';
import { useInvitation } from 'modules/candidate/utilities';
import { useTrackEvent, ARCHIVE_INVITATIONS_NAMES } from 'utils/analytics';
import { useUser } from 'context/CurrentUser';
import { scrollToTopIfIframe } from 'utils';
import ArchiveInvitationModal from './archive-invitation-modal';
import UnarchiveInvitationModal from './unarchive-invitation-modal';

const ArchiveInvitation = () => {
  const trackEvent = useTrackEvent();
  const currentUser = useUser();
  const invitation = useInvitation();

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [unarchiveModalOpen, setUnarchiveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openArchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_INVITATIONS_NAMES.ARCHIVE_INVITATION_ACTION_SELECTED, {
        'Archive action': 'Archive invitation',
      });
    }

    setArchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setArchiveModalOpen, currentUser, trackEvent]);

  const openUnarchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_INVITATIONS_NAMES.ARCHIVE_INVITATION_ACTION_SELECTED, {
        'Archive action': 'Unarchive invitation',
      });
    }

    setUnarchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setUnarchiveModalOpen, currentUser, trackEvent]);

  const closeArchiveModal = useCallback(() => {
    setArchiveModalOpen(false);
  }, [setArchiveModalOpen]);

  const closeUnarchiveModal = useCallback(() => {
    setUnarchiveModalOpen(false);
  }, [setUnarchiveModalOpen]);

  const { call: archiveInvitationCall } = useArchiveInvitations();
  const { call: unarchiveInvitationCall } = useUnarchiveInvitations();

  const handleArchiveAction = useCallback(
    (setModalOpen, apiCall) => {
      setIsLoading(true);
      setModalOpen(false);

      apiCall({
        candidate_invitation_ids: [
          {
            candidate_id: invitation?.candidate_id as string,
            invitation_id: invitation?.id,
          },
        ],
        user_resource_id: currentUser?.id as string,
      }).then(() => {
        queryCache.invalidateQueries('candidates/dashboard');
        setIsLoading(false);
      });
    },
    [archiveInvitationCall, unarchiveInvitationCall, invitation],
  );

  const archive = useCallback(
    () => handleArchiveAction(setArchiveModalOpen, archiveInvitationCall),
    [handleArchiveAction, archiveInvitationCall],
  );
  const unarchive = useCallback(
    () => handleArchiveAction(setUnarchiveModalOpen, unarchiveInvitationCall),
    [handleArchiveAction, unarchiveInvitationCall],
  );

  if (invitation?.status !== InvitationStatuses.EXPIRED) {
    return null;
  }

  return (
    <>
      <M.ActionMenuItem
        title={
          invitation?.archived ? 'Unarchive invitation' : 'Archive invitation'
        }
        kind='node'
        actionNode={<M.Icon icon='Archive' size='16' />}
        onClick={invitation?.archived ? openUnarchiveModal : openArchiveModal}
      />
      <ArchiveInvitationModal
        isOpen={archiveModalOpen}
        closeModal={closeArchiveModal}
        archive={archive}
      />
      <UnarchiveInvitationModal
        isOpen={unarchiveModalOpen}
        closeModal={closeUnarchiveModal}
        unarchive={unarchive}
      />
      {isLoading && <M.LoadingSpinner />}
    </>
  );
};

export default ArchiveInvitation;
