import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxDivider,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalEducationVerificationProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};

const InternationalEducationVerification = ({
  shouldOpen,
  actionProps,
}: InternationalEducationVerificationProps) => {
  return (
    <FlexContainer data-testid='international-education-verification-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Education verification'
            priceByType='international_education_verification'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Confirm the candidate’s education or certification history outside
            the United States.
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Find the candidate’s completed degree, course of study, and attendance dates.' />
              <BulletPoint text='Help maintain compliance, mitigate risk, and make informed hiring decisions.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Turnaround can be slower than US verification.' />
              <BulletPoint text="Verify the candidate's education, not their identity." />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_education_verification'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalEducationVerification;
