import React from 'react';
import { TaxExemptionCertificate } from 'api/payment';
import TaxCertificate from './TaxCertificate';

type Props = {
  taxExemptionCertificates: Array<TaxExemptionCertificate>;
};

const TaxExemptionCertificateDetails: React.FC<Props> = ({
  taxExemptionCertificates,
}) => {
  return (
    <section
      style={{ marginTop: '1.5rem' }}
      data-testid='tax-exemption-certificate-details'
    >
      {taxExemptionCertificates.map((certificate: TaxExemptionCertificate) => (
        <div key={certificate.id}>
          <TaxCertificate
            status={certificate.status}
            expirationDate={certificate.expiration_date}
            signedDate={certificate.signed_date}
            exposureRegion={certificate.exposure_region}
          />
        </div>
      ))}
    </section>
  );
};

export default TaxExemptionCertificateDetails;
