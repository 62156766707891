import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const SelectorModal = styled(M.ComposedModal)`
  #mastodon & {
    display: flex;
    gap: 32px;

    @media (max-width: 600px) {
      height: 100vh;
      width: 100vw;
      min-width: 100vw;
    }

    .cds--modal-container {
      height: calc(100vh - 10rem);
      width: calc(100vw - 20rem) !important;
      max-width: calc(100vw - 5rem);

      @media (max-width: 940px) {
        width: calc(100vw - 10rem) !important;
      }

      @media (max-width: 600px) {
        height: 100vh;
        width: 100vw;
        min-width: 100vw;
      }

      @media (min-width: 940px) {
        max-width: 1280px;
        max-height: 624px;
      }
    }

    .mastodon-modal-header-title-container {
      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        letter: 1%;
      }
    }

    .cds--modal-container-body {
      padding: 40px 32px;
      gap: 32px;
    }
  }
`;

export const AccountChoiceContainer = styled.div`
  padding: 0 64px;
  display: flex;
  gap: 32px;
  flex-direction: column;

  @media (max-width: 940px) {
    padding: 0;
  }

  h3 {
    weight: 700;
    letter: 1%;
  }

  .account-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-content: space-between;
    width: 100%;

    @media (max-width: 600px) {
      //flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      width: 100%;
    }
  }
`;

export const AccountChoice = styled(M.Card)<{ disabled: boolean }>`
  #mastodon & {
    flex-basis: calc(
      33.33% - 16px
    ); // 33.33% for 3 items per row, 16px for the gap
    max-width: calc(33.33% - 16px);
    border-radius: 4px;
    cursor: pointer;
  {props => props.disabled && 'pointer-events:none;'}

@media (max-width: 940px) {
      flex-basis: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }

    @media (max-width: 600px) {
      flex-basis: 100%;
      min-width: 100%;
    }

    .cds--radio-button__appearance {
      display: none;
    }

    div {
      display: flex;
      gap: 12px;
    }
  }
`;
export const AccountChoiceTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
