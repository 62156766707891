import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { SectionLabel } from '../styles';
import ShowEmptyFields from './show-empty-fields';

type Props = {
  trackAction: () => void;
};

const RightPanel: React.FC<Props> = ({ trackAction }) => {
  const { t } = useTranslation();
  const label = t(`${namespace}:report.settings.menu.right-panel.label`);

  return (
    <>
      <SectionLabel>{label}</SectionLabel>
      <ShowEmptyFields trackAction={trackAction} />
    </>
  );
};

export default RightPanel;
