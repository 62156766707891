import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalCriminalSearchProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};
const InternationalCriminalSearch = ({
  shouldOpen,
  actionProps,
}: InternationalCriminalSearchProps) => {
  return (
    <FlexContainer data-testid='international-criminal-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Criminal search outside the US'
            priceByType='international_criminal_search_v2'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Search location-appropriate databases for criminal offenses.
            {` `}
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Search multi-jurisdictional repositories and databases to return major offense conviction records.' />
              <BulletPoint text='Help maintain compliance, mitigate risk, and make informed hiring decisions.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Turnaround can be slower than US searches.' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_criminal_search_v2'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalCriminalSearch;
