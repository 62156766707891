import { AnyQueryKey, useQuery } from 'react-query';
import { getJobRoles, JobRoleParams } from './actions';

export const useJobRoles = (params: JobRoleParams) => {
  const key: AnyQueryKey = ['jobRoles', { id: params?.accountId }];

  const request = () => getJobRoles(params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!params.accountId,
    staleTime: 60000,
  });
};

export default useJobRoles;
