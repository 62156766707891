import React, { useContext, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@dashboard-experience/mastodon';
import { ErrorBoundary } from '@dashboard-experience/utils';
import moment from 'moment';
import DefaultComplianceModal from 'components/DefaultComplianceModal/DefaultComplianceModal';
import * as Adjudication from 'modules/adjudication/ui';
import { Router } from 'pages';
import {
  NotificationsContainer,
  ReactQueryProvider,
  ResizeContainer,
} from 'containers';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useBetaState } from 'providers/Beta';
import UIContext from 'context/UI';
import {
  getInternalDashboardUrl,
  postMessageToDashboard,
  useShouldInternalRedirect,
} from 'utils';
import OneTrustEmbed from 'components/OneTrustEmbed';
import { datadogRum } from '@datadog/browser-rum';

const AppWrapper = styled.div`
  font-family: ${fonts.legacy}, ${fonts.ui};
  background-color: ${colors.uiGrey0};
  padding: 0px !important;
`;

const RedirectWrapper = () => {
  const shouldRedirect = useShouldInternalRedirect();
  const { isIframe } = useContext(UIContext);
  const [showModal, setShowModal] = useState(null);

  useEffect(() => {
    if (shouldRedirect) {
      const path = window.location.pathname;
      const query = Object.fromEntries(
        new URLSearchParams(window.location.search),
      );
      const hash = window.location.hash.substring(1);

      try {
        // Log event in Datadog for tracking
        const context = {
          view: {
            pathname: path,
            location: window.location.toString(),
            query,
          },
        };
        datadogRum.addAction('customer_internal_redirect', context);
      } catch (e) {
        // ensure this doesn't break the redirect flow if there is an error with DD, like not being initialized
      }
      const newLocation = getInternalDashboardUrl(path, query, hash);
      window.location.replace(newLocation);
    }
  }, [shouldRedirect]);

  return shouldRedirect ? null : (
    <>
      <Adjudication.HostProvider>
        <Router />
      </Adjudication.HostProvider>
      {!isIframe && (
        <DefaultComplianceModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

const App = () => {
  const { contextId, isIframe } = useContext(UIContext);
  const betaState = useBetaState(CANDIDATE_REDESIGN);

  const onClickHandler = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'userIsActive',
      });
    }
  }, [isIframe, contextId]);

  useEffect(() => {
    if (window.pendo) {
      window.pendo.updateOptions({
        visitor: {
          candidate_redesign_state: betaState,
        },
      });
    }
  }, [betaState]);

  const dataDogFallbackHandler = useCallback(() => {
    return <div>Internal Server Error</div>;
  }, []);

  moment.locale('en');

  return (
    <ReactQueryProvider>
      <ErrorBoundary fallback={dataDogFallbackHandler}>
        <NotificationsContainer />
        <ResizeContainer />
        <OneTrustEmbed />
        <AppWrapper onClick={onClickHandler}>
          <RedirectWrapper />
        </AppWrapper>
      </ErrorBoundary>
    </ReactQueryProvider>
  );
};

export default App;
