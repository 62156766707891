/* eslint-disable react/jsx-no-bind */
import { Package } from 'types';
import moment from 'moment';
import CopyInvitationLinkModal from 'components/Packages/CopyInvitationLinkModal';
import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { Flag } from '@dashboard-experience/react-flagr';
import {
  SHOW_DELETE_BUTTON_FOR_PACKAGES,
  SHOW_EDIT_BUTTON_FOR_PACKAGES,
} from 'Constants';
import AssignNodesCell from 'components/Packages/AssignNodesCell';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  CurrentUser,
  hasPermission,
  accountHasPermission,
} from '@dashboard-experience/utils';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useHistory } from 'react-router-dom';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import PackageScreenings from './PackageScreenings';
import AliasTag from './Alias';
import {
  PACKAGE_BUILDER_EVENT_NAMES,
  useTrackEvent,
} from './Amplitude/analytics';

const TableContainer = styled(M.TableContainer)`
  a {
    color: ${colors.brandAqua3} !important;
  }
  span :not(.cds--tooltip-content) {
    color: ${colors.brandNavy3} !important;
  }

  .cds--data-table td {
    padding-top: 24px !important;
    padding-bottom: 32px !important;
    vertical-align: top !important;
    color: ${colors.brandNavy4} !important;
  }
  .cds--data-table thead {
    border-bottom: none !important;
  }
  .cds--data-table th {
    color: ${colors.brandNavy4} !important;
  }
  .cds--data-table thead :nth-child(2) {
    padding-left: 1.4rem !important;
  }
  .cds--data-table thead :nth-child(3) {
    padding-right: 5rem;
    text-align: end;
  }
  .cds--data-table thead :nth-child(4) {
    padding-left: 2rem !important;
  }
  .cds--data-table thead tr {
    background: ${colors.brandSlate1};
    border: none !important;
    height: 48px;
  }
`;

const PriceHeader = styled(M.TableHeader)`
  .cds--table-header-label {
    text-align: right !important;
  }
`;

const ActionsHeader = styled(M.TableHeader)`
  .cds--table-header-label {
    ${({ $hidePrice }) => $hidePrice && 'padding-left: 15px; '}
  }
`;

const BoldPackageName = styled.div`
  max-width: 250px;
  overflow-wrap: break-word;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${colors.uiGrey600};
  margin-left: 15px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin-top: -12px;
`;

const ActionButton = styled(M.Button)`
  div.cds--assistive-text {
    background-color: ${colors.uiGrey0} !important;
    color: ${colors.uiGrey800} !important;
  }
`;

const VerticalDivider = styled.div`
  margin-top: 15px;
  width: 1px;
  height: 20px;
  background: ${colors.brandSlate2};
`;

const PriceCellAsterisk = styled(M.TableCell)`
  padding-right: 4.7rem !important;
  text-align: end !important;
`;

const PriceCellVaries = styled(M.TableCell)`
  text-align: end !important;
  padding-right: 5rem !important;
`;

const TableCellPackageName = ({ id, value }: { id: string; value: string }) => {
  return (
    <M.TableCell key={id}>
      <BoldPackageName>{value}</BoldPackageName>
    </M.TableCell>
  );
};

const TableCellScreenings = ({ id, value }: { id: string; value: Package }) => {
  return (
    <M.TableCell key={id}>
      <PackageScreenings
        screenings={value.screenings}
        screeningSettings={value.screening_settings}
      />
      <AliasTag basePackage={value} />
    </M.TableCell>
  );
};

const TableCellPrice = ({
  price,
  international_only,
}: {
  price: number;
  international_only: boolean;
}) => {
  if (price === 0 || international_only) {
    return <PriceCellVaries>Price varies</PriceCellVaries>;
  }
  return (
    <PriceCellAsterisk>{`${getDollarAmount(
      price.toString(),
    )}*`}</PriceCellAsterisk>
  );
};

const TableCellNodes = ({ isAssignNodeCellVisible, id, accountId }: any) => {
  return <AssignNodesCell data={{ name: 'slug', id }} accountId={accountId} />;
};

const InvitationLink = ({
  handleShowModal,
  index,
  showInvitationModal,
  setShowInvitationModal,
  apply_url,
  packageName,
}: any) => {
  return (
    <div>
      <ActionButton
        kind='tertiary'
        size='lg'
        hasIconOnly
        iconDescription='View invitation link'
        onClick={handleShowModal}
        data-testid='invitation-link-icon'
        id={`invitation-link-button-${index}`}
      >
        <M.Icon icon='Link' size={16} />
      </ActionButton>
      {showInvitationModal && (
        <CopyInvitationLinkModal
          showModal={showInvitationModal}
          setShowModal={setShowInvitationModal}
          applyUrl={apply_url}
          packageName={packageName}
        />
      )}
    </div>
  );
};

const TableCellActions = ({
  id,
  packageName,
  apply_url,
  isAssignNodeCellVisible,
  isInvitationLinkVisible,
  isInternationalOnly,
  hasInternationalCrimeSearch,
  accountId,
  currentUser,
  index,
  setCurrentPackage,
  setShowDeleteModal,
  updatedAt,
}: any) => {
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const { contextId } = useContext(UIContext);
  const history = useHistory();
  const trackEvent = useTrackEvent();

  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  const handleShowModal = useCallback(() => setShowInvitationModal(true), []);

  const handleDeleteClick = useCallback(() => {
    setShowDeleteModal(true);
    setCurrentPackage({ accountId, packageId: id, packageName });
  }, [accountId, id, packageName, setCurrentPackage, setShowDeleteModal]);

  const isHierarchyInvitationLink =
    hasPermission(currentUser, 'manage_packages') &&
    accountHasPermission(currentUser, 'allow_dashboard_report_ordering');

  const handleEditClick = useCallback(
    packageId => {
      trackEvent(
        currentUser,
        PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_EDIT_CLICKED,
      );
      navigate(`/packages/${packageId.split(':')[0]}/edit`);
    },
    [currentUser, navigate, trackEvent],
  );

  return (
    <M.TableCell key={id}>
      <ActionContainer>
        <ActionButtonsContainer>
          <Flag flagKey={SHOW_EDIT_BUTTON_FOR_PACKAGES} variantKey='enabled'>
            {hasPermission(currentUser, 'manage_packages') && (
              <ActionButton
                kind='tertiary'
                size='lg'
                hasIconOnly
                iconDescription='Edit package'
                onClick={() => handleEditClick(id)}
                data-testid='edit-package-icon'
                id={`edit-package-button-${index}`}
              >
                <M.Icon icon='Pen' size={16} />
              </ActionButton>
            )}
            {hasPermission(currentUser, 'manage_packages') &&
              isInvitationLinkVisible && <VerticalDivider />}
          </Flag>
          {(isInvitationLinkVisible || isHierarchyInvitationLink) &&
            !isInternationalOnly &&
            !hasInternationalCrimeSearch && (
              <InvitationLink
                handleShowModal={handleShowModal}
                index={index}
                showInvitationModal={showInvitationModal}
                setShowInvitationModal={setShowInvitationModal}
                apply_url={apply_url}
                packageName={packageName}
              />
            )}
          <Flag flagKey={SHOW_DELETE_BUTTON_FOR_PACKAGES} variantKey='enabled'>
            {hasPermission(currentUser, 'manage_packages') && (
              <>
                <VerticalDivider />
                <ActionButton
                  hidden
                  kind='tertiary'
                  size='lg'
                  hasIconOnly
                  iconDescription='Delete package'
                  href=''
                  target='_parent'
                  data-testid='delete-package-icon'
                  id={`delete-package-button-${index}`}
                  onClick={handleDeleteClick}
                >
                  <M.Icon icon='TrashCan' size={16} />
                </ActionButton>
              </>
            )}
          </Flag>
        </ActionButtonsContainer>
        {isAssignNodeCellVisible && (
          <TableCellNodes
            data={{ name: 'slug', id }}
            id={id}
            accountId={accountId}
            isAssignNodeCellVisible={isAssignNodeCellVisible}
          />
        )}
        <ActionText>
          last edited on {updatedAt && moment(updatedAt).format('MMM DD, YYYY')}
        </ActionText>
      </ActionContainer>
    </M.TableCell>
  );
};

interface CurrentPackage {
  accountId: string;
  packageId: string;
  packageName: string;
}

const PackagesTable = ({
  accountId,
  currentUser,
  isInvitationLinkVisible,
  isAssignNodeCellVisible,
  packages,
  deletePackage,
  scrollIntoView,
}: {
  accountId: string;
  currentUser?: CurrentUser;
  isInvitationLinkVisible: boolean;
  isAssignNodeCellVisible: boolean;
  packages: Package[];
  deletePackage: Function;
  scrollIntoView: Function;
}) => {
  const [currentPackage, setCurrentPackage] = useState<CurrentPackage | null>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentPackageRowRef: any = useRef();

  // selectively not displaying that Adverse Media Search is a separate screening
  const filterAdverseMedia = (pkgSet: any) => {
    const filtered = pkgSet.screenings.filter(
      (screening: any) =>
        screening.type !== 'international_adverse_media_search',
    );
    return filtered;
  };

  // renamed international packages to display "International Criminal (Or Adverse Media)" instead of "Out-of-Country Criminal History"
  const renameIfInternational = (pkgSet: any) => {
    const isIntlOnly = pkgSet.international_only;
    pkgSet.screenings.forEach((screening: any) => {
      if (isIntlOnly && screening.type === 'international_criminal_search_v2') {
        screening.type = 'international_criminal_or_adverse_media';
      }
    });
    return pkgSet;
  };

  // does not mutate packages, but allows display of screenings named differently in intl vs domestic
  const packagesToDisplay = packages.map(pkgSet => {
    const filteredScreenings = filterAdverseMedia(pkgSet);
    const filteredPkgSet = {
      ...pkgSet,
      screenings: filteredScreenings,
    };
    return renameIfInternational(filteredPkgSet);
  });

  useEffect(() => {
    if (showDeleteModal) {
      scrollIntoView();
    }

    if (!showDeleteModal && currentPackageRowRef.current) {
      currentPackageRowRef?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      currentPackageRowRef?.current.focus();
    }
  }, [scrollIntoView, showDeleteModal]);

  const showPriceColumn =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner';

  return (
    <>
      {showDeleteModal && (
        <DeleteConfirmationModal
          {...currentPackage}
          setCurrentPackage={setCurrentPackage}
          deletePackage={deletePackage}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      <TableContainer>
        <M.Table size='sm'>
          <M.TableHead>
            <M.TableRow>
              <M.TableHeader key='table-header-name-header'>
                Package name
              </M.TableHeader>
              <M.TableHeader key='table-header-screenings-header'>
                Screenings
              </M.TableHeader>
              {showPriceColumn && (
                <PriceHeader key='table-header-price-header'>Price</PriceHeader>
              )}
              <ActionsHeader
                $hidePrice={!showPriceColumn}
                key='table-header-actions-header'
              >
                Actions
              </ActionsHeader>
              {isAssignNodeCellVisible && <M.TableHeader />}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {packagesToDisplay.map((row: any, index: number) => {
              const hasInternationalCrimeSearch = row.screenings.some(
                (screening: any) =>
                  screening.type === 'international_criminal_search_v2',
              );
              return (
                <tr
                  key={row.id}
                  ref={
                    currentPackage?.packageId === row.id
                      ? currentPackageRowRef
                      : null
                  }
                >
                  <TableCellPackageName id={row.id} value={row.name} />
                  <TableCellScreenings id={row.id} value={row} />

                  {showPriceColumn && (
                    <TableCellPrice
                      price={row.price}
                      international_only={row.international_only}
                    />
                  )}

                  <TableCellActions
                    accountId={accountId}
                    currentUser={currentUser}
                    id={row.id}
                    apply_url={row.apply_url}
                    isInvitationLinkVisible={isInvitationLinkVisible}
                    isAssignNodeCellVisible={isAssignNodeCellVisible}
                    packageName={row.name}
                    index={index}
                    hasInternationalCrimeSearch={hasInternationalCrimeSearch}
                    isInternationalOnly={row.international_only}
                    setCurrentPackage={setCurrentPackage}
                    setShowDeleteModal={setShowDeleteModal}
                    updatedAt={row.updated_at}
                  />
                </tr>
              );
            })}
          </M.TableBody>
        </M.Table>
      </TableContainer>
    </>
  );
};

export default PackagesTable;
