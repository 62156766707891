import React from 'react';
import moment, { Moment } from 'moment';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { useReport } from 'containers/Report';
import { useAdjudicationEvents } from 'modules/adjudication/ui/report/events/api';
import { useAdverseActions } from 'modules/adjudication/api';
import { AdjudicationEventTypes } from 'modules/adjudication/data';
import NonActionableBanner from './non-actionable-banner';
import { replaceCheckrEmail } from './utils';

const AdjudicationPreAABanner = () => {
  const { t } = useTranslation();
  const report = useReport();
  const { data: adjudicationEvents } = useAdjudicationEvents(report.id);
  const { adverseAction } = useAdverseActions(report.id);

  if (!adjudicationEvents || !adverseAction) return null;

  const preAAEvent = adjudicationEvents.find(
    event => event.type === AdjudicationEventTypes.PRE_ADVERSE_ACTION,
  );

  if (
    !preAAEvent ||
    report?.adjudication !== ADJUDICATION_VALUES.PRE_ADVERSE_ACTION
  )
    return null;

  const preAAdate = moment(
    adverseAction.pre_adverse_action_notice_sent_at,
  ).format('MMM D, YYYY');
  const currentDate = moment().startOf('day');

  let postAADateObject: Moment | undefined;

  if (adverseAction.pause_data)
    postAADateObject = moment(
      Date.now() + 1000 * adverseAction.pause_data.remaining,
    );
  if (adverseAction.post_notice_scheduled_at)
    postAADateObject = moment(adverseAction.post_notice_scheduled_at).startOf(
      'day',
    );

  const adjudicatorText = preAAEvent.user
    ? t('banners.adjudicationPreAA.hasUser', {
        adjudicator:
          preAAEvent.user.full_name ||
          replaceCheckrEmail(preAAEvent.user.email),
        preAAdate,
      })
    : t('banners.adjudicationPreAA.default', {
        preAAdate,
      });

  const title = postAADateObject
    ? `${adjudicatorText} ${t('banners.adjudicationPreAA.postNotice', {
        postAADate: postAADateObject.format('MMM D, YYYY'),
        daysLeft: postAADateObject.diff(currentDate, 'days'),
      })}`
    : adjudicatorText;

  return <NonActionableBanner kind='info' title={title} />;
};

export default AdjudicationPreAABanner;
