import React from 'react';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { colors } from '@dashboard-experience/mastodon';
import { SearchAdvanced } from '@carbon/icons-react';
import { IncludeAliasProps } from 'utils/PackageUtils';
import { ALIAS_AS_AN_ADDON } from '../../../Flags';
import { AliasTagProps } from './types';
import { includesAliases } from '../../../utils';

const StyledTag = styled.div`
  background: ${colors.uiAqua50};
  color: #1a2026;
  border-color: ${colors.uiAqua200};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  padding: 2px 6px;
  align-content: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 135px;
  margin-top: 5px;
`;

const StyledSearchIcon = styled(SearchAdvanced)`
  background-color: ${colors.uiAqua50};
  fill: ${colors.uiAqua500} !important;
  width: 16px;
  height: 16px;
`;

const AliasTag: React.FC<AliasTagProps> = ({ basePackage }) => {
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  if (!aliasFlag || !includesAliases(basePackage as IncludeAliasProps)) {
    return null;
  }

  return (
    <StyledTag>
      <StyledSearchIcon color={colors.uiAqua500} />
      Aliases added
    </StyledTag>
  );
};

export default AliasTag;
