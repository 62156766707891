export enum SCREENING {
  SSN_TRACE = 'SSN trace',
  SEX_OFFENDER_REGISTRY = 'Sex offender registry search',
  GLOBAL_WATCHLIST = 'Global watchlist search',
  NATIONAL_CRIMINAL = 'National criminal search',
  COUNTY_CRIMINAL = 'County criminal search (7-year)',
  EDUCATION_VERIFICATION = 'Education verification',
  EMPLOYMENT_VERIFICATION = 'Employment verification',
  FEDERAL_CRIMINAL = 'Federal criminal search (10-year)',
  STATE_CRIMINAL = 'State criminal search (7-year)',
  INTERNATIONAL_CRIMINAL = 'International criminal (or adverse media)',
  IDENTITY_DOCUMENT = 'Identity document verification',
}

export const BASIC_SCREENINGS: SCREENING[] = [
  SCREENING.SSN_TRACE,
  SCREENING.SEX_OFFENDER_REGISTRY,
  SCREENING.GLOBAL_WATCHLIST,
  SCREENING.NATIONAL_CRIMINAL,
];

export const ESSENTIAL_SCREENINGS: SCREENING[] = [
  ...BASIC_SCREENINGS,
  SCREENING.COUNTY_CRIMINAL,
];

export const PROFESSIONAL_SCREENINGS: SCREENING[] = [
  ...ESSENTIAL_SCREENINGS,
  SCREENING.EDUCATION_VERIFICATION,
  SCREENING.EMPLOYMENT_VERIFICATION,
];

export const COMPLETE_SCREENINGS: SCREENING[] = [
  ...ESSENTIAL_SCREENINGS,
  SCREENING.FEDERAL_CRIMINAL,
  SCREENING.STATE_CRIMINAL,
];

export const INTERNATIONAL_BASIC_SCREENINGS: SCREENING[] = [
  SCREENING.INTERNATIONAL_CRIMINAL,
  SCREENING.GLOBAL_WATCHLIST,
];

export const INTERNATIONAL_PROFESSIONAL_SCREENINGS: SCREENING[] = [
  ...INTERNATIONAL_BASIC_SCREENINGS,
  SCREENING.EDUCATION_VERIFICATION,
  SCREENING.EMPLOYMENT_VERIFICATION,
];

export const INTERNATIONAL_PROFESSIONAL_PLUS_SCREENINGS: SCREENING[] = [
  ...INTERNATIONAL_PROFESSIONAL_SCREENINGS,
  SCREENING.IDENTITY_DOCUMENT,
];

export const ADD_ONS_PRICES = {
  motor_vehicle_report: 950,
  education_verification: 1250,
  employment_verification: 3500,
  federal_criminal_search: 1000,
};
