/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import { colors, M } from '@dashboard-experience/mastodon';
import React, { useState, useEffect, useCallback } from 'react';

import { scrollToTop } from 'utils';

import camelize from 'camelize-ts';
import decamelize from 'decamelize';
import ReactFilestack from 'filestack-react';

import {
  oauthAssetsFilestackKey as filestackKey,
  filestackOptions,
  CANDIDATE_SMS_SETTING_MOVED,
  SHOW_COMMUNICATIONS_SETTINGS_KEY,
  ALIAS_AS_AN_ADDON,
} from 'Constants';

import {
  AddressSection,
  AdverseActionEmailCC,
  ConfirmModal,
  LogoImg,
  FileInputGroupSettings,
  FileInputGroupWrap,
  ButtonsWrap,
  InputGroupTopAligned,
  InputGroupWrapper,
  SaveCancelGroup,
  LogoPlaceholder,
  StyledTitleWrapper,
  StyledTitle,
  StyledSeparator,
  SmallTitle,
} from 'components';

import { GenericObject, hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useFilestackUploadResponse } from 'hooks';
import {
  useTrackEvent,
  MANUAL_BULKORDER_NAMES,
  DASHBOARD_LANDING_EVENT_NAMES,
} from 'utils/analytics';

import {
  ALIASES,
  useTrackEvent as usePackagesTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { useLocation } from 'react-router-dom';
import { Prompt } from 'react-router';
import { PostalAddress, CompanyAddress } from 'types';
import { useRequestManualOrders } from 'api/accounts/hooks';
import AliasRadioSelection from 'components/Packages/OrderBackgroundCheck/ReviewAndSubmitOrder/AliasRadioSelection';
import AliasLearnMoreModal from 'components/Packages/OrderBackgroundCheck/ReviewAndSubmitOrder/AliasLearnMoreModal';
import {
  useUpdateAccountSettings,
  useUploadError,
} from '../../api/accountSettings/hooks';
import SettingsSchema from './Schema';
import ManualOrderModal from '../Packages/OrderBackgroundCheck/GetStarted/ManualOrderModal';
import { ManualOrdersReview } from '../Packages/OrderBackgroundCheck/Context';

type AccountSettingsProps = {
  t: (arg0: string) => Promise<string>;
};

const updateSettingsState =
  (setSettingsState: React.Dispatch<React.SetStateAction<any>>) =>
  (key: string, newValue: any) => {
    setSettingsState((prevState: GenericObject) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

const isAccountExists = (account: GenericObject) => {
  return Object.keys(account).length > 0;
};

const getSettings = (account: GenericObject) => {
  const settings = SettingsSchema() as GenericObject;

  if (isAccountExists(account)) {
    Object.keys(settings).forEach(s => {
      settings[s].value = account[s];
    });
  }

  return settings;
};

const sortInputs = (account: GenericObject) => {
  const settings = camelize(getSettings(account));
  const inputs = Object.keys(settings);

  return inputs
    .sort((a, b) => (settings[a].index < settings[b].index ? -1 : 1))
    .map(i => ({ [i]: settings[i] }));
};

const AccountSettings: React.FC<AccountSettingsProps> = ({
  t,
}: GenericObject) => {
  const [modal, setModal] = useState<{
    open: boolean;
    header: string;
    message: string;
    callback: () => void;
  }>({
    open: false,
    header: '',
    message: '',
    callback: () => {},
  });

  const initialPostalAddress: PostalAddress = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const initialcompanyAddress: CompanyAddress = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const currentUser = useUser();

  const { account } = currentUser;
  const {
    id,
    authorized,
    manual_orders_review: manualOrdersReviewStatus,
  } = account;

  const [settingsState, setSettingsState] = useState({
    adverseActionEmail: '',
    adverseActionEmailCC: false,
    aliasAutoEnable: account?.alias_auto_enable,
    company: initialcompanyAddress,
    billingEmail: '',
    complianceContactEmail: '',
    logoUri: '',
    name: '',
    postalAddress: initialPostalAddress,
    suppressEmailInvites: false,
    suppressSmsNotifications: false,
    supportEmail: '',
    supportPhone: '',
    technicalContactEmail: '',
    uriName: '',
    disableSettings: false,
    enableManualOrdering: false,
    defaultAlias: true,
  });

  const {
    adverseActionEmailCC,
    aliasAutoEnable,
    company,
    logoUri,
    disableSettings,
    postalAddress,
    suppressSmsNotifications,
    enableManualOrdering,
  } = settingsState;

  const { call, result } = useUpdateAccountSettings();
  const { call: requestManualOrdersCall, result: requestManualOrdersResult } =
    useRequestManualOrders();
  const processing: any = result.isLoading;
  const [errorMsg, setErrorMsg] = useState<any>({});

  const { callUploadError } = useUploadError();
  const [showManualOrdersModal, setShowManualOrdersModal] = useState(false);
  const trackEvent = useTrackEvent();
  const trackPackagesEvent = usePackagesTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  const manualOrdersModalShow = useCallback(() => {
    trackEvent(MANUAL_BULKORDER_NAMES.MANUAL_ORDERS_MODAL_OPENED);
    setShowManualOrdersModal(true);
  }, [trackEvent]);

  const manualOrdersModalClose = useCallback(() => {
    setShowManualOrdersModal(false);
  }, []);

  const manualOrdersModalSubmit = useCallback(() => {
    requestManualOrdersCall({ accountId: currentUser.account.id }).then(() => {
      if (requestManualOrdersResult.isSuccess) {
        setShowManualOrdersModal(false);
      }
    });
  }, [
    requestManualOrdersCall,
    requestManualOrdersResult.isSuccess,
    currentUser.account.id,
  ]);

  const setInitialSettings = useCallback(() => {
    setSettingsState(settings => {
      const newSettings: any = { ...settings };

      if (isAccountExists(account)) {
        const settings = getSettings(account);

        Object.keys(settings).forEach(s => {
          newSettings[camelize(s)] = account[s];
        });

        if (account.logo_uri && account.logo_uri !== 'false') {
          newSettings.logoUri = account.logo_uri;
        }

        if (account.suppress_sms_notification) {
          newSettings.suppressSmsNotifications = true;
        }

        if (account.suppress_email_invitation) {
          newSettings.suppressEmailInvites = true;
        }

        if (account.adverse_action_email_cc) {
          newSettings.adverseActionEmailCC = account.adverse_action_email_cc;
        } else {
          newSettings.adverseActionEmailCC = false;
        }

        if (account.alias_auto_enable) {
          newSettings.aliasAutoEnable = true;
        }

        if (account.manual_orders_enabled) {
          newSettings.enableManualOrdering = true;
        }

        if (account.postal_address) {
          newSettings.postalAddress = account.postal_address;
        }

        if (account.company) {
          newSettings.company = account.company;
        }
      }

      newSettings.disableSettings = !hasPermission(
        currentUser,
        'manage_account_settings',
      );
      return newSettings;
    });
  }, [account, currentUser]);
  const smsSettingMoved =
    useFlag(CANDIDATE_SMS_SETTING_MOVED)?.variantKey === 'on';
  const newCommunicationSettingsEnabled =
    useFlag(SHOW_COMMUNICATIONS_SETTINGS_KEY)?.variantKey === 'on';

  useEffect(() => {
    setInitialSettings();
  }, [setInitialSettings]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const setSettings = useCallback(
    (
      key: string,
      value: string | boolean | string[] | PostalAddress | CompanyAddress,
    ) => {
      updateSettingsState(setSettingsState)(key, value);
    },
    [],
  );

  const updateAddress = (
    newAddress: PostalAddress | CompanyAddress,
    addressType: 'postalAddress' | 'company',
    name: string,
    setSettings: (
      type: 'postalAddress' | 'company',
      address: PostalAddress | CompanyAddress,
    ) => void,
  ) => {
    newAddress.name = name;
    setSettings(addressType, newAddress);
  };

  const handlePostalAddress = useCallback(
    (newAddress: PostalAddress) => {
      updateAddress(
        newAddress,
        'postalAddress',
        settingsState.name,
        setSettings,
      );
    },
    [settingsState.name, setSettings],
  );

  const handleAliasAutoEnable = useCallback(
    (event: any) => {
      setSettings('aliasAutoEnable', event);
    },
    [setSettings],
  );

  const handleCompanyAddress = useCallback(
    (newAddress: CompanyAddress) => {
      updateAddress(newAddress, 'company', settingsState.name, setSettings);
    },
    [settingsState.name, setSettings],
  );

  const hideModal = useCallback(() => {
    setModal({
      open: false,
      header: '',
      message: '',
      callback: () => {},
    });
  }, [modal]);

  const showModal = useCallback(
    (header: string, message: string, callback: () => void) => {
      setModal({
        open: true,
        header,
        message,
        callback,
      });
      scrollToTop();
    },
    [modal],
  );

  const onChange = useCallback(
    (key: string) => (event: any) => {
      setBlockNavigation(true);
      setSettings(key, event.target.value);
    },
    [setSettings],
  );

  const onFileDelete = useCallback(
    () => setSettings('logoUri', ''),
    [setSettings],
  );

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: callUploadError,
    successCallback: file => {
      const staticUrl = file.url;

      return onChange('logoUri')({ target: { value: staticUrl } });
    },
  });

  const confirmNameChange = useCallback(() => {
    updateAccountSettings();
    hideModal();
  }, [account, settingsState, hideModal]);

  const changeName = () => {
    showModal(
      'Change Account Name',
      'Please confirm that you would like to change the name of this account.',
      confirmNameChange,
    );
  };

  const updateAccountSettings = useCallback(() => {
    const enableManualOrdersInitialValue = account.manual_orders_enabled;
    call(
      { changedSettings: settingsState, account },
      {
        onError: err => {
          setErrorMsg(err.response.data.account);
        },
      },
    ).then(() => {
      setBlockNavigation(false);
      if (enableManualOrdersInitialValue !== enableManualOrdering) {
        trackEvent(
          MANUAL_BULKORDER_NAMES.ENABLE_MANUAL_ORDERS_SETTING_CHANGED,
          {
            'Enable Manual Orders': enableManualOrdering,
          },
        );
      }
    });
  }, [account, call, enableManualOrdering, settingsState, trackEvent]);

  const onSave = useCallback(() => {
    setErrorMsg({});

    if (account.name !== settingsState.name) {
      return changeName();
    }

    if (!isSignup && account.logo_uri !== settingsState.logoUri) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.LOGO_UPDATED);
    }
    if (account.alias_auto_enable !== settingsState.aliasAutoEnable) {
      trackPackagesEvent(
        currentUser,
        ALIASES.CHECKR_DASHBOARD_DEFAULT_ALIAS_ACCOUNT_SETTING_UPDATED,
        {
          Source: 'Account Settings',
          Selection: settingsState.aliasAutoEnable ? 'On' : 'Off',
        },
      );
    }
    return updateAccountSettings();
  }, [account, call, settingsState]);

  const onCancel = useCallback(() => {
    setInitialSettings();
  }, [setInitialSettings]);

  const filestackUpload = useCallback(
    ({ onPick }: GenericObject) => {
      return (
        <M.Button kind='secondary' onClick={onPick}>
          {t('buttons.upload')}
        </M.Button>
      );
    },
    [t],
  );

  const inputs = Object.assign({}, ...sortInputs(account));

  const toggleSmsSetting = useCallback(() => {
    onChange('suppressSmsNotifications')({
      target: { value: !suppressSmsNotifications },
    });
  }, [onChange, suppressSmsNotifications]);

  const toggleManualOrdering = useCallback(() => {
    onChange('enableManualOrdering')({
      target: { value: !enableManualOrdering },
    });
  }, [onChange, enableManualOrdering]);

  const [blockNavigation, setBlockNavigation] = useState(false);
  const promptMessage =
    'You have unsaved changes, are you sure you want to leave?';

  const onBeforeUnload = (event: any) => {
    (event || window.event).returnValue = promptMessage;
    return promptMessage;
  };

  useEffect(() => {
    if (blockNavigation) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }
  }, [blockNavigation]);

  return (
    <>
      <Prompt when={blockNavigation} message={promptMessage} />
      <StyledTitle data-testid='page-title'>
        {t('accountSettings.title')}
      </StyledTitle>
      {/* @ts-ignore */}
      <ConfirmModal
        open={modal.open}
        hideModal={hideModal}
        callback={modal.callback}
        header={modal.header}
        message={modal.message}
      />
      <InputGroupWrapper data-testid='wrap-contact-form'>
        {Object.keys(inputs).map((key: string, i: number) => {
          const inputError: any =
            errorMsg && errorMsg[decamelize(key)]
              ? errorMsg[decamelize(key)].join(' ')
              : false;

          return (
            <InputGroupTopAligned key={i.toString()}>
              <M.Input
                placeholder={t(`inputs.${key}`)}
                disabled={inputs[key].disabled || disableSettings}
                id={key}
                invalid={!!inputError}
                invalidText={inputError}
                onChange={onChange(key)}
                type={inputs[key].type}
                value={(settingsState as any)[key] || ''}
                labelText={t(`inputs.${key}`)}
              />
            </InputGroupTopAligned>
          );
        })}
      </InputGroupWrapper>
      <AddressSection
        companyAddress={company}
        disabled={disableSettings}
        handleCompanyAddress={handleCompanyAddress}
        handlePostalAddress={handlePostalAddress}
        postalAddress={postalAddress}
      />
      <AdverseActionEmailCC
        setting={adverseActionEmailCC}
        setSettings={setSettings}
      />
      <StyledSeparator />
      {filestackKey && (
        <div data-testid='section-logo'>
          <StyledTitle id='section-logo'>
            {t('headings.accountLogo')}
          </StyledTitle>
          <FileInputGroupSettings>
            {logoUri ? (
              <LogoImg src={logoUri} alt='Logo' data-testid='logo' />
            ) : (
              <LogoPlaceholder data-testid='icon-placeholder'>
                <M.Icon
                  icon='Image'
                  size='20'
                  style={{
                    fill: colors.uiTextSecondaryLight,
                  }}
                />
              </LogoPlaceholder>
            )}
            {!disableSettings && (
              <FileInputGroupWrap>
                <p>
                  Upload a clear logo in JPG or PNG format with a maximum file
                  size of 100KB. Images will scale proportionally to a 160px
                  height.
                </p>
                <ButtonsWrap data-testid='filestack-btn-wrap'>
                  <ReactFilestack
                    actionOptions={filestackOptions}
                    apikey={filestackKey}
                    customRender={filestackUpload}
                    onSuccess={onFileUpload}
                  />
                  {logoUri && (
                    <M.Button
                      kind='tertiary'
                      onClick={onFileDelete}
                      data-testid='btn-remove'
                    >
                      {t('verbs.remove')}
                    </M.Button>
                  )}
                </ButtonsWrap>
              </FileInputGroupWrap>
            )}
          </FileInputGroupSettings>
        </div>
      )}
      {smsSettingMoved && newCommunicationSettingsEnabled && (
        <div data-testid='sms-notification-section'>
          <StyledSeparator style={{ margin: '1.5rem 0' }} />
          <StyledTitle>
            {t('accountSettings.SMSNotifications.heading')}
          </StyledTitle>
          <p
            style={{ color: colors.uiTextSecondaryLight, marginBottom: '1rem' }}
          >
            {t('accountSettings.SMSNotifications.message')}
          </p>
          <M.Checkbox
            id='suppressSmsNotifications'
            onChange={toggleSmsSetting}
            checked={!suppressSmsNotifications}
            data-testid='suppress-sms-notifications'
            labelText={t('accountSettings.SMSNotifications.checkbox.label')}
          />
        </div>
      )}
      <>
        <StyledSeparator />
        <StyledTitleWrapper>
          <StyledTitle>{t('accountSettings.orderSettings')}</StyledTitle>
        </StyledTitleWrapper>
        {authorized && (
          <>
            <M.GridRow>
              <M.GridCol lg={10} style={{ padding: 0 }}>
                <div data-testid='manual-order-section'>
                  <StyledTitleWrapper>
                    <SmallTitle className={manualOrdersReviewStatus}>
                      {t('accountSettings.manualOrdering.heading')}
                    </SmallTitle>
                    {manualOrdersReviewStatus ===
                      ManualOrdersReview.NOT_REQUESTED && (
                      <div>
                        <M.Button
                          kind='secondary'
                          onClick={manualOrdersModalShow}
                        >
                          Set up
                        </M.Button>
                      </div>
                    )}
                  </StyledTitleWrapper>
                  <p
                    style={{
                      color: colors.uiTextSecondaryLight,
                      marginBottom: '1rem',
                    }}
                  >
                    {t('accountSettings.manualOrdering.description')}{' '}
                    <M.Link
                      onClick={manualOrdersModalShow}
                      style={{ cursor: 'pointer' }}
                      data-testid='modal-manual-orders'
                    >
                      {t(`accountSettings.manualOrdering.link`)}
                    </M.Link>
                  </p>
                  {!currentUser.roles?.includes('admin') ||
                  manualOrdersReviewStatus === ManualOrdersReview.REQUESTED ? (
                    <M.TooltipDefinition
                      definition={
                        !currentUser.roles?.includes('admin')
                          ? t(
                              'accountSettings.manualOrdering.checkbox.tooltip1',
                            )
                          : t(
                              'accountSettings.manualOrdering.checkbox.tooltip2',
                            )
                      }
                      align='top'
                      highlighted={false}
                    >
                      <M.Checkbox
                        id='enableManualOrdering'
                        onChange={toggleManualOrdering}
                        checked={enableManualOrdering}
                        data-testid='enable-manual-orders'
                        labelText={t(
                          'accountSettings.manualOrdering.checkbox.label',
                        )}
                        disabled
                      />
                    </M.TooltipDefinition>
                  ) : (
                    <M.Checkbox
                      id='enableManualOrdering'
                      onChange={toggleManualOrdering}
                      checked={enableManualOrdering}
                      data-testid='enable-manual-orders'
                      labelText={t(
                        'accountSettings.manualOrdering.checkbox.label',
                      )}
                    />
                  )}
                </div>
              </M.GridCol>
            </M.GridRow>
            <StyledSeparator />
            <ManualOrderModal
              show={showManualOrdersModal}
              handleClose={manualOrdersModalClose}
              handleSubmit={manualOrdersModalSubmit}
              manualOrdersReviewStatus={manualOrdersReviewStatus}
            />
          </>
        )}
        {aliasFlag && (
          <M.GridRow>
            <M.GridCol lg={10} style={{ padding: 0 }}>
              <div data-testid='default-alias-section'>
                <StyledTitleWrapper>
                  <SmallTitle>
                    {t('accountSettings.defaultAlias.heading')}
                  </SmallTitle>
                </StyledTitleWrapper>
                <p
                  style={{
                    color: colors.uiTextSecondaryLight,
                    marginBottom: '1rem',
                  }}
                >
                  {t('accountSettings.defaultAlias.description')}
                  <AliasLearnMoreModal text='search aliases.' />
                </p>
                <AliasRadioSelection
                  aliasAutoEnableSetting={aliasAutoEnable}
                  setDefaultAlias={handleAliasAutoEnable}
                  source='Account Settings'
                />
              </div>
            </M.GridCol>
          </M.GridRow>
        )}
      </>
      <SaveCancelGroup>
        {processing ? (
          <span>
            <M.LoadingInline />
          </span>
        ) : (
          <ButtonsWrap className='btn-wrap-primary'>
            <M.Button
              kind='secondary'
              disabled={!id || disableSettings}
              onClick={onCancel}
              data-testid='btn-cancel'
            >
              {t('buttons.cancel')}
            </M.Button>
            <M.Button
              kind='primary'
              disabled={!id || disableSettings}
              onClick={onSave}
              data-testid='btn-save'
            >
              {t('buttons.save_changes')}
            </M.Button>
          </ButtonsWrap>
        )}
        {!authorized && (
          <M.TooltipButton
            className='disabled'
            kind='danger'
            disabled={!id || disableSettings}
            style={{ margin: '8px' }}
            label='Submit a request to our support team to perform this action'
            data-testid='btn-delete-account'
            customStyles={{
              tooltip: {
                content: {
                  maxWidth: '200px',
                  textAlign: 'center',
                },
              },
            }}
          >
            Delete my account
          </M.TooltipButton>
        )}
      </SaveCancelGroup>
    </>
  );
};

export default AccountSettings;
