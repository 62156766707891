import { Tabs } from 'components/DashboardWrapper/tabs';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlagrContext } from '@dashboard-experience/react-flagr';
import SubmenuItem from './submenu-item';
import NavElement from './nav-element';
import { FullWidthActionMenu } from './styles';
import { useUser } from '../../../../context/CurrentUser';

type Props = {
  tabName: string;
  navCollapsed: boolean;
  submenuTabs: Tabs;
  currentPath?: string;
};

const NavElementWithSubmenu: React.FC<Props> = ({
  tabName,
  navCollapsed,
  submenuTabs,
  currentPath,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'navigation' });

  const user = useUser();
  const { flags } = useContext(FlagrContext) as any;

  const isActive = () => {
    for (const key in submenuTabs) {
      if (
        submenuTabs[key]?.activePrefix &&
        submenuTabs[key]?.activePrefix === currentPath
      ) {
        return true;
      }
    }
    return false;
  };

  const hasSubItemsToShow = Object.entries(submenuTabs).find(subMenuItem =>
    subMenuItem[1].authorized(user, flags),
  );

  const menuLabelText = t(tabName);
  return hasSubItemsToShow ? (
    <FullWidthActionMenu
      trigger={
        <NavElement
          navCollapsed={navCollapsed}
          text={menuLabelText}
          tabName={tabName}
          isActive={isActive()}
        />
      }
      position='right-top'
      positionMenuAbsolutely
      shouldCloseOnItemSelect
    >
      {Object.entries(submenuTabs).map(([titleKey, tab]) => {
        return <SubmenuItem key={titleKey} titleKey={titleKey} tab={tab} />;
      })}
    </FullWidthActionMenu>
  ) : null;
};

export default NavElementWithSubmenu;
