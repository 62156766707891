import React, { useCallback } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { PR_US_STATE_KEY } from 'Flags';
import {
  State,
  US_STATES,
} from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { StatesDropdown } from './Dropdown.styles';

const stateItemToString = (item: State) => item?.name;

const PR = { name: 'Puerto Rico', abbreviation: 'PR', id: 'PR' };

type Props = {
  selectedLocation: GetStartedT.WorkLocation;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
  disabled: boolean;
  invalid?: boolean;
};

const StateDropdown: React.FC<Props> = ({
  selectedLocation,
  setSelectedLocation,
  disabled = false,
  invalid = false,
}) => {
  const puertoRicoAsStateFlagEnabled =
    useFlag(PR_US_STATE_KEY)?.variantKey === 'true';

  const selectableStates = puertoRicoAsStateFlagEnabled
    ? [...US_STATES, PR]
    : US_STATES;

  const findStateByAbbreviation = () =>
    US_STATES.find(state => state.abbreviation === selectedLocation?.state);

  const selectedState = selectedLocation?.state
    ? findStateByAbbreviation()
    : '';

  const handleStateChange = useCallback(
    ({ selectedItem }) => {
      setSelectedLocation({
        ...selectedLocation,
        state: selectedItem?.abbreviation,
        city: undefined,
      });
    },
    [selectedLocation, setSelectedLocation],
  );

  return (
    <StatesDropdown
      data-testid='state-dropdown'
      id='state-dropdown'
      label='State'
      titleText=''
      items={selectableStates}
      disabled={disabled}
      itemToString={stateItemToString}
      invalidText='State is Required'
      invalid={invalid}
      selectedItem={selectedState}
      onChange={handleStateChange}
    />
  );
};

export default StateDropdown;
