/* eslint-disable react/jsx-no-bind */
import React from 'react';
import SpecialAddScreeningsAction, {
  SpecialAddScreeningsActionProps,
} from 'components/AddScreenings/shared/SpecialAddScreeningsAction';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type ProfessionalLicenseVerificationProps = {
  shouldOpen: boolean;
  getProfessionalLicenseVerificationPrice: (itemPrices: string[]) => void;
  itemPrices: string[];
  actionProps: ActionPropsProps;
  plvProps: SpecialAddScreeningsActionProps;
};

const ProfessionalLicenseVerification: React.FC<
  ProfessionalLicenseVerificationProps
> = ({
  shouldOpen,
  getProfessionalLicenseVerificationPrice,
  itemPrices,
  actionProps,
  plvProps,
}) => {
  return (
    <FlexContainer data-testid='professional-license-verification'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-professional-license-verification'
        title={
          <Title
            getPriceByType={() =>
              getProfessionalLicenseVerificationPrice(itemPrices)
            }
            actionProps={actionProps}
            title='Professional license verification'
            priceByType={ScreeningType.professional_license_verification}
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Verify the candidate’s professional license to confirm eligibility
            for specialized or regulated roles.
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Most verifications happen instantly.' />
              <BulletPoint text='License types include accounting, Coast Guard, contractor, dietitian, healthcare, insurance, IT (technology), mental health, nursing, pharmaceutical, real estate, and veterinary medicine.' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Some verifications take 1-2 business days.' />
              <BulletPoint text="Verify the candidate's license, not their identity." />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <SpecialAddScreeningsAction {...plvProps} />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default ProfessionalLicenseVerification;
