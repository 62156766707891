import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const ModalBodyText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding-bottom: 1.5rem;

  a {
    font-size: 1rem;
  }
`;

type PaymentProfileModalProps = {
  showPaymentProfileModal: boolean;
  handleClose: Function;
};

const PaymentProfileModal: React.FC<PaymentProfileModalProps> = ({
  showPaymentProfileModal,
  handleClose,
}) => {
  return (
    <M.ComposedModal
      open={showPaymentProfileModal}
      onClose={handleClose}
      data-testid='payment-profile-modal'
    >
      <M.ModalHeader closeModal={handleClose}>
        <h2>Payment profiles</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <ModalBodyText>
          To support your billing flexibility, you can select a payment profile
          for this order. Profiles can represent different departments or
          currencies to help streamline invoice management.
        </ModalBodyText>
        <ModalBodyText>
          By default, your new order uses your main account payment profile.
        </ModalBodyText>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default PaymentProfileModal;
