import React from 'react';
import Header from './header';
import Provider from './state';

type Props = {};

const Page: React.FC<Props> = () => {
  return (
    <Provider>
      <div data-testid='reports-list-page'>
        <Header />
      </div>
    </Provider>
  );
};

export default Page;
