import React from 'react';
import { useTranslation } from 'react-i18next';
import Cell from './Cell';

type PropsType = {
  taskType: string | null;
  url: string;
};

const TaskType = ({ taskType, url }: PropsType) => {
  const { t } = useTranslation('case-management');
  if (!taskType) return null;

  const type = t(`tasks.type.${taskType}`);
  return (
    <Cell url={url} testid='cm-ui-tasks-page-table-rows-task-type'>
      {type}
    </Cell>
  );
};

export default TaskType;
