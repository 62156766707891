/* eslint-disable react/display-name */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ChargeExplainer } from 'components/AiExplainr';

export const handleAiClick = (state: string, county: string) => {
  return (type: any, charge: any) => (
    <ChargeExplainer charge={charge} state={state} county={county} />
  );
};
