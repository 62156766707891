import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Dropdown } from '../../SharedItems';
import { City, MAJOR_CITIES } from '../MajorCities';
import { useOrderBackgroundCheckContext, actionTypes } from '../../Context';

const CityDropdown = styled(Dropdown)``;

const cityItemToString = (city: City) =>
  city ? `${city.name}, ${city.state}` : '';

const SelectCity: React.FC<any> = ({ cities = MAJOR_CITIES }) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const selectableCities = useMemo(
    () =>
      cities.filter(
        (c: City) => c.state === state.location.state?.abbreviation,
      ),
    [cities, state.location.state],
  );
  // Use base package to see whether the user has come from the review & submit page
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  const handleCityChange = useCallback(
    ({ selectedItem }: any) => {
      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: { location: { ...state.location, city: selectedItem } },
      });
      // Reset if user comes back to page and changes location
      if (
        state.location?.city?.name !== selectedItem &&
        fromReviewAndSubmitPage
      ) {
        dispatch({
          type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
          payload: {},
        });
      }
    },
    [dispatch, state.location, fromReviewAndSubmitPage],
  );

  return (
    <>
      {state.location.state && selectableCities.length > 0 && (
        <CityDropdown
          data-testid='city-dropdown-testid'
          label='City (Optional)'
          items={selectableCities}
          itemToString={cityItemToString}
          onChange={handleCityChange}
          selectedItem={state.location.city !== '' ? state.location.city : null}
        />
      )}
    </>
  );
};

export default SelectCity;
