export enum CSV_EVENTS {
  CSV_VIEW_CSV_DOWNLOAD = 'Checkr Dashboard Candidate Search Download CSV Result Downloaded',
}

export enum CANDIDATE_REPORT_EVENT_NAMES {
  REPORT_VIEW_ORDER_NEW_REPORT_SELECTED = 'Checkr Dashboard Report View Order New Report Selected',
  REPORT_VIEW_REPORT_LOG_USED = 'Checkr Dashboard Report View Report Log Used',
  REPORT_VIEW_REPORT_LOG_FILTER_USED = 'Checkr Dashboard Report View Report Log Filter Used',
  REPORT_VIEW_VIEWED = 'Checkr Dashboard Report View Viewed',
  REPORT_ADD_SUBSCRIPTION_SELECTED = 'Checkr Dashboard Report View Add Subscription Selected',
  REPORT_DELETE_CANDIDATE_DATA = 'Checkr Dashboard Report View Delete Candidate Data Selected',
  REPORT_DOWNLOAD_PDF_SELECTED = 'Checkr Dashboard Report View Download PDF Selected',
  REPORT_SETTINGS_SELECTED = 'Checkr Dashboard Report View Settings Selected',
  REPORT_CANDIDATE_MODAL_COMPLETED = 'Checkr Dashboard Report View Delete Candidate Data Modal Completed',
  REPORT_REQUEST_NEW_REPORT_SUBMITTED = 'Checkr Dashboard Report View Request new report for this candidate submitted',
  CUSTOMER_SELECTED_CANDIDATE_ACTION = 'Checkr Dashboard Report View Action Selected',
  VIEW_ADVERSE_ACTION_INFO = 'Checkr Dashboard View Adverse Action Info',
}

export enum CANDIDATE_SEARCH_EVENT_NAMES {
  CANDIDATE_SEARCH_PAGE_LANDED = 'Checkr Dashboard Candidate Search Page View',
  CANDIDATE_SEARCH_DOWNLOAD_CSV_BUTTON_CLICKED = 'Checkr Dashboard Candidate Search Download CSV Button Clicked',
  CANDIDATE_SEARCH_DOWNLOAD_CSV_MODAL_EXPORT_BUTTON_CLICKED = 'Checkr Dashboard Candidate Search Download CSV Modal Export Button Clicked',
  CANDIDATE_SEARCH_RESULTS_SORTED = 'Checkr Dashboard Candidate Search Result Sorted',
  CANDIDATE_SEARCH_FILTER_SELECTED = 'Checkr Dashboard Candidate Search Filter Selected',
  CANDIDATE_SEARCH_FILTER_RESET = 'Checkr Dashboard Candidate Search Filter Reset',
}

export enum CASE_MANAGEMENT_EVENT_NAMES {
  TASKS_PAGE_VIEWED = 'Checkr Dashboard Tasks Page Viewed',
  TASKS_PAGE_REPORT_LINK_CLICKED = 'Checkr Dashboard Tasks Page - Report Link Clicked',
}

export enum ORDER_BACKGROUND_CHECK_BULKORDER_NAMES {
  DOWNLOAD_CSV_TEMPLATE_CLICKED = 'Checkr Dashboard Order BGC Bulk Order Download CSV Template Clicked',
  BULK_ORDER_REDIRECT_TO_DOCUMENTS = 'Checkr Dashboard Order BGC Bulk Order Redirect to Documents',
  CSV_SUCCESSFULLY_UPLOADED = 'Checkr Dashboard Order BGC Bulk Order CSV File Uploaded',
  CSV_FILE_DELETED = 'Checkr Dashboard Order BGC Bulk Order CSV File Deleted',
  HISTORY_NAVIGATION = 'Checkr Dashboard Bulk Order History Navigation',
  HISTORY_HIDDEN_VIEWED = 'Checkr Dashboard Bulk Order History (Hidden) Viewed',
  MANUAL_INVITE_CLICKED = 'Checkr Dashboard Order BGC Bulk Order Enter Manually Card Clicked',
  UPLOAD_INVITE_CLICKED = 'Checkr Dashboard Order BGC Bulk Order Upload CSV Card Clicked',
}

export enum MANUAL_BULKORDER_NAMES {
  MANUAL_ORDERS_MODAL_OPENED = 'Checkr Dashboard Order BGC Manual Orders Modal Opened',
  DOWNLOAD_ALL_FORMS_BUTTON_CLICKED = 'Checkr Dashboard Order BGC Manual Bulk Order Download All Forms Button Clicked',
  ENABLE_MANUAL_ORDERS_SETTING_CHANGED = 'Checkr Dashboard Account Enable Manual Orders Setting Changed',
}

export enum PAYMENT_AND_BILLING_NAMES {
  PAYMENT_DOCUMENTS_TAB_CLICKED = 'Checkr Dashboard Payment and Billing Documents Tab Clicked',
  PAYMENT_AND_BILLING_PAGE_VIEWED = 'Checkr Dashboard Payment and Billing Page Viewed',
  CSV_FILE_HYPERLINK_CLICKED = 'Checkr Dashboard Payment and Billing CSV File Hyperlink Clicked',
  ERRORS_FILE_HYPERLINK_CLICKED = 'Checkr Dashboard Payment and Billing Errors File Hyperlink Clicked',
}

export enum ARCHIVE_REPORTS_NAMES {
  ARCHIVE_REPORT_ACTION_SELECTED = 'Checkr Dashboard Report View Archive Report Action Selected',
  DASHBOARD_RESULTS_CONTENT_TYPE = 'Checkr Dashboard Candidate Search Filter Selected',
}

export enum ARCHIVE_INVITATIONS_NAMES {
  ARCHIVE_INVITATION_ACTION_SELECTED = 'Checkr Dashboard Invitation View Archive Invitation Button Clicked',
}

export enum CANDIDATE_SEARCH_DROPDOWN_NAMES {
  REPORT_STATUS = 'Report status',
  ASSESSMENT = 'Assessment',
  ADJUDICATION = 'Adjudication',
  MONITOR_TYPE = 'Monitor type',
  INVITATION_STATUS = 'Invitation status',
  REPORTS_WITH_CANCELED_ITEMS = 'Reports with canceled items',
  PROGRAM = 'Program',
  NODE_NAME = 'Node Name',
  NODE_ID = 'Node ID',
}

export enum SIDE_MENU_NAVIGATION_NAMES {
  PAY_NAVIGATION_CLICKED = 'Checkr Dashboard Pay Navigation Item Clicked',
  TRUST_NAVIGATION_CLICKED = 'Checkr Dashboard Trust Navigation Item Clicked',
  HOME_NAVIGATION_CLICKED = 'Checkr Dashboard Home Page Navigation Item Clicked',
}

export enum I9_EVENT_NAMES {
  // I-9 Page
  I9_ACCOUNT_SETUP_FORM_VIEWED = 'Checkr Dashboard I9 Customer Account Setup form page viewed',
  I9_ACCOUNT_SETUP_GET_STARTED_VIEWED = 'Checkr Dashboard I9 Customer Account Setup get started page viewed',
  I9_ACCOUNT_SETUP_TOS_VIEWED = 'Checkr Dashboard I9 Customer Account Setup terms of service page viewed',
  I9_ACCOUNT_SETUP_COMPLETED = 'Checkr Dashboard I9 Customer Account Setup Enter Details Completed',
  I9_ORDER_STARTED = 'Checkr Dashboard I9 Order Started',
  I9_ORDER_COMPLETED = 'Checkr Dashboard I9 Review & Submit Order Completed',
  I9_ORDER_SELECT_WORKFLOW_STARTED = 'Checkr Dashboard I9 Select Workflow Started',
  I9_ORDER_SELECT_WORKFLOW_COMPLETED = 'Checkr Dashboard I9 Select Workflow Completed',
  I9_ORDER_CANDIDATE_SELECT_STARTED = 'Checkr Dashboard I9 Candidate Selection Started',
  I9_ORDER_CANDIDATE_SELECT_COMPLETED = 'Checkr Dashboard I9 Candidate Selection Completed',
  I9_STATUSES_PAGE_VIEWED = 'Checkr Dashboard I9 statuses page viewed',
  I9_SETTINGS_PAGE_VIEWED = 'Checkr Dashboard I9 settings page viewed',
}

export enum DASHBOARD_LANDING_EVENT_NAMES {
  DASHBOARD_HOME_PAGE_VIEWED = 'Checkr Dashboard Home Page View',
  DASHBOARD_FOX_HOME_PAGE_VIEWED = 'Checkr Dashboard Home Page View - FOX',
  REPORT_METRICS_CLICKED = 'Checkr Dashboard Home Report Metrics Click',
  REPORT_METRICS_DATE_FILTER_CLICKED = 'Checkr Dashboard Home Report Metrics - Date Filter Click',
  SETUP_AND_EXPLORE_CLICKED = 'Checkr Dashboard Home Setup and Explore Click',
  RESOURCES_CLICKED = 'Checkr Dashboard Home Resources Click',
  BLOG_CLICKED = 'Checkr Dashboard Home Blog Click',
  ORDER_BGC_CLICKED = 'Checkr Dashboard Home Order BGC Click',
  LOGO_CLICKED = 'Checkr Dashboard Checkr Logo Navigation Item Clicked',
  LOGO_UPDATED = 'Checkr Dashboard Account Settings Logo Updated',
}

export enum SIGNUP_EVENT_NAMES {
  SIGNUP_PAGE_VIEWED = 'Self-Serve Customer Signup Signup Page Viewed',
  SIGNUP_PAGE_COMPLETED = 'Self-Serve Customer Signup Signup Page Completed',
  SIGNUP_CHECKR_USER_ACCOUNT_SIGNED_IN = 'Self-Serve Customer Signup Checkr User Account Signed In',
  SIGNUP_ORDER_BGC_PAGE_COMPLETED = 'Self-Serve Customer Signup Order BGC Page Completed',
  SIGNUP_ORDER_BGC_SELECT_PACKAGE_VIEWED = 'Self-Serve Customer Signup Order BGC - Select a Package Step Viewed',
  SIGNUP_ORDER_BGC_CUSTOMIZE_ADDONS_VIEWED = 'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Viewed',
  SIGNUP_ORDER_BGC_INVITE_CANDIDATES_STEP_VIEWED = 'Self-Serve Customer Signup Order BGC - Invite Candidates Step Viewed',
  SIGNUP_ORDER_BGC_SKIP_ORDER_CLICKED = 'Self-Serve Customer Signup Skip Order BGC Flow Clicked',
  SIGNUP_ORDER_BGC_LEARN_MORE_CLICKED = 'Self-Serve Customer Signup Learn More Clicked',
  SIGNUP_ORDER_BGC_SKIP_ORDER_FLOW_COMPLETED = 'Self-Serve Customer Signup Skip Order BGC Flow Completed',
  SIGNUP_PAYMENT_INFORMATION_COMPLETED = 'Self-Serve Customer Signup Payment Information Completed',
  SIGNUP_RETURN_TO_PAYMENT = 'Self-Serve Customer Signup Return to Payment Page Viewed',
  SIGNUP_CREATE_PAYMENT_INFORMATION_VIEWED = 'Self-Serve Customer Signup Payment Information Viewed',
  SIGNUP_SELECT_PACKAGE_STEP_COMPLETED = 'Self-Serve Customer Signup Order BGC - Select a Package Step Completed',
  SIGNUP_CUSTOMIZE_ADDONS_STEP_COMPLETED = 'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Completed',
  SIGNUP_INVITE_CANDIDATES_STEP_COMPLETED = 'Self-Serve Customer Signup Order BGC - Invite Candidates Step Completed',
  SIGNUP_PAYMENT_INFORMATION_NEXT_PAGE_CLICKED = 'Self-Serve Customer Signup Payment Information Next Page Clicked',
  SIGNUP_SELECT_PACKAGE_STEP_CLICKED = 'Self-Serve Customer Signup Order BGC - Select a Package Step Next Page Clicked',
  SIGNUP_CUSTOMIZE_ADDONS_STEP_CLICKED = 'Self-Serve Customer Signup Order BGC - Customize Add Ons Step Next Page Clicked',
  SIGNUP_INVITE_CANDIDATES_STEP_CLICKED = 'Self-Serve Customer Signup Order BGC - Invite Candidates Step Next Page Clicked',
  SIGNUP_PACKAGE_CARD_CLICKED = 'Self-Serve Customer Signup Order BGC - Package Card Clicked',
  SIGNUP_SIGNUP_PAGE_NEXT_CLICKED = 'Self-Serve Customer Signup Signup Page Next Page Clicked',
  SIGNUP_SELECT_PACKAGE_STEP_VIEWED = 'Self-Serve Customer Signup Order BGC - Select a Package Step Viewed',
  SIGNUP_ALIAS_OFFERED = 'Self-Serve Customer Signup Order BGC - Alias Offered',
  SIGNUP_ALIAS_RADIO_BUTTON_CLICKED = 'Self-Serve Customer Signup Order BGC - Alias Radio Button Clicked',
}

export enum SECONDARY_ACTIONS_EVENT_NAMES {
  CANCEL_ADVERSE_ACTION_MAIN_BUTTON_CLICKED = 'Checkr Dashboard Report View Cancel Adverse Action Main Button Clicked',
  CANCEL_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED = 'Checkr Dashboard Report View Cancel Adverse Action Dropdown Menu Clicked',
  PAUSE_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED = 'Checkr Dashboard Report View Pause Adverse Action Dropdown Menu Clicked',
  UPDATE_ASSESSMENT_DROPDOWN_MENU_CLICKED = 'Checkr Dashboard Report View Update Assessment Dropdown Menu Clicked',
  RESUME_ADVERSE_ACTION_MAIN_BUTTON_CLICKED = 'Checkr Dashboard Report View Resume Adverse Action Main Button Clicked',
  RESUME_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED = 'Checkr Dashboard Report View Resume Adverse Action Dropdown Menu Clicked',
  POST_ADVERSE_ACTION_MAIN_BUTTON_CLICKED = 'Checkr Dashboard Report View Post Adverse Action Main Button Clicked',
  POST_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED = 'Checkr Dashboard Report View Post Adverse Action Dropdown Menu Clicked',
}

export enum FOX_SIGNUP_EVENT_NAMES {
  FOX_SIGNUP_ORDER_PLACED = 'Self-Serve Customer Signup Order Placed',
  FOX_SIGNUP_ACCORDION_EXPANDED = 'Self-Serve Customer Signup FOX Signup Accordion Expanded',
  FOX_SIGNUP_BACK_BUTTON_CLICKED = 'Self-Serve Customer Signup Back Button Clicked',
}

export enum BETA_EVENT_NAMES {
  BETA_OPT_IN = 'Customer Dashboard Beta Opt In',
  BETA_OPT_OUT = 'Customer Dashboard Beta Opt Out',
}

export enum EXCEPTION_EVENT {
  SEND_CANDIDATE_REQUEST = 'Checkr Dashboard Report View Send Exceptions Candidate Request',
}

export enum ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT {
  CHECKR_DASHBOARD_ORDER_BGC_PARTNER_PAYMENT_MODAL_VIEWED = 'Checkr Dashboard Order BGC Partner Payment Modal Viewed',
  CHECKR_DASHBOARD_PARTNER_PAYMENT_ENTERED = 'Checkr Dashboard Partner Payment Entered',
}

export enum AI_CHARGE_EXPLAINER_FEEDBACK {
  AI_CHARGE_EXPLAINER__MAIN_BUTTON_CLICKED = 'Checkr Dashboard Report View AI Charge Explainer Main Button Clicked',
  AI_CHARGE_EXPLAINER_THUMBS_UP = 'Checkr Dashboard Report View AI Charge Explainer Thumbs Up Clicked',
  AI_CHARGE_EXPLAINER_THUMBS_DOWN = 'Checkr Dashboard Report View AI Charge Explainer Thumbs Down Clicked',
  AI_CHARGE_EXPLAINER_FEEDBACK_REASON_SELECTED = 'Checkr Dashboard Report View AI Charge Explainer Feedback Reason Submitted',
}

export enum REPORT_SETTING_EVENT_NAMES {
  REPORT_SETTINGS_REPORT_VIEW_SHOW_REPORT_SUMMARY_CLICKED = 'Checkr Dashboard Report View Report Settings Show Report Summary Clicked',
  REPORT_SETTINGS_ACCOUNT_SETTINGS_TAB_HIDE_REPORT_SUMMARY_CLICKED = 'Checkr Dashboard Account Settings Tab Report Settings Hide Report Summary Clicked',
  REPORT_SETTINGS_SHOW_EMPTY_FIELDS = 'Checkr Dashboard Report Settings Show Empty Fields Clicked',
  REPORT_SETTINGS_SHOW_RESOLVED_EXCEPTIONS = 'Checkr Dashboard Report Settings Show Resolved Exceptions Clicked',
}

export type AnalyticsEvent =
  | (typeof CSV_EVENTS)[keyof typeof CSV_EVENTS]
  | (typeof CANDIDATE_REPORT_EVENT_NAMES)[keyof typeof CANDIDATE_REPORT_EVENT_NAMES]
  | (typeof CANDIDATE_SEARCH_EVENT_NAMES)[keyof typeof CANDIDATE_SEARCH_EVENT_NAMES]
  | (typeof CASE_MANAGEMENT_EVENT_NAMES)[keyof typeof CASE_MANAGEMENT_EVENT_NAMES]
  | (typeof ORDER_BACKGROUND_CHECK_BULKORDER_NAMES)[keyof typeof ORDER_BACKGROUND_CHECK_BULKORDER_NAMES]
  | (typeof MANUAL_BULKORDER_NAMES)[keyof typeof MANUAL_BULKORDER_NAMES]
  | (typeof PAYMENT_AND_BILLING_NAMES)[keyof typeof PAYMENT_AND_BILLING_NAMES]
  | (typeof ARCHIVE_REPORTS_NAMES)[keyof typeof ARCHIVE_REPORTS_NAMES]
  | (typeof ARCHIVE_INVITATIONS_NAMES)[keyof typeof ARCHIVE_INVITATIONS_NAMES]
  | (typeof SIDE_MENU_NAVIGATION_NAMES)[keyof typeof SIDE_MENU_NAVIGATION_NAMES]
  | (typeof I9_EVENT_NAMES)[keyof typeof I9_EVENT_NAMES]
  | (typeof DASHBOARD_LANDING_EVENT_NAMES)[keyof typeof DASHBOARD_LANDING_EVENT_NAMES]
  | (typeof SIGNUP_EVENT_NAMES)[keyof typeof SIGNUP_EVENT_NAMES]
  | (typeof SECONDARY_ACTIONS_EVENT_NAMES)[keyof typeof SECONDARY_ACTIONS_EVENT_NAMES]
  | (typeof DASHBOARD_LANDING_EVENT_NAMES)[keyof typeof DASHBOARD_LANDING_EVENT_NAMES]
  | (typeof FOX_SIGNUP_EVENT_NAMES)[keyof typeof FOX_SIGNUP_EVENT_NAMES]
  | (typeof ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT)[keyof typeof ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT]
  | (typeof BETA_EVENT_NAMES)[keyof typeof BETA_EVENT_NAMES]
  | (typeof EXCEPTION_EVENT)[keyof typeof EXCEPTION_EVENT]
  | (typeof AI_CHARGE_EXPLAINER_FEEDBACK)[keyof typeof AI_CHARGE_EXPLAINER_FEEDBACK]
  | (typeof REPORT_SETTING_EVENT_NAMES)[keyof typeof REPORT_SETTING_EVENT_NAMES];
