import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Exception } from 'types';
import MultipleExceptionsBanner from './multiple-exceptions-banner';
import SingleExceptionBanner from './single-exception-banner';

const StyledResolvableExceptionsBanners = styled.div`
  width: 100%;
  .expandable-panel-heading-left {
    max-width: 75%;
  }
  * {
    line-height: 23px;
  }
`;

const ResolvableExceptionsBanners = ({
  unresolvedExceptions,
}: {
  unresolvedExceptions: Exception[];
}) => {
  const unexpiredExceptions = unresolvedExceptions.filter(exception => {
    const createdAt = moment(exception.created_at);
    // Handle edge case where expires_at is null: default to 7 days after the creation date.
    const defaultExpiresAt = createdAt.add(7, 'days');
    const expiresAt = moment(exception.expires_at || defaultExpiresAt);

    return expiresAt.isAfter(moment.utc());
  });

  if (!unexpiredExceptions.length) return null;

  return (
    <StyledResolvableExceptionsBanners>
      <SingleExceptionBanner exceptions={unexpiredExceptions} />
      <MultipleExceptionsBanner exceptions={unexpiredExceptions} />
    </StyledResolvableExceptionsBanners>
  );
};

export default ResolvableExceptionsBanners;
