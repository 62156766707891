import React, { useCallback } from 'react';
import { useSignupPageContext } from 'pages/Signup/context';
import { useTranslation } from 'react-i18next';
import useSignupAmplitudeEvents from 'components/Signup/hooks/useSignupAmplitudeEvents';

import {
  SectionSubtitle,
  TitleContainer,
  Title,
  Subtitle,
  Section,
  StyledRadioButton,
  SectionTitleContainer,
  Recommended,
  SectionTitle,
  LoadingBlock,
  RadioButtonGroup,
  Bold,
} from './styles';

type Props = {
  aliasPrice: string | null;
};

const AliasRadioButton = ({ aliasPrice }: Props) => {
  const { t } = useTranslation('signup');
  const { alias, setAlias, selectedPackage } = useSignupPageContext();
  const { trackAliasRadioButtonClickedEvent } = useSignupAmplitudeEvents();

  const onChange = useCallback(
    (event: React.ChangeEvent) => {
      setAlias((prevAlias: any) => {
        return {
          ...prevAlias,
          [selectedPackage]: (event.target as HTMLInputElement).value,
        };
      });
      trackAliasRadioButtonClickedEvent(
        (event.target as HTMLInputElement).value,
      );
    },
    [selectedPackage, setAlias, trackAliasRadioButtonClickedEvent],
  );

  return (
    <Section>
      <SectionTitleContainer>
        <SectionTitle>{t('components.alias.title')}</SectionTitle>
        <Recommended>{t('components.alias.recommended')}</Recommended>
      </SectionTitleContainer>
      <SectionSubtitle>{t('components.alias.subtitle')}</SectionSubtitle>
      <RadioButtonGroup
        name='reason-for-alternate'
        orientation='vertical'
        valueSelected={alias[selectedPackage]}
      >
        <StyledRadioButton
          key='enabled'
          labelText={
            <TitleContainer>
              <Title>
                {t('components.alias.enabledTitle1')}
                {aliasPrice || <LoadingBlock />}*
                {t('components.alias.enabledTitle2')}
              </Title>
              {alias[selectedPackage] !== 'false' && (
                <Subtitle>{t('components.alias.enabledSubtitle')}</Subtitle>
              )}
            </TitleContainer>
          }
          checked={alias[selectedPackage] === 'true'}
          value='true'
          onClick={onChange}
          data-testid='alias-enabled'
        />
        <StyledRadioButton
          key='disabled'
          labelText={
            <TitleContainer>
              <Title>{t('components.alias.disabledTitle')}</Title>
              {alias[selectedPackage] !== 'true' && (
                <Subtitle>
                  <Subtitle>
                    {t('components.alias.disabledSubtitle1')}
                    <Bold>{t('components.alias.disabledSubtitle2')}</Bold>
                    {t('components.alias.disabledSubtitle3')}
                  </Subtitle>
                </Subtitle>
              )}
            </TitleContainer>
          }
          checked={alias[selectedPackage] === 'false'}
          value='false'
          onClick={onChange}
          data-testid='alias-disabled'
        />
      </RadioButtonGroup>
    </Section>
  );
};

export default AliasRadioButton;
