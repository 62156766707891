import React, { useCallback, useState } from 'react';
import { useGetStates } from 'api/location';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { useFlag } from '@dashboard-experience/react-flagr';
import { USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS } from 'Flags';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { State } from 'components/AddScreenings/types';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import OutOfCountrySearch from './CriminalRecordScreenings/OutOfCountrySearch';
import FederalCriminalSearch from './CriminalRecordScreenings/FederalCriminalSearch';
import StatewideCriminalSearch from './CriminalRecordScreenings/StatewideCriminalSearch';
import CountyCriminalSearch from './CriminalRecordScreenings/CountyCriminalSearch';
import {
  AccordionItem,
  AccordionItemProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import courtWeights from '../../shared/svg/court-weights.svg';
import GlobalWatchlistSearch from './CriminalRecordScreenings/GlobalWatchlistSearch';

type CriminalRecordsSearchesItemProps = AccordionItemProps & {
  usingAddChecks: boolean;
  itemPrices: any[];
};

const CriminalRecordsSearchesItem = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
  usingAddChecks,
  itemPrices,
}: CriminalRecordsSearchesItemProps) => {
  const dispatch = useDispatch();
  const [states, setStates] = useState<State[]>([]);
  const showGlobalWatchlist =
    useFlag(USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS)?.variantKey === 'on';

  const handleGetStatesSuccess = (data: any) => {
    if (data) {
      const cleaned = Object.values(data).map((country: any) => {
        const { id, name, short_name } = country;
        return {
          id,
          name,
          abbreviation: short_name,
        };
      });
      setStates(cleaned);
    }
  };

  const handleGetStatesError = (error: Error) => {
    dispatch(toastError('Error retrieving states: ', error.message));
  };

  const getItemPrice = useCallback(
    (productType: ScreeningType) => {
      const itemPrice = itemPrices?.find((item: any) => {
        return item.product_type === productType && item.subtype === 'current';
      })?.base_price;
      return itemPrice ? getDollarAmount(itemPrice) : 'Loading price...';
    },
    [itemPrices],
  );

  const handlePriceStandaloneCountyCrim = useCallback(() => {
    // Note: we are using the same SKU for all the counties
    return getItemPrice(ScreeningType.county_criminal_search);
  }, [getItemPrice]);

  const handlePriceStateCrim = useCallback(() => {
    // Note: we are using the same SKU for all the states
    return getItemPrice(ScreeningType.state_criminal_search);
  }, [getItemPrice]);

  useGetStates(handleGetStatesSuccess, handleGetStatesError, usingAddChecks);

  return (
    <AccordionItem
      open={
        screeningTypesInSearch?.includes('county_criminal_search') ||
        screeningTypesInSearch?.includes('state_criminal_search') ||
        screeningTypesInSearch?.includes('federal_criminal_search') ||
        screeningTypesInSearch?.includes('global_watchlist_search') ||
        (screeningTypesInSearch?.includes('international_criminal_search_v2') &&
          screeningTypesInSearch?.includes(
            'international_adverse_media_search',
          ))
      }
      data-testid='criminal-records-searches-dropdown'
      title={
        <CategoryTitle data-testid='criminal-records-searches-title'>
          <Icon src={courtWeights} className='screening-icon' /> Criminal
          searches
        </CategoryTitle>
      }
    >
      <ul>
        <CountyCriminalSearch
          shouldOpen={screeningTypesInSearch?.includes(
            'county_criminal_search',
          )}
          getPriceByType={getPriceByType}
          handlePrice={handlePriceStandaloneCountyCrim} // Only used for Add Checks
          actionProps={actionProps}
          usingAddChecks={usingAddChecks}
          states={states}
        />
        <StatewideCriminalSearch
          shouldOpen={screeningTypesInSearch?.includes('state_criminal_search')}
          getPriceByType={getPriceByType}
          handlePrice={handlePriceStateCrim} // Only used for Add Checks
          actionProps={actionProps}
          usingAddChecks={usingAddChecks}
          states={states}
        />
        <FederalCriminalSearch
          shouldOpen={screeningTypesInSearch?.includes(
            'federal_criminal_search',
          )}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
        />
        {showGlobalWatchlist && (
          <GlobalWatchlistSearch
            shouldOpen={screeningTypesInSearch?.includes(
              'global_watchlist_search',
            )}
            getPriceByType={getPriceByType}
            actionProps={actionProps}
          />
        )}
        <OutOfCountrySearch
          shouldOpen={
            screeningTypesInSearch?.includes(
              'international_criminal_search_v2',
            ) &&
            screeningTypesInSearch?.includes(
              'international_adverse_media_search',
            )
          }
          getPriceByType={getPriceByType}
          actionProps={actionProps}
        />
      </ul>
    </AccordionItem>
  );
};

export default CriminalRecordsSearchesItem;
