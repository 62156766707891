import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/rulesets/context-dates/option';
import { useDispatch } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { Actions as OptionsActions } from 'modules/assess/ui/state/context-date/options';
import { postMessageToDashboard } from 'utils';
import Actions from './actions';
import Description from './Description';
import Position from './Position';
import Row from './Row';
import RemoveDialog from '../remove';

type Props = {
  contextDate: Entity.Type;
  optionsCount: number;
};

export const Container: React.FC<Props> = ({ contextDate, optionsCount }) => {
  const dispatch = useDispatch();
  const [open, show] = useState(false);
  const positionDown = useCallback(
    () =>
      dispatch(
        OptionsActions.Position.create({
          id: contextDate.id,
          position: +1,
          relative: true,
        }),
      ),
    [dispatch, contextDate],
  );

  const positionUp = useCallback(
    () =>
      dispatch(
        OptionsActions.Position.create({
          id: contextDate.id,
          position: -1,
          relative: true,
        }),
      ),
    [dispatch, contextDate],
  );

  const remove = useCallback(() => {
    postMessageToDashboard({
      messageId: 'scroll_to_top',
    });
    show(true);
  }, [show]);

  const handleClose = useCallback(() => show(false), [show]);
  const handleDelete = useCallback(() => {
    dispatch(OptionsActions.Remove.create(contextDate));
  }, [contextDate, dispatch]);

  return (
    <>
      <M.Container
        data-testid='assess-rules-version-context-dates-options'
        style={{ paddingLeft: 0, paddingRight: 0 }}
        type='info'
      >
        <Row>
          <Position position={contextDate.position} />
          <Description option={contextDate.option} />
          <Actions
            actions={{ positionDown, positionUp, remove }}
            position={contextDate.position}
            optionsCount={optionsCount}
          />
        </Row>
      </M.Container>
      <RemoveDialog
        open={open}
        option={contextDate.option}
        onDelete={handleDelete}
        onClose={handleClose}
      />
    </>
  );
};

export default Container;
