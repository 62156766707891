import React from 'react';
import { M } from '@dashboard-experience/mastodon';

interface MenuItemProps {
  openModal: (e: React.MouseEvent) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ openModal }) => {
  return <M.ActionMenuItem title='Add Checks' onClick={openModal} />;
};
export default MenuItem;
