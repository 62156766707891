import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { SCREENING_NAME_MAP, SCREENING_SUBTYPE_NAME_MAP } from 'Constants';
import styled from 'styled-components';
import { Package } from 'types';
import { titleize } from '@dashboard-experience/utils';
import AliasTag from 'components/Packages/Alias';

const H2 = styled.h2`
  #mastodon && {
    margin-bottom: 24px;
  }
`;

const ScreeningsBody = styled.span`
  display: block;
  padding-bottom: 16px;
  color: ${colors.uiGrey900};
  font-weight: 700;
`;

const ScreeningName = styled.li`
  color: ${colors.uiGrey900}C6; // C6 = 78% opacity
  line-height: 28px;
`;

const Icon = styled(M.Icon)`
  fill: ${colors.uiAqua500};
  vertical-align: text-bottom;
  margin-right: 8px;
`;

type Props = {
  open: boolean;
  closeModal: Function;
  packageObj: Package;
};

const PackageModal: React.FC<Props> = ({ open, closeModal, packageObj }) => {
  const { screenings, name } = packageObj;

  return (
    <M.ComposedModal open={open} onClose={closeModal}>
      <M.ModalHeader title='Package details' buttonOnClick={closeModal} />
      <M.ModalBody>
        <H2>{name}</H2>
        <ScreeningsBody>Screenings</ScreeningsBody>
        <ul>
          {screenings?.map(({ type, subtype }) => {
            // If our predefined maps don't have the screening/subtype names in them, then use titleize to try converting the slug into a reasonable name
            const screeningName = SCREENING_NAME_MAP[type] || titleize(type);
            const subtypeName = subtype
              ? SCREENING_SUBTYPE_NAME_MAP[subtype] ||
                titleize(subtype as string)
              : '';

            return (
              <ScreeningName key={type}>
                <Icon icon='Checkmark' size='20' />

                {screeningName}
                {subtypeName && ` (${subtypeName})`}
              </ScreeningName>
            );
          })}
        </ul>
        <AliasTag basePackage={packageObj} />
      </M.ModalBody>

      <M.ModalFooter
        actions={{
          primary: {
            name: 'Got it',
            onClick: closeModal,
          },
        }}
      />
    </M.ComposedModal>
  );
};

export default PackageModal;
