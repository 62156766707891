import React, { useEffect } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

import { localStorageFallback } from '@dashboard-experience/utils';
import {
  DOMESTIC_GEO,
  INTERNATIONAL_GEO,
  useCreatePackageContext,
} from './Context';

const MainSection = styled.div`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 40px 80px;
  padding-bottom: 32px !important;
  background-color: ${colors.uiGrey0};
  margin-bottom: 50px !important;

  align-self: center;
  width: 100%;
  max-width: 768px !important;
  height: 260px !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainerText = styled.div`
  margin-top: 32px !important;
  margin-bottom: 20px !important;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.brandNavy3};
  line-height: 20px;
`;

const RadioButtonGroup = styled(M.RadioButtonGroup)`
  .mastodon-radio-button {
    width: 189px;
    display: inline-flex;
    padding: 0.75rem;
    border: 1px solid ${colors.brandNavy2};
    border-radius: 4px;

    .cds--radio-button__label {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      color: ${colors.brandNavy4};
    }
  }

  .cds--radio-button__appearance {
    margin-right: 0.5rem;
    border: 1px solid ${colors.brandSlate6};
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance {
    border: 1px solid ${colors.brandNavy2};
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    background-color: ${colors.brandNavy2};

    .cds--radio-button:checked {
      background-color: ${colors.uiOrange600} !important;
    }
  }
`;

const StyledRadioButton = styled(M.RadioButton)`
  background: ${props =>
    props.selected ? `${colors.brandSlate2}` : `${colors.uiGrey0}`};
  border: ${props =>
    props.selected
      ? `1px solid ${colors.brandNavy2} !important`
      : `1px solid ${colors.brandSlate3} !important`};
`;

const GetStarted = () => {
  const {
    reducerState: { selectedGeo },
    search: { queryParamsPresent, searchCleaned },
    canCreateInternationalPackage,
    functions: { addSelectedGeoToReducerState },
  } = useCreatePackageContext();

  useEffect(() => {
    if (!queryParamsPresent && searchCleaned) {
      localStorageFallback.setItem('selectedGeo', selectedGeo);
    }
  }, [selectedGeo, queryParamsPresent, searchCleaned]);

  return (
    <MainSection>
      <ButtonContainer>
        <ButtonContainerText>
          Where will your candidate work?
        </ButtonContainerText>
        <RadioButtonGroup
          onChange={addSelectedGeoToReducerState}
          valueSelected={selectedGeo}
        >
          <StyledRadioButton
            key={DOMESTIC_GEO}
            labelText='United States'
            value={DOMESTIC_GEO}
            selected={selectedGeo === DOMESTIC_GEO}
          />
          <StyledRadioButton
            key={INTERNATIONAL_GEO}
            labelText='Outside of the US'
            value={INTERNATIONAL_GEO}
            selected={selectedGeo === INTERNATIONAL_GEO}
            disabled={!canCreateInternationalPackage}
          />
        </RadioButtonGroup>
      </ButtonContainer>
    </MainSection>
  );
};

export default GetStarted;
