import styled from 'styled-components';

export const InternationalCreditBoldCaption = styled.span`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
`;

export const InternationalCreditGrayCaption = styled.span`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgba(26, 32, 38, 0.65);
`;

export const InternationalCreditReportRecordLine = styled.div`
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0.35rem !important;
`;

export const InternationalCreditReportRecordBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
`;
