import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  PARSHeader,
  PARSEducationInstructions,
  PARSBody,
} from '../../components/PARSProgram';

const PARSPage = () => {
  return (
    <StyledGrid>
      <PARSHeader />
      <PARSEducationInstructions />
      <PARSBody />
    </StyledGrid>
  );
};

export default PARSPage;

const StyledGrid = styled(M.Grid)`
  min-height: 80vh;
`;
