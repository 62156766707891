import { useLocation } from 'react-router-dom';
import { useReport } from 'containers/Report';
import { AssessmentVariant } from 'types/report/assessmentVariants';
import assessmentVariants from './__mocks__/assessmentVariants';

// [EADA-4970] Will add useQuery to this hook
// Until then we are mocking the data
// NOTE: this logic is gated by
// the assess_assessment_variants account setting
// which is not enabled for any prod accounts
export const useFetchAssessmentVariants = (): any => {
  const report = useReport();

  const enabled = report?.account?.assess_assessment_variants;

  const placeholderData = enabled ? assessmentVariants : undefined;

  return {
    isLoading: false,
    isError: false,
    data: placeholderData,
  };
};

export const useGetDisplayLabel = (): any => {
  const { search } = useLocation();
  const { data: assessmentVariants } = useFetchAssessmentVariants();

  if (!assessmentVariants) return null;
  const assessmentParam = new URLSearchParams(search).get('assessment');

  const currentAssessmentVariant = assessmentVariants.find(
    (assessment: AssessmentVariant) => assessment.id === assessmentParam,
  );
  return currentAssessmentVariant?.display_label;
};
