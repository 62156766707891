import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  AccountChoice,
  AccountChoiceContainer,
  AccountChoiceTitle,
} from './styles';
import { MultiAccount } from '../../../utils';

const avatarColors = [
  colors.uiGreen600,
  colors.brandPinkAccent2,
  colors.uiAqua600,
  colors.uiOrange600,
];

type AccountProps = {
  name: string;
  color: string;
  account_id: string;
  handleAccountSwitch: (account_id: any) => Promise<void>;
};

const Account: React.FC<AccountProps> = ({
  name,
  color,
  account_id,
  handleAccountSwitch,
}) => {
  const handleSelect = useCallback(async () => {
    await handleAccountSwitch(account_id);
  }, [handleAccountSwitch, account_id]);
  return (
    <AccountChoice onClick={handleSelect}>
      <M.Avatar name={name} label={name} customColor={color} size={2} />
      <p>{name}</p>
    </AccountChoice>
  );
};

const AccountChoices: React.FC<{
  accounts: MultiAccount[];
  continueUrl: string;
  state: string;
}> = ({ accounts, continueUrl, state }) => {
  const [selectEnabled, setSelectEnabled] = useState(true);
  let colorIndex = 0;
  const getColor = useCallback(() => {
    const color = avatarColors[colorIndex];

    if (colorIndex === avatarColors.length - 1) {
      colorIndex = 0;
    } else {
      colorIndex += 1;
    }
    return color;
  }, []);

  const handleSkipButton = useCallback(async () => {
    // Login the user without the account id param
    window.location.replace(`${continueUrl}?state=${state}`);
  }, [continueUrl, state]);

  const handleAccountSwitch = useCallback(
    async account_id => {
      if (selectEnabled) {
        setSelectEnabled(false);
        window.location.replace(
          `${continueUrl}?state=${state}&account_id=${account_id}`,
        );
      }
    },
    [selectEnabled, continueUrl, state],
  );

  return (
    <AccountChoiceContainer>
      <AccountChoiceTitle>
        <h3>Which account do you want to view?</h3>
        <M.Button onClick={handleSkipButton} kind='tertiary'>
          Skip for now
        </M.Button>
      </AccountChoiceTitle>
      <div className='account-cards'>
        {accounts.map(account => {
          return (
            <Account
              key={account.account_id}
              account_id={account.account_id}
              name={account.name}
              color={getColor()}
              handleAccountSwitch={handleAccountSwitch}
            />
          );
        })}
      </div>
    </AccountChoiceContainer>
  );
};

export default AccountChoices;
