import React, { useCallback } from 'react';
import { useUpdateCandidate } from 'api/candidate';

import { FieldComponentProps } from '../../types';
import { ActionableTextField } from './styles';

const CustomID: React.FC<FieldComponentProps> = ({
  fieldKey,
  allowCandidateEdit,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
  testid,
}) => {
  const { custom_id: customId, id: candidateId } = candidate;
  const updatedCustomId = candidateUpdates?.custom_id;
  // updatedCustomId may be empty string
  const displayCustomId =
    updatedCustomId === undefined ? customId : updatedCustomId;

  const successCallback = useCallback(
    (custom_id: string) =>
      setCandidateUpdates?.({ ...candidateUpdates, custom_id }),
    [setCandidateUpdates, candidateUpdates],
  );

  const { updateCandidateCall } = useUpdateCandidate(
    fieldKey,
    'custom ID',
    successCallback,
  );

  const tooltipLabel = !displayCustomId ? null : 'Copy';

  const onCustomIdEditSubmit = useCallback(
    (newCustomIdValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          custom_id: newCustomIdValue,
        });
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  return (
    <ActionableTextField
      data-testid={testid}
      allowEdit={allowCandidateEdit}
      initialDisplayValue={displayCustomId || '-'}
      initialValue={displayCustomId}
      submitCallback={onCustomIdEditSubmit}
      tooltipLabel={tooltipLabel}
    />
  );
};

export default CustomID;
