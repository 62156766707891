import React, { useCallback } from 'react';

import { M } from '@dashboard-experience/mastodon';
import { titleize } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import {
  TimelineFiltersContainer,
  TimelineFiltersList,
} from './TimelineStyledComponents';
import { TimelineFilterItems } from './types';

type TimelineFilterItemProp = {
  filterItems: TimelineFilterItems[];
  updateFilters: (
    filterItems: TimelineFilterItems[],
    itemIndex: number,
    value: {},
  ) => void;
  item: {
    name: string;
    show?: boolean;
  };
  itemIndex: number;
};

type TimelineFiltersProp = {
  filterItems: TimelineFilterItems[];
  updateFilters: (
    filterItems: TimelineFilterItems[],
    itemIndex: number,
    value: {},
  ) => void;
};

const TimelineFilterItem: React.FC<TimelineFilterItemProp> = ({
  filterItems,
  updateFilters,
  item,
  itemIndex,
}) => {
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleChange = useCallback(
    (event, { checked }) => {
      updateFilters(filterItems, itemIndex, checked);

      if (currentUser) {
        trackEvent(
          CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_REPORT_LOG_FILTER_USED,
          {
            'Filter Name': titleize(filterItems[itemIndex].name),
            'Filter Action': checked ? 'Check' : 'Uncheck',
            Report: reportProperties,
          },
        );
      }
    },
    [
      updateFilters,
      filterItems,
      itemIndex,
      currentUser,
      trackEvent,
      reportProperties,
    ],
  );

  return (
    <li>
      <M.Checkbox
        checked={item.show}
        labelText={item.name}
        title={item.name}
        id={`timelineFilter-${item.name}`}
        onChange={handleChange}
      />
    </li>
  );
};

const TimelineFilters: React.FC<TimelineFiltersProp> = ({
  filterItems,
  updateFilters,
}) => {
  return (
    <TimelineFiltersContainer>
      <strong>Filters:&nbsp;</strong>
      <TimelineFiltersList>
        {filterItems.map((item: { name: string }, itemIndex: number) => (
          <TimelineFilterItem
            key={item.name}
            item={item}
            itemIndex={itemIndex}
            updateFilters={updateFilters}
            filterItems={filterItems}
          />
        ))}
      </TimelineFiltersList>
      <hr />
    </TimelineFiltersContainer>
  );
};

export default TimelineFilters;
