import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
  OrderCompleteModal as AmplitudeOrderCompleteModal,
} from 'components/Packages/Amplitude/analytics';
import {
  CurrentUser,
  hasPermission,
  localStorageFallback,
} from '@dashboard-experience/utils';
import { useHistory } from 'react-router-dom';
import { I9_ORDERING, I9_ALLOWED_USER_ROLES } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useGetAccountSettings } from 'api/i9';
import { useDismissI9Upsell } from 'api/accounts';
import * as queryString from 'querystring';
import AliasSettingSelection from './AliasSettingSelection';
import { useOrderBackgroundCheckContext } from '../Context';

const ModalStyled = styled(M.ComposedModal)`
  .cds--modal-close {
    display: none;
  }
`;

const UpsellWrap = styled.div`
  border-top: 1px solid ${colors.borderPrimaryLight};
  margin-top: 1.5rem;
  padding-top: 1.5rem;
`;

const ContainerInner = styled.div`
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.25rem;
  box-shadow: 0rem 0.25rem 2rem -1rem ${colors.bgPrimaryDark}1a;
  align-items: center;
  margin: 1.5rem 0;
  padding: 1.5rem;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledButton = styled(M.Button)`
  margin: 0.75rem 0 0 1rem !important;
  padding: 0.5rem !important;
`;

type SubmitModalProps = {
  open: boolean;
  handleClose: () => void;
  currentUser: CurrentUser;
  bulkInviteEnabled?: boolean;
  manualBulkOrderEnabled?: boolean;
  redirectPage: Function;
};

const SubmitModal: React.FC<SubmitModalProps> = ({
  open,
  handleClose,
  currentUser,
  bulkInviteEnabled,
  manualBulkOrderEnabled,
  redirectPage,
}) => {
  const trackEvent = useTrackEvent();
  const history = useHistory();
  const hasI9Permissions = I9_ALLOWED_USER_ROLES.some(r =>
    currentUser?.roles?.includes(r),
  );

  const { call } = useDismissI9Upsell();
  const isI9UpsellFlagOn = useFlag(I9_ORDERING)?.variantKey === 'on';
  const { state } = useOrderBackgroundCheckContext();
  const showDefaultAliasSection =
    state?.aliasesEnabledChanged &&
    localStorageFallback.getItem('hideDefaultAliasModal') !== 'true';

  const aliasEnabled = state?.aliasesEnabled === AliasesEnabledType.ON;
  const { isLoading, isError } = useGetAccountSettings();
  const isI9SetupAvailable = !isLoading && isError;
  const accountId = currentUser?.account?.id;
  const [isHideI9UpsellChecked, setIsHideI9UpsellChecked] = useState(false);
  const isI9Dismissed = currentUser?.account?.dismiss_i9_upsell;
  const showI9UpsellSection =
    isI9UpsellFlagOn && hasI9Permissions && !isI9Dismissed;

  const trackModalClicks = useCallback(
    (selected: AmplitudeOrderCompleteModal) => {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.ORDER_SUBMIT_COMPLETED,
        {
          'Order Completion Type': selected,
        },
      );
    },
    [currentUser, trackEvent],
  );

  const handleOrderNew = useCallback(() => {
    trackModalClicks('Order New Check');
    handleClose();
    redirectPage('/order/get-started', true);
  }, [handleClose, redirectPage, trackModalClicks]);

  const handleGoToCandidates = useCallback(() => {
    trackModalClicks('Go to Candidate Page');
    handleClose();
    redirectPage('/candidates', true);
  }, [redirectPage, handleClose, trackModalClicks]);

  const handleViewBulkHistory = useCallback(() => {
    if (hasPermission(currentUser, 'read_account_invoices')) {
      redirectPage('/billing/bulk-history', true);
    } else {
      redirectPage('/bulk-order-history', true);
    }
    handleClose();
  }, [currentUser, handleClose, redirectPage]);

  const btnOrderNewCheck = {
    name: 'Order new check',
    onClick: handleOrderNew,
    'data-testid': 'order-new-check-button',
  };

  const btnGoToCandidates = {
    name: 'Go to Candidates page',
    onClick: handleGoToCandidates,
    'data-testid': 'candidate-page-button',
  };

  const btnViewBulkHistory = {
    name: 'View bulk order history',
    onClick: handleViewBulkHistory,
    'data-testid': 'view-documents-page-button',
  };

  const handleBackdropClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleI9Redirect = useCallback(() => {
    const queryParams = { source: 'order' };
    const queryStringified = queryString.stringify(queryParams);
    history.push({
      pathname: '/i-9',
      search: queryStringified,
    });
  }, [history]);

  const handleHideI9UpsellClick = useCallback(() => {
    const updatedI9UpsellState = !isHideI9UpsellChecked;

    setIsHideI9UpsellChecked(updatedI9UpsellState);

    call({ accountId: currentUser?.account?.id, dismissStatus: true });
  }, [accountId, isHideI9UpsellChecked]);

  return (
    <ModalStyled
      data-testid='submit-modal'
      open={open}
      onClose={handleBackdropClick}
    >
      <M.ModalHeader data-testid='submit-modal-header'>
        <h2>Your order was submitted</h2>
      </M.ModalHeader>

      <M.ModalBody data-testid='submit-modal-description'>
        {bulkInviteEnabled ? (
          <>
            <div>
              Your invitations are on the way! This process may take several
              minutes to complete depending on the total number of invitations.
              Once a check is started, you can view its progress in the{' '}
              <strong>Candidates</strong> page.
            </div>
            <div style={{ marginTop: '2rem' }}>
              Previous bulk orders can be viewed in{' '}
              <strong>Payment & billing</strong> {'>'}{' '}
              <strong>Bulk order history</strong>.
            </div>
          </>
        ) : (
          <>
            {manualBulkOrderEnabled ? (
              <>
                Your order is in progress! You can view order details and
                previous bulk orders in <strong>Bulk order history</strong>.
                After a check is started, you can view its progress in the{' '}
                <strong>Candidates page</strong>.
              </>
            ) : (
              <>
                Your candidate links are on the way! After a check starts, you
                can find its progress on the <strong>Candidates</strong> page.
              </>
            )}
          </>
        )}
        {isLoading && showI9UpsellSection && <M.LoadingInline />}
        {showDefaultAliasSection && (
          <AliasSettingSelection aliasEnabled={aliasEnabled} />
        )}
        {showI9UpsellSection &&
          isI9SetupAvailable &&
          !state?.aliasesEnabledChanged && (
            <UpsellWrap>
              <h6>What’s next?</h6>
              <p>
                Explore more ways that Checkr can help you onboard and maintain
                a great team.
              </p>
              <ContainerInner>
                <FlexWrap>
                  <div>
                    <h6>Form I-9</h6>
                    <p>
                      Easily complete and manage I-9 employment verification for
                      your staff in one secure dashboard.
                    </p>
                  </div>
                  <StyledButton kind='tertiary' onClick={handleI9Redirect}>
                    <M.Icon icon='ArrowRight' size={24} />
                  </StyledButton>
                </FlexWrap>
                <M.Checkbox
                  id='hideI9Upsell'
                  onChange={handleHideI9UpsellClick}
                  checked={isHideI9UpsellChecked}
                  labelText='Don’t show me this again'
                />
              </ContainerInner>
            </UpsellWrap>
          )}
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={
            bulkInviteEnabled || manualBulkOrderEnabled
              ? btnViewBulkHistory
              : btnOrderNewCheck
          }
          btnRight={btnGoToCandidates}
          size='small'
        />
      </M.ModalFooter>
    </ModalStyled>
  );
};

export default SubmitModal;
