import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  useGetRandomPollDetails,
  useSelectAlternates,
} from 'api/randomDrugTesting';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { filter } from 'lodash/fp';
import { IPollDetail, ISelectedCandidate } from './types';
import PollBody from './PollBody';
import { getDrugAlcoholCandidates } from './utils';
import { calculatePercentage } from '../RandomSelection/utils';
import AlternateSelectionModal from './AlternateSelectionModal';

interface Props {
  eligibleEmployees: number;
  poll: IPollDetail;
  initialOpenedState: boolean;
  onOrderReports: (candidates: ISelectedCandidate[]) => void;
}

const IconCompleted = styled(M.Icon)`
  fill: ${colors.successGreen100};
  margin-bottom: 0.1rem;
  margin-right: 0.4rem;
  vertical-align: middle;
`;

const Poll: FC<Props> = ({
  eligibleEmployees,
  poll,
  initialOpenedState,
  onOrderReports,
}) => {
  const [isAlternateSelectionModalOpen, setIsAlternateSelectionModalOpen] =
    useState<boolean>(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState<number | null>(
    null,
  );

  const [opened, setOpened] = useState<boolean>(initialOpenedState);
  const [groupedCandidates, setGroupedCandidates] = useState<
    ISelectedCandidate[] | null
  >(null);

  const {
    id,
    createdAt,
    alcoholSelectionCount,
    drugSelectionCount,
    eligibleEmployeeCount,
    completedDrugTests,
    completedAlcoholTests,
  } = poll;

  const { pollEligibleEmployees } =
    eligibleEmployeeCount === 0
      ? { pollEligibleEmployees: eligibleEmployees }
      : { pollEligibleEmployees: eligibleEmployeeCount };

  const { data, isLoading, isError, refetch } = useGetRandomPollDetails(
    id,
    opened,
  );
  const { randomlySelectedCandidates } = data || {};

  useEffect(() => {
    if (randomlySelectedCandidates && !groupedCandidates)
      setGroupedCandidates(
        getDrugAlcoholCandidates(randomlySelectedCandidates),
      );
  }, [groupedCandidates, randomlySelectedCandidates]);

  const expandClick = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useEffect(() => {
    if (isError && opened) setOpened(false);
  }, [isError, opened]);

  const closeAlternateSelectionModal = useCallback(
    () => setIsAlternateSelectionModalOpen(false),
    [],
  );

  const {
    call: selectAlternates,
    isLoading: isSubmitting,
    isError: isAlternateError,
  } = useSelectAlternates();

  const onSelectAlternates = useCallback(
    async (selectedReason: string) => {
      await selectAlternates({
        id,
        alternates: [
          {
            random_testing_poll_candidate_id: selectedCandidateId!,
            reason: selectedReason as string,
          },
        ],
      });

      if (!isAlternateError) {
        await refetch();
        setGroupedCandidates(null);
        closeAlternateSelectionModal();
      }
    },
    [
      closeAlternateSelectionModal,
      id,
      isAlternateError,
      refetch,
      selectAlternates,
      selectedCandidateId,
    ],
  );

  const discloseAlternateSelectionModal = useCallback((candidateId: string) => {
    setIsAlternateSelectionModalOpen(true);
  }, []);

  const checkAlternateAvailability = useCallback(
    () =>
      filter(candidate => !candidate.excused, randomlySelectedCandidates)
        .length >= 1,
    [randomlySelectedCandidates],
  );

  const isAlternateAvailable = useMemo(
    () => checkAlternateAvailability(),
    [checkAlternateAvailability],
  );

  const isDrugTestCompleted =
    !!drugSelectionCount && completedDrugTests === drugSelectionCount;
  const isAlcoholTestCompleted =
    !!alcoholSelectionCount && completedAlcoholTests === alcoholSelectionCount;

  return (
    <Container data-testid={`random-poll-details-${id}`}>
      <AlternateSelectionModal
        isOpen={isAlternateSelectionModalOpen}
        onSubmit={onSelectAlternates}
        onClose={closeAlternateSelectionModal}
        isLoading={isSubmitting}
      />{' '}
      <StyledRow
        data-testid={`poll-styledRow-${id}`}
        onClick={expandClick}
        role='button'
      >
        <M.GridCol lg={3}>
          <Label>{dateWithTimezone(createdAt)}</Label>
        </M.GridCol>
        <M.GridCol lg={4}>
          <Label>Pool details</Label>
          <Text>{`${pollEligibleEmployees} eligible employees`}</Text>
        </M.GridCol>
        {drugSelectionCount !== 0 && (
          <M.GridCol lg={4}>
            <Label>Drug test</Label>
            <Text>
              Testing rate: {drugSelectionCount}/{pollEligibleEmployees} (
              {calculatePercentage(drugSelectionCount, pollEligibleEmployees)}%)
            </Text>
            <Text>
              {isDrugTestCompleted && (
                <IconCompleted icon='CheckmarkFilled' size='12' />
              )}
              {completedDrugTests}/{drugSelectionCount} Completed
            </Text>
          </M.GridCol>
        )}
        {!drugSelectionCount && (
          <NonActiveGridCol lg={4}>
            <Label>Drug test</Label>
            <Text>-</Text>
          </NonActiveGridCol>
        )}
        {alcoholSelectionCount !== 0 && (
          <M.GridCol lg={4}>
            <Label>Alcohol test</Label>
            <Text>
              Testing rate: {alcoholSelectionCount}/{pollEligibleEmployees} (
              {calculatePercentage(
                alcoholSelectionCount,
                pollEligibleEmployees,
              )}
              %)
            </Text>
            <Text>
              {isAlcoholTestCompleted && (
                <IconCompleted icon='CheckmarkFilled' size='12' />
              )}
              {completedAlcoholTests}/{alcoholSelectionCount} Completed
            </Text>
          </M.GridCol>
        )}
        {!alcoholSelectionCount && (
          <NonActiveGridCol lg={4}>
            <Label>Alcohol test</Label>
            <Text>-</Text>
          </NonActiveGridCol>
        )}
        <IconColumn lg={1} style={{ margin: 'auto' }}>
          <IconWrapper data-testid='random-poll-open-close-icon'>
            <M.Icon
              icon={opened ? 'ChevronSortDown' : 'ChevronSortUp'}
              size='32'
            />
          </IconWrapper>
        </IconColumn>
      </StyledRow>
      {opened && (
        <PollBody
          id={id}
          isLoading={isLoading}
          onOrderReports={onOrderReports}
          discloseAlternateSelectionModal={discloseAlternateSelectionModal}
          setSelectedCandidateId={setSelectedCandidateId}
          isAlternateAvailable={isAlternateAvailable}
          setGroupedCandidates={setGroupedCandidates}
          groupedCandidates={groupedCandidates || []}
        />
      )}
    </Container>
  );
};

export default Poll;

const Container = styled.div`
  border: 1px solid #e1e6eb;
  width: 100%;
  margin: 1em 0;
  cursor: pointer;
`;

const StyledRow = styled(M.GridRow)`
  padding: 1em 0;
  &:hover {
    background-color: ${colors.uiHover};
  }
`;
const Label = styled.p`
  font-weight: 600;
  margin-bottom: 5px !important;
`;

const Text = styled.p`
  margin-bottom: 5px !important;
`;

const IconWrapper = styled.div`
  padding: 16px;
  cursor: pointer;
  margin-left: -46px;
`;

const IconColumn = styled(M.GridCol)`
  padding-right: 18px;
`;

const NonActiveGridCol = styled(M.GridCol)`
  p {
    color: ${colors.uiGrey500} !important;
  }
`;
