import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { usePostEmailUnresolvedExceptions } from 'api/candidate';
import { useReport } from 'containers/Report';
import Button from 'components/Button';
import { EXCEPTION_EVENT, useTrackEvent } from 'utils/analytics';

import Error from './error';
import { P } from './styles';

const StyledButton = styled(Button)`
  #mastodon && {
    display: block;
    margin-top: 1rem;
  }
`;

const label = `${namespace}:report.exceptions.banners.action.label`;

const Action = () => {
  const report = useReport();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const {
    emailUnresolvedExceptionsCall,
    emailUnresolvedExceptionsResult: { isLoading, isSuccess, isError, error },
  } = usePostEmailUnresolvedExceptions(report.id);

  const handleClick = useCallback(() => {
    emailUnresolvedExceptionsCall();
    trackEvent(EXCEPTION_EVENT.SEND_CANDIDATE_REQUEST);
  }, [emailUnresolvedExceptionsCall, trackEvent]);

  if (isLoading) {
    return (
      <M.LoadingInline
        style={{ marginTop: '1rem' }}
        description={t(`${namespace}:report.exceptions.banners.action.loading`)}
      />
    );
  }

  if (isSuccess) {
    return <P>{t(`${namespace}:report.exceptions.banners.action.success`)}</P>;
  }

  return (
    <>
      {!isError ? (
        <StyledButton onClick={handleClick} kind='secondary' trans={label} />
      ) : (
        <Error error={error} />
      )}
    </>
  );
};

export default Action;
