import { useEffect } from 'react';

const DASHBOARD_PREFERENCE_CHANGE_EVENT = 'dashboardPreferenceChange';
const DASHBOARD_NESTED_PREFERENCE_CHANGE_EVENT =
  'dashboardNestedPreferenceChange';

type DashboardPreferenceChange = {
  key: string;
  value: any;
};

type DashboardNestedPreferenceChange = {
  key: string;
  subKey: string;
  value: any;
};

declare global {
  interface GlobalEventHandlersEventMap {
    [DASHBOARD_PREFERENCE_CHANGE_EVENT]: CustomEvent<DashboardPreferenceChange>;
    [DASHBOARD_NESTED_PREFERENCE_CHANGE_EVENT]: CustomEvent<DashboardNestedPreferenceChange>;
  }
}

export const usePreferenceEventListener = (
  key: string,
  setPreference: Function,
) => {
  useEffect(() => {
    const handler = (event: CustomEvent<DashboardPreferenceChange>) => {
      if (event.detail.key === key) {
        setPreference(event.detail.value);
      }
    };
    window.addEventListener(DASHBOARD_PREFERENCE_CHANGE_EVENT, handler);
    return () => {
      window.removeEventListener(DASHBOARD_PREFERENCE_CHANGE_EVENT, handler);
    };
  }, [key, setPreference]);
};

export const useNestedPreferenceEventListener = (
  key: string,
  subKey: string,
  setPreference: Function,
) => {
  useEffect(() => {
    const handler = (event: CustomEvent<DashboardNestedPreferenceChange>) => {
      if (event.detail.key === key && event.detail.subKey === subKey) {
        setPreference(event.detail.value);
      }
    };
    window.addEventListener(DASHBOARD_NESTED_PREFERENCE_CHANGE_EVENT, handler);
    return () => {
      window.removeEventListener(
        DASHBOARD_NESTED_PREFERENCE_CHANGE_EVENT,
        handler,
      );
    };
  }, [key, setPreference, subKey]);
};

export const syncPreference = (key: string, value: any) => {
  const event = new CustomEvent<DashboardPreferenceChange>(
    DASHBOARD_PREFERENCE_CHANGE_EVENT,
    {
      detail: {
        key,
        value,
      },
    },
  );
  window.dispatchEvent(event);
};

export const syncNestedPreference = (
  key: string,
  subKey: string,
  value: any,
) => {
  const event = new CustomEvent<DashboardNestedPreferenceChange>(
    DASHBOARD_NESTED_PREFERENCE_CHANGE_EVENT,
    {
      detail: {
        key,
        subKey,
        value,
      },
    },
  );
  window.dispatchEvent(event);
};
