import React from 'react';
import CountyCivilSearch from './CivilRecordScreenings/CountyCivilSearch';
import FederalCivilSearch from './CivilRecordScreenings/FederalCivilSearch';
import {
  AccordionItem,
  AccordionItemProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import gavel from '../../shared/svg/gavel.svg';

const CivilRecordsSearchesItem = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
}: AccordionItemProps) => {
  return (
    <AccordionItem
      open={
        screeningTypesInSearch?.includes('county_civil_search') ||
        screeningTypesInSearch?.includes('federal_civil_search')
      }
      data-testid='civil-records-search-dropdown'
      title={
        <CategoryTitle data-testid='civil-records-search-title'>
          <Icon src={gavel} className='screening-icon' /> Civil searches
        </CategoryTitle>
      }
    >
      <ul>
        <CountyCivilSearch
          shouldOpen={screeningTypesInSearch?.includes('county_civil_search')}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
        />
        <FederalCivilSearch
          shouldOpen={screeningTypesInSearch?.includes('federal_civil_search')}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
        />
      </ul>
    </AccordionItem>
  );
};

export default CivilRecordsSearchesItem;
