/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PaymentMethod } from '@stripe/stripe-js';
import { STRIPE_KEY } from 'Constants';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { queryCache } from 'react-query';

import {
  FOX_SIGNUP_EVENT_NAMES,
  SIGNUP_EVENT_NAMES,
  useTrackEvent,
} from 'utils/analytics';
import { Modal, Footer } from './ui/Modal';
import {
  Container,
  Section,
  FlexColumn,
  FormSection,
  SectionDescription,
} from './ui/CreateAccount/PaymentDetailsSection/styles';
import useCreatePaymentIntent from './hooks/useCreatePaymentIntent';
import usePostPaymentMethod from './hooks/usePostPaymentMethod';
import { CreateAccountSection } from './ui/CreateAccount';
import Accordion from './ui/Accordion';
import Error from './ui/Error';
import {
  Container as ModalContainer,
  PageSubtitle,
  TitleContainerWithButton,
} from './styles';
import { storeUtmParamsInSessionStorage } from './utils/sessionStorage';
import useGetUser from './hooks/useGetUser';

type Props = {
  error: any;
  setError: (error: any) => void;
};

const PaymentForm = ({ error, setError }: Props) => {
  const { t } = useTranslation('signup');
  const modalRef = useRef(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { data } = useGetUser();
  const { mutate: postPaymentMethodCall } = usePostPaymentMethod(() => {
    setStripeLoading(false);
    history.push({
      pathname: '/signup/packages',
      search: location.search,
    });
  });

  const stripe = useStripe();
  const elements = useElements();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    const cardElement = elements?.getElement('payment');
    if (cardElement) {
      const handleChange = (event: any) => {
        if (event.complete) {
          setIsFormFilled(true);
          return;
        }
        setIsFormFilled(false);
      };

      cardElement.on('change', handleChange);

      return () => {
        cardElement.off('change', handleChange);
      };
    }
    return () => {};
  }, [elements]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setStripeLoading(true);

      trackEvent(
        SIGNUP_EVENT_NAMES.SIGNUP_PAYMENT_INFORMATION_NEXT_PAGE_CLICKED,
        {
          'Self Serve Signup Version': 'FOX Signup v2',
        },
      );

      if (!stripe || !elements) {
        setStripeLoading(false);
        return;
      }

      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            expand: ['payment_method'],
            payment_method_data: {
              billing_details: {
                name: data.full_name,
              },
            },
          },
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setError({
              error: result.error,
              source: 'stripe',
            });
            setStripeLoading(false);
            return;
          }
          const paymentMethod: string | PaymentMethod =
            result.setupIntent?.payment_method!;
          const stripeId: string =
            paymentMethod instanceof String
              ? (paymentMethod as string)
              : (paymentMethod as PaymentMethod).id;
          if (
            !(paymentMethod instanceof String) &&
            (paymentMethod as PaymentMethod).card &&
            (paymentMethod as PaymentMethod).card?.funding === 'prepaid'
          ) {
            setError({
              error: {},
              source: 'prepaid',
            });
            queryCache.invalidateQueries('create-payment-intent');
            setStripeLoading(false);
            return;
          }
          postPaymentMethodCall(
            { stripeId },
            {
              onSuccess: () => {
                trackEvent(
                  SIGNUP_EVENT_NAMES.SIGNUP_PAYMENT_INFORMATION_COMPLETED,
                  {
                    'Self Serve Signup Version': 'FOX Signup v2',
                  },
                );
              },
              onError: (error: unknown) => {
                queryCache.invalidateQueries('create-payment-intent');

                setError({ error, source: 'payment' });
                setStripeLoading(false);
              },
            },
          );
        })
        .catch(error => {
          queryCache.invalidateQueries('create-payment-intent');
          setError({ error, source: 'payment' });
          setStripeLoading(false);
        });
    },
    [
      trackEvent,
      stripe,
      elements,
      data.full_name,
      postPaymentMethodCall,
      setError,
    ],
  );

  return (
    <Modal
      hideHeader
      footer={
        <Footer
          primaryBtnText={t(`pages.paymentPage.submitButton`)}
          primaryButtonProps={{
            onClick: handleSubmit,
            disabled: stripeLoading || !isFormFilled,
          }}
        />
      }
    >
      <TitleContainerWithButton>
        <div style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
          <h3 data-testid='create-account-page-title'>
            {t(`components.Modal.paymentTitle`)}
          </h3>
          <PageSubtitle data-test-id='create-account-page-subtitle'>
            {t(`components.Modal.paymentSubtitle`)}
          </PageSubtitle>
        </div>
      </TitleContainerWithButton>
      <ModalContainer ref={modalRef}>
        <Error modalRef={modalRef} error={error} />
        <CreateAccountSection
          setPaymentDetailsOpen={() => {}}
          setError={setError}
        />
        <Accordion
          primaryText={t(`components.PaymentSection.paymentDetails`)}
          status='incomplete'
          disabled={false}
          open
          onHeadingClick={() =>
            trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_ACCORDION_EXPANDED, {
              Accordion: 'Payment Details',
            })
          }
        >
          <Container>
            <Section>
              <FlexColumn>
                <h5 className='mb-0'>{t(`components.PaymentSection.title`)}</h5>
                <SectionDescription>
                  {t(`components.PaymentSection.description`)}
                </SectionDescription>
              </FlexColumn>
              <FormSection>
                <PaymentElement
                  options={{
                    wallets: { applePay: 'never', googlePay: 'never' },
                  }}
                />
              </FormSection>
            </Section>
          </Container>
        </Accordion>
      </ModalContainer>
    </Modal>
  );
};

const PaymentPage = () => {
  const modalRef = React.useRef(null);
  const { t } = useTranslation('signup');
  const stripePromise = useMemo(() => loadStripe(STRIPE_KEY!), []);
  const trackEvent = useTrackEvent();
  const [error, setError] = useState<any>();

  const { data } = useCreatePaymentIntent({
    onError: error => setError({ error, source: 'payment-intent' }),
  });

  useEffect(() => {
    storeUtmParamsInSessionStorage();
  }, []);

  useEffect(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_RETURN_TO_PAYMENT, {
      'Test Variation': 'Treatment',
    });
  }, [trackEvent]);

  const handleSubmit = useCallback(() => {}, []);

  if (!data)
    return (
      <Modal
        hideHeader
        footer={
          <Footer
            primaryBtnText={t(`pages.paymentPage.submitButton`)}
            primaryButtonProps={{
              onClick: handleSubmit,
              disabled: true,
            }}
          />
        }
      >
        <TitleContainerWithButton>
          <div style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
            <h3 data-testid='create-account-page-title'>
              {t(`components.Modal.paymentTitle`)}
            </h3>
            <PageSubtitle data-test-id='create-account-page-subtitle'>
              {t(`components.Modal.paymentSubtitle`)}
            </PageSubtitle>
          </div>
        </TitleContainerWithButton>
        <ModalContainer ref={modalRef}>
          <Error modalRef={modalRef} error={error} />
          <CreateAccountSection
            setPaymentDetailsOpen={() => {}}
            setError={setError}
          />
          <Accordion
            primaryText={t(`components.PaymentSection.paymentDetails`)}
            secondaryText=''
            status='incomplete'
            disabled={!localStorage.getItem('accessToken')}
            open={!!localStorage.getItem('accessToken')}
            onHeadingClick={() => {}}
            tooltip={t(`components.PaymentSection.tooltip`)}
          >
            <Container>
              <Section>
                <FlexColumn>
                  <h5 className='mb-0'>
                    {t(`components.PaymentSection.title`)}
                  </h5>
                  <SectionDescription>
                    {t(`components.PaymentSection.description`)}
                  </SectionDescription>
                </FlexColumn>
                <FormSection />
              </Section>
            </Container>
          </Accordion>
        </ModalContainer>
      </Modal>
    );

  return (
    <Elements
      key={data.client_secret}
      stripe={stripePromise}
      options={{
        clientSecret: data.client_secret,
        appearance: {
          theme: 'stripe',
        },
        loader: 'always',
      }}
    >
      <PaymentForm error={error} setError={setError} />
    </Elements>
  );
};

export default PaymentPage;
