import React, { useCallback } from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { PROFESSIONAL_SCREENINGS } from 'components/Signup/constants/screenings';

import PackageCard from '../../PackageCard';
import ScreeningsListItem from '../ScreeningsListItem';

const ProfessionalPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
}) => {
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Professional',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_professional_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Professional'
      description='Additional verifications for advanced roles'
      price={7999}
      packageType='checkrdirect_professional_criminal'
      selected={selectedPackage === 'checkrdirect_professional_criminal'}
      onClick={handlePackageSelect}
    >
      <>
        {PROFESSIONAL_SCREENINGS.map(screeningName => (
          <ScreeningsListItem
            key={screeningName}
            screeningName={screeningName}
          />
        ))}
      </>
    </PackageCard>
  );
};

export default ProfessionalPackageCard;
