import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PackageHeader,
  MobilePackageHeader,
} from '../styles/PackageCardList.styles';
import { namespace } from '../../locales';

const PackageHeaderWithMobile = () => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;
  return (
    <>
      <PackageHeader>{t(`${basePath}.tableHeaders.packageName`)}</PackageHeader>
      <MobilePackageHeader>
        {t(`${basePath}.tableHeaders.package`)}
      </MobilePackageHeader>
    </>
  );
};

export default PackageHeaderWithMobile;
