import { M } from '@dashboard-experience/mastodon';
import { kebabToSnake } from '@dashboard-experience/utils';
import { callUserActions } from 'components/Report/userActions';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import React, { useCallback } from 'react';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { useFlag } from '@dashboard-experience/react-flagr';
import { handleAiClick } from '../../../../api/aiExplainr';
import { RecordComponentProps } from './types';

type Type = React.FC<RecordComponentProps>;

const Record: Type = ({
  report,
  record,
  currentUser,
  reportStatusType,
  index,
  status,
  federalType = '',
}) => {
  const handleClickWithExplainer = handleAiClick(
    record.state || '',
    record.county || '',
  );

  const formattedType = kebabToSnake(federalType);
  const { [`${formattedType}`]: screeningType, id } = report;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  const onChargeClick = useCallback(
    isExpanded => {
      const eventProperties = {
        event: 'clicked_into_charges',
        extra_info: record.charges,
      };
      // only call if expanding charges
      return isExpanded
        ? callUserActions(id, 'report', eventProperties)
        : undefined;
    },
    [id, record.charges],
  );
  return (
    <div data-testid={`${federalType}-record`}>
      <M.Record
        currentUser={currentUser}
        record={record}
        report={report}
        reportStatusType={reportStatusType}
        screeningStatus={status}
        onChargeClick={onChargeClick}
        chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
        chargesExplainer={
          aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
        }
      />
      {screeningType.records && index < screeningType.records.length - 1 && (
        <M.Divider key={`divider-${record.id}`} />
      )}
    </div>
  );
};

export default Record;
