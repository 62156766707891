import { ID } from 'modules/id';
import ContextDateOptions from 'modules/assess/models/context-date-option';
import { Initializer } from 'state/utilities/state';

export type Type = {
  id: ID;
  option?: ContextDateOptions.Type;
  position: number;
};

export const initialize: Initializer<Omit<Type, 'id'>> = (
  context_date: Partial<Type> = {},
) => ({
  position: 1,
  ...context_date,
});

export type List = Array<Type>;
export type Map = { [key: string]: List };
