import React from 'react';

type ProvideContactInfoProps = {
  smsInvite?: React.ReactNode | null;
  allowBulkInvite: boolean;
};

const ProvideContactInfoManuallySection: React.FC<ProvideContactInfoProps> = ({
  smsInvite,
  allowBulkInvite,
}) => {
  return (
    <>
      <h4
        className='section-title'
        data-testid='provide-contact-info-section-title'
      >
        How should we contact your candidate?
      </h4>
      <p>
        Adding a first name and phone number increases candidate response rates.
      </p>
      <p>We’ll send the candidate link after you place your order.</p>
      {smsInvite}
    </>
  );
};

export default ProvideContactInfoManuallySection;
