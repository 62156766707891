import React from 'react';
import styled from 'styled-components';
import Description from './Description';
import Guard from './Guard';
import Title from './Title';

const StyledContainer = styled.div`
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

export const Container: React.FC = () => {
  return (
    <Guard>
      <StyledContainer data-testid='assess-ruleset-version-context-dates-panel'>
        <Title />
        <Description />
      </StyledContainer>
    </Guard>
  );
};

export default Container;
