import React, {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  useGetReport,
  useReportPropertiesForAmplitudeEvents,
} from 'api/reports';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import CompleteModal from 'modules/adjudication/ui/adverse-action/complete-single/flow/modal/modal';
import Guard from 'modules/adjudication/ui/adverse-action/complete-single/button/guard';

const CancelScreenings = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const { t } = useTranslation();
  const text = t(`${namespace}:report.actions.cancel_screenings.action`);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement> | SyntheticEvent<Element>) => {
      e?.stopPropagation();
      setOpen(true);
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        {
          Action: text,
          Source: 'Adjudication Adverse Action',
          Report: reportProperties,
        },
      );
      // TODO: remove once customer is standalone app
      scrollToTop();
    },
    [reportProperties, text, trackEvent],
  );

  if (!report.completable) return null;

  return (
    <Guard>
      <M.Button
        kind='secondary-destructive'
        id='cancel-screenings-button'
        onClick={handleOpen}
      >
        {text}
      </M.Button>
      {open && <CompleteModal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default CancelScreenings;
