import { FetchPackageType } from '@dashboard-experience/utils';
import { Package } from '../../../types';

export type AliasTagProps = {
  basePackage: Package | FetchPackageType;
};

export enum AliasesEnabledType {
  ON = 'on',
  OFF = 'off',
}
