import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import type { SelectPackageT } from '..';

const ConditionalLoadingRender: React.FC<
  SelectPackageT.ConditionalLoadingProps
> = ({ shouldRender, children }) => {
  return shouldRender ? (
    <>{children}</>
  ) : (
    <M.LoadingInline description='Loading packages...' />
  );
};

export default ConditionalLoadingRender;
