import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  getIncludeDrivingHistorySetting,
  getScreeningSetting,
  getScreeningType,
  isInternationalMotorVehicleReport,
} from 'components/Packages/PackageScreenings';
import CIRCLE_CHECKMARK from 'components/Packages/CreatePackage/Context/CIRCLE_CHECKMARK';
import PackagePrice from './PackagePrice';
import { Package, Screening } from '../../../../types';
import { useOrderBackgroundCheckContext } from '../Context';
import AliasTag from '../../Alias/AliasTag';

const ClickableChoiceCard = styled(M.Button)`
  display: flex;
  align-items: start !important;
  max-width: 1000px;
  width: 744px !important;
  padding: 24px !important;
  margin-left: 0 !important;
  margin-bottom: 16px !important;
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  border: ${props =>
    props.checked
      ? `1px solid ${colors.uiNavy200} !important`
      : `1px solid ${colors.uiGrey200} !important`};
  border-radius: 2px !important;
  box-shadow: none !important;

  .mastodon-radio-button,
  .cds--radio-button__appearance {
    transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  #mastodon & {
    &.mastodon-button.primary:has(svg:only-child) {
      padding: 24px !important;
    }
  }

  :hover,
  :hover .mastodon-radio-button,
  :hover .cds--radio-button__appearance {
    background: ${colors.uiNavy100} !important;
  }
  :has(:active .cds--radio-button__appearance) {
    background: ${colors.uiNavy100} !important;
  }
  :hover {
    border: 1px solid ${colors.uiNavy200} !important;
  }

  &.cds--btn--disabled {
    background: ${colors.uiGrey100} !important;
    border-color: ${colors.borderPrimaryLight} !important;
    color: ${colors.uiTextDisabledLight} !important;

    &:active,
    .mastodon-radio-button,
    &:hover .mastodon-radio-button,
    &:hover .cds--radio-button__appearance,
    .cds--radio-button__appearance:before {
      background: ${colors.uiGrey100} !important;
    }

    h3,
    .screening-list li span,
    .screening-list li:before,
    .price * {
      color: ${colors.uiTextDisabledLight};
    }

    .cds--radio-button__label,
    .button-reset {
      cursor: not-allowed !important;
    }

    .cds--tooltip & {
      margin-bottom: 0 !important;
    }
  }

  @media all and (max-width: 940px) {
    width: 100% !important;
  }

  #mastodon & {
    &.mastodon-button.primary:active:enabled {
      background-color: ${colors.uiNavy100} !important;
      border-color: ${colors.uiNavy100} !important;
    }
  }
`;

export const RadioButton = styled(M.RadioButton)`
  margin-right: 0.75rem;
  .cds--radio-button__appearance {
    border: 1px solid ${colors.brandSlate5} !important;
  }
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  .cds--radio-button:focus
    + .cds--radio-button__label
    .cds--radio-button__appearance::after {
    box-shadow: unset !important;
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    margin-left: -15px;
    margin-top: -15px;
    content: url(${CIRCLE_CHECKMARK}) !important;
    background-color: ${props =>
      props.checked
        ? `${colors.uiNavy100} !important`
        : `${colors.uiGrey0} !important`};
    width: 100% !important;
    height: 100% !important;
    transform: scale(0.5) !important;
  }
`;

const PackageNameCell = styled.h5`
  display: flex;
  width: 276px;
  padding-right: 80px;
  font-weight: 700;
  color: ${colors.uiTextPrimaryLight};

  @media all and (max-width: 630px) {
    width: 100%;
  }
`;

export const ScreeningListItem = styled.li`
  display: flex;
  &:before {
    content: '✓' !important;
    color: ${colors.brandAqua3};
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.uiTextPrimaryLight};
`;

export const ScreeningUl = styled.ul`
  list-style: none !important;
  padding-left: 5px;

  @media all and (max-width: 630px) {
    padding-left: 12px;
  }
`;

export const ScreeningType = styled.span`
  padding-left: 5px;
  display: inline-flex;
  flex-wrap: wrap;
`;

export const ScreeningSettings = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

const ScreeningListContainer = styled.div`
  width: 276px;
  ul {
    list-style-type: square;
  }
  ul > li {
    color: ${colors.brandGreen4};
  }

  ul > li > span {
    color: ${colors.uiTextPrimaryLight};
  }

  @media all and (max-width: 630px) {
    width: 100%;

    ul {
      margin-top: 4px;
    }
  }
`;

const PriceCell = styled.div`
  width: 144px;
  padding-left: 40px;

  @media all and (max-width: 540px) {
    display: none;
  }
`;

const PackageNameText = styled.div`
  width: 200px;
  overflow-wrap: break-word;
`;

const PriceCellMobile = styled.div`
  display: none;
  padding-left: 30px;
  padding-bottom: 10px;
  width: 100%;

  @media all and (max-width: 540px) {
    display: flex;
  }
`;

const Flex = styled.div`
  display: flex;

  @media all and (max-width: 900px) {
    flex-flow: column;
  }
`;

export type Props = {
  basePackage: Package;
  idx: number;
  showPackagePrice: boolean;
  isDisabled: boolean;
  basePackageScreenings: Screening[] | undefined;
  handleSelectPackageWrap: (basePackage: Package) => void;
};

const PackageCard: React.FC<Props> = ({
  basePackage,
  idx,
  showPackagePrice,
  isDisabled,
  basePackageScreenings,
  handleSelectPackageWrap,
}) => {
  const { state } = useOrderBackgroundCheckContext();

  const handleSelect = useCallback(
    e => {
      e.preventDefault();
      handleSelectPackageWrap(basePackage);
    },
    [handleSelectPackageWrap, basePackage],
  );

  return (
    <ClickableChoiceCard
      key={basePackage.id}
      data-testid={`choice-card-${idx + 1}`}
      onClick={handleSelect}
      checked={state?.basePackage?.slug === basePackage.slug}
      disabled={isDisabled}
    >
      <Flex>
        <PackageNameCell data-testid={`package-name-${idx + 1}`}>
          <RadioButton
            data-testid={`package-button-${idx + 1}`}
            checked={state?.basePackage?.slug === basePackage.slug}
            onClick={handleSelect}
            disabled={isDisabled}
          />
          <PackageNameText>{basePackage.name}</PackageNameText>
        </PackageNameCell>
        {showPackagePrice && (
          <PriceCellMobile
            className='price'
            data-testid={`price-cell-mobile-${idx + 1}`}
          >
            <PackagePrice basePackage={basePackage} />
          </PriceCellMobile>
        )}
        <ScreeningListContainer>
          <ScreeningUl
            className='screening-list'
            data-testid={`screening-ul-${idx + 1}`}
          >
            {basePackageScreenings?.map((screening: Screening) => (
              <ScreeningListItem key={`${screening.type}`}>
                <ScreeningType data-testid={`screening-type-${screening.type}`}>
                  {basePackage.international_only &&
                  screening.type === 'international_criminal_search_v2'
                    ? getScreeningType(
                        'international_criminal_or_adverse_media',
                      )
                    : getScreeningType(screening.type)}{' '}
                  <ScreeningSettings>
                    {`${getScreeningSetting(
                      screening,
                      basePackage?.screening_settings,
                    )}`}
                  </ScreeningSettings>
                  {isInternationalMotorVehicleReport(screening.type) && (
                    <ScreeningSettings>
                      {`${getIncludeDrivingHistorySetting(screening)}`}
                    </ScreeningSettings>
                  )}
                </ScreeningType>
              </ScreeningListItem>
            ))}
            <AliasTag basePackage={basePackage} />
          </ScreeningUl>
        </ScreeningListContainer>
      </Flex>

      {showPackagePrice && (
        <PriceCell className='price' data-testid={`price-cell-${idx + 1}`}>
          <PackagePrice basePackage={basePackage} />
        </PriceCell>
      )}
    </ClickableChoiceCard>
  );
};

export default PackageCard;
