import React from 'react';
import styled from 'styled-components';
import BreadcrumbItem from './BreadcrumbItem';

export const GET_STARTED_KEY = 1 as const;
export const SELECT_YOUR_PACKAGE_KEY = 2 as const;
export const CUSTOMIZE_WITH_ADDONS_KEY = 3 as const;
export const REVIEW_AND_SUBMIT_KEY = 4 as const;
export const MANUAL_ENTRY_KEY = 5 as const;

export type Steps =
  | typeof GET_STARTED_KEY
  | typeof SELECT_YOUR_PACKAGE_KEY
  | typeof CUSTOMIZE_WITH_ADDONS_KEY
  | typeof REVIEW_AND_SUBMIT_KEY
  | typeof MANUAL_ENTRY_KEY;

export const BREADCRUMB_STEPS: Record<string, string> = {
  GET_STARTED: '1. Get started',
  SELECT_YOUR_PACKAGE: '2. Select your package',
  CUSTOMIZE_WITH_ADDONS: '3. Customize',
  REVIEW_AND_SUBMIT: '4. Review & submit order',
  REVIEW_AND_SUBMIT_ADDONS_DISABLED: '3. Review & submit order',
  MANUAL_ENTRY: '5. Manual entry',
  UPLOAD_CANDIDATE_INFO: '3. Upload candidate info',
};

export const STEP_NUMBER: Record<Steps, keyof typeof BREADCRUMB_STEPS> = {
  [GET_STARTED_KEY]: BREADCRUMB_STEPS.GET_STARTED,
  [SELECT_YOUR_PACKAGE_KEY]: BREADCRUMB_STEPS.SELECT_YOUR_PACKAGE,
  [CUSTOMIZE_WITH_ADDONS_KEY]: BREADCRUMB_STEPS.CUSTOMIZE_WITH_ADDONS,
  [REVIEW_AND_SUBMIT_KEY]: BREADCRUMB_STEPS.REVIEW_AND_SUBMIT,
  [MANUAL_ENTRY_KEY]: BREADCRUMB_STEPS.MANUAL_ENTRY,
};

export const STEP_PATH: Record<Steps, string> = {
  [GET_STARTED_KEY]: '/order/get-started',
  [SELECT_YOUR_PACKAGE_KEY]: '/order/select-your-package',
  [CUSTOMIZE_WITH_ADDONS_KEY]: '/order/customize-with-addons',
  [REVIEW_AND_SUBMIT_KEY]: '/order/review-and-submit',
  [MANUAL_ENTRY_KEY]: '/order/manual-entry',
};

export const getNextStep = (step: Steps): Steps => {
  if (step === MANUAL_ENTRY_KEY) {
    return REVIEW_AND_SUBMIT_KEY;
  }
  return (step + 1) as Steps;
};

const OrderedList = styled.ol`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 900px;
`;

type BreadcrumbProps = {
  currentStepNumber: number;
  currentPage: string;
  addOnsEnabled: boolean;
  manualBulkOrderEnabled: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  currentPage,
  currentStepNumber,
  addOnsEnabled,
  manualBulkOrderEnabled,
}) => {
  return (
    <OrderedList>
      <BreadcrumbItem
        data-testid='bread-crumb-get-started'
        step={BREADCRUMB_STEPS.GET_STARTED}
        stepNumber={GET_STARTED_KEY}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
      <BreadcrumbItem
        data-testid='bread-crumb-select-your-package'
        step={BREADCRUMB_STEPS.SELECT_YOUR_PACKAGE}
        stepNumber={SELECT_YOUR_PACKAGE_KEY}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
      {manualBulkOrderEnabled ? (
        <BreadcrumbItem
          data-testid='bread-crumb-upload-candidate-info'
          step={BREADCRUMB_STEPS.UPLOAD_CANDIDATE_INFO}
          stepNumber={3}
          currentStepNumber={currentStepNumber}
          currentPage={currentPage}
        />
      ) : (
        addOnsEnabled && (
          <BreadcrumbItem
            data-testid='bread-crumb-customize-with-addons'
            step={BREADCRUMB_STEPS.CUSTOMIZE_WITH_ADDONS}
            stepNumber={3}
            currentStepNumber={currentStepNumber}
            currentPage={currentPage}
          />
        )
      )}
      <BreadcrumbItem
        data-testid='bread-crumb-review-and-submit'
        step={
          addOnsEnabled || manualBulkOrderEnabled
            ? BREADCRUMB_STEPS.REVIEW_AND_SUBMIT
            : BREADCRUMB_STEPS.REVIEW_AND_SUBMIT_ADDONS_DISABLED
        }
        stepNumber={4}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
    </OrderedList>
  );
};

export default Breadcrumbs;
