import React, {
  useState,
  useCallback,
  MouseEvent,
  SyntheticEvent,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';

import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import Guard from './guard';
import Modal from '../flow/modal';

const ChangeButton = () => {
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const text = t(`${namespace}:report.actions.change.action`);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement> | SyntheticEvent<Element>) => {
      e?.stopPropagation();
      setOpen(true);
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        { Action: text, Report: reportProperties },
      );
      // TODO: remove once customer is standalone app
      scrollToTop();
    },
    [setOpen, text, trackEvent],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Guard>
      <M.Button
        data-testid='change-decision-button'
        kind='secondary-destructive'
        onClick={handleOpen}
      >
        {text}
      </M.Button>
      {open && <Modal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default ChangeButton;
