import React, { memo } from 'react';
import styled from 'styled-components';
import LifeCycleActions from './lifecycle-actions';
import DownloadActions from './download-actions';
import SecondaryActions from './secondary-actions';

const Container = styled.div`
  display: flex;
  gap: 12px;
`;

const ReportActionsV2: React.FC = () => {
  return (
    <Container data-testid='candidate-header-report-actions-v2'>
      <LifeCycleActions />
      <DownloadActions />
      <SecondaryActions />
    </Container>
  );
};

export default memo(ReportActionsV2);
