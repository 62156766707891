import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useInvitation } from 'modules/candidate/utilities';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NonActionableBanner from './non-actionable-banner';

const InvitationExpiredBanner = () => {
  const { t } = useTranslation();

  const candidate = useCandidate();
  const invitation = useInvitation();

  // Only show this banner if the invitation is actually expired
  if (
    !invitation?.expires_at ||
    moment(invitation?.expires_at).isAfter(moment.now())
  ) {
    return null;
  }

  const d = new Date(invitation.created_at);
  const displayDate = d.toLocaleString('en-us', {
    // @ts-ignore
    dateStyle: 'medium',
  });

  // Show generic text if we don't have the candidate's name; we GENERALLY won't have a name, unless they already have an actual Report with us.
  const displayName = candidate.first_name || 'The candidate';

  return (
    <NonActionableBanner
      kind='info'
      title={t('banners.invitationExpired.text', { displayName, displayDate })}
    />
  );
};

export default InvitationExpiredBanner;
