import { Client as client } from 'api';
import { PostalAddress, CompanyAddress } from 'types';
import decamelize from 'decamelize';
import _ from 'lodash';

export type ParamsType = {
  accountId: string;
  adverseActionEmail: string;
  billingEmail: string;
  company: CompanyAddress;
  complianceContactEmail: string;
  logoUri: string;
  name: string;
  postalAddress: PostalAddress;
  suppressEmailInvites: boolean;
  suppressSmsNotifications: boolean;
  supportEmail: string;
  supportPhone: string;
  technicalContactEmail: string;
  uriName: string;
  modalOpen: boolean;
  disableSettings: boolean;
};
export type GenericObject = { [key: string]: any };

/**
 * @description Submits changes to any Account settings
 * @param changedSettings - Subset of settings, anything that has been modified and needs to be submitted to the server
 * @param account - Standard account object, needed for the ID to submit to
 * @param settings - OPTIONAL, a base set of any other settings you wish to also submit to the server
 * @returns {promise}
 */
export const updateAccount = (
  changedSettings: GenericObject,
  account: GenericObject,
  settings: GenericObject = {},
): Promise<GenericObject> => {
  // Go through all of the ChangedSettings, and safely add them to the main Settings object
  Object.keys(changedSettings).forEach((k: string) => {
    if (k !== 'accountId' && k !== 'modalOpen') {
      const value: any = changedSettings[k];

      switch (k) {
        case 'logoUri': // Never submit an empty logo URI to the server
          if (value == null) break;
          settings[decamelize(k)] = value;
          break;
        case 'suppressEmailInvites':
          if (value == null) break;
          settings.suppress_email_invitation = value;
          break;
        case 'suppressSmsNotifications':
          if (value == null) break;
          settings.suppress_sms_notification = value;
          break;
        // TODO Remove after delivery the user preference improvements
        // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
        case 'disableAssess':
          if (value == null) break;
          settings.assess_enabled = false;
          break;
        case 'enableManualOrdering':
          if (value == null) break;
          settings.manual_orders_enabled = value;
          break;
        case 'company':
          if (value == null) break;
          settings.company = value;
          break;
        case 'aliasAutoEnable':
          if (value == null) break;
          settings.alias_auto_enable = value;
          break;
        case 'postalAddress':
          if (_.every(value, v => v === '')) break;
          settings.postal_address = value;
          break;
        default:
          // Ensure that anything added is snake_cased before submitting
          settings[decamelize(k)] = value;
          break;
      }
    }
  });

  return client.put(`/accounts/${account.id}`, settings);
};

export const deleteAccount = (accountId: string): Promise<string> => {
  const url = `/accounts/${accountId}`;
  return client.delete(url);
};
