import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Option from 'modules/assess/models/context-date-option';
import { useNamespace } from 'modules/assess/ui/router/context';

type Props = {
  option?: Option.Type;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation();
  const namespace = useNamespace();
  const basePath = `assess:context_dates.${namespace}.options.${option}`;
  const { name, description } = t(basePath, { returnObjects: true }) as {
    name: string;
    description: string;
  };

  return (
    <Container>
      <strong>{name}</strong>
      {description}
    </Container>
  );
};

export default Description;
