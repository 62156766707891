import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledAccordion = styled(M.Accordion)`
  .cds--accordion__item {
    border: 1px solid ${colors.uiGrey200};
    border-radius: 6px;
    box-shadow: 0 4px 32px -16px rgba(26, 32, 38, 0.1);
  }

  .cds--accordion__heading {
    padding-right: 24px !important;

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: unset;
      &:disabled {
        .custom-tooltip {
          display: block;
        }
      }
    }
  }

  .cds--accordion__item {
    .cds--accordion__wrapper {
      padding: 0 24px;
    }
  }

  .cds--accordion__title {
    padding-left: 0 !important;
  }

  @media (min-width: 640px) {
    .cds--accordion__content {
      padding-right: 16px !important;
    }
  }
`;

export const AccordionItem = styled(M.AccordionItem)`
  .cds--accordion__content {
    padding: 0 0 24px !important;
  }
`;

export const Container = styled.div.attrs({
  className: 'accordion-title-container',
})`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px;
  position: relative;
`;

export const SecondaryText = styled.div.attrs({
  className: 'accordion-secondary-text',
})`
  margin-left: auto;
  color: ${colors.uiTextSecondaryLight};
  text-align: right;
`;

export const Icon = styled(M.Icon)<{ fill?: string }>`
  fill: ${props => props.fill};
`;

export const CustomTooltip = styled.div.attrs({
  className: 'custom-tooltip',
})`
  background: ${colors.uiTextSecondaryLight};
  border-radius: 0.25rem;
  box-shadow: 0 0.375rem 1.75rem -0.375rem #0e151c29;
  color: ${colors.uiTextPrimaryDark};
  display: none;
  font-size: 0.875rem;
  left: 50%;
  line-height: 1.31rem;
  gap: 0.5rem;
  min-height: 1.75rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -0.9rem;
  transform: translate(-50%, 0);

  @media only screen and (max-width: 767px) {
    top: -1.6rem;
    width: 17.2rem;
  }
`;
