import { M } from '@dashboard-experience/mastodon';
import { useGetReport } from 'api/reports';
import { t } from 'i18next';
import { useReportTasksAction } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import { useDisplayRequireAction } from 'modules/adjudication/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReportTasks from '../../report-tasks/constants';

const StyledToggle = styled(M.Toggle)`
  margin-top: 0.1rem;
  .cds--toggle__appearance {
    column-gap: 6px;
  }
`;

const Label = styled.p`
  font-size: 14px !important;
  font-weight: 600;
`;

const ToggleContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const NeedsFollowUp: React.FC = () => {
  const { report } = useGetReport();
  const { report_tasks = [] } = report;
  const { ActionRequired } = ReportTasks;

  const task = report_tasks.find(({ kind }: { kind?: string }) => {
    return kind === ActionRequired;
  });

  const [actionRequiredTask, setActionRequiredTask] = useState(task);

  useEffect(() => {
    if (task) {
      setActionRequiredTask(task);
    }
  }, [ActionRequired, report_tasks, task]);

  const { call } = useReportTasksAction(
    report.id,
    setActionRequiredTask,
    actionRequiredTask,
  );

  const handleUpdate = useCallback(() => {
    call();
  }, [call]);

  const toggled = !!actionRequiredTask?.value;
  const needsFollowUpTranslation = `${namespace}:report.actions.needs_follow_up`;

  const displayRequireAction = useDisplayRequireAction();

  if (!displayRequireAction || report.archived) {
    return null;
  }

  return (
    <ToggleContainer data-testid='action-required-report-task'>
      <Label>{t(`${needsFollowUpTranslation}.label`)}</Label>
      <StyledToggle
        id='action-required-toggle-id'
        data-testid='action-required-toggle'
        toggled={toggled}
        onToggle={handleUpdate}
        size='sm'
        hideLabel
      />
    </ToggleContainer>
  );
};

export default NeedsFollowUp;
