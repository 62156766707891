import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import {
  BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY,
  CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE,
  bulkOrderFilestackKey as filestackKey,
} from 'Constants';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { hasPermission } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { parse } from 'papaparse';
import { getDashboardUrl } from 'utils';
import SecureFilestack from 'components/SecureFilestack/secureFilestack';
import useFilestackUploadResponse from 'hooks/useFilestackUploadResponse';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions/ToastActions';
import { namespace } from '../../locales';
import {
  StyledDeleteFileButton,
  StyledFindARecord,
  StyledNeedHelp,
  StyledTemplateInstructions,
  StyledUploadedFile,
} from './CandidateContactInfoSection.styles';

const fileStackOptions = {
  accept: ['text/csv'],
  storeTo: {
    location: 's3',
    path: `/bulk_order/`,
  },
  maxFiles: 1,
  uploadInBackground: false,
  fromSources: ['local_file_system'],
  maxSize: 1000000,
  customText: {
    Files: 'File',
    'Select Files to Upload': 'Select file to upload',
    'Selected Files': 'Selected file',
    'Deselect All': 'Deselect file',
    'or Drag and Drop, Copy and Paste Files':
      'or drag and drop, copy and paste file ',
  },
};

type CandidateBulkUploadType = {
  uploadedCsv: GetStartedT.BulkInviteCSV;
  setUploadedCsv: Dispatch<SetStateAction<GetStartedT.BulkInviteCSV>>;
  setParsedEmails: Dispatch<SetStateAction<GetStartedT.Email[]>>;
};

const CandidateBulkUpload = ({
  uploadedCsv,
  setUploadedCsv,
  setParsedEmails,
}: CandidateBulkUploadType) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const dispatchToast = useDispatch();
  const bulkInviteFlagr: number = Number(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const bulkInviteFlagrEnabled: boolean = !isNaN(bulkInviteFlagr);
  const basePath = `${namespace}:candidateContactInfo.upload`;

  const csvTemplate = CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE;

  const handleDownloadCSVTemplate = useCallback(() => {
    if (bulkInviteFlagrEnabled) {
      // TODO: tracking event for download CSV here
    }
  }, [bulkInviteFlagrEnabled]);

  const handleRedirectToBulkOrderHistory = useCallback(
    e => {
      if (hasPermission(currentUser, 'read_account_invoices')) {
        if (bulkInviteFlagrEnabled) {
          // TODO: tracking event here
        }
      }
    },
    [bulkInviteFlagrEnabled],
  );

  const bulkOrderHistoryUrl =
    hasPermission(currentUser, 'read_account_invoices') &&
    bulkInviteFlagrEnabled
      ? getDashboardUrl(`/billing/bulk-history`)
      : getDashboardUrl(`/bulk-order-history`);

  const fileStackCustomRender = useCallback(
    ({ onPick }) => (
      <M.Button
        kind='secondary'
        disabled={uploadedCsv.fileDisplayName}
        data-testid='csv-upload-button'
        size='sm'
        onClick={onPick}
      >
        {t(`${basePath}.uploadButton`)}
      </M.Button>
    ),
    [uploadedCsv],
  );

  const handleFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatchToast(toastError('Failed to upload file'));
    },
    successCallback: file => {
      const csv: GetStartedT.BulkInviteCSV = {
        fileStorageKey: file.key,
        fileDisplayName: file.filename,
        fileS3URL: file.url,
      };

      setUploadedCsv(csv);

      if (csv.fileStorageKey && bulkInviteFlagrEnabled) {
        // TODO: tracking event here
      }

      parse(file.url, {
        download: true,
        header: true,
        skipEmptyLines: 'greedy',
        complete: result => {
          const data = result?.data as GetStartedT.BulkInviteUploadRow[];
          const newParsedEmails = data.map(row => Object.entries(row)[0][1]);
          setParsedEmails(newParsedEmails);
        },
      });
    },
  });

  const handleFileDelete = useCallback(() => {
    if (bulkInviteFlagrEnabled) {
      // TODO: tracking event here
    }

    setUploadedCsv({} as GetStartedT.BulkInviteCSV);
    setParsedEmails([]);
  }, [bulkInviteFlagrEnabled]);

  return (
    <>
      <StyledNeedHelp className='p2'>
        {t(`${basePath}.needHelp`)}{' '}
        <M.Link
          size='lg'
          data-testid='download-csv-template'
          download
          onClick={handleDownloadCSVTemplate}
          href={csvTemplate}
        >
          {t(`${basePath}.downloadTemplate`)}
        </M.Link>
      </StyledNeedHelp>

      <h5>{t(`${basePath}.templateInstructions.header`)}</h5>
      <ul>
        <StyledTemplateInstructions>
          &bull; {t(`${basePath}.templateInstructions.stepOne`)}
        </StyledTemplateInstructions>
        <StyledTemplateInstructions>
          &bull; {t(`${basePath}.templateInstructions.stepTwo`)}
        </StyledTemplateInstructions>
      </ul>

      <StyledFindARecord className='p2'>
        {t(`${basePath}.findRecordInstructions.findARecord`)}{' '}
        <span className='bold'>
          {t(`${basePath}.findRecordInstructions.paymentAndBilling`)}
        </span>{' '}
        &gt;{' '}
        <M.Link
          className='bold'
          size='lg'
          data-testid='redirect-to-bulk-history'
          href={bulkOrderHistoryUrl}
          onClick={handleRedirectToBulkOrderHistory}
        >
          {t(`${basePath}.findRecordInstructions.bulkOrderHistory`)}
        </M.Link>
      </StyledFindARecord>

      <SecureFilestack
        actionOptions={fileStackOptions}
        apikey={filestackKey}
        customRender={fileStackCustomRender}
        onSuccess={handleFileUpload}
      />
      {uploadedCsv.fileDisplayName && (
        <StyledUploadedFile>
          <M.Icon icon='CheckmarkFilled' size={16} />
          <p className='filename' data-testid='uploaded-file-name'>
            {uploadedCsv.fileDisplayName}
          </p>
          <StyledDeleteFileButton
            data-testid='delete-uploaded-csv'
            onClick={handleFileDelete}
          >
            {t(`${basePath}.deleteButton`)}
          </StyledDeleteFileButton>
        </StyledUploadedFile>
      )}
    </>
  );
};

export default CandidateBulkUpload;
