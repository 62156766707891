import React, { useCallback, useContext, useEffect } from 'react';
import { useReport } from 'containers/Report';
import {
  useCandidate,
  useComponents,
  useReportTabs,
} from 'providers/Candidate';
import UIContext from 'context/UI';
import { useNotifier } from 'hooks';
import { error } from 'state/notifications/entity';
import { Redirect } from 'react-router-dom';
import { colors } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';

import {
  ReportContent as ReportContentMilestoneOne,
  useCanViewCaseManagement,
} from 'modules/report';

import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import ReportContent from './ReportContent';

const customerViewProps = {
  style: {
    fontWeight: 'bold',
    marginBottom: 10,
    padding: 10,
    background: colors.uiGrey100,
    color: colors.uiTextPrimaryLight,
    border: `1px solid ${colors.uiGrey300}`,
    borderRadius: 4,
    maxWidth: '99rem',
    margin: 'auto',
  },
};

const Report = () => {
  const report = useReport();
  const currentUser = useUser();
  const candidate = useCandidate();
  const components = useComponents();
  const { isIframe, isStandaloneIframe } = useContext(UIContext);
  const notifier = useNotifier();
  const showInternalNotes = useCanViewCaseManagement(report);
  const { customerView, setCustomerView } = useReportTabs();

  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
  const toggleCustomerView = useCallback(() => {
    setCustomerView(!customerView);
  }, [setCustomerView, customerView]);

  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  useEffect(() => {
    if (
      !isIframe ||
      isStandaloneIframe ||
      components.includes('report_information')
    ) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_VIEWED, {
        Report: reportProperties,
      });
    }
  }, [components, isIframe, reportProperties, isStandaloneIframe, trackEvent]);

  const screeningsAndTabsBlock = components.includes('screenings_and_tabs');
  const showCustomerViewToggle =
    hasPermission(currentUser, 'read_full_reports') && // only internal users see the toggle
    (screeningsAndTabsBlock || !isIframe || isStandaloneIframe); // only show the toggle when included, or not in iframe

  // If report doesn't exist on candidate, redirect
  if (report.candidate?.id !== candidate.id) {
    notifier(
      error({
        title: `Report ${report.id} does not exist for Candidate ${candidate.id}`,
      }),
    );
    const { hash, search } = window.location;
    const lastReportId = candidate?.last_report_id;
    const path = lastReportId
      ? `/candidates/${candidate.id}/reports/${lastReportId}`
      : `/candidates/${candidate.id}`;
    return <Redirect to={`${path}${search}${hash}`} />;
  }

  const showCustomerViewComponents = // Components are conditionally hidden for internal users:
    customerView || // Internal users see the toggle, so only show the components when the toggle is enabled
    !showCustomerViewToggle; // External users do not see the toggle and should show all components.

  return (
    <>
      {candidateRedesignEnabled ? (
        <ReportContentMilestoneOne
          showCustomerViewToggle={showCustomerViewToggle}
          customerView={customerView}
          customerViewProps={customerViewProps}
          toggleCustomerView={toggleCustomerView}
          showInternalNotes={showInternalNotes}
          showCustomerViewComponents={showCustomerViewComponents}
        />
      ) : (
        <ReportContent
          showCustomerViewToggle={showCustomerViewToggle}
          customerView={customerView}
          customerViewProps={customerViewProps}
          toggleCustomerView={toggleCustomerView}
          showInternalNotes={showInternalNotes}
          showCustomerViewComponents={showCustomerViewComponents}
        />
      )}
    </>
  );
};

export default Report;
