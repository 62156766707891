import { namespace } from 'modules/candidate/locales';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExceptionWithScreeningName,
  getExceptionDocumentTypes,
} from '../../utils';

const SingleExceptionMessageBody = ({
  exception,
  firstName,
  totalCanceledScreenings,
  screeningStatus,
}: {
  exception: ExceptionWithScreeningName;
  firstName: string | null | undefined;
  totalCanceledScreenings: number;
  screeningStatus: string;
}) => {
  const { t } = useTranslation();
  const documentTypes = getExceptionDocumentTypes(exception);
  const { screeningName } = exception;

  let message = '';
  if (
    (screeningName === 'Ssn Trace' && totalCanceledScreenings > 0) ||
    totalCanceledScreenings > 1
  ) {
    message = t(
      `${namespace}:report.exceptions.banners.canceledScreenings.body.relatedCanceled`,
      {
        firstName,
        documentTypes,
        screeningName,
        screeningStatus,
      },
    );
  } else {
    message = t(
      `${namespace}:report.exceptions.banners.canceledScreenings.body.singleCanceled`,
      {
        firstName: firstName || 'The candidate',
        documentTypes,
        screeningName,
        screeningStatus,
      },
    );
  }

  return <span>{message}</span>;
};

export default SingleExceptionMessageBody;
