import React, { useCallback } from 'react';
import { Report, User } from 'types';
import { GenericObject } from '@dashboard-experience/utils';
import PdfLink from './pdf-link';
import AdverseActionItems from './adverse-action-items';

type Props = {
  report: Report;
  user: User;
  setThereWasAction: React.Dispatch<React.SetStateAction<boolean>>;
  pdfItems: GenericObject[];
  showPdfEncryptionNote: boolean;
};

const DownloadActionItems: React.FC<Props> = ({
  user,
  report,
  setThereWasAction,
  pdfItems,
  showPdfEncryptionNote,
}) => {
  const closeItemWithAction = useCallback(() => {
    setThereWasAction(true);
  }, [setThereWasAction]);

  return (
    <>
      {Array.isArray(pdfItems) &&
        pdfItems.map((item, idx) => (
          <PdfLink
            key={item.link}
            item={item}
            report={report}
            closeWithAction={closeItemWithAction}
            showPdfEncryptionNote={showPdfEncryptionNote}
          />
        ))}
      <AdverseActionItems />
    </>
  );
};

export default DownloadActionItems;
