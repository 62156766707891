import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Assignment } from 'modules/assess/models/rulesets';

export type Props = {
  currentSelectedItems?: Assignment.List;
  items?: string[];
  initialSelectedItems?: string[];
  invalid?: boolean;
  invalidText?: string;
  labels: {
    label: string;
    long_label: string;
    single_label: string;
    plural_label: string;
  };
  onChange?: (items: string[]) => void;
  testId?: string;
};

const Row = styled.div`
  display: flex;
  width: 576px;
  align-items: flex-start;
  gap: 12px;
`;

const RowLabel = styled.p`
  color: ${colors.textIconBlackSecondary78};
  font-size: 0.875rem !important;
  font-weight: 700;
  line-height: 1.25rem;
  display: flex;
  width: 80px;
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const MultiSelectStyled = styled(M.MultiSelect)`
  width: 320px;
  height: 32px;
`;

export const Selector: React.FC<Props> = ({
  currentSelectedItems,
  items,
  initialSelectedItems,
  invalid,
  invalidText,
  labels,
  onChange,
  testId,
}) => {
  const getLabel = () => {
    const selectedCount =
      initialSelectedItems?.length ?? currentSelectedItems?.length ?? 0;

    if (selectedCount === 1) {
      return labels.single_label;
    }
    if (selectedCount > 1) {
      return labels.plural_label;
    }

    return labels.long_label;
  };

  return (
    <Row data-testid={testId}>
      <RowLabel>{labels.label}</RowLabel>
      <MultiSelectStyled
        id={testId}
        alwaysShowSelectionTags
        items={items}
        initialSelectedItems={initialSelectedItems}
        invalid={invalid}
        invalidText={invalidText}
        label={getLabel()}
        onChange={onChange}
      />
    </Row>
  );
};

export default Selector;
