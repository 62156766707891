import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';
import Option from './option';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const StyledContainer = styled.div`
  padding-bottom: 0.5rem;

  #assess-v1-settings-candidate-story-request-options {
    .cds--radio-button__label-text {
      color: ${colors.brandNavy3};
      font-weight: 500;
      font-sieze: 14px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 3.5rem;
`;

const Options: React.FC<Props> = ({ settings, setSettings }) => {
  const current =
    settings?.adverse_action_pause_on_candidate_story_submitted_value ||
    Entity.AdverseActionPauseOptions.All;
  const disabled = !settings?.adverse_action_pause_on_candidate_story_submitted;

  const handleChange = useCallback(
    (selected: Entity.AdverseActionPauseOptions) => {
      setSettings((state: Entity.Type) => ({
        ...state,
        adverse_action_pause_on_candidate_story_submitted_value: selected,
      }));
    },
    [setSettings],
  );

  return (
    <StyledContainer data-testid='assess-v1-settings-adverse-action-pause-options'>
      <Flex id='assess-v1-settings-adverse-action-pause-options'>
        <Option
          onChange={handleChange}
          selected={current === Entity.AdverseActionPauseOptions.All}
          value={Entity.AdverseActionPauseOptions.All}
          disabled={disabled}
        />
        <Option
          onChange={handleChange}
          selected={current === Entity.AdverseActionPauseOptions.AutoOnly}
          value={Entity.AdverseActionPauseOptions.AutoOnly}
          disabled={disabled || !settings?.adverse_action_automation?.enabled}
        />
        <Option
          onChange={handleChange}
          selected={current === Entity.AdverseActionPauseOptions.ManualOnly}
          value={Entity.AdverseActionPauseOptions.ManualOnly}
          disabled={disabled}
        />
        <Option
          onChange={handleChange}
          selected={
            current === Entity.AdverseActionPauseOptions.EnabledPackages
          }
          value={Entity.AdverseActionPauseOptions.EnabledPackages}
          disabled={disabled}
        />
      </Flex>
    </StyledContainer>
  );
};

export default Options;
