import React from 'react';
import {
  RightSideContainer,
  StyledFooter,
  SecondaryButton,
  PrimaryButton,
} from './styles';

type Props = {
  primaryBtnText: string;
  secondaryBtnText?: string;
  secondaryOnClick?: () => void;
  primaryButtonProps?: PrimaryButtonProps;
};

export type PrimaryButtonProps = {
  disabled: boolean;
  onClick: Function;
};

const Footer = ({
  primaryBtnText,
  secondaryBtnText,
  secondaryOnClick,
  primaryButtonProps,
}: Props) => {
  return (
    <StyledFooter className='styled-footer'>
      {secondaryBtnText && (
        <SecondaryButton
          className='secondary-button'
          onClick={secondaryOnClick}
          data-testid='secondary-button'
          kind='tertiary'
          size='md'
        >
          {secondaryBtnText}
        </SecondaryButton>
      )}
      <RightSideContainer>
        <PrimaryButton
          className='primary-button'
          kind='primary'
          data-testid='primary-button'
          size='md'
          {...primaryButtonProps}
        >
          {primaryBtnText}
        </PrimaryButton>
      </RightSideContainer>
    </StyledFooter>
  );
};

export default Footer;
