import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { M } from '@dashboard-experience/mastodon';
import {
  AssessStatuses,
  ReportStatuses,
  getResultOrStatus,
} from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { useFlag } from '@dashboard-experience/react-flagr';
import { handleAiClick } from '../../../../api/aiExplainr';
import getBodyText from './getBodyText';

const { CONSIDER } = ReportStatuses;

const NationalCriminalSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { national_criminal_search: natCrim } = report;
  const currentUser = useUser();
  const status = getResultOrStatus(natCrim || {});
  const isConsider = status === CONSIDER;
  const useScreeningStatusOverAssessment =
    natCrim?.assessment?.display?.decision === AssessStatuses.ELIGIBLE;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  return natCrim ? (
    <M.Screening
      title='National Search'
      screening={natCrim}
      statusType={reportStatusType}
      id={SCREENING_TITLES.national_criminal_search.id}
      useScreeningStatusOverAssessment={useScreeningStatusOverAssessment}
    >
      {isConsider && getBodyText(report)}
      {isConsider &&
        natCrim?.records?.map((record: any) => {
          const handleClickWithExplainer = handleAiClick(
            record.state || '',
            record.county || '',
          );

          return (
            <M.Record
              key={record.id}
              record={record}
              currentUser={currentUser}
              report={report}
              reportStatusType={reportStatusType}
              chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
              chargesExplainer={
                aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
              }
            />
          );
        })}
    </M.Screening>
  ) : null;
};

export default NationalCriminalSearch;
