import { useNamespace } from 'modules/assess/ui/router/context';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Description: React.FC = () => {
  const { t } = useTranslation('');
  const namespace = useNamespace();
  const description = t(
    `assess:ruleset.version.context_dates_panel.${namespace}.description`,
  );

  return (
    <p data-testid='assess-ruleset-version-context-dates-panel-description'>
      {description}
    </p>
  );
};

export default Description;
