import React from 'react';
import {
  AccordionItem,
  ActionPropsProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import stethoscope from '../../shared/svg/stethoscope.svg';
import FACIS1Search from './HealthcareSanctionsFACISScreenings/FACIS1Search';
import FACIS3Search from './HealthcareSanctionsFACISScreenings/FACIS3Search';

type HealthcareSanctionsFACISItemProps = {
  screeningTypesInSearch: string[];
  searchString?: string;
  actionProps: ActionPropsProps;
  itemPrices: { [key: string]: number };
  additionalProperties: {
    facis_search: {
      subtype: number;
    };
  };
};

const HealthcareSanctionsFACISItem = ({
  screeningTypesInSearch,
  searchString,
  actionProps,
  itemPrices,
  additionalProperties,
}: HealthcareSanctionsFACISItemProps) => {
  return (
    <AccordionItem
      open={screeningTypesInSearch?.includes('facis_search')}
      data-testid='healthcare-sanctions-dropdown'
      title={
        <CategoryTitle data-testid='healthcare-sanctions-title'>
          <Icon src={stethoscope} className='screening-icon' /> Fraud Abuse
          Control Information System (FACIS)
        </CategoryTitle>
      }
    >
      <ul>
        <FACIS1Search
          shouldOpen={
            screeningTypesInSearch?.includes('facis_search') &&
            searchString?.includes('facis1')
          }
          itemPrices={itemPrices}
          actionProps={actionProps}
          additionalProperties={additionalProperties}
        />
        <FACIS3Search
          shouldOpen={
            screeningTypesInSearch?.includes('facis_search') &&
            searchString?.includes('facis3')
          }
          itemPrices={itemPrices}
          actionProps={actionProps}
          additionalProperties={additionalProperties}
        />
      </ul>
    </AccordionItem>
  );
};

export default HealthcareSanctionsFACISItem;
