import { ReactPortal } from 'react';
import noop from 'lodash/noop';
import { DropdownItem } from 'modules/adjudication/ui/decision/common/split-action-button';
import {
  AnalyticsEvent,
  SECONDARY_ACTIONS_EVENT_NAMES,
  useTrackEvent,
} from 'utils/analytics';
import {
  useCancelAction,
  usePauseAction,
  usePostAdverseAction,
  useResumeAction,
} from 'modules/adjudication/ui/decision/adverse-action';
import { usePostAAAllowed } from 'modules/adjudication/utilities';
import { GenericObject } from '@dashboard-experience/utils';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

type Modal = ReactPortal | null;
type Item = DropdownItem & { modal: Modal };

const addItem = (
  item: Item,
  items: DropdownItem[],
  modals: Modal[],
  trackEvent: (event: AnalyticsEvent, eventProperties?: GenericObject) => any,
  eventName: AnalyticsEvent,
  reportProperties: GenericObject,
) => {
  const { title, subtitle, action, modal } = item;

  const actionWithAmplitudeTracking = () => {
    trackEvent(eventName, {
      Source: 'Dropdown Item',
      Report: reportProperties,
    });
    action();
  };

  items.push({
    title,
    subtitle,
    action: actionWithAmplitudeTracking,
  });
  modals.push(modal);
};

const useGetDropdownItems = () => {
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();
  const cancel = useCancelAction();
  const pause = usePauseAction();
  const post = usePostAdverseAction();
  const resume = useResumeAction();
  const postAdverseActionAllowed = usePostAAAllowed();

  const items: DropdownItem[] = [];
  const modals: Modal[] = [];
  let action: () => void = noop;

  if (resume) {
    addItem(
      resume,
      items,
      modals,
      trackEvent,
      SECONDARY_ACTIONS_EVENT_NAMES.RESUME_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED,
      reportProperties,
    );
    if (action === noop) {
      action = resume.action;
    }
  }

  if (postAdverseActionAllowed && post) {
    addItem(
      post,
      items,
      modals,
      trackEvent,
      SECONDARY_ACTIONS_EVENT_NAMES.POST_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED,
      reportProperties,
    );
    if (action === noop) {
      action = post.action;
    }
  }

  if (cancel) {
    addItem(
      cancel,
      items,
      modals,
      trackEvent,
      SECONDARY_ACTIONS_EVENT_NAMES.CANCEL_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED,
      reportProperties,
    );
    if (action === noop) {
      action = cancel.action;
    }
  }

  if (pause) {
    addItem(
      pause,
      items,
      modals,
      trackEvent,
      SECONDARY_ACTIONS_EVENT_NAMES.PAUSE_ADVERSE_ACTION_DROPDOWN_MENU_CLICKED,
      reportProperties,
    );
    if (action === noop) {
      action = pause.action;
    }
  }

  return {
    items,
    modals,
    action,
  };
};

export default useGetDropdownItems;
