/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import { useContext, useMemo } from 'react';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Report,
  address,
  GenericObject,
  Candidate,
  AdverseActionable,
  AISummaryHook,
  AISummary,
  ReportExceptions,
} from 'types';
import UIContext from 'context/UI';
import { useFlag } from '@dashboard-experience/react-flagr';
import { REPORT_DATA_MASSAGING } from 'Flags';

import { toastError, toastSuccess } from 'actions';

import { isInternationalCountry, postMessageToDashboard } from 'utils';
import { useReport } from 'containers/Report';
import {
  getReportExceptions,
  getWithMultiMvr,
  getAdverseActionable,
  BaseParams,
  getScreeningAdditionalInformation,
  getAssociatedAddresses,
  OrderNewReportParams,
  orderNewReport,
  upgradeReport,
  orderNewReportViaAddChecks,
  getAISummary,
} from './actions';
import { mapReportToStatus } from './helpers';

export const useReportsWithMultiMvr = (params: BaseParams) => {
  const { reportId } = params;
  const key: AnyQueryKey = ['reports/multi-mvr', { ...params }];

  const dataMassaging = useFlag(REPORT_DATA_MASSAGING)?.variantKey === 'on';

  const request = () => getWithMultiMvr(params);

  const res = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId,
    staleTime: 30000, // 30 seconds
  });

  const rep = res?.data || {};
  const report = useMemo(
    () => (dataMassaging ? mapReportToStatus(rep) : rep),
    [rep, dataMassaging],
  ) as Report;
  const isLoading = res?.isLoading as boolean;
  const isError = res?.isError as boolean;
  const refetch = res?.refetch;
  const error = res?.error;

  const payload = useMemo(
    () => ({
      report,
      isLoading,
      isError,
      error,
      refetch,
    }),
    [report, isLoading, isError, refetch, error],
  );

  return payload;
};

export const useAdverseActionable = () => {
  const { report } = useGetReport();
  const reportId = report.id;
  const key: AnyQueryKey = ['adverse-actionable', reportId];

  const request = () => getAdverseActionable(reportId);

  const res = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId,
  });
  const adverseActionable = useMemo(
    () => res?.data || {},
    [res?.data],
  ) as AdverseActionable;
  const isLoading = res?.isLoading as boolean;
  const isError = res?.isError as boolean;
  const refetch = res?.refetch;

  const payload = useMemo(
    () => ({
      adverseActionable,
      isLoading,
      isError,
      refetch,
    }),
    [adverseActionable, isLoading, isError, refetch],
  );

  return payload;
};

export const useGetReport = () => {
  const { reportId }: GenericObject = useParams();
  return useReportsWithMultiMvr({ reportId });
};

export const useGetReportExceptions = (reportId: string) => {
  const request = () => getReportExceptions(reportId);
  const key: AnyQueryKey = ['reportExceptions', { reportId }];
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId,
    staleTime: 30000,
  });

  const exceptions = useMemo(
    () => result?.data || {},
    [result?.data],
  ) as ReportExceptions;
  return exceptions;
};

export const useGetAssociatedAddresses = (
  reportId: string,
  showAssociatedAddresses: boolean,
) => {
  const request = () => getAssociatedAddresses(reportId);
  const key: AnyQueryKey = ['associatedAddresses', { reportId }];
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId && showAssociatedAddresses,
  });

  const addresses = useMemo(
    () => result?.data?.data || [],
    [result?.data?.data],
  ) as address[];

  return addresses;
};

export const useGetScreeningAdditionalInformation = (
  reportId: string,
  screeningId: string,
) => {
  const dispatch = useDispatch();
  const request = () => getScreeningAdditionalInformation(reportId);
  const key: AnyQueryKey = ['screeningAdditionalInfo', { reportId }];
  const result = useQuery(key, request, {
    onError: () =>
      dispatch(
        toastError(`Error: Unable to retrieve details of PATCH request`),
      ),
  });

  const { data, isSuccess, isError } = result;
  let relatedAdditionalInfo;

  if (data?.length)
    relatedAdditionalInfo = data.find((el: { object_id: string }) => {
      return el.object_id === screeningId;
    });

  let screeningAdditionalInformation;

  if (relatedAdditionalInfo) {
    screeningAdditionalInformation =
      relatedAdditionalInfo.object_additional_information;
  }

  return { screeningAdditionalInformation, isError, isSuccess };
};

export const useOrderNewReport = (viaAddChecks = false) => {
  const dispatch = useDispatch();
  const request = (params: OrderNewReportParams) =>
    !viaAddChecks ? orderNewReport(params) : orderNewReportViaAddChecks(params);
  const { contextId, isIframe, isStandaloneIframe } = useContext(UIContext);
  const history = useHistory();

  const [call, result] = useMutation(request, {
    onSuccess: (report: Report, params) => {
      const { candidate_id: candidateId } = params;

      // update candidate with the new report in react-query cache
      queryCache.setQueryData(
        ['candidates/dashboard', { candidateId }],
        (oldCandidate: Candidate = { id: candidateId }) => ({
          ...oldCandidate,
          last_report_id: report.id,
        }),
      );
      queryCache.setQueryData(
        ['candidates/dashboard/reports', { candidateId }],
        (oldCandidate: Candidate = { id: candidateId }) => ({
          ...oldCandidate,
          reports: [...(oldCandidate.reports || []), report],
        }),
      );

      if (isIframe && !isStandaloneIframe) {
        // inform dashboard of the new report
        postMessageToDashboard({
          contextId,
          broadcastMessage: 'add_report_to_candidate',
          params: {
            candidateId,
            report,
          },
        });
      } else {
        // redirect to new report if not in an iframe
        const path = `/candidates/${candidateId}/reports/${report.id}`;
        history.push(path);
      }
      dispatch(toastSuccess('Report successfully requested!'));
    },
  });

  return {
    call,
    result,
  };
};

export const useUpgradeReport = (reportId: string, packageSlug: string) => {
  const dispatch = useDispatch();

  const request = () => upgradeReport(reportId, packageSlug);

  const [upgradeReportCall, updateReportResult] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess(`Package upgraded`));
    },
    onError: (error: GenericObject, query) => {
      dispatch(toastError(`Error: ${error.response.data.error}`));
    },
  });
  return {
    upgradeReportCall,
    updateReportResult,
  };
};

export const useAISummary = (reportId: string): AISummaryHook => {
  const dispatch = useDispatch();
  const key: AnyQueryKey = ['ai-summary', { reportId }];
  const request = () => getAISummary(reportId);
  const result = useQuery(key, request, {
    onError: () =>
      dispatch(toastError(`Error: Unable to retrieve ai summary at this time`)),
  });
  const aiSummary = useMemo(
    () => result?.data || {},
    [result?.data],
  ) as AISummary;
  const isFetching = result?.isFetching as boolean;
  const isError = result?.isError as boolean;

  const payload = useMemo(
    () => ({ aiSummary, isFetching, isError }),
    [aiSummary, isFetching, isError],
  );

  return payload;
};

export const useIsInternationalReport = () => {
  const { report } = useGetReport();

  if (!report?.work_locations?.length) {
    return false;
  }

  const workLocation = report?.work_locations[0];
  return isInternationalCountry(workLocation?.country);
};

export const useReportPropertiesForAmplitudeEvents = () => {
  const report = useReport();

  return useMemo(
    () => ({
      report_status: report.status,
      report_assessment: report.assessment?.display?.decision,
      report_adjudication: report.adjudication,
    }),
    [report],
  );
};
