import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/reports-list/locales';

type Props = {};

const Header: React.FC<Props> = () => {
  const { t } = useTranslation();
  const title = t(`${namespace}:header.title`);

  return (
    <div data-testid='reports-list-header'>
      <h2>{title}</h2>
    </div>
  );
};

export default Header;
