import { ReportStatuses } from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NonActionableBanner from './non-actionable-banner';

const ReportDisputedBanner = () => {
  const { t } = useTranslation();
  const { report } = useGetReport();

  if (report?.status !== ReportStatuses.DISPUTE) {
    return null;
  }

  return (
    <NonActionableBanner kind='info' title={t('banners.reportDisputed.text')} />
  );
};

export default ReportDisputedBanner;
