/* eslint-disable react/destructuring-assignment */
import { PARAMS } from 'components/Search';
import { getUrlParams } from 'modules/reports-list/utilities';
import { ActionType, Action, State } from './types';

export const initialState = {
  filters: getUrlParams(),
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }
    case ActionType.INITIALIZE_FILTERS: {
      const initialFilters = {
        ...PARAMS.default,
        ...getUrlParams(),
      };

      return {
        ...state,
        filters: initialFilters,
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export default reducer;
