import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { AssessStatuses, StatusTypes } from '@dashboard-experience/utils';

export const formatDate = (date?: string | null, fallback?: string | null) =>
  date ? moment(date).format('MMM y') : fallback;

export const dateRange = (startDate?: string | null, endDate?: string | null) =>
  `${formatDate(startDate, 'Unknown')} - ${formatDate(endDate, 'Present')}`;

export const duration = (
  startDate?: string | null,
  endDate?: string | null,
) => {
  if (!startDate) {
    return '';
  }

  const from = new Date(startDate).getTime();
  const to = endDate ? new Date(endDate).getTime() : new Date().getTime();

  return humanizeDuration(to - from, {
    round: true,
    units: ['y', 'mo'],
  });
};

export const getItemDisplayStatus = (
  reportStatusType: string,
  screeningStatus: string,
  itemStatus: string,
) => {
  const isStatusTypeAssess = reportStatusType == StatusTypes.Assess;
  const isItemConsider = itemStatus === AssessStatuses.CONSIDER;
  const isScreeningStatusAssess =
    screeningStatus === AssessStatuses.REVIEW ||
    screeningStatus === AssessStatuses.ESCALATED;
  const showScreeningStatus =
    isStatusTypeAssess && isItemConsider && isScreeningStatusAssess;
  return showScreeningStatus ? screeningStatus : itemStatus;
};
