import CustomIframe from 'components/CustomIframe';
import { CHECKR_PAY_URL } from 'Constants';
import { useUser } from 'context/CurrentUser';
import { isNil, omitBy } from 'lodash';
import React from 'react';

const iframeStyle = {
  width: '100%',
  minHeight: '1000px',
};

const removeNullValues = (object: any) => {
  return omitBy(object, value => isNil(value) || value === '');
};

const CheckrPayPage = () => {
  const currentUser = useUser();

  const iframeParamsData = removeNullValues({
    accountId: currentUser.account_id,
    companyName: currentUser?.account?.company?.name,
    companyPhone: currentUser?.account?.company?.phone,
    companyWebsite: currentUser?.account?.company?.website,
    email: currentUser.email,
    fullName: currentUser.full_name,
    userId: currentUser.id,
  });

  const iframeParams = new URLSearchParams(iframeParamsData).toString();

  return (
    <CustomIframe
      src={`${CHECKR_PAY_URL}/bgc-customer-onboarding?${iframeParams}`}
      id='checkr-pay-iframe'
      style={iframeStyle}
    />
  );
};

export default CheckrPayPage;
