import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const NonElectronicLocationConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
}: Props) => (
  <M.ComposedModal onClose={onCancel} open={isOpen}>
    <M.ModalHeader closeModal={onCancel}>
      <h2>Paper custody & control form</h2>
    </M.ModalHeader>
    <M.ModalBody>
      This clinic requires that the individual testing take a paper chain of
      custody form with them. Do not choose this clinic unless you have your own
      paper custody & control forms to give to the employee.
    </M.ModalBody>
    <M.ModalFooter>
      <ButtonRow>
        <ButtonWrapper>
          <M.Button
            data-testid='action-btn-secondary'
            onClick={onCancel}
            kind='secondary'
          >
            Cancel
          </M.Button>
          <M.Button
            data-testid='action-btn-secondary'
            onClick={onConfirm}
            kind='primary'
          >
            Confirm
          </M.Button>
        </ButtonWrapper>
      </ButtonRow>
    </M.ModalFooter>
  </M.ComposedModal>
);

const ButtonRow = styled(M.GridRow)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

export default NonElectronicLocationConfirmationModal;
