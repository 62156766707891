import React, { useCallback } from 'react';
import moment from 'moment';
import { M, colors } from '@dashboard-experience/mastodon';
import { EventView } from 'modules/adjudication/data';

const AdverseActionLink: React.FC<{ event: EventView; t: Function }> = ({
  event,
  t,
}) => {
  const timezone = moment.tz.guess();

  const handleClick = useCallback(() => {
    window.open(event.pdf_url, '_blank');
  }, [event]);

  const time = moment.tz(event.time, timezone).format('MMM. DD, YYYY h:mm a z');
  const description = (
    <>
      {time}
      {event.pdf_encrypted && (
        <small style={{ fontSize: '12px', color: colors.uiGrey500 }}>
          <br />
          {t('adjudication:report.events.password_protected_pdf')}
        </small>
      )}
    </>
  );

  return (
    <M.ActionMenuItem
      title={event.text}
      kind='node'
      actionNode={<M.Icon icon='Download' size='16' />}
      onClick={handleClick}
      description={description}
    />
  );
};

export default AdverseActionLink;
