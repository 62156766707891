import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { MultiAccount } from 'utils/AuthUtils';
import { NavDropdown, NavSelectedItem } from './styles';

type Props = {
  id: string;
  onChange: (selected: any) => void;
  defaultValue: MultiAccount;
  items: MultiAccount[];
  navCollapsed: boolean;
};

type SelectedItem = {
  selectedItem: MultiAccount;
};

const RenderedSelectedItem: React.FC<{
  selectedName: string;
  navCollapsed: boolean;
}> = ({ selectedName, navCollapsed }) => {
  return (
    <NavSelectedItem navCollapsed={navCollapsed}>
      <M.Icon icon='ArrowsHorizontal' />
      <span>{selectedName}</span>
    </NavSelectedItem>
  );
};

const NavDropdownComponent: React.FC<Props> = ({
  id,
  onChange,
  defaultValue,
  items,
  navCollapsed,
}) => {
  const [selectedItem, setSelectedItem] = useState<MultiAccount>(defaultValue);

  const handleSelect = useCallback(
    (value: SelectedItem) => {
      setSelectedItem(value.selectedItem);
      onChange(value.selectedItem);
    },
    [onChange, setSelectedItem],
  );

  const itemToString = useCallback((item: MultiAccount) => item.name, []);
  const renderSelectedItem = useCallback(
    (item: MultiAccount): JSX.Element => (
      <RenderedSelectedItem
        selectedName={item.name}
        navCollapsed={navCollapsed}
      />
    ),
    [navCollapsed],
  );

  return (
    <NavDropdown
      id={id}
      label='Switch Account'
      onChange={handleSelect}
      titleText=''
      selectedItem={selectedItem}
      initialSelectedItem={defaultValue}
      items={items}
      itemToString={itemToString}
      renderSelectedItem={renderSelectedItem}
      navCollapsed={navCollapsed}
    />
  );
};

export default NavDropdownComponent;
