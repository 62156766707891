import React, { ChangeEvent, useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { namespace } from '../../../locales';
import {
  StyledInviteEntryRowContainer,
  StyledInviteFormField,
} from './ManuaInviteEntries.styles';

type InviteEntryRowProps = {
  index: number;
  invites?: GetStartedT.Invite[];
  handleRemoveInvite: (id: string) => void;
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  handleNameChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  handlePhoneChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
};

const InviteEntryRow = ({
  index,
  invites = [],
  handleRemoveInvite,
  handleEmailChange,
  handleNameChange,
  handlePhoneChange,
}: InviteEntryRowProps) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.manual.inviteEntry.columns`;
  const currInvite = invites[index];

  const handleCloseClick = useCallback(
    () => handleRemoveInvite(currInvite.id),
    [handleRemoveInvite, currInvite.id],
  );

  return (
    <StyledInviteEntryRowContainer>
      <StyledInviteFormField
        type='email'
        labelText={t(`${basePath}.email.label`)}
        defaultValue={currInvite.email.value}
        data-dd-privacy='mask'
        data-testid={`invite-entry-email-${index}`}
        name='email'
        invalid={!currInvite.email.isValid}
        invalidText={t(`${basePath}.email.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e, currInvite.id),
          [handleEmailChange, currInvite.id],
        )}
      />
      <StyledInviteFormField
        type='name'
        labelText={t(`${basePath}.firstName.label`)}
        defaultValue={currInvite.firstName.value}
        data-dd-privacy='mask'
        data-testid={`invite-entry-name-${index}`}
        name='firstName'
        invalid={!currInvite.firstName.isValid}
        invalidText={t(`${basePath}.firstName.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handleNameChange(e, currInvite.id),
          [handleNameChange, currInvite.id],
        )}
      />
      <StyledInviteFormField
        type='phone'
        labelText={t(`${basePath}.phoneNumber.label`)}
        defaultValue={currInvite.phoneNumber.value}
        data-dd-privacy='mask'
        data-testid={`invite-entry-phone-${index}`}
        name='phoneNumber'
        invalid={!currInvite.phoneNumber.isValid}
        invalidText={t(`${basePath}.phoneNumber.errorMsg`)}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e, currInvite.id),
          [handlePhoneChange, currInvite.id],
        )}
      />
      {invites.length > 1 && (
        <M.Button
          kind='tertiary'
          onClick={handleCloseClick}
          data-testid={`invite-entry-remove-${index}`}
        >
          <M.Icon icon='Close' color={colors.uiGrey400} size={20} />
        </M.Button>
      )}
    </StyledInviteEntryRowContainer>
  );
};

export default InviteEntryRow;
