import React from 'react';
import {
  usePostAAAllowed,
  useDisplayCancelAdverseAction,
} from 'modules/adjudication/utilities';
import PostAA from 'modules/adjudication/ui/decision/adverse-action/post-adverse-action/button';
import { Cancel } from 'modules/adjudication/ui/decision/adverse-action/cancel';
import { resumeAA } from 'modules/adjudication/ui/decision/adverse-action/resume';
import { Report } from 'types';
import { useGetReport } from 'api/reports';
import { accountHasPermission } from '@dashboard-experience/utils';
import { useAdverseActions } from 'modules/adjudication/api';
import useGetDropdownItems from './use-get-dropdown-items';

const PostActions = () => {
  const postAdverseActionAllowed = usePostAAAllowed();
  const displayCancelAdverseAction = useDisplayCancelAdverseAction();
  const { report } = useGetReport() as unknown as Report;
  const canPause = accountHasPermission(report, 'adverse_action_pause');
  const { adverseAction } = useAdverseActions(report?.id);
  const hasPauseData = adverseAction?.pause_data;

  const displayResumeAdverseAction = canPause && hasPauseData;
  const { items, modals, action } = useGetDropdownItems();

  let Component: React.ElementType = () => null;
  if (displayResumeAdverseAction) {
    Component = resumeAA;
  } else if (postAdverseActionAllowed) {
    Component = PostAA;
  } else if (displayCancelAdverseAction) {
    Component = Cancel;
  }

  return (
    <>
      <Component dropdownItems={items} action={action} />
      {modals}
    </>
  );
};

export default PostActions;
