import React from 'react';
import { M } from '@dashboard-experience/mastodon';

interface LearnMoreModalProps {
  hideModal: () => void;
  open: boolean;
}

const LearnMoreModal = ({ hideModal, open }: LearnMoreModalProps) => {
  return (
    <M.ComposedModal
      onClose={hideModal}
      open={open}
      data-testid='learn-more-i9-modal'
    >
      <M.ModalHeader closeModal={hideModal}>
        <h2>I-9 information</h2>
      </M.ModalHeader>
      <M.ModalBody style={{ textAlign: 'left' }}>
        <p>
          <a
            href='https://help.checkr.com/hc/en-us/articles/15483139071895'
            target='_blank'
            rel='noreferrer'
          >
            Form I-9
          </a>{' '}
          is an employment eligibility form that the United States Citizenship
          and Immigration Services (USCIS) administers. You can use the Checkr
          Dashboard to{' '}
          <a
            href='https://help.checkr.com/hc/en-us/articles/15483223505303'
            target='_blank'
            rel='noreferrer'
          >
            order Form I-9
          </a>
          .
        </p>
        <p>
          If you&apos;re an admin user and you want to order Form I-9,{' '}
          <a href='https://checkr.com/contact' target='_blank' rel='noreferrer'>
            contact Checkr
          </a>{' '}
          to enable it. Federal and local law require that some employers use{' '}
          <a
            href='https://help.checkr.com/hc/en-us/articles/15483238166423'
            target='_blank'
            rel='noreferrer'
          >
            E-Verify
          </a>
          .
        </p>
        <p>
          Checkr partners with{' '}
          <a
            href='https://mitratech.com/products/tracker-i-9-compliance/'
            target='_blank'
            rel='noreferrer'
          >
            Tracker I-9
          </a>{' '}
          to help you order and track your Form I-9 documents and to integrate
          E-Verify into the Checkr Dashboard.
        </p>
        <p>
          <a
            href='https://help.checkr.com/hc/en-us/articles/16115864326551'
            target='_blank'
            rel='noreferrer'
          >
            Continue reading
          </a>
        </p>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button
          kind='primary'
          onClick={hideModal}
          data-testid='learn-more-i9-close-modal'
        >
          Got it
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default LearnMoreModal;
