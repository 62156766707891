import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { GridCol } from './PaymentStyledComponents';
import TaxCertificateStatusBadge from './TaxCertificateStatusBadge';

type Props = {
  status: string;
  expirationDate: string;
  exposureRegion: string;
  signedDate: string;
};

const TaxCertificate: React.FC<Props> = ({
  status,
  expirationDate,
  signedDate,
  exposureRegion,
}) => {
  const expiration = moment(expirationDate);
  const expirationYear = expiration.year();
  const expirationMonth = expiration.format('MM');

  const expirationDateComponent = (
    <p
      style={{ color: colors.uiTextSecondaryLight }}
      data-testid='tec-expiration-date'
    >
      Exp:&nbsp;
      <span>
        {expirationMonth}/{expirationYear}
      </span>
    </p>
  );

  const doesNotExpire = expirationDate === '9999-12-31';
  const showExpirationDate = status === 'valid' && !doesNotExpire;

  const effectiveDate = moment(signedDate);
  const effectiveMonth = effectiveDate.format('MM');
  const effectiveYear = effectiveDate.format('YYYY');

  const certificateTitle = signedDate
    ? `${exposureRegion} ${effectiveMonth}/${effectiveYear}`
    : exposureRegion;

  const certificateTitleComponent = (
    <p
      style={{ color: colors.uiTextSecondaryLight }}
      data-testid='tec-certificate-title'
    >
      <strong>{certificateTitle}</strong>
    </p>
  );

  return (
    <section
      data-testid='tax-exemption-certificate'
      style={{ marginBottom: '1rem' }}
    >
      <GridCol>
        <M.GridRow>
          {certificateTitleComponent}
          <TaxCertificateStatusBadge
            status={status}
            expirationDate={expirationDate}
          />
        </M.GridRow>
      </GridCol>
      <M.GridRow>{showExpirationDate && expirationDateComponent}</M.GridRow>
    </section>
  );
};

export default TaxCertificate;
