import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import { Report, CurrentUser as User } from '@dashboard-experience/utils';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import useGetPdfItems from 'modules/candidate/ui/header/report-actions/v2/download-actions/useGetPdfItems';
import PdfLinks from './PdfLinks';

const RenderTitle = () => (
  <div className='mastodon-menu-title' style={{ display: 'flex' }}>
    <span className='mastodon-menu-label'>Download PDF</span>
    <M.Icon icon='ChevronDown' size='16' style={{ marginLeft: '0.5rem' }} />
  </div>
);

const Note = () => (
  <p
    data-testid='note'
    style={{
      marginTop: '1rem',
      fontSize: sizes.small,
      color: `${colors.uiGrey900}a6`,
      lineHeight: '1rem',
    }}
  >
    Report PDF is password protected <br />
    with the last four digits of the <br />
    candidate’s SSN
  </p>
);

const StyledDropdownButtonWrapper = styled('div')`
  .cds--popover-container {
    width: 208px;
  }

  .mastodon-overflow-menu {
    max-height: 3rem;
    color: ${colors.uiTextPrimaryLight};
    width: 100%;
    box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
    border: 1px solid ${colors.uiGrey200};
    border-radius: 4px;
    height: 36px;
    margin: 0 0.5rem;
  }

  .cds--popover {
    display: none !important;
  }

  .mastodon-menu-label {
    font-weight: bold;
  }
`;

const DownloadPdfButton: React.FC<{ currentUser: User; report: Report }> = ({
  currentUser,
  report,
}) => {
  const { pdfItems, showPdfEncryptionNote } = useGetPdfItems();

  const [thereWasAction, setThereWasAction] = useState(false);

  const onOpenMenu = useCallback(() => {
    setThereWasAction(false);
  }, [setThereWasAction]);

  const closeWithAction = useCallback(() => {
    setThereWasAction(true);
  }, [setThereWasAction]);

  const trackEvent = useTrackEvent();

  const onCloseMenu = useCallback(() => {
    if (currentUser && !thereWasAction) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Document Selected': 'No Action',
      });
    }
  }, [currentUser, thereWasAction, trackEvent]);

  // @ts-ignore
  return (
    <div>
      <StyledDropdownButtonWrapper>
        <M.OverflowMenu
          data-floating-menu-container
          renderIcon={RenderTitle}
          id='pdf-downloads'
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
        >
          {Array.isArray(pdfItems) &&
            pdfItems.map((pdf, idx) => {
              return (
                <PdfLinks
                  key={`pdf.docType-${idx + 1}`}
                  item={pdf}
                  // @ts-ignore TODO: this error should be fixed once report type is updated in Utils
                  report={report}
                  closeWithAction={closeWithAction}
                />
              );
            })}
        </M.OverflowMenu>
      </StyledDropdownButtonWrapper>

      {showPdfEncryptionNote && <Note />}
    </div>
  );
};

export default DownloadPdfButton;
