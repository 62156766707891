import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import processEvents from 'modules/adjudication/ui/report/events/processEvents';
import AdverseActionLink from './adverse-action-link';

const AdverseActionItems = () => {
  const report = useReport();
  const { id: reportId } = report;

  const { adverseActions } = useAdverseActions(reportId);
  const { t } = useTranslation(namespace);

  const processedEvents = processEvents(t, report, adverseActions, {}, []);
  const pdfEvents = processedEvents.filter(e => e.pdf_url);

  if (!pdfEvents.length) return null;

  return (
    <M.ActionSubMenuItem
      title='Adverse action notices'
      size='m'
      position='left-bottom'
    >
      {pdfEvents.map(e => (
        <AdverseActionLink event={e} t={t} key={e.pdf_url} />
      ))}
    </M.ActionSubMenuItem>
  );
};

export default AdverseActionItems;
