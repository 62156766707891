import { Client } from 'api';
import { Candidate, CandidatePostalAddress, GenericObject, User } from 'types';
import querystring from 'querystring';
import { CANDIDATES_PATH, PUBLIC_CANDIDATES_PATH } from './constants';

export type BaseParams = {
  candidateId?: string;
  params?: { [key: string]: any };
};

// Information on the User who is making the Deletion call on behalf of a Candidate
export type DeletionUserParams = {
  deletion_contact_email_address: string;
  deletion_contact_first_name?: string;
  deletion_contact_last_name?: string;
};

export const getDashboardCandidate = ({
  candidateId,
  params,
}: BaseParams): Promise<Candidate> => {
  return Client.get(`${CANDIDATES_PATH}/${candidateId}/dashboard`, {
    params,
  }) as Promise<Candidate>;
};

export const getFullSsn = (path: string): Promise<GenericObject> =>
  Client.get(`${CANDIDATES_PATH}/${path}`);

export const updateCandidate = (
  candidate: GenericObject,
  field: string,
): Promise<GenericObject> => {
  const { id: candidateId } = candidate;
  const data = { [field]: candidate[field] };
  return Client.post(`${CANDIDATES_PATH}/${candidateId}/${field}`, data);
};

export const getCandidateExceptions = (
  candidateId?: string,
): Promise<GenericObject> => {
  return Client.get(`candidates/${candidateId}/exceptions`);
};

export const postEmailUnresolvedExceptions = (
  reportId: string,
): Promise<GenericObject> => {
  return Client.post(`reports/${reportId}/email_unresolved_exceptions`);
};

// CCPA Data deletion call, to delete a Candidate's data at their request
export const deleteCandidateData = (
  candidateId: string,
  user: User,
): Promise<GenericObject> => {
  // We need to transmit some information on the User making the request for auditing reasons. Email at the least
  const paramsObject: DeletionUserParams = {
    deletion_contact_email_address: user.email,
  };

  // If the User also has a full name, pass that along in the request as well.
  if (user.full_name && user.full_name !== '') {
    const nameSplit = user.full_name ? user.full_name.split(' ') : [];
    const firstName = nameSplit.length ? nameSplit[0] : '';
    const lastName = nameSplit.length ? nameSplit[nameSplit.length - 1] : '';
    if (nameSplit.length === 2) {
      paramsObject.deletion_contact_first_name = firstName;
      paramsObject.deletion_contact_last_name = lastName;
    }
  }

  const qs = querystring.encode(paramsObject);

  return Client.delete(`${CANDIDATES_PATH}/${candidateId}/pii?${qs}`, true);
};

export const updateCandidatePostalAddress = (
  candidateId: string,
  params: CandidatePostalAddress,
): Promise<CandidatePostalAddress> => {
  return Client.post(
    `${PUBLIC_CANDIDATES_PATH}/${candidateId}/postal_address`,
    params,
  ) as Promise<CandidatePostalAddress>;
};

export const getCandidatePostalAddress = (
  candidateId: string,
): Promise<CandidatePostalAddress> => {
  return Client.get(
    `${PUBLIC_CANDIDATES_PATH}/${candidateId}/postal_address`,
  ) as Promise<CandidatePostalAddress>;
};
