import React from 'react';
import Criminal from 'modules/assess/models/context-date-option/criminal';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { Criminal as CriminalState } from 'modules/assess/ui/state/context-date/state';
import { entries } from 'state/utilities/entities/cache';
import Create from '../CreateContainer';
import List from '../List';
import { findAvailableOptions } from '../utils';

export const ContextDatesContainer: React.FC = () => {
  const { options: stateOptions } = useState();
  const criminalStateOptions = stateOptions as CriminalState;
  const options = entries(criminalStateOptions.criminal) || [];
  const availableOptions = findAvailableOptions(options, Criminal);

  return (
    <div data-testid='assess-ruleset-version-context-dates-criminal-container'>
      <List options={options} />
      <Create availableOptions={availableOptions} />
    </div>
  );
};

export default ContextDatesContainer;
