import { useMutation, useQuery } from 'react-query';
import { useUpdatedState } from '@dashboard-experience/utils';

import { useCallback, useMemo, useState } from 'react';
import {
  initializeUserPreferences,
  postDashboardPreference,
  postNestedDashboardPreference,
} from './actions';
import { getDashboardPreference, getNestedDashboardPreference } from './utils';
import {
  syncNestedPreference,
  syncPreference,
  useNestedPreferenceEventListener,
  usePreferenceEventListener,
} from './sync';

export const usePostDashboardPreference = () =>
  useMutation(postDashboardPreference);

export const usePostNestedDashboardPreference = () =>
  useMutation(postNestedDashboardPreference);

export const useInitializeUserPreferences = (enabled: boolean) =>
  useQuery('initializeUserPreferences', initializeUserPreferences, {
    refetchOnWindowFocus: false,
    retry: 1,
    // Wait until we have a user before we initialize preferences
    enabled,
  });

export const usePreference = (key: string) => {
  const loaded = useMemo(() => getDashboardPreference(key), [key]);
  const [syncedPreference, setSyncedPreference] = useState(loaded);
  usePreferenceEventListener(key, setSyncedPreference);
  const [savedPreference, setSavedPreference] =
    useUpdatedState(syncedPreference);

  const [post, result] = usePostDashboardPreference();

  const savePreference = useCallback(
    async (value: any) => {
      setSavedPreference(value);
      syncPreference(key, value);
      return post({ preference: key, value });
    },
    [post, key, setSavedPreference],
  );
  return useMemo(
    () => [savedPreference, savePreference, result],
    [savedPreference, savePreference, result],
  );
};

export const useNestedPreference = (key: string, subKey: string) => {
  const loaded = useMemo(
    () => getNestedDashboardPreference(key, subKey),
    [key, subKey],
  );
  const [syncedPreference, setSyncedPreference] = useState(loaded);
  useNestedPreferenceEventListener(key, subKey, setSyncedPreference);

  const [savedPreference, setSavedPreference] =
    useUpdatedState(syncedPreference);

  const [post, result] = usePostNestedDashboardPreference();
  const savePreference = useCallback(
    async (value: any) => {
      setSavedPreference(value);
      syncNestedPreference(key, subKey, value);
      return post({ parentKey: key, subKey, value });
    },
    [setSavedPreference, post, key, subKey],
  );
  return useMemo(
    () => [savedPreference, savePreference, result],
    [savedPreference, savePreference, result],
  );
};
