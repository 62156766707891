import { Client } from 'api';

export type JobRoleParams = {
  accountId: string;
};

export const getJobRoles = ({
  accountId,
}: JobRoleParams): Promise<{ [key: string]: any }> => {
  return Client.get(`/accounts/${accountId}/job_roles`);
};
