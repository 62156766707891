import React, {
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { updateParentWindowUrl } from 'utils';
import { useHistory } from 'react-router-dom';
import * as Countries from 'i18n-iso-countries';
import { accountHasPermission } from '@dashboard-experience/utils';
import { usePackagePrice } from 'components/AddScreenings/hooks/usePackagePrice';
import * as utils from 'components/AddScreenings/shared/utils';
import UserContext from 'context/CurrentUser';
import {
  EditableSection as AmplitudeEditableSection,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { Context } from 'components/Packages/context';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  MULTIPLE_PEOPLE,
  actionTypes,
  useOrderBackgroundCheckContext,
} from '../Context';
import Header from './Header';
import Footer from './Footer';
import SummaryOfPricing from './SummaryOfPricing';
import EditableSection from './EditableSection';
import * as SectionContent from './SectionContent';
import { Geo } from '../SharedItems';

import CandidateInfoSection from './CandidateInfoSection';

const ContainerStyled = styled.div`
  max-width: 824px;
`;

export const isWorkLocationVisible = (
  segmentation_enabled: boolean,
  geos: Geo[],
) => {
  return (
    (!segmentation_enabled && geos.length !== 0) ||
    (segmentation_enabled && geos.length > 0)
  );
};

export type ReviewAndSubmitOrderProps = {
  orderCertified: boolean;
  setOrderCertified: (orderCertified: boolean) => void;
  contextId: string;
  handleLearnMoreClick: () => void;
  handleServiceAgreementClick: () => void;
  setSubmitClicked?: (clicked: boolean) => void;
};

const ReviewAndSubmitOrder: React.FC<ReviewAndSubmitOrderProps> = ({
  orderCertified = false,
  setOrderCertified = () => {},
  contextId,
  handleLearnMoreClick,
  handleServiceAgreementClick,
  setSubmitClicked = () => {},
}) => {
  const currentUser = useContext(UserContext);
  const [paymentProfileEditMode, setPaymentProfileEditMode] = useState(false);
  const history = useHistory();
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const { geos, payment_profiles, billing_entity_enabled } =
    useContext(Context);

  const {
    emails,
    providerType,
    inviteMethod,
    manualBulkUploadType,
    addedScreeningTypes,
    additionalProperties,
    newPackageNameInvalid,
    newPackageName,
    location,
    saveForNextTime,
    oldBasePackage,
    basePackage,
    geo,
    selectedGeo,
    paymentProfile,
    pricesSnapshot: { addonPrices, aliasPrice },
  } = state;
  const { account } = currentUser;
  const { segmentation_enabled } = account;
  const trackEvent = useTrackEvent();
  const addonsAdded: boolean = useMemo(() => {
    return addedScreeningTypes.length > 0;
  }, [addedScreeningTypes.length]);

  const basePackageToUse = useMemo(() => {
    // This is ONLY for UI; The API will save the base package with add-ons as a private package
    // Display oldBasePackage if there are no addons, aliases or package is not saved. This is equal to 'Skip' button click
    return (!saveForNextTime ||
      !addonsAdded ||
      state.aliasesEnabled === AliasesEnabledType.OFF) &&
      oldBasePackage?.name
      ? oldBasePackage
      : basePackage;
  }, [
    saveForNextTime,
    addonsAdded,
    state.aliasesEnabled,
    oldBasePackage,
    basePackage,
  ]);
  const selectedGeoIsInternational = selectedGeo === 'international';
  const useAlias =
    Boolean(basePackageToUse.use_alias_skus) ||
    state.aliasesEnabled === AliasesEnabledType.ON;
  const showPrices =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner' &&
    !selectedGeoIsInternational;

  const shouldCalculatePrice = state.basePackage
    ? !state.basePackage.requires_fmcsa_compliance
    : true;

  const trackEditClicks = useCallback(
    (section: AmplitudeEditableSection) => {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_EDITED,
        {
          'Edit Section': section,
          'Candidate Email Changed': section === 'Candidate' ? 'Yes' : 'No',
          'Number Of Candidate Email Entered':
            section === 'Candidate' ? emails.length : null,
        },
      );
    },
    [trackEvent, currentUser, emails],
  );

  const handleEdit = useCallback(
    (path, sectionName) => {
      trackEditClicks(sectionName);
      updateParentWindowUrl({
        contextId,
        path,
        reload: false,
      });
      history.push(path);
      // Use oldBasePackage to be able to delete previously added checks
      dispatch({
        type: actionTypes.SET_BASE_PACKAGE,
        payload: { basePackage: state.oldBasePackage },
      });
      dispatch({
        type: actionTypes.EDIT_CLICKED,
        payload: { editClicked: true },
      });
    },
    [trackEditClicks, contextId, history, dispatch, state.oldBasePackage],
  );

  const handlePaymentProfileEdit = useCallback(() => {
    const clickedSave = paymentProfileEditMode === true;

    if (clickedSave) trackEditClicks('Payment profile');

    setPaymentProfileEditMode(!paymentProfileEditMode);
  }, [paymentProfileEditMode, trackEditClicks]);

  const packageName = useMemo(() => {
    if (!newPackageNameInvalid && newPackageName !== '') {
      return newPackageName;
    }
    if (addonsAdded || state.aliasesEnabled === AliasesEnabledType.ON) {
      return basePackage.name;
    }
    return basePackageToUse?.name;
  }, [
    addonsAdded,
    state.aliasesEnabled,
    newPackageNameInvalid,
    newPackageName,
    basePackageToUse?.name,
    basePackage.name,
  ]);

  const handleGetStartedEditClick = useCallback(() => {
    handleEdit('/order/get-started', 'Work Location');
  }, [handleEdit]);

  const handleSelectPackageEditClick = useCallback(() => {
    handleEdit('/order/select-your-package', 'Select Your Package');
  }, [handleEdit]);

  const handleAddonsEditClick = useCallback(() => {
    handleEdit('/order/customize-with-addons', 'Select add-ons');
  }, [handleEdit]);

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: basePackageToUse,
      addedScreeningTypes,
      additionalProperties,
      packageName,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    addedScreeningTypes,
    additionalProperties,
    basePackageToUse,
    packageName,
  ]);

  const basePackageQuery = usePackagePrice(
    ['base-package-price', basePackageToUse?.name],
    account,
    basePackageToUse,
    undefined,
    shouldCalculatePrice,
  );

  const basePackageQueryWithoutAlias = usePackagePrice(
    ['base-package-price', basePackageToUse?.name],
    account,
    { ...basePackageToUse, aliases_enabled: 'off' },
    undefined,
    shouldCalculatePrice,
  );

  const basePackagePrice = basePackageQueryWithoutAlias?.data?.fixed_price;

  const builtPackage = buildPostBodyWithAddOns();

  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackageToUse?.name,
      addedScreeningTypes,
      additionalProperties,
    ],
    account,
    {
      ...builtPackage,
      aliases_enabled: useAlias
        ? AliasesEnabledType.ON
        : builtPackage.aliases_enabled,
    },
    undefined,
    shouldCalculatePrice,
  );

  const countryToString = (country: string) => {
    return Countries.getName(country, 'en') || country || '';
  };

  const locationString = useMemo(() => {
    if (!segmentation_enabled && geos.length) {
      return geo?.country && geo?.country !== ''
        ? countryToString(geo.country)
        : geo?.name;
    }
    if (
      location.country !== 'US' ||
      (location.country === 'US' && !location.state)
    )
      return countryToString(location.country || '');

    if (!location.city || location.city === '')
      return `${location.state?.name}`;

    return `${location.city.name}, ${location.state.abbreviation}`;
  }, [location, segmentation_enabled, geos, geo]);

  const showWorkLocation = useMemo(() => {
    return isWorkLocationVisible(segmentation_enabled, geos);
  }, [segmentation_enabled, geos]);

  const basePackageScreenings = basePackageToUse?.screenings?.map(
    screening => screening.type,
  );

  const showPriceInfo = useMemo(() => {
    return showPrices && !!basePackagePrice && basePackagePrice !== 0;
  }, [showPrices, basePackagePrice]);

  const { data, isLoading: isAddOnsPricesLoading } = pendingAddOnPricesQuery;

  useEffect(() => {
    // Capture price snapshot if we are on manual bulk upload flow
    if (
      data &&
      data?.bundled_price &&
      state.manualBulkUploadType === MULTIPLE_PEOPLE
    ) {
      dispatch({
        type: actionTypes.SET_PRICE_SNAPSHOT,
        payload: {
          pricesSnapshot: {
            addonPrices,
            basePackagePrice,
            aliasPrice: data.bundled_price - basePackagePrice,
            subtotalPerCandidate: utils.getDollarAmount(data?.bundled_price),
          },
        },
      });
    }
  }, [
    addonPrices,
    basePackagePrice,
    data,
    dispatch,
    state.manualBulkUploadType,
  ]);

  return (
    <ContainerStyled>
      <Header
        providerType={providerType}
        inviteMethod={inviteMethod}
        manualBulkUploadType={manualBulkUploadType}
      />
      {billing_entity_enabled && (
        <EditableSection
          id='paymentProfile'
          sectionTitle='Payment profile'
          onEditClick={handlePaymentProfileEdit}
          description={{
            title: state.paymentProfile.name || 'none',
            content: (
              <SectionContent.PaymentProfileSectionContent
                payment_profiles={payment_profiles}
                billing_entity_enabled={billing_entity_enabled}
                payment_profile={paymentProfile}
                goBackClicked={false}
                editMode={paymentProfileEditMode}
              />
            ),
            isCandidatesSection: true,
            expand: true,
          }}
          inlineEdit
          editMode={paymentProfileEditMode}
        />
      )}

      <CandidateInfoSection
        handleGetStartedEditClick={handleGetStartedEditClick}
        setSubmitClicked={setSubmitClicked}
        trackEditClicks={trackEditClicks}
        handleEdit={handleEdit}
      />

      {showWorkLocation && (
        <EditableSection
          id='location'
          sectionTitle='Work location'
          onEditClick={handleGetStartedEditClick}
          description={{
            title: locationString,
          }}
        />
      )}
      <EditableSection
        id='selected-package'
        sectionTitle='Selected package'
        onEditClick={handleSelectPackageEditClick}
        description={{
          title: packageName,
          content: (
            <SectionContent.ScreeningsContent
              screenings={basePackageToUse?.screenings}
              additionalProperties={additionalProperties}
            />
          ),
        }}
        showPricingColumn={showPriceInfo}
        price={basePackageQuery.data?.fixed_price}
        priceDescription='Starting at'
      />
      {addedScreeningTypes.length > 0 && (
        <EditableSection
          id='selected-addons'
          sectionTitle='Customizations'
          onEditClick={handleAddonsEditClick}
          description={{
            expand: true,
            content: (
              <SectionContent.AddOnSectionContent
                // If save for next time was selected, addons will already be included in the newly created package
                addOnPricesData={saveForNextTime ? [] : data}
                addonData={addonPrices as any}
                isLoading={isAddOnsPricesLoading}
                basePackageScreenings={basePackageScreenings ?? []}
                additionalProperties={additionalProperties}
                showPrices={showPrices}
              />
            ),
          }}
          showPricingColumn={showPrices}
          hideLastColumn
        />
      )}
      {showPriceInfo && (
        <SummaryOfPricing
          isLoading={isAddOnsPricesLoading}
          addOnData={data}
          aliasPrice={
            useAlias || basePackage.aliases_enabled !== AliasesEnabledType.OFF
              ? aliasPrice
              : 0
          }
          hasAliasSkus={useAlias}
          saveForLater={state.saveForNextTime}
          pricesSnapshot={state.pricesSnapshot}
        />
      )}
      <Footer
        orderCertified={orderCertified}
        setOrderCertified={setOrderCertified}
        showPrices={showPriceInfo}
        handleLearnMoreClick={handleLearnMoreClick}
        handleServiceAgreementClick={handleServiceAgreementClick}
      />
    </ContainerStyled>
  );
};

export default ReviewAndSubmitOrder;
