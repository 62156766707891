import React, { useCallback, useState } from 'react';
import { Actions } from 'modules/assess/ui/state/context-date';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'modules/assess/ui/ruleset/version/context-dates/store';
import Accidents from './accidents';
import Suspensions from './suspensions';
import Violations from './violations';

const TABS = ['accidents', 'suspensions', 'violations'];

export const Container: React.FC = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const handleSelectionChange = useCallback(
    (e: { selectedIndex: number }) => {
      setSelected(e.selectedIndex);
      dispatch(
        Actions.Update.create({
          currentOption: TABS[e.selectedIndex],
        }),
      );
    },
    [dispatch, setSelected],
  );

  return (
    <div data-testid='assess-ruleset-version-context-dates-mvr-container'>
      <M.Tabs onChange={handleSelectionChange} selectedIndex={selected}>
        <M.TabList>
          <M.Tab data-testid='assess-mvr-accidents-tab'>Accidents</M.Tab>
          <M.Tab data-testid='assess-mvr-suspensions-tab'>Suspensions</M.Tab>
          <M.Tab data-testid='assess-mvr-violations-tab'>Violations</M.Tab>
        </M.TabList>
        <M.TabPanels>
          <M.TabPanel data-testid='assess-mvr-accidents-tab-panel'>
            <Accidents />
          </M.TabPanel>
          <M.TabPanel data-testid='assess-mvr-suspensions-tab-panel'>
            <Suspensions />
          </M.TabPanel>
          <M.TabPanel data-testid='assess-mvr-violations-tab-panel'>
            <Violations />
          </M.TabPanel>
        </M.TabPanels>
      </M.Tabs>
    </div>
  );
};

export default Container;
