import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const SectionSubtitle = styled.p.attrs({
  className: 'alias-section-subtitle',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
    margin-top: 0;
    padding-bottom: 1.5rem;
  }
`;

export const TitleContainer = styled.div.attrs({
  className: 'alias-title-container',
})`
  gap: 6px;
`;

export const Title = styled.p.attrs({
  className: 'alias-title',
})`
  color: ${colors.uiGrey900};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  display: flex;
`;

export const Subtitle = styled.span.attrs({
  className: 'alias-subtitle',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
  }
`;

export const StyledRadioButton = styled(M.RadioButton).attrs({
  className: 'alias-radio-button',
})`
  border-radius: 4px;
  padding: 12px 24px 12px 12px;
  width: 100%;

  background: ${props =>
    props.checked ? `${colors.uiNavy100}` : `${colors.uiGrey0}`};
  border: ${props =>
    props.checked
      ? `1px solid ${colors.uiNavy200} !important`
      : `1px solid ${colors.uiGrey200} !important`};
`;

export const Section = styled.section.attrs({
  className: 'alias-section',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${colors.uiGrey200};
  padding: 24px;
`;

export const SectionTitleContainer = styled.div.attrs({
  className: 'alias-section-title-container',
})`
  display: flex;
  margin-bottom: 1rem;
  gap: 8px;
  flex-wrap: wrap;

  #mastodon h5 {
    margin-bottom: 0;
  }
`;

export const Recommended = styled.div.attrs({
  className: 'alias-recommended',
})`
  border-radius: 4px;
  background: ${colors.uiAqua300};
  text-align: center;
  align-content: center;
  padding: 0 6px 0 6px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: auto;
`;

export const SectionTitle = styled.h5.attrs({
  className: 'alias-section-title',
})`
  margin-bottom: 0 !important;
`;

export const RadioButtonGroup = styled(M.RadioButtonGroup).attrs({
  className: 'alias-radio-button-group',
})`
  .cds--radio-button-group {
    width: 100%;
  }
`;

export const LoadingBlock = styled(M.LoadingBlock).attrs({
  className: 'alias-loading-block',
})`
  height: 16px;
  width: 48px;
  align-self: center;
  margin: 0 6px;
`;

export const Bold = styled.span.attrs({
  className: 'alias-bold',
})`
  font-weight: 700;
  color: ${colors.uiGrey900};
`;
