import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useUpdateAccountSettings } from 'components';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, REPORT_SETTING_EVENT_NAMES } from 'utils/analytics';

const StyledForm = styled(M.Form)`
  color: ${colors.uiTextPrimaryLight};

  p {
    max-width: 659px;
  }
`;

const StyledCheckboxBox = styled.div`
  .cds--checkbox-wrapper {
    margin-top: 0.75rem;
  }
`;

const ReportSettings: React.FC = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const { account } = user;

  const [hideReportSummary, setHideReportSummary] = useState(
    account?.hide_report_summary,
  );

  const { call: updateAccountCall } = useUpdateAccountSettings();

  const toggleReportSummary = useCallback(() => {
    const newValue = !hideReportSummary;
    setHideReportSummary(newValue);
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_ACCOUNT_SETTINGS_TAB_HIDE_REPORT_SUMMARY_CLICKED,
      {
        'Hide Report Summary Clicked': !newValue,
      },
    );

    const changedSettings = { hide_report_summary: newValue };
    updateAccountCall({ changedSettings, account });
  }, [hideReportSummary, trackEvent, updateAccountCall, account]);

  return (
    <StyledForm id='report-settings' data-testid='report-settings-form'>
      <h3>Manage Report View</h3>
      <StyledCheckboxBox>
        <M.Checkbox
          onChange={toggleReportSummary}
          name='hideReportSummary'
          labelText='Hide report summary'
          disabled={false}
          hideLabel={false}
          id='hideReportSummary'
          checked={hideReportSummary}
          data-testid='hide-report-summary-checkbox'
        />
      </StyledCheckboxBox>
    </StyledForm>
  );
};

export default ReportSettings;
