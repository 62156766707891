const getFormStatus = (
  values: any,
  errors: any,
  touched: any,
  complete: boolean,
  hasServerError: boolean,
) => {
  if (complete) {
    return 'complete';
  }

  if (
    hasServerError ||
    Object.keys(errors).some(key => touched[key as keyof typeof touched])
  ) {
    return 'warning';
  }

  const allValuesEmpty = Object.values(values).every(
    value => value === '' || value === false,
  );

  if (allValuesEmpty) {
    return 'not-started';
  }

  return 'incomplete';
};

export default getFormStatus;
