/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const P = styled.p`
  #mastodon && {
    margin-top: 1rem;
    color: ${colors.uiTextSecondaryLight};
  }
`;
