import i18n from 'i18next';
import merge from 'lodash/merge';
import { initReactI18next } from 'react-i18next';

import en from 'locales/en';
import * as adjudication from 'modules/adjudication/locales';
import * as assess from 'modules/assess/locales';
import * as assessV2 from 'modules/assess/v2/locales';
import * as account from 'containers/Account/locales';
import * as candidate from 'modules/candidate/locales';
import * as caseManagement from 'modules/case-management/locales';
import * as csv from 'components/CsvExport/locales';
import * as drugHealth from 'components/Report/Screenings/DrugHealthScreenings/locales';
import * as dashboard from 'components/DashboardLanding/locales';
import * as signup from 'components/Signup/locales';
import * as betterOrderExperience from 'components/BetterOrderExperience/locales';
import * as i9 from 'components/FormI9/locales';
import * as reportsList from 'modules/reports-list/locales';

import fc from 'components/FairChance/locales';

const namespaces = [
  'translation',
  adjudication.namespace,
  assess.namespace,
  assessV2.namespace,
  account.namespace,
  candidate.namespace,
  caseManagement.namespace,
  csv.namespace,
  drugHealth.namespace,
  'fc',
  signup.namespace,
  betterOrderExperience.namespace,
  i9.namespace,
  reportsList.namespace,
];

const resources = {
  en: {
    translation: en,
    fairChance: fc,
  },
};

merge(
  resources,
  adjudication.resources,
  assess.resources,
  assessV2.resources,
  account.resources,
  candidate.resources,
  caseManagement.resources,
  csv.resources,
  drugHealth.resources,
  dashboard.resources,
  signup.resources,
  betterOrderExperience.resources,
  i9.resources,
  reportsList.resources,
);

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    ns: namespaces,
    resources,
    partialBundledLanguages: true,
    react: {
      useSuspense: process.env.NODE_ENV !== 'test',
    },
    interpolation: {
      format: (value, format, lng, options) => {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'currency') {
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: options.formatOptions.currency || 'USD',
            currencyDisplay: 'narrowSymbol',
          }).format(value);
        }
        return value;
      },
    },
  });

export default i18n;
