import React, { useCallback } from 'react';
import Option from 'modules/assess/models/context-date-option';
import Description from './Description';
import AvailableOption from './option';

export type Props = {
  options: Option.List;
  selectedOptions: Option.List;
  setSelectedOptions: (options: Option.List) => void;
};

export const Content: React.FC<Props> = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleOptionChange = useCallback(
    (selected: boolean, option: Option.Type) => {
      const updateOptions = sortedOptions(
        option,
        selected,
        selectedOptions,
        options,
      );
      setSelectedOptions(updateOptions);
    },
    [options, selectedOptions, setSelectedOptions],
  );

  const children = options.map(option => (
    <AvailableOption
      checked={selectedOptions.includes(option)}
      key={option}
      option={option}
      onChange={handleOptionChange}
    />
  ));

  return (
    <>
      <Description />
      {children}
    </>
  );
};

function sortedOptions(
  eventOption: Option.Type,
  isOptionSelected: boolean,
  selectedOptions: Option.List,
  allOptions: Option.List,
) {
  let updatedOptions = [...selectedOptions];
  if (isOptionSelected) {
    updatedOptions.push(eventOption);
  } else {
    updatedOptions = updatedOptions.filter(o => o !== eventOption);
  }

  return updatedOptions.sort(
    (a, b) => allOptions.indexOf(a) - allOptions.indexOf(b),
  );
}

export default Content;
