import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import styled from 'styled-components';

const ModalBody = styled(M.ModalBody)`
  padding-bottom: 1rem !important;
  p {
    padding-right: 0;
  }
`;

const EmploymentVerificationModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <>
      <LearnMoreLink
        style={{ textDecoration: 'underline' }}
        data-testid='employment-verification-learn-more-open-dialog'
        onClick={onOpen}
        linkText='a lookback period'
      />
      <M.ComposedModal
        data-testid='employment-verification-learn-more-dialog'
        open={open}
        onClose={onClose}
      >
        <M.ModalHeader
          data-testid='employment-verification-learn-more-title'
          closeModal={onClose}
        >
          What is a lookback?
        </M.ModalHeader>
        <ModalBody data-testid='employment-verification-learn-more-content'>
          <p>
            A lookback period specifies how many years in the past a check
            searches for information. Most customers search within the last 5-7
            years. Information with a start or end date within the lookback
            period is included.
          </p>

          <p>
            Candidate work histories can be shorter than the lookback. You can
            change the lookback period in the dashboard based on a
            position&apos;s seniority, security clearance, or work with
            vulnerable populations.
          </p>
        </ModalBody>
      </M.ComposedModal>
    </>
  );
};

export default EmploymentVerificationModal;
