import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { namespace } from '../../locales';
import { StyledChoiceCardWrap } from '../GetStartedStep.styles';
import { StyledChoiceCardGroup } from './CandidateContactInfoSection.styles';

type InfoMethodRadiosProps = {
  selectedInfoMethod: GetStartedT.InfoMethod;
  setSelectedInfoMethod: Dispatch<SetStateAction<GetStartedT.InfoMethod>>;
};

type InfoMethodRadioOption = {
  id: string;
  title: string;
  description: string;
  value: GetStartedT.InfoMethod;
};

const InfoMethodRadios = ({
  selectedInfoMethod,
  setSelectedInfoMethod,
}: InfoMethodRadiosProps) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.infoMethodRadios`;

  const options: InfoMethodRadioOption[] = [
    {
      id: 'manual',
      title: t(`${basePath}.manual.title`),
      description: t(`${basePath}.manual.description`),
      value: 'MANUAL',
    },
    {
      id: 'upload',
      title: t(`${basePath}.upload.title`),
      description: t(`${basePath}.upload.description`),
      value: 'UPLOAD',
    },
  ];

  const handleInfoMethodClick = useCallback(
    e => {
      setSelectedInfoMethod(e.target.value);
    },
    [setSelectedInfoMethod],
  );

  return (
    <StyledChoiceCardGroup data-testid='info-method-radio-group'>
      {options.map(choice => {
        const { id, title, description, value } = choice;
        return (
          <StyledChoiceCardWrap key={id}>
            <M.ChoiceCard
              data-testid={`info-method-radio-group-${id}`}
              title={title}
              description={description}
              value={value}
              onClick={handleInfoMethodClick}
              checked={selectedInfoMethod === value}
            />
          </StyledChoiceCardWrap>
        );
      })}
    </StyledChoiceCardGroup>
  );
};

export default InfoMethodRadios;
